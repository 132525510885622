import { StatusBar, Style } from '@capacitor/status-bar';
import { isMobileFn } from './device';

const { setStyle } = StatusBar;

export const statusBar = {
  async setLight() {
    await setStyle({ style: Style.Light });
  },
  async setDark() {
    await setStyle({ style: Style.Dark });
  },
  async toggleDarkness(value: boolean) {
    const isMobile = await isMobileFn();

    if (!isMobile) return;

    await setStyle({ style: value ? Style.Dark : Style.Light });
  },
};
