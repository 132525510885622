<template>
  <div class="character-card-small d-flex clickable" @click="selectCharacter">
    <CommenterImage
      :src="resizeUpload(character.info.cropProfilePicture, '80x80')"
      :ring="get(character, 'profile_ring.image')"
      :ringWidth="60"
      :top="-5"
      size="md"
      type="character"
    />
    <div class="rest-info ml-3 flex-grow-1">
      <div class="name d-flex align-items-center justify-content-start">
        <div class="clickable no-select">
          {{ name }}
        </div>
      </div>
      <div class="description no-select">
        {{ characterDescriptionTruncated }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Character } from '@/shared/types/static-types';
import { truncateEasy, stripHtmlTags } from '@/shared/utils/string';
import { authStore } from '@/shared/pinia-store/auth';
import CommenterImage from '@/shared/components/storage/CommenterImage.vue';
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  character: {
    type: Object as PropType<Character>,
    default: {},
  },
});

const character = toRef(props, 'character');
const emit = defineEmits(['characterSelected']);

const user = computed(() => {
  const { user } = authStore();
  return user.value;
});

const name = computed(() => {
  let privacyIcon = '';
  if (character.value.privacy === 'U') {
    privacyIcon = '🔗 ';
  } else if (character.value.privacy === 'M') {
    privacyIcon = '🔒 ';
  }
  return privacyIcon + character.value.info.name;
});

const characterDescriptionTruncated = computed(() => {
  let disclaimer = '';
  if (character.value.privacy === 'U') {
    disclaimer = "(Note: This character's privacy setting is Unlisted) ";
  } else if (character.value.privacy === 'M') {
    disclaimer = '(Note: This character is only visible to yourself) ';
  }
  const desc = stripHtmlTags(character.value.info.description || '');
  return truncateEasy(disclaimer + desc, 114);
});

const selectCharacter = () => {
  emit('characterSelected', character.value.id);
};
// }
</script>

<style lang="sass" scoped>
.actions
  max-width: 200px
  .inline-button
    &:not(:first-of-type)
      margin: 0 5px
.edit-button
  color: #7f10b3
  font-size: 14px
  font-weight: bold
.character-card-small
  img
    width: 60px
    height: 60px
    border-radius: 40px
    border: solid gray 0.1px
    object-fit: cover
  .name
    font-size: 18px
    font-weight: bold
  .description
    font-size: 13px
  .icon-button
    color: #ae38e5
    font-size: 20px
    .d-flex
      font-size: 20px
  .reaction-count
    font-size: 18px
    font-weight: bold
.primary-color
  color: #7f10b3
.secondary-color
  color: #3dc2ff
</style>
