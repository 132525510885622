<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar>
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-title class="title">{{ modalTitle }}</ion-title>
        </div>
        <ion-buttons slot="end">
          <ion-button class="close-button" @click="dismissModal()">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content">
      <div class="pb-3 px-4 mt-3">
        <div class="h-100">
          <div class="d-flex flex-column">
            <div>Enter Template Name</div>
            <div class="mt-3 mb-3">
              <div>
                <ion-input v-model="templateName" class="c-input" placeholder="Enter template name." />
              </div>
            </div>

            <div class="w-100 d-flex justify-content-center">
              <ion-button
                class="submit-btn"
                :disabled="isSubmitting || !isFormComplete"
                type="submit"
                @click="onSubmit"
              >
                <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
                <span v-else>Submit</span>
              </ion-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { IonModal, IonContent, IonTitle } from '@ionic/vue';

import { toast } from '@/shared/native';
import { postCharacterStylizeTemplate, updateCharTemplate } from '@/shared/actions/characters';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  isEditPopover: {
    type: Boolean,
    default: false,
  },
  modalTitle: {
    type: String,
    default: 'Edit Template Name',
  },
  id: {
    type: String,
    default: '',
  },
  tempName: {
    type: String,
    default: '',
  },
});

const tempName = toRef(props, 'tempName');
const templateName: any = ref('');
const id = toRef(props, 'id');
watch(
  () => props.isOpen,
  async () => {
    templateName.value = tempName.value;
  }
);
const emits = defineEmits(['dismissModal', 'fetch', 'updated']);
const isSubmitting = ref(false);
const isEditPopover = toRef(props, 'isEditPopover');

const dismissModal = () => {
  emits('dismissModal');
};

const isFormComplete = computed(() => {
  return !!templateName.value;
});
const onSubmit = async () => {
  if (!isFormComplete.value) return;

  isSubmitting.value = true;

  try {
    const res = await updateCharTemplate(id.value, false, {
      name: templateName.value,
    });
    isEditPopover.value ? emits('updated') : emits('fetch', res.id);
  } catch (_err) {
    await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    dismissModal();
  }

  isSubmitting.value = false;
};
</script>
<style lang="sass" scoped>
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: calc(85vh - 45px)
  overflow: auto
  overflow-x: hidden

.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 18px
  text-align: center
  padding-left: 55px
  padding-right: 0px
</style>
