import _sanitizeHtml from 'sanitize-html';

export const sanitizeHtml = (text: string): string => {
  const noFollowText = text.replace(/<a /g, '<a rel="nofollow" ');
  return `<div class='shtmlch'>${_sanitizeHtml(noFollowText || '', {
    allowedAttributes: { '*': ['style', 'src', 'alt', 'width', 'height', 'href', 'rel', 'title', 'data-toggle'] },
    allowedIframeHostnames: ['www.youtube.com', 'www.youtu.be'],
    allowedTags: [
      'address',
      'article',
      'aside',
      'iframe',
      'details',
      'footer',
      'header',
      'summary',
      'center',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'hgroup',
      'main',
      'nav',
      'section',
      'blockquote',
      'dd',
      'div',
      'dl',
      'dt',
      'figcaption',
      'figure',
      'hr',
      'li',
      'main',
      'ol',
      'p',
      'pre',
      'ul',
      'a',
      'abbr',
      'b',
      'bdi',
      'bdo',
      'br',
      'cite',
      'code',
      'data',
      'dfn',
      'em',
      'i',
      'kbd',
      'mark',
      'q',
      'rb',
      'rp',
      'rt',
      'rtc',
      'ruby',
      's',
      'samp',
      'small',
      'span',
      'strong',
      'sub',
      'sup',
      'time',
      'u',
      'var',
      'wbr',
      'caption',
      'col',
      'colgroup',
      'table',
      'tbody',
      'td',
      'tfoot',
      'th',
      'thead',
      'tr',
      'img',
    ],
    parseStyleAttributes: true,
  })}</div>`;
};
