import { MutationTree } from 'vuex';
import { NotificationsState } from './types';

export const mutations: MutationTree<NotificationsState> = {
  SET_NOTIFICATIONS: (state, { notifications, paging, unreadCount }) => {
    state.notifications = notifications;
    state.notificationsPaging = paging;
    state.unreadCount = unreadCount;
  },
  APPEND_NOTIFICATIONS: (state, { results, paging }) => {
    state.notifications = [...(state.notifications || []), ...results];
    state.notificationsPaging = paging;
    state.notificationsPage = (state.notificationsPage || 0) + 1;
  },
  SET_UNREAD_COUNT: (state, { unreadCount }) => {
    state.unreadCount = unreadCount;
    state.unreadCountFromAPI = unreadCount;
  },
};
