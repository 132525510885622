import { ActionTree } from 'vuex';
import { NotificationsState } from './types';
import { RootState } from '@/shared/store';
import { fetchNotifications, getNextPage, getUnreadCount, markAllAsRead } from '@/shared/actions/notifications';
import constants from '@/shared/statics/constants';

export const actions: ActionTree<NotificationsState, RootState> = {
  async getNotifications({ commit }, { page = 1, pageSize = constants.defaultPageSize, markAsRead, type }) {
    const {
      results: { results, ...paging },
      count,
    } = await fetchNotifications(page, pageSize, markAsRead, type);

    const unreadCount = markAsRead ? 0 : count;
    commit('SET_NOTIFICATIONS', { notifications: results, paging, unreadCount });
  },

  async notificationsNextPage({ commit, state }) {
    const { notificationsPaging } = state;
    const {
      results: { results, ...paging },
    } = await getNextPage(notificationsPaging);

    commit('APPEND_NOTIFICATIONS', { results, paging });
  },

  async fetchUnreadCount({ commit }) {
    const { unread_count } = await getUnreadCount();

    commit('SET_UNREAD_COUNT', { unreadCount: unread_count });
  },

  async markAllAsRead({ commit }) {
    await markAllAsRead();

    commit('SET_UNREAD_COUNT', { unreadCount: 0 });
  },
};
