import constants from '@/shared/statics/constants';
import type { Paging } from '@/shared/types/static-types';
import { chAxios } from '@/shared/lib/axios';

export async function fetchNotifications(
  page = 1,
  pageSize = constants.defaultPageSize,
  markAsRead?: boolean,
  type = 'all'
) {
  const typeParams =
    type === 'all'
      ? {}
      : {
          type,
        };
  const url = `/notifications/`;

  const { data } = await chAxios().get(url, {
    params: { page, page_size: pageSize, ...(markAsRead && { mark_as_read: 1 }), ...typeParams },
  });
  return data;
}

export async function getNextPage(paging: Paging) {
  const url = paging.next;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getUnreadCount(key = '') {
  const url = `/notifications/unread_count/`;

  const { data } = await chAxios().get(url, { params: { key } });
  return data;
}

export async function markAllAsRead() {
  const url = `/notifications/mark_all_as_read/`;

  const { data } = await chAxios().post(url, {});
  return data;
}

export async function markAsSkip(notificationId: string) {
  const url = `/notifications/mark_as_skip/`;

  const { data } = await chAxios().get(url, {
    params: { notification_id: notificationId },
  });
  return data;
}
