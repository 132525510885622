
import { bootstrap } from 'vue-gtag';
import * as amplitude from '@amplitude/analytics-browser';

export function enableAnalytics() {
  enableGA();
  enableAmplitude();
}

function enableGA() {
  const {
    public: { env },
  } = useRuntimeConfig();
  if (env === 'development') return;
  bootstrap().then((gtag) => {
    return gtag;
  });
}

function enableAmplitude() {
  const {
    public: { env },
  } = useRuntimeConfig();
  if (env === 'development') return;
}

export function sendAnalyticsEvent(eventName: string, params?: any) {
  const { trackAmplitudeEvent, trackPosthogEvent } = useAnalytics();

  trackAmplitudeEvent(eventName, params);
  trackPosthogEvent(eventName, params);
}

export function analyticsSetUser(userId: string) {
  const {
    public: { env },
  } = useRuntimeConfig();
  if (env === 'development') {
    console.info('analyticsSetUser: Would set userId to ' + userId);
    return;
  }
  try {
    amplitude.setUserId(userId);
  } catch (e) {}
}
