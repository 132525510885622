import { ActionTree } from 'vuex';
import { UsersState } from './types';
import { RootState } from '@/shared/store';
import {
  getUser,
  getUserByUsername,
  getUserProfileById,
  getUserProfileByUsername,
  setPublicInventory
} from '@/shared/actions/users';
import { User, UserProfile, Collectible } from '@/shared/types/static-types';

export const actions: ActionTree<UsersState, RootState> = {
  async getUser({ commit }, id) {
    const userInstance: User = await getUser(id);
    commit('SET_USER', userInstance);
  },

  async getUserByUsername({ commit }, username) {
    const userInstance: User = await getUserByUsername(username);
    commit('SET_USER', userInstance);
  },

  async getUserProfile({ commit }, id) {
    const userProfileInstance: UserProfile = await getUserProfileById(id);
    commit('SET_PROFILE', userProfileInstance);
  },
  async getUserProfileByUsername({ commit }, username) {
    const userProfileInstance: UserProfile = await getUserProfileByUsername(username);
    commit('SET_PROFILE', userProfileInstance);
  },
  resetUserProfile({ commit }) {
    commit('RESET_PROFILE');
  },
  async setPublicInventory({ commit }, featuredItemIds) {
    const featuredCollectibles: Collectible[] = await setPublicInventory(featuredItemIds);
    commit('UPDATE_PROFILE', { featured_collectibles: featuredCollectibles });
  },
};
