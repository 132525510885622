<template>
  <div class="position-relative w-100">
    <div class="scroller h-100 position-absolute w-100 d-flex justify-content-between">
      <div v-if="withBackground" :class="{ hide: !hasLess }" class="left-button-background btn-background">
        <div
          class="left-scroller d-flex align-items-center justify-content-start"
          :class="{ hide: !hasLess, 'scroller-bg': noGradientBackground }"
        >
          <ion-button
            mode="ios"
            class="button"
            fill="none"
            shape="round"
            style="background: #7a7e9b !important; width: 24px !important; height: 32px !important"
            :class="{ hide: !hasLess, 'chevron-button mx-2': noGradientBackground }"
            @click="scroll('left')"
          >
            <ion-icon
              class="icon"
              :icon="chevronBackSharp"
              :class="{ 'icon-dark': noGradientBackground, 'icon-size': withBackground }"
            />
          </ion-button>
        </div>
      </div>
      <div
        v-else
        class="left-scroller d-flex align-items-center justify-content-start"
        :class="{ hide: !hasLess, 'scroller-bg': noGradientBackground }"
      >
        <ion-button
          mode="ios"
          class="button"
          fill="none"
          shape="round"
          :class="{ hide: !hasLess, 'chevron-button mx-2': noGradientBackground }"
          @click="scroll('left')"
        >
          <ion-icon class="icon" :icon="chevronBackSharp" :class="{ 'icon-dark': noGradientBackground }" />
        </ion-button>
      </div>
      <div v-if="withBackground" :class="{ hide: !hasMore }" class="ml-auto button-background btn-background">
        <div
          class="right-scroller d-flex align-items-center justify-content-end"
          :class="{ hide: !hasMore, 'scroller-bg': noGradientBackground }"
        >
          <ion-button
            mode="ios"
            class="button"
            fill="none"
            shape="round"
            style="background: #7a7e9b !important; width: 24px !important; height: 32px !important"
            :class="{ hide: !hasMore, 'chevron-button mx-2': noGradientBackground }"
            @click="scroll('right')"
          >
            <ion-icon
              class="icon"
              :icon="chevronForwardSharp"
              :class="{ 'icon-dark': noGradientBackground, 'icon-size': withBackground }"
            />
          </ion-button>
        </div>
      </div>
      <div
        v-else
        class="right-scroller d-flex align-items-center justify-content-end"
        :class="{ hide: !hasMore, 'scroller-bg': noGradientBackground }"
      >
        <ion-button
          mode="ios"
          class="button"
          fill="none"
          shape="round"
          :class="{ hide: !hasMore, 'chevron-button mx-2': noGradientBackground }"
          @click="scroll('right')"
        >
          <ion-icon class="icon" :icon="chevronForwardSharp" :class="{ 'icon-dark': noGradientBackground }" />
        </ion-button>
      </div>
    </div>
    <div ref="scrollContent" class="scroll-content">
      <slot :events="{ resize: calculateScroll }" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import { Prop } from 'vue-property-decorator';
import { chevronForwardSharp, chevronBackSharp } from 'ionicons/icons';

@Options({
  name: 'Scroller',
  components: {},
})
export default class Scroller extends Vue {
  @Prop() public scrollSize!: number;
  @Prop({ default: 80 }) public threshold!: number;
  @Prop({ default: false }) public noGradientBackground!: boolean;
  @Prop({ default: false }) public withBackground!: boolean;

  public first = first;

  public hasMore = false;
  public hasLess = false;
  public chevronForwardSharp = chevronForwardSharp;
  public chevronBackSharp = chevronBackSharp;
  public goToMarketPlaceListings() {
    this.$emit('switchTab');
  }

  public calculateScroll() {
    try {
      const scrollContent = this.$refs.scrollContent as HTMLElement;
      const scrollPosition = scrollContent.scrollLeft;
      const clientWidth = scrollContent.clientWidth;
      const scrollWidth = scrollContent.scrollWidth - clientWidth;
      if (scrollPosition < this.threshold) {
        this.hasLess = false;
      } else {
        this.hasLess = true;
      }
      if (scrollPosition < Math.max(scrollWidth - this.threshold, 0)) {
        this.hasMore = true;
      } else {
        this.hasMore = false;
      }
    } catch (error) {}
  }

  public init() {
    try {
      this.calculateScroll();
      const scrollContent = this.$refs.scrollContent as HTMLElement;
      scrollContent.addEventListener('scroll', () => {
        this.calculateScroll();
      });
    } catch (error) {}
  }

  public scroll(direction: 'left' | 'right') {
    try {
      const scrollContent = this.$refs.scrollContent as HTMLElement;
      if (direction === 'left') {
        scrollContent.scrollBy({ left: -this.scrollSize, behavior: 'smooth' });
      }

      if (direction === 'right') {
        scrollContent.scrollBy({ left: this.scrollSize, behavior: 'smooth' });
      }
    } catch (error) {}
  }

  mounted() {
    setTimeout(() => {
      this.init();
    }, 250);
    window.addEventListener('resize', this.calculateScroll);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.calculateScroll);
  }
}
</script>

<style lang="sass" scoped>
.scroller-bg
  background: none !important
.hide
  pointer-events: none !important
  opacity: 0
.chevron-button
  width: 37px !important
  height: 37px !important
  border-radius: 20px !important
  background: white !important
.button
  width: 35px
  height: 100%
  pointer-events: all
  visibility: visible
  --padding-start: 0
  --padding-end: 0
  z-index: 1
.scroller
  z-index: 1
  pointer-events: none
.left-scroller
  pointer-events: none
  position: absolute
  width: 100%
  height: 100%
  background: linear-gradient(270deg, rgba(2,0,36,0) 0%, rgba(236,236,239,0.0) 90%, rgba(242,242,242,0.6) 95%, rgba(242,242,242,0.95) 100%)
.right-scroller
  pointer-events: none
  position: absolute
  width: 100%
  height: 100%
  background: linear-gradient(90deg, rgba(2,0,36,0) 0%, rgba(236,236,239,0.0) 90%, rgba(242,242,242,0.6) 95%, rgba(242,242,242,0.95) 100%)
.listings
  overflow-x: auto

.name
  font-size: 18px
  font-weight: bold
  color: #737373
  cursor: pointer
  .name:hover
    opacity: 0.7

.see-all-text
  color: #214163
.title
  font-size: 24px
  font-weight: bold

.listing-card
  overflow: unset !important
  .icon-button
    color: #ae38e5
    font-size: 20px
    .d-flex
      font-size: 20px
  @media(min-width: 420px)
    min-width: 28% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 50% !important
    max-width: 50% !important

.one-listing
  img

    width: 250px
    height: 130px
    object-fit: cover
    border-top-right-radius: 4px
    border-top-left-radius: 4px

.baseprice
  font-size: 18px
  font-weight: bold
.scroll-content
  overflow-x: auto
  overflow-y: hidden
.listing-list
  overflow-x: hidden
.scroll-content, .listing-list
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none

.icon-dark
  color: #214163 !important

.button-background
  width: 45px
  z-index: 3 !important

.btn-background
  background: #f3f3f3 !important
.dark .btn-background
  background: #010123 !important

.left-button-background
  width: 55px
  z-index: 3 !important
.icon-size
  font-size: 14px !important
</style>
