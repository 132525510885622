import { Module } from 'vuex';
import { RootState } from '../../index';
import { UserProfile } from './../../../types/static-types';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { UsersState } from './types';

const state: UsersState = {
  users: {}, // { user-id1: {user}, user-id2: {user}}
  follows: {}, // {follow-id1: {follow}, follow-id2: {follow}}
  ids: [], // [user-id1, user-id2, user-id3]
  lastPaging: {}, // {count: #, next: url, previous: url, page: # >= 1}
  loading: false,
  profile: {} as UserProfile,
};

export const UsersModule: Module<UsersState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
