<template>
  <div class="profile-popover">
    <ion-item class="item-container d-flex pointer no-select" @click="copyLinktoProfile">
      <div class="ml-2 menu-item d-flex align-items-center no-select">
        <i class="ti-link mr-1" /><span>Copy Link</span>
      </div>
    </ion-item>

    <ion-item v-if="isPostOwner" class="item-container d-flex pointer no-select" @click="onPress('editPost')">
      <div class="ml-2 menu-item d-flex align-items-center no-select">
        <i class="ti-pencil mr-1" /><span>Edit Post</span>
      </div>
    </ion-item>

    <report
      v-if="!isPostOwner"
      :is-popover="true"
      :report-entity-id="post?.id"
      :report-entity-type="'blab'"
      :reported-user="post.user"
      @close="closeReportPost('block')"
    >
      <ion-item class="item-container d-flex pointer no-select">
        <div class="ml-2 menu-item d-flex align-items-center no-select text-danger">
          <i class="ti-alert mr-1" /><span>Report Post</span>
        </div>
      </ion-item>
    </report>
  </div>
</template>

<script lang="ts" setup>
import Report from '@/shared/components/Report.vue';

import { OverlayInterface } from '@ionic/core';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';

const { user: loggedInUser } = authStore();

const props = defineProps({
  instance: {
    type: Object as PropType<OverlayInterface>,
  },
  post: {
    type: Object as PropType<any>,
  },
});

const instance = toRef(props, 'instance') as any;
const post = toRef(props, 'post');

const copyLinktoProfile = () => {
  const {
    public: { hostUrl },
  } = useRuntimeConfig();
  const link = `${hostUrl}/posts/${post.value?.id}`;
  navigator.clipboard.writeText(link);
  toast.show('Link copied to clipboard', 'nonative', 'primary');
  onPress();
};

const onPress = (code?: string) => {
  if (instance) {
    try {
      instance.value.dismiss(code);
    } catch (e) {}
  }
  dismissByClick();
};

const closeReportPost = async (value: any) => {
  await instance.value.dismiss(value);
};
const dismissByClick = () => {
  try {
    const el = document.querySelector('body') as any;
    el.click();
  } catch (error) {}
};

const isPostOwner = computed(() => {
  try {
    return post.value.user!.username === loggedInUser.value.username;
  } catch (error) {
    return false;
  }
});
</script>

<style scoped lang="sass">
.pointer
  cursor: pointer
.bottom-button
  background-color: var(--ion-color-primary) !important
  color: white
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
</style>
