import { sendAnalyticsEvent } from '@/shared/services/analytics';
import constants from '@/shared/statics/constants';
import { chAxios } from '@/shared/lib/axios';

export async function getchatroomDetails(id?: string) {
  const url = `/worlds/chats/${id}/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getUnmuteAndMuteStatus(id?: string) {
  const url = `/worlds/chats/${id}/notification-status/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getJoinedChatroomCounts() {
  const url = `/worlds/chats/joined-chatrooms-unread-count/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getTopChatrooms(orderBy = '-created', page = 1, pageSize = constants.defaultPageSize) {
  const url = `/worlds/chats/public-chat-rooms/`;
  try {
    const { data } = await chAxios().get(url, { params: { order_by: orderBy, page, page_size: pageSize } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function socialSpaceChatroomMessage(id: string, msgData: {}) {
  const url = `/worlds/chats/${id}/send-message/`;
  const { data } = await chAxios().post(url, msgData);
  sendAnalyticsEvent('Sent message in chatroom');
  return data;
}

export async function socialSpaceChatroomReplyMessage(id: string, msgData: {}) {
  const url = `/worlds/chats/${id}/send-message/`;
  const { data } = await chAxios().post(url, msgData);
  sendAnalyticsEvent('Replied in chatroom');
  return data;
}
export async function getSocialSpaceRoomMsgs(id: string, page = 1, pageSize = 100) {
  const url = `/worlds/chats/${id}/message/`;
  try {
    const { data } = await chAxios().get(url, { params: { page, page_size: pageSize } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function joinPrivateSocialChatroom(
  room: string,
  world: string,
  requestee_id: string,
  requestee_type: string,
  message: string
) {
  const url = `/worlds/chats/join-chatroom/`;
  const { data } = await chAxios().post(url, { room, world, requestee_id, requestee_type, message });
  return data;
}

export async function approveRequestToJoinSocialChatroom(id: string, world: string) {
  const url = `/worlds/chats/accept-request/`;
  const { data } = await chAxios().post(url, { id, world });
  sendAnalyticsEvent('Approved request to join chatroom');
  return data;
}

export async function getJoinedChatrooms(world_id: string, page = 1, pageSize = constants.defaultPageSize) {
  const url = `/worlds/chats/joined-chatrooms/`;
  try {
    const { data } = await chAxios().get(url, { params: { world_id, page, page_size: pageSize } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getchatUnreadCounts(roomIds: string[]) {
  const url = `/worlds/chats/room-unread-count/`;
  try {
    const { data } = await chAxios().post(url, { room_ids: roomIds });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getChatroomCounts(roomIds: string[]) {
  try {
    const { data } = await chAxios().post(`/worlds/chats/chatrooms-count/`, {
      room_ids: roomIds,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getChatroomMembers(id: string, page = 1, pageSize = constants.defaultPageSize) {
  const url = `/worlds/chats/${id}/chatroom-member/`;
  try {
    const { data } = await chAxios().get(url, { params: { page, page_size: pageSize } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function chatroomUnreadMessageCounts(id?: string) {
  const url = `/worlds/chats/${id}/chatroom-msg-count-in-other-rooms/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getChatroomStatus(world_id: string, roomIds: string[]) {
  try {
    const { data } = await chAxios().post(`/worlds/chats/chatrooms-status/?world_id=${world_id}`, {
      room_ids: roomIds,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getChatroomLastMsg(world_id: string, roomIds: string[]) {
  try {
    const { data } = await chAxios().post(`/worlds/chats/chatrooms-last-msg/?world_id=${world_id}`, {
      room_ids: roomIds,
    });
    return data;
  } catch (e) {
    throw e;
  }
}
export async function getChatroomMemberCounts(world_id: string, roomIds: string[]) {
  try {
    const { data } = await chAxios().post(`/worlds/chats/chatrooms-member-count/?world_id=${world_id}`, {
      room_ids: roomIds,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getUnmuteAndMuteNotificationStatus(roomIds: string[]) {
  try {
    const { data } = await chAxios().post(`/worlds/chats/rooms-notification-status/`, {
      room_ids: roomIds,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function removSocialSpaceChatroomMember(id: string, userid: string) {
  const url = `/worlds/chats/${id}/remove-chatroom-member/`;
  try {
    const { data } = await chAxios().delete(url, { data: { user_id: userid } });
    sendAnalyticsEvent('Removed social space chatroom member');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function mutesocialSpaceChatroom(id: string, isMuted: boolean) {
  const url = `/worlds/chats/${id}/mute-room/`;
  const { data } = await chAxios().post(url, { is_muted: isMuted });
  sendAnalyticsEvent(`${isMuted ? 'Muted' : 'Unmuted'} social space chatroom`);
  return data;
}

export async function leavesocialSpaceChatroom(id: string) {
  const url = `/worlds/chats/${id}/leave-chatroom/`;
  const { data } = await chAxios().delete(url);
  sendAnalyticsEvent('Left space chatroom');
  return data;
}

export async function searchChatRooms(page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/worlds/chats/`;
  try {
    const { data } = await chAxios().get(url, { params: { page, page_size: pageSize, ...params } });
    return data;
  } catch (e) {
    throw e;
  }
}
