import {
  IonToggle,
  IonInput,
  IonItem,
  IonSpinner,
  IonButton,
  IonRouterOutlet,
  IonPage,
  IonTabButton,
  IonBadge,
  IonTabBar,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonTitle,
  IonSegmentButton,
  IonSegment,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSkeletonText,
  IonCheckbox,
  IonReorderGroup,
  IonReorder,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonCard,
  IonRadio,
  IonList,
  IonRadioGroup,
  IonMenu,
  IonMenuToggle,
  IonAccordion,
  IonAccordionGroup,
  IonRange,
  IonModal,
  IonProgressBar,
  IonIcon,
  IonCardContent,
  IonCardHeader,
  IonPopover,
  IonSearchbar,
  IonMenuButton,
  IonFooter,
} from '@ionic/vue';
import InlineSvg from 'vue-inline-svg';
import VueCountdown from '@chenfengyuan/vue-countdown';
import Scroller from '@/shared/components/Scroller.vue';
import IonCustomButton from '@/shared/components/ion-custom-button.vue';
import ModalsLayout from '@/shared/components/modals.vue';
import PlusTag from '@/shared/components/PlusTag.vue';
import ChLoading from '@/shared/components/ChLoading.vue';
import Editor from '@/shared/components/Editor.vue';
import { Sortable } from 'sortablejs-vue3';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('Sortable', Sortable);
  nuxtApp.vueApp.component('Editor', Editor);
  nuxtApp.vueApp.component('ion-popover', IonPopover);
  nuxtApp.vueApp.component('ion-toggle', IonToggle);
  nuxtApp.vueApp.component('ion-card-content', IonCardContent);
  nuxtApp.vueApp.component('ion-card-header', IonCardHeader);
  nuxtApp.vueApp.component('ion-input', IonInput);
  nuxtApp.vueApp.component('ion-item', IonItem);
  nuxtApp.vueApp.component('ion-spinner', IonSpinner);
  nuxtApp.vueApp.component('ion-button', IonButton);
  nuxtApp.vueApp.component('ion-router-outlet', IonRouterOutlet);
  nuxtApp.vueApp.component('ion-page', IonPage);
  nuxtApp.vueApp.component('ion-tab-button', IonTabButton);
  nuxtApp.vueApp.component('ion-badge', IonBadge);
  nuxtApp.vueApp.component('ion-tab-bar', IonTabBar);
  nuxtApp.vueApp.component('ion-toolbar', IonToolbar);
  nuxtApp.vueApp.component('ion-header', IonHeader);
  nuxtApp.vueApp.component('ion-buttons', IonButtons);
  nuxtApp.vueApp.component('ion-segment-button', IonSegmentButton);
  nuxtApp.vueApp.component('ion-segment', IonSegment);
  nuxtApp.vueApp.component('ion-label', IonLabel);
  nuxtApp.vueApp.component('ion-refresher', IonRefresher);
  nuxtApp.vueApp.component('ion-refresher-content', IonRefresherContent);
  nuxtApp.vueApp.component('ion-content', IonContent);
  nuxtApp.vueApp.component('ion-custom-button', IonCustomButton);
  nuxtApp.vueApp.component('ion-infinite-scroll', IonInfiniteScroll);
  nuxtApp.vueApp.component('ion-infinite-scroll-content', IonInfiniteScrollContent);
  nuxtApp.vueApp.component('ion-skeleton-text', IonSkeletonText);
  nuxtApp.vueApp.component('modals-layout', ModalsLayout);
  nuxtApp.vueApp.component('ion-checkbox', IonCheckbox);
  nuxtApp.vueApp.component('ion-reorder-group', IonReorderGroup);
  nuxtApp.vueApp.component('ion-reorder', IonReorder);
  nuxtApp.vueApp.component('ion-select', IonSelect);
  nuxtApp.vueApp.component('ion-select-option', IonSelectOption);
  nuxtApp.vueApp.component('ion-textarea', IonTextarea);
  nuxtApp.vueApp.component('ion-card', IonCard);
  nuxtApp.vueApp.component('ion-radio', IonRadio);
  nuxtApp.vueApp.component('ion-list', IonList);
  nuxtApp.vueApp.component('ion-radio-group', IonRadioGroup);
  nuxtApp.vueApp.component('scroller', Scroller);
  nuxtApp.vueApp.component('inline-svg', InlineSvg);
  nuxtApp.vueApp.component('ion-menu', IonMenu);
  nuxtApp.vueApp.component('ion-menu-toggle', IonMenuToggle);
  nuxtApp.vueApp.component('ion-accordion', IonAccordion);
  nuxtApp.vueApp.component('ion-accordion-group', IonAccordionGroup);
  nuxtApp.vueApp.component('ion-range', IonRange);
  nuxtApp.vueApp.component('ion-modal', IonModal);
  nuxtApp.vueApp.component('ion-progress-bar', IonProgressBar);
  nuxtApp.vueApp.component('ion-title', IonTitle);
  nuxtApp.vueApp.component('ion-icon', IonIcon);
  nuxtApp.vueApp.component(VueCountdown.name, VueCountdown);
  nuxtApp.vueApp.component('ion-searchbar', IonSearchbar);
  nuxtApp.vueApp.component('ion-menu-button', IonMenuButton);
  nuxtApp.vueApp.component('PlusTag', PlusTag);
  nuxtApp.vueApp.component('ChLoading', ChLoading);
  nuxtApp.vueApp.component('ion-footer', IonFooter);
});
