import { defineStore, storeToRefs } from 'pinia';
import { PushNotificationData } from '~/shared/types/static-types';

export const usePush = defineStore(
  'push',
  () => {
    const isCollapsed = ref(true);
    const deviceToken = ref('');

    const announce = (data: PushNotificationData) => {
      pushes.value.push(data);
      pushes.value = Array.from(pushes.value.reduce((map, obj) => map.set(obj.title, obj), new Map()).values());
    };

    const setDeviceToken = (token: string) => {
      deviceToken.value = token;
    };

    // const pushes = ref<PushNotificationData[]>([
    //   { key: '1', title: 'New comment from zkr', path: '/profile' },
    //   { key: '2', title: 'New character from zkr', path: '/profile' },
    //   { key: '3', title: 'New character from zkr', path: '/profile' },
    //   { key: '4', title: 'New character from zkr', path: '/profile' },
    //   { key: '5', title: 'New character from zkr', path: '/profile' },
    //   { key: '6', title: 'New character from zkr', path: '/profile' },
    //   { key: '7', title: 'New character from zkr', path: '/profile' },
    // ]);

    const pushes = ref<PushNotificationData[]>([]);

    const swipeUp = () => {
      if (!isCollapsed.value) {
        isCollapsed.value = true;

        return;
      }

      if (isCollapsed.value) {
        pushes.value = [];
      }
    };

    const swipeDown = () => {
      if (isCollapsed.value) {
        isCollapsed.value = false;
      }
    };

    const dismissOne = (key: string) => {
      const pushIndex = pushes.value.findIndex((item) => item.key === key);

      pushes.value.splice(pushIndex, 1);
    };

    return {
      isCollapsed,
      setDeviceToken,
      swipeUp,
      swipeDown,
      dismissOne,
      announce,
      pushes,
      deviceToken,
    };
  },
  { persist: { enabled: true } }
);

export const pushStore = () => {
  const store = usePush();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
