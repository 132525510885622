// For now this is more about the image gallery in edit mode,
// rather than storing all visible images.
import { defineStore, storeToRefs } from 'pinia';
import { nanoid } from 'nanoid';
import { reactImage } from './shared/image';
import { handleObjectImageUpdates, bulkUpdateWatermarks, removeImages } from '@/shared/actions/imagesCollections';
import { Image, ImageCollection } from '@/shared/types/static-types';
import { toast } from '@/shared/native';
import logger from '@/shared/services/logger';

const resetStore = () => {
  return {
    imageCollections: {},
    imageCollectionImages: {}, // ownership & order of images in a collection
    images: {},
    dirtyImages: {},
    dirtyImageCollections: {},
    dirtyActiveImageCollectionIdOrdering: false,
    activeImageCollectionIds: [],
    visibleImageCollectionId: null,
    modalImageIndex: 0,
    viewImageModalOpen: false,
    draggingImageId: null,
    isWatermarkLoading: false,
    watermarkLoadText: '',
    removedImages: { ids: [], urls: [] },
  }
};

export interface IImageStore {
  imageCollections: { [key: string]: ImageCollection };
  imageCollectionImages: { [key: string]: string[] };
  images: { [key: string]: Image };
  dirtyImages: { [key: string]: Image };
  dirtyImageCollections: { [key: string]: ImageCollection };
  dirtyActiveImageCollectionIdOrdering: boolean;
  activeImageCollectionIds: string[];
  visibleImageCollectionId: string | null;
  modalImageIndex: number;
  viewImageModalOpen: boolean;
  draggingImageId: string | null;
  isWatermarkLoading: boolean;
  watermarkLoadText: string;
  removedImages: { ids: string[]; urls: string[] };
}

export const useImageStore = defineStore('images', {
  state: (): IImageStore => resetStore(),
  getters: {
    unsavedChanges: (state) => {
      return Object.keys(state.dirtyImages).length ||
        Object.keys(state.dirtyImageCollections).length ||
        state.dirtyActiveImageCollectionIdOrdering;
    },
    nonFanartActiveImageCollectionIds: (state) => {
      return state.activeImageCollectionIds.filter((id) => state.imageCollections[id]?.abbreviation !== 'fan_art');
    },
    imageById: (state) => (id: string) => {
      return state.images[id];
    },
    imageIdsInCollection: (state) => (id: string) => {
      return state.imageCollectionImages[id] || [];
    },
    imagesInCollection: (state) => (id: string) => {
      const imageIds = state.imageCollectionImages[id] || [];
      return imageIds.map((id) => state.images[id]).filter(Boolean);
    },
    imageCollectionById: (state) => (id: string) => {
      return state.imageCollections[id];
    },
    dirtyImagesArray: (state) => {
      if (!state.dirtyImages) return [];
      return Object.values(state.dirtyImages);
    },
    dirtyImageCollectionsArray: (state) => {
      if (!state.dirtyImageCollections) return [];
      return Object.values(state.dirtyImageCollections);
    },
    activeImageCollections: (state) => {
      const activeIds = state.activeImageCollectionIds;
      const out = Object.values(state.imageCollections)
        .filter((coll) => activeIds.includes(coll.id_ as string) || !coll.id);
      if (out.length === 1 && out[0].abbreviation === 'fan_art') return [];
      return out;
    },
    visibleImageCollection: (state) => {
      if (!state.visibleImageCollectionId) return null;
      return state.imageCollections[state.visibleImageCollectionId];
    },
    visibleImageCollectionImages: (state) => {
      if (!state.visibleImageCollectionId) return [];
      const imageIds = state.imageCollectionImages[state.visibleImageCollectionId]?.filter(Boolean) || [];
      return imageIds.map((id) => state.images[id]);
    },
    isVisibleImageCollectionReadOnly: (state) => {
      if (!state.visibleImageCollectionId) return false;
      return state.visibleImageCollectionId && state.imageCollections[state.visibleImageCollectionId] && state.imageCollections[state.visibleImageCollectionId].abbreviation === 'decorations';
    }
  },
  actions: {
    reset() {
      Object.assign(this, resetStore());
    },
    setDraggingImageId(id: string | null) {
      this.draggingImageId = id;
    },
    setVisibleCollection(id: string | null) {
      this.visibleImageCollectionId = id;
    },
    setActiveCollectionIds(ids: string[]) {
      this.activeImageCollectionIds = ids;
    },
    setImages(payload: Image[]) {
      if(!isEmpty(payload)){
        payload.forEach((image) => {
         // this.images[image.id!] = image;
         this.images[image.id!] = {...image, ...this.images[image.id!]};
       });
    }
    },
    setImageCollections(payload: ImageCollection[]) {
      if (payload?.length) this.visibleImageCollectionId = payload[0].id_!;
      const newIds = payload.map((coll) => coll.id_).filter(Boolean) as string[];
      this.setActiveCollectionIds(newIds);

      payload.forEach((coll) => {
        if (coll.id_) {
          this.imageCollections[coll.id_] = coll;
          (coll.images_page?.results || []).forEach((image) => {
            this.images[image.id!] = image;
          });
          if (coll.images_page?.results?.length) {
            this.imageCollectionImages[coll.id_] = coll.images_page.results.map((i) => i.id!);
          } else {
            this.imageCollectionImages[coll.id_] = [];
          }
        }
      });
    },
    updateImageLocal(payload: Image) {
      this.images[payload.id!] = {...this.images[payload.id!], ...payload};
      this.dirtyImages[payload.id!] = this.images[payload.id!];
    },
    createImageCollectionLocal(payload: { name?: string }) {
      const newColl = { 
        name: payload.name || 'Images',
        images: [],
        id_: nanoid(6),
      } as ImageCollection;
      this.imageCollections[newColl.id_!] = newColl;
      let newActiveCollectionIds = []
      if (!this.nonFanartActiveImageCollectionIds?.length) {
        newActiveCollectionIds = [newColl.id_!, ...this.activeImageCollectionIds];
      } else {
        newActiveCollectionIds = [...this.activeImageCollectionIds, newColl.id_!];
      }
      this.setActiveCollectionIds(newActiveCollectionIds)
      this.imageCollectionImages[newColl.id_!] = [];
      this.dirtyImageCollections[newColl.id_!] = newColl;
      this.dirtyActiveImageCollectionIdOrdering = true;
      this.setVisibleCollection(newColl.id_!);
    },
    updateImageCollectionLocal(payload: ImageCollection) {
      this.imageCollections[payload.id_!] = {...this.imageCollections[payload.id_!], ...payload};
      this.dirtyImageCollections[payload.id_!] = this.imageCollections[payload.id_!];
    },
    updateImageCollectionOrder(payload: { from: number, to: number }) {
      const { from, to } = payload;
      const newIds = [...this.activeImageCollectionIds];
      const [removed] = newIds.splice(from, 1);
      newIds.splice(to, 0, removed);
      this.setActiveCollectionIds(newIds);
      this.dirtyActiveImageCollectionIdOrdering = true;
    },
    deleteImageCollectionLocal(collectionId: string) {
      if (this.dirtyImageCollections[collectionId]) delete this.dirtyImageCollections[collectionId];
      if (this.imageCollections[collectionId]) delete this.imageCollections[collectionId];
      const newActiveIds = this.activeImageCollectionIds.filter((id) => id !== collectionId);
      this.setActiveCollectionIds(newActiveIds);
      if (this.visibleImageCollectionId === collectionId) {
        if (newActiveIds.length)
          this.setVisibleCollection(newActiveIds[0]);
        else 
          this.setVisibleCollection(null);
      }
      this.dirtyActiveImageCollectionIdOrdering = true;
    },
    changeImageOrderLocal(payload: { collectionId: string, from: number, to: number }) {
      // changes the position of one image within a collection
      const { collectionId, from, to } = payload;
      const collection = this.imageCollections[collectionId];
      if (!collection) return;
      const images = this.imageCollectionImages[collectionId];
      if (!images) return;
      const newImages = [...images];
      const [removed] = newImages.splice(from, 1);
      newImages.splice(to, 0, removed);
      this.imageCollectionImages[collectionId] = newImages;
      this.dirtyImageCollections[collectionId] = collection;
    },
    addImagesToImageCollectionLocal(payload: { collectionId?: string, imageIds: string[] }) {
      let { collectionId, imageIds } = payload;
      if (!collectionId) {
        // use the first active image collection. If there is no visible collection, create one locally.
        if (!this.nonFanartActiveImageCollectionIds?.length) { 
          this.createImageCollectionLocal({});
          collectionId = this.nonFanartActiveImageCollectionIds[0];
          this.setVisibleCollection(collectionId);
        } else if (this.visibleImageCollectionId) {
          collectionId = this.visibleImageCollectionId;
        } else {
          collectionId = this.nonFanartActiveImageCollectionIds[0];
          this.setVisibleCollection(collectionId);
        }
      }
      const collection = this.imageCollections[collectionId];
      if (!collection) {
        throw "Collection not found";
      }
      this.imageCollectionImages[collectionId] = [...(this.imageCollectionImages[collectionId] || []), ...imageIds];
      this.dirtyImageCollections[collectionId] = collection;
    },
    moveImageToCollectionLocal(payload: { imageId: string, from: string, to: string }) {
      // remove image from collection "from" and append to collection "to"
      const { imageId, from, to } = payload;
      if (from === to) return;
      const fromCollection = this.imageCollections[from];
      const toCollection = this.imageCollections[to];
      if (!fromCollection || !toCollection) {
        throw "Collection not found"; // TODO try fetching it?
      }
      const fromImages = this.imageCollectionImages[from];
      const toImages = this.imageCollectionImages[to];
      if (!fromImages) {
        throw "Origin album image list not found"; // TODO try fetching it?
      }
      if (!toImages) {
        throw "Destination album image list not found"; // TODO try fetching it?
      }
      const fromIndex = fromImages.indexOf(imageId);
      if (fromIndex === -1) {
        throw "Image not found in collection"; // TODO try fetching it?
      }
      const newFromImages = [...fromImages];
      newFromImages.splice(fromIndex, 1);
      const newToImages = [...toImages, imageId];
      this.imageCollectionImages[to] = newToImages;
      this.imageCollectionImages[from] = newFromImages;
      
      this.dirtyImageCollections[from] = fromCollection;
      this.dirtyImageCollections[to] = toCollection;
    },
    removeImageFromCollectionLocal(payload: { collectionId?: string | null, imageId: string }) {
      let { collectionId, imageId } = payload;
      if (!collectionId) collectionId = this.visibleImageCollectionId || null;
      if (!collectionId) return;
      const collection = this.imageCollections[collectionId];
      if (!collection) return;
      const images = this.imageCollectionImages[collectionId];
      if (!images) return;
      const newImages = images.filter((id) => id !== imageId);
      this.imageCollectionImages[collectionId] = newImages;
      this.dirtyImageCollections[collectionId] = collection;
      this.setRemovedImages({ ids: [imageId] });
    },
    setRemovedImages({ ids = [], urls = [], replace = false }: { ids?: string[]; urls?: string[]; replace?: boolean }) {
      this.removedImages = replace
        ? { ids, urls }
        : { ids: this.removedImages.ids.concat(ids), urls: this.removedImages.urls.concat(urls) };
    },
    async commitRemovedImages() {
      if (this.removedImages.ids.length || this.removedImages.urls.length) {
        await removeImages(this.removedImages);
      }
      this.removedImages = { ids: [], urls: [] };
    },
    replaceReactionLocal(payload:
    { 
      image: Image,
      reactResp: { 
        updatedReactionsData: {
          reaction_counts: any,
          user_reaction: any
        }
      }
    }) {
      const { image, reactResp } = payload;
      const i = this.images[image.id!] = image;
      i.user_reaction = reactResp.updatedReactionsData.user_reaction!;
      i.reaction_counts = reactResp.updatedReactionsData.reaction_counts!;
    },
    async commitAllChanges(parent: { id?: string, model?: string }) {
      const dirtyCollectionsToPatch = this.dirtyImageCollectionsArray.filter((coll) => coll.id);
      const dirtyCollectionsToCreate = this.dirtyImageCollectionsArray.filter((coll) => !coll.id);
      const payload = {
        images: {},
        collections: {},
        id: parent.id,
        object_type: parent.model,
      } as any;
      if (this.dirtyImagesArray?.length) {
        payload.images.update = this.dirtyImages;
      } if (this.dirtyActiveImageCollectionIdOrdering) {
        payload.collections.reorder = this.activeImageCollectionIds;
      } if (dirtyCollectionsToCreate?.length) {
        dirtyCollectionsToCreate.forEach((coll) => {
          coll.images = this.imageCollectionImages[coll.id_!] || [];
        });
        payload.collections.create = dirtyCollectionsToCreate;
      } if (dirtyCollectionsToPatch?.length) {
        dirtyCollectionsToPatch.forEach((coll) => {
          coll.images = this.imageCollectionImages[coll.id_!] || [];
        });
        payload.collections.update = {};
        dirtyCollectionsToPatch.forEach((coll) => {
          payload.collections.update[coll.id!] = coll;
        });
      }
     
      const data = await handleObjectImageUpdates(payload);
      /*
      data = {
        images: {
          updated: {id: Image, id: Image, ...}
        },
        collections: {
          created: [ImageCollection, ImageCollection, ...],
          updated: { id: ImageCollection, id: ImageCollection, ... },
          reordered: [id1, id2, id3, ...]
        }
      }
      */
      const imagesUpdated = data.images.updated;
      if (imagesUpdated) {
        // turn imagesUpdated from { k:v, ... } to [v, v, v]
        const images = (Object.values(imagesUpdated) || []) as Image[];
        this.setImages(images);
      }
      this.dirtyImages = {};

      let idsObsolete = []
      if (data.collections.created?.length) {
        /*
        activeImageCollectionIds = [id1, id2_, id3]
        incoming response = [id1, id2, id3]
        replace imageCollections[1] with data.created[1], matching index
        do this for all elements in activeImageCollections that have id_ instead of id
        */
        data.collections.created.forEach((coll: ImageCollection) => {
          this.imageCollections[coll.id!] = coll;
          this.imageCollectionImages[coll.id!] = coll.images_page?.results.map(x=>x.id!) || [];
        });
        if (this.visibleImageCollectionId) {
          const payloadOutCollectionIds = payload.collections.create.map((x:ImageCollection) => x.id_);
          if (payloadOutCollectionIds.includes(this.visibleImageCollectionId)) {
            this.visibleImageCollectionId = data.collections.created[payloadOutCollectionIds.indexOf(this.visibleImageCollectionId)].id;
          }
        }
        // this assumes all ImageCollection creations succeed - is this going to be true? Make the save function return 4xx on failure
        const toCreateIds = payload.collections.create.map((i: ImageCollection) => i.id_);
        const createdIds = data.collections.created.map((i: ImageCollection) => i.id);
        idsObsolete = toCreateIds.filter((id: string) => !createdIds.includes(id));
        idsObsolete.forEach((id: string) => {
          delete this.imageCollections[id];
        });
      }
      if (data.collections.updated) {
        Object.keys(data.collections.updated).forEach((id) => {
          this.imageCollections[id] = data.collections.updated[id];
        });
      }

      if (data.collections.reordered)
        this.setActiveCollectionIds(data.collections.reordered);

      this.dirtyImageCollections = {};
      this.dirtyActiveImageCollectionIdOrdering = false;

      // remove deleted images
      await this.commitRemovedImages();


      // return ordered ImageCollection IDs
      return this.activeImageCollectionIds;
    },
    async setWatermarkImages(payload: { imageIds: string[], watermarking: boolean }) {
      // watermarking is true when watermark is being added; false when being removed
      const { imageIds, watermarking } = payload;
      this.isWatermarkLoading = true;
      try {
        this.watermarkLoadText = watermarking
          ? 'Hubert is painting on a watermark. Give it a few seconds!'
          : 'Hubert is removing the watermark. Give it a few seconds!';
        const resp = await bulkUpdateWatermarks({ image_ids: imageIds, is_watermarked: watermarking });
        resp.forEach((img: any) => {
          this.images[img.id!] = img;
        });
        toast.show(
          watermarking ? 'Watermark added!' : 'Watermark removed!',
          'nonative',
          watermarking ? 'success' : 'secondary'
        );
      } catch (e) {
        logger.error({
          e,
          msg: 'Encountered an error while editing image watermarks',
          loc: 'image-store.setWatermarkImages',
          data: { imageIds, watermarking },
        });
      } finally {
        this.watermarkLoadText = '';
        this.isWatermarkLoading = false;
      }
    },
    async changeReaction(imageId: string, reaction: string) {
      if (!imageId) return;
      const image = this.imageById(imageId);
      const { newReaction, updatedReactionsData } = await reactImage({
        reaction,
        image,
        fromRoulette: false
      });
      image.user_reaction = newReaction;
      image.reaction_counts = updatedReactionsData.reaction_counts;
      this.setImages([image]);
    },
    openViewImageModal(payload: { imageId: string, collectionId: string }) {
      if (!payload.imageId || !payload.collectionId) return;
      this.visibleImageCollectionId = payload.collectionId;
      this.modalImageIndex = this.imageIdsInCollection(payload.collectionId)?.indexOf(payload.imageId) || 0;
      this.viewImageModalOpen = true;
    },
    closeViewImageModal() {
      this.modalImageIndex = 0;
      this.viewImageModalOpen = false;
    },
    incrementModalVisibleImageIndex(increment: number) {
      this.modalImageIndex += increment
    },
    setModalVisibleImageIndex(index: number) {
      this.modalImageIndex = index;
    }
  },
});

export const imageStore = () => {
  const store = useImageStore();
  return {
    ...store,
    ...storeToRefs(store),
  };
};