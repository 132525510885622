<template>
  <div
    class="world-card"
    :class="{ 'reorder-handle': isReorderable, 'image-only': imageOnly, 'd-flex': !forFeed, vertical }"
    @contextmenu="handleContext"
    :style="forFeed ? 'height: 290px' : ''"
  >
    <div class="w-100 h-100 position-relative">
      <ion-button
        v-if="!isReorderable && isRemovable"
        class="position-absolute remove-selection-btn"
        fill="clear"
        @click="remove"
        ><i class="ti-close"
      /></ion-button>
      <a class="a-wrap w-100 h-100" @click.prevent="goToWorld" :href="worldHref">
        <div class="w-100 h-100" @click="toggleSelection">
          <div class="image d-flex align-items-center position-absolute">
            <img
              loading="lazy"
              v-world-card-image
              :src="resizeUpload(world.cropped_profile_img || world.cover_image, '380h')"
            />
          </div>
          <div
            v-if="!imageOnly"
            class="world-info h-100 w-100 d-flex position-relative"
            :class="{ 'justify-content-between': !forFeed, 'flex-column': forFeed, vertical }"
          >
            <div>
              <div class="tag d-flex align-items-center justify-content-center">
                {{
                  ['updated_story_world_text', 'updated_world_profile_img'].includes(feed.action)
                    ? 'Story World Update'
                    : feed.action === 'created_story_world'
                    ? 'New Story World'
                    : isDraft
                    ? 'Draft Story World'
                    : 'Story World'
                }}
              </div>
              <div
                v-if="worldPrivacy === 'M' || worldPrivacy === 'U'"
                class="tag sm mt-1 d-flex align-items-center justify-content-center"
              >
                <span v-if="worldPrivacy === 'M'">🔒&nbsp;Private</span>
                <span v-else-if="worldPrivacy === 'U'">🔗&nbsp;Unlisted</span>
              </div>
              <MatureTag class="mt-1" v-if="isNsfw" />
            </div>

            <ion-checkbox
              v-if="isSelectable && isOwner"
              :disabled="true"
              class="select position-absolute"
              mode="ios"
              :checked="isSelected"
            />
            <div
              class="world-details d-flex flex-column"
              :class="{ 'mt-auto': forFeed }"
              :style="forFeed ? 'width: 100%; font-size: 12px' : ''"
            >
              <div
                class="w-100"
                :class="
                  ['updated_story_world_text', 'updated_world_profile_img'].includes(feed.action)
                    ? forFeed
                      ? 'title-update mb-4'
                      : 'title-update'
                    : 'd-flex flex-row title'
                "
              >
                <router-link class="routerlink clickable" :to="goToWorldLink">
                  <span v-if="['updated_story_world_text', 'updated_world_profile_img'].includes(feed.action)">
                    <span style="color: #8ce53b">
                      {{ text }}
                    </span>
                    update on
                  </span>
                  <span
                    :style="
                      ['updated_story_world_text', 'updated_world_profile_img'].includes(feed.action)
                        ? 'text-decoration: underline; color: #8ce53b'
                        : ''
                    "
                  >
                    <span v-if="worldPrivacy === 'M'">🔒&nbsp;</span><span v-if="worldPrivacy === 'U'">🔗&nbsp;</span>
                    {{ world.name }}
                  </span>
                </router-link>
                <span v-if="['updated_story_world_text', 'updated_world_profile_img'].includes(feed.action)"
                  >! Go check it out!
                </span>
                <ion-badge id="click-trigger" color="danger" class="count">
                  {{ worldUnreadCount > 0 ? worldUnreadCount : null }}
                </ion-badge>
              </div>

              <div
                v-if="['updated_story_world_text', 'updated_world_profile_img'].includes(feed.action)"
                class="description mt-1"
              ></div>
              <div
                v-if="!['updated_story_world_text', 'updated_world_profile_img'].includes(feed.action)"
                class="mt-1"
                :class="{ description: !forFeed }"
              >
                {{ truncate(stripHtmlTags(world?.description || ''), { length: 100 }) }}
              </div>
              <div class="mt-auto" style="font-size: 12px">
                <a
                  class="clickable username d-flex flex-wrap align-items-center"
                  :href="userHref"
                  @click.prevent.stop="goToUser(username)"
                  :class="{
                    'text-color-username': usernameColor && !usernameColor.startsWith('#'),
                    'bold-username': usernameColor,
                  }"
                  v-if="username"
                  :style="
                    !usernameColor
                      ? ''
                      : usernameColor.startsWith('#')
                      ? `color: ${usernameColor} !important`
                      : `background-image: ${usernameColor} !important`
                  "
                >
                  <div class="mr-1">@{{ truncateText(username, 8) }}</div>
                  <ProfileBadge :badges="badges" :show-small-badge="true" />
                </a>
              </div>
              <div v-if="!limited" class="actions mt-auto mb-n1 ml-n1">
                <div class="actions-inner d-flex flexp-wrap align-items-center justify-content-between">
                  <Reaction
                    :class="{ disabled: isDraft }"
                    @click.stop
                    :reactions="mutatedReactionsData?.reaction_counts"
                    :showBox="get(world, 'showBox')"
                    @updated="emits('updated')"
                    @delete="deleteCurrGiftBox"
                    :progressId="get(world, 'progressId')"
                    :user-reaction="mutatedReactionsData?.user_reaction"
                    :right-indent-popup="true"
                    type="world"
                    @changed="(reaction) => reactionChanged(reaction.key, reaction.isInstant)"
                  >
                    <ion-button color="transparent" class="inline-button icon-button clickable d-file" @click.prevent>
                      <div
                        class="d-flex align-items-center justify-content-center"
                        :class="{ 'text-secondary': !!mutatedReactionsData?.user_reaction?.reaction }"
                      >
                        <i
                          class="ti-thumb-up text-primary mr-1"
                          :class="{ 'text-secondary': !!mutatedReactionsData?.user_reaction?.reaction }"
                        />
                        <span class="reaction-count">{{
                          mutatedReactionsData?.reaction_counts?.total_count || 0
                        }}</span>
                      </div>
                    </ion-button>
                  </Reaction>
                  <div
                    v-if="!isSelectable && isOwner && !limited"
                    class="clickable drop-down d-flex align-items-center justify-content-center"
                    @click.stop.prevent="openDropDown"
                  >
                    <i class="ti-more-alt" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { WorldsInfo } from '@/shared/types/static-types';
import MatureTag from '@/shared/components/MatureTag.vue';
import { react } from '@/shared/helpers/worlds';
import { getWorldsMemberCounts, getWorldsUnreadCounts } from '@/shared/actions/worlds';
import Reaction from '@/shared/components/Reaction/index.vue';
import { alertController } from '@ionic/vue';
import { toast } from '@/shared/native';
import CardPopover from './popovers/CardPopover.vue';
import { popovers } from '@/shared/native/popovers';
import { authStore } from '@/shared/pinia-store/auth';
import { deleteWorld, deleteWorldDraft } from '@/shared/actions/worlds';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { resizeUpload } from '@/shared/utils/upload';
import { stripHtmlTags } from '@/shared/utils/string';
import { truncateText } from '@/shared/utils/string';
import { isMobileFn } from '@/apps/mobile/native/device';

const props = defineProps({
  world: {
    type: Object,
    default: () => ({}),
  },
  lazyUserReaction: {
    type: Boolean,
    default: false,
  },
  isSelected: {
    type: Boolean,
  },
  isSelectable: {
    type: Boolean,
  },
  isRemovable: {
    type: Boolean,
  },
  isReorderable: {
    type: Boolean,
  },
  limited: {
    type: Boolean,
  },
  feed: {
    type: Object,
    default: () => ({}),
  },
  imageOnly: {
    type: Boolean,
  },
  forFeed: {
    type: Boolean,
  },
  vertical: {
    type: Boolean,
  },
  openBlank: {
    type: Boolean,
    default: false,
  },
  reactedStory: {
    type: Object,
    default: null,
  },
});
const router = useRouter();
const username = computed(() => world.value.user?.username || world.value.author?.username);
const isDraft = computed(() => world.value && !world.value.slug);
const id = computed(() => world?.value?.id);
const reactedStory = toRef(props, 'reactedStory');
const remove = (e: any) => {
  e.preventDefault();

  const data = {
    detail: {
      checked: !isSelected.value,
    },
  } as CustomEvent;

  select(data);
};

const world = computed(() => props.world as WorldsInfo);
const isSelected = computed(() => props.isSelected as boolean);
const isRemovable = computed(() => props.isRemovable as boolean);
const isSelectable = computed(() => props.isSelectable as boolean);
const openBlank = computed(() => props.openBlank as boolean);
const imageOnly = computed(() => props.imageOnly as boolean);
const limited = computed(() => props.limited as boolean);
const worldUnreadCount = ref(0);
const memberCount = ref(0);
const feed = toRef(props, 'feed');
const isNsfw = computed(() => world.value.is_nsfw);
const route = useRoute();
const usernameColor = computed(() => {
  return world.value?.user?.customize_profile?.username_color || '';
});

const lazyUserReaction = toRef(props, 'lazyUserReaction');
const worldPrivacy = computed(() => world.value.privacy);

const isReorderable = computed(() => props.isReorderable as boolean);
const handleContext = (e: any) => {
  if (props.isReorderable) return e.preventDefault();
};

const doDelete = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to delete this world.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Delete',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      if (id.value) {
        if (isDraft.value) await deleteWorldDraft(id.value);
        else await deleteWorld(id.value);
        location.reload();
      }
      toast.show('Story World deleted successfully', 'nonative', 'success');
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};

const deleteCurrGiftBox = () => {
  emits('delete', id.value);
};

const openDropDown = (ev: CustomEvent) => {
  popovers.open(ev, CardPopover, {
    title: isDraft.value ? 'Story World Draft' : 'Story World',
    id: id.value,
    value: 'world',
    isDraft: isDraft.value,
  });
};
const reactionCount = ref(world.value.reaction_counts);

const mutatedReactionsData = lazyUserReaction.value
  ? ref({
      reaction_counts: reactionCount.value,
      user_reaction: reactedStory.value || null,
    })
  : ref(pick(world.value, ['reaction_counts', 'user_reaction']));

watch([reactionCount, reactedStory], () => {
  if (lazyUserReaction.value) {
    mutatedReactionsData.value = {
      reaction_counts: reactionCount.value,
      user_reaction: reactedStory.value,
    };
  }
});

const worldHref = computed(() => {
  const router = useRouter();
  const { href } = router.resolve(goToWorldLink.value);

  return href;
});

const userHref = computed(() => {
  const router = useRouter();
  const route = {
    name: 'profile',
    params: { username: username.value },
  };
  const { href } = router.resolve(route);

  return href;
});

const goToUser = async (username: string) => {
  if (limited.value || imageOnly.value || isSelectable.value || isRemovable.value || isReorderable.value) return;

  const router = useRouter();

  const route = {
    name: 'profile',
    params: { username },
  };

  const isMobile = await isMobileFn();

  if (openBlank.value && !isMobile) {
    const { href } = router.resolve(route);
    window.open(href, '_blank');
    return;
  }

  router.push(route);
};

const goToWorld = async () => {
  if (limited.value || imageOnly.value || isSelectable.value || isRemovable.value || isReorderable.value) return;

  const router = useRouter();
  const isMobile = await isMobileFn();

  if (openBlank.value && !isMobile) {
    const { href } = router.resolve(goToWorldLink.value);
    window.open(href, '_blank');
    return;
  }

  router.push(goToWorldLink.value);
};

const { user } = authStore();

const emits = defineEmits(['select', 'delete', 'updated']);

const badges = computed(() => {
  return get(world.value?.user?.customize_profile, 'badges') || [];
});
const reactionChanged = async (reaction: string, isInstant = false) => {
  const reactionResp = await react(
    reaction,
    world.value,
    mutatedReactionsData.value.user_reaction,
    mutatedReactionsData.value.reaction_counts,
    isInstant
  );

  mutatedReactionsData.value = {
    reaction_counts: reactionResp.reaction_counts,
    user_reaction: reactionResp.user_reaction,
  };
};

const isOwner = computed(() => {
  try {
    return world.value.user?.id === user.value.id || world.value.user === user.value.id;
  } catch (error) {
    return false;
  }
});

const goToWorldLink = computed(() => {
  const name = isDraft.value ? 'draft-story-world' : 'world-details';
  const params = isDraft.value ? { id: world.value.id } : { slug: world.value.slug };

  return { name, params };
});

const fetchWorldsMemberCounts = async () => {
  const resp = await getWorldsMemberCounts([world.value.id || '']);

  memberCount.value = get(resp, world.value.id || '');
};

const fetchWorldUnreadCounts = async () => {
  const resp = await getWorldsUnreadCounts([world.value.id || '']);

  worldUnreadCount.value = get(resp, world.value.id || '');
};

const text = computed(() => {
  if (feed.value.action === 'created_story_world') return;
  return `${
    feed.value.action === 'updated_story_world_text'
      ? 'Key Info'
      : feed.value.action === 'updated_world_profile_img'
      ? 'Profile Image'
      : ''
  }`;
});

const toggleSelection = (e: any) => {
  if (!isSelectable.value) {
    return;
  }

  e.preventDefault();

  const data = {
    detail: {
      checked: !isSelected.value,
    },
  } as CustomEvent;

  select(data);
};

const select = (e: CustomEvent) => {
  emits('select', e.detail.checked);
};

onMounted(() => {
  if (route.name !== 'home') {
    fetchWorldsMemberCounts();
    fetchWorldUnreadCounts();
  }
});
</script>

<style lang="sass" scoped>
.drop-down
  width: 20px
  height: 20px
  color: #FFF
  background: #0A0928E5
  font-size: 12px
  border-radius: 6px
.select
  bottom: 5px
  z-index: 2
.actions
  *
    color: #FFF !important
    font-size: 12px !important
.world-details
  background: rgba(28, 11, 57, 0.85)
  width: 50%
  max-width: 200px
  border-radius: 12px
  padding: 10px
  *
    color: #FFF
  .title
    font-size: 16px
    max-height: 40px
    font-weight: bold
    a
      text-overflow: ellipsis
      width: 100%
      display: -webkit-box
      -webkit-line-clamp: 2
      -webkit-box-orient: vertical
      overflow: hidden

  .title-update
    font-size: 16px
    max-height: 40px
    font-weight: bold
    // a
    //   text-overflow: ellipsis
    //   width: 100%
    //   display: -webkit-box
    //   -webkit-line-clamp: 2
    //   -webkit-box-orient: vertical
    //   overflow: hidden
  .description
    font-size: 12px
    text-overflow: ellipsis
    width: 100%
    height: calc(100% - 75px) !important
    display: -webkit-box !important
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    overflow: hidden
    margin-bottom: 5px
.world-info
  position: relative
  z-index: 11
  padding: 6px
  &.vertical
    flex-direction: column
    .world-details
      min-height: 45%
      width: 100%
      max-height: 60%

.username
  font-weight: bold
  color: rgba(255, 222, 103, 1)
.tag
  max-width: 120px
  min-width: 120px
  padding-left: 5px
  padding-right: 5px
  height: 20px
  border: 1.5px solid #8CE53B
  color: #8CE53B
  background: #0A0928E5
  font-size: 12px
  font-weight: bold
  border-radius: 10px
  &.sm
    max-width: 75px
    min-width: 75px

.remove-selection-btn
  z-index: 22
  background: rgba(0,0,0,0.5) !important
  pointer-events: all
  --width: 25px
  --height: 25px
  --padding-start: 8px !important
  --padding-end: 8px !important
  border-radius: 20px
  color: #FFF !important
  min-width: none
  right: -10px
  top: -10px
  overflow: hidden
.world-card
  height: 191px
  box-shadow: 1px 1px 5px 0px rgba(33,65,99,0.19)
  border-radius: 12px
  background: #FFF
  cursor: pointer
  &.vertical
    height: 270px
  &.image-only
    width: 60px
    height: 60px
    img,.char-info,.image
      border-radius: 6px !important
    .world-small-img
      width: 30px !important
      height: 30px !important
      object-fit: unset !important
  ::v-deep
    .checkbox-disabled
      opacity: 1 !important
  .image
    border-radius: 12px
    overflow: hidden
    width: 100%
    height: 100%
    background: #F178B9
    img
      width: 100%
      height: 100%
      object-fit: cover
.text-secondary
  *
    color: #3dc2ff !important
</style>
