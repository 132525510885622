import { Module } from 'vuex';
import { RootState } from '../../index';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { BlabsState } from './types';

const state: BlabsState = {
  inActionBlab: {},
  trendingBlabs: [],
  trendingBlabsPage: 0,
  trendingBlabsPaging: {},
  topBlabs: [],
  topBlabsPage: 0,
  topBlabsPaging: {},
  latestBlabs: [],
  latestBlabsPage: 0,
  latestBlabsPaging: {},
  artBlabs: [],
  artBlabsPage: 0,
  artBlabsPaging: {},
  storiesBlabs: [],
  storiesBlabsPage: 0,
  storiesBlabsPaging: {},
  isSunshine: false,
  sunshineId: null,
};

export const BlabsModule: Module<BlabsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
