<template>
  <ion-modal
    class="modal-with-content"
    :is-open="isOpen"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="close"
  >
    <ion-content no-bounce>
      <ion-button class="clickable-item-hov top-right" color="transparent" @click.prevent="close">
        <i class="ti-close" color="medium" />
      </ion-button>
      <h2 class="text-center">Add to Bookmarks</h2>
      <div class="collections-container">
        <div v-for="(c, index) in collections" :key="c.id" class="coll-row">
          <input
            :id="c.id"
            type="checkbox"
            class="mr-1"
            :disabled="working"
            :checked="c.context_bookmarked"
            @change="toggleBookmark($event, index)"
          />
          <label class="label" :for="c.id">{{ c.name }}</label>
        </div>
        <p v-if="paging.next" class="clickable-item-hov text-center" @click="nextPage">Load More</p>
        <span class="d-inline-block small mt-2 clickable-item-hov" @click="makeNewCollection"
          >Start another bookmark list</span
        >
      </div>
      <ion-button color="dark" class="close-bottom" @click="close">Close</ion-button>
    </ion-content>
  </ion-modal>
</template>

<script lang="ts" setup>
import { BookmarkCollection, Paging } from '@/shared/types/static-types';
import { getBookmarkCollections, bookmark, unbookmark, createBookmarkCollection } from '@/shared/actions/bookmarks';
import { getNextPage } from '@/shared/helpers/pagination';
import constants from '@/shared/statics/constants';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
const { isAuthenticated } = authStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  objectId: {
    type: String,
    required: true,
  },
  contentType: {
    type: String,
    required: true,
    validator: (prop: string) => constants.bookmarkableTypes.includes(prop),
  },
});

const emits = defineEmits(['close', 'changeStatus']);
const collections = ref([]) as Ref<BookmarkCollection[]>;
const working = ref(false) as Ref<boolean>;
const paging = ref({
  next: null,
  previous: null,
  count: 0,
  page: 1,
}) as Ref<{ next: string | null; previous: string | null; count: number; page: number }>;

const close = () => {
  emits('close');
};

const changeStatus = (isBookmarked: boolean) => {
  emits('changeStatus', isBookmarked);
};

const fetchBookmarkCollections = async () => {
  if (!isAuthenticated.value) return;
  const data = await getBookmarkCollections(1, props.objectId);
  collections.value = data.results;
  paging.value = data;
};

const nextPage = async () => {
  const data = await getNextPage(paging.value as Paging);
  collections.value = [...collections.value, ...data.results];
  paging.value = data;
};

watch(
  () => props.objectId,
  (newVal: string) => {
    if (newVal) fetchBookmarkCollections();
  }
);

onMounted(() => {
  if (props.objectId) fetchBookmarkCollections();
});

const toggleBookmark = async (_: any, index: number) => {
  working.value = true;
  const bookmarkCollection: BookmarkCollection = collections.value[index];
  const isBookmarking = !bookmarkCollection.context_bookmarked; // if true, bookmark; if false, unbookmark
  bookmarkCollection.context_bookmarked = !bookmarkCollection.context_bookmarked;
  try {
    if (isBookmarking) {
      await bookmark({
        collection: bookmarkCollection.id,
        id: props.objectId,
        type: props.contentType,
      });
      changeStatus(true);
    } else {
      const count = await unbookmark({ collection: bookmarkCollection.id, id: props.objectId });
      if (count === 0) {
        changeStatus(false);
      } else {
        changeStatus(true);
      }
    }
  } catch (e) {
    // Do nothing
    bookmarkCollection.context_bookmarked = !bookmarkCollection.context_bookmarked;
  } finally {
    working.value = false;
  }
  working.value = false;
};

const makeNewCollection = async () => {
  working.value = true;
  try {
    const newCollection = await createBookmarkCollection({ name: 'Bookmarks' });
    collections.value = [...collections.value, newCollection];
  } catch (e) {
    await toast.show(`Error creating a new bookmark collection, please try again.`, 'nonative', 'danger');
  } finally {
    working.value = false;
  }
};
</script>

<style lang="sass" scoped>
.panel
  font-size: 22px
  border-bottom: 2px solid rgba(33, 65, 99, .2)
  padding-bottom: 6px
  font-weight: bold
  margin: 17px 0
.badge-modal-container
  background-color: white
  width: 100%
.collection-img
  width: 80px
  height: 80px
  border-radius: 8px
  object-fit: cover
  margin-bottom: 20px
.top-right
  color: black
  position: absolute
  top: 0.5rem
  right: 0.5rem
.clickable-item-hov
  background: none
.close-bottom
  position: absolute
  bottom: 0.5rem
  width: 200px
  left: 0
  right: 0
  margin-left: auto
  margin-right: auto
.coll-row
  padding: 0.25rem
  margin: 0.5rem
.collections-container
  margin: 0 auto
  padding: 0 20%
  overflow-y: scroll
  max-height: 70%
</style>
