import { createStore } from 'vuex';
import { UsersModule } from './modules/users-module';
import { CharactersModule } from './modules/characters-module';
import { NotificationsModule } from './modules/notifications-module';
import { RolechatModule } from './modules/rolechat-module';
import { ListingModule } from './modules/listing-module';
import { WorldsModule } from './modules/worlds-module';
import { BlabsModule } from './modules/blabs-module';
import { FanworksModule } from './modules/fanworks-module';
import { ImagesModule } from './modules/images-module';
import { SiteEventModule } from './modules/events-module';

const store = createStore({
  modules: {
    UsersModule,
    CharactersModule,
    NotificationsModule,
    RolechatModule,
    ListingModule,
    WorldsModule,
    BlabsModule,
    FanworksModule,
    ImagesModule,
    SiteEventModule
  },
});

export interface RootState {}
export default store;
