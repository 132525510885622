import { ActionTree } from 'vuex';
import { FanWorksState } from './types';
import { RootState } from '@/shared/store';

import { hasUnopenedFanWork } from '@/shared/actions/fanwork';

export const actions: ActionTree<FanWorksState, RootState> = {
  async getUnopenedFanWork({ commit }) {
    const resp = await hasUnopenedFanWork();
    commit('SET_UNOPENED_FANWORK', {
      hasUnopenedFanWork: get(resp, 'unopened_fanwork_count', ''),
    });
  },
};
