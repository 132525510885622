<template>
  <div @click="openGiftModal">
    <slot />
    <gift-compliment-bomb-modal
      :is-open="isOpenGiftComplimentBombModal"
      :gift-inventory="giftInventory"
      :inventory="inventory"
      :show-footer="true"
      modal-title="Gift Compliment Bomb"
      @open="openSelectionModal"
      @close="isOpenGiftComplimentBombModal = false"
    />
    <compliment-bomb-user-gift-selection-modal
      :is-open="isOpenUserGiftSelectionModal"
      :selected-gift="selectedGift"
      @updated="fetchInventorydetails"
      @close="isOpenUserGiftSelectionModal = false"
      @dismiss="closeSelectionModal"
    />

    <ComplimentBombsSelectedAndGiftedModal
      :is-open="isOpengiftModal"
      :modal-title="giftModalTitle"
      :section-title="giftSectionTitle"
      :section-description="`The user should receive a notification in the next few minutes. Way to go!`"
      :svg-icon="'/compliment-heart.svg'"
      :button-text="'Done'"
      @close="closeGiftedModal"
    />
  </div>
</template>

<script lang="ts" setup>
import ComplimentBombUserGiftSelectionModal from '@/shared/modals/ComplimentBombUserGiftSelectionModal.vue';
import ComplimentBombsSelectedAndGiftedModal from '@/shared/modals/ComplimentBombsSelectedAndGiftedModal.vue';
import GiftComplimentBombModal from '@/shared/modals/GiftComplimentBombModal.vue';

const props = defineProps({
  giftInventory: { type: Object, default: {} },
  inventory: { type: Object, default: {} },
});
const selectedGift = ref({});
const tempInvType = ref('');
const giftModalTitle = ref('Gift Successfully Sent');
const giftSectionTitle = ref('YOUR GIFT WAS SENT');
const emits = defineEmits(['updated']);
const isOpenUserGiftSelectionModal = ref(false);
const isOpengiftModal = ref(false);
const isOpenGiftComplimentBombModal = ref(false);

const fetchInventorydetails = (val: any) => {
  tempInvType.value = val;
  isOpengiftModal.value = true;
};

const openSelectionModal = (val: any) => {
  selectedGift.value = val;
  isOpenUserGiftSelectionModal.value = true;
};
const openGiftModal = () => {
  isOpenGiftComplimentBombModal.value = true;
};
const closeSelectionModal = (val: any) => {
  if (get(val, 'detail.role') === 'backdrop') {
    isOpenUserGiftSelectionModal.value = false;
  }
};

const closeGiftedModal = () => {
  isOpengiftModal.value = false;
  isOpenUserGiftSelectionModal.value = false;
  isOpenGiftComplimentBombModal.value = false;
  emits('updated', tempInvType.value);
};
</script>

<style lang="sass" scoped></style>
