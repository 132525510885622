import { chAxios } from '@/shared/lib/axios';
import { sendAnalyticsEvent } from '@/shared/services/analytics';

export async function createFanwork(payload: {
  submitted_for_id?: string;
  submitted_for_type: string;
  type?: string;
  image_url?: string;
  note?: string;
  receiver?: string;
  other_title?: string | null | undefined;
  other_content?: string | null | undefined;
}) {
  try {
    const { data } = await chAxios().post(`/fanworks/`, payload);
    sendAnalyticsEvent('Submitted FanWork');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getFanWorks(submissionType = 'receiver', params = {}) {
  const url = `/fanworks/`;
  try {
    const { data } = await chAxios().get(url, {
      params: { submission_type: submissionType, ...params },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function fetchSubmitter(page = 1) {
  const url = `/fanworks/unique-receiver/`;
  try {
    const { data } = await chAxios().get(url, { params: { page } });
    return data;
  } catch (e) {
    throw e;
  }
}
export async function updateFanWorkStatus(id: string, payload = {}) {
  try {
    const url = `/fanworks/${id}/mark-open/`;
    const { data } = await chAxios().patch(url, payload);
    return data;
  } catch (e) {
    throw e;
  }
}
export async function updateFeatureFanWorkGallery(id: string, payload = {}) {
  try {
    const url = `/fanworks/${id}/feature/`;
    const { data } = await chAxios().patch(url, payload);
    sendAnalyticsEvent('featured fanart');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateFanWorkGallery(id: string, payload = {}) {
  try {
    const url = `/fanworks/${id}/unfeature/`;
    const { data } = await chAxios().patch(url, payload);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getFanWorkDetails(id: string) {
  try {
    const { data } = await chAxios().get(`/fanworks/${id}/`);
    sendAnalyticsEvent('opened fanwork');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createFeedBack(id: string, params = {}) {
  try {
    const { data } = await chAxios().post(`/fanworks/${id}/feedback/`, { ...params });
    sendAnalyticsEvent('reacted to fanwork');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function hasUnopenedFanWork() {
  try {
    const { data } = await chAxios().get(`/fanworks/unopened-count/`);
    return data;
  } catch (e) {
    throw e;
  }
}
