import { characterCardImage } from '../shared/directives/character-card-image';
import { imageResizer } from '../shared/directives/image-resizer';
import { userImage } from '../shared/directives/user-image';
import { characterImage } from '@/shared/directives/character-image';
import { fanArtImage } from '@/shared/directives/fan-art-image';
import { templateImage } from '@/shared/directives/template-image';
import { image } from '@/shared/directives/image';
import { folderCardImage } from '@/shared/directives/folder-card-image';
import { worldCardImage } from '@/shared/directives/world-card-image';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('image', {
    beforeMount: image,
    updated: image,
  });

  nuxtApp.vueApp.directive('character-image', {
    beforeMount: characterImage,
    updated: characterImage,
  });

  nuxtApp.vueApp.directive('fan-art-image', {
    beforeMount: fanArtImage,
    updated: fanArtImage,
  });

  nuxtApp.vueApp.directive('user-image', {
    beforeMount: userImage,
    updated: userImage,
  });

  nuxtApp.vueApp.directive('template-image', {
    beforeMount: templateImage,
    updated: templateImage,
  });

  nuxtApp.vueApp.directive('image-resizer', {
    beforeMount: imageResizer,
    updated: imageResizer,
  });

  nuxtApp.vueApp.directive('character-card-image', {
    beforeMount: characterCardImage,
    updated: characterCardImage,
  });

  nuxtApp.vueApp.directive('folder-card-image', {
    beforeMount: folderCardImage,
    updated: folderCardImage,
  });

  nuxtApp.vueApp.directive('world-card-image', {
    beforeMount: worldCardImage,
    updated: worldCardImage,
  });
});
