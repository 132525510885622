import { GetterTree } from 'vuex';
import { ListingState } from './types';
import { RootState } from '@/shared/store';

export const getters: GetterTree<ListingState, RootState> = {
  inActionList(state: ListingState) {
    return state.inActionList;
  },
  marketplaceListings(state: ListingState) {
    return state.marketplaceListings;
  },

  MarketplaceListingsPaging(state: ListingState) {
    return state.marketplaceListingsPaging;
  },
};
