<template>
  <div class="d-flex align-items-center mx-auto" :class="hubuxCoinsSection">
    <inline-svg src="/hubucks_pack_small.svg" class="svg mr-1" />
    <div class="d-flex align-items-center">
      <div class="small-pre-count" v-if="!isFreeSubscription">
        x{{ get(user, 'subscription_level') === subscriptionLevels.plus ? 2 : 3 }}:
      </div>
      <div
        class="mr-1"
        :class="isFreeSubscription ? 'coin-text' : 'pre-coin-text'"
        :style="
          get(currency, 'selected_tier') === tiers.lowTier && isFreeSubscription && isDark
            ? '-webkit-text-fill-color: #ffffff;-webkit-text-stroke: 1px  #431c89'
            : ''
        "
      >
        {{ get(currency, 'coins', 0) }}
      </div>
      <span class="mt-1" :class="isFreeSubscription ? 'hubux-text' : 'pre-hubux-text'" :style="{ color: hubuxTextClr }"
        >Hubux</span
      >

      <div class="d-flex sub-badge" v-if="showBadge">
        <SubPlanBadge class="ml-1" v-for="(_, index) of [1, 2]" :key="index" :plan="subLevelMap[index + 1]" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import constants from '@/shared/statics/constants';
import SubPlanBadge from '@/shared/components/SubPlanBadge.vue';
import { mainStore } from '@/shared/pinia-store/main';

const { user } = authStore();
const props = defineProps({
  currency: {
    type: Object,
  },
  showBadge: {
    type: Boolean,
    default: false,
  },
});
const subLevelMap = constants.subscriptionLevelsTextMap;
const subscriptionLevels = constants.subscriptionLevels;
const tiers = constants.tiers;
const currency = toRef(props, 'currency');
const { dark: isDark } = mainStore();
const isFreeSubscription = computed(() => {
  return get(user.value, 'subscription_level') === subscriptionLevels.free;
});

const hubuxTextClr = computed(() => {
  return get(currency.value, 'selected_tier') === tiers.lowTier && isFreeSubscription.value && isDark.value
    ? 'white'
    : '';
});

const hubuxCoinsSection = computed(() => {
  const freeSubClass = 'py-2 px-3 free-hubuck-border';
  const selectedTier = get(currency.value, 'selected_tier');
  if (isFreeSubscription.value) {
    const tierClass =
      selectedTier === tiers.midTier ? ' mid-tier-bg' : selectedTier === tiers.highTier ? ' high-tier-bg' : '';
    return `${freeSubClass}${tierClass}`;
  } else return 'p-2 w-75 position-relative hubuck-border';
});
const hubuxBg = computed(() => {
  const defaultClr = isDark.value ? '#010123' : '#fff';
  return defaultClr;
});
</script>

<style lang="sass" scoped>
.mid-tier-bg
  background: linear-gradient(180deg, rgba(255, 252, 234, 0) 0%, #FFFCEA 100%), linear-gradient(0deg, #FFE4B4, #FFE4B4)
.high-tier-bg
  background: linear-gradient(180deg, #FFDFC8 0%, rgba(255, 223, 200, 0) 100%), linear-gradient(0deg, #EABEFF, #EABEFF)
.pre-hubux-text
  color: #431c89
  font:950 19px "Roboto", sans-serif
.dark .pre-hubux-text
  color: white
.hubux-text
  font-size: 20px
  color: #431c89
  font-weight:800

.pre-coin-text
  font: 950 30px "Roboto Slab", sans-serif
  color: #431c89
.dark .pre-coin-text
  color: white !important
.pre-svg
  width: 40px
  height: auto
.svg
  width: 50px
  height: auto
.sub-badge
  position: absolute
  top: 4px
  right: 4px
.small-pre-count
  font-size: 12px
  color: rgb(0, 180, 197)
  position: absolute
  top: 3px
.coin-text
  font: 952 37px "Roboto Slab", sans-serif
  -webkit-text-fill-color: #431c89
  -webkit-text-stroke: 1px #ffffff
.free-hubuck-border
  z-index: 1
  border: 2px solid #eaecf6
  border-radius: 12px
  width: 100%
  min-width: 219px
  max-width: 219px
.hubuck-border
  z-index: 1
  border: 2px solid transparent
  border-radius: 12px
  background: linear-gradient(90deg, v-bind(hubuxBg), v-bind(hubuxBg)),linear-gradient(170deg, #d838f7 43%, #9f4cef 0, #55cae5 63%, #64ece5 76%)
  background-clip: padding-box, border-box
  background-origin: padding-box, border-box
</style>
