import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { chAxios } from '@/shared/lib/axios';
import constants from '@/shared/statics/constants';
import type { Paging } from '@/shared/types/static-types';

export async function getTotalBadges(page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/user-profiles/badges-all/`;
  const { data } = await chAxios().get(url, { params: { page, page_size: pageSize, ...params } });
  return data;
}

export async function getNextPage(paging: Paging) {
  const url = paging.next;
  const { data } = await chAxios().get(url);
  return data;
}
export async function getUserProfileBadges() {
  const url = `/user-profiles/unlocked-badges/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function postUserBadge(payload: {}) {
  const url = `/user-profiles/add-badges/`;

  const { data } = await chAxios().post(url, payload);
  sendAnalyticsEvent('Created Badge ');
  return data;
}
