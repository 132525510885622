import { MutationTree } from 'vuex';
import { RolechatState } from './types';

export const mutations: MutationTree<RolechatState> = {
  SET_ROLECHATS: (state, { rolechats, paging }) => {
    state.rolechats = rolechats;
    state.rolechatsPaging = paging;
    state.rolechatsPage = 1;
  },
  APPEND_ROLECHATS: (state, { results, paging }) => {
    state.rolechats = [...(state.rolechats || []), ...results];
    state.rolechatsPaging = paging;
    state.rolechatsPage = (state.rolechatsPage || 0) + 1;
  },
  SET_UNREAD_STATUS: (state, { hasUnreadRolechats, unreadRolechatsCount }) => {
    state.hasUnreadRolechats = hasUnreadRolechats;
    if (unreadRolechatsCount !== undefined) state.unreadRolechatsCount = unreadRolechatsCount;
  },
  DECREMENT_UNREAD_COUNT: (state) => {
    state.unreadRolechatsCount = Math.max(0, (state.unreadRolechatsCount || 0) - 1);
  }
};
