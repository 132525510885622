import { MutationTree } from 'vuex';
import { CharactersState } from './types';
import { Character } from '@/shared/types/static-types';

export const mutations: MutationTree<CharactersState> = {
  SET_HOME_CHARACTERS: (state, { results, paging }) => {
    state.homeCharacters = results;
    state.homeCharactersPaging = paging;
  },
  APPEND_HOME_CHARACTERS: (state, { results, paging }) => {
    state.homeCharacters = [...(state.homeCharacters || []), ...results];
    state.homeCharactersPaging = paging;
    state.homeCharactersPage = (state.homeCharactersPage || 0) + 1;
  },
  SET_USER_CHARACTERS: (state, { results, paging }) => {
    state.userCharacters = results;
    state.userCharactersPaging = paging;
  },
  APPEND_USER_CHARACTERS: (state, { results, paging }) => {
    state.userCharacters = [...(state.userCharacters || []), ...results];
    state.userCharactersPaging = paging;
    state.userCharactersPage = (state.userCharactersPage || 0) + 1;
  },
  SET_TRENDING_CHARACTERS: (state, { results, paging }) => {
    state.trendingCharacters = results;
    state.trendingCharactersPaging = paging;
  },
  APPEND_TRENDING_CHARACTERS: (state, { results, paging }) => {
    state.trendingCharacters = [...(state.trendingCharacters || []), ...results];
    state.trendingCharactersPaging = paging;
    state.trendingCharactersPage = (state.trendingCharactersPage || 0) + 1;
  },
  SET_TOP_CHARACTERS: (state, { results, paging }) => {
    state.topCharacters = results;
    state.topCharactersPaging = paging;
  },
  APPEND_TOP_CHARACTERS: (state, { results, paging }) => {
    state.topCharacters = [...(state.topCharacters || []), ...results];
    state.topCharactersPaging = paging;
    state.topCharactersPage = (state.topCharactersPage || 0) + 1;
  },
  SET_LATEST_CHARACTERS: (state, { results, paging }) => {
    state.latestCharacters = results;
    state.latestCharactersPaging = paging;
  },
  APPEND_LATEST_CHARACTERS: (state, { results, paging }) => {
    state.latestCharacters = [...(state.latestCharacters || []), ...results];
    state.latestCharactersPaging = paging;
    state.latestCharactersPage = (state.latestCharactersPage || 0) + 1;
  },
  SET_OWN_CHARACTERS: (state, { results, paging }) => {
    state.ownCharacters = results;
    state.ownCharactersPaging = paging;
  },
  APPEND_OWN_CHARACTERS: (state, { results, paging }) => {
    state.ownCharacters = [...(state.ownCharacters || []), ...results];
    state.ownCharactersPaging = paging;
    state.ownCharactersPage = (state.ownCharactersPage || 0) + 1;
  },
  APPLY_REACTION_ON_CHARACTER: (
    state,
    { character, updatedReactionsData }: { character: Character; updatedReactionsData: Partial<Character> }
  ) => {
    const { inActionCharacter } = state;

    // home
    const homeCharacters = state.homeCharacters || [];
    const findHomeCharacterIndex = homeCharacters.findIndex((homeCharacter) => homeCharacter.id === character.id);
    if (findHomeCharacterIndex >= 0) {
      const foundHomeCharacter = state.homeCharacters![findHomeCharacterIndex];
      const updatedHomeCharacter = {
        ...foundHomeCharacter,
        ...updatedReactionsData,
      };
      state.homeCharacters![findHomeCharacterIndex] = updatedHomeCharacter;
    }
    // latest
    const latestCharacters = state.latestCharacters || [];
    const findLatestCharacterIndex = latestCharacters.findIndex(
      (latestCharacter) => latestCharacter.id === character.id
    );
    if (findLatestCharacterIndex >= 0) {
      const foundLatestCharacter = state.latestCharacters![findLatestCharacterIndex];
      const updatedLatestCharacter = {
        ...foundLatestCharacter,
        ...updatedReactionsData,
      };
      state.latestCharacters![findLatestCharacterIndex] = updatedLatestCharacter;
    }
    // trending
    const trendingCharacters = state.trendingCharacters || [];
    const findtrendingCharacterIndex = trendingCharacters.findIndex(
      (trendingCharacter) => trendingCharacter.id === character.id
    );
    if (findtrendingCharacterIndex >= 0) {
      const foundtrendingCharacter = state.trendingCharacters![findtrendingCharacterIndex];
      const updatedtrendingCharacter = {
        ...foundtrendingCharacter,
        ...updatedReactionsData,
      };
      state.trendingCharacters![findtrendingCharacterIndex] = updatedtrendingCharacter;
    }
    // top
    const topCharacters = state.topCharacters || [];
    const findtopCharacterIndex = topCharacters.findIndex((topCharacter) => topCharacter.id === character.id);
    if (findtopCharacterIndex >= 0) {
      const foundtopCharacter = state.topCharacters![findtopCharacterIndex];
      const updatedtopCharacter = {
        ...foundtopCharacter,
        ...updatedReactionsData,
      };
      state.topCharacters![findtopCharacterIndex] = updatedtopCharacter;
    }
    // user
    const userCharacters = state.userCharacters || [];
    const finduserCharacterIndex = userCharacters.findIndex((userCharacter) => userCharacter.id === character.id);
    if (finduserCharacterIndex >= 0) {
      const founduserCharacter = state.userCharacters![finduserCharacterIndex];
      const updateduserCharacter = {
        ...founduserCharacter,
        ...updatedReactionsData,
      };
      state.userCharacters![finduserCharacterIndex] = updateduserCharacter;
    }

    if (character.id === inActionCharacter.id) {
      const updatedInactionCharacter = {
        ...inActionCharacter,
        ...updatedReactionsData,
      };
      state.inActionCharacter! = updatedInactionCharacter;
    }
  },
  SET_IN_ACTION_CHARACTERS: (state, inActionCharacter) => {
    state.inActionCharacter = inActionCharacter;
  },
  SET_IN_ACTION_CHARACTERS_DRAFTS: (state, inActionCharacterDraft) => {
    state.inActionCharacterDraft = inActionCharacterDraft;
  },
};
