import { MutationTree } from 'vuex';
import { SiteEventState } from './types';

export const mutations: MutationTree<SiteEventState> = {
  SET_SITE_EVENTS: (state, { results, paging }) => {
    state.siteEvents = results;
    state.siteEventsPaging = paging;
  },
  APPEND_SITE_EVENTS: (state, { results, paging }) => {
    state.siteEvents = [...(state.siteEvents || []), ...results];
    state.siteEventsPaging = paging;
    state.siteEventsPage = (state.siteEventsPage || 0) + 1;
  },

  SET_SUBMISSIONS: (state, { results, paging }) => {
    state.submissions = results;
    state.submissionsPaging = paging;
  },
  APPEND_SUBMISSIONS: (state, { results, paging }) => {
    state.submissions = [...(state.submissions || []), ...results];
    state.submissionsPaging = paging;
    state.submissionsPage = (state.submissionsPage || 0) + 1;
  }
};
