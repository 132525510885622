export const featureFlags = {
  isXmas: false,
  quizzes: true,
  pro: true,
  subscriptionUpgrade: true,
  storage: true,
  usernameColor: true,
  userCoverPic: true,
  reactionsRevamp: true,
  emailUpdate: true,
  complimentBombs: true,
  shareProfile: true,
  joinImg: true,
  ads: true,
  profileBadge: true,
  currency: true,
  profileRing: true,
  paywallOnMobile: false,
  userLogo: true,
  backgroundOverlay: true,
  backgroundImage: true,
};
