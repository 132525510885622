<template>
  <div v-if="character.type != 'draft'" class="wrapper">
    <div id="character-comments" class="title mt-4 mb-3" :style="{ color: textColor }">
      Comments <span v-if="characterCommentsCount">({{ characterCommentsCount }})</span>
    </div>
    <PostComment :target="character" :customize="customize" commenting-on-type="character" @posted="load" />

    <!-- Comments list -->
    <div class="w-100 mt-2">
      <div v-for="(comment, index) in allComments" :key="index">
        <CommentsListItem
          :id="`comment-${comment.id}`"
          key="index"
          :comment="comment"
          :target="character"
          commenting-on-type="character"
          :customize="customize"
          @posted="load"
          @commentDeleted="load"
        />
        <!-- Replies -->
        <div v-if="get(comment, 'replies.length')">
          <CommentsListItem
            v-for="(reply, rIndex) in comment.replies.slice(0, 2)"
            :id="`comment-reply-${comment.id}`"
            :key="rIndex"
            :comment="reply"
            :customize="customize"
            :target="character"
            commenting-on-type="character"
            @posted="load"
            @commentDeleted="load"
          />
        </div>

        <Transition name="slide-fade">
          <div v-if="comment.showAllReplies && comment.replies.length > 2">
            <CommentsListItem
              v-for="reply in comment.replies.slice(2, comment.replies.length)"
              :id="`comment-reply-${comment.id}`"
              :key="reply.id"
              :comment="reply"
              :customize="customize"
              :target="character"
              commenting-on-type="character"
              @posted="load"
              @commentDeleted="load"
            />
          </div>
        </Transition>

        <div v-if="comment.replies.length > 2" class="d-flex justify-content-center ml-auto mb-3 w-75">
          <ion-button
            class="tertiary"
            color="medium"
            size="small"
            @click.prevent="comment.showAllReplies = !comment.showAllReplies"
          >
            Show {{ comment.showAllReplies ? 'Less' : 'More' }}
          </ion-button>
        </div>
      </div>

      <div class="d-flex justify-content-center pb-4">
        <ChLoading size="sm" v-if="areCommentsLoading" class="spinner" />
        <a v-else-if="totalComments > allComments.length" href="#" @click.prevent="loadMoreComments"
          >Load {{ loadMoreandLess }}</a
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Action } from 's-vuex-class';
import { Character } from '@/shared/types/static-types';
import namespace from '@/shared/store/namespace';
import CommentsListItem from '@/shared/components/CommentsListItem/index.vue';
import PostComment from '@/shared/components/PostComment.vue';
import { getCommentsCount } from '@/shared/actions/comments';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { textColorChanged } from '@/shared/utils/textConverter';

@Options({
  name: 'CharacterComments',
  components: { CommentsListItem, PostComment },
})
export default class CharacterComments extends Vue {
  @Prop() character!: Character;
  @Prop() customize!: any;

  public allComments: any[] = [];
  public totalComments = 0;
  public currentPage = 1;
  public characterCommentsCount = 0;
  public areCommentsLoading = false;
  public loadMoreandLess = 'More';

  get = get;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  @Action('getComments', { namespace: namespace.CharactersModule })
  public getComments!: Function;

  @Action('updateWSCommentConnection', { namespace: namespace.CharactersModule })
  public updateWSCommentConnection!: Function;

  @Action('getUserCharacters', { namespace: namespace.CharactersModule })
  public getUserCharacters!: any;

  public load() {
    this.fetchCommentsCount();
    this.fetchComments();
    this.hookCommentWS();
  }

  public get isDark() {
    const { dark } = mainStore();
    return dark.value;
  }

  public get bgColor() {
    return `${get(this.customize, 'data.theme.backgrounds.page')} !important`;
  }

  public get textColor() {
    const defaultClr = this.isDark ? '#FFFFFF' : '#214163';
    return isEmpty(get(this.customize, 'template_applied'))
      ? defaultClr
      : `${textColorChanged(this.bgColor)} !important`;
  }

  @Watch('character')
  characterChanged() {
    this.load();
  }

  public async fetchCommentsCount() {
    if (this.character.id) {
      const resp = await getCommentsCount([this.character.id], constants.commentedAsTypes.CHARACTER);
      this.characterCommentsCount = get(resp, 'counts.0.comments_count', 0);
    }
  }

  public async loadMoreComments() {
    this.fetchComments(this.currentPage + 1);
  }

  public async fetchComments(page = 1) {
    this.areCommentsLoading = true;
    if (this.character.id) {
      const resp = await this.getComments({
        objId: this.character.id,
        objType: constants.commentedAsTypes.CHARACTER,
        page,
        params: { h: this.$route.query?.cmnt || '' }
      });

      for (const comment of resp.results) {
        comment.showAllReplies = false;
      }

      if (page === 1) {
        this.allComments = resp.results;
      } else {
        this.allComments = this.allComments.concat(resp.results);
      }
      this.totalComments = resp.count;
      this.currentPage = page;
    }
    this.areCommentsLoading = false;
  }

  public hookCommentWS() {
    const { authToken } = authStore();
    const {
      public: { wsUrl },
    } = useRuntimeConfig();
    const { wsConnection } = useSocket(`${wsUrl}/ws/comments/${this.character.id}/?token=${authToken.value}`);
    wsConnection.value.onmessage = (event) => {
      const data = JSON.parse(event.data).message;

      if (data.action === 'Create') {
        if (!data.comment.parent_comment_id) {
          this.allComments.unshift(data.comment);
        } else {
          const comment = this.allComments.find((com) => com.id === data.comment.parent_comment_id);
          if (comment) {
            comment.replies.push(data.comment);
          }
        }
        this.characterCommentsCount += 1;
      } else if (data.action === 'Update') {
        const commentId = data.comment.parent_comment_id || data.comment.id;
        const comment = this.allComments.find((com) => com.id === commentId);

        if (comment) {
          let commentToUpdate;
          if (data.comment.parent_comment_id) {
            commentToUpdate = comment.replies.find((rep: any) => rep.id === data.comment.id);
          } else {
            commentToUpdate = comment;
          }

          if (commentToUpdate) {
            commentToUpdate.text = data.comment.text;
            commentToUpdate.is_edited = true;
          }
        }
      } else if (data.action === 'Delete') {
        const commentId = data.comment.parent_comment_id || data.comment.id;
        const comment = this.allComments.find((com) => com.id === commentId);

        if (!data.comment.parent_comment_id) {
          this.characterCommentsCount = this.characterCommentsCount - 1 - comment.replies.length;
          this.allComments = this.allComments.filter((com) => com.id !== commentId);
        } else {
          comment.replies = comment.replies.filter((com: any) => com.id !== data.comment.id);
          this.characterCommentsCount -= 1;
        }
      }
      this.$emit('commentsUpdated');
    };
  }

  public async mounted() {
    this.totalComments = 0;
    this.allComments = [];
    // if (this.isAuthenticated) {
    //   this.getUserCharacters({ id: this.user.id });
    // }

    this.load();
  }
}
</script>

<style lang="sass" scoped>
.comment-poster-img
  width: 60px
  height: 60px
  border-radius: 40px
  border: solid gray 0.1px
  object-fit: cover
.title
  font-size: 24px
  font-weight: bold
.sub-title
  font-size: 20px
  font-weight: bold
.small-title
  font-size: 13px
  font-weight: bold
.publish-btn
  width: 100px
  .spinner
    width: 15px
    height: 15px

.slide-fade-enter-active
  transition: all 0.5s ease-out

.slide-fade-leave-active
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1)

.slide-fade-enter-from,
.slide-fade-leave-to
  transform: translateY(-20px)
  opacity: 0
</style>
