<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-title class="title">{{ modalTitle }}</ion-title>
        </div>
        <ion-buttons slot="end">
          <ion-button class="close-button" @click="dismissModal()">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content">
      <div class="pb-3 px-4 mt-3">
        <ImageStorageSpace
          :is-storage-full-modal="true"
          :storage-tiers="storageTiers"
          upgrade-tag-bg-color="#fff"
          :show-title="false"
        />
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import ImageStorageSpace from '@/shared/components/ImageStorageSpace.vue';
import { getImageStorageTiers } from '@/shared/actions/imagesCollections';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  modalTitle: {
    type: String,
    default: 'Storage Space Full',
  },
});
const isOpen = toRef(props, 'isOpen');
const emits = defineEmits(['close']);
const storageTiers = ref({});

watch(isOpen, async () => {
  if (isOpen.value) {
    const resp = await getImageStorageTiers();
    storageTiers.value = resp;
  }
});

const dismissModal = () => {
  emits('close');
};
</script>
<style lang="sass" scoped>
.dark .toolbar
  --background: #17074c !important
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: calc(85vh - 45px)
  overflow: auto
  overflow-x: hidden

.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 18px
  font-weight: bold
  text-align: center
  padding-left: 55px
  padding-right: 0px
</style>
