import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { chAxios } from '@/shared//lib/axios';
import { Paging } from '../types/static-types';

export async function getShopVirtualItems(params = {}) {
  const url = `/currency/virtual-items/shop/`;
  const { data } = await chAxios().get(url, {
    params: { ...params },
  });
  return data;
}

export async function getVirtualItemsCurrencyConfig() {
  const url = `/currency/virtual-items/currency-config/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getVirtualItemsCustomSet(params = {}) {
  const url = `/currency/custom-scheduler/`; 
  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function getNextPage(paging: Paging) {
  const url = paging.next;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getVirtualItemsActiveSchedulers() {
  const url = `/currency/virtual-items/active-schedulers/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getVirtualItemDetails(id?: any) {
  const url = `/currency/virtual-items/${id}`;
  const { data } = await chAxios().get(url);
  return data;
}
export async function getUsersVirtualEarnings(params = {}) {
  const url = `/users/my-virtual-earnings/`;
  const { data } = await chAxios().get(url,{ params:{ ...params }});
  return data;
}


export async function getFeaturedVirtualItems(params = {}) {
  const url = `/currency/virtual-items/top/`;
  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function getVirtualItems(params = {}) {
  const url = `/currency/virtual-items/`;
  const { data } = await chAxios().get(url, { params });
  return data;
}

export async function getGiftRespondStatus(id: string) {
  const { data } = await chAxios().get(`/currency/gift-logs/${id}`);
  return data;
}
export async function giftItemRespond(payload: {}) {
  const url = `/currency/gift-logs/respond/`;
  const { data } = await chAxios().post(url, payload);
  sendAnalyticsEvent('Thankyou Message sent successfully');
  return data;
}


export async function getVirtualItemsCategories(params = {}) {
  const url = `/currency/virtual-items/categories/`;
  const { data } = await chAxios().get(url, {
    params: { ...params },
  });
  return data;
}

export async function purchaseVirtualItem(itemId: string) {
  const url = `/currency/virtual-items/purchase/`;
  const { data } = await chAxios().post(url, { item_id: itemId });
  sendAnalyticsEvent('purchased an item');
  return data;
}
export async function giftVirtualItem(payload:{}) {
  const url = `/currency/gift-logs/`;
  const { data } = await chAxios().post(url,payload);
  sendAnalyticsEvent('purchased an item');
  return data;
}
export async function giftVirtualItemRespond(payload:{}) {
  const url = `/currency/gift-logs/respond/`;
  const { data } = await chAxios().post(url,payload);
  sendAnalyticsEvent('Thankyou Message sent successfully');
  return data;
}
