<template>
  <ClientOnly>
    <div class="d-flex flex-wrap" :class="setNoPadding ? 'px-3' : 'p-3'">
      <div class="d-flex ml-auto align-items-center clickable-item-hov">
        <template v-for="item in items" :key="item.id">
          <div
            class="plus-section d-flex align-items-center mx-auto justify-content-center"
            @click="openShop($event, item.item)"
          >
            <i class="ti-plus plus-icon" />
          </div>
          <div
            class="store-shop-svg"
            :style="item.id === 2 ? 'left:10px;top:0px' : ''"
            @click="openShop($event, item.item)"
          >
            <CurrencyImage v-if="item.id === 1" :type="'coin'" :img-width="imgWidth" />
            <inline-svg :src="item.svg" class="svg" />
          </div>

          <div
            class="d-flex align-items-center text-center justify-content-end ml-auto px-1 store-badge"
            @click="openShop($event, item.item)"
          >
            <div class="count-badge d-flex align-items-center text-center justify-content-end px-1">
              <count-up v-if="earned && item.id === 1" :end-val="item.count" :startVal="coinEarned"></count-up>

              <div v-else>
                {{ item.count }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import CountUp from 'vue-countup-v3';
import { openGemShopModal, openSpecialBundleShopModal } from '@/shared/utils/modals';
import CurrencyImage from '@/shared/components/CurrencyImage.vue';
const props = defineProps({
  coins: { type: Number, default: 0 },
  gems: { type: Number, default: 0 },
  earned: { type: Number, default: 0 },
  setNoPadding: { type: Boolean, default: false },
  imgWidth: { type: Number },
});
const coins = toRef(props, 'coins');
const gems = toRef(props, 'gems');
const earned = toRef(props, 'earned');
const openShop = (ev: MouseEvent, key: string) => {
  if (key === 'gems') {
    openGemShop(ev);
  } else {
    openBundleShop(ev);
  }
};
const openGemShop = (ev: MouseEvent) => {
  const { isAuthenticated } = authStore();
  if (!isAuthenticated.value) return;
  openGemShopModal(ev);
};
const openBundleShop = (ev: MouseEvent) => {
  const { isAuthenticated } = authStore();
  if (!isAuthenticated.value) return;
  openSpecialBundleShopModal(ev);
};
const items = computed(() => {
  return [
    { count: coins.value, svg: '/hubuck_wborder.svg', id: 1, item: 'coins' },
    { count: gems.value, svg: '/gem.svg', id: 2, item: 'gems' },
  ];
});

const coinEarned = computed(() => {
  return coins.value - earned.value;
});
</script>

<style lang="sass" scoped>
.store-shop-svg
  position: relative
  top: -1px
  left: 6px
  width: 46px
  height: 46px
  @media(max-width:500px)
    width: 26px
    height: 26px
.store-bar
  border: 2px solid var(--ion-color-primary)
  border-radius: 12px
.plus-section
  left: 55px
  z-index: 1
  top: 10px
  position: relative
  width: 20px
  background: #0093c5
  height: 20px
  border: 3px solid #431c89
  border-radius: 20px
  @media(max-width:500px)
    left: 34px
    top: 6px
    width: 14px
    height: 14px
.plus-icon
  font-size: 9px
  font-weight: bolder
  color: white
  @media(max-width:500px)
    font-size: 6px

.count-badge
  background: #671CA4
  font-weight: bolder
  border-radius: 20px
  height:19px
  min-width: 49px
  font-size: 14px
  font-weight: bold
  width: 100%
  @media(max-width:500px)
    height:13px
    font-size: 12px


.store-badge
  background: var(--ion-color-primary)
  color: white
  border-top-right-radius: 20px
  border-bottom-right-radius: 20px
  height: 25px
  padding-left: 9px !important
  @media(max-width:500px)
    height: 18px
.svg
  height: 46px
  width: 46px
  @media(max-width:500px)
    height: 26px
    width: 26px
</style>
