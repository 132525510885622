<template>
  <div
    v-if="showCollection"
    :id="`c_${id}`"
    class="mr-2 album d-flex flex-column album-dt"
    :class="{ 'is-active': isActive, edit: isEditor, 'd-ic': isEditor }"
    :style="{ outline: borderDefaultColor }"
    :ondragover="handleDragOver"
    @click="selectCollection"
    @contextmenu="handleContextMenu"
  >
    <Sortable
      :id="`ih_${id}`"
      item-key="id"
      tag="div"
      :list="empty"
      :options="sortableOptions"
      :disabled="!isEditor"
      class="invissort"
      @add="onImageMovedIntoAlbum"
    >
    </Sortable>
    <div class="images d-flex flex-grow-1 position-relative">
      <ion-button
        v-if="isEditor && get(collection, 'type') !== 'auto' && !isCollectionReadOnly"
        fill="none"
        class="position-absolute one-button position-absolute"
        @click="removeImageCollection"
      >
        <i class="ti-close" />
      </ion-button>
      <div
        v-if="introImage?.image"
        class="intro"
        :style="{
          background:
            get(introImage, 'type') === 'decoration'
              ? `url(${introImage?.image}`
              : `url(${resizeUpload(introImage?.image, '190h')})`,
          filter: `blur(${introImage?.is_spoiled && !isEditor && !introImage?.spoiled_images.user ? '50px' : ''})`,
        }"
        :class="{ only: !restImages.length }"
      />
      <div v-if="restImages.length" class="rest d-flex flex-column">
        <div
          v-for="(oneRest, index) of restImages"
          :key="index"
          class="one-rest"
          :style="{
            background:
              get(oneRest, 'type') === 'decoration'
                ? `url(${oneRest?.image}`
                : `url(${resizeUpload(oneRest?.image, '190h')})`,
            filter: `blur(${oneRest?.is_spoiled && !isEditor && !oneRest?.spoiled_images.user ? '50px' : ''})`,
          }"
          :class="{ only: restImages.length === 1 }"
        />
      </div>
    </div>
    <div class="info px-2 d-flex align-items-center justify-content-between" :style="{ background: sectionColor }">
      <div
        class="info name d-flex align-items-center text-force-dark no-select"
        :style="{ background: sectionColor, 'font-family': defaultTextFont }"
      >
        {{ collection.name }}
      </div>
      <div
        class="count text-force-dark no-select"
        :style="{ background: sectionColor, 'font-family': defaultTextFont }"
      >
        {{ formattedCount }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import { imageStore } from '@/shared/pinia-store/images';
import { mainStore } from '@/shared/pinia-store/main';
import { toast } from '@/shared/native';
import { resizeUpload } from '@/shared/utils/upload';
import { formatNumber } from '@/shared/utils/number';

const { deleteImageCollectionLocal, moveImageToCollectionLocal, setVisibleCollection } = imageStore();

const props = defineProps({
  isEditor: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: () => '',
  },
  customize: {
    type: Object,
    default: () => ({}),
  },
});
const sortableOptions = {
  group: {
    name: 'imgcollmove',
    pull: false,
    put: ['imgmove'],
  },
  animation: 50,
  swapThreshold: 0,
  removeCloneOnHide: true,
};
const empty = ref([]);
const isEditor = toRef(props, 'isEditor');
const id = toRef(props, 'id');
const customize = toRef(props, 'customize');
const { isTempDefault } = useCustTempDefault(props);
const dark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});
const collection = computed(() => {
  const { imageCollectionById } = imageStore();
  return imageCollectionById.value(id.value);
});
const isActive = computed(() => {
  const { visibleImageCollectionId } = imageStore();
  return visibleImageCollectionId.value === id.value;
});
const isCollectionReadOnly = computed(() => {
  return collection.value?.abbreviation === 'decorations';
});
const routeName = computed(() => {
  try {
    const router = useRouter();
    return (
      router.currentRoute.value.name === 'character-profile-new' ||
      router.currentRoute.value.name === 'character-profile-draft-new'
    );
  } catch (error) {
    return '';
  }
});

const previousColor = ref('');
const sectionColor = computed(() => {
  return isEmpty(get(customize.value, 'template_applied')) &&
    dark.value &&
    get(customize.value, 'data.theme.backgrounds.infoSection') === '#E6E6E6' &&
    routeName.value
    ? '#aaa'
    : isEmpty(get(customize.value, 'template_applied')) &&
      get(customize.value, 'data.theme.backgrounds.infoSection') === '#E6E6E6'
    ? '#aaa !important'
    : `${get(customize.value, 'data.theme.backgrounds.infoSection') || '#aaa'} !important`;
});

const showCollection = computed(() => {
  return collection.value && (isEditor.value || collection.value?.images_page?.count);
});

const collectionImages = computed(() => {
  const { imagesInCollection } = imageStore();
  return imagesInCollection.value(id.value);
});

const introImage = computed(() => {
  try {
    return collectionImages.value[0] || {};
  } catch (e) {
    return {};
  }
});

const restImages = computed(() => {
  let visuals = [...(collectionImages.value || [])];
  // filter out undefined
  visuals.shift();
  visuals = visuals.splice(0, 2).filter((i) => i);
  return visuals;
});

const borderDefaultColor = computed(() => {
  const border = isEmptyTempDefaultColor('isBorder');
  if (isActive.value) return border;
  return 'solid 1px rgba(0,0,0,0.1) !important';
});

const defaultTextFont = computed(() => {
  return isEmpty(get(customize.value, 'template_applied')) &&
    isEmpty(get(customize.value, 'data.theme.text.default.font'))
    ? 'Roboto'
    : get(customize.value, 'data.theme.text.default.font');
});

onMounted(() => {
  if (!isEditor.value) return;
  if (!id.value) return;
  const el = document.getElementById(`c_${id.value}`);
  el?.addEventListener('dragleave', handleDragLeave);
  el?.addEventListener('dragenter', handleDragEnter);
});

onBeforeUnmount(() => {
  if (!id.value) return;
  const el = document.getElementById(`c_${id.value}`);
  el?.removeEventListener('dragleave', handleDragLeave);
  el?.removeEventListener('dragenter', handleDragEnter);
});

const onImageMovedIntoAlbum = (e: any) => {
  const { visibleImageCollectionId, imageCollectionById } = imageStore();
  const oldCollId = visibleImageCollectionId.value;
  if (oldCollId === id.value || !oldCollId || !id.value) {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    nextTick(() => {
      const div = document.getElementById(`ih_${id.value}`) as HTMLDivElement;
      while (div.firstChild) div.removeChild(div.firstChild);
      empty.value = [...empty.value];
    });
    return false;
  }

  const oldColl = imageCollectionById.value(oldCollId);
  if (oldColl.abbreviation === 'decorations') {
    toast.show('You cannot remove images from this album.', 'nonative', 'primary');
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    nextTick(() => {
      const div = document.getElementById(`ih_${id.value}`) as HTMLDivElement;
      while (div.firstChild) div.removeChild(div.firstChild);
      empty.value = [...empty.value];
    });
    return false;
  } else if (collection.value?.abbreviation === 'decorations') {
    toast.show('You cannot add images to this album.', 'nonative', 'primary');
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
    nextTick(() => {
      const div = document.getElementById(`ih_${id.value}`) as HTMLDivElement;
      while (div.firstChild) div.removeChild(div.firstChild);
      empty.value = [...empty.value];
    });
    return false;
  }
  const imgId = e.item.id.substring(2);
  if (oldCollId && id.value && imgId) {
    moveImageToCollectionLocal({
      imageId: imgId,
      from: oldCollId,
      to: id.value,
    });
    nextTick(() => {
      const div = document.getElementById(`ih_${id.value}`) as HTMLDivElement;
      while (div.firstChild) div.removeChild(div.firstChild);
      empty.value = [...empty.value];
    });
  }
};

const defaultTextColor = computed(() => {
  const defaultClr = dark.value ? '#FFFFFF' : '#000000';
  return isTempDefault.value ? defaultClr : `${get(customize.value, 'data.theme.text.default.color', defaultClr)}`;
});

const formattedCount = computed(() => {
  const count = collectionImages.value?.length || 0;
  try {
    return formatNumber(count);
  } catch (e) {
    return count;
  }
});

function handleDragEnter(e: any) {
  const el = document.getElementById(`c_${id.value}`);
  el?.classList.add('over');
  e.preventDefault();
}

function handleDragLeave(e: any) {
  const el = document.getElementById(`c_${id.value}`);
  el?.classList.remove('over');
}

function handleDragOver(e: any) {
  e.preventDefault();
  return false;
}

const selectCollection = () => {
  setVisibleCollection(id.value);
};

const isEmptyTempDefaultColor = (value: any) => {
  const currentColor = get(customize.value, 'data.theme.text.default.color');
  if (isEmpty(get(customize.value, 'template_applied')) && currentColor === '#000000' && isEmpty(previousColor.value)) {
    return value === 'isBorder' ? '2px solid #AE38E5 !important' : value === 'isBox' ? '#E6E6E6 !important' : '';
  } else {
    previousColor.value = currentColor;
    return value === 'isBorder'
      ? `2px solid ${get(customize.value, 'data.theme.text.default.color') || '#AE38E5'} !important`
      : `${get(customize.value, 'data.theme.text.default.color', '#CCC')} !important`;
  }
};

const removeImageCollection = async (ev: CustomEvent) => {
  ev.stopPropagation();
  const alert = await alertController.create({
    cssClass: '',
    header: 'Are you sure?',
    message: `You are removing this album and all images within it.`,
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'text-secondary',
        id: 'cancel-button',
      },
      {
        text: 'Confirm',
        id: 'confirm-button',
        role: 'ok',
        cssClass: 'text-secondary',
      },
    ],
  });
  await alert.present();
  const { role } = await alert.onDidDismiss();
  if (role === 'ok') {
    deleteImageCollectionLocal(id.value);
  }
};

const handleContextMenu = (e: any) => {
  e.preventDefault();
  e.stopPropagation();
  e.stopImmediatePropagation();
  return false;
};
</script>
<style scoped lang="sass">
.one-button
  width: 25px
  height: 25px
  background-color: rgba(0,0,0,1)
  color: #FFF
  --padding-start: 0
  --padding-end: 0
  border-radius: 8px
  right: 2px
  top: 0px
  position: absolute
  pointer-events: all
  z-index: 1
.name
  position: relative
  margin-right: 1.7rem
  overflow: hidden
/* fade out name content to background color on the right */
.count
  position: absolute
  display: block
  bottom: 5px
  right: 5px
  background-color: white
  border-radius: 4px
  font-size: 12px
  padding: 0.1rem
  min-width: 1rem !important
  text-align: center
.album.over
  filter: brightness(1.1)

.album
  -webkit-user-drag: element
  position: relative
  width: calc(25% - 1rem)
  height: 124px
  min-width: 150px
  overflow: hidden
  border-radius: 8px
  cursor: pointer
  @media(max-width: 500px)
    height: 102px
    min-width: 124px
    width: calc(25% - 1rem)
  .info
    background-color: #CCC
    height: 30px
    font-size: 11px
    letter-spacing: 0.02rem
    font-weight: normal
    color: v-bind(defaultTextColor) !important
    //color: black
    .count
      color: v-bind(defaultTextColor) !important
  .intro
    margin-right: 4px
  .intro, .rest
    width: 50%
    height: 100%
    background-size: cover !important
    background-position: center !important
    &.only
      width: 100%
      margin-right: 0px
  .rest
    .one-rest
      background-size: cover !important
      background-position: center !important
      height: calc(50% - 2px)
      &.only
        height: 100%
        margin-bottom: 0 !important
      &:first-of-type
        margin-bottom: 4px
.dark .album .info
  background: v-bind(sectionColor) !important
  color: v-bind(defaultTextColor) !important
.dark .album .info .count
  color: v-bind(defaultTextColor) !important
.invissort
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  opacity: 0
  pointer-events: none !important
</style>
