import { alertController, modalController } from '@ionic/vue';
import SubscribeModal from '@/shared/modals/SubscribeModal.vue';
import PurchaseModal from '@/shared/modals/PurchaseModal.vue';
import GemPurchaseModal from '@/shared/modals/GemPurchaseModal.vue';
import BuyWithGemConfirmModal from '@/shared/modals/BuyWithGemConfirmModal.vue';
import { isMobileFn } from '~/apps/mobile/native/device';
import { featureFlags } from '../config/feature-flags';

// Use this example to open a modal from anywhere
// generalize later when number of these modals > 2
export const openSubscribeModal = async (event: any, openedFrom?: string) => {
  const modal = await modalController.create({
    component: SubscribeModal,
    backdropDismiss: false,
    cssClass: 'subs-modal',
    componentProps: {
      openedFrom,
    },
  });
  await modal.present();
};

export const openSubscribeSuccessModal = async (event: any, tier: string, subscriptionData: any) => {
  const modal = await modalController.create({
    component: SubscribeModal,
    backdropDismiss: false,
    cssClass: 'subs-modal',
    componentProps: {
      goToSuccessTier: tier,
      subscriptionData,
    },
  });
  await modal.present();
  await modal.onDidDismiss();
};

export const openPurchaseModal = async (
  event: any,
  productCode: string,
  name: string,
  quantity: number,
  postAction?: string,
  openedFrom?: string,
  firstPurchaseOffer?: string
) => {
  const isMobile = await isMobileFn();
  const disablePaywall = !featureFlags.paywallOnMobile && isMobile;

  if (disablePaywall) {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Payment n mobile coming soon',
      message: `If you need to purchase anything you can use the website for now.`,
      buttons: [
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });

    alert.present();
  }

  const modal = await modalController.create({
    component: PurchaseModal,
    backdropDismiss: false,
    cssClass: 'subs-modal',
    componentProps: {
      openedFrom,
      productCode,
      quantity,
      postAction,
      name,
      firstPurchaseOffer,
    },
  });
  await modal.present();
  const code = await modal.onDidDismiss();
  return code;
};

export const openGemShopModal = async (event: any, type?: string) => {
  const modal = await modalController.create({
    component: GemPurchaseModal,
    backdropDismiss: true,
    cssClass: 'subs-modal',
    componentProps: {
      listingType: type,
    },
  });
  await modal.present();
  return await modal.onDidDismiss();
};

export const openSpecialBundleShopModal = async (event: any) => {
  openGemShopModal(event, 'special');
};

export const openBuyWithGemsModal = async (event: any, productListing: any) => {
  const modal = await modalController.create({
    component: BuyWithGemConfirmModal,
    backdropDismiss: false,
    cssClass: 'purchase-success-modal',
    componentProps: {
      productListing,
    },
  });
  await modal.present();
  return await modal.onDidDismiss();
};

// just use modalController where you need it as follows:
// export const openModal = async (component: any, cssClass?: string, componentProps?: any) => {
//   const modal = await modalController.create({ component, cssClass, componentProps });
//   await modal.present();
//   modal.onDidDismiss().then((data) => {...});
// };

const modals: any = {};

export const getModalComponent = (type: string): any => {
  return modals[type];
};
