export const useSocket = (url: string) => {
  const wsConnection = ref<WebSocket>({} as WebSocket);

  if (process.client) {
    wsConnection.value = new WebSocket(url);
  }

  const wsClose = () => {
    if (wsConnection?.value?.close && process.client) {
      wsConnection.value.close();
      wsConnection.value = {} as WebSocket;
    }
  };

  const wsConnect = () => {
    if (wsConnection?.value && process.client) {
      wsClose();
      wsConnection.value = new WebSocket(url);
      wsConnection.value.onerror = () => {};
    }
  };

  onBeforeUnmount(() => {
    wsClose();
  });

  return { wsConnection, wsClose, wsConnect };
};
