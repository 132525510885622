<template>
  <div class="d-flex flex-column py-2" style="padding-right: 16px; padding-left: 16px">
    <div class="sub-title mt-3">Please explain what you are reporting (Optional)</div>
    <div class="py-3">
      <ion-textarea v-model="comments" class="c-textarea one-input" placeholder="Reason for reporting" />
    </div>

    <div><ion-button class="w-100 submit-btn" color="primary"  @click="submit">Next</ion-button></div>
    <hr />
  </div>
</template>

<script lang="ts" setup>
import { submitUserReport } from '@/shared/actions/report';
import { authStore } from '@/shared/pinia-store/auth';
import { profileStore } from '@/shared/pinia-store/profile';
const props = defineProps({
  category: {
    type: Object,
    default: () => {},
  },
  reportEntityId: {
    type: String,
    default: '',
  },
  reportEntityType: {
    type: String,
    default: '',
  },
  reportedFrom: {
    type: String,
    default: '',
  },
  reportedUser: {
    type: Object,
    default: {},
  },
  reportImageUrl: {
    type: String,
    default: '',
  },
});

const comments = ref('');
const emits = defineEmits(['submitted']);
const category = toRef(props, 'category');
const { currentProfile } = profileStore();
const { user } = authStore();

const reportEntityType = toRef(props, 'reportEntityType');
const reportEntityId = toRef(props, 'reportEntityId');
const reportedUser = toRef(props, 'reportedUser');
const reportedFrom = toRef(props, 'reportedFrom');
const reportImageUrl = toRef(props, 'reportImageUrl');

const reportSubCategories = computed(() => {
  return category.value.text;
});

const submit = async () => {
  const payload = !isEmpty(reportImageUrl.value)
    ? {
        comments: comments.value,
        reported_user: reportedUser.value.id,
        report_category: category.value.id,
        reported_entity_type: reportEntityType.value,
        image_url: reportImageUrl.value,
        reported_by: user.value.id,
      }
    : !isEmpty(reportedFrom.value)
    ? {
        comments: comments.value,
        reported_user: reportedUser.value.id,
        report_category: category.value.id,
        reported_entity_type: reportEntityType.value,
        reported_entity_id: reportEntityId.value,
        reported_by: user.value.id,
        reported_from: reportedFrom.value,
      }
    : {
        comments: comments.value,
        reported_user: reportedUser.value.id,
        report_category: category.value.id,
        reported_entity_type: reportEntityType.value,
        reported_entity_id: reportEntityId.value,
        reported_by: user.value.id,
      };
  await submitUserReport(payload);
  emits('submitted');
};
</script>

<style lang="sass" scoped>
.submit-btn
  color: white
  --border-radius: 20px
.text
  color: #65676B
.title
  font-weight: bold
  font-size: 22px
  margin-top: 1rem
.sub-title
  font-weight: bold
</style>