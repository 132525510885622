export const reactionsNames: { [key: string]: string } = {
  myfav: 'My favorite!!! 🤩',
  obses: 'Obsessed!! 😍',
  socoo: 'So cool 😎',
  relat: 'I can relate 🥺',
  vibe: 'I vibe with this ✨',
  hero: 'My hero! ⚡',
  more: 'More, please! 🙏',
  love: "I'm in love 💖",
  cute: 'So cute! 😸',
  ba: 'Badass 🔥',
  gorg: 'Gorgeous 💕',
  spcls: "I'm speechless 🤯",
  chils: 'Wow. Chills 🥶',
  icart: 'Incredible art!! 🎨',
  wellw: 'So well written!!! ✍',
  sofun: 'So funny 🤣',
  lov: '❤️ Love this!',
};

export const worldReactionsNames: { [key: string]: string } = {
  world: '🌐 Incredible world-building!!',
  socoo: ' 😎 So cool!!',
  jdwri: '✍️ Jaw-dropping writing!!',
  socre: '🎨 So creative!!',
  mindb: '🤯 Mindblown!!',
  musts: '👀 Must see!!',
  love: '😍 Love it here!!',
  great: '🥰 Great spot for a date!',
  vibe: '✨ Good vibes!!',
  petfr: '🐶 Pet-friendly!!',
  stwif: '📶 Strong wifi!!',
  ampic: '📸 Amazing photo opps!!',
  sofun: '🤣 So funny',
  lov: '❤️ Love this!',
};

export const blabReactionsNames: { [key: string]: string } = {
  yocol: 'Yoooo SO COOL 🤯',
  gwrit: 'Great writing ✍️',
  rel: 'Relatable ❤️',
  bestr: 'Best I’ve read 🤩',
  myfa: 'My favorite 😻',
  eart: 'Epic art 🎨',
  cstop: 'Can’t stop laughing 🤣',
  cried: 'I cried 😭',
  cidea: 'Clever idea 🧐',
  somem: 'So many emotions 😵',
  socut: 'So cute 🥰',
  yas: 'YAAS 🙌',
  sla: 'Slayy 😍',
  allth: 'ALL THE FEELZ 🥺',
  mmplz: 'Make more plz! 🙏',
  lov: 'Love 💝',
  thank: 'Thanks 💞',
};
export const visualReactionsNames: { [key: string]: string } = {
  amart: 'Amazing art 😍',
  lovys: 'Love your style 🥰',
  scute: 'So cute 🥺',
  outuc: 'Outstanding use of colors 😳',
  crili: 'Crisp lineart 🥓',
  artbr: 'Artistic brilliance 🌈',
  workg: 'Work of genius 🤯',
  frea: 'Freakin awesome 🔥',
  maspi: 'Masterpiece 🎊',
  sotal: 'So talented 🎨',
  excep: 'Exceptional 👏',
  inspi: 'Inspiring 💡',
  flaex: 'Flawless execution 💯',
  beywo: 'Beyond words 🙏',
  trulu: 'Truly unique 🔥',
  incde: 'Incredible detail 👌',
  lov: '❤️ Love this!'
};
