import { GetterTree } from 'vuex';
import { UsersState } from './types';
import { RootState } from '@/shared/store';


export const getters: GetterTree<UsersState, RootState> = {
  currentPagedRequestInfo(state) {
    return state.lastPaging;
  },
  byUsername: (state) => (username: string) => {
    return find(state.users, (user) => toLower(user.username) === toLower(username));
  },
  byId: (state) => (id: string) => {
    return find(state.users, (user) => user.id === id);
  },
  usersToShow(state) {
    let res = [];
    if (state.ids.length) {
      res = filter(state.users, ({ id }) => state.ids.includes(id));
      res = sortBy(res, (u) => state.ids.indexOf(u.id));
    }
    return res;
  },
  currentProfileUser(state) {
    return find(state.users, (user) => state.currentProfileUserId === user.id);
  },
  getFollow: (state) => (targetUserId: string, uId: string) => {
    const follow = find(state.follows, (f) => uId === f.userId && targetUserId === f.followingUserId);
    return follow || {};
  },
  loading: (state) => {
    return state.loading;
  },
  profile: (state) => {
    return state.profile;
  },
};
