<template>
  <div>
    <div class="d-flex justify-content-end skip-btn clickable-item-hov" @click="emits('skip')">Skip</div>
    <div class="d-flex flex-column justify-content-center align-items-center text-center mx-auto choose-sec">
      Add a profile cover picture
    </div>
    <div class="prf-sec d-flex flex-column flex-grow-1 px-3 pt-3 mt-3 position-relative">
      <img v-user-image class="profile position-absolute" :src="profilePicture" />
      <div
        class="cover w-100"
        :style="{ background: '#ccc' }"
        v-if="!isEmpty(coverSlider) && coverPreference === coverPreferences.MULTIPLE"
      >
        <ClientOnly>
          <swiper
            :modules="[SwiperPagination]"
            loop
            :navigation="{ enabled: true, nextEl: '#nextpg', prevEl: '#prevpg' }"
            :pagination="{ clickable: true }"
            class="swiper-base"
            @swiper="onSwiperInstance"
          >
            <swiper-slide v-for="(visual, index) in coverSlider" :key="index">
              <img :src="visual" style="width: 100%; height: 100%; object-fit: cover" />
            </swiper-slide>
          </swiper>
          <div class="navbtns">
            <div id="prevpg" class="d-flex-column clickable-area mx-2" @click="prevPage">
              <i class="ti-angle-left" />
            </div>
            <div id="nextpg" class="d-flex-column clickable-area mx-2" @click="nextPage">
              <i class="ti-angle-right" />
            </div>
          </div>
        </ClientOnly>
      </div>

      <div v-else class="cover w-100" :style="{ background: '#ccc' }">
        <div
          v-if="changingCover && !temp"
          class="dimmed d-flex align-items-center justify-content-center position-absolute"
        >
          <ch-loading size="lg" />
        </div>
        <img v-if="coverImage" :src="coverImage" style="width: 100%; height: 100%; object-fit: cover" />
      </div>
      <ion-button
        class="position-absolute p-0 round-cover-button"
        shape="round"
        color="dark"
        @click="changeCoverPopover"
      >
        <inline-svg class="icon mr-1" src="/icons/camera.svg" />
      </ion-button>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center text-center mx-auto">
      <small class="cover-txt mt-5">Enhance your profile with a personalized cover photo your friends will love.</small>
    </div>
  </div>
</template>

<script lang="ts" setup>
import ChangeCoverPopover from '@/shared/pages/profile/popovers/ChangeCoverPopover.vue';
import { popovers } from '@/shared/native/popovers';
import { authStore } from '@/shared/pinia-store/auth';
import { coverPreferences } from '@/shared/statics/constants';
const swiperRef = ref(null) as any;
const { temp, changingCover, user } = authStore();
const emits = defineEmits(['skip']);
const profilePicture = computed(() => get(user.value, 'profile_picture_url'));
const coverImage = computed(() => {
  const cover =
    changingCover.value && !temp.value
      ? changingCover.value
      : user.value?.cover_cropped_url
      ? user.value?.cover_cropped_url
      : '/empty.png';

  return cover;
});

const nextPage = () => {
  swiperRef?.value?.slideNext();
};

const prevPage = () => {
  swiperRef?.value?.slidePrev();
};
const onSwiperInstance = (v: any) => {
  swiperRef.value = v;
};

const coverSlider = computed(() => {
  return get(user.value?.customize_profile, 'sliding_cover_images');
});
const coverPreference = computed(() => {
  return get(user.value?.customize_profile, 'cover_preference') || '';
});

const changeCoverPopover = (ev: CustomEvent) => {
  popovers.open(ev, ChangeCoverPopover, { isUserProfile: true, restrictGif: true }, 'create-popover');
};
</script>

<style scoped lang="sass">
.navbtns
  display: flex
  position: absolute
  justify-content: space-between
  align-items: center
  height: 100% !important
  width: 100% !important
  top: 0
  bottom: 0
  z-index: 11
  pointer-events: none
  .clickable-area
    // full height of the enclosing div
    height: 2rem
    width: 2rem
    border-radius: 50%
    background-color: rgba(0, 0, 0, 0.75)
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    pointer-events: all
    color: white
    &:hover
      color: var(--ion-color-secondary)
  i
    pointer-events: none
    font-size: 1rem
.cover-txt
  width: 50%
  color: #afa7a7
  @media(max-width:360px)
    width:75%
.prf-sec
  padding: 10px 10px 0 10px !important

.profile
    width: 110px
    height: 110px
    top: 50px
    right: 0
    z-index: 100
    left: 0
    margin: auto
    border-radius: 60px
.cover
  height: 120px
  border-radius: 10px 10px 0 0
  overflow: hidden
  object-fit: cover
.indexer
  z-index: 2
.cover-wrap
  border-radius: 12px
.round-cover-button
  --padding-start: 8px
  --padding-end: 4px
  --ion-color-dark: rgba(10, 9, 40, 0.8)
  top: 9px
  right: 12px
  height: 24px
  font-size: 12px
  cursor: pointer
  z-index: 4
.dimmed
  z-index: 1
  width: 100%
  height: 100%
  background-color: rgba(0,0,0,0.6)
  pointer-events: none
  top: 0
  left: 0
.skip-btn
  text-decoration: underline
  font-size: 14px
.choose-sec
  font-size: 23px
  width: 50%
  @media(max-width:300px)
    width:75%
  .user-cover-image
    height: 140px
    width: 100%
    overflow: hidden
    position: relative
  img
    width: 100%
    height: 100%
    border-radius: 12px
    background-position: center
    background-size: cover
    object-fit: cover
</style>
