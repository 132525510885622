import { GetterTree } from 'vuex';
import { WorldsState } from './types';
import { RootState } from '@/shared/store';

export const getters: GetterTree<WorldsState, RootState> = {
  trendingWorlds(state: WorldsState) {
    return state.trendingWorlds;
  },

  trendingWorldsPaging(state: WorldsState) {
    return state.trendingWorldsPaging;
  },

  topWorlds(state: WorldsState) {
    return state.topWorlds;
  },
  topWorldsPaging(state: WorldsState) {
    return state.topWorldsPaging;
  },
  myWorlds(state: WorldsState) {
    return state.myWorlds;
  },
  myWorldsPaging(state: WorldsState) {
    return state.myWorldsPaging;
  },
  userWorlds(state: WorldsState) {
    return state.userWorlds;
  },
  userWorldsPaging(state: WorldsState) {
    return state.userWorldsPaging;
  },
  inActionWorld(state: WorldsState) {
    return state.inActionWorld;
  },
  latestWorlds(state: WorldsState) {
    return state.latestWorlds;
  },
  latestWorldsPaging(state: WorldsState) {
    return state.latestWorldsPaging;
  },

  reactionWorld(state: WorldsState) {
    return state.reactionWorld;
  },

  statusWorld(state: WorldsState) {
    return state.statusWorld;
  },
  hasUnreadWorldMsgs(state: WorldsState) {
    return state.hasUnreadWorldMsgs;
  },
};
