<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="py-3 px-3">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <div v-if="quizzesLogs.next_day - 1" style="font-weight: bolder; font-size: 20px">
                Day {{ quizzesLogs.next_day - 1 }} of this quiz is complete!
              </div>
              <div v-if="get(quizzesLogs, 'quiz.is_char_based')" class="mt-1">
                You've unlocked insights <span v-if="!isEmpty(get(quizzesLogs, 'character'))">about</span>
                <router-link
                  v-if="get(quizzesLogs, 'character.slug')"
                  :to="{ name: 'character-profile-new', params: { slug: get(quizzesLogs, 'character.slug') } }"
                  class="clickable mx-1"
                  @click="dismissModal"
                >
                  <span>{{ get(quizzesLogs.character, 'info.name') }}</span
                  >!
                </router-link>
              </div>
            </div>
            <div></div>

            <div>
              <ion-buttons slot="end">
                <ion-button class="close-button" @click="dismissModal()">
                  <i class="ti-close" />
                </ion-button>
              </ion-buttons>
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <div class="px-2 mt-3">
        <div v-for="(insight, index) in insights" :key="index" class="d-flex">
          <div>
            <div class="circle" :style="{ background: viewCardClr(index) }">Day {{ index + 1 }}</div>
            <div v-if="index !== insightLastIndex" class="line" :style="{ background: viewCardClr(index) }"></div>
          </div>

          <div class="flex-column w-100 px-2 quiz-card" :class="isEmpty(quizzesLogs.extra[index]) ? 'pb-4' : 'py-2'">
            <div class="card" :class="isEmpty(quizzesLogs.extra[index]) && isMobSmallScreen ? 'py-1' : 'py-2'">
              <div style="position: relative" v-if="!isDayUnlocked(index)">
                <div class="px-2 py-2 lock-icon ml-auto">
                  <i class="ti-lock" style="font-weight: bold" />
                </div>
              </div>

              <div
                :class="{ disabled: isEmpty(quizzesLogs.extra[index]) }"
                class="d-flex justify-content-center text-center align-items-center flex-column"
                :style="!isMobSmallScreen ? 'padding-right: 42px' : ''"
              >
                <div class="sub-title">{{ insight.title }}</div>
                <div class="text py-2">{{ insight.description }}</div>
                <div v-if="!isEmpty(quizzesLogs.extra[index])">
                  <ion-button class="view-btn" @click="viewInsight(index)">View</ion-button>
                </div>
              </div>
            </div>
            <div
              v-if="
                isEmpty(quizzesLogs.extra[index + 1]) &&
                !isEmpty(quizzesLogs.extra[index]) &&
                isEmpty(quizzesLogs.completed_at)
              "
              class="text-center py-2 mb-1"
            >
              Continue
              {{ getCurrentDateTime >= getNextDayDateTime ? `day ${get(quizzesLogs, 'next_day')}` : 'this' }}
              quiz

              <span v-if="!isEmpty(get(quizzesLogs, 'character'))">
                as
                <span style="color: var(--ion-color-primary)">
                  {{ get(quizzesLogs, 'character.info.name') }}
                </span>
              </span>
              {{ getCurrentDateTime >= getNextDayDateTime ? '' : 'tomorrow ' }}
              to unlock more insights!
            </div>
          </div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <div class="px-2 py-2">
            <span v-if="!isEmpty(quizzesLogs.completed_at)"
              >Quiz Completed {{ formatSlashDate(quizzesLogs.completed_at) }}</span
            >
            <span v-else-if="getCurrentDateTime >= getNextDayDateTime">
              <ion-button @click="continueModal" :disabled="!quizzesLogs.quiz.is_active">Continue</ion-button>
            </span>
            <span v-else>
              Come back in
              <strong style="color: var(--ion-color-primary)">
                <vue-countdown
                  v-slot="{ hours, minutes, seconds }"
                  :time="formatFromNowInMilliseconds(get(quizzesLogs, 'next_day_start_date'))"
                >
                  <span v-if="hours"> {{ hours < 10 ? `0${hours}` : hours }}:</span>
                  <span v-if="minutes">{{ minutes < 10 ? `0${minutes}` : minutes }}:</span
                  >{{ seconds < 10 ? `0${seconds}` : seconds }}
                </vue-countdown>
              </strong>
              to continue the quiz
              <span v-if="!isEmpty(quizzesLogs.character)">with {{ get(quizzesLogs, 'character.info.name') }} </span>
              and get more insights!
            </span>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import { getQuizzesInsights } from '@/shared/actions/quizzes';
import { formatSlashDate, formatFromNowInMilliseconds } from '@/shared/utils/dateTime';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  logId: {
    type: String,
    default: '',
  },
});
const emits = defineEmits(['close', 'view']);
const isOpen = toRef(props, 'isOpen');
const logId = toRef(props, 'logId');
const quizzesLogs: any = ref({});

const router = useRouter();
const currentTime = ref(new Date());

const updateTime = () => {
  currentTime.value = new Date();
};

const timeInterval = setInterval(updateTime, 1000);
watch(isOpen, () => {
  if (isOpen.value) {
    fetchQuizzesLog();
  } else {
    clearInterval(timeInterval);
  }
});
const dismissModal = () => {
  emits('close');
};

const getNextDayDateTime = computed(() => {
  const { getDateTime } = useCurrentTime(new Date(quizzesLogs.value.next_day_start_date));
  return getDateTime.value;
});

const getCurrentDateTime = computed(() => {
  const { getDateTime } = useCurrentTime(currentTime.value);
  return getDateTime.value;
});

const isDayUnlocked = (index: any) => {
  return (
    (index + 1 === quizzesLogs.value.next_day &&
      getCurrentDateTime.value >= getNextDayDateTime.value &&
      isEmpty(quizzesLogs.value.extra[index])) ||
    !isEmpty(quizzesLogs.value.extra[index])
  );
};

const viewCardClr = (index: any) => {
  return isDayUnlocked(index) ? 'var(--ion-color-primary)' : '#a6a9bd';
};

const viewInsight = (index: any) => {
  emits('view', quizzesLogs.value, index);
};

const insights = computed(() => {
  return quizzesLogs.value.quiz_info;
});

const fetchQuizzesLog = async () => {
  const resp = await getQuizzesInsights(logId.value);
  quizzesLogs.value = resp;
};
const continueModal = () => {
  router.push({
    name: 'quizzes',
    query: {
      quizId: quizzesLogs.value.quiz.id,
      logId: quizzesLogs.value.id,
      charId: get(quizzesLogs.value, 'character.id'),
    },
  });
  dismissModal();
};

const insightLastIndex = computed(() => {
  return insights.value.length - 1;
});
const isMobSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 550;
});
</script>
<style lang="sass" scoped>
.hide
  display: none
.dark .toolbar
  --background: #17074c !important
.lock-icon
  width: 31px
  position: absolute
  background: #172c44
  color: white
  border-radius: 8px
  text-align: center
  justify-content: center
  display: flex
  align-items: center
  right: 15px
  top: -18px
  @media(max-width:500px)
    top: -12px !important
  @media(max-width:300px)
    right: 9px !important

.disabled
  opacity: 0.5
  pointer-events: none
.view-btn
  --border-radius: 7px
  text-transform: none
  height: 34px
  width: 100%
  --background: #00b4c5
.lock-height
  height: 17px !important
  @media(max-width:469px) and (min-width:320px)
    height: 27px !important
  @media(max-width:320px)
    min-height: 37% !important
.last-comp-line
  height: 49px !important
.line
  position: relative
  width: 5px
  left: 38px
  height: 100%
  z-index: 1

.circle
  position: relative
  z-index: 2
  overflow: hidden
  border-radius: 40px
  height: 84px
  width: 82px
  line-height: 81px
  text-align: center
  font-weight: 500
  color: white
  font-size: 20px
.changed-border
  border: 2px solid var(--ion-color-primary) !important
.card
  background: white
  height: auto
  width: 100%
.dark .card
  background: #010123 !important
.modal-inner-content
  background: #e6e6e6
  max-height: 70dvh
  overflow: auto
  @media(max-width:300px)
    max-height: 65dvh
.ios .modal-inner-content
  max-height: 60dvh !important
  background: #e6e6e6
  overflow: auto
.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.sub-title
  font-size: 18px
  font-weight: bold
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  padding-left: 55px
  padding-right: 0px
.ans-border
  border: 2px solid gray
  border-radius: 7px
</style>
