<template>
  <New 
    :type="type" 
    :showBox="showBox" 
    :progressId="progressId" 
    :disabled="disabled"
    @updated="emits('updated')"
    @delete="deleteCurrGiftBox"
  >
    <slot />
  </New>
</template>

<script lang="ts" setup>
import New from './new.vue';

defineProps({
  showBox: {
    type: Boolean,
    default: false,
  },
  progressId: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'character',
  },
  disabled: {
    type: Boolean,
    default: false,
  }
});

const emits = defineEmits(['updated', 'delete']);
const deleteCurrGiftBox = () => {
  emits('delete');
};
</script>
