<template>
  <div
    ref="reactions"
    class="position-relative reactions-area d-flex align-items-center"
    style="z-index: 9999"
    :class="{ disabled }"
  >
    <div
      id="reacts-btn"
      :class="{ throttling }"
      v-hammer:pan="panning"
      v-hammer:panend="panEnd"
      v-hammer:pressup="hide"
      v-hammer:press="show"
      class="reacts d-flex align-items-center"
      @mouseenter="isHoveringOver"
      @mouseleave="isNotHoveringOver"
      @click="(e) => react(e, 'lov', true)"
      @contextmenu.prevent.stop
    >
      <div ref="likeRef" class="like-btn d-flex align-items-center">
        <slot />
      </div>
    </div>
    <CurrencyGiftAnimationModal :is-open="isOpenCurrAnimationModal" @close="closeAnimationModal" />
    <DisplayGiftItemsModal
      :currency="currencyData"
      :is-open="isOpenCurrGiftModal"
      @close="openHintModal"
      @dismiss-modal="dismissModal"
    />
    <CurrencyHintModal :is-open="isOpenHintCurrModal" @close="closeHintModal" @dismiss-modal="dismissHintModal" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import DisplayGiftItemsModal from '@/shared/modals/DisplayGiftItemsModal.vue';
import CurrencyHintModal from '@/shared/modals/CurrencyHintModal.vue';
import CurrencyGiftAnimationModal from '@/shared/modals/CurrencyGiftAnimationModal.vue';
import { ReactionCounts, UserReaction } from '@/shared/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';
import { popovers } from '@/shared/native/popovers';
import { uiStore } from '@/shared/pinia-store/ui';
import { nanoid } from 'nanoid';
import ReactionsPopover from './popover.vue';
import { toast } from '@/shared/native/toast';
import { postCurrencyOpenStatus } from '@/shared/actions/currency';

@Options({
  name: 'New',
  components: { DisplayGiftItemsModal, CurrencyHintModal, CurrencyGiftAnimationModal },
})
export default class New extends Vue {
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: () => ({}) }) public reactions!: ReactionCounts;
  @Prop({ default: () => ({}) }) public userReaction!: UserReaction;
  @Prop({ default: false }) public showBox!: boolean;
  @Prop({ default: '' }) public progressId!: string;
  @Prop({ default: 'character' }) public type!: string;

  public isOpenCurrGiftModal = false;
  public isOpenHintCurrModal = false;
  public isOpenCurrAnimationModal = false;
  public throttling = false;
  public currencyData = {};
  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public instance: HTMLIonPopoverElement | null = null;

  @Watch('instance')
  instanceChanged(val: any) {
    if (!val) {
      this.instance = null;
    }
  }
  async openGiftBox() {
    try {
      if (this.progressId) {
        const resp = await postCurrencyOpenStatus({ progress_box: this.progressId });
        this.currencyData = resp;
        this.isOpenCurrAnimationModal = true;
      }
    } catch (_err: any) {
      toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }
  }

  public closeAnimationModal() {
    this.isOpenCurrAnimationModal = false;
    this.isOpenCurrGiftModal = true;
  }
  @Watch('reacting')
  async reactingHappening(val: any) {
    try {
      if (val) {
        if (this.instance) {
          return;
        }

        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
          if (this.instance) {
            return;
          }

          this.instance = await popovers.open(
            val,
            ReactionsPopover,
            {
              userReaction: this.userReaction,
              reactions: this.reactions,
              rID: this.rID,
              progressId: this.progressId,
              showBox: this.showBox,
              type: this.type,
            },
            'reaction-popover',
            false
          );

          const res = await this.instance?.onDidDismiss();

          this.throttling = true;

          setTimeout(() => {
            this.throttling = false;
          }, 500);

          this.instance = null;

          if (res?.data === 'updated') {
            this.openGiftBox();
          } else if (res?.data) {
            this.$emit('changed', res.data);
          }
        }, 500);
      }

      if (!val && !this.instance) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.instance?.dismiss();
          this.instance = null;
          this.reacting = false;
        }, 2000);
      }
    } catch (error) {}
  }

  public rID = nanoid(8);
  public iconsReactions: any[] = [];
  public hoveredReactionIndex = 999;
  public isPanning = false;
  public reacting = false;
  public working = false;
  public isHovering = false;
  public timer: any = null;

  public isHoveringOver(e: any) {
    if (this.disabled) return;
    const { setHoverOverReactOn } = uiStore();
    setHoverOverReactOn(this.rID);
    this.setReacting(e);
  }

  public react(e: any, key: string, isInstant = false) {
    if (this.disabled) return;
    if (!this.isAuthenticated) {
      toast.show('Sign in to react to content!', 'nonative', 'primary');
      return;
    }
    this.setReacting(e);
    this.$emit('changed', { key, isInstant });
  }

  public isNotHoveringOver() {
    const { setHoverOverReactOff } = uiStore();
    setHoverOverReactOff();
    this.setReacting(null);
  }

  public panEnd() {
    if (this.hoveredReactionIndex < 999) {
      this.hoveredReactionIndex = 999;
    } else {
      this.close();
    }
  }

  public closeHintModal() {
    this.isOpenHintCurrModal = false;
    this.isOpenCurrGiftModal = false;
    setTimeout(() => {
      if (get(this.currencyData, 'refresh_existing')) {
        this.$emit('updated');
      } else {
        this.$emit('delete');
      }
    }, 10);
  }

  public dismissHintModal(val: any) {
    if (get(val, 'detail.role') === 'backdrop') {
      this.closeHintModal();
    }
  }
  public openHintModal() {
    this.isOpenHintCurrModal = true;
  }

  public dismissModal(val: any) {
    if (get(val, 'detail.role') === 'backdrop') {
      this.openHintModal();
    }
  }

  public panning() {
    if (this.disabled) return;
    this.isPanning = true;
  }

  public close(event?: any) {
    if (event) {
      const elId = event.target?.id;
      if (elId === 'toolbox') {
        return;
      }
    }
    this.setReacting(false);
  }

  public show(e: any) {
    if (this.disabled) return;
    this.isPanning = false;
    this.setReacting(e);
  }

  public hide() {
    if (this.disabled) return;
    this.isPanning = false;
    this.setReacting(null);
  }

  public setReacting(value: any) {
    if (this.disabled) return;
    this.reacting = value;
  }
}
</script>

<style scoped lang="sass">
.throttling,.disabled
  pointer-events: none

.like-btn
  color: #214163 !important
  overflow: auto !important
  ::v-deep
    *
      user-select: none !important
  .icon
    color: #214163 !important
</style>
