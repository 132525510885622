import { Module } from 'vuex';
import { RootState } from '../../index';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ListingState } from './types';

const state: ListingState = {
  inActionList: {},
  marketplaceListings: [],
  marketplaceListingsPaging: {},
  marketplaceListingsPage: 0,
};

export const ListingModule: Module<ListingState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
