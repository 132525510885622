import type { Paging } from '@/shared/types/static-types';
import { chAxios } from '@/shared/lib/axios';

import { sendAnalyticsEvent } from '@/shared/services/analytics';
import constants from '@/shared/statics/constants';

export async function getRolechats(page = 1, params = {}) {
  const url = `/rolechats/`;
  try {
    const { data } = await chAxios().get(url, { params: { page, ...params } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRolechatNextPage(paging: Paging) {
  const url = paging.next;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRolechatRoom(roomId: string) {
  try {
    const { data } = await chAxios().get(`/rolechats/${roomId}/`);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateRolechatRoom(roomId: string, roomData: {}) {
  try {
    const { data } = await chAxios().patch(`/rolechats/${roomId}/`, roomData);

    sendAnalyticsEvent('Updated rolechat room');

    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRolechatRequests(page = 1, pageSize?: number) {
  const url = `/rolechats/requests/`;
  try {
    const { data } = await chAxios().get(url, { params: { page, page_size: pageSize } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRolechatProfile() {
  const url = `/rolechats/profile/my-profile/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRolechatRequest(id: string) {
  const url = `/rolechats/requests/${id}/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateRolechatReqStatus(id: string, status: string) {
  const url = `/rolechats/requests/${id}/update-status/`;

  try {
    const { data } = await chAxios().patch(url, { status });

    if (status === constants.rolechatReqStatuses.accepted) {
      sendAnalyticsEvent('Accepted rolechat request');
    } else {
      sendAnalyticsEvent('Denied rolechat request');
    }

    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRolechatProfileStatus() {
  try {
    const { data } = await chAxios().get(`/rolechats/profile/status/`);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createRolechatProfile(profileData: {}) {
  try {
    const { data } = await chAxios().post(`/rolechats/profile/`, profileData);

    sendAnalyticsEvent('Created rolechat profile');

    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateRolechatProfile(
  id: string,
  profileData: {
    ideal_message_len: string;
    fav_types: string[];
    looking_for: string;
    sample_text: string;
  }
) {
  try {
    const { data } = await chAxios().patch(`/rolechats/profile/${id}/`, profileData);

    sendAnalyticsEvent('Updated rolechat profile');

    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRolechatRandStarter() {
  try {
    const { data } = await chAxios().get(`/rolechats/starters/random-starter/`);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createRolechatStarter(starterData: {}) {
  try {
    const { data } = await chAxios().post(`/rolechats/starters/`, starterData);

    sendAnalyticsEvent('Created rolechat starter');

    return data;
  } catch (e) {
    throw e;
  }
}

export async function acceptReqRolechatStarter(reqData: {
  rolechat_starter?: string;
  message?: string;
  character?: string;
}) {
  try {
    const { data } = await chAxios().patch(`/rolechats/starters/rolechat-req/`, reqData);

    if (reqData.rolechat_starter) {
      sendAnalyticsEvent('Liked rolechat starter');
    } else {
      sendAnalyticsEvent('Sent character rolechat request');
    }

    return data;
  } catch (e) {
    throw e;
  }
}

export async function declineRolechatStarter(id: string) {
  try {
    const { data } = await chAxios().patch(`/rolechats/starters/${id}/decline-starter/`, {});

    sendAnalyticsEvent('Disliked rolechat starter');

    return data;
  } catch (e) {
    throw e;
  }
}

export async function sendRolechatMessage(roomId: string, messageData: any) {
  try {
    const { data } = await chAxios().post(`/rolechats/${roomId}/send-message/`, messageData);

    sendAnalyticsEvent('Sent rolechat message');

    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRolechatRoomMsgs(roomId: string, page = 1, pageSize = 100) {
  const url = `/rolechats/${roomId}/messages/`;
  try {
    const { data } = await chAxios().get(url, { params: { page, page_size: pageSize } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function markRoomMsgsRead(roomId: string) {
  try {
    const { data } = await chAxios().patch(`/rolechats/${roomId}/mark-all-read/`);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function hasUnreadRolechatMsgs() {
  try {
    const { data } = await chAxios().get(`/rolechats/has-unread-msgs/`);
    return data;
  } catch (e) {
    throw e;
  }
}
