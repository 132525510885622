import { useSafeEnv } from './useSafeEnv';

export const useRobotsCheck = () => {
  const { env, appType } = useSafeEnv();

  if (appType.value === 'mobile') return

  const isProduction = env.value === 'production';
  const finalContent = computed(() => (isProduction ? `` : 'noindex,nofollow,noai,noimageai'));

  return useHead({
    meta: [
      {
        name: 'robots',
        content: finalContent,
      },
    ],
  });
};
