<template>
  <div v-for="(trait, traitIndex) in traits" :key="traitIndex" class="d-flex flex-column">
    <div class="d-flex">
      <div class="w-100 mt-2">
        <div class="text">
          {{ trait.title }}:
          <span v-if="trait.is_added" style="color: var(--ion-color-primary)">Added to Profile</span>
        </div>
        <div class="px-2 mr-2 mt-1 traits" :class="{ 'changed-traits': trait.is_added }">{{ trait.val }}</div>
      </div>
      <div v-if="isCharBased" class="ml-auto">
        <div
          class="px-3 add-profile-btn clickable-item d-flex align-items-center text-center justify-content-center"
          :class="{ 'changed-prof-btn': trait.is_added }"
          @click="addToProfile(traitIndex)"
        >
          <span v-if="!trait.is_added"> <i class="ti-plus mr-2 icon" />Add to Profile</span>
          <span v-else>Remove</span>
        </div>
      </div>
    </div>
    <div v-if="!isEmpty(trait.description)" class="px-2 py-2 mt-1 desc">
      <div v-html="sanitizeHtml(trait.description)" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { sanitizeHtml } from '@/shared/utils/html';
const props = defineProps({
  traits: {
    type: Array,
    default: [],
  },
  isCharBased: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['added']);
const traits: any = toRef(props, 'traits');
const addToProfile = (index: any) => {
  emits('added', index);
};
</script>

<style lang="sass" scoped>
.desc
  background: white
  border-radius: 10px
.dark .desc
  background: #010123 !important
  color: white !important
.icon
  font-size: 12px
  font-weight: bolder
  @media(max-width:550px)
    font-size: 11px
.changed-prof-btn
  background: #8928c4 !important
  color: white !important
  border: none !important
  padding-top: 0.55rem !important
  padding-bottom: 0.55rem !important
  margin-top: 20px !important
.add-profile-btn
  border: 3px solid var(--ion-color-primary)
  border-radius: 28px
  color: var(--ion-color-primary)
  font-weight: bold
  max-width: 100%
  width: 145px
  text-wrap: nowrap !important
  margin-top: 22px !important
  padding-top: 0.45rem
  padding-bottom: 0.45rem
  @media(max-width:550px)
    font-size: 14px
.text
  @media(max-width:500px)
    display: flex
    flex-direction: column
.changed-traits
  background: var(--ion-color-primary)
  color: white
.traits
  color: var(--ion-color-primary)
  background: white
  border-radius: 20px
  padding-bottom: 0.4rem
  padding-top: 0.4rem
  margin-top: 1px
  width: calc(100% - 8px)
  font-weight: bold

.dark .traits
  background: #010123 !important
</style>