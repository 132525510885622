import { cacheKeys } from '@/shared/statics/constants';

export async function getLatestAnnouncement() {
  try {
    const cacheKey = cacheKeys.announcements;
    const value = await useChFetch<any>(cacheKey, `/announcements/latest/`, undefined, 5000 * 60);

    return value;
  } catch (e) {
    throw e;
  }
}
