import { ActionTree } from 'vuex';
import { ListingState } from './types';
import { RootState } from '@/shared/store';
import {
  editListing,
  createPostlisting,
  getMarketplaceListings,
  GetListingFromSlug,
  getpostlisting,
} from '@/shared/actions/marketplace';
import { sendAnalyticsEvent } from '@/shared/services/analytics';
import constants from '@/shared/statics/constants';

export const actions: ActionTree<ListingState, RootState> = {
  async getListingFromSlug({ commit }, slug: string) {
    const inActionList = await GetListingFromSlug(slug);
    commit('SET_IN_ACTION_LISTS', inActionList);
    return inActionList;
  },

  async getList({ commit }, id: string) {
    const inActionList = await getpostlisting(id);
    commit('SET_IN_ACTION_LISTS', inActionList);
    return inActionList;
  },

  async getHomeListings({ commit }, { page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getMarketplaceListings(page, pageSize);
    commit('SET_HOME_LISTINGS', { results, paging });
  },

  async homeListingsNextPage({ commit, state }) {
    const { marketplaceListingsPage = 0 } = state;
    const { results, ...paging } = await getMarketplaceListings(marketplaceListingsPage + 1);
    commit('APPEND_HOME_LISTINGS', { results, paging });
  },

  async createMarketplaceListing(_obj, { listingData }) {
    const data = await createPostlisting(listingData);
    sendAnalyticsEvent('Marketplace listing created');
    return data;
  },

  async updatelisting(
    _obj,
    { id, title, base_price, description, usage_term, visuals, category, is_nsfw, is_active, tags }
  ) {
    return editListing(id, { title, base_price, description, usage_term, visuals, category, is_nsfw, is_active, tags });
  },
};
