import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tMGwffvjBc from "/app/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_AUP22rrBAc from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _3rdPartyLibs_client_kSFkHUAaKq from "/app/plugins/3rdPartyLibs.client.ts";
import captcha_client_JrcUjqxh5z from "/app/plugins/captcha.client.ts";
import checkForLoadError_GBkQl5LBx6 from "/app/plugins/checkForLoadError.ts";
import components_client_NynTgMti2N from "/app/plugins/components.client.ts";
import crisp_client_DCubnCdDP5 from "/app/plugins/crisp.client.ts";
import directives_client_23ySUe5jkO from "/app/plugins/directives.client.ts";
import errorHandler_client_HLECVax37c from "/app/plugins/errorHandler.client.ts";
import init_client_lHDJZN4lq2 from "/app/plugins/init.client.ts";
import masonary_client_aE61i5VvNH from "/app/plugins/masonary.client.ts";
import pinia_persistedstate_IFGYgTMLE7 from "/app/plugins/pinia-persistedstate.ts";
import posthog_client_fUucxKWhZE from "/app/plugins/posthog.client.ts";
import store_9xNuDHGAVU from "/app/plugins/store.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tMGwffvjBc,
  plugin_AUP22rrBAc,
  _3rdPartyLibs_client_kSFkHUAaKq,
  captcha_client_JrcUjqxh5z,
  checkForLoadError_GBkQl5LBx6,
  components_client_NynTgMti2N,
  crisp_client_DCubnCdDP5,
  directives_client_23ySUe5jkO,
  errorHandler_client_HLECVax37c,
  init_client_lHDJZN4lq2,
  masonary_client_aE61i5VvNH,
  pinia_persistedstate_IFGYgTMLE7,
  posthog_client_fUucxKWhZE,
  store_9xNuDHGAVU
]