<template>
  <div class="sections" v-auto-animate>
    <div class="onboarding" v-if="showOnboarding">
      <div v-if="currentStep === 3">
        <div class="msg d-flex">
          <div style="width: 60px; height: 60px">
            <img class="image ml-2" src="/onboarding/hub-c-left.png" />
          </div>
          <div class="showcase">
            “Showcase your characters via&nbsp;<span class="color-pink">Customizable Profiles</span>."
          </div>
          <div>
            <ion-button class="next-btn w-100 px-2" @click="stepForward">Next </ion-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onboardingStore } from '@/shared/pinia-store/onboarding';

const { currentStep, stepForward, showOnboarding, showOverlay } = onboardingStore();
</script>

<style scoped lang="sass">
.next-btn
  opacity: 1
  height: 50px
  --border-radius: 10px
  --background: linear-gradient(to right,rgb(23 219 233 / 84%) 9%,rgb(22 173 227 / 182%) 56%,rgb(3 168 227) 98%,rgb(3 168 227) 100%)


.image
  width: 100%
  height: 100%
  object-fit: contain
.color-pink
  color: var(--ch-primary-color)
.onboarding
  position: inherit
  width: 100vw
  height: 100%
  pointer-events: none
  .showcase
   @media(max-width: 311px)
     font-size: 11px
  .msg
    pointer-events: all
    width: 535px
    height: 78px
    position: absolute
    z-index: 2
    font-size: 14px
    color: var(--ch-black-color)
    font-weight: bold
    text-align: center
    background: rgba(251, 242, 201, 1)
    border-radius: var(--border-radius-sm)
    min-height: 40px
    align-items: center
    justify-content: space-between
    box-shadow: 0px 0px 8px 0px rgba(1, 1, 35, 0.75)
    left: 15px
    top: 70px
    cursor: pointer
    left: 0
    right: 0
    margin: auto
    @media(max-width: 500px)
      width: 314px
    @media(max-width: 311px)
      width: calc(100% - 39px)
.sections
  width: 100vw
  height: var(--doc-height)
  pointer-events: none
  z-index: 5000
  position: absolute
</style>
