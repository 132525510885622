import * as amplitude from '@amplitude/analytics-browser';

export const useAnalytics = () => {
  const trackAmplitudeEvent = (eventName: string, params?: any, userId?: string) => {
    const {
      public: { env },
    } = useRuntimeConfig();
    if (env === 'development') {
      // eslint-disable-next-line no-console
      console.info('trackAmplitudeEvent: send ' + eventName, params);
      return;
    }

    const opts = {
      time: Date.now(),
    };
    try {
      amplitude.track(eventName, params, opts);
    } catch (e) {}
  };

  const trackPosthogEvent = (eventName: string, params?: any, userId?: string) => {
    const {
      public: { env },
    } = useRuntimeConfig();
    if (env === 'development') {
      // eslint-disable-next-line no-console
      console.info('sendAnalyticsEvent (Posthog): ' + eventName, params);
      return;
    }

    const { $posthog } = useNuxtApp();
    if ($posthog) {
      const posthog = $posthog();

      try {
        posthog?.capture(eventName, params);
      } catch (e) {}
    }
  };

  const trackFacebookEvent = (event: string, params?: any, userId?: string) => {
    try {
      if (!process.client) {
        return;
      }

      // @ts-ignore
      window.fbq('track', event, params);
    } catch (error) {}
  };

  const trackTiktokEvent = (event: string, params?: any, userId?: string) => {
    try {
      if (!process.client) {
        return;
      }

      // @ts-ignore
      window.ttq.track(event, params);
    } catch (error) {}
  };

  const trackEvent = (event: string, params?: any, skipPixelSources?: boolean) => {
    const {
      public: { env },
    } = useRuntimeConfig();

    if (env !== 'production') {
      event = 'test_' + event;
    }

    if (skipPixelSources) {
      trackAmplitudeEvent(event, params);
      trackPosthogEvent(event, params);
      return;
    }

    trackFacebookEvent(event, params);
    trackTiktokEvent(event, params);
    trackAmplitudeEvent(event, params);
    trackPosthogEvent(event, params);
  };

  return {
    trackAmplitudeEvent,
    trackFacebookEvent,
    trackTiktokEvent,
    trackEvent,
    trackPosthogEvent,
  };
};
