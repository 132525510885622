import { Preferences } from '@capacitor/preferences';

export const useSessionStorage = async <T>(key: string) => {
  const returned = ref<T | undefined>();

  const { value } = await Preferences.get({ key });
  const valueFromStorage = value ? (JSON.parse(value) as T) : undefined;

  returned.value = valueFromStorage;

  const set = async (value: T) => {
    await Preferences.set({ key, value: JSON.stringify(value) });
    returned.value = value;
  };

  const clear = async (key: string) => {
    await Preferences.remove({ key });
  };
  const unset = clear;
  return { returned, set, clear, unset };
};
