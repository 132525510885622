<template>
  <div>
    <div v-if="isLoading" class="d-flex align-items-center justify-content-center mx-auto"><ChLoading /></div>
    <div v-else class="p-3">
      <div class="d-flex align-items-center mb-2 justify-content-between">
        <div class="back clickable-item-hov" @click="goBack">Back</div>
        <div class="title">Which age group are you in?</div>
        <div />
      </div>
      <ion-list class="bg-transparent">
        <ion-radio-group v-model="ageRange">
          <div class="d-flex flex-column">
            <ion-item
              :class="{ selected: ageRange === '18 - 25' }"
              class="bg-transparent d-flex action-btn"
              lines="none"
              @click="save('18 - 25')"
            >
              <ion-radio slot="start" value="18 - 25" style="margin-inline-end: 15px" class="radio-btn" />
              <div
                class="privacy-text d-flex mx-auto justify-content-center align-items-center text-center clickable-item-hov"
                :class="{ 'selected-text': ageRange === '18 - 25' }"
              >
                18 - 25 Yrs
              </div>
            </ion-item>

            <ion-item
              class="bg-transparent d-flex action-btn"
              lines="none"
              :class="{ selected: ageRange === '26 - 30' }"
              @click="save('26 - 30')"
            >
              <ion-radio slot="start" value="26 - 30" style="margin-inline-end: 15px" class="radio-btn" />
              <div
                class="privacy-text d-flex mx-auto justify-content-center align-items-center text-center clickable-item-hov"
                :class="{ 'selected-text': ageRange === '26 - 30' }"
              >
                26 - 30 Yrs
              </div>
            </ion-item>
            <ion-item
              class="bg-transparent d-flex action-btn"
              :class="{ selected: ageRange === '31 - 35' }"
              lines="none"
              @click="save('31 - 35')"
            >
              <ion-radio slot="start" value="31 - 35" style="margin-inline-end: 15px" class="radio-btn" />
              <div
                :class="{ 'selected-text': ageRange === '31 - 35' }"
                class="privacy-text d-flex mx-auto justify-content-center align-items-center text-center clickable-item-hov"
              >
                31 - 35Yrs
              </div>
            </ion-item>
            <ion-item
              class="bg-transparent d-flex action-btn"
              :class="{ selected: ageRange === '36 - 40' }"
              lines="none"
              @click="save('36 - 40')"
            >
              <ion-radio slot="start" value="36 - 40" style="margin-inline-end: 15px" class="radio-btn" />
              <div
                :class="{ 'selected-text': ageRange === '36 - 40' }"
                class="privacy-text d-flex mx-auto justify-content-center align-items-center text-center clickable-item-hov"
              >
                36 - 40 Yrs
              </div>
            </ion-item>
            <ion-item
              class="bg-transparent d-flex action-btn"
              :class="{ selected: ageRange === '41+' }"
              lines="none"
              @click="save('41+')"
            >
              <ion-radio slot="start" value="41+" style="margin-inline-end: 15px" class="radio-btn" />
              <div
                :class="{ 'selected-text': ageRange === '41+' }"
                class="privacy-text d-flex mx-auto justify-content-center align-items-center text-center clickable-item-hov"
              >
                41+ Yrs
              </div>
            </ion-item>
            <ion-item
              class="bg-transparent d-flex action-btn clickable-item-hov"
              :class="{ selected: ageRange === '' }"
              lines="none"
              @click="save('')"
            >
              <ion-radio slot="start" value="" style="margin-inline-end: 15px" class="radio-btn clickable-item-hov" />
              <div
                :class="{ 'selected-text': ageRange === '' }"
                class="privacy-text d-flex mx-auto justify-content-center align-items-center text-center clickable-item-hov"
              >
                Prefer not to say
              </div>
            </ion-item>
          </div>
        </ion-radio-group>
      </ion-list>
      <div class="d-flex justify-content-center align-items-center text-center mx-auto py-3">
        <small class="small-text">You can change this later on your profile.</small>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UserProfile } from '@/shared/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';
import { profileStore } from '@/shared/pinia-store/profile';
import { onboardingStore } from '~/shared/pinia-store/onboarding';
import ChLoading from '~/shared/components/ChLoading.vue';

const { updateUserProfile } = profileStore();
const { user } = authStore();
const { stepBackward } = onboardingStore();
const emits = defineEmits(['forward']);
const ageRange = ref<any>(null);
const isLoading = ref(false);
watch(
  user,
  () => {
    if (user.value) {
      ageRange.value = get(user.value, 'age_range');
    }
  },
  { immediate: true }
);
const goBack = () => {
  isLoading.value = true;
  setTimeout(() => {
    stepBackward(1);
  }, 10);
};
const save = (age: any) => {
  ageRange.value = age;
  const data: Partial<UserProfile> = {
    age_range: ageRange.value || '',
  };
  setTimeout(() => {
    updateUserProfile(data);
    emits('forward');
  }, 1000);
};
</script>

<style lang="sass" scoped>
.back
  text-decoration: underline
  color: #AE38E5
.small-text
  color: #A7AABE
  font-weight: bold
.selected-text
  color: #fff !important
.privacy-text
  font-size: 16px
  font-weight: bold
  color: #7050B7
.action-btn
  border-radius: 30px
  --background: transparent
  color: #000000
  margin:5px
  border: 2px solid #ae38e5
  display: flex
  justify-content: center
  align-items: center


.radio-btn
  display: none

.selected
  --background: #ae38e5 !important
  background: #ae38e5 !important
  color: #ffffff

.title
  font-size: 22px
  font-weight: bold
  color: #214163
  @media(max-width: 500px)
    font-size: 16px
.dark .title
  color: #ffffff

.flex-column
  flex-direction: column
</style>
