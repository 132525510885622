<template>
  <ion-modal
    class="modal-small"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="inner-content py-3 px-4">
      <div class="d-flex justify-content-between">
        <div class="title">Submit FanWork</div>
        <ion-buttons>
          <ion-button class="close-button text-black" @click="dismissModal()">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </div>

      <div class="fan-work w-100 d-flex align-items-center">
        <div class="d-flex">
          <template v-for="(fanwork, index) in submitFanWork" :key="index">
            <ion-card
              class="fan-work-card px-3 py-3 clickable-item-hov"
              :class="!index ? 'mr-1 ml-0' : 'mr-0 ml-1'"
              @click="selectFanWorkType(fanwork.name)"
            >
              <div class="name">
                {{ fanwork.title }}
              </div>
              <div class="description mt-2">
                {{ fanwork.description }}
              </div>
            </ion-card>
          </template>
        </div>
      </div>
    </div>
  </ion-modal>
</template>
 
<script lang="ts" setup>
const props = defineProps({
  isOpen: { type: Boolean, default: false }
});

const emit = defineEmits(['selected', 'dismissModal']);

const isOpen = toRef(props, 'isOpen');

const submitFanWork = [
  {
    title: 'Submit FanArt',
    description: 'Tradtional Art, Digital Art, Comic or any other form of fanwork primarily graphic.',
    name: 'art',
  },
  {
    title: 'Submit Other FanWork',
    description: 'Fanfic, Drabble, Poem, Music, or any other form of fanwork besides FanArt.',
    name: 'other',
  },
];

function selectFanWorkType(name: any) {
  emit('selected', name);
}

function dismissModal() {
  emit('dismissModal');
}
</script>

<style lang="sass" scoped>
.fan-work
  overflow: unset !important
  height: calc(100% - 100px)

.fan-work-card
  width: 50%
  background: var(--ion-color-primary)
  color: white

  .name
    font-size: 18px
    font-weight: bold
    word-break: break-word
    width: 100%
  .description
    font-size: 12px
    color: white
.title
  font-weight: bold
  font-size: 24px
.close-button
  width: 50px

.modal-small
  --width: 540px
  --height: auto
  @media(max-width: 560px)
    --width: 95%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 80vh
  overflow: auto
</style>
