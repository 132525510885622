import { GetterTree } from 'vuex';
import { RolechatState } from './types';
import { RootState } from '@/shared/store';

export const getters: GetterTree<RolechatState, RootState> = {
  rolechats(state: RolechatState) {
    return state.rolechats;
  },
  rolechatsPaging(state: RolechatState) {
    return state.rolechatsPaging;
  },
  unreadRolechatsCount(state: RolechatState) {
    return state.unreadRolechatsCount;
  },
  hasUnreadRolechats(state: RolechatState) {
    return state.hasUnreadRolechats;
  },
};
