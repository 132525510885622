import { popoverController } from '@ionic/vue';

export const popovers = {
  async open(event: any, component: any, componentProps: any = {}, additionalClass: string = '', showBackdrop = true) {
    const popover = await popoverController.create({
      component,
      componentProps,
      cssClass: `popover-cmfl ${additionalClass}`,
      event,
      mode: 'ios',
      alignment: 'center',
      showBackdrop,
      animated: true,
    });

    if (popover.componentProps) {
      popover.componentProps.instance = popover;
    }
    popover.showBackdrop = false;
    await popover.present();

    return popover;
  },
};
