import { chAxios } from '@/shared/lib/axios';

export async function getBlockedEntities() {
  const { data } = await chAxios().get(`/blocked-entities/`);
  return data;
}

export async function blockEntity(entityType: string, entityId: string) {
  const { data } = await chAxios().post(`/blocked-entities/`, {
    blocked_entity_type: entityType,
    blocked_entity_id: entityId,
  });
  return data;
}

export async function unblockEntity(id: string) {
  const { data } = await chAxios().delete(`/blocked-entities/${id}`);
  return data;
}
