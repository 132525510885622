import { ActionTree } from 'vuex';
import { SiteEventTaskSubmission, UserReaction, ReactionCounts } from '@/shared/types/static-types';
import { SiteEventState } from './types';
import { RootState } from '@/shared/store';
import {
  getPastEvents,
  getActiveEvents,
  getEventDetails,
  getEventTask,
  getEventTaskPoll,
  getUserTaskSubmissions,
  submitToEventTask,
  getNextPage,
} from '@/shared/actions/events';
import {
  deleteReactionOnTaskSubmission,
  updateReactionOnTaskSubmission,
  createReactionOnTaskSubmission,
} from '@/shared/actions/reactions';

export const actions: ActionTree<SiteEventState, RootState> = {
  async createEventTaskSubmission({ commit }, { id, payload }: { id: string; payload: any }) {
    const submission = await submitToEventTask(id, payload);
    commit('SET_IN_ACTION_CHARACTERS_DRAFTS', submission);
    return submission;
  },
  async getUserEventTaskSubmissions({ commit }, id: string) {
    const { results, ...paging } = await getUserTaskSubmissions(id);
    commit('SET_SUBMISSIONS', { results, paging });
  },
  async userEventTaskSubmissionsNextPage({ commit, state }) {
    const { submissionsPaging } = state;
    const { results, ...paging } = await getNextPage(submissionsPaging);
    commit('APPEND_SUBMISSIONS', { results, paging });
  },
  async getActiveEvents({ commit }) {
    const { results, ...paging } = await getActiveEvents();
    commit('SET_SITE_EVENTS', { results, paging });
  },
  async activeEventsNextPage({ commit, state }) {
    const { siteEventsPaging } = state;
    const { results, ...paging } = await getNextPage(siteEventsPaging);
    commit('APPEND_SITE_EVENTS', { results, paging });
  },
  async reactToTaskSubmission(
    { commit },
    {
      reaction,
      submission,
      isInstant = false,
    }: { reaction: string; submission: SiteEventTaskSubmission; isInstant: boolean }
  ) {
    const newReaction = await sendReactionToBackend(reaction, submission, isInstant);
    const updatedReactionsData = updateReactionsDataForSubmission(reaction, submission, newReaction);
    return { newReaction, updatedReactionsData };
  },
};

const isUserReactedSameReaction = (
  reaction: string,
  submission: SiteEventTaskSubmission,
  isInstant = false
): boolean => {
  const { user_reaction } = submission;
  return !!((user_reaction?.id && user_reaction.reaction === reaction) || isInstant);
};

const isUserReactedAnotherReaction = (reaction: string, submission: SiteEventTaskSubmission): boolean => {
  const { user_reaction } = submission;
  return !!(user_reaction?.id && user_reaction?.reaction !== reaction);
};

const sendReactionToBackend = async (reaction: string, submission: SiteEventTaskSubmission, isInstant: boolean) => {
  const target = 'tasksubmission';
  const { user_reaction, id: submissionId = '' } = submission;
  // delete
  if (isUserReactedSameReaction(reaction, submission, isInstant)) {
    return await deleteReactionOnTaskSubmission({ reactionId: user_reaction?.id || '' });
  }

  // patch
  if (isUserReactedAnotherReaction(reaction, submission)) {
    return await updateReactionOnTaskSubmission({ reactionId: user_reaction?.id || '', reaction });
  }

  // post
  const data = await createReactionOnTaskSubmission({ objectId: submissionId, reaction, target });
  return data;
};

const updateReactionsDataForSubmission = (
  reaction: string,
  submission: SiteEventTaskSubmission,
  newReaction: UserReaction
): Partial<SiteEventTaskSubmission> => {
  const submissionData = cloneDeep(submission);
  submissionData.reaction_counts = submissionData.reaction_counts || { total_count: 0 };
  const totalCount = submissionData.reaction_counts.total_count;
  let userReaction = submissionData.user_reaction || ({} as UserReaction);
  const prevUserReaction = submission.user_reaction as UserReaction;

  // editing counts if un-reacting or editing reaction
  if (submissionData.user_reaction as UserReaction) {
    submissionData.reaction_counts[prevUserReaction.reaction as keyof ReactionCounts]--;
    if (newReaction.reaction in submissionData.reaction_counts) {
      submissionData.reaction_counts[newReaction.reaction as keyof ReactionCounts]++;
    } else {
      // ignore; newReaction.reaction will never be "ordering" (which requires type string[])
      //  @ts-ignore
      submissionData.reaction_counts[newReaction.reaction as keyof ReactionCounts] = 1;
    }
  }
  // if reacting to story for the first time: editing counts & user_reaction
  if (!userReaction.id) {
    submissionData.reaction_counts!.total_count = totalCount + 1;
    //  @ts-ignore
    submissionData.reaction_counts[newReaction.reaction as keyof ReactionCounts] = 1;
    userReaction = newReaction;
  }

  // if editing reaction
  if (isUserReactedAnotherReaction(reaction, submissionData)) {
    submissionData.user_reaction!.reaction = reaction;
  }

  // if un-reacting
  if (isUserReactedSameReaction(reaction, submissionData) && !newReaction) {
    submissionData.reaction_counts!.total_count = totalCount > 0 ? totalCount - 1 : 0;
    submissionData.user_reaction = undefined;
  } else {
    submissionData.user_reaction = {
      ...userReaction,
      reaction,
    };
  }

  const out = {
    user_reaction: submissionData.user_reaction,
    reaction_counts: submissionData.reaction_counts,
  };
  return out;
};
