import posthog from 'posthog-js';

export default defineNuxtPlugin(() => {
  const { public: p } = useRuntimeConfig();
  const { posthogKey, posthogHost, env } = p;
  if (env === 'development') return;
  const posthogClient = posthog.init(posthogKey, {
    api_host: posthogHost || 'https://app.posthog.com',
    capture_pageview: false, // we add manual pageview capturing below
    loaded: (posthog) => {
      if (import.meta.env.MODE === 'development') posthog.debug();
    },
  });

  const isProduction = env === 'production';

  if (isProduction) {
    // Detect page view
    const router = useRouter();
    router.afterEach((to) => {
      nextTick(() => {
        posthog.capture('$pageview', {
          current_url: to.fullPath,
        });
      });
    });
  }

  return {
    provide: {
      posthog: () => posthogClient,
    },
  };
});
