import { isMobileFn } from '../native/device';

export const useMobile = () => {
  const { appType } = useSafeEnv();
  const isMobile = ref(false);

  const init = async () => {
    isMobile.value = (await isMobileFn()) && appType.value === 'mobile';
  };

  init();

  return { isMobile };
};
