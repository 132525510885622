import { useSafeEnv } from './useSafeEnv';

export const useChHead = (
  pageTitle: Ref = ref('CharacterHub - Share and store your original characters'),
  title: Ref = ref('CharacterHub - Share and store your original characters'),
  description: Ref = ref(
    'Store information about your characters and develop them further with the help of the community! Whether you like drawing characters, writing stories, or designing worlds, this is the place for you!'
  ),
  url: Ref = ref('https://characterhub.com/'),
  image: Ref = ref('https://characterhub.com/images/ch_twitter_banner.png'),
  allowCrawlers: Ref<boolean> = ref(true),
  allowAiBots: Ref<boolean> = ref(true)
) => {
  const { env } = useSafeEnv();

  const document = useDocument();
  document.value.title = pageTitle?.value || '';

  const isProduction = env.value === 'production';

  const allowAiBotsValue = allowAiBots?.value;
  const allowCrawlersValue = allowCrawlers?.value;
  const crawlersContent = `${allowCrawlersValue ? 'follow' : 'noindex,follow'}`;
  const separator = crawlersContent ? ',' : '';
  const noAiContent = `${allowAiBotsValue ? '' : 'noai,noimageai'}`;
  const finalContent = computed(() =>
    (isProduction ? `${crawlersContent}${separator}${noAiContent}` : 'noindex,nofollow,noai,noimageai').replace(
      /,$/,
      ''
    )
  );

  if (process.server) {
    useServerSeoMeta({
      robots: finalContent,
      ogImage: image,
      ogTitle: title,
      title,
      ogSiteName: 'CharacterHub',
      ogDescription: description,
      description,
      ogUrl: url,
      ogType: 'website',
      twitterSite: '@characterhub',
      twitterTitle: title,
      twitterDescription: description,
      twitterImage: image,
    });
    return;
  }
  useHead({
    title: pageTitle,
    meta: [
      {
        name: 'robots',
        content: finalContent,
      },
      {
        property: 'og:image',
        content: image,
        hid: 'og:image',
      },
      {
        property: 'og:title',
        content: title,
        hid: 'og:title',
      },
      {
        name: 'title',
        content: title,
        hid: 'title',
      },
      {
        property: 'og:site_name',
        content: `CharacterHub`,
      },
      {
        property: 'og:description',
        content: description,
        hid: 'og:description',
      },
      {
        name: 'description',
        content: description,
        hid: 'description',
      },
      {
        property: 'og:type',
        content: 'profile',
        hid: 'og:type',
      },
      {
        property: 'og:url',
        content: url,
        hid: 'og:url',
      },
      {
        name: 'twitter:url',
        content: url,
        hid: 'twitter:url',
      },
      {
        name: 'twitter:title',
        content: title,
        hid: 'twitter:title',
      },
      {
        name: 'twitter:site',
        content: '@characterhub',
        hid: 'twitter:title',
      },
      {
        name: 'og:type',
        content: 'website',
        hid: 'og:type',
      },
      {
        name: 'twitter:description',
        content: description,
        hid: 'twitter:description',
      },
      {
        name: 'twitter:image',
        content: image,
        hid: 'twitter:image',
      },
    ],
  });
};
