import store from '@/shared/store';
import constants from '@/shared/statics/constants';
import { validateResetPasswordToken } from '@/shared/services/auth';
import { authStore } from '@/shared/pinia-store/auth';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) return;
  if (to.name !== 'reset-password') return;
  if (!store.getters['auth/isLoggedIn']) {
    try {
      await validateResetPasswordToken(to.query.token || '');
      return;
    } catch (error) {
      const { updateResetPasswordTokenInvalid } = authStore();
      updateResetPasswordTokenInvalid(constants.forgotPassToastOptions.INVALID_TOKEN);
      navigateTo({ name: 'login' });
    }
  } else navigateTo({ name: 'home' });
});
