<template>
  <ion-badge
    class="sub-plan-badge"
    :class="
      !isEmpty(text)
        ? isPlusPlan
          ? 'sub-plan-text-badge plus-plan-bg clickable-item-hov'
          : 'sub-plan-text-badge ultra-plan-bg clickable-item-hov'
        : isPlusPlan
        ? 'plus-plan-bg'
        : 'ultra-plan-bg'
    "
    :style="withBorder ? 'border: 1px solid white' : ''"
    @click="action"
    >{{ isPlusPlan ? `${plus}` : `${ultra}` }}</ion-badge
  >
</template>

<script lang="ts" setup>
import { openSubscribeModal } from '@/shared/utils/modals';
const props = defineProps({
  plan: { type: String, default: 'plus' },
  text: { type: String, default: '' },
  withBorder: { type: Boolean, default: false },
});
const text = toRef(props, 'text');
const plus = computed(() => {
  return !isEmpty(text.value) ? text.value : '+plus';
});
const action = () => {
  if (!isEmpty(text.value)) {
    openSubscribeModal(null, 'uploadFormGifAttempted');
  }
};
const ultra = computed(() => {
  return !isEmpty(text.value) ? text.value : 'ULTRA';
});
const isPlusPlan = computed(() => {
  return props.plan === 'plus';
});
</script>

<style scoped lang="sass">
.sub-plan-text-badge
  border-radius: 12px !important
  height: 35px !important
  padding: 13px !important
  font-size: 14px !important
.sub-plan-badge
  border-radius: 20px
  height: 14px
  display: flex
  align-items: center
  padding: 7px
  font-size: 10px
  justify-content: center
  text-align: center

.plus-plan-bg
  background: #00b4c5
.ultra-plan-bg
  background: linear-gradient(111.47deg, #E338FC 0%, #AE38E5 25%, #886EFF 50%, #4FBDE5 75%, #6DFFE5 100%)
</style>
