<template>
  <div class="main-content" ref="mainContent" v-if="displayImage">
    <img
      :src="get(currencyConfig, 'hubux_image')"
      :style="{ width: `${width}`, height: `${width}` }"
      class="border-image"
    />
  </div>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';

const props = defineProps({
  image: { type: String, default: '' },
  imgWidth: { type: Number, default: 0 },
  type: { type: String, default: '' },
});

const { currencyConfig } = authStore();
const type = toRef(props, 'type');
const mainContent = ref<HTMLElement | null>(null);
const image = toRef(props, 'image');
const imgWidth = toRef(props, 'imgWidth');
const displayImage = computed(() => {
  return get(currencyConfig.value, 'hubux_image') && type.value === 'coin';
});
const width = computed(() => {
  if (imgWidth.value) {
    return `${imgWidth.value / 2}px`;
  } else if (mainContent.value) {
    const mainContentWidth = mainContent.value.clientWidth as any;

    return `${mainContentWidth / 2}px`;
  } else {
    return 'auto';
  }
});
</script>

<style lang="sass" scoped>
.main-content
  position: relative
  left: 50%
  top:  50%
  transform: translate(-50%,-55%)



.main-content::before
  content: ""
  display: block
  background-color: #fdb11066
  border-radius: 100%
  z-index: 3
  position: absolute
  width: v-bind(width)
  height: v-bind(width)
  left: 50%
  top:  50%
  transform: translate(-50%,-55%)


.border-image
  z-index: 0
  border-radius: 100%
  position: absolute
  object-fit: cover
  display: block
  left: 50%
  top:  50%
  transform: translate(-50%,-55%)
</style>
