<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="inner-content d-flex flex-column px-4 py-3">
      <div class="d-flex justify-content-between">
        <div class="title">{{ type === 'art' ? 'Submit FanArt' : 'Submit Other FanWork' }}</div>
        <ion-buttons>
          <ion-button class="close-button text-black" @click="dismissModal()">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </div>

      <ion-button class="back-btn mt-2" @click="goBack">
        <div class="d-flex mt-1">
          <span> <i class="ti-angle-left icon" /></span>
          <span class="mx-1">Go back</span>
        </div>
      </ion-button>

      <form @submit.prevent>
        <div v-if="type === 'art'" class="d-flex flex-column align-items-center upload mt-2">
          <div v-if="fanart.image" class="position-relative">
            <img loading="lazy" v-if="resizeUpload(fanart.image, '380h')" v-image :src="fanart.image" class="img" />

            <ion-button fill="none" class="position-absolute one-button left" @click="deleteImage">
              <i class="ti-close" />
            </ion-button>
          </div>

          <drag-or-drop-file :remove="isRemoving" @uploaded="uploadFanWork" />
        </div>

        <div v-else class="mt-2">
          <form @submit.prevent>
            <ion-input id="msgText" v-model="fanart.other_title" rows="3" class="c-input" :placeholder="`Title`" />

            <div class="mt-2">
              <Editor
                v-model="fanart.other_content"
                :api-key="`${tinyMCEKey}`"
                :init="{
                  height: 250,
                  menu: {
                    insert: {
                      title: 'Insert',
                      items: 'image link media inserttable | charmap emoticons hr  | insertdatetime',
                    },
                  },
                  menubar: true,
                  relative_urls: false,
                  plugins:
                    'quickbars advlist autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media table code help wordcount',
                  toolbar:
                    'undo redo | bold italic backcolor | blocks | fontfamily |  \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
                  contextmenu: false,
                  quickbars_insert_toolbar: false,
                  quickbars_selection_toolbar: 'bold italic underline strikethrough link',
                }"
                placeholder="Type your message here..."
              />
            </div>
          </form>
        </div>

        <ion-textarea
          v-model="fanart.note"
          rows="8"
          class="c-textarea mb-3"
          placeholder="Add a note to go with your fan work! You can share anything you want the creator to know!"
          :maxlength="280"
          @input="checkLength($event.target.value)"
        />

        <div class="w-100 d-flex justify-content-center">
          <ion-button
            class="submit-btn"
            :disabled="isSubmitting || !isFormComplete"
            type="submit"
            @click.prevent="onSubmit"
          >
            <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
            <span v-else>Submit</span>
          </ion-button>
        </div>
      </form>
    </div>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { resizeUpload } from '@/shared/utils/upload';
import UploadForm from '@/shared/components/upload-form.vue';
import { createFanwork } from '@/shared/actions/fanwork';
import { FanWork } from '@/shared/types/static-types';
import { toast } from '@/shared/native';

import DragOrDropFile from '@/shared/components/DragOrDropFile.vue';


@Options({
  name: 'SubmitFanartAndFanworkModal',
  components: { UploadForm, DragOrDropFile },
})
export default class SubmitFanartAndFanworkModal extends Vue {
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({}) type!: any;
  @Prop({}) submittedForId!: string;
  @Prop({ default: 'character' }) submittedForType!: string;
  resizeUpload = resizeUpload;
  isSubmitting = false;
  isSubmitted = false;
  public get tinyMCEKey() {
    const {
      public: { tinyMCEKey },
    } = useRuntimeConfig();
    return tinyMCEKey;
  }

  public isRemoving = false;

  public fanart: FanWork = {
    note: '',
    submitted_for_id: '',
    submitted_for_type: '',
    receiver: '',
    type: '',
    image: '',
    other_title: '',
    other_content: '',
  };

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 420;
  }

  public goBack() {
    this.$emit('goBack');
  }

  public async checkLength(val: any) {
    if (val.length >= 280) await toast.show("You can't write more words", 'nonative', 'danger');
  }

  public get isFormComplete() {
    return this.type === 'art' ? !!this.fanart.image : !!this.fanart.other_title;
  }

  public uploadFanWork(image: any) {
    this.fanart.image = image;
    this.isRemoving = false;
  }

  public deleteImage() {
    this.fanart.image = '';
    this.isRemoving = true;
  }

  public async onSubmit() {
    if (!this.isFormComplete) return;

    this.isSubmitting = true;
    try {
      const resp = await createFanwork({
        submitted_for_id: this.submittedForId,
        submitted_for_type: this.submittedForType,
        type: this.type,
        note: this.fanart.note,
        ...(this.type === 'art'
          ? { image_url: this.fanart.image }
          : { other_title: this.fanart.other_title, other_content: this.fanart.other_content }),
      });

      if (get(resp, 'earned_raffle_bonus')) {
        await toast.show(
          `You've earned an extra entry for @${get(resp, 'receiver.username')}'s raffles!`,
          'nonative',
          'success'
        );
      }

      this.$emit('submitted');
    } catch (_err) {
      await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
      this.dismissModal();
    }

    this.isSubmitting = false;
  }

  public resetData() {
    this.fanart.note = '';
    this.fanart.other_title = '';
    this.fanart.other_content = '';
    this.fanart.image = '';
  }

  public dismissModal() {
    this.$emit('dismissModal');
    this.resetData();
  }
}
</script>

<style lang="sass" scoped>
.one-button
  width: 25px
  height: 25px
  background-color: rgba(0,0,0,0.5)
  color: #FFF
  --padding-start: 0
  --padding-end: 0
  border-radius: 13px
  font-size: 12px
  right: 5px
  top: 5px
  position: absolute
  pointer-events: all
  &.left
    left: 5px
  &.left-icon
    left: 35px

.close-button
  width: 50px
.sub-text
  font-size: 18px

.img
  max-width: 100% !important
  max-height: 300px
  object-fit: cover
  display: block
  width: auto
  height: auto

.title
  font-weight: bold
  font-size: 24px

.back-btn
  width: 95px
  height: 29px
  min-height: 29px
  // --border-radius: 8px
  text-transform: none

.submit-btn
  width: 100px

.modal-big
  // --width: 38%
  --width: 540px
  --height: auto
  @media(max-width: 560px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 90vh
  overflow: auto
</style>
