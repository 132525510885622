<template>
  <div @click="openModal">
    <ion-button
      v-if="inButton"
      :color="isBookmarked && !isCustom ? 'secondary' : !isBookmarked && !isCustom ? '' : ''"
      :class="getRouteColor"
      :disabled="!isAuthenticated"
    >
      <inline-svg class="icon" src="/icons/bookmark-new.svg" :class="isBookmarked ? 'bookmarked' : ''" />
    </ion-button>
    <i
      v-else
      class="ti-bookmark"
      :class="{
        'text-secondary': isBookmarked,
        bmicon: !showText,
        'bmicon-text': showText,
        'bmicon-color text-light': !isBookmarked,
      }"
    />

    <b v-if="showText" :class="{ 'text-secondary': isBookmarked }"> Bookmark </b>
    <bookmark-modal
      :is-open="bookmarkModalIsOpen"
      :object-id="props.objectId"
      :content-type="props.contentType"
      @close="closeModal"
      @changeStatus="changeStatus"
    />
  </div>
</template>

<script lang="ts" setup>
import { getObjectBookmarkCollections } from '@/shared/actions/bookmarks';
import BookmarkModal from '@/shared/modals/BookmarkModal.vue';
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';

const { isAuthenticated } = authStore();

const props = defineProps({
  objectId: {
    type: String,
    required: true,
  },
  contentType: {
    type: String,
    required: true,
    validator: (prop: string) => constants.bookmarkableTypes.includes(prop),
  },
  inButton: {
    type: Boolean,
    default: false,
  },
  isCustom: {
    type: Boolean,
    default: false,
  },
  textColor: {
    type: String,
    default: '',
  },
  openBookmark: {
    type: Boolean,
    default: false,
  },
  showText: {
    type: Boolean,
    default: false,
  },
  iconColor: {
    type: String,
    default: '',
  },
});

const route = useRoute();
const textColor = toRef(props, 'textColor');
const iconColor = toRef(props, 'iconColor');
const isCustom = toRef(props, 'isCustom');
const isBookmarked = ref(false) as Ref<boolean>;
const bookmarkModalIsOpen = ref(false);
const emit = defineEmits(['closeBookmarkModal']);

const openModal = () => {
  if (route.name !== 'character-stylize') {
    if (!isAuthenticated.value || isCustom.value) return;
    bookmarkModalIsOpen.value = true;
  }
};

const closeModal = () => {
  bookmarkModalIsOpen.value = false;
  emit('closeBookmarkModal');
};

const getRouteColor = computed(() => {
  if (route.name === 'character-stylize' || route.name === 'character-profile-new') {
    return 'text-color';
  } else {
    return 'text-white';
  }
});

const changeStatus = (newStatus: boolean) => {
  isBookmarked.value = newStatus;
};

const fetchBookmarkCollections = async (objId: string) => {
  if (!isAuthenticated.value) return;
  try {
    const { count } = await getObjectBookmarkCollections(objId);
    isBookmarked.value = count > 0;
  } catch (e) {}
};

watch(props, (newVal: Object, oldVal) => {
  if (!props.objectId) {
    isBookmarked.value = false;
    return;
  }
  if (newVal) fetchBookmarkCollections(props.objectId);
  if (props.openBookmark) {
    bookmarkModalIsOpen.value = props.openBookmark;
  } else {
    closeModal();
  }
});

onMounted(async () => {
  if (props.objectId) fetchBookmarkCollections(props.objectId);
});
</script>

<style lang="sass" scoped>
ion-button
  fill: inherit
.icon
  &.bookmarked
    fill: inherit
.text-color
  color: v-bind(textColor) !important

.bmicon-color
  color: v-bind(iconColor) !important
.bmicon
  position: relative
  top: 3px
  font-size: 1.2rem
  padding: 0.1rem 0.25rem 0 0.25rem
  cursor: pointer
.bmicon:hover
  opacity: 0.8

.bmicon-text
  position: relative
  top: 3px
  font-size: 1.2rem

.dark .text-light
  color: white !important
</style>
