<template>
  <div class="wrapper logging-out">
    <ChLoading size="lg" class="spinner-loading" />
  </div>
</template>

<script lang="ts" setup></script>

<style scoped lang="sass">
.wrapper
  z-index: 999999
  width: 100vw
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  position: absolute
  background: #FFF
.spinner-loading
  color: #214163 !important
</style>
