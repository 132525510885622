import { mainStore } from '@/shared/pinia-store/main';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) return;
  const { back, title } = to.meta;
  const headerType = back ? 'back' : 'default';
  const { setHeader } = mainStore();
  setHeader(headerType);
  const finalTitle = 'CharacterHub - Share and store your original characters';
  const additional = title ? ' |' + title : '';
  const document = useDocument();
  if (!document.value) return;
  document.value.title = finalTitle + additional;
});
