<template>
  <div>
    <div class="d-flex" :class="!isParent ? 'shrinked ml-auto' : ''">
      <CommenterImage
        v-if="!isEdit"
        switching
        size="cm"
        :type="get(lastCommentAs, 'type') === commentedAsTypes.USER ? 'user' : 'character'"
        :src="
          resizeUpload(
            get(lastCommentAs, 'type') === commentedAsTypes.USER
              ? get(user, 'profile_picture_url')
              : get(lastCommentAs, 'item.info.cropProfilePicture'),
            '80x80'
          )
        "
        :ring="
          get(lastCommentAs, 'type') === commentedAsTypes.USER
            ? get(user, 'customize_profile.profile_ring.image')
            : get(lastCommentAs, 'item.profile_ring.image')
        "
        :ringWidth="77"
        :top="-8"
        :left="-8"
        :setRingPosition="true"
        class="clickable"
        @click.prevent="openCommentAsList"
      />
      <div class="w-100" :class="{ 'ml-2': !isEdit }">
        <form @submit.prevent>
          <ion-textarea
            id="commentText"
            v-model="commentText"
            rows="3"
            class="c-textarea m-0"
            :placeholder="`Share something nice about ${targetName}`"
            :disabled="isPosting || !isAuthenticated"
            required
          />

          <div class="mt-2 d-flex" :class="isEdit ? 'justify-content-end' : 'justify-content-between'">
            <div v-if="isAuthenticated && !isEdit" class="text mt-1" :style="{ color: asColor }">
              Replying as
              <strong :style="`color: ${replyAsColor};`">{{ isCommentAsUser ? 'yourself' : lastCommentAsName }}</strong
              >.
              <a
                href="#"
                :style="`color:${replyAsColor};  text-decoration: ${textUnderLine}; `"
                @click.prevent="openCommentAsList"
                ><b
                  ><i>{{ switchPostAsText }}</i></b
                ></a
              >
            </div>
            <i v-else-if="!isEdit" class="text-gray">Log in to post comments</i>

            <div>
              <ion-button
                v-if="isEdit"
                class="publish-btn mr-1"
                :disabled="isPosting"
                color="danger"
                @click.prevent="onCancel"
              >
                Cancel
              </ion-button>

              <ion-button
                v-if="get(user, 'is_email_verified', true)"
                class="publish-btn"
                :style="`--background: ${buttonColor};color: ${buttonTextColor}`"
                :disabled="isPosting || !isAuthenticated"
                :color="postSuccess ? 'success' : ''"
                @click.prevent="onPost"
              >
                <i v-if="postSuccess" class="ti-check-box" />
                <ChLoading size="sm" v-else-if="isPosting" class="spinner" />
                <span v-else>{{ isEdit ? 'Save' : 'Post' }}</span>
              </ion-button>
              <VerificationButton
                :disabled="isPosting || !isAuthenticated || !commentText"
                :actionName="'Post'"
                v-else
              />
            </div>
          </div>
        </form>
      </div>
    </div>

    <comment-as-select-modal
      :is-open="openCommentAsModal"
      @dismiss-modal="closeCommentAsModal"
      @item-selected="selectCommentAs"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Action } from 's-vuex-class';
import { Character, User } from '@/shared/types/static-types';
import namespace from '@/shared/store/namespace';
import { toast } from '@/shared/native';
import CommentAsSelectModal from '@/shared/modals/CommentAsSelectModal.vue';
import constants, { ERROR_UNLISTED_CHARACTER_IN_COMMENTS } from '@/shared/statics/constants';
import { updateComment, createComment } from '@/shared/actions/comments';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { textColorChanged } from '@/shared/utils/textConverter';
import logger from '@/shared/services/logger';
import CommenterImage from '@/shared/components/storage/CommenterImage.vue';
import VerificationButton from '@/shared/components/VerificationButton.vue';
import { resizeUpload } from '@/shared/utils/upload';

@Options({
  name: 'PostComment',
  components: { CommentAsSelectModal, CommenterImage, VerificationButton },
})
export default class PostComment extends Vue {
  @Prop() target!: Character | User;
  @Prop() customize!: object;
  @Prop({ default: true }) isParent!: boolean;
  @Prop({ required: true }) commentingOnType!: string; // character, user
  @Prop({ default: false }) isEdit!: boolean;
  @Prop({ required: false }) parentCommentId!: string;
  @Prop({ required: false }) replyToMentionName!: string;
  @Prop({ required: false }) replyToComment!: string;
  @Prop({ required: false }) commentId!: string;
  @Prop({ default: () => '' }) existingText!: string;

  get = get;
  commentedAsTypes = constants.commentedAsTypes;

  resizeUpload = resizeUpload;

  public commentText = '';
  public isPosting = false;
  public postSuccess = false;

  public openCommentAsModal = false;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get targetName() {
    if (this.commentingOnType === 'character') return get(this.target, 'info.name', 'the character');
    if (this.commentingOnType === 'user') return this.target.username;
  }

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  @Action('postComment', { namespace: namespace.CharactersModule })
  public postCommentOnCharacter!: Function;

  public async postCommentOnUser(params: any) {
    const cmt = await createComment(params);
    return cmt;
  }

  public postComment(params: any) {
    if (this.commentingOnType === constants.commentedAsTypes.CHARACTER) {
      return this.postCommentOnCharacter(params);
    }
    if (this.commentingOnType === constants.commentedAsTypes.USER) {
      return this.postCommentOnUser(params);
    }
  }

  public openCommentAsList() {
    this.openCommentAsModal = true;
  }

  public closeCommentAsModal() {
    this.openCommentAsModal = false;
  }

  public get lastCommentAsName() {
    const { lastCommentAs } = authStore();
    const data = lastCommentAs.value;
    return data.type === constants.commentedAsTypes.USER
      ? get(data.item, 'username', 'Unknown')
      : get(data.item, 'info.name', 'Unknown');
  }

  public get lastCommentAs() {
    const { lastCommentAs } = authStore();
    return lastCommentAs.value;
  }

  public get isCommentAsUser() {
    return !this.lastCommentAs || this.lastCommentAs.type === constants.commentedAsTypes.USER;
  }

  public get switchPostAsText() {
    return this.isCommentAsUser ? 'Reply as your character instead!' : `Switch who you're replying as.`;
  }

  public get buttonColor() {
    return isEmpty(get(this.customize, 'template_applied'))
      ? '#ae38e5'
      : get(this.customize, 'data.theme.backgrounds.buttons');
  }

  public get buttonTextColor() {
    const router = useRouter();
    const currentRoute = router.currentRoute.value.name;
    if (
      ![
        'character-profile-new',
        'character-profile-draft-new',
        'character-stylize',
        'character-draft-stylize',
      ].includes(String(currentRoute))
    ) {
      return null;
    }

    const defaultClr = this.isdark ? '#FFFFFF' : '#000000';
    return isEmpty(get(this.customize, 'template_applied')) ? defaultClr : textColorChanged(this.buttonColor as string);
  }

  public selectCommentAs(data: { id: string; type: string; item: {} }) {
    const { updateLastCommentAs } = authStore();
    updateLastCommentAs({
      lastCommentAs: data,
      lastCommentAsName:
        data.type === constants.commentedAsTypes.USER
          ? get(data.item, 'username', 'Unknown')
          : get(data.item, 'info.name', 'Unknown'),
    });
  }

  public async onPost() {
    if (!this.commentText?.trim()) {
      toast.show('Please type a comment first!', 'nonative', 'primary');
      return;
    }
    if (!this.isEdit && ['U', 'M'].includes(this.lastCommentAs?.item?.privacy)) {
      toast.show(ERROR_UNLISTED_CHARACTER_IN_COMMENTS, 'nonative', 'danger');
      const { updateLastCommentAs } = authStore();
      updateLastCommentAs({
        lastCommentAs: { id: this.user.id, type: constants.commentedAsTypes.USER, item: cloneDeep(this.user) },
        lastCommentAsName: this.user.username,
      });
      return;
    }
    this.isPosting = true;
    let data = {};
    let fn = '';
    try {
      if (this.isEdit) {
        fn = 'patch';
        await updateComment(this.commentId, this.commentText);
      } else {
        fn = 'post';
        data = {
          ...data,
          text: this.commentText,
          commenter_id: this.lastCommentAs!.id,
          commenter_type: this.lastCommentAs!.type,
          commented_on_type: this.commentingOnType,
          commented_on_object_id: this.target.id,
          ...(this.parentCommentId && { parent_comment: this.parentCommentId }),
          ...(this.replyToComment && { reply_to_comment: this.replyToComment }),
        };
        await this.postComment(data);
      }

      this.postSuccess = true;
      setTimeout(() => (this.postSuccess = false), 2000);
    } catch (e: any) {
      toast.show('Could not post comment. Please try again.', 'nonative', 'danger');
      logger.error({
        e,
        loc: 'PostComment.onPost: ',
        msg: 'PostComment.onPost Error: ' + fn,
        data,
      } as any);
    } finally {
      this.commentText = '';
      this.isPosting = false;
      this.$emit('posted');
    }
  }

  public onCancel() {
    this.$emit('canceled');
  }

  public get isdark() {
    const { dark } = mainStore();
    return dark.value;
  }

  public get bgColor() {
    return `${get(this.customize, 'data.theme.backgrounds.page')} !important`;
  }

  public get asColor() {
    return isEmpty(get(this.customize, 'template_applied')) && this.isdark
      ? '#fff'
      : isEmpty(get(this.customize, 'template_applied'))
      ? '#000'
      : textColorChanged(this.bgColor);
  }

  public get postColor() {
    return isEmpty(get(this.customize, 'template_applied'))
      ? 'var(--ion-color-primary)'
      : textColorChanged(this.bgColor);
  }

  public get replyAsColor() {
    return isEmpty(get(this.customize, 'template_applied')) && this.isCommentAsUser && this.isdark
      ? '#fff'
      : isEmpty(get(this.customize, 'template_applied')) && this.isCommentAsUser
      ? '#000'
      : this.postColor;
  }

  public get textUnderLine() {
    return isEmpty(get(this.customize, 'template_applied')) ? 'none' : 'underline';
  }

  public async created() {
    if (this.isEdit) {
      this.commentText = this.existingText;
    }

    if (!this.lastCommentAs || !this.lastCommentAsName) {
      const { updateLastCommentAs } = authStore();
      updateLastCommentAs({
        lastCommentAs: { id: this.user?.id, type: constants.commentedAsTypes.USER, item: cloneDeep(this.user) },
        lastCommentAsName: this.user?.username,
      });
    }

    if (this.replyToMentionName) {
      this.commentText = `@${this.replyToMentionName} `;
    }
  }
}
</script>

<style lang="sass" scoped>
.shrinked
  width: calc(100% - 50px - 0.5rem)
.text
  margin-left: 5px
  font-size: 14px !important
  @media(max-width: 500px)
    font-size: 12px !important
.comment-poster-img
  width: 60px
  height: 60px
  border-radius: 40px
  object-fit: cover

.publish-btn
  width: 100px
  .spinner
    width: 15px
    height: 15px
</style>
