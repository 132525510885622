<template>
  <div class="profile-popover">
    <ion-item class="item-container d-flex pointer no-select" @click="copyLinktoProfile">
      <div class="ml-2 menu-item d-flex align-items-center no-select">Copy Profile Link</div>
    </ion-item>
    <ion-item v-if="isCharacterOwner" class="item-container d-flex pointer" @click="startTransferCharacter">
      <div class="ml-2 menu-item d-flex align-items-center no-select">Transfer Character</div>
    </ion-item>
    <ion-item class="item-container d-flex pointer" @click="showOwnershipHistory">
      <div class="ml-2 menu-item d-flex align-items-center no-select">Ownership History</div>
    </ion-item>
    <ion-item class="item-container d-flex pointer" @click="showCompBomb" v-if="isComplimentBomb && isAuthenticated">
      <div class="ml-2 menu-item d-flex align-items-center no-select">Compliment Bomb</div>
    </ion-item>
    <report
      v-if="!isCharacterOwner && isAuthenticated"
      :is-popover="true"
      :report-entity-id="character?.id"
      :report-entity-type="'character'"
      :reported-user="get(character, 'author')"
      @close="closeReportChar"
    >
      <ion-item class="item-container d-flex pointer">
        <div class="ml-2 menu-item d-flex align-items-center no-select text-danger">Report Character</div>
      </ion-item>
    </report>
  </div>
</template>

<script lang="ts" setup>
import { OverlayInterface } from '@ionic/core';
import Report from '@/shared/components/Report.vue';
import { Character } from '@/shared/types/static-types';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
const { isAuthenticated } = authStore();
const props = defineProps({
  instance: {
    type: Object as PropType<OverlayInterface>,
  },
  character: {
    type: Object as PropType<Character>,
  },
  isCharacterOwner: {
    type: Boolean,
    default: false,
  },
  isComplimentBomb: {
    type: Boolean,
    default: false,
  },
});

const instance = toRef(props, 'instance') as any;
const character = toRef(props, 'character');
const isCharacterOwner = toRef(props, 'isCharacterOwner');
const isComplimentBomb = toRef(props, 'isComplimentBomb');
const copyLinktoProfile = () => {
  const link = `${window.location.origin}/character/${character.value?.slug}`;
  navigator.clipboard.writeText(link);
  toast.show('Link copied to clipboard', 'nonative', 'primary');
  onPress();
};

const closeReportChar = async () => {
  await instance.value.dismiss();
};

const startTransferCharacter = () => {
  onPress('transferCharacter');
};

const showOwnershipHistory = () => {
  onPress('characterOwnership');
};

const showCompBomb = () => {
  onPress('complimentBomb');
};

const onPress = (code?: string) => {
  if (instance) {
    try {
      instance.value.dismiss(code);
    } catch (e) {}
  }
  dismissByClick();
};

const dismissByClick = () => {
  try {
    const el = document.querySelector('body') as any;
    el.click();
  } catch (error) {}
};
</script>

<style scoped lang="sass">
.pointer
  cursor: pointer
.bottom-button
  background-color: var(--ion-color-primary) !important
  color: white
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
</style>
