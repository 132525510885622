<template>
  <div class="relationships">
    <div v-for="(relationship, index) of relationshipsList" :key="index">
      <RelationshipCard :customize="customize" :relationship="relationship" :class="{ 'mt-3': index > 0 }" />
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <ion-button v-if="hasMore" @click="handleLoadMore">
      <ChLoading size="sm" v-if="loading" />
      <div v-else>See more</div>
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
import RelationshipCard from './RelationshipCard.vue';
import { characterProfileStore } from '@/shared/pinia-store/character-profile';

const { relationships, loadCharacterRelationshipsNextPage } = characterProfileStore();
const loading = ref(false);
const props = defineProps({
  customize: {
    type: Object,
    default: {},
  },
});

const handleLoadMore = async () => {
  loading.value = true;
  await loadCharacterRelationshipsNextPage();
  loading.value = false;
};

const relationshipsList = computed(() => relationships.value?.results);
const hasMore = computed(() => relationships.value?.next);
</script>

<style lang="sass" scoped></style>
