<template>
  <div>
    <ion-item
      v-if="followType !== 'superfollow'"
      class="item-container clickable-item-hov"
      @click="updateFollowStatus('superfollow')"
    >
      <div class="menu-item">
        <p class="my-2">
          SuperFollow
          <br /><small>(<i class="ti-bell" /> + priority on feed)</small>
        </p>
      </div>
    </ion-item>

    <ion-item
      v-if="followType !== 'follow'"
      class="item-container clickable-item-hov"
      @click="updateFollowStatus('follow')"
    >
      <div class="menu-item">
        <p class="my-2">
          Follow
          <br /><small>(<i class="ti-bell" style="font-size: 14px" /> + on feed)</small>
        </p>
      </div>
    </ion-item>

    <ion-item
      v-if="followType !== 'litefollow'"
      class="item-container clickable-item-hov"
      @click="updateFollowStatus('litefollow')"
    >
      <div class="menu-item">
        <p class="my-2">
          LiteFollow <br />
          <small>(not on feed)</small>
        </p>
      </div>
    </ion-item>

    <hr v-if="status" />
    <ion-item v-if="status" class="bottom-button-container clickable-item-hov" @click="updateFollowStatus('unfollow')">
      <div class="menu-item w-100">
        <div class="bottom-button">Unfollow</div>
      </div>
    </ion-item>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  instance: {
    type: Object,
  },
  status: {
    type: Boolean,
  },
  followType: {
    type: String,
  },
});

const updateFollowStatus = async (status: string) => {
  await props.instance?.dismiss({ value: status });
};
</script>

<style scoped lang="sass">
.bottom-button
  background-color: var(--ion-color-danger) !important
  color: white !important
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  display: flex
  align-items: center
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
</style>
