import moment from 'moment';

export function formatDateTime(dateTime: any) {
  return moment(dateTime).format('MMM DD, YYYY, hh:mm A');
}

export function formatDate(date: any) {
  return moment(date).format('MMM DD, YYYY');
}

export function formatSlashDate(date: any) {
  return moment(date).format('MM/DD/YY');
}
export function formatTimeAgo(dateTime: any) {
  return moment(dateTime).fromNow();
}

export function formatFullDateTime(dateTime: any) {
  return moment(dateTime).format('dddd MMMM Do YYYY');
}

export function formatDescDateTime(dateTime: any) {
  return `${moment(dateTime).format('MMM DD, YYYY')} at ${moment(dateTime).format('hh:mm A')}`;
}

export function dateHasPassed(dateTime: any) {
  if (!dateTime) return false;
  return moment(dateTime).isBefore();
}

export function formatIntervalInMilliseconds(dateFrom: any, dateUntil: any) {
  // dateUntil >= dateFrom
  return moment(dateUntil).valueOf() - moment(dateFrom).valueOf();
}

export function formatFromNowInMilliseconds(dateTime: any, returnRaw = false) {
  const value = moment(dateTime).valueOf() - moment(new Date()).valueOf();
  return returnRaw ? value : value < 0 ? 0 : value;
}

export function convertIntoDays(dateTime: any) {
  const endDate: any = new Date(dateTime);
  const currDate: any = new Date();
  const timeDiff = endDate - currDate;
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  return { days, hours };
}
