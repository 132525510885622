import { defineStore, storeToRefs } from 'pinia';
import { storage, toast } from '@/shared/native';
import { analyticsSetUser, sendAnalyticsEvent } from '@/shared/services/analytics';
import { getAuthTokenHeader } from '@/shared/services/auth';
import logger from '@/shared/services/logger';
import constants, { imageCategories } from '@/shared/statics/constants';
import store from '@/shared/store';
import {
  getSelf,
  updateUserdata,
  emailUpdate,
  usersFreebie,
  usersCurrency,
  updateContentPreferences,
  getSuspensionStatus,
} from '@/shared/actions/users';
import { checkUserSubscription } from '@/shared/actions/payments';
import { chAxios } from '@/shared/lib/axios';
import { getImageStorage } from '@/shared/actions/imagesCollections';
import { upload } from '@/shared/services/upload';
import { Character, Registration, User } from '@/shared/types/static-types';
import { modalController } from '@ionic/vue';
import ShareProfileModal from '@/shared/modals/ShareProfileModal.vue';
import { profileStore } from './profile';
import { featureFlags } from '@/shared/config/feature-flags';
import { onboardingStore } from './onboarding';
import { getUsersCurrencyBoxImage } from '@/shared/actions/currency';
import { getUserCharacters as _getUserCharacters } from '@/shared/actions/characters';
import { pushStore } from '~/apps/mobile/pinia-store/push';
import { logout } from '../actions/auth';
import { getVirtualItemsCurrencyConfig } from '../actions/virtualItems';
import { Preferences } from '@capacitor/preferences';

export interface IAuthState {
  _authToken?: string;
  _temp?: boolean;
  _id?: string;
  _changingProfilePicture: string;
  _changingCover: string;
  _user?: any;
  _preparedUserUpdates: any;
  uploading?: number;
  _referrer?: any;
  _inActionRegistration: Partial<Registration>;
  _loggedIn?: boolean;
  _notificationsInitialized?: boolean;
  _notificationsList: any[];
  _isLoggingOut: boolean;
  _emailSettings: any;
  _resetPasswordTokenInvalid: string | null;
  _lastPostAs?: { id: string; type: string; item: {} } | null;
  _lastPostAsName?: string | null;
  _lastCommentAs?: { id: string; type: string; item: {} } | null;
  _lastCommentAsName?: string | null;
  _counter?: number;
  _previousTempData?: any;
  _previousCustomizeData?: any;
  _isCreatedToast?: boolean;
  _isSavedToast?: boolean;
  _isTempApplied?: boolean;
  _userImageStorage?: number;
  _userStorageQuota?: number;
  _imageTypes?: any;
  _currentTempProfilePic?: any;
  _currentTempCoverPic?: any;
  _storageSpacePercentage?: any;
  _isScrollHandleAtBottom?: boolean;
  _tempAvailableCompliment?: any;
  _complimentCount?: any;
  _totalComplimentedCount?: any;
  _currency?: any;
  _freebie?: any;
  _currencyBoxImage?: any;
  _isPrfRingUpdated?: boolean;
  _userCharacters: Character[];
  _currencyConfig?: any;
  _userCharactersLoading: boolean;
  _creationsVisible?: boolean;
  _profileInstance?: any;
  _userEventSignUp?: boolean
  _userTryOnAnimation?: any;
  _userTryOnBgImage?: any;
  _userTryonImage?: any;
  _firstCharacterCreation?: boolean
}

export const useAuth = defineStore('auth', {
  persist: { enabled: true },
  state: (): IAuthState => ({
    _authToken: '',
    _id: '',
    _temp: false,
    _changingCover: '',
    _changingProfilePicture: '',
    _user: {},
    _userTryOnAnimation: {},
    _userTryOnBgImage: {},
    _userTryonImage: {},
    _preparedUserUpdates: {},
    uploading: 0,
    _referrer: null,
    _loggedIn: false,
    _notificationsInitialized: false,
    _notificationsList: [],
    _isLoggingOut: false,
    _emailSettings: {},
    _resetPasswordTokenInvalid: null,
    _inActionRegistration: {},
    _lastPostAs: null,
    _lastPostAsName: null,
    _lastCommentAs: null,
    _lastCommentAsName: null,
    _counter: 0,
    _creationsVisible: true,
    _previousTempData: {},
    _previousCustomizeData: {},
    _isSavedToast: false,
    _isCreatedToast: false,
    _isTempApplied: false,
    _userImageStorage: 0,
    _userStorageQuota: 0,
    _storageSpacePercentage: null,
    _currentTempProfilePic: null,
    _currentTempCoverPic: null,
    _imageTypes: [],
    _isScrollHandleAtBottom: false,
    _tempAvailableCompliment: null,
    _complimentCount: null,
    _totalComplimentedCount: null,
    _currency: {},
    _freebie: {},
    _currencyBoxImage: null,
    _userCharacters: [],
    _currencyConfig: {},
    _userCharactersLoading: true,
    _isPrfRingUpdated: false,
    _profileInstance: null,
    _userEventSignUp: false,
    _firstCharacterCreation: false,
  }),
  actions: {
    SET_LOGGING_OUT(value: any) {
      this._isLoggingOut = value;
    },
    SET_USER_TOKEN(token: any) {
      this._authToken = token;
    },
    SET_NOTIFICATION_INITIALIZED(value: any) {
      this._notificationsInitialized = value;
    },
    SET_LOGGEDIN(value: any) {
      this._loggedIn = value;
    },
    SET_USER(user: any) {
      this._user = user;
    },
    SET_IN_ACTION_REG(inActionRegistration: any) {
      this._inActionRegistration = inActionRegistration;
    },
    SET_REFERRER(username: any) {
      this._referrer = username;
      if (!username || !username.trim()) {
        storage.remove(constants.lsKeys.referrer);
      }
    },
    SET_USER_ID(id: any) {
      this._id = id;
    },
    UPDATE_USER(fieldsToUpdate: any) {
      forOwn(fieldsToUpdate, (val, key) => {
        this._user[key] = val;
      });
    },
    PREPARE_USER_UPDATE({ fields, reset = false }: any) {
      // prepares fields for patch
      if (reset) this._preparedUserUpdates = {};
      forOwn(fields, (val, key) => {
        this.preparedUserUpdates[key] = val;
      });
    },
    UNPREPARE_USER_UPDATE(fields: any) {
      forOwn(this.preparedUserUpdates, (val, key) => {
        if (fields.includes(key)) delete this.preparedUserUpdates[key];
      });
    },
    UPDATE_USER_LOCAL(fieldsToUpdate: any) {
      // simulates a PATCH on user properties on client-side only.
      // (used for onboarding)
      forOwn(fieldsToUpdate, (val, key) => {
        this._user[key] = val;
      });
    },
    UPLOADING(val: any) {
      this.uploading = val;
    },
    UPDATE_EMAIL_SETTINGS(newSettings: any) {
      this._emailSettings = newSettings;
    },

    UPDATE_PASS_RESET_TOKEN_INVALID(res: any) {
      this._resetPasswordTokenInvalid = res;
    },
    UPDATE_LOGGED_IN_USER_DATA(userData: any) {
      this._user = { ...this.user, ...userData };
    },
    updateUserProfilePicture(url: any) {
      this._user = { ...this.user, profile_picture_url: url };
    },
    updateScrollHandleAtBottom(value: any) {
      this._isScrollHandleAtBottom = value;
    },
    updateFirstCharacterCreationChallenge(value:any){
      this._firstCharacterCreation = value
    },
    updateTempAvailableCompliment(value: any) {
      this._tempAvailableCompliment = value;
    },
    updateComplimentCount(value: any) {
      this._complimentCount = value;
    },
    updateTotalComplimentedCount(value: any) {
      this._totalComplimentedCount = value;
    },
    updateCreationsToggleVisibility(value: any) {
      this._creationsVisible = value;
    },
    updateCurrentUserTempProfilePicture(value: any) {
      this._currentTempProfilePic = value;
    },
    updateUserCoverPicture(url: any) {
      this._user = { ...this.user, cover_cropped_url: url };
    },
    updateCurrentUserTempCoverPicture(value: any) {
      this._currentTempCoverPic = value;
    },
    updateUsernameColor(customize_profile: any) {
      this._user = { ...this.user, customize_profile: customize_profile };
    },
    UPDATE_APPLY_TEMPLATE_BTN({ data, counter }: any) {
      this._counter = counter;
      if (this._counter === 3) {
        this._user = { ...this._user, ...data };
      }
    },
    UPDATE_PREVIOUS_TEMP_DATA({ previousTempData }: any) {
      if (!isEmpty(previousTempData)) this._previousTempData = previousTempData;
    },
    UPDATE_CUSTOMIZE_TEMP_DATA({ previousCustomizeData, isUndo, isTempEmp }: any) {
      const route = useRoute();
      if (!isEmpty(previousCustomizeData) && route.name !== 'character-profile-new')
        this._previousCustomizeData = previousCustomizeData;
      this._previousCustomizeData.isUndo = isUndo;
      this._previousCustomizeData.isTempEmp = isTempEmp;
    },
    UPDATE_SAVED_TOAST(isSaved: any) {
      this._isSavedToast = isSaved;
    },
    UPDATE_CREATED_TOAST(isCreated: any) {
      this._isCreatedToast = isCreated;
    },
    UPDATE_TEMP_APP(isApplied: any) {
      this._isTempApplied = isApplied;
    },
    UPDATE_LAST_POST_AS({ lastPostAs, lastPostAsName }: any) {
      this._lastPostAs = lastPostAs;
      this._lastPostAsName = lastPostAsName;
    },

    UPDATE_LAST_COMMENT_AS({ lastCommentAs, lastCommentAsName }: any) {
      this._lastCommentAs = lastCommentAs;
      this._lastCommentAsName = lastCommentAsName;
    },
    async hydrateUser() {
      const res = await getSelf();
      this.getUserCharacters();

      this._changingProfilePicture = '';
      this._changingCover = '';
      this._temp = false;

      this.SET_USER({
        ...(this._user || {}),
        pro: { ...(res.pro || {}) },
        subscription_level: res.subscription_level,
        bomb_first_time_use: res.bomb_first_time_use,
        subscription_duration: res.subscription_duration,
        username: res.username || '',
        profile_picture_url: res.profile_picture_url || '',
        customize_profile: res.customize_profile,
        email_configs: res.email_configs,
        date_joined: res.date_joined,
        email: res.email,
        is_email_verified: res.is_email_verified,
        restrictions: res.restrictions,
        is_nsfw: res.is_nsfw,
        show_nsfw: res.show_nsfw,
        over_18: res.over_18,
        original_picture_url: res.original_picture_url,
        is_banned: res.is_banned,
        banned_until: res.banned_until,
        is_staff: res.is_staff,
        st: res.st,
        age_range: res.age_range,
      });
    },
    async login({ username, password, isRegistering = false, isQuestModal = false }: any) {
      const url = `/login/`;
      const params = { username, password };
      const {updateEventSignUp}= authStore()
      try {
        const res = await chAxios().post(url, params);
        this._authToken = res.data.token;
        this._id = res.data.user.id;
        this._user = res.data.user;
        this.SET_USER_TOKEN(res.data.token);
        this.SET_USER_ID(res.data.user.id);
        this.SET_USER(res.data.user);
        store.commit('UsersModule/SET_USER', res.data.user, { root: true });
        if (!isRegistering) {
          analyticsSetUser(res.data.user.id);
          sendAnalyticsEvent('Logged In');
        }
      } catch (e) {
        const err = e as any;
        if (!err || !err.response) throw new Error('Connection error');
        if (err.response.status === 429) {
          throw new Error('Exceeded login attempt limit. Try again in 1 minute.');
        } else if (err.response.status === 400) {
          throw new Error('Wrong username or password');
        } else if (err.response.status === 404) {
          throw new Error('Connection error');
        } else {
          logger.error({ loc: 'store.auth.login', e } as any);
          throw new Error('Could not sign in');
        }
      }finally{
      if(isQuestModal)  updateEventSignUp(true)
      }
    },
    async register(user: any, isQuestModal = false) {
      const url = `/register/`;
      const { trackEvent } = useAnalytics();
      user.referrer = this._referrer && Object.keys(this._referrer).length ? this._referrer : null;

      const { data } = await chAxios().post(url, user);
      const inActionRegistration = data;
      if (!data.d12zjs) {
        analyticsSetUser(inActionRegistration.user.id);
        trackEvent('Registered');
      }
      this.SET_REFERRER(null);
      this.SET_IN_ACTION_REG(inActionRegistration);
      
      await this.login({
        username: user.username,
        password: user.password,
        isRegistering: true,
        isQuestModal: isQuestModal
      });
      const router = useRouter();
      isQuestModal ?   '' : router.push({ name: 'profile-self' });
      return inActionRegistration;
    },
    async logout() {
      const { deviceToken } = pushStore();
      const router = useRouter();
      const { updateLastCommentAs } = authStore();
      const { reset } = onboardingStore();
      try {
        try {
          await logout(deviceToken.value);
        } catch (error) {}

        reset();

        this._authToken = undefined;
        this._id = undefined;

        await storage.clear();
        await Preferences.clear();

        this.SET_LOGGEDIN(false);
        this.SET_LOGGING_OUT(true);
        store.commit('UsersModule/RESET', {});
        this.SET_USER({});
        this.SET_USER_TOKEN(null);
        this.SET_USER_ID(null);
        this.PREPARE_USER_UPDATE({ reset: true, fields: {} });
        this.UPDATE_LAST_POST_AS({ lastPostAs: null, lastPostAsName: null });
        this.UPDATE_LAST_COMMENT_AS({ lastCommentAs: null, lastCommentAsName: null });

        await updateLastCommentAs({ lastCommentAs: null, lastCommentAsName: null });
        await router.replace('/');

        setTimeout(() => {
          this.SET_LOGGING_OUT(false);
        }, 2000);
      } catch (error) {
        this._authToken = undefined;
        this._id = undefined;
        await storage.clear();
        this.SET_LOGGING_OUT(false);
      }
    },
    async getSuspensionInfo() {
      const data = await getSuspensionStatus();
      return data;
    },
    async fetchEmailUnsubToken() {
      const url = `/users/email_token_req/`;
      const headers = await getAuthTokenHeader();
      const { data } = await chAxios().post(url, {}, { headers });
      return data;
    },

    async fetchEmailSettings(token: string) {
      const url = `/users/email_token/?t=${token}`;
      const { data } = await chAxios().get(url);
      this.UPDATE_EMAIL_SETTINGS(data);
      return data;
    },

    async updateEmailSettings({ token, settings }: any) {
      const payload = {
        settings,
        t: token,
      };
      const url = `/users/email_token/`;
      const { data } = await chAxios().post(url, payload);
      this.UPDATE_EMAIL_SETTINGS(data);
      return data;
    },
    updateResetPasswordTokenInvalid(tokenInvalid: any) {
      this.UPDATE_PASS_RESET_TOKEN_INVALID(tokenInvalid);
    },

    updateLoggedInUserData(userData: any) {
      this.UPDATE_LOGGED_IN_USER_DATA(userData);
    },
    logoutWatcher() {
      if (this._isLoggingOut) {
        this.logout();
      }
    },
    async updateLastPostAs({ lastPostAs, lastPostAsName }: any) {
      this.UPDATE_LAST_POST_AS({ lastPostAs, lastPostAsName });
    },
    async updateCreatedToast(isCreated: any) {
      this.UPDATE_CREATED_TOAST(isCreated);
    },
    async updateTempApp(isApplied: any) {
      this.UPDATE_TEMP_APP(isApplied);
    },
    async updateSavedToast(isSaved: any) {
      this.UPDATE_SAVED_TOAST(isSaved);
    },
    async updateProfileRingStatus(isUpdated: any) {
      this._isPrfRingUpdated = isUpdated;
    },

    async updateProfileInstance(data: any) {
      this._profileInstance = data;
    },
    updateProfileTryOnAnimation(data: any) {
      this._userTryOnAnimation = data;
    },
    updateProfileTryOnBgImage(data: any) {
      this._userTryOnBgImage = data;
    },
    updateProfileTryonImage(data: any) {
      this._userTryonImage = data;
    },
   
     updateEventSignUp(data: any) {
      this._userEventSignUp = data;
    },
    async updateLastCommentAs({ lastCommentAs, lastCommentAsName }: any) {
      this.UPDATE_LAST_COMMENT_AS({ lastCommentAs, lastCommentAsName });
    },
    async updateUserTemplateReuserBtn({ data, user, counter }: any) {
      if (counter === 3) {
        await updateUserdata(user.id, data);
      }
      this.UPDATE_APPLY_TEMPLATE_BTN({ data, counter });
    },
    async updatePreviousTemplate({ previousTempData }: any) {
      this.UPDATE_PREVIOUS_TEMP_DATA({ previousTempData });
    },

    async updatePreviousCustomize({ previousCustomizeData, isUndo, isTempEmp }: any) {
      this.UPDATE_CUSTOMIZE_TEMP_DATA({ previousCustomizeData, isUndo, isTempEmp });
    },
    async refreshUserSubscriptionLevel() {
      const data = await checkUserSubscription();
      this.SET_USER({
        ...this._user,
        subscription_level: data.subscription_level,
        subscription_duration: data.subscription_duration,
        st: data.st,
      });
    },
    setUserSubscriptionLevel(level: number, duration: string, pro: any) {
      if (!level || !duration) return;
      this.SET_USER({
        ...this._user,
        subscription_level: level,
        subscription_duration: duration,
        pro,
      });
    },
    async fetchImageTypes(data: any) {
      const { user_occupied_storage, storage_quota, ...filtered } = data;
      this._userImageStorage = user_occupied_storage;
      this._userStorageQuota = storage_quota;
      this._storageSpacePercentage = Math.floor((user_occupied_storage / storage_quota) * 100);
      this._imageTypes = imageCategories
        .map((item: any) => ({
          ...item,
          percentage: filtered[item.value] ? (filtered[item.value] / storage_quota) * 100 : null,
        }))
        .filter((item: any) => item.percentage !== null);
    },

    async fetchImageStorage() {
      const data = await getImageStorage();
      this.fetchImageTypes(data);
    },
    async getUserCharacters() {
      try {
        this._userCharactersLoading = true;
        const response = await _getUserCharacters(this._user.id, null, { incl_u_m: true });
        this._userCharacters = response || [];
      } catch (error) {
      } finally {
        this._userCharactersLoading = false;
      }
    },
    async getCurrencyConfig() {
      try {
        const response = await getVirtualItemsCurrencyConfig();
        this._currencyConfig = response || {};
      } catch (error) {}
    },
    async updateImageStorage(data: any) {
      this.fetchImageTypes(data);
    },
    async updateUser(userId: string, data: Partial<User>) {
      await updateUserdata(userId, data);
      this.SET_USER({
        ...this._user,
        ...data,
      });
      await toast.show('User saved successfully', 'nonative', 'success');
    },
    async updateUserEmail(new_email: string) {
      const res = await emailUpdate(new_email);
      this.SET_USER({
        ...this._user,
        email_configs: res,
      });
      await toast.show('Email updated, please verify the new email!', 'nonative', 'warning');
    },
    async openChangeProfilePictureSuccessModal() {
      if (!this._user.profile_picture_url || !this._user.cover_cropped_url) return;
      const modal = await modalController.create({
        component: ShareProfileModal,
        cssClass: 'share-profile-modal',
      });
      await modal.present();
    },

    changeUserProfilePicture(url: string) {
      const prevSelectedProfilePicture = this._user.profile_picture_url;
      const data: object = {
        profile_picture_url: url,
        original_picture_url: url,
      };

      const { updateUserProfile } = profileStore();
      const { ongoingOnboarding } = onboardingStore();

      updateUserProfile(data).then(() => {
        if (!prevSelectedProfilePicture && !ongoingOnboarding.value) {
          this.openChangeProfilePictureSuccessModal();
        }
      });
    },
    async changeProfilePictureWithNoAdditionalSteps(
      image: Blob | string,
      isUserProfile: boolean,
      isUserEditProfile: boolean
    ) {
      try {
        this._changingProfilePicture = typeof image === 'string' ? image : URL.createObjectURL(image);
        this._temp = isUserEditProfile;
        const url = typeof image === 'string' ? image : await upload(image);

        if (url) {
          if (isUserProfile) {
            this.changeUserProfilePicture(url);
          } else if (isUserEditProfile) {
            this.updateCurrentUserTempProfilePicture(url);
          }
        }

        const { currentStep, stepForward } = onboardingStore();

        if (currentStep.value === 21) {
          stepForward();
        }
      } catch (error) {
      } finally {
        setTimeout(() => {
          this._changingProfilePicture = '';
          this._temp = false;
          toast.show('Profile picture updated', 'nonative', 'success');
        }, 2000);
      }
    },
    async loadCurrencyBoxImage() {
      const resp = await getUsersCurrencyBoxImage();
      this._currencyBoxImage = resp;
    },
    changeUserCoverPicture(url: string) {
      const prevSelectedCover = this._user.cover_url;
      const data: object = {
        cover_url: url,
        cover_cropped_url: url,
        cover_preference: 'single',
      };

      const { updateUserProfile } = profileStore();
      const { ongoingOnboarding } = onboardingStore();

      updateUserProfile(data);

      if (!prevSelectedCover && featureFlags.shareProfile && !ongoingOnboarding.value) {
        setTimeout(() => {
          this.openChangeProfilePictureSuccessModal();
        }, 1000);
      }
    },
    async changeCoverWithNoAdditionalSteps(image: Blob | string, isUserProfile: boolean, isUserEditProfile: boolean) {
      try {
        this._changingCover = typeof image === 'string' ? image : URL.createObjectURL(image);
        this._temp = isUserEditProfile;
        const url = typeof image === 'string' ? image : await upload(image);

        if (url) {
          if (isUserProfile) {
            this.changeUserCoverPicture(url);
          } else if (isUserEditProfile) {
            this.changeUserCoverPicture(url);
            // this.updateCurrentUserTempCoverPicture(url);
          }
          const { currentStep, stepForward } = onboardingStore();

          // if (currentStep.value === 22) {
          //   stepForward();
          // }
        }
      } catch (error) {
      } finally {
        setTimeout(() => {
          this._changingCover = '';
          this._temp = false;
          toast.show('Header image updated', 'nonative', 'success');
        }, 2000);
      }
    },
    async getUsersCurrency() {
      const resp = await usersCurrency();
      this._currency = resp;
    },
    async getUserFreebieDetails() {
      const resp = await usersFreebie();
      this._freebie = resp;
    },
    async updateContent(payload: {}) {
      const res = await updateContentPreferences(payload);
      this.SET_USER({
        ...this._user,
        is_nsfw: res.response.is_nsfw,
        show_nsfw: res.response.show_nsfw,
      });
      await toast.show('Content Preferences updated successfully!', 'nonative', 'success');
    },
  },
  getters: {
    loggedIn(): any {
      return this._loggedIn;
    },
    isEmailVerified(): boolean {
      return this._user?.is_email_verified;
    },
    userBannedUntil(): any {
      return this._user?.banned_until ? new Date(this._user.banned_until) : null;
    },
    currencyBoxImage(): any {
      return this._currencyBoxImage;
    },
    notificationsInitialized(): any {
      return this._notificationsInitialized;
    },
    notificationsList(): any {
      return this._notificationsList;
    },
    authToken(): any {
      return this._authToken;
    },
    isScrollAtBottom(): any {
      return this._isScrollHandleAtBottom;
    },
    isFirstCharacterCreationChallenge(): any{
      return this._firstCharacterCreation
    },
    previousAvailableCompliment(): any {
      return this._tempAvailableCompliment;
    },
    totalComplimentedCount(): any {
      return this._totalComplimentedCount;
    },
    complimentCount(): any {
      return this._complimentCount;
    },
    usersFreebie(): any {
      return this._freebie;
    },
    usersCurrency(): any {
      return this._currency;
    },
    isAuthenticated(): boolean {
      return !!this._authToken && !!this._user;
    },
    isStaff(): any {
      return this._user && this._user.is_mod;
    },
    isOnboarding(): any {
      const dateJoined = new Date(this._user?.date_joined);
      const now = new Date();
      const onboardingEndDate = new Date(this._user?.date_joined);
      onboardingEndDate.setDate(dateJoined.getDate() + constants.onboardingPeriodDays);
      return now < onboardingEndDate;
    },
    isOnboardingDay0_1(): any {
      const dateJoined = new Date(this._user?.date_joined);
      const now = new Date();
      const d1 = new Date(this._user?.date_joined);
      d1.setDate(dateJoined.getDate() + 1);
      return now < d1;
    },
    isOnboardingDay1_2(): any {
      const dateJoined = new Date(this._user?.date_joined);
      const now = new Date();
      const d1 = new Date(this._user?.date_joined);
      const d2 = new Date(this._user?.date_joined);
      d1.setDate(dateJoined.getDate() + 1);
      d2.setDate(dateJoined.getDate() + 2);
      return d1 <= now && now < d2;
    },
    isOnboardingDay2_3(): any {
      const dateJoined = new Date(this._user?.date_joined);
      const now = new Date();
      const d2 = new Date(this._user?.date_joined);
      const d3 = new Date(this._user?.date_joined);
      d2.setDate(dateJoined.getDate() + 2);
      d3.setDate(dateJoined.getDate() + 3);
      return d2 <= now && now < d3;
    },
    isOnboardingDay3_end(): any {
      const dateJoined = new Date(this._user?.date_joined);
      const now = new Date();
      const d3 = new Date(this._user?.date_joined);
      const dEnd = new Date(this._user?.date_joined);
      d3.setDate(dateJoined.getDate() + 3);
      dEnd.setDate(dateJoined.getDate() + constants.onboardingPeriodDays);
      return d3 <= now && now < dEnd;
    },
    isUploading(): any {
      return this.uploading;
    },
    isEventUserRegistered(): any {
      return this._userEventSignUp;
    },
    isLoggingOut(): any {
      return this._isLoggingOut;
    },
    preparedUserUpdates(): any {
      return this._preparedUserUpdates;
    },
    inActionRegistration(): any {
      return this._inActionRegistration;
    },
    async referrer(): Promise<any> {
      return this._referrer || (await storage.get(constants.lsKeys.referrer));
    },
    user(): any {
      return this._user;
    },
    userId(): any {
      return this._user.id || constants.anonUserId;
    },
    emailSettings(): any {
      return this._emailSettings;
    },
    getResetPassTokenInvalid(): any {
      return this._resetPasswordTokenInvalid;
    },
    lastPostAs(): any {
      return this._lastPostAs;
    },
    lastPostAsName(): any {
      return this._lastPostAsName;
    },
    lastCommentAs(): any {
      return this._lastCommentAs;
    },
    lastCommentAsName(): any {
      return this._lastCommentAsName;
    },
    userTryOnAnimation(): any {
      return this._userTryOnAnimation;
    },
    userTryOnBgImage(): any {
      return this._userTryOnBgImage
    },
    userTryonImage(): any {
      return this._userTryonImage
    },
    isSavedToast(): any {
      return this._isSavedToast;
    },
    isPrfRingUpdated(): any {
      return this._isPrfRingUpdated;
    },
    profileInstance(): any {
      return this._profileInstance;
    },
    isTempApplied(): any {
      return this._isTempApplied;
    },
    isCreatedToast(): any {
      return this._isCreatedToast;
    },
    previousTemplate(): any {
      return this._previousTempData;
    },
    previousCustomize(): any {
      return this._previousCustomizeData;
    },
    counter(): any {
      return this._counter;
    },
    userPro(): any {
      return this._user.pro || {};
    },
    isPro(): any {
      return Object.keys(this._user.pro || {}).length > 0;
    },
    subscriptionLevel(): number {
      return this._user.subscription_level || 0;
    },
    subscriptionDuration(): string {
      return this._user.subscription_duration || '';
    },
    userImageStorage(): any {
      return this._userImageStorage || 0;
    },
    creationsVisible(): any {
      return this._creationsVisible;
    },
    userStorageQuota(): any {
      return this._userStorageQuota || 0;
    },
    userStoragePercentage(): any {
      return this._storageSpacePercentage || 0;
    },
    imageType(): any {
      return this._imageTypes || [];
    },
    userCurrentTempProfilePic(): any {
      return this._currentTempProfilePic || null;
    },
    userCurrentTempCoverPic(): any {
      return this._currentTempCoverPic || null;
    },
    changingProfilePicture(): string {
      return this._changingProfilePicture;
    },
    changingCover(): string {
      return this._changingCover;
    },
    temp(): boolean {
      return !!this._temp;
    },
    currencyConfig(): any {
      return this._currencyConfig || {};
    },
    userHasCharacters(): boolean {
      if (this._userCharactersLoading) return true;

      return !!this._userCharacters.length;
    },
  },
});

export const authStore = () => {
  const store = useAuth();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
