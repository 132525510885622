import { GetterTree } from 'vuex';
import { NotificationsState } from './types';
import { RootState } from '@/shared/store';

export const getters: GetterTree<NotificationsState, RootState> = {
  notifications(state: NotificationsState) {
    return state.notifications;
  },
  notificationsPaging(state: NotificationsState) {
    return state.notificationsPaging;
  },
  unreadCount(state: NotificationsState) {
    return state.unreadCount;
  },
  unreadCountFromAPI(state: NotificationsState) {
    return state.unreadCountFromAPI;
  },
};
