<template>
  <div>
    <ion-skeleton-text v-if="isLoading" :animated="true" class="storage-space-loader" />
    <div v-else :class="isSideMenu ? 'side-menu-storage' : ''">
      <div class="img-storage" :class="{ 'mx-2': !isStorageFullModal }">
        <div class="d-flex justify-content-center align-items-center" v-if="!isStorageFullModal && showTitle">
          <StorageSpaceIcon :plan="subLevelMap[get(loggedInUser, 'subscription_level')]" />
          <span class="title">My Image Storage Space </span>
          <SubPlanBadge
            :plan="subLevelMap[get(loggedInUser, 'subscription_level')]"
            class="ml-2"
            v-if="get(loggedInUser, 'subscription_level') > 0"
          />
        </div>

        <div :class="{ 'mt-2': !isStorageFullModal && showTitle }" class="memory-text text-center">
          <span class="used-memory-text" :class="{ 'text-danger': userStoragePercentage >= 90 }"
            >{{ imgStorageKbtoGbConverter(userImageStorage) }} GB</span
          >
          of
          {{ imgStorageKbtoGbConverter(userStorageQuota) }} GB (<span
            :class="{ 'text-danger': userStoragePercentage >= 90 }"
            >{{ userStoragePercentage }}%</span
          >) used
        </div>

        <div class="py-1">
          <div class="d-flex space-bar-wrap">
            <div
              v-for="(imgType, index) in imageType"
              :key="index"
              class="img-space-bar"
              :style="{ width: `${imgType.percentage}%`, background: `${imgType.color}` }"
            ></div>
          </div>
        </div>

        <div class="row w-100 no-gutters">
          <div
            class="d-flex flex-wrap align-items-center mr-1 memory-text"
            :class="{ 'mr-3': isStorageFullModal }"
            v-for="(img, index) in imageType"
            :key="index"
          >
            <div v-if="img.percentage" class="pt-1 mr-1 small-circle" :style="{ background: `${img.color}` }" />
            <div>
              {{ img.name }}
              <span v-if="img.percentage"
                >({{ img.percentage < 0.1 ? 0.1 : round(img.percentage, img.percentage < 1 ? 1 : 0) }}%)</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-center flex-wrap text-center align-items-center mt-1 mx-2 memory-text text-gray"
      v-if="!isStorageFullModal"
    >
      <div>Uploads for Posts, Events, FanArt & Decorations <strong> don't </strong> affect storage.</div>
    </div>
    <div v-else class="py-2" :class="{ 'px-2': isStorageFullModal }">
      To upload more images, you need more <strong>storage space.</strong> You can either free up space by
      <strong>deleting uploaded images,</strong> or <strong class="text-primary">upgrading your current plan.</strong>
    </div>

    <div
      class="d-flex justify-content-center mt-2"
      v-if="
        !isStorageFullModal &&
        featureFlags.subscriptionUpgrade &&
        get(loggedInUser, 'subscription_level') !== subscriptionLevels.ultra
      "
    >
      <UpgradeSubscriptionText postfix="for more storage." :bg-color="upgradeTagBgColor" from="imageStorageSpace" />
    </div>
    <div v-else class="d-flex upgrade">
      <div
        class="w-100"
        v-for="(upgrade, index) in upgradeData"
        :key="index"
        :class="upgrade.type === 'ultra' ? 'upgrade-border-ultra' : 'upgrade-border-plus'"
      >
        <div class="px-2 py-2">
          <div
            class="d-flex justify-content-center text-center align-items-center flex-column upgrade-text"
            :class="upgrade.type === 'ultra' ? 'ultra-color' : 'plus-color'"
          >
            <span>Upgrade to {{ upgrade.type }} and get</span>
            <span class="d-flex justify-content-center align-items-center text">{{ upgrade.space }}GB</span>
            <span>of image space storage</span>
          </div>
          <div class="d-flex justify-content-center">
            <ion-button
              class="upgrade-button clickable-item"
              :class="upgrade.type === 'ultra' ? 'upgrade-btn-ultra' : 'upgrade-btn-plus'"
              @click="openSubscribeModal($event, 'imageStorageSpace')"
              >Upgrade</ion-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import UpgradeSubscriptionText from './UpgradeSubscriptionText.vue';
import SubPlanBadge from './SubPlanBadge.vue';
import StorageSpaceIcon from './StorageSpaceIcon.vue';
import { featureFlags } from '@/shared/config/feature-flags';
import constants from '@/shared/statics/constants';
import { openSubscribeModal } from '@/shared/utils/modals';

const {
  fetchImageStorage,
  userImageStorage,
  imageType,
  userStorageQuota,
  user: loggedInUser,
  userStoragePercentage,
} = authStore();
const props = defineProps({
  isStorageFullModal: { type: Boolean, default: false },
  storageTiers: { type: Object, default: {} },
  isSideMenu: { type: Boolean, default: false },
  upgradeTagBgColor: { type: String, default: '#f3f3f3' },
  showTitle: { type: Boolean, default: false },
});

const upgradeData: any = ref([]);
const isLoading = ref(false);
const storageTiers = toRef(props, 'storageTiers');
const isSideMenu = toRef(props, 'isSideMenu');
const subscriptionLevels = constants.subscriptionLevels;
const subLevelMap = constants.subscriptionLevelsTextMap;

watch(storageTiers, () => {
  upgradeData.value = upgradeType.value.map((item: any) => ({
    ...item,
    space: imgStorageKbtoGbConverter(storageTiers.value[item.value]),
  }));
});

const upgradeType = computed(() => {
  if (loggedInUser.value.subscription_level === subscriptionLevels.free) {
    return [
      { type: '+plus', value: 'plus' },
      { type: 'ultra', value: 'plus_ultra' },
    ];
  } else if (loggedInUser.value.subscription_level === subscriptionLevels.plus) {
    return [{ type: 'ultra', value: 'plus_ultra' }];
  } else {
    return [];
  }
});

const getImagesStorage = async () => {
  isLoading.value = true;
  fetchImageStorage();
  isLoading.value = false;
};

const imgStorageKbtoGbConverter = (value: any) => {
  let result = value / (1024 * 1024);
  if (result < 0.01) return 0;
  return round(result, 2);
};

onMounted(async () => {
  await getImagesStorage();
});
</script>

<style scoped lang="sass">
.space-icon-plus-bg
  color: #00b4c5 !important
.used-memory-text
  font-size: 18px
  font-weight: bold

.memory-text
  font-size: 13px
.ultra-color
  background: linear-gradient(111deg, rgb(182 53 225) 30%, rgb(136 109 254) 55%, #6492e6 25%, #63e9e5 76%) text
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
.plus-color
  color: #00b4c5

.upgrade-button
  width: 45%
  height: 42px
  --border-radius: 10px

.upgrade-button::part(native)
  border: 3px solid black
.small-circle
  border-radius: 20px
  width: 8px
  height: 8px
  min-width: 8px
  max-width: 8px

.text
  font-size: 45px !important
.upgrade
  gap: 13px
  @media(max-width: 520px)
    flex-direction: column

.upgrade-border-ultra
  border: 2px solid transparent
  border-radius: 10px
  background: linear-gradient(to right, white, white), linear-gradient(120deg, rgb(216, 56, 247) 19%, rgb(146 96 248) 35%, rgb(85, 202, 229) 81%, rgb(100, 236, 229) 44%)
  background-clip: padding-box, border-box
  background-origin: padding-box, border-box

.upgrade-border-plus
  border: 2px solid #00b4c5
  border-radius: 10px
.upgrade-text
  font-size: 18px
  font-weight: bold
.storage-space-loader
  width: 360px
  height: 150px
  margin-left: 23px

.upgrade-btn-ultra
  --background: linear-gradient(165deg ,rgb(190 52 226) 21%,rgb(145 97 249) 34%,rgb(88 208 228) 79%,rgb(215 56 247) 85%)
  opacity: 1
  animation: gradient 4s ease infinite
  transition: transform 0.2s
.upgrade-btn-ultra:after
  content: ""
  position: absolute
  top: 4px
  left: 4px
  width: calc(100% - 8px)
  background: linear-gradient(to top, rgb(255 255 255 / 66%), rgba(255, 255, 255, 0))
  border-radius: 10px
  height: 34px
@keyframes gradient
  50%
    background-position: 140% 50%
    transform: skew(-2deg)
.upgrade-btn-plus
  --background: #24cbe1
  opacity: 1
  animation: gradientplus 4s ease infinite
  transition: transform 0.2s
.upgrade-btn-plus:after
  content: ""
  position: absolute
  top: 5px
  left: 4px
  width: calc(100% - 8px)
  background: linear-gradient(rgb(255 255 255 / 66%), rgb(255 255 255 / 0%))
  border-radius: 10px
  height: 35px
@keyframes gradientplus
  50%
    background-position: 140% 50%
    transform: skew(-2deg)

.space-icon
  font-size: 24px
  color: #ae38e5 !important

.img-space-bar
  &:first-child
    border-top-left-radius: 10px
    border-bottom-left-radius: 10px
  &:last-child
    border-top-right-radius: 10px
    border-bottom-right-radius: 10px

.space-bar-wrap
  background-color: #e0e0e0
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2)
  border-radius: 10px
  height: 6px

.img-storage
  border-radius: 10px
  border: 2px solid #e3e4ec
  padding: 9px
.side-menu-storage
  padding: 6px

.title
  font-size: 18px
  font-weight: bold
</style>
