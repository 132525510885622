import { Module } from 'vuex';
import { RootState } from '../../index';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { FanWorksState } from './types';

const state: FanWorksState = {
  hasUnopenedFanWork: 0,
};

export const FanworksModule: Module<FanWorksState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
