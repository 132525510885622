<template>
  <ion-modal
    class="modal-with-content"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="close"
  >
    <ion-content class="h-100">
      <ion-button class="clickable-item-hov top-right gray" color="transparent" @click.prevent="close">
        <i class="ti-close close-button" color="medium" />
      </ion-button>
      <h4 class="headers">Transfer Ownership of "{{ character?.info.name }}"</h4>
      <div v-if="!pendingCharacterTransfer" class="background align-items-center justify-content-center">
        <div class="fields pt-1">
          <ion-textarea
            v-model="note"
            class="c-textarea mb-3"
            placeholder="Add any terms or notes for the transfer here. This record will be public!"
          />

          <ion-searchbar
            v-model="searchValue"
            class="c-input c-search"
            autocomplete="off"
            :debounce="300"
            show-clear-button="focus"
            placeholder="Search for a user"
            @ionInput="lookup"
          />
          <input type="text" placeholder="Username" style="display: none" />
          <div v-if="lookupOpen" class="results w-100">
            <div v-if="!lookupUsers?.length" class="no-data">No results</div>
            <div
              v-for="(user, index) of lookupUsers"
              :key="user.id"
              class="one-result d-flex py-2 px-2 text-align-left w-100"
              @click="selectUser(user)"
            >
              <span class="result-name">
                <span
                  ><img
                    loading="lazy"
                    v-image
                    class="lookup-result-thumb"
                    :src="resizeUpload(user.profile_picture_url, '80x80')"
                  />
                  @{{ user.username }}</span
                >
              </span>
            </div>
          </div>
          <div v-if="selectedUser">
            <div class="d-flex-column justify-content-center align-items-center w-100">
              <p class="text-center">
                <span class="bold">@{{ selectedUser.username }}</span> must accept the request for the transfer to go
                through.
              </p>
              <p class="text-center">
                <span class="bold">Are you sure</span> you want to transfer ownership of this character?
              </p>
            </div>
            <div class="d-flex justify-content-center">
              <ion-button class="action-btn clickable-item-hov" color="danger" @click="transfer">
                <span>Yes</span>
              </ion-button>
              <ion-button class="action-btn clickable-item-hov" color="medium" @click="close">
                <span>No</span>
              </ion-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="background align-items-center justify-content-center">
        <div class="d-flex filler"></div>
        <h5 class="text-center">
          Your transfer request sent to @{{ pendingCharacterTransfer?.user_to?.username }} is pending.
        </h5>
        <div class="d-flex justify-content-center">
          <ion-button class="clickable-item-hov" color="danger" @click="cancelRequest">
            <span>Cancel Request</span>
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script lang="ts" setup>
import { Character, User, CharacterTransferRequest } from '@/shared/types/static-types';
import { partialLookup } from '@/shared/actions/users';
import { characterProfileStore } from '@/shared/pinia-store/character-profile';
import { resizeUpload } from '@/shared/utils/upload';

const { pendingCharacterTransfer, initiateCharacterTransferRequest, cancelPendingCharacterTransferRequest } =
  characterProfileStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  character: {
    type: Object as PropType<Character>,
    required: true,
  },
  pendingTransferRequest: {
    type: Object as PropType<CharacterTransferRequest>,
    required: false,
  },
});

const emits = defineEmits(['close']);
const character = toRef(props, 'character');
const isOpen = toRef(props, 'isOpen');
const searchValue = ref('');
const lookupOpen = ref(false);
const lookupUsers = ref<User[]>([]);
const selectedUser = ref<User | null>(null);
const note = ref('');

const close = (transferReq?: CharacterTransferRequest) => {
  emits('close', transferReq);
};

const transfer = async () => {
  if (!selectedUser.value) return;
  const safeNote = (note.value || '').trim();
  const transferRequest = await initiateCharacterTransferRequest(selectedUser.value.id, safeNote);
  close(transferRequest);
};

const cancelRequest = async () => {
  if (!pendingCharacterTransfer.value) return;
  await cancelPendingCharacterTransferRequest();
  close();
};

const lookup = async (event: CustomEvent) => {
  const usersArray = await partialLookup(searchValue.value);
  lookupUsers.value = usersArray;
  lookupOpen.value = true;
};

const selectUser = (user: User) => {
  lookupOpen.value = false;
  lookupUsers.value = [];
  searchValue.value = '';
  selectedUser.value = user;
};
</script>

<style lang="sass" scoped>
.top-right
  position: absolute
  top: 0.5rem
  right: 0.5rem
.close-button
  color: gray
.headers
  text-align: center
.title
  font-weight: bold
  font-size: 22px
.background
  margin: 1rem
.results
  margin-top: 0.25rem
  max-height: 230px
  .one-result
    min-height: 2rem
    border-bottom: 1px solid #CCC
    cursor: pointer
    position: relative
    flex-direction: column
    &:hover
      opacity: 0.9
      color: var(--ion-color-primary) !important
      background: rgba(125,125,125,0.1)
.lookup-result-thumb
  height: 25px
  width: 25px
  object-fit: cover
  border-radius: 50%
.c-search
  ::v-deep
    padding: 0
    ion-icon
      margin-top: 0.25rem
    input
      padding-left: 3rem !important
      height: 3.2rem !important
.action-btn
  width: 100px
.filler
  height: 15vh
</style>
