import { ReactionWorldCounts, UserReaction, WorldsInfo } from '@/shared/types/static-types';
import { SocialSpace } from '@/shared/types/static-types';
import { createReactionOnWorld, deleteReactionOnWorld, updateReactionOnWorld } from '@/shared/actions/reactions';

const isUserReactedSameReaction = (reaction: string, userReaction: any, isInstant = false): boolean => {
  return !!(userReaction && userReaction.id && (userReaction.reaction === reaction || isInstant));
};

const isUserReactedAnotherReaction = (reaction: string, userReaction: any): boolean => {
  return !!(userReaction && userReaction.id && userReaction.reaction !== reaction);
};

export const sendReactionToBackend = async (
  reaction: string,
  userReaction: any,
  world: WorldsInfo | SocialSpace,
  isInstant = false
) => {
  const target = 'world';
  const { id: worldId = '' } = world;

  // delete
  if (isUserReactedSameReaction(reaction, userReaction, isInstant)) {
    return await deleteReactionOnWorld({ reactionId: userReaction?.id || '' });
  }

  // patch
  if (isUserReactedAnotherReaction(reaction, userReaction)) {
    return await updateReactionOnWorld({ reactionId: userReaction?.id || '', reaction });
  }

  // post
  const data = await createReactionOnWorld({ objectId: worldId, reaction, target });
  return data;
};

export const updateReactionsDataForWorld = (
  reaction: string,
  updatedUserReaction: any,
  updatedReactionCounts: any,
  newReaction: UserReaction
): Partial<WorldsInfo> => {
  const reactionCounts = updatedReactionCounts || { total_count: 0 };
  const totalCount = updatedReactionCounts.total_count;
  let userReaction = updatedUserReaction;

  const prevUserReaction = userReaction as UserReaction;

  // editing counts if un-reacting or editing reaction
  if (userReaction as UserReaction) {
    reactionCounts[prevUserReaction.reaction as keyof ReactionWorldCounts]--;
    if (newReaction.reaction in reactionCounts) {
      reactionCounts[newReaction.reaction as keyof ReactionWorldCounts]++;
    } else {
      // ignore; newReaction.reaction will never be "ordering" (which requires type string[])
      //  @ts-ignore
      reactionCounts[newReaction.reaction as keyof ReactionWorldCounts] = 1;
    }
  }
  // if reacting to story for the first time: editing counts & user_reaction
  if (!userReaction?.id) {
    reactionCounts!.total_count = totalCount + 1;
    if (!reactionCounts[newReaction.reaction as keyof ReactionWorldCounts]) {
      //  @ts-ignore
      reactionCounts[newReaction.reaction as keyof ReactionWorldCounts] = 1;
    } else {
      //  @ts-ignore
      reactionCounts[newReaction.reaction as keyof ReactionWorldCounts] += 1;
    }
    userReaction = newReaction;
  }

  // if editing reaction
  if (isUserReactedAnotherReaction(reaction, userReaction)) {
    userReaction!.reaction = reaction;
  }

  // if un-reacting
  if (isUserReactedSameReaction(reaction, userReaction) && !newReaction) {
    reactionCounts!.total_count = totalCount > 0 ? totalCount - 1 : 0;
    userReaction = undefined;
  } else {
    userReaction = {
      ...userReaction,
      reaction,
    };
  }

  const out = {
    user_reaction: userReaction,
    reaction_counts: reactionCounts,
  };
  return out;
};

export const react = async (
  reaction: any,
  world: WorldsInfo | SocialSpace,
  userReaction: any,
  updatedReactionCounts: any,
  isInstant: boolean = false
) => {
  const newReaction = await sendReactionToBackend(reaction, userReaction, world, isInstant);
  const updatedReactionsData = updateReactionsDataForWorld(reaction, userReaction, updatedReactionCounts, newReaction);
  return updatedReactionsData;
};
