<template>
  <div
    v-if="image"
    :id="`i_${id}`"
    class="inner-img grid-item position-relative"
    :class="{
      'inner-i-d': isEditor,
      'pointer-events-none': isReadOnlyCollection,
      'drag-prio': !isReordering,
    }"
    :draggable="isEditor"
  >
    <div v-if="isEditor" class="top w-100">
      <div class="position-relative ml-1">
        <ion-button
          v-if="get(image, 'type', 'standard') == 'standard'"
          fill="solid"
          :color="imageButtonColor"
          class="one-button left"
          @click.prevent="openEditImageModal"
        >
          <i class="ti-pencil" />
        </ion-button>
        <ion-button
          v-if="get(image, 'type', 'standard') == 'standard' && image?.id && image?.image"
          fill="solid"
          :color="image?.is_watermarked ? 'medium' : imageButtonColor"
          class="one-button ml-1"
          :title="image?.is_watermarked ? 'Remove watermark' : 'Watermark image'"
          @click="toggleImageWatermark"
        >
          <span>
            <inline-svg v-if="image?.is_watermarked === false" src="/icons/watermark-on.svg" />
            <inline-svg v-if="image?.is_watermarked === true" src="/icons/watermark-off.svg" />
          </span>
        </ion-button>
        <ion-button
          v-if="image?.is_spoiled"
          fill="solid"
          :color="imageButtonColor"
          class="one-button left"
          style="--padding-start: 0; --padding-end: 0; height: 30px; width: 30px"
          disabled
        >
          <ion-icon class="icon" style="font-size: 18px !important" :icon="eyeOffOutline" />
        </ion-button>
        <ion-button
          fill="solid"
          :color="imageButtonColor"
          class="one-button right position-absolute"
          @click="startRemoveImage"
        >
          <i class="ti-close" />
        </ion-button>
      </div>
    </div>
    <div v-if="isEditor && !image?.image" class="upload-image">
      <UploadForm
        :is-multiple="false"
        :save-watermark="true"
        :full-image-info="true"
        :outfits="image?.id"
        :used-key="imageUsedKey"
        @storageFullError="closeAndOpenStorageModal"
        class="upload-button"
        @uploaded="addImage"
        @loading="setUploading"
      >
        <ion-button :disabled="isUploading" :color="imageButtonColor" :loading="isUploading" class="upload-button">
          <ChLoading size="sm" v-if="isUploading" class="spinner" />
          <div v-else class="no-select">
            <i class="ti-plus" />
            Add Image
          </div>
        </ion-button>
      </UploadForm>
    </div>

    <div class="img-content">
      <ion-button
        v-if="image?.user !== user.id"
        shape="round"
        color="dark"
        class="report-btn position-absolute report-top"
        @click.stop="dropDownToggle($event, image)"
      >
        <i class="ti-more-alt" />
      </ion-button>
      <div
        v-if="!isEditor && !excludedImageReactionRoutes.includes($route.name as string)"
        class="hold-left reaction-bottom position-absolute"
        :class="{ top: !isMobSmallScreen }"
      >
        <Reaction
          type="visual"
          :reactions="image.reaction_counts"
          :user-reaction="image.user_reaction"
          :index="index + 1"
          :is-mob-small-screen="isMobSmallScreen"
          :left-indent-popup="index % 2 === 0"
          :right-indent-popup="index % 2 === 1"
          @changed="(reaction) => reactionChanged(reaction)"
          @click.stop
          @hideReactions="onHideReactions"
          @showReactions="onShowReactions"
        >
          <ion-button
            :color="!!image.user_reaction && image.user_reaction.reaction ? 'secondary' : ''"
            class="p-1 reaction-btn"
            :style="`--background: ${buttonColor}; color: ${textColor};`"
          >
            <div class="d-flex actions">
              <i class="ti-thumb-up" />
              <span class="mx-1 reaction-count">{{ image.reaction_counts?.total_count || 0 }}</span>
            </div>
          </ion-button>
        </Reaction>
      </div>

      <div @click="pressImage" v-if="image?.is_spoiled && !isEditor && !image?.spoiled_images.user" class="image-blur">
        {{ image?.spoiled_text ? image?.spoiled_text : 'Spoiler' }}
      </div>
      <div class="w-100 fc my-1">
        <div class="vis-outline">
          <img
            :class="image?.image ? 'vis' : 'default-image'"
            :src="
              resizeUpload(
                image?.image ||
                  (usedKey === 'outfits'
                    ? '/images/autumn.svg'
                    : usedKey === 'favorites'
                    ? '/images/hamburger.svg'
                    : usedKey === 'inventory'
                    ? '/images/glasses.svg'
                    : '/images/baby.svg') ||
                  '/empty.png',
                '240h'
              )
            "
            @click="pressImage"
            @contextmenu="handleContextMenu"
            :style="image?.is_spoiled && !isEditor && !image?.spoiled_images.user ? 'filter:blur(50px)' : ''"
            class="no-select"
          />

          <div v-if="!isEditor" class="text-center">
            {{ truncate(image.description, { length: 20 }) }}
          </div>
        </div>

        <div
          v-if="isWatermarkLoading && !editImageModalIsOpen"
          class="watermarking-card w-100"
          :class="isEditor || image.description ? '' : 'rounded-bottom'"
        >
          <div class="h-100 position-relative">
            <div class="d-flex justify-content-center waiting-icon"><i class="ti-time" /></div>
            <div class="text-center mt-2 process-text">Image Processing...</div>
            <div v-if="!isMobSmallScreen" class="text-center mt-2">{{ watermarkLoadText }}</div>
          </div>
        </div>
        <div v-if="isEditor" class="vis-desc rounded-bottom">
          <input
            v-model="image.description"
            type="text"
            class="vis-desc-editor mt-0 p-2 rounded-bottom"
            placeholder="Enter caption here"
            maxlength="255"
            @input="debounceSave"
          />
        </div>
      </div>
    </div>

    <ImageStorageSpaceFullModal :is-open="isOpenStorageFullModal" @close="isOpenStorageFullModal = false" />
  </div>
</template>
<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import { mainStore } from '@/shared/pinia-store/main';
import { imageStore } from '@/shared/pinia-store/images';
import ImagePopover from '@/shared/components/storage/popovers/ImagePopover.vue';
import { excludedImageReactRoutes } from '@/shared/statics/constants';
import Reaction from '@/shared/components/Reaction/index.vue';
import { reactImage } from '@/shared/pinia-store/shared/image';
import UploadForm from '@/shared/components/upload-form.vue';
import { eyeOffOutline } from 'ionicons/icons';
import ImageStorageSpaceFullModal from '@/shared/modals/ImageStorageSpaceFullModal.vue';
import { characterEditorStore } from '@/shared/pinia-store/character-editor';
import { popovers } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import { textColorChanged } from '@/shared/utils/textConverter';
import { resizeUpload } from '@/shared/utils/upload';

const { setRemovedCharImages } = characterEditorStore();
const { user } = authStore();

const excludedImageReactionRoutes = excludedImageReactRoutes;
const props = defineProps({
  isEditor: {
    type: Boolean,
    default: false,
  },
  id: {
    type: String,
    default: () => '',
  },
  customize: {
    type: Object,
    default: () => ({}),
  },

  image: {
    type: Object,
    default: () => {},
  },
  index: {
    type: Number,
    default: 0,
  },
  isWatermarkLoading: {
    type: Boolean,
    default: false,
  },
  watermarkLoadText: {
    type: String,
    default: () => '',
  },
  usedKey: {
    type: String,
    default: '',
  },
  editImageModalIsOpen: {
    type: Boolean,
    default: false,
  },
  reordering: {
    type: Boolean,
    default: false,
  },
});
const isEditor = toRef(props, 'isEditor');
const id = toRef(props, 'id');
const index = toRef(props, 'index');
const customize = toRef(props, 'customize');
const image = toRef(props, 'image');
const usedKey = toRef(props, 'usedKey');
const isOpenStorageFullModal = ref(false);
const isReordering = toRef(props, 'reordering');
const isUploading = ref(false);
const emits = defineEmits([
  'onReaction',
  'openEditModal',
  'hideReactions',
  'showReactions',
  'touched',
  'watermark',
  'updatedImage',
  'deleteImage',
  'updatedImageReaction',
  'uploadingImage',
  'addImage',
]);

const dark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const imageButtonColor = computed(() => {
  return dark.value ? 'light' : 'dark';
});

const currentImageCollectionId = computed(() => {
  const { visibleImageCollectionId } = imageStore();
  return visibleImageCollectionId.value;
});
const closeAndOpenStorageModal = () => {
  isOpenStorageFullModal.value = true;
};

const debounceSave = debounce(() => emits('updatedImage', image.value), 1000);

const windowWidth = computed(() => {
  const { width } = useWindowSize();
  return width.value;
});

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 580;
});

const buttonColor = computed(() => {
  return isEmpty(get(customize.value, 'template_applied')) &&
    get(customize.value, 'data.theme.backgrounds.buttons') === '#AE38E5'
    ? '#ae38e5'
    : get(customize.value, 'data.theme.backgrounds.buttons', '#ae38e5');
});

const isReadOnlyCollection = computed(() => {
  const { isVisibleImageCollectionReadOnly } = imageStore();
  return isVisibleImageCollectionReadOnly.value;
});

const dropDownToggle = (ev: CustomEvent, imgObj: any) => {
  popovers.open(ev, ImagePopover, { image: imgObj, usedKey: usedKey.value });
};

const textColor = computed(() => {
  return buttonColor.value ? textColorChanged(buttonColor.value) : '#fff';
});

const reactionChanged = async (reaction: any) => {
  const { newReaction, updatedReactionsData } = await reactImage({ reaction: reaction.key, image: image.value, fromRoulette: false });
  emits('updatedImageReaction', {
    ...image.value,
    user_reaction: newReaction,
    reaction_counts: updatedReactionsData.reaction_counts,
  });
};

const imageUsedKey = computed(() => {
  return usedKey.value === 'outfits'
    ? 'character_outfit'
    : usedKey.value === 'favorites'
    ? 'character_favorites'
    : usedKey.value === 'inventory'
    ? 'character_inventory'
    : 'character_lifestages';
});

const setUploading = (val: boolean) => {
  isUploading.value = val;
  emits('uploadingImage', val);
};

const addImage = (img: any) => {
  if (!img) return;
  emits('addImage', img);
};

const toggleImageWatermark = () => {
  if (!image.value?.id) return;
  emits('watermark', { imageIds: [image.value?.id!], watermarking: !image.value.is_watermarked });
};

const openEditImageModal = () => {
  emits('openEditModal', image.value);
};

const startRemoveImage = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `You are removing this ${
        usedKey.value === 'outfits'
          ? 'Outfit'
          : usedKey.value === 'favorites'
          ? 'Favorite'
          : usedKey.value === 'inventory'
          ? 'Inventory'
          : 'Life Stage'
      }.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      emits('deleteImage', image.value);
      setRemovedCharImages({ ids: [image.value.id] });
    }
  } catch (e) {}
};

const pressImage = () => {
  if (isEditor.value) return;
  if (!image.value?.id) return;

  if (image.value.is_spoiled && !image.value?.spoiled_images.user) {
    emits('updatedImage', { ...image.value, is_spoiled: false });
    return;
  }
  const forceData = {} as any;
  forceData.latest = currentImageCollectionId.value;
  openEditImageModal();
};
const onShowReactions = () => {
  if (index.value < 2) emits('showReactions');
};
const onHideReactions = () => {
  if (index.value < 2) emits('hideReactions');
};
const handleContextMenu = (e: any) => {
  if (isEditor.value) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    return false;
  }
};
</script>
<style scoped lang="sass">
.report-top
  top: 15px
  right: 8px
  z-index: 1
.report-btn
  --ion-color-dark: rgba(10, 9, 40, 0.8)
  --ion-color-contrast: #FFF !important
  --padding-start: 0
  --padding-end: 0
  width: 23px
  height: 18px
  font-size: 14px

.image-blur
  font-size: 12px
  top: 50%
  background: rgb(85 84 84)
  color: white
  border-radius: 20px
  padding: 15px
  text-align: center
  left: 50%
  transform: translateX(-50%) translateY(-50%)
  z-index: 1
  position: absolute
.grid-item
  align-self: stretch

.inner-img
  position: relative
  width: 100%
  height: 100%
  cursor: pointer
  border-radius: 10px
.rounded-bottom
  border-bottom-left-radius: 10px
  border-bottom-right-radius: 10px

.fc
  align-items: stretch
  display: inline-block

.vis-outline
  border-radius: 10px 10px 0 0 !important
  outline: 1px solid rgba(0,0,0,0.05) !important
  background: rgba(255,255,255,0.2)

.vis
  width: 100% !important
  height: 100% !important
  object-fit: contain
  aspect-ratio: 8/5
  padding-bottom: 3px
  object-position: center
  position: relative
  margin-bottom: -2px
  border-radius: 6px !important

.default-image
  width: 100% !important
  height: 100% !important
  object-fit: contain
  object-position: center
  aspect-ratio: 8/5
  position: relative
  padding: 45px

.drag
  transform: scale(0.5)

.vis-desc-editor
  color: rgb(51, 51, 51) !important
  width: 100%
  background-color: white !important
  font-size: 12px
  border: none
.vis-desc
  outline: 1px solid rgba(0,0,0,0.05) !important
  background-color: #aaa
.bottom-0
  bottom: 0
.top
  position: absolute
  margin-top: 0.25rem
  z-index: 1

.upload-image
  position: absolute
  z-index: 1
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 100% 
  display: flex 
  justify-content: center
  align-items: center
.right
  right: 0.4rem
.hold-left
  margin-left: 0.4rem
ion-button
  cursor: pointer
.one-button
  --border-radius: 8px
  width: 1.6rem !important
  height: 1.6rem !important
  --padding-start: 10px
  --padding-end: 10px
input[type=text]::placeholder
  color: #888 !important
.watermarking-card
  position: absolute
  background-color: rgba(255, 255, 255, 0.65) !important
  font-size: 12px !important
  border-radius: 10px !important
  border: none !important
  outline: none !important
  top: 0
  right: 0
  left: 0
  bottom: 0
  display: block !important
  z-index: 1
  justify-content: center !important
  align-items: stretch !important
  padding: 0 !important
  margin: 0 !important
  div
    top: 25%
  .waiting-icon
    font-size: 36px !important
    color: var(--color) !important
  .process-text
    color: var(--color) !important
    font-size: 14px !important
    font-weight: 400 !important
.reaction-count
  font-weight: bold
.reaction-btn
  --border-radius: 8px
  width: 45px
  height: 37px
.reaction-bottom
  bottom: 8px !important
  left: -5px !important
.img-content
  flex: 1
.upload-button
  width: 150px
  max-width: 120px
  border-radius: 10px
  height: 45px
  opacity: 0.9

  ::v-deep
    .c-button
      font-size: 15px !important
      --border-radius: 10px !important
      height: 40px
      text-transform: unset
.spinner
  height: 15px
</style>
