import { chAxios } from '@/shared/lib/axios';


export async function getProducts() {
  const url = `/p/details/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function initiateSubscriptionCheckout(priceId: string) {
  const url = `/p/init/`;
  const { data } = await chAxios().post(url, { price: priceId });
  return data;
}

export async function confirmSubscriptionCheckout(setupIntentId: string, priceId: string) {
  const url = `/p/confirm/`;
  const { data } = await chAxios().post(url, { setup_intent_id: setupIntentId, price_id: priceId });
  return data;
}

export async function initiateItemCheckout(item: string, quantity: number, currentUrl?: string) {
  const url = `/p/init-buy/`;
  const { data } = await chAxios().post(url, { item, quantity, currentUrl });
  return data;
}

export async function confirmItemCheckout(paymentIntentId: string) {
  const url = `/p/confirm-buy/`;
  const { data } = await chAxios().post(url, { payment_intent_id: paymentIntentId });
  return data;
}

export async function checkUserSubscription() {
  const url = `/p/status/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function modifySubscription(level: string, interval: string) {
  const url = '/p/modify/';
  const { data } = await chAxios().post(url, {level, interval});
  return data;
}

export async function preModifySubscription(level: string, interval: string) {
  const url = '/p/premodify/';
  const { data } = await chAxios().post(url, {level, interval});
  return data;
}

export async function cancelPendingChange() {
  const url = '/p/cancel-change/';
  const res = await chAxios().post(url);
  return res;
}

export async function checkForPendingChange() {
  const url = '/p/pending-change/';
  const { data } = await chAxios().get(url);
  return data;
}

export async function getShopItems() {
  const url = '/p/shop/';
  const { data } = await chAxios().get(url);
  return data;
}

export async function getGemShopListings() {
  const url = '/p/gems-shop/';
  const { data } = await chAxios().get(url);
  return data;
}

export async function getGemPackageListings() {
  const url = '/p/gems-packages/';
  const { data } = await chAxios().get(url);
  return data;
}

export async function buyGemShopListing(listing: string) {
  const url = '/p/gems-buy/';
  const { data } = await chAxios().post(url, { listing });
  return data;
}

export async function createOrderPaypal(product: string, quantity=1) {
  const url = '/p/p-create/';
  const { data } = await chAxios().post(url, { product, quantity });
  return data;
}

export async function captureOrderPaypal(order_id: string) {
  const url = '/p/p-capture/';
  const body = { order_id };
  const { data } = await chAxios().post(url, body);
  return data;
}

export async function initiateSubscriptionCheckoutPaypal(priceId: string) {
  const url = `/p/p-init/`;
  const { data } = await chAxios().post(url, { plan_id: priceId });
  return data;
}

export async function verifySubscriptionCheckoutPaypal(id: string) {
  const url = `/p/p-confirm/`;
  const { data } = await chAxios().post(url, { id });
  return data;
}

export async function preModifySubscriptionPaypal(level: number, interval: string) {
  /*
  */
  const url = '/p/p-premodify/';
  const { data } = await chAxios().post(url, {level, interval});
  return data;
}

export async function confirmModifySubscriptionPaypal(sid: string) {
  /*
  After the user has made the new subscription, this function is called to confirm the subscription
  And cancel the old one. (Should this fail, server webhook should handle it)
  sid: Subscription ID
  */
  const url = '/p/p-postmodify/';
  const { data } = await chAxios().post(url, { sid });
  return data;  // this returns empty 204
}
