<template>
  <client-only>
    <ion-content ref="modal" @willDismiss="dismissModal" scroll-y="true">
      <ion-header>
        <ion-toolbar>
          <ion-title mode="md" slot="start"
            ><strong>{{ title }}</strong></ion-title
          >
          <ion-buttons slot="end">
            <ion-button size="large" @click="dismissModal">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div class="position-relative p-1" v-if="listingType === 'special'">
        <ShopProductRowCard
          v-for="(listing, index) in listings"
          :product="listing"
          @reload="dismissModal"
          class="mb-2"
        />
      </div>
      <div class="position-relative p-1" v-else>
        <StoreOffersSection
          inModal
          :products="listings"
          :white-card-color="true"
          :amountSection="true"
          :ringShopBtn="true"
        />
      </div>
    </ion-content>
  </client-only>
</template>

<script lang="ts" setup>
import { modalController } from '@ionic/vue';
import { shopStore } from '@/shared/pinia-store/shop';
import StoreOffersSection from '@/shared/pages/store/StoreOffersSection.vue';
import ShopProductRowCard from '@/shared/components/Store/ShopProductRowCard.vue';
const { fetchGemPackageListings } = shopStore();
const props = defineProps({
  listingType: {
    type: String,
    default: '',
  },
});
const emits = defineEmits(['fetchGemListings']);
const listingType = toRef(props, 'listingType');
const listings = computed(() => {
  const { gemPackages } = shopStore();
  if (listingType.value === 'special') return gemPackages.value?.special as any;
  return gemPackages.value?.bundles as any;
});
onMounted(async () => {
  const { gemPackages } = shopStore();
  if (!gemPackages.value?.length) fetchGemPackageListings();
});
function dismissModal() {
  modalController.dismiss(null, 'cancel');
}
const title = computed(() => {
  if (listingType.value === 'special') return 'Special Bundles';
  return 'Gem Packages';
});
</script>

<style scoped lang="sass">
.title
  font-size: 1.25rem
  font-weight: 500
  margin-bottom: 1rem
</style>
