<template>
  <ion-modal
    class="modal-with-layout"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="h-100 px-2" style="overflow: auto">
        <form @submit.prevent="submitProfile">
          <div class="mb-3"><i> Please fill out the below profile to get started!</i></div>

          <div class="mb-2"><strong>What length messages are ideal for you?</strong></div>
          <div class="mb-3">
            <ion-select v-model="profile.ideal_message_len" mode="md" placeholder="Select appropriate option">
              <ion-select-option value="long">Long</ion-select-option>
              <ion-select-option value="short">Short</ion-select-option>
              <!-- <ion-select-option value="any">Don't Care</ion-select-option> -->
            </ion-select>
          </div>

          <div class="mb-2"><strong>Favorite types:</strong></div>
          <div class="mb-3">
            <ion-select v-model="profile.fav_types" mode="md" placeholder="Select appropriate options" :multiple="true">
              <ion-select-option value="angst">Angst</ion-select-option>
              <ion-select-option value="action">Action</ion-select-option>
              <ion-select-option value="combat">Combat</ion-select-option>
              <ion-select-option value="hurt_comfort">Hurt/Comfort</ion-select-option>
              <ion-select-option value="romance">Romance</ion-select-option>
              <ion-select-option value="chat_texting">Chat/Texting</ion-select-option>
              <ion-select-option value="adventure">Adventure</ion-select-option>
            </ion-select>
          </div>

          <div class="mb-2"><strong>What's important to you in a roleplay chat?</strong></div>
          <ion-textarea
            v-model="profile.looking_for"
            rows="3"
            class="c-textarea mb-3"
            placeholder="Share the things you are looking for in roleplay"
          />

          <div class="mb-2">
            <strong>Post a roleplay chat sample here! It lets others see what roleplaying with you is like.</strong>
          </div>
          <ion-textarea
            v-model="profile.sample_text"
            rows="3"
            class="c-textarea mb-3"
            placeholder="Write a sample message here"
          />

          <div class="w-100 d-flex justify-content-center">
            <ion-button class="submit-btn" :disabled="isSubmitting || !isFormComplete" type="submit">
              <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
              <span v-else>{{ actionName }}</span>
            </ion-button>
          </div>
        </form>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { createRolechatProfile, getRolechatProfile, updateRolechatProfile } from '@/shared/actions/rolechats';
import { toast } from '@/shared/native';

@Options({
  name: 'RolechatProfileFormModal',
})
export default class RolechatProfileFormModal extends Vue {
  @Prop({ default: () => 'Roleplay Profile', required: false }) modalTitle!: string;
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({ default: false }) isEdit!: string;

  @Watch('isOpen')
  async editRolechatProfile() {
    if (this.isOpen && this.isEdit) {
      const response = await await getRolechatProfile();
      this.profile = response;
      this.profileId = response.id;
    }
  }

  isSubmitting = false;
  profile = {
    ideal_message_len: '',
    fav_types: [],
    looking_for: '',
    sample_text: '',
  };

  profileId = '';

  public get isFormComplete() {
    return (
      !!this.profile.ideal_message_len &&
      !!this.profile.looking_for &&
      !!this.profile.sample_text &&
      !isEmpty(this.profile.fav_types)
    );
  }

  public get actionName() {
    return this.isEdit ? 'Save' : 'Submit';
  }

  public dismissModal() {
    this.$emit('dismissModal');
  }

  public async submitProfile() {
    if (!this.isFormComplete) return;

    this.isSubmitting = true;

    try {
      if (!this.isEdit) {
        await createRolechatProfile(this.profile);
        await toast.show('Roleplay profile created successfully.', 'nonative', 'success');
      } else {
        await updateRolechatProfile(this.profileId, this.profile);
        await toast.show('Roleplay profile updated successfully.', 'nonative', 'success');
      }
    } catch (_err) {
      await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
    }

    this.$emit('saveDismiss');
    this.isSubmitting = false;
  }
}
</script>

<style lang="sass" scoped>
.submit-btn
  width: 180px
  .spinner
    width: 15px
    height: 15px
</style>
