<template>
  <ion-modal
    class="modal-big"
    css-class=""
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="inner-content p-2">
      <ChLoading size="lg" class="mx-auto p-3" v-if="loading" />
      <div v-else>
        <div class="d-flex align-items-center justify-content-center text-center">
          <ClaimedBoxes :claimedBoxes="claimedBoxes" :is-modal="true" />
        </div>
        <div class="hint mt-2">
          <div class="px-2 hint-pt">Hint:</div>
          <div class="p-2 text-black">
            Boxes like to hide in other people's
            <span style="font-weight: bold">character profiles and reactions.</span>
          </div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex align-items-center justify-content-center">
          <ion-button @click="closeModal" class="thanks-btn">Thanks!</ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import constants from '@/shared/statics/constants';
import ClaimedBoxes from '@/shared/pages/store/ClaimedBoxes.vue';
import { getUsersClaimedBoxes } from '@/shared/actions/currency';
import { mainStore } from '@/shared/pinia-store/main';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  boxes: {
    type: Object,
    default: {},
  },
});

const isOpen = toRef(props, 'isOpen');
const boxes = toRef(props, 'boxes');
const claimedBoxes = ref({});
const loading = ref(true);
const { dark: isDark } = mainStore();
const fetchUserClaimedBoxes = async () => {
  const resp = await getUsersClaimedBoxes();
  claimedBoxes.value = resp;
  loading.value = false;
};

const hintBg = computed(() => {
  const defaultClr = isDark.value ? '#010123' : '#fff';
  return defaultClr;
});
watch(isOpen, () => {
  if (isOpen.value && isEmpty(boxes.value)) {
    fetchUserClaimedBoxes();
  } else {
    claimedBoxes.value = boxes.value;
    loading.value = false;
  }
});

const emits = defineEmits(['close', 'dismissModal']);

const dismissModal = (val: any) => {
  emits('dismissModal', val);
};

const closeModal = () => {
  emits('close');
};
</script>

<style lang="sass" scoped>
.dark .thanks-btn::part(native)
  color: white
.hint-pt
  background: linear-gradient(90deg, rgba(255, 49, 222, 0.7) 0%, rgba(247, 6, 207, 0) 100%),linear-gradient(0deg, #4155c9, #4155c9)
  border-top-left-radius: 8px
  border-top-right-radius: 8px
  color: white
.hint
  border: 2px solid transparent
  border-radius: 10px
  background: linear-gradient(to right, v-bind(hintBg),  v-bind(hintBg)), linear-gradient(90deg, rgb(195 61 216) 0%, rgb(155 69 212) 51%), linear-gradient(96deg, #4155C9, #4155C9)

  background-clip: padding-box, border-box
  background-origin: padding-box, border-box
.dark .toolbar
  --background: #17074c !important

.title
  font-weight: bold
  font-size: 22px

.modal-big
  --width: 400px
  --height: auto
  --border-radius: 9px

.inner-content
  background: var(--ion-background-color, #fff)
  overflow: hidden
  height: 100%
  @media(max-height:525px)
   height: 100%
</style>
