<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="inner-content d-flex flex-column px-4 py-3">
      <div class="d-flex justify-content-between">
        <div class="title">Submitted</div>
        <ion-buttons>
          <ion-button class="close-button text-black" @click="dismissModal">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </div>

      <ion-card class="fan-work-card mx-0 p-3">
        <div class="d-flex flex-column">
          <div class="title text-white text-center">Your FanWork has been sucessfully submitted!</div>

          <span class="text-center text text-white mt-1">FanWork makes the creative world go around!</span>
          <span class="text-center text text-white">Look how happy you just made Hubert!</span>
        </div>
      </ion-card>

      <div class="d-flex justify-content-center">
        <img loading="lazy" :src="yayAnimation" class="img" />
      </div>

      <div class="w-100 d-flex justify-content-center">
        <ion-button class="submit-btn" @click="dismissModal"> Close </ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Getter } from 's-vuex-class';
import namespace from '@/shared/store/namespace';

import { mainStore } from '@/shared/pinia-store/main';

@Options({
  name: 'SubmittedFanWorkModal',
})
export default class SubmittedFanWorkModall extends Vue {
  @Prop({ default: false }) isOpen!: boolean;

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  public get dark() {
    const { dark } = mainStore();
    return dark.value;
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 420;
  }

  public dismissModal() {
    this.$emit('dismissModal');
  }

  public get yayAnimation() {
    return this.dark ? '/animation_darkmode.gif' : '/animation.gif';
  }
}
</script>

<style lang="sass" scoped>
.close-button
  width: 50px
.submit-btn
  width: 100px
.img
  max-width: 200px
  max-height: auto
.fan-work-card
  overflow: unset !important
  background: var(--ion-color-primary)

  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important

.title
  font-size: 22px
  font-weight: bold
.text
  font-size: 18px

.modal-big
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 80vh
  overflow: auto
</style>
