<template>
  <ion-footer translucent>
    <ion-toolbar class="toolbar">
      <slot />
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts" setup></script>
<style lang="sass" scoped>
.dark .toolbar
  --background: #17074c !important
</style>
