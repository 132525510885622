<template>
  <div
    class="position-relative"
    :class="selectedImg ? 'disabled-img' : 'clickable-item-hov'"
    :style="displayOrderCount ? 'height:120px;width:151px' : ''"
  >
    <div class="selected-cxt" v-if="selectedImg && !displayOrderCount">Selected</div>
    <div class="count-cxt" v-if="displayOrderCount">
      <span class="d-flex justify-content-center align-items-center" style="font-size: 14px; font-weight: bold">{{
        count + 1
      }}</span>
    </div>
    <ion-button
      v-else
      color="transparent"
      class="float-circle-btn clickable-item-hov"
      @click.prevent.stop="emits('delete', image)"
    >
      <ion-icon :icon="closeCircleOutline" class="close-icon" />
    </ion-button>
    <img class="img" loading="lazy" :src="image.url" />
  </div>
</template>

<script lang="ts" setup>
import { closeCircleOutline } from 'ionicons/icons';
const props = defineProps({
  image: {
    type: Object,
    default: {},
  },
  selectedImg: {
    type: Boolean,
    default: false,
  },
  displayOrderCount: {
    type: Boolean,
    default: false,
  },
  count: {
    type: Number,
    default: 0,
  },
});
const emits = defineEmits(['delete']);
</script>

<style lang="sass" scoped>
.count-cxt
  border-radius: 25px
  height: 22px
  width: 22px
  background: #00B4C5
  color: white
  position: absolute
  top: 5px
  border: 2px solid white
  right: 3px
.disabled-img
  opacity: 0.6 !important
.selected-cxt
  border-radius: 20px
  height: 20px
  background: #00B4C5
  color: white
  position: absolute
  top: 5px
  padding: 0px 8px 0px 8px
  font-size: 14px
  right: 3px
.close-icon
  height: 22px
  width: 22px
.float-circle-btn
  background-color: rgba(0,0,0,0.5)
  --border-radius: 100% !important
  border-radius: 100% !important
  width: 25px
  height: 25px
  --padding: 0
  --padding-start: 0
  --padding-end: 0
  top: 0px
  left: 3px
  position: absolute
  z-index: 1
.img
  width: 100%
  height: 100%
  object-fit: cover
</style>
