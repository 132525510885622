<template>
  <ion-header translucent>
    <ion-toolbar class="toolbar">
      <div class="d-flex justify-content-between align-items-center px-3 py-2">
        <div class="clickable-item-hov" @click="dismissModal">
          <i class="ti-arrow-left icon" v-if="showGoBackButton" />
        </div>
        <div>
          <div class="title">{{ modalTitle }}</div>
        </div>
        <div>
          <ion-buttons slot="end" v-if="showCloseButton">
            <ion-button @click="dismissModal">
              <i class="ti-close icon" />
            </ion-button>
          </ion-buttons>
        </div>
      </div>
    </ion-toolbar>
  </ion-header>
</template>

<script lang="ts" setup>
defineProps({
  modalTitle: {
    type: String,
    default: '',
  },
  showGoBackButton: {
    type: Boolean,
    default: false,
  },
  showCloseButton: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['close']);

const dismissModal = () => {
  emits('close');
};
</script>
<style lang="sass" scoped>
.icon
  font-weight: bold
.dark .toolbar
  --background: #17074c !important
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
</style>
