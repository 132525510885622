<template>
  <div class="ml-5">
    <div class="d-flex w-100">
      <ProfileRing
        :image="
          isNull(message.reply_as_char)
            ? get(message, 'user.customize_profile.profile_ring.image')
            : get(message.reply_as_char, 'profile_ring.image')
        "
        :borderWidth="50"
        :ringTop="-5"
        :ringLeft="18"
        class="position-absolute"
      />
      <img
        loading="lazy"
        v-image
        class="img ml-4"
        :src="
          isNull(message.reply_as_char)
            ? get(message, 'user.profile_picture_url', '/empty.png')
            : get(message.reply_as_char, 'info.cropProfilePicture', '/empty.png')
        "
      />

      <div class="ml-2 flex-grow-1">
        <div class="d-flex flex-column msg-content" :class="{ 'bg-white': $route.name === 'post-details' }">
          <div class="d-flex justify-content-between align-items-center flex-wrap">
            <div class="d-flex flex-column">
              <router-link
                v-if="!message.reply_as_char"
                class="msg-font"
                :to="{ name: 'profile', params: { username: get(message, 'user.username') } }"
                @click.stop
                >{{ get(message, 'user.username') }}
              </router-link>

              <router-link
                v-else
                class="msg-font"
                :to="{ name: 'character-profile-new', params: { slug: message.reply_as_char.slug } }"
                @click.stop
                >{{ get(message.reply_as_char, 'info.name') }}
              </router-link>

              <small class="timer">{{ formatTimeAgo(message.created) }}</small>
              <!-- <small v-if="message.location" class="mx-2">
                <i class="ti-location-pin icon" /> {{ message.location }}</small
              > -->
            </div>
          </div>

          <div v-if="message.title && !isEditing">{{ message.title }}</div>
          <div v-if="!isEditing" v-html="sanitizeHtml(strippedMessage)" />

          <post-blab
            v-if="isEditing"
            :blab-editing-text="message.text"
            :blab-editing-title="message.title"
            :parent="message.id"
            :is-edit="true"
            @cancelled="onCancel"
            @send="onEditComplete"
          ></post-blab>
        </div>

        <div v-if="!isEditing" class="mt-1 d-flex justify-content-between">
          <div class="actions d-flex" style="margin-left: -5px">
            <a href="#" @click.prevent>
              <Reaction
                type="post"
                :reactions="reactions"
                :user-reaction="userReaction"
                @changed="(reaction) => reactionChanged(reaction.key, message, reaction.isInstant)"
              >
                <ion-button color="transparent" class="inline-button icon-button clickable">
                  <div class="d-flex align-items-center justify-content-center">
                    <i
                      :color="!!userReaction && userReaction.reaction ? 'secondary' : 'primary'"
                      class="ti-thumb-up mr-2"
                    />
                    <span
                      class="reaction-count"
                      :class="!!userReaction && userReaction.reaction ? 'text-secondary' : 'text-primary'"
                      >{{ reactions.total_count || 0 }}</span
                    >
                  </div>
                </ion-button>
              </Reaction>
            </a>
            <a href="#" class="ml-2 d-flex align-items-center" @click.prevent="postReply">
              <i class="ti-comment" /><span v-if="!isMobSmallScreen" class="ml-1">Reply</span>
            </a>
            <a
              v-if="isAuthenticated && isMessageOwner(message)"
              href="#"
              class="ml-2 d-flex align-items-center"
              @click.prevent="toggleEdit"
            >
              <i class="ti-pencil-alt mr-1" /><span v-if="!isMobSmallScreen">Edit</span>
            </a>
            <a
              v-if="isAuthenticated && isMessageOwner(message)"
              href="#"
              class="ml-2 d-flex align-items-center text-danger"
              @click.prevent="deleteReply(message.id)"
            >
              <i class="ti-trash mr-1" /><span v-if="!isMobSmallScreen">Delete</span>
            </a>

            <a href="#" class="ml-3 d-flex align-items-center" v-if="!isMessageOwner(message)">
              <i @click.stop="dropdownToggle($event, message)" class="ti-more-alt menu-icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Getter } from 's-vuex-class';
import { Prop } from 'vue-property-decorator';
import { alertController } from '@ionic/vue';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import constants from '@/shared/statics/constants';
import Report from '@/shared/components/Report.vue';
import { sanitizeHtml } from '@/shared/utils/html';
import namespace from '@/shared/store/namespace';
import { stripHtmlTags } from '@/shared/utils/string';
import PostBlab from '@/shared/pages/blabs/components/PostBlab.vue';
import { blabDeleteSunShineReply, blabSunShineReply, deleteBlabReply } from '@/shared/actions/blabs';
import { Blab } from '@/shared/types/static-types';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import store from '@/shared/store';
import { popovers } from '@/shared/native/popovers';
import Reaction from '@/shared/components/Reaction/index.vue';
import ProfileRing from '@/shared/components/ProfileRing.vue';
import PostPopover from './PostPopover.vue';

@Options({
  name: 'BlabReplyMessage',
  components: { PostBlab, Reaction, Report, ProfileRing },
})
export default class BlabReplyMessage extends Vue {
  @Prop({ default: () => {} }) message!: any;
  @Prop({ default: () => {} }) blab!: any;
  @Prop({ default: false }) reply!: boolean;
  @Prop({ default: () => {} }) repliesCount!: any;

  sanitizeHtml = sanitizeHtml;

  isEmpty = isEmpty;
  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  @Getter('inActionBlab', { namespace: namespace.BlabsModule })
  public inActionBlab!: Blab;

  public isEditing = false;
  public isReply = false;
  public isopenReplies = false;

  hover = false;
  isSunShine = false;
  stripHtmlTags = stripHtmlTags;

  formatTimeAgo = formatTimeAgo;
  get = get;
  commentedAsTypes = constants.commentedAsTypes;

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 420;
  }

  public isMessageOwner(message: any) {
    try {
      return message.user?.id === this.user.id;
    } catch (error) {
      return false;
    }
  }

  public get isBlabOwner() {
    try {
      return this.inActionBlab.user!.username === this.user.username;
    } catch (error) {
      return false;
    }
  }

  public async sunshining(id: string) {
    this.isSunShine = true;

    const res = await blabSunShineReply(this.blab, id);
    this.$emit('shine');
  }

  public onCancel() {
    this.isEditing = false;
  }

  public async notsunshining(id: string) {
    const res = await blabDeleteSunShineReply(id, this.blab);
    this.isSunShine = false;
    this.$emit('unshine');
  }

  public async openReplies(id: string) {
    this.$emit('replies', id);
  }

  public async reactionChanged(reaction: string, msg: any, isInstant = false) {
    const reactionResp = await store.dispatch('BlabsModule/reactBlabReply', { reaction, blab: msg, isInstant });
    this.$emit('afterReaction', reactionResp);
  }

  public get userReaction() {
    if (this.message.user_reaction) {
      return this.message.user_reaction;
    }
  }

  public get reactions() {
    const { reaction_counts } = this.message || {};
    return reaction_counts;
  }

  public get strippedMessage() {
    return stripHtmlTags(this.message.text).replace(/(?:\r\n|\r|\n)/g, '<br />');
  }

  public async postReply() {
    this.$emit('nestedReply');
  }

  public dropdownToggle = async (ev: CustomEvent, message: any) => {
    const popover = await popovers.open(ev, PostPopover, {
      reportEntityId: message?.id,
      reportEntityType: 'blabreply',
      reportedUser: get(message, 'user'),
    });
    try {
      await popover.onDidDismiss();
    } catch (e) {}
  };

  public toggleEdit() {
    this.isEditing = !this.isEditing;
  }

  public onEditComplete(payload: any) {
    this.isEditing = false;
    this.message.text = payload.text;
    this.message.title = payload.title;
  }

  public async deleteReply(id: string) {
    try {
      const alert = await alertController.create({
        cssClass: '',
        header: 'Are you sure?',
        message: `Please confirm that you want to delete this reply.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'text-secondary',
            id: 'cancel-button',
          },
          {
            text: 'Confirm',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'text-danger',
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === 'ok') {
        await deleteBlabReply(id);
        await toast.show('Reply deleted', 'nonative', 'success');
        this.isReply = false;
        this.$emit('deleted', id);
        this.$emit('count');
      }
    } catch (e) {}
  }
}
</script>

<style lang="sass" scoped>
.timer
  position: relative
  top: -5px
  color: #A7AABE
.msg-font
  color: #ae38e5
  font-weight: bold

.dark .msg-font
  color: white

.blab-drag-icon
  font-size: 24px

.img
  width: 40px
  height: 40px
  border-radius: 20px
  border: solid gray 0.1px
  object-fit: cover

.msg-content
  border-radius: 15px
  //background-color: #F3F3F3

.dark .msg-content
  //background-color: #767676 !important
</style>
