import { loadStripe } from '@stripe/stripe-js';
import { 
  getProducts as fetchProducts, 
  initiateSubscriptionCheckout as callInitiateSubscriptionCheckout,
  confirmSubscriptionCheckout as callConfirmSubscriptionCheckout,
  initiateItemCheckout as callInitiateItemCheckout,
  confirmItemCheckout as callConfirmItemCheckout,
  modifySubscription as callModifySubscription,
  preModifySubscription as callPreModifySubscription,
  checkForPendingChange as callCheckForPendingChange,
  cancelPendingChange as callCancelPendingChange
} from '@/shared/actions/payments';

class StripeSingleton {
  public static productNames: string[] = ['CharacterHub Plus', 'CharacterHub Plus Ultra'];
  public static subscribeReturnUrl: string = `https://characterhub.com/upgrade-inter`;
  public static purchaseReturnUrl: string = `https://characterhub.com/checkout-inter`;

  private static instance: StripeSingleton;
  private apiKey: string;
  private prices: any;
  private stripe: any;

  private constructor(key: string) {
    this.apiKey = key;
  }
  
  public getSubscriptionReturnUrl() {
    const { hostUrl } = useSafeEnv();
    return `${hostUrl.value}/upgrade-inter`;
  }

  public getPurchaseReturnUrl() {
    const { hostUrl } = useSafeEnv();
    return `${hostUrl.value}/checkout-inter`;
  }

  public async load() {
    this.stripe = await loadStripe(this.apiKey);
  }

  public static getInstance(key: string): StripeSingleton {
    if (!StripeSingleton.instance) {
      if (!key) throw new Error('StripeSingleton must be initialized with an API key');
      StripeSingleton.instance = new StripeSingleton(key);
    }
    return StripeSingleton.instance;
  }

  public getStripe(): any {
    return this.stripe;
  }

  public async loadProductsAndPrices(): Promise<void> {
    try {
      this.prices = await fetchProducts();
      return this.prices;
    } catch (error) {
      console.error('Error loading products and prices:', error);
    }
  }

  public getPrices(): any {
    return this.prices;
  }

  public async preModifySubscription(product: string, intervalSelected: any) {
    return await callPreModifySubscription(product, intervalSelected);
  }

  public async modifySubscription(product: string, intervalSelected: any) {
    return await callModifySubscription(product, intervalSelected);
  }

  public async initiateSubscriptionCheckout(priceId: string) {
    return await callInitiateSubscriptionCheckout(priceId);
  }

  public async confirmSubscriptionCheckout(setupIntentId: string, priceId: string) {
    return await callConfirmSubscriptionCheckout(setupIntentId, priceId);
  }

  public async initiateItemCheckout(item: string, quantity: number, currentUrl: string) {
    return await callInitiateItemCheckout(item, quantity, currentUrl);
  }

  public async confirmItemCheckout(paymentIntentId: string) {
    return await callConfirmItemCheckout(paymentIntentId);
  }

  public async checkForPendingChange() {
    return await callCheckForPendingChange();
  }

  public async cancelPendingChange() {
    return await callCancelPendingChange();
  }
}

export default StripeSingleton;
