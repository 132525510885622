<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3">
          <div class="title">Buy {{ item.title }} {{ get(item, 'item_group.title') }}</div>
          <div />
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content">
      <div class="px-3 py-2 mt-1">
        <ChLoading size="lg" class="mx-auto" v-if="loading" />
        <div v-else class="d-flex text-black justify-content-center mx-auto align-items-center text-center flex-grow-1">
          <VirtualItemsDisplay :item="item" />
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between text-center align-items-center p-2">
          <div>
            <ion-button
              color="transparent"
              class="purchase-btn"
              @click="openConfirmPurchaseModal"
              :class="{ hide: item.is_bought }"
            >
              <ChLoading size="sm" v-if="loading" class="spinner" />
              <div v-else class="text-black d-flex align-items-center">
                Buy for
                <div class="mx-1 token">
                  <CurrencyImage :image="'/char.jpg'" :type="get(item, 'currency_type')" :img-width="20" />
                  <inline-svg :src="imageFromItemsCurrency" class="svg" />
                </div>

                <strong>{{ item.amount }}</strong>
              </div>
            </ion-button>
          </div>
          <ion-button class="gift-btn" @click="openGiftModal">
            <ChLoading size="sm" v-if="loading" class="spinner" />
            <div v-else class="d-flex align-items-center">
              <ion-icon class="mr-1" style="font-size: 18px !important; color: white" :icon="giftOutline" />
              <span style="color: white">Send as Gift</span>
            </div>
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import { purchaseVirtualItem } from '@/shared/actions/virtualItems';
import VirtualItemsDisplay from '@/shared/pages/store/VirtualItemsDisplay.vue';
import CurrencyImage from '@/shared/components/CurrencyImage.vue';
import { giftOutline } from 'ionicons/icons';
import { useVirtualItemCurrencyImage } from '~/composables/useVirtualItemCurrencyImage';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  item: {
    type: Object,
    default: {},
  },
});
const emits = defineEmits(['close', 'gift', 'purchase']);
const isOpen = toRef(props, 'isOpen');
const item = toRef(props, 'item');
const loading = ref(false);
const { imageFromItemsCurrency } = useVirtualItemCurrencyImage(item.value);
const dismissModal = () => {
  emits('close');
};

const openConfirmPurchaseModal = () => {
  emits('purchase');
};

const openGiftModal = () => {
  emits('gift');
};

watch(isOpen, () => {
  if (isOpen.value) {
    loading.value = false;
  }
});
</script>
<style lang="sass" scoped>
.hide
  display: none !important
.token
  height: 20px
.svg
  width: 20px
  height: 20px
.ring-title
  font-weight: bold
  font-size: 18px
.purchase-btn
  border: 2px solid #e6e6e6
  border-radius: 20px
  color: #214163
.gift-btn
  color: white
  --border-radius: 20px
  --background: #00B4C5

.image
  width: 105px
  height: 105px
  min-width: 105px
  img
    width: 100%
    height: 100%
    object-fit: cover
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: #fff
  max-height: 60vh
  max-height: 60dvh

.modal-big
  --width: 450px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.sub-title
  font-size: 18px
  font-weight: bold
.title
  font-size: 18px
  color: #214163
  font-weight: 500
</style>
