<template>
  <div class="set-bg-color py-1" style="background: #f3f3f3 !important">
    <div class="character-profile-header d-flex flex-column mt-2 char-box">
      <div class="cover" style="background: #ae38e5 !important"></div>
      <div class="rest" style="background: #e6e6e6 !important">
        <div class="name d-flex justify-content-center" style="color: black !important">
          <div class="change-font">{{ get(customizeCharacter, 'entity.info.name') }}</div>
        </div>
        <div class="quote" style="color: black !important">
          {{ truncateText(stripHtmlTags(get(customizeCharacter, 'entity.info.quote'))) }}
        </div>

        <div class="button-container px-3 pb-2">
          <div class="button-row">
            <ion-button class="small-button w-100" style="--background: #ae38e5 !important" />
            <ion-button class="small-button rolechat" style="--background: #ae38e5 !important" />
            <ion-button class="w-100 small-button inside-reaction" style="--background: #ae38e5 !important" />
          </div>
        </div>
      </div>
    </div>

    <div class="info-app-section py-1 mt-2" style="background: #e6e6e6 !important">
      <div class="px-2 py-2">
        <div class="info-title" style="color: black !important">title</div>
        <div class="info-des mt-1" style="color: black !important">default font</div>
      </div>
    </div>
  </div>
  <div
    v-if="(!isEmpty(template) && !isMobSmallScreen && !isNewCustomizationTemplate) || showTempName"
    class="px-1 mt-2"
    style="margin-bottom: 58px"
  >
    <div class="text-black"><strong>Template Name:</strong></div>
    <div style="color: #a7aabe !important">{{ showTempName ? 'No Template' : 'Unnamed' }}</div>
  </div>
</template>
<script lang="ts" setup>
import { truncateText } from '@/shared/utils/string';
import { stripHtmlTags } from '@/shared/utils/string';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
const {
  template,

  customizeCharacter,
} = characterStylizeStore();
const { width } = useWindowSize();
const props = defineProps({
  isNewCustomizationTemplate: {
    type: Boolean,

    default: false,
  },
  showTempName: {
    type: Boolean,
    default: false,
  },
});
const isMobSmallScreen = computed(() => {
  return width.value <= 800;
});
</script>
<style lang="sass" scoped>
.info-app-section
  max-width: 300px
  min-width: 300px
  margin: 0 auto
  border-radius: 10px
  @media(max-width:800px)
    max-width: 95px
    min-width: 95px
.char-box
  max-width: 300px
  min-width: 300px
  margin: 0 auto
  @media(max-width:800px)
    max-width: 95px !important
    min-width: 95px !important
.set-bg-color
  border-radius: 10px
  @media(min-width:301px)
    padding-bottom: 8px !important
  @media(max-width:300px)
    height: 117px !important

.button-container
  margin: 0 auto
  display: flex
  flex-direction: column
  align-items: center
  max-width: 200px
  pointer-events: none
  ::v-deep
    ion-button
      --ion-color-secondary: #328FD2
      --ion-color-secondary-rgb: 80,200,255
      --ion-color-secondary-contrast: #ffffff
      --ion-color-secondary-contrast-rgb: 255,255,255
      --ion-color-secondary-shade: #2d81bd
      --ion-color-secondary-tint: #479ad7
.wide-button
  width: 82%
  height: 14px
  color: #fff
  border: none
  border-radius: 8px
  margin-bottom: 15px
  text-transform: unset
  font-size: 18px
  --border-radius: 8px
  @media(max-width:1000px)
    height: 11px
.button-row
  display: flex
  justify-content: space-between
  width: 100%
  &:first-child
    margin-left: -2px !important
.button-row-2
  display: flex
  justify-content: space-between
  width: 100%
  gap: 0
.small-button
  display: inline-block !important
  width: 33%
  height: 11px
  color: #fff
  font-size: 16px
  border: none
  border-radius: 8px
  --border-radius: 8px
  @media(max-width:1000px)
    height: 11px

@media(max-width: 800px)
  .character-profile-header
    .cover
      height: 15px !important
    .name
      font-size: 16px !important

.character-profile-header
  color: black
  border-radius: 20px
  overflow: hidden
  .cover
    height: 39px
    overflow: hidden
  .rest
    text-align: center


  .quote
    margin-top: -7px
    font-size: 14px

  .name
    font-size: 18px
    margin: 9px !important
</style>
