export interface Tab {
  name: string;
  value: string;
  type?: any;
  icon?: any;
}

export interface ReactionWorldCounts {
  incredible?: number;
  cool?: number;
  jawdrop?: number;
  creative?: number;
  mind?: number;
  see?: number;
  love?: number;
  spot?: number;
  vibes?: number;
  pet?: number;
  wifi?: number;
  photo?: number;
  sofun?: number;
  total_count: number;
  ordering?: string[];
}

export interface ReactionCommentCounts {
  love?: number;
  total_count: number;
  ordering?: string[];
}

export interface ReactionImageCounts {
  amart?: number;
  lovys?: number;
  scute?: number;
  outuc?: number;
  crili?: number;
  artbr?: number;
  workg?: number;
  frea?: number;
  maspi?: number;
  sotal?: number;
  excep?: number;
  inspi?: number;
  flaex?: number;
  beywo?: number;
  trulu?: number;
  incde?: number;
  total_count: number;
  ordering?: string[];
}
export interface Reactions {
  myfav?: number;
  obses?: number;
  socoo?: number;
  relat?: number;
  vibe?: number;
  hero?: number;
  more?: number;
  love?: number;
  cute?: number;
  ba?: number;
  gorg?: number;
  spcls?: number;
  chils?: number;
  icart?: number;
  wellw?: number;
  sofun?: number;
}

export interface User {
  username: string;
  id: string;
  is_nsfw: boolean;
  is_staff?: boolean;
  show_nsfw: boolean;
  mp_agreement: boolean;
  bomb_first_time_use: boolean;
  date_joined?: any;
  profile_picture_url?: string;
  subscription_duration?: string;
  subscription_level?: string;
  customize_profile?: any;
  st?: string;
  is_banned?: boolean;
  banned_until?: any;
  restrictions?: any;
  pro?: any;
}

export interface Reaction {
  userId: string;
}

export interface Colors {
  hex_code: string;
  label: string;
}
export interface UserReaction {
  id: string;
  reaction: string;
}

export interface ReactionCounts {
  myfav?: number;
  obses?: number;
  socoo?: number;
  relat?: number;
  vibe?: number;
  hero?: number;
  more?: number;
  love?: number;
  cute?: number;
  ba?: number;
  gorg?: number;
  spcls?: number;
  chils?: number;
  icart?: number;
  wellw?: number;
  sofun?: number;
  total_count: number;
  ordering?: string[];
}

export interface Follow {
  userId: string;
}

export interface World {
  name: string;
  id: string;
  slug: string;
  type?: string;
  modified?: string;
}

export interface GeneralInfo {
  [key: string]: string;
}

export interface MarketplaceListing {
  title: string;
  base_price?: number;
  description?: string;
  usage_term?: string;
  visuals?: string[];
  category?: string;
  is_nsfw?: boolean;
  is_active?: boolean;
  id?: string;
  slug?: string;
  tags: string[];
  seller?: User;
  currency_type?: string;
}
export interface FanWork {
  submitted_for_id: string;
  submitted_for_type: string;
  receiver: string;
  type: string;
  image?: string;
  note: string;
  other_title: string;
  other_content: string;
}

export type Image = {
  id?: string;
  created?: string;
  username?: string;
  description?: string;
  character_name?: string; // only provided for images featured in SEO pillar pages
  source?: string;
  source_user?: any;
  image?: string;
  reduced_url?: string;
  med_url?: string;
  info?: any;
  user?: string;
  is_watermarked?: boolean;
  is_spoiled?: boolean;
  spoiled_text?: string;
  spoiled_images?: any;
  type?: string;
  reaction_counts?: ReactionImageCounts;
  user_reaction?: UserReaction;
  public_location?: any;
  visual_url?: string;
  image_type?: string;
  image_size?: number;
};

export type ImageCollection = {
  id?: string;
  id_?: string;
  created?: string;
  user?: string;
  name?: string;
  description?: string;
  parent_collection?: ImageCollection;
  images_page?: {
    results: Image[];
    next?: string;
    count?: number;
  };
  images?: string[];
  abbreviation?: string;
};

export interface Blab {
  title?: string;
  description: string;
  reaction_counts?: ReactionCounts;
  location?: string;
  category?: string;
  id: string;
  character?: any;
  is_nsfw?: boolean;
  user?: User;
  user_reaction?: UserReaction;
  created?: any;
  visuals: string[];
  tags: string[];
  type?: string;
  featured_characters: any[];
  privacy: string;
}

export interface BlabReply {
  blab?: string;
  text?: string;
  reaction_counts?: ReactionCounts;
  title?: string;
  id: string;
  character?: string;
  is_edited?: boolean;
  user?: User;
  user_reaction?: UserReaction;
  created?: any;
  parent_reply?: string;
  reply_as_char?: string;
}

export interface BlabSunShineReply {
  blab?: string;
  order_num?: string;
  reply?: BlabReply[];
}

export interface SocialSpace {
  id?: string;
  modified?: string;
  name: string;
  quote: string;
  slug?: string;
  reaction_counts?: ReactionWorldCounts;
  user_reaction?: UserReaction;
  user?: User;
  cover_image: string;
  description: string;
  rooms: string[];
  is_nsfw: boolean;
  visuals?: string[];
  visual_collections?: string[];
  visual_collections_page?: { results?: ImageCollection[]; next?: string; count?: number };
  tags: string[];
  collections: ImageCollection[];
  type?: string;
  privacy?: string;
  character_worlds?: string[];
  warningMessage: string;
  extra: { quote: string; [key: string]: any };
  [key: string]: any;
}

export interface WorldChatDetails {
  banner_img_url?: string;
  description?: string;
  id?: string;
  privacy?: string;
  title?: string;
  world?: any;
  extra?: any;
  order?: number;
}

type UserReg = User;

export interface Registration {
  user: UserReg;
}

export interface PhysicalCharacteristics {
  [key: string]: string;
}

export type Visual = {
  url?: string;
  caption?: string;
  source?: string;
  id?: string;
};

export interface CharacterInfo {
  name: string;
  quote: string;
  cover?: string;
  profilePicture?: string;
  cropProfilePicture: string;
  following?: Follow[];
  description?: string;
  world?: World;
  generalInfo?: GeneralInfo;
  physicalCharacteristics?: PhysicalCharacteristics;
  likes?: string[];
  dislikes?: string[];
  // eslint-disable-next-line no-use-before-define
  relationshipsSection?: Relationship[];
  status?: string;
  spotifyLink?: string[];
  backstory?: string;
  creatorsNote?: string;
  visuals?: Visual[];
  colors: Colors[];
  about: { [index: string]: any };
  aboutConfig: { [index: string]: any };
  stats: { [index: string]: any };
  statsConfig: { [index: string]: any };
  featuredIn: { type: string; title: string; link: string }[];
  warningMessage: string;
  moodboard?: Visual[];
  published_at?: string;
  privacy?: string;
  collections?: number;
  [key: string]: any;
}

export interface Character {
  id?: string;
  info: CharacterInfo;
  author?: User;
  modified?: string;
  reaction_counts?: ReactionCounts;
  is_public: boolean;
  user_reaction?: UserReaction;
  slug?: string;
  allow_roleplay_req: boolean;
  allow_comp_bomb: boolean;
  is_nsfw: boolean;
  worlds?: string[];
  character_collections?: string[];
  visual_collections?: string[];
  visual_collections_page?: { results?: ImageCollection[]; next?: string; count?: number };
  collections?: ImageCollection[];
  privacy?: string;
  tags?: string[];
  published_at?: string;
  type?: string;
  user: string;
  original_creator?: string;
  is_draft?: boolean; // only present in serialized Characters nested in CharacterRelationships
  // eslint-disable-next-line no-use-before-define
  pendingTransfer?: CharacterTransferRequest;
  // eslint-disable-next-line no-use-before-define
  ownershipHistory?: CharacterOwnershipEntry[];
  [key: string]: any;
}

export interface CharacterInWorld {
  id: string;
  info: Character['info'];
  slug: string;
  user: string;
}

export interface WorldsInfo {
  id?: string;
  name: string;
  quote: string;
  slug?: string;
  reaction_counts?: ReactionWorldCounts;
  user_reaction?: UserReaction;
  user?: User;
  cover_image: string;
  description: string;
  subworlds: string[];

  is_nsfw: boolean;
  allow_join: boolean;
  membership_details: string;
  visuals?: string[];
  visual_collections?: string[];
  visual_collections_page?: { results?: ImageCollection[]; next?: string; count?: number };
  tags: string[];
  collections: ImageCollection[];
  type?: string;
  privacy: string;
  character_worlds?: CharacterInWorld[];
  world_collections?: string[];
  included_locations: string[];
  triggerwarning: string;
  extra: {
    about: { [index: string]: any };
    colors: string[];
    spotifyLink: string[];
    featuredin: { [index: string]: any };
    [key: string]: any;
  };
  [key: string]: any;
}

export interface Collection {
  name?: string;
  id: string;
  description?: string;
  slug?: string;
  image_url?: string;
  cropped_img_url?: string;
  banner_img_url?: string;
  user?: User;
}

export interface BookmarkCollection {
  name?: string;
  id: string;
  description?: string;
  slug?: string;
  image_url?: string;
  cropped_img_url?: string;
  banner_img_url?: string;
  user?: User;
  context_bookmarked?: boolean;
}

export const socials = {
  facebook: 'facebook',
  instagram: 'instagram',
  tiktok: 'tiktok',
  tumblr: 'tumblr',
  deviantart: 'deviantart',
  twitter: 'twitter',
};

export type Socials = {
  [key in keyof typeof socials]?: string;
};

export interface UserRegistration {
  username?: string;
  id?: string;
  email?: string;
  first_login?: boolean;
}

export interface CharacterMini {
  name: string;
  profilePicture: string;
  slug?: string;
  info: CharacterInfo;
}

export interface Relationship {
  name: string;
  characters: CharacterMini[];
}

export type CharacterTransferRequest = {
  character: Character | null;
  user_from: User | null;
  user_to: User | null;
  state: string;
  note: string;
  id?: string;
  responded_at?: any;
};

export interface Raffle {
  id?: string;
  slug?: string;
  title?: string;
  is_nsfw?: boolean;
  total_winners?: number;
  description?: string;
  tags?: string[];
  terms_condition: string;
  visuals?: string[];
  is_featured: boolean;
  duration?: number;
  start_date: any;
  category?: string;
  status?: string;
}
export interface Post {}

export interface Recipient {
  username: string;
  id: string;
}

export interface Section {
  name?: string;
  id?: string;
  description?: string;
  slug?: string;
}

export interface HomeSection {
  id: string;
  section: Section;
  characters: any;
}

export interface Actor {
  username: string;
  id: string;
}

export interface Info {
  name: string;
}

export interface INotification {
  id: number;
  slug: number;
  unread: boolean;
  actor: Actor;
  target: any;
  action_object: any;
  verb: string;
  recipient: Actor;
  data: any;
  description?: string;
}

export interface Paging {
  page?: number;
  next: string;
  previous: string;
  count: number;
  results?: any[];
}

export interface SiteEvent {
  id: string;
  slug: string;
  title: string;
  starts_at?: any;
  ends_at?: any;
  description?: string;
  point_name?: string;
  image_url?: string;
  bg_image_url?: string;
  point_milestones?: any[];
  user_points?: 0;
}

export interface SiteEventTask {
  id: string;
  name: string;
  description?: string;
  response_type?: string;
  action_url?: string;
  per_user_submission_limit?: number;
  default_point_value?: number;
  task_type?: string;
  user_submission_count?: number;
  starts_at?: any;
  ends_at?: any;
  event?: string;
  event_slug?: string;
  event_title?: string;
  event_image_url?: string;
  will_refresh?: boolean;
  required_template_fields?: string[];
  required_template_field_labels?: any;
  is_response_publishable?: boolean;
  allow_fanchar_spinoffs?: boolean;
  extra?: any;
}

export interface SiteEventGroup {
  id: string;
  name: string;
  description?: string;
  slug: string;
  events: SiteEvent[];
  image_url?: string;
  tasks: { [eventId: string]: SiteEventTask[] };
}

export interface SiteEventTaskPollOption {
  image: string;
  text: string;
  vote_count?: number;
}

export interface SiteEventTaskPoll {
  id: string;
  question: string;
  description?: string;
  action_url?: string;
  options: SiteEventTaskPollOption[];
  show_results: boolean;
  results?: SiteEventTaskPollOption[];
  task_type?: string;
}

export interface SiteEventTaskSubmission {
  id?: string;
  is_public?: boolean;
  is_nsfw?: boolean;
  task: string;
  user: string;
  username: string;
  points_given?: number;
  submission_text?: string;
  submission_image_url?: string;
  submission_character?: any;
  submission_characters?: any[];
  created: any;
  extra?: any;
  reaction_counts?: ReactionCounts;
  user_reaction?: UserReaction;
  interaction_responses?: any[];
  interaction_response?: any;
}

export interface Collectible {
  count: number;
  description: string;
  id: string;
  image: string;
  in_circulation_count: number;
  item_type: string;
  name: string;
  perks: string;
  rarity: string;
  received_at: any;
  short_description: string;
  tier: number;
}

export interface UserProfile extends User {
  header_color?: string;
  profile_picture?: string;
  cover_url?: string;
  cover_cropped_url?: string;
  display_name?: string;
  profile_picture_url?: string;
  pronouns?: string;
  about_me?: string;
  over_18?: boolean;
  age_range?: string;
  socials?: Socials;
  featured?: {
    characters?: string[];
    collections?: string[];
  };
  featured_characters: Character[];
  featured_collections: Collection[];
  featured_collectibles: Collectible[];
  follower_count?: number;
  following_count?: number;
  character_count?: number;
  story_world_count?: number;
  customize_profile?: any;
  sliding_cover_images?: string[];
  cover_preference?: string;
}

export type Meta = {
  content: string;
  name: string;
};

export enum CharacterRelationshipStatus {
  Draft = 'D',
  RequestSent = 'R',
  RequestDenied = 'X',
  RequestAccepted = 'A',
}

export type CharacterRelationship = {
  character1: Character | null;
  character2: Character | null;
  character1_description: string | null;
  character2_description: string | null;
  character1_status: string | null;
  character2_status: string | null;
  character1_order?: number;
  character2_order?: number;
  request_message: string;
  state?: CharacterRelationshipStatus;
  id?: string;
  _id?: string;
};

export type CharacterOwnershipEntry = {
  id: string;
  character: Character | null;
  date: any;
  user: string;
  user_to?: string; // if type is 'credited', this value is null
  note?: string; // transfer note or original_creator value
  type: string; // 'transferred' or 'credited'
};

export type PaginatedResponse<T> = {
  results: T;
} & Paging;

export interface KeyedColor {
  id: string;
  hex_code: string;
  label: string;
}

export interface CharacterStylize {
  entity?: Character;
  entity_id?: string;
  entity_type?: string;
  id?: string;
  name?: string;
  template_applied?: string;
  user?: User;
  data?: any;
}

export interface CharacterTemplate {
  id?: string;
  user?: User;
  data?: any;
  name?: string;
}

export interface Report {
  reviewed_by?: string;
  status?: string;
  evaluation?: string;
  comments?: string;
  reviewer_comments?: string;
  reported_by?: User;
  reported_user?: User;
  report_category?: string;
  reported_entity_type?: string;
  reported_entity_id?: string;
  reported_content?: any;
  reported_content_link?: string;
  reported_admin_link?: string;
  created?: any;
  modified?: any;
  id?: string;
  extra?: any;
}

export interface UserModLog {
  user: User;
  action_taken?: string;
  comments?: string;
  confirmed_by?: any;
  extra?: any;
  id?: string;
  created?: any;
  modified?: any;
  report_count: number;
}
export type OnboardingCommon = {
  step: number;
  trivia_question_responses: string[];
  secret_ingredient_response: string;
  heard_about: string;
  accomplishments: string[];
  interests: string[];
  skipped_subscription: boolean;
};

export type Onboarding = OnboardingCommon & {
  user: User;
  liked_characters: Character[];
};
export type OnboardingCreateBody = OnboardingCommon & {
  liked_characters: string[];
  user: string;
};
export type OnboardingUpdateBody = OnboardingCreateBody;

export type StoreProduct = {
  id?: string;
  name: string;
  code: string;
  amount: number;
  image: string;
  description?: string;
  description_html?: string;
  discount_percent?: number;
  discounted_amount?: number;
  discounted_until?: any;
  limit_per_user?: number;
  first_purchase_offer?: any;
  is_new?: boolean;
  quantity?: number;
  post_action?: string;
  amount_gems?: number;
  amount_coins?: number;
  expires_at?: any;
  more_preview_images?: any[];
};

export type PushNotificationData = {
  title: string;
  key: string;
  path: string;
};

export type EditorAboutExample = {
  [key: string]: string;
};