<template>
  <div class="stats">
    <div v-for="item of orderedStats" :key="item.key">
      <div v-if="hasValue(item)" class="one-stats d-flex align-items-center">
        <div class="key dark">{{ toTitleSpaceCase(item.key) }}</div>
        <div
          v-if="item.displayType === 'bar'"
          class="stvalue dark position-relative d-flex justify-content-center flex-grow-1"
          :style="{ border: sectionBorderAndColor }"
        >
          <div class="numbers dark px-2">
            <div :style="`font-family: ${defaultTextFont}`">{{ item.value }}/{{ item.maxvalue }}</div>
          </div>
          <span class="position-absolute" :style="{ width: `${width(item)}%` }" />
        </div>
        <div
          v-if="item.displayType === 'text'"
          :style="{ border: sectionBorderAndColor }"
          class="stvalue stvalue-text dark d-flex align-items-center flex-grow-1"
        >
          {{ item.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toTitleSpaceCase } from '@/shared/utils/string';
import { formatStatsIn } from '@/shared/utils/transform';
import { mainStore } from '@/shared/pinia-store/main';

const props = defineProps({
  stats: {
    type: Object,
    default: {},
  },
  statsConfig: {
    type: Object,
    default: {},
  },
  customize: {
    type: Object,
    default: {},
  },
});

const customize = toRef(props, 'customize');
const stats = toRef(props, 'stats');
const statsConfig = toRef(props, 'statsConfig');
const currColor = ref('');
const route = useRoute();

const isdark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});
const width = ({ value, maxvalue }: any) => {
  return (Number(value) * 100) / Number(maxvalue);
};
const defaultTextFont = computed(() => {
  return (isEmpty(get(customize.value, 'template_applied')) &&
    isEmpty(get(customize.value, 'data.theme.text.charName.font'))) ||
    get(customize.value, 'data.theme.text.charName.font') === null
    ? 'Roboto'
    : get(customize.value, 'data.theme.text.default.font', '');
});

const infosectionColor = computed(() => {
  return isEmpty(get(customize.value, 'template_applied')) &&
    isdark.value &&
    ['character-profile-draft-new', 'character-profile-new'].includes(route.name as string)
    ? '#010123'
    : isEmpty(get(customize.value, 'template_applied')) &&
      get(customize.value, 'data.theme.backgrounds.infoSection') === '#E6E6E6'
    ? '#FFFFFF'
    : get(customize.value, 'data.theme.backgrounds.infoSection');
});

const numbersBgColor = computed(() => {
  const currdefaultClr = get(customize.value, 'data.theme.text.default.color');

  if (isEmpty(get(customize.value, 'template_applied')) && currdefaultClr === '#000000') {
    if (isEmpty(currColor.value)) return;
  } else {
    currColor.value = currdefaultClr;
    return get(customize.value, 'data.theme.backgrounds.infoSection');
  }
});
const getDefaultColor = computed(() => {
  const currdefaultClr = get(customize.value, 'data.theme.text.default.color');
  const defaultClr = '#45B6F5';

  if (isEmpty(get(customize.value, 'template_applied')) && currdefaultClr === '#000000') {
    if (isEmpty(currColor.value)) return defaultClr;
  } else {
    currColor.value = currdefaultClr;
    return `${get(customize.value, 'data.theme.text.default.color', defaultClr)}`;
  }
});

const sectionSubTitleBold = computed(() => {
  return isEmpty(get(customize.value, 'template_applied'))
    ? '800'
    : get(customize.value, 'data.theme.text.default.bold')
    ? '800'
    : '100';
});
const defaultColor = computed(() => {
  const defaultClr = isdark.value ? '#FFFFFF' : '#000000';
  return isEmpty(get(customize.value, 'template_applied')) &&
    ['character-profile-new', 'character-profile-draft-new'].includes(route.name as string)
    ? defaultClr
    : `${get(customize.value, 'data.theme.text.default.color', defaultClr)}`;
});
const getDefaultBold = computed(() => {
  return isEmpty(get(customize.value, 'template_applied')) &&
    (route.name === 'character-profile-new' || route.name === 'character-profile-draft-new')
    ? 'normal'
    : get(customize.value, 'data.theme.text.default.bold')
    ? '800'
    : 'normal';
});
const sectionBorderAndColor = computed(() => {
  const color = get(customize.value, 'data.theme.text.default.color');

  return isEmpty(get(customize.value, 'template_applied')) &&
    get(customize.value, 'data.theme.text.default.color') === '#000000'
    ? 'none'
    : `3px solid ${color} !important`;
});

const hasValue = (stat: any) => {
  return (stat.displayType === 'bar' && stat.value && stat.maxvalue) || (stat.displayType === 'text' && stat.value);
};

const orderedStats = computed(() => {
  return formatStatsIn(stats.value, statsConfig.value);
});
</script>

<style lang="sass" scoped>
.stats
  .one-stats
    margin-top: 10px
    span
      height: 100%
      background: v-bind(getDefaultColor)
      left: 0
    .stvalue
      word-break: break-word
      background: v-bind(infosectionColor)
      border-radius: 12px
      min-height: 30px
      overflow: hidden
      min-height: 33px
      color: v-bind(defaultColor) !important
      white-space: pre-line
      text-align: left
      width: calc(100% - 150px)
      &.dark
        background: v-bind(infosectionColor) !important
        font-family: v-bind(defaultTextFont)
      .numbers
        z-index: 1
        background: v-bind(infosectionColor)
        height: 26px
        border-radius: 9px
        line-height: 28px
        font-weight: v-bind(getDefaultBold)
        color: v-bind(defaultColor) !important
        &.dark
          color: v-bind(defaultColor) !important
          background: v-bind(numbersBgColor) !important
    .stvalue-text
      padding: 7px 0.7rem !important
    .key
      width: 130px
      color: v-bind(defaultColor) !important
      font-weight: v-bind(sectionSubTitleBold) !important
      align-self: flex-start !important
      margin-top: 10px
      line-height: 1.1rem 
      text-align: left
      margin-left: 0.5rem
      position: relative
      &.dark
        color: v-bind(defaultColor) !important
        font-family: v-bind(defaultTextFont) !important
    @media(max-width: 500px)
      flex-direction: column
      .key, .stvalue
        width: 100% !important
      .key
        margin-bottom: 5px
.dark .stvalue
  background: v-bind(infosectionColor) !important
  color: v-bind(defaultColor) !important
@media(max-width: 600px)
  .key
    width: 120px !important
    margin-left: auto
</style>
