<template>
  <ion-modal class="big-modal" :is-open="isOpen" :backdrop-dismiss="false" :show-backdrop="true">
    <div
      class="d-flex justify-content-center mx-auto align-items-center"
      style="left: 50%; top: 42%; position: absolute; transform: translateX(-50%) translateY(-50%)"
    >
      <img
        class="img"
        loading="lazy"
        style="max-width: 42vh"
        src="/boxopening_animatepng_1loop.png"
        @load="onAnimLoad"
      />
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  currency: {
    type: Object,
  },
});
const emits = defineEmits(['close']);
const isOpen = toRef(props, 'isOpen');

const onAnimLoad = () => {
  setTimeout(() => {
    emits('close');
  }, 1500);
};
</script>

<style lang="sass" scoped>
.big-modal
  --background: transparent !important
  --backdrop-opacity: 0.5 !important
  box-shadow: none !important
</style>
