export const storage = {
  async set(key: string, data: any) {
    if (process.server) return;
    await localStorage.setItem(key, JSON.stringify(data));
  },
  async get(key: string) {
    if (process.server) return;
    const value = localStorage.getItem(key);
    return JSON.parse(value as any);
  },
  async remove(key: string) {
    if (process.server) return;
    await localStorage.removeItem(key);
  },
  async clear() {
    if (process.server) return;
    await localStorage.clear();
  },
};
