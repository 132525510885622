<template>
  <transition>
    <div class="d-flex">
      <ion-card
        class="blab-card ml-0 mt-3 mr-3"
        :style="`background:${infoSectionColor};color: ${defaultTextColor}; border: ${sectionBorder}; border-radius: ${sectionEdges};box-shadow: ${sectionShadow}`"
      >
        <div class="d-flex justify-content-between text-center align-items-center px-3 mt-3">
          <div
            class="title"
            :style="` color: ${titleColor}; font-family: ${titlesTextFont}; font-weight: ${titlesTextBold} !important; font-style: ${titlesTextItalic} !important`"
          >
            Posts ({{ blabsCount.all_posts }})
          </div>
          <div v-if="isOwner">
            <router-link :to="{ name: 'create-post' }">
              <ion-button class="post-btn" :style="`--background: ${defaultBtnTextColor}`">
                <inline-svg width="20px" src="/icons/create.svg" class="mx-2 icon" />
                Create Post</ion-button
              >
            </router-link>
          </div>
        </div>
        <div class="px-3">
          <char-posts-tabs
            :btn-clr="defaultBtnTextColor"
            :text-clr="tabTextClr"
            :tabs="tabs"
            :text-btn-clr="textBtnClr"
            :count="blabsCount"
            :active-index="tabIndex"
            :text-font="defaultTextFont"
            :text-style="defaultTextItalic"
            @tab-changed="tabChanged"
          />
        </div>
        <div
          v-if="userPost && userPost.length"
          class="px-3"
          :class="{
            'hide-clickable-card': $route.name === 'character-stylize' || $route.name === 'character-draft-stylze',
          }"
        >
          <template v-for="(post, index) of userPost" :key="post.id">
            <blab-card-details-part
              :post="post"
              :customize="customize"
              :default-color="defaultTextColor"
              :dark="dark"
              :char-name="charName"
              :char-id="charId"
              :default-text-font="defaultTextFont"
              :replies-count="get(blabRepliesCount, `${post.id}.blab_replies_count`) || 0"
              :featured_characters="get(post.featured_characters, [])"
              @afterReaction="(reactionResp) => onReaction(index, reactionResp)"
              @open="openBlabModal"
            />
            <hr v-if="index < userPost.length - 1" />
          </template>
          <div class="d-flex justify-content-end py-2">
            <router-link
              :to="
                character
                  ? {
                      name: 'char-posts',
                      params: { slug: slug },
                      query: { tab: currentTab },
                    }
                  : {
                      name: 'user-posts',
                      params: { username: username },
                      query: { tab: 'blabs' },
                    }
              "
            >
              <ion-button class="see-all-btn" :style="`--background: ${defaultBtnTextColor};color: ${textBtnClr}`">
                <div class="d-flex">
                  <strong :style="`font-family: ${defaultTextFont}; font-style: ${defaultTextItalic} !important`">
                    See
                    {{
                      pluralize(
                        currentTab === 'comic' ? 'All Comic' : currentTab === 'story' ? 'All Story' : 'All Post'
                      )
                    }}
                  </strong>
                </div>
              </ion-button>
            </router-link>
          </div>
        </div>

        <div v-else>
          <router-link :to="{ name: 'create-post' }">
            <ion-button color="transparent" class="inline-button d-flex justify-content-center mt-2 pb-2">
              <div class="d-flex" style="color: grey">
                <span> Create a Post</span>
                <i class="ti-angle-right mr-2" :icon="arrowForward" />
              </div>
            </ion-button>
          </router-link>
        </div>
      </ion-card>
      <blab-details-modal
        :is-open="openBlabAsModal"
        :blabid="blabid"
        @dismiss-modal="closeBlabAsModal"
        @edit="openEditBlab"
        @details="openDetailsPage"
        @profile="openProfile"
      />
    </div>
  </transition>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import pluralize from 'pluralize';
import { Prop, Watch } from 'vue-property-decorator';
import { arrowForward } from 'ionicons/icons';
import BlabDetailsModal from '@/shared/modals/BlabDetailsModal.vue';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import BlabCardDetailsPart from '@/shared/pages/blabs/components/BlabCardDetailsPart.vue';
import { getCountofBlabReplies } from '@/shared/actions/blabs';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import CharPostsTabs from '@/shared/pages/blabs/components/CharPostsTabs.vue';
import { characterPostsTabs } from '@/shared/statics/tabs';
import { Tab } from '@/shared/types/static-types';
import { textColorChanged } from '@/shared/utils/textConverter';

@Options({
  name: 'BlabCard',
  components: { BlabCardDetailsPart, BlabDetailsModal, CharPostsTabs, pluralize },
})
export default class BlabCard extends Vue {
  @Prop({ default: () => ({}) }) userPost!: any;
  @Prop({ default: () => ({}) }) count!: number;
  @Prop({ default: () => ({}) }) username!: any;
  @Prop({ default: false }) character!: boolean;
  @Prop({ default: () => ({}) }) slug!: any;
  @Prop() charName!: any;
  @Prop() customize!: any;
  @Prop() blabsCount!: any;
  @Prop() isOwner!: boolean;
  @Prop() charId!: any;

  public first = first;
  public tabs = characterPostsTabs;
  public currentTab: string = 'blabs';
  public tabIndex = 0;
  pluralize = pluralize;
  arrowForward = arrowForward;

  get = get;
  blabRepliesCount = {};
  openBlabAsModal = false;
  blabid = '';
  isEdit = false;
  openprofile = false;
  formatTimeAgo = formatTimeAgo;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  @Watch('userPost', { immediate: true })
  getBlabReply() {
    if (get(this.userPost, 'length')) this.fetchBlabReplies();
  }

  public onReaction(postIndex: number, reaction: any) {
    this.userPost[postIndex] = {
      ...this.userPost[postIndex],
      ...reaction.updatedReactionsData,
    };
  }

  public async fetchBlabReplies() {
    this.blabRepliesCount = {};
    const resp = await getCountofBlabReplies(map(this.userPost, 'id'));
    this.blabRepliesCount = keyBy(resp, 'blab');
  }

  public get isTempDefault() {
    return isEmpty(get(this.customize, 'template_applied')) && this.isCharProfileRoute;
  }

  public get defaultTextFont() {
    return this.isTempDefault
      ? 'Roboto'
      : `${get(this.customize, 'data.theme.text.default.font') || 'Roboto'} !important`;
  }

  public get defaultTextItalic() {
    return get(this.customize, 'data.theme.text.default.italics') ? 'italic' : 'normal';
  }

  public get sectionBorder() {
    const border = get(this.customize, 'data.theme.styles.infoSections.border', 'none');
    const color = get(this.customize, 'data.theme.styles.infoSections.color');
    return `3px ${border} ${color} !important`;
  }

  public get sectionEdges() {
    if (get(this.customize, 'data.theme.styles.sectionEdges') === 'sharp') return '0px !important';
    else if (get(this.customize, 'data.theme.styles.sectionEdges') === 'round') return '10px !important';
    return '20px !important';
  }

  public get dark() {
    const { dark } = mainStore();
    return dark.value;
  }

  public get isCharProfileRoute() {
    try {
      const router = useRouter();
      return (
        router.currentRoute.value.name === 'character-profile-new' ||
        router.currentRoute.value.name === 'character-profile-draft-new'
      );
    } catch (error) {
      return '';
    }
  }

  public get infoSectionColor() {
    return this.isTempDefault && this.dark
      ? '#17074C !important'
      : `${get(this.customize, 'data.theme.backgrounds.infoSection')} !important`;
  }

  public get titleColor() {
    return this.isTempDefault && this.dark
      ? '#FFFFFF'
      : `${get(this.customize, 'data.theme.text.titles.color') || '#000000'} !important`;
  }

  public get titlesTextFont() {
    return this.isTempDefault
      ? 'Roboto'
      : `${get(this.customize, 'data.theme.text.titles.font') || 'Roboto'} !important`;
  }

  public get defaultTextColor() {
    const defaultClr = this.dark ? '#FFFFFF' : '#000000';
    return this.isTempDefault
      ? `${defaultClr} !important`
      : `${get(this.customize, 'data.theme.text.default.color', defaultClr)}`;
  }

  public get tabTextClr() {
    const defaultClr = this.dark ? '8f8a8a' : '#214163';
    return this.isTempDefault ? `${defaultClr}` : `${get(this.customize, 'data.theme.text.default.color', defaultClr)}`;
  }

  public get defaultBtnTextColor() {
    const defaultClr = 'var(--ion-color-primary)';
    return this.isTempDefault
      ? `${defaultClr}`
      : `${get(this.customize, 'data.theme.backgrounds.buttons', defaultClr)}`;
  }

  public get textBtnClr() {
    return this.defaultBtnTextColor ? textColorChanged(this.defaultBtnTextColor) : '#fff';
  }

  public get sectionShadow() {
    return get(this.customize, 'data.theme.styles.infoSections.shadow') === 'none'
      ? '0px 0px 0px !important'
      : get(this.customize, 'data.theme.styles.infoSections.shadow') === 'alittle'
      ? '0 2px 13px rgb(0 0 0 / 15%) !important'
      : '3px 2px 20px #635d5d !important';
  }

  public get titlesTextColor() {
    return this.isTempDefault && this.dark ? '#FFFFFF' : get(this.customize, 'data.theme.text.titles.color');
  }

  public tabChanged({ value }: Tab) {
    this.currentTab = value;
    this.$emit('tabchanged', value === 'blabs' ? '' : value);
  }

  public get titlesTextBold() {
    return get(this.customize, 'data.theme.text.titles.bold') ? 'bold' : 'normal';
  }

  public get titlesTextItalic() {
    return get(this.customize, 'data.theme.text.titles.italics') ? 'italic' : 'normal';
  }

  public openBlabModal(id: string) {
    this.blabid = id;
    this.openBlabAsModal = true;
  }

  public closeBlabAsModal(val?: any) {
    this.openBlabAsModal = false;
    this.$nextTick(() => (this.blabid = ''));
  }

  public openEditBlab(edit: boolean, id: string) {
    const router = useRouter();
    this.isEdit = edit;
    if (this.isEdit) {
      this.blabid = '';
      this.openBlabAsModal = false;
      router.push({ name: 'edit-post', params: { id } });
    }
  }

  public openProfile(openprofile: boolean) {
    this.blabid = '';
    this.openprofile = openprofile;
    if (this.openprofile) {
      this.openBlabAsModal = false;
    }
  }

  public openDetailsPage() {
    this.openBlabAsModal = false;
  }
}
</script>

<style lang="sass" scoped>
.post-btn
  width: 100%
  --border-radius: 12px
  height: 30px
.hide-clickable-card
  pointer-events: none !important

.title
  font-size: 24px

.see-all-text
  color: #214163
.see-all-btn
  width: 100%
  --border-radius: 12px
.blab-card
  overflow: unset !important
  .d-flex
    font-size: 15px
  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important
  .blab-title
    font-size: 16px
    font-weight: bold
  .img
    width: 40px
    height: 40px
    object-fit: cover
</style>
