<template>
  <div class="position-relative" :style="{ width: `${borderWidth}px` }" v-if="featureFlags.profileRing">
    <img
      :src="image"
      :class="isEmpty(usercharacter) ? 'border-image' : 'prof-border-image'"
      :style="{ top: `${ringTop}px`, left: `${ringLeft}px` }"
    />

    <div
      v-if="!isEmpty(usercharacter)"
      class="my-0 profile-picture d-flex align-items-center justify-content-center"
      :style="{
        backgroundImage: `url(${profilePicture})`,
      }"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { featureFlags } from '@/shared/config/feature-flags';
import { resizeUpload } from '../utils/upload';

const props = defineProps({
  width: { type: Number },
  height: { type: Number },
  image: { type: String, default: '' },
  borderWidth: { type: Number, default: '' },
  ringTop: { type: Number, default: 0 },
  ringLeft: { type: Number },
  usercharacter: { type: Object, default: {} },
});
const width = toRef(props, 'width');
const height = toRef(props, 'height');
const ringLeft = toRef(props, 'ringLeft');
const usercharacter: any = toRef(props, 'usercharacter');
const profilePicture = computed(() => {
  return !isEmpty(get(usercharacter.value, 'slug')) || get(usercharacter.value, 'type') === 'draft'
    ? resizeUpload(get(usercharacter.value, 'info.cropProfilePicture', '/empty.png'), '80x80')
    : resizeUpload(get(usercharacter.value, 'profile_picture_url'), '80x80');
});
</script>

<style lang="sass" scoped>
.prof-border-image
  top: 50% !important
  left: 50%
  transform: translateX(-50%) translateY(-50%)
  z-index: 3
  position: absolute
.profile-picture
  cursor: pointer
  width: v-bind('`${width}px`')
  height: v-bind('`${height}px`')
  min-width: v-bind('`${width}px`')
  background-color: var(--ion-color-primary) !important
  color: #FFF
  border-radius: 40px
  background-size: cover
  background-position: center
  z-index: 2
  position: absolute
  transform: translateX(-50%) translateY(-50%)
  top: 50%
  left: 50%
  font-size: 13px

.border-image
  z-index: 3
  position: absolute
</style>
