import { createReactionOnCharacter, deleteReactionOnCharacter, updateReactionOnCharacter } from '@/shared/actions/reactions';
import { Character } from '@/shared/types/static-types';

export const isUserReactedSameReaction = (reaction: string, character: Character, isInstant = false): boolean => {
  const { user_reaction } = character;
  return !!(user_reaction && user_reaction.id && (user_reaction.reaction === reaction || isInstant));
};

export const isUserReactedAnotherReaction = (reaction: string, character: Character): boolean => {
  const { user_reaction } = character;
  return !!(user_reaction && user_reaction.id && user_reaction.reaction !== reaction);
};

export const updatedSendReactionToBackend = async (
  reaction: string,
  character: Character,
  userReaction: any,
  fromRoulette: boolean = false,
  isInstant: boolean = false
) => {
  const target = 'character';
  const { id: characterId = '' } = character;

  // delete
  if (isUserReactedSameReaction(reaction, userReaction, isInstant)) {
    return await deleteReactionOnCharacter({ reactionId: userReaction?.id || '' });
  }

  // patch
  if (isUserReactedAnotherReaction(reaction, userReaction)) {
    return await updateReactionOnCharacter({ reactionId: userReaction?.id || '', reaction });
  }

  // post
  const data = await createReactionOnCharacter({ objectId: characterId, target, reaction, fromRoulette });
  return data;
};

export const sendReactionToBackend = async (
  reaction: string,
  character: Character,
  fromRoulette: boolean = false,
  isInstant: boolean = false
) => {
  const target = 'character';
  const { user_reaction, id: characterId = '' } = character;

  // delete
  if (isUserReactedSameReaction(reaction, character, isInstant)) {
    return await deleteReactionOnCharacter({ reactionId: user_reaction?.id || '' });
  }

  // patch
  if (isUserReactedAnotherReaction(reaction, character)) {
    return await updateReactionOnCharacter({ reactionId: user_reaction?.id || '', reaction });
  }

  // post
  const data = await createReactionOnCharacter({ objectId: characterId, target, reaction, fromRoulette });
  return data;
};

export const newTextKey = (character: Character) => {
  const info = character?.info;
  const keys = Object.keys(info);
  const maxKeyNumber =
    keys
      .filter((key) => key.includes('text'))
      .map((key) => Number(key.replaceAll('text', '')))
      .sort((a, b) => a - b)
      .pop() || 0;
  const newKey = `text${maxKeyNumber + 1}`;
  return newKey;
};

export const newAboutKey = (character: Character) => {
  const info = character?.info;
  const keys = Object.keys(info);
  const maxKeyNumber =
    keys
      .filter((key) => key.includes('moreAbout'))
      .filter((key) => !key.includes('Config'))
      .map((key) => Number(key.replaceAll('moreAbout', '')))
      .sort((a, b) => a - b)
      .pop() || 0;

  const newKey = `moreAbout${maxKeyNumber + 1}`;
  return newKey;
};
