import { Tab } from '@/shared/types/static-types';

export const charactersTabs: Tab[] = [
  {
    name: 'Trending',
    value: 'trending',
  },
  {
    name: 'Latest',
    value: 'latest',
  },
  {
    name: 'Top',
    value: 'top',
  },
];

export const worldsTabs: Tab[] = [
  {
    name: 'Trending',
    value: 'trending',
  },
  {
    name: 'Latest',
    value: 'latest',
  },
  {
    name: 'Top',
    value: 'top',
  },
];
export const themesTabs: Tab[] = [
  {
    name: 'Backgrounds',
    value: 'backgrounds',
  },
  {
    name: 'Text',
    value: 'text',
  },
  {
    name: 'Styles',
    value: 'styles',
  },
];
export const stylizeTabs: Tab[] = [
  {
    name: 'Themes',
    value: 'themes',
  },
];
export const raffleTabs: Tab[] = [
  {
    name: 'All',
    value: 'all',
  },
  {
    name: 'CharacterHub',
    value: 'characterhub',
  },

  {
    name: 'Character',
    value: 'character',
  },
  {
    name: 'Art',
    value: 'art',
  },
];

export const complimentBombCharTabs: Tab[] = [
  {
    name: 'All Characters',
    value: 'all',
  },
  {
    name: 'My Characters',
    value: 'mychars',
  },

  {
    name: 'Characters I Follow',
    value: 'followchars',
  },
];

export const complimentBombUserTabs: Tab[] = [
  {
    name: 'All Users',
    value: 'all',
  },
  {
    name: 'Users You Follow',
    value: 'myfollowing',
  },

  {
    name: 'Users Who Follow You',
    value: 'myfollowers',
  },
];

export const complimentTabs: Tab[] = [
  {
    name: 'New Compliments',
    value: 'new',
  },
  {
    name: 'Top Compliments',
    value: 'top',
  },
];

export const inventoryTabs: Tab[] = [
  {
    name: 'Consumable',
    value: 'consumable',
  },
  {
    name: 'To Be Gifted',
    value: 'gifted',
  },
];

export const manageRaffleTabs: Tab[] = [
  {
    name: 'Ongoing',
    value: 'ongoing',
  },
  {
    name: 'Ended',
    value: 'ended',
  },
];
export const quizzesTabs: Tab[] = [
  {
    name: 'All',
    value: 'all',
  },
  {
    name: 'OC',
    value: 'oc',
  },

  {
    name: 'Worldbuilding',
    value: 'worldbuilding',
  },
];
export const quizzesCharSelectionTabs: Tab[] = [
  {
    name: 'All Characters',
    value: 'allchar',
  },
];

export const characterHubStoreTabs: Tab[] = [
  {
    name: 'Special Offers',
    value: 'offers',
  },
  {
    name: 'Popular',
    value: 'popular',
  },
  {
    name: 'Limited Edition',
    value: 'edition',
  },
  {
    name: 'All',
    value: 'all',
  },
];

export const profileRingsTabs: Tab[] = [
  {
    name: 'Featured',
    value: 'featured',
  },

  {
    name: 'All',
    value: 'all',
  },
];

export const eventTabs: Tab[] = [
  {
    name: 'Recommended',
    value: 'recommended',
  },
  {
    name: 'Writing',
    value: 'writing',
  },
  {
    name: 'Art',
    value: 'art',
  },
  {
    name: 'Just For Fun',
    value: 'justfun',
  },
  {
    name: 'Spiderman',
    value: 'spiderman',
  },
];
export const eventDetailsTabs: Tab[] = [
  { name: 'Ongoing', value: 'ongoing' },
  { name: 'Upcoming', value: 'upcoming' },
  { name: 'My Submissions', value: 'submission' },

  // TODO: Integrate ended event tasks tab in details page
  // { name: 'Ended', value: 'ended' },
];

export const homeQuestsTabs: Tab[] = [
  { name: 'Social', value: 'social' },
  { name: 'Events', value: 'events' },
  { name: 'Raffles', value: 'raffles' },
  { name: 'Quizzes', value: 'quizzes' },
];

export const profileAddOnsTabs: Tab[] = [
  {
    name: 'Username Colors',
    value: 'usercolor',
  },
  {
    name: 'Badges',
    value: 'userbadge',
  },
  {
    name: 'Profile Rings',
    value: 'prfrings',
  },
];

export const ocDecorTabs: Tab[] = [
  {
    name: 'Hat',
    value: 'hat',
    icon: '/icons/hat.svg',
  },
  {
    name: 'Hair',
    value: 'hair',
    icon: '/icons/hair.svg',
  },
  {
    name: 'Glasses',
    value: 'glasses',
    icon: '/icons/glasses.svg',
  },
  {
    name: 'Mustache',
    value: 'mustache',
    icon: '/icons/moustache.svg',
  },
  {
    name: 'Text',
    value: 'text',
    icon: 'ti-comment-alt',
  },
];
export const ocSubmissionTabs: Tab[] = [
  {
    name: 'Your Submissions',
    value: 'submission',
  },
  {
    name: 'Community',
    value: 'community',
  },
];

export const profileTabs: Tab[] = [
  {
    name: 'Characters',
    value: 'characters',
    icon: 'ti-pencil',
  },
  {
    name: 'Story Worlds',
    value: 'worlds',
    icon: 'ti-world',
  },
  {
    name: 'Social Spaces',
    value: 'spaces',
    icon: 'ti-comments',
  },
  {
    name: 'Folders',
    value: 'folders',
    icon: 'ti-folder',
  },
];

export const collectionTabs: Tab[] = [
  {
    name: 'Characters',
    value: 'characters',
  },
  {
    name: 'Story Worlds',
    value: 'storyworlds',
  },
];

export const charactersDraftTabs: Tab[] = [
  {
    name: 'Drafts',
    value: 'drafts',
  },
  {
    name: 'Characters',
    value: 'characters',
  },
  {
    name: 'Folders',
    value: 'folders',
  },
  {
    name: 'Bookmarks',
    value: 'bookmarks',
  },
];

export const myCreationsTabs: Tab[] = [
  {
    name: 'Characters',
    value: 'characters',
  },
  {
    name: 'Worlds',
    value: 'worlds',
  },
  {
    name: 'Folders',
    value: 'folders',
  },
  {
    name: 'Bookmarks',
    value: 'bookmarks',
  },
  {
    name: 'Reactions',
    value: 'reactions',
  },
];

export const draftsTabs: Tab[] = [
  {
    name: 'Characters',
    value: 'characters',
  },
  {
    name: 'Worlds',
    value: 'worlds',
  },
];

export const presetsTabs: Tab[] = [
  {
    name: 'Character Presets',
    value: 'presets',
  },
];

export const blabsTabs: Tab[] = [
  {
    name: 'Trending',
    value: 'trending',
  },
  {
    name: 'Latest',
    value: 'latest',
  },
  {
    name: 'Top',
    value: 'top',
  },
];

export const usersBlabsTabs: Tab[] = [
  {
    name: 'All Posts',
    value: 'blabs',
  },
  {
    name: 'Stories',
    value: 'story',
  },
  {
    name: 'Comics',
    value: 'comic',
  },
];

export const characterPostsTabs: Tab[] = [
  {
    name: 'All Posts',
    value: 'blabs',
  },
  {
    name: 'Stories',
    value: 'story',
  },
  {
    name: 'Comics',
    value: 'comic',
  },
];

export const socialSpacesTabs: Tab[] = [
  {
    name: 'INFORMATION',
    value: 'information',
    icon: 'ti-info-alt',
  },
  {
    name: 'CHATROOMS',
    value: 'chatroom',
    icon: 'ti-comments',
  },
];

export const searchTabs: Tab[] = [
  {
    name: 'All',
    value: 'all',
    icon: 'ti-view-list',
  },
  {
    name: 'Characters',
    value: 'characters',
    icon: 'ti-pencil',
  },
  {
    name: 'Users',
    value: 'users',
    icon: 'ti-user',
  },
  {
    name: 'Story Worlds',
    value: 'worlds',
    icon: 'ti-world',
  },
  {
    name: 'Social Spaces',
    value: 'spaces',
    icon: '/icons/people-outline.svg',
  },
  {
    name: 'Posts',
    value: 'blabs',
    icon: 'ti-file',
  },
  {
    name: 'Tags',
    value: 'tags',
    icon: 'ti-tag',
  },
  {
    name: 'Marketplace Listings',
    value: 'marketplacelistings',
    icon: 'ti-shopping-cart',
  },
  {
    name: 'Chatroom',
    value: 'chatrooms',
    icon: 'ti-comments',
  },
];

export const followTabs: Tab[] = [
  {
    name: 'Followers',
    value: 'followers',
  },
  {
    name: 'Following',
    value: 'following',
  },
];

export const reactionsTabs: Tab[] = [
  {
    name: 'Characters',
    value: 'characters',
    icon: 'ti-pencil',
  },
  {
    name: 'Story Worlds',
    value: 'worlds',
    icon: 'ti-world',
  },
  {
    name: 'Social Spaces',
    value: 'social',
    icon: 'ti-comments',
  },
  {
    name: 'Posts',
    value: 'blabs',
    icon: 'ti-file',
  },
  {
    name: 'Images',
    value: 'visuals',
    icon: 'ti-image',
  },
];

export const marketplaceTabs: Tab[] = [
  {
    name: 'Marketplace',
    value: '',
  },
  {
    name: 'Free',
    value: 'free',
  },
  {
    name: 'Art Commissions',
    value: 'art_commission',
  },
  {
    name: 'Character Sales',
    value: 'character_sale',
  },
  {
    name: 'CharacterHub',
    value: 'characterhub',
  },
];
