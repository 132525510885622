import { mainStore } from "~/shared/pinia-store/main";

export const useCharCustDefault = (props: any) => {
    const { isTempDefault } = useCustTempDefault(props);
    const getDefaultColor = computed(() => {
        const {dark }= mainStore()
        const defaultClr = dark.value ? 'white' : 'black';
      
        if (isTempDefault.value) {
          return defaultClr;
        } else {
          return `${get(props.customize, 'data.theme.text.default.color', defaultClr)}`;
        }
      });
      const getDefaultFont = computed(() => {
        return isTempDefault.value ? 'Roboto' : get(props.customize, 'data.theme.text.default.font','Roboto') ;
      });
      
      const getDefaultItalic = computed(() => {
        return get(props.customize, 'data.theme.text.default.italics') ? 'italic' : 'normal';
      });
      
      const getDefaultBold = computed(() => {
        return isTempDefault.value  ? '800' : get(props.customize, 'data.theme.text.default.bold') 
      });
    return { getDefaultColor, getDefaultFont,getDefaultItalic ,getDefaultBold  };
  };
  