<template>
  <div class="d-flex align-items-center" :class="isMobSmallScreen ? '' : 'justify-content-end featured-margin'">
    <small><b>Featured Characters:</b></small>
    <div v-if="characters && characters.length" class="d-flex align-items-center">
      <small v-for="(char, index) of charsToDisplay" :key="char.id">
        <router-link
          class="one-character d-flex align-items-center ml-2 position-relative"
          :class="isMobSmallScreen ? 'flex-column' : ''"
          :to="{ name: 'character-profile-new', params: { slug: char.slug } }"
          @click="closeCharProfile"
        >
          <ProfileRing
            :image="get(char, 'profile_ring.image')"
            :borderWidth="40"
            :ringTop="prfRingTopPosition"
            :ringLeft="prfRingLeftPosition"
            class="position-absolute"
          />
          <img
            v-image
            class="clickable-item-hov mr-1"
            :src="resizeUpload(get(char, 'info.cropProfilePicture'), '80x80')"
          />
          <span class="text-primary clickable-item-hov">{{ truncateText(get(char, 'info.name', ''), 15) }}</span>
        </router-link>
      </small>
      <small
        v-if="characters.length > charsDispCount"
        class="clickable-item-hov ml-2"
        style="font-weight: bold"
        @click="showAllChars"
      >
        +{{ characters.length - charsDispCount }} more</small
      >
      <see-all-characters-modal :is-open="isCharsOpen" :characters="characters" @close="closeAllCharsModal" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Character } from '@/shared/types/static-types';
import SeeAllCharactersModal from '@/shared/modals/SeeAllCharactersModal.vue';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';
import ProfileRing from '@/shared/components/ProfileRing.vue';

const { width: windowWidth } = useWindowSize();
const emits = defineEmits(['close']);

const props = defineProps({
  characters: { type: Array<Character>, default: () => [] },
});

const isCharsOpen = ref(false);

const characters = toRef(props, 'characters');

const charsToDisplay = computed(() => {
  return characters.value.slice(0, charsDispCount.value);
});

const showAllChars = () => {
  isCharsOpen.value = true;
};
const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 540;
});

const prfRingTopPosition = computed(() => {
  return isMobSmallScreen.value ? -5 : -20;
});

const prfRingLeftPosition = computed(() => {
  return isMobSmallScreen.value ? -2 : -5;
});

const closeAllCharsModal = () => {
  isCharsOpen.value = false;
};

const isMobScreen = computed(() => {
  return windowWidth.value <= 768;
});

const closeCharProfile = () => {
  emits('close');
};

const charsDispCount = computed(() => {
  return isMobScreen.value ? 2 : 3;
});

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

onMounted(async () => {
  window.addEventListener('resize', handleResize);
});
</script>

<style lang="sass" scoped>
.featured-margin
  margin-top: -31px
.one-character
  img
    min-width: 30px
    width: 30px !important
    height: 30px !important
    border-radius: 15px
    border: solid gray 0.1px
    object-fit: cover
    .name
      font-size: 9px
      font-weight: bold
      color: #737373
      cursor: pointer
    .name:hover
      opacity: 0.7
</style>
