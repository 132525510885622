<template>
  <div>
    <ion-item v-if="isEditable" class="item-container clickable-item-hov" @click="doEditComment">
      <div class="menu-item">
        <p class="my-2">Edit</p>
      </div>
    </ion-item>
    <ion-item v-if="isDeletable" class="item-container clickable-item-hov" @click="doDeleteComment">
      <div class="menu-item">
        <p class="my-2">Delete</p>
      </div>
    </ion-item>

    <report
      :is-popover="true"
      @close="doReportComment"
      :report-entity-id="comment?.id"
      :report-entity-type="'comment'"
      :reported-user="getUser"
    >
      <ion-item v-if="!isEditable" class="item-container clickable-item-hov text-danger">
        <div class="menu-item">
          <p class="my-2">Report</p>
        </div>
      </ion-item>
    </report>
  </div>
</template>

<script lang="ts" setup>
import Report from '@/shared/components/Report.vue';
import { alertController } from '@ionic/core';
import { toast } from '@/shared/native';
import { deleteComment } from '@/shared/actions/comments';
import { authStore } from '@/shared/pinia-store/auth';
import constants from '@/shared/statics/constants';

const props = defineProps({
  instance: {
    type: Object,
  },
  comment: {
    type: Object,
  },
  isEditable: {
    type: Boolean,
  },
  isDeletable: {
    type: Boolean,
  },
});

const comment = toRef(props, 'comment');

const doEditComment = async () => {
  props.instance?.dismiss({
    operation: 'edit',
    commentId: comment.value?.id,
    parentId: comment.value?.parent_comment_id,
  });
};

const doReportComment = async () => {
  props.instance?.dismiss();
};

const getUser = computed(() => {
  const payload = {
    username:
      get(comment.value, 'commented_as.type') === constants.commentedAsTypes.USER
        ? get(comment.value, 'commented_as.username')
        : get(comment.value, 'commented_as.user'),
    id: comment.value?.commenter_user_id,
  };
  return payload;
});

const doDeleteComment = async () => {
  try {
    const alert = await alertController.create({
      cssClass: 'custom-alert ',
      message: `Are you sure you want to delete this comment?`,
      backdropDismiss: false,
      translucent: false,
      buttons: [
        {
          text: 'Cancel',
        },
        {
          text: 'Confirm',
          role: 'confirm',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();

    if (role === 'confirm') {
      deleteComment(comment.value?.id);

      setTimeout(() => {
        props.instance?.dismiss({
          operation: 'delete',
          commentId: comment.value?.id,
          parentId: comment.value?.parent_comment_id,
        });
      }, 500);

      toast.show('Comment deleted successfully', 'nonative', 'success');
    }
  } catch (err) {
    toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
  }
};
</script>

<style scoped lang="sass">
.bottom-button
  background-color: var(--ion-color-danger) !important
  color: white !important
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  display: flex
  align-items: center
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
</style>
