<template>
  <div
    ref="reactionsEl"
    @mouseover="gotIn"
    @scroll="gotIn"
    @mouseleave="gotOut"
    v-click-outside="dismiss"
    id="toolbox"
    class="toolbox"
  >
    <div class="reactions-types p-1">
      <div v-for="(_, key, index) in reactionsList" :key="index" class="reaction-type" @click.prevent="react(key)">
        <div :class="[userReaction && key === userReaction.reaction ? 'count-selected' : 'count']">
          {{ reactionCount(key) }}
        </div>
        <div class="mt-1 clickable" :class="[userReaction && key === userReaction.reaction ? 'name-selected' : 'name']">
          {{ reactionName(key) }}
        </div>
      </div>
      <div
        v-if="featureFlags.currency && showBox"
        class="d-flex justify-content-center mx-auto align-items-center curr-trans-box clickable-item-hov"
        @click="updateFeeds"
      >
        <img :src="currencyImage" style="position: relative; top: 20%" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { uiStore } from '@/shared/pinia-store/ui';
import { authStore } from '@/shared/pinia-store/auth';
import { toast } from '@/shared/native/toast';
import { featureFlags } from '@/shared/config/feature-flags';
import {
  blabReactionsNames,
  reactionsNames,
  visualReactionsNames,
  worldReactionsNames,
} from '@/shared/statics/reactions';

const reactionsList = computed(() => {
  if (type.value === 'character') {
    return reactionsNames;
  }

  if (type.value === 'world') {
    return worldReactionsNames;
  }

  if (type.value === 'post') {
    return blabReactionsNames;
  }

  if (type.value === 'visual') {
    return visualReactionsNames;
  }

  return reactionsNames;
});

const props = defineProps({
  type: {
    type: String,
    default: 'character',
  },
  instance: {
    type: Object,
  },
  userReaction: {
    type: Object,
  },
  reactions: {
    type: Object,
  },
  rID: {
    type: String,
  },
  showBox: {
    type: Boolean,
    default: false,
  },
});

const { currencyBoxImage, isAuthenticated } = authStore();
const reactions = toRef(props, 'reactions');
const instance = toRef(props, 'instance');
const rID = toRef(props, 'rID');
const type = toRef(props, 'type');
const reactionsEl = ref<any | null>(null);

const timer = ref<any>(null);

const react = (key: string | number, isInstant = false) => {
  if (!isAuthenticated.value) {
    toast.show('Sign in to react to content!', 'nonative', 'primary');
    return;
  }
  instance.value?.dismiss({ key, isInstant });
};
const updateFeeds = () => {
  instance.value?.dismiss('updated');
};

const gotIn = () => {
  clearTimeout(timer.value);
};

const gotOut = () => {
  timer.value = setTimeout(() => {
    dismiss();
  }, 200);
};

const dismiss = () => {
  instance.value?.dismiss();
};

const reactionName = (key: string | number) => {
  return reactionsList.value[key];
};

const reactionCount = (key: string | number): number => {
  return reactionsMap.value[key] || 0;
};

const reactionsMap = computed(() => {
  const reactionss = omit({ ...reactions.value }, ['total_count', 'ordering']);
  return reactionss as { [key: string]: number };
});

const scrollEl = ref<HTMLElement | null>(null);
const currScroll = ref(0);

const cb = (e: any) => {
  try {
    if (reactionsEl.value?.parentElement?.parentElement) {
      reactionsEl.value?.parentElement?.parentElement.setAttribute(
        'style',
        `--offset-y: ${-1 * (e.target.scrollTop - currScroll.value)}px`
      );
    }

    const { isMobSmallScreen } = useWindowSize();

    if (!isMobSmallScreen.value) {
      setTimeout(() => {
        dismiss();
      }, 1000);
    }
  } catch (error) {}
};

onMounted(() => {
  try {
    scrollEl.value = document.querySelector('.layout-scroll-area');

    if (scrollEl.value) {
      currScroll.value = scrollEl.value.scrollTop;
      scrollEl.value?.addEventListener('scroll', cb);
    }

    timedDismiss();
  } catch (error) {}
});

const currencyImage = computed(() => {
  return isEmpty(get(currencyBoxImage.value, 'box_image'))
    ? '/idlebox_square.png'
    : get(currencyBoxImage.value, 'box_image');
});
const timedDismiss = () => {
  clearTimeout(timer.value);
  timer.value = setTimeout(() => {
    const { isHoveringOverReactIcon } = uiStore();
    if (!isHoveringOverReactIcon.value || isHoveringOverReactIcon.value !== rID.value) dismiss();
    else timedDismiss();
  }, 1000);
};

onBeforeUnmount(() => {
  try {
    if (scrollEl.value) {
      scrollEl.value.removeEventListener('scroll', cb);
    }
  } catch (error) {}
});
</script>

<style scoped lang="sass">
.curr-trans-box
  color: black !important
  width: 50px
  height: 50px
  position: relative
.toolbox
  border-radius: 9px
  overflow: hidden
  padding: 6px
.reactions-types
  width: 100% !important
  height: 133px !important
  overflow: auto
  display: flex
  grid-gap: 6px
  flex-wrap: wrap
  &::-webkit-scrollbar
    display: block !important
  &::-webkit-scrollbar
    height: 4px
    width: 4px
  &::-webkit-scrollbar-track
    border-radius: 5px
  &::-webkit-scrollbar-thumb:horizontal
    background: var(--ion-color-primary)
  &::-webkit-scrollbar-thumb:hover
    background: #555
  .reaction-type
    color: #7f10b3 !important
    width: calc(50% - 3px) !important
    position: relative !important
    text-align: center
    .count
      height: 20px
      font-size: 16px !important
      color: #7f10b3 !important
      user-select: none !important
    .count-selected
      height: 20px
      font-size: 16px
      color: #3dc2ff !important
      user-select: none !important
    .name
      background-color: #7f10b3 !important
      color: #FFF !important
      border-radius: 13px
      height: 35px
      align-items: center
      justify-content: center
      display: flex
      line-height: 1.05rem
      font-size: 14px
    .name-selected
      background-color: #3dc2ff !important
      color: #FFF !important
      border-radius: 13px
      height: 35px
      align-items: center
      justify-content: center
      display: flex
      line-height: 1.05rem
      font-size: 14px
</style>
