import { sendAnalyticsEvent } from '@/shared/services/analytics';
import constants from '@/shared/statics/constants';
import { Paging, MarketplaceListing } from '@/shared/types/static-types';
import { chAxios } from '@/shared/lib/axios';

export async function createPostlisting(listData: any) {
  try {
    const { data } = await chAxios().post(`/marketplace/listings/`, listData);
    return data;
  } catch (e) {
    throw e;
  }
}
export async function getpostlisting(id: string) {
  const url = `/marketplace/listings/${id}/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function continueMPOrder(payload: { email: string; listing: string; name: string; text: string }) {
  try {
    const { data } = await chAxios().post(`/marketplace/listings/continue-order/`, payload);
    sendAnalyticsEvent('Marketplace order request initiated');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function editListing(id: string, listingData: MarketplaceListing) {
  const url = `/marketplace/listings/${id}/`;
  try {
    const { data } = await chAxios().patch(url, listingData);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateMPListingStatus(id: string, is_active: boolean) {
  try {
    const { data } = await chAxios().patch(`/marketplace/listings/${id}/update-status/`, { is_active });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getMarketplaceListings(page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/marketplace/listings/`;
  try {
    const { data } = await chAxios().get(url, { params: { page, page_size: pageSize, ...params } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function GetListingFromSlug(slug: string) {
  const url = `/marketplace/listings/from-slug/`;
  try {
    const { data } = await chAxios().get(url, { params: { slug } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteMarketplaceListing(id: string) {
  const url = `/marketplace/listings/${id}`;
  try {
    const { data } = await chAxios().delete(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getUserMarketplaceListings(user: string, page = 1, pageSize = constants.defaultPageSize) {
  const url = `/marketplace/listings/from-user/`;
  try {
    const { data } = await chAxios().get(url, { params: { user, page, page_size: pageSize } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getNextPage(paging: Paging) {
  const url = paging.next;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}
