import { MutationTree } from 'vuex';
import { UserProfile } from './../../../types/static-types';

import { UsersState } from './types';

export const mutations: MutationTree<UsersState> = {
  RESET_CONTENT: (state) => {
    state.users = {};
  },
  SET_USER: (state, user) => {
    if (!user) return;
    if (user.is_following) {
      state.follows[user.is_following.id] = user.is_following;
    }
    state.users[user.id] = user;
  },
  SET_PROFILE: (state, userProfile) => {
    if (!userProfile) return;
    state.profile = userProfile;
  },
  UPDATE_PROFILE: (state, userProfile) => {
    if (!userProfile) return;

    state.profile = {
      ...state.profile,
      ...userProfile,
    };
  },
  RESET_PROFILE: (state) => {
    state.profile = {} as UserProfile;
  },
  SET_USERS: (state, users) => {
    /*
     * Adds user objects to the object store.
     * users: an object of user objects, keyed by the user's ID.
     */
    forOwn(users, (user, id) => {
      if (user.is_following) state.follows[user.is_following.id] = user.is_following;
      try {
        if (state.users[id] && typeof state.users[id].si === 'boolean') user.si = state.users[id].si;
      } catch (e) {}
      state.users[id] = user;
    });
  },
  SET_IDS: (state, { ids = [], reset = true }) => {
    /*
     * Appends to or replaces the list of users to show (by ID)
     * ids: list of user IDs in order to show.
     * reset: boolean. Set this to false when getting the 2nd+ page of a query
     */
    if (reset) {
      state.ids = ids;
    } else {
      const newIDs = state.ids.concat(ids);
      state.ids = newIDs;
    }
  },
  SET_PAGING: (state, paging) => {
    state.lastPaging = paging;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_FOLLOWS: (state, follows) => {
    forOwn(follows, (follow, id) => {
      state.follows[id] = follow;
    });
  },
  ADD_FOLLOW: (state, follow) => {
    const targetUser = find(state.users, ({ id }) => id === follow.followingUserId);
    if (targetUser) {
      state.users[targetUser.id].is_following = follow;
    }
  },
  REPLACE_FOLLOW: (state, { follow }) => {
    const controlledFollowingUserId = follow.followingUserId;
    const newStateFollows: any = Object.values(state.follows)
      .filter(({ followingUserId }: any) => controlledFollowingUserId !== followingUserId)
      .reduce((total: any, current: any) => ({ ...total, [current.id]: current }), {});
    newStateFollows[follow.id] = follow;
    state.follows = newStateFollows;
  },
  REMOVE_FOLLOW: (state, follow) => {
    const targetUser = find(state.users, ({ id }) => id === follow.followingUserId);
    const foundFollow = find(
      state.follows,
      ({ followingUserId, userId }) => followingUserId === follow.followingUserId && userId === follow.userId
    );
    if (targetUser) {
      state.users[targetUser.id].is_following = false;
    }
    if (foundFollow) {
      delete state.follows[foundFollow.id];
    }
  },
  SET_CURRENT_PROFILE_USER_ID: (state, userId) => {
    state.currentProfileUserId = userId;
  },
  RESET: (state) => {
    state.ids = [];
    state.follows = {};
    state.lastPaging = {};
    state.follows = {};
    state.users = {};
  },
  BLOCK_USER: (state, userId) => {
    if (state.users[userId]) {
      state.users[userId].is_blocked = true;
    }
  },
  UNBLOCK_USER: (state, userId) => {
    if (state.users[userId]) {
      state.users[userId].is_blocked = false;
    }
  },
  INCREASE_FOLLOWERS_COUNT: (state, targetUserId) => {
    const currentUser = find(state.users, (user) => targetUserId === user.id);
    if (currentUser) {
      const newCount = currentUser.follower_count + 1;
      state.users[targetUserId].follower_count = newCount;
    }
  },
  DECREASE_FOLLOWERS_COUNT: (state, targetUserId) => {
    const currentUser = find(state.users, (user) => targetUserId === user.id);
    if (currentUser) {
      const newCount = currentUser.follower_count - 1 < 0 ? 0 : currentUser.follower_count - 1;
      state.users[targetUserId].follower_count = newCount;
    }
  },
};
