<template>
  <div
    v-for="(badge, index) in badges"
    :key="index"
    :class="{
      'lock flex-column p-2 my-2': showUnlockDescription && subscriptionLevel !== index + 1 && subscriptionLevel <= 1,
      hide: showUnlockDescription && (subscriptionLevel === index + 1 || subscriptionLevel > 1),
    }"
  >
    <div
      :class="{
        'd-flex': showUnlockDescription,
        'active-badge clickable-item-hov': selectedBadgeIndex === index && showSubBadge && !noBorder,
        'clickable-item-hov': showSubBadge,
      }"
      @click="action(index)"
    >
      <div
        class="prf-badge-lock"
        :class="{
          'small-badge-lock': showSubBadge,
          'badge-content': showUnlockDescription,
          'mr-1': !showSubBadge && !showSmallBadge,
          'small-user-prf-badge': showSmallBadge,
          'prf-head-badge': prfHeadBg,
        }"
      >
        <div v-if="showSubBadge">
          <SubPlanBadge
            :plan="subLevelMap[index + 1]"
            class="ml-2 sub-badge-icon"
            @click="openSubscribeModal"
            :class="{ hide: subscriptionLevel === index + 1 || subscriptionLevel > 1 }"
          />
        </div>
        <i
          class="ti-lock"
          v-if="subscriptionLevel <= 1 && subscriptionLevel !== index + 1 && !get(badge, 'item.image_url')"
        />
        <img class="user-badge-img" :src="get(badge, 'item.image_url')" />
      </div>
      <div class="d-flex align-items-center flex-wrap" v-if="showUnlockDescription">
        To unlock the<strong class="mx-1">{{ badge.textBeforeIcon }} </strong>, earn the

        <inline-svg class="medal-icon mx-1" :src="badge.svg" />

        <div style="color: #ff9c33" class="mr-1">{{ badge.textAfterIcon }} Achievement</div>
        or upgrade to

        <SubPlanBadge
          @click="emits('open')"
          :plan="badge.plan === 'plus' ? subLevelMap[1] : subLevelMap[2]"
          class="ml-2 clickable-item-hov"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import constants from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { banOutline } from 'ionicons/icons';
import { openSubscribeModal } from '@/shared/utils/modals';
import SubPlanBadge from '@/shared/components/SubPlanBadge.vue';
const { subscriptionLevel } = authStore();

const subLevelMap = constants.subscriptionLevelsTextMap;
const props = defineProps({
  badges: {
    type: Array,
    default: [],
  },
  prfHeadBg: {
    type: Boolean,
    default: false,
  },
  noBorder: {
    type: Boolean,
    default: false,
  },
  showSmallBadge: {
    type: Boolean,
    default: false,
  },
  selectedBadge: {
    type: Object,
    default: {},
  },
  showSubBadge: {
    type: Boolean,
    default: false,
  },
  showUnlockDescription: {
    type: Boolean,
    default: false,
  },
});

const selectedBadgeIndex = ref(0);
const showSubBadge = toRef(props, 'showSubBadge');
const badges = toRef(props, 'badges') as any;
const emits = defineEmits(['selected', 'open']);

const action = (index: any) => {
  selectedBadgeIndex.value = index;
  emits('selected', selectedBadgeIndex.value + 1);
};
</script>
<style lang="sass" scoped>
.user-badge-img
  border-radius: 26px
  height: 100%
.hide-cursor
  pointer-events: none
.hide
  display: none
.active-badge
  border: 3px solid var(--ion-color-primary)
  border-radius: 35px
.sub-badge-icon
  position: absolute
  bottom: -1px
  right: -25px
.medal-icon
  width:20px
  height: 20px
  border: 2px solid #ff9c33
  border-radius: 10px
.badge-content
  height: 45px !important
  width: 45px !important
  min-width:45px
  max-width: 45px
  font-size: 20px
  border-radius: 25px !important
  border: 3px solid #c5bbbb

.lock
  border: 2px solid white
  border-radius: 30px
  display: flex
  align-content: center


.small-badge-lock
  height: 55px !important
  width: 55px !important
  font-size: 24px
  border-radius: 30px !important
  border: 3px solid #c6c8d6
.small-user-prf-badge
  height: 14px !important
  width: 15px !important
  font-size: 9px !important
  border-radius: 0px !important
  border: none !important
  background: none !important
.prf-head-badge
  border-radius: 0px !important
  border: none !important
  background: none !important
.prf-badge-lock
  border-radius: 20px
  height: 25px
  width: 25px
  position: relative
  background: #7a7e9b
  display: flex
  align-items: center
  justify-content: center
  color: white
</style>
