<template>
  <div :class="[comment.parent_comment_id ? 'ml-auto reply' : '']" :id="'cmnt-' + comment.id">
    <div class="d-flex">
      <router-link :to="posterProfileLink" class="img-link" :class="{ 'is-disabled': ongoingBoarding }">
        <CommenterImage
          class="clickable"
          :type="comment.commented_as.type === commentedAsTypes.USER ? 'user' : 'character'"
          :src="resizeUpload(getProfilePicture(comment), '80x80')"
          :ring="getProfilePictureRing(comment)"
          :ringWidth="77"
          :top="-8"
          :left="-8"
          size="cm"
          :setRingPosition="true"
        />
      </router-link>

      <div v-if="!isEditing" class="card mb-3 ml-2 comment-text bg-light" :class="!!comment?.h ? 'cmnt-h' : ''">
        <div @mouseover="hover = true" @mouseleave="hover = false">
          <div class="card-body d-flex p-3">
            <div class="w-100">
              <div class="d-flex flex-wrap">
                <router-link :to="posterProfileLink" :class="{ 'is-disabled': ongoingBoarding }">
                  <strong
                    class="clickable d-flex align-items-center"
                    :class="{
                      'text-color-username':
                        usernameColor(comment.commented_as) &&
                        !usernameColor(comment.commented_as).startsWith('#') &&
                        comment.commented_as.type === commentedAsTypes.USER,
                      'text-black':
                        !usernameColor(comment.commented_as) || comment.commented_as.type !== commentedAsTypes.USER,
                      'bold-username':
                        usernameColor(comment.commented_as) && comment.commented_as.type === commentedAsTypes.USER,
                    }"
                    :style="
                      !usernameColor(comment.commented_as) || comment.commented_as.type !== commentedAsTypes.USER
                        ? ''
                        : usernameColor(comment.commented_as).startsWith('#')
                        ? `color: ${usernameColor(comment.commented_as)} !important`
                        : `background-image: ${usernameColor(comment.commented_as)} !important`
                    "
                  >
                    <span class="mr-1">
                      {{
                        truncate(
                          comment.commented_as.type === commentedAsTypes.USER
                            ? `@${comment.commented_as.username}`
                            : get(comment.commented_as, 'info.name', 'Unknown'),
                          { length: 22 }
                        )
                      }}
                    </span>
                    <ProfileBadge
                      :badges="get(comment.commented_as.customize_profile, 'badges') || []"
                      :show-small-badge="true"
                    />
                  </strong>
                </router-link>

                <div class="ml-auto d-flex">
                  <small>{{ formatDescDateTime(comment.created) }}</small>
                  <ion-button
                    v-if="hasAction"
                    fill="clear"
                    href="#"
                    class="mr-n2 ml-2 d-flex align-items-center more-btn"
                    @click.prevent="openDropDown"
                  >
                    <i class="ti-more-alt" />
                  </ion-button>

                  <ion-button
                    v-else
                    fill="clear"
                    class="mr-n2 ml-2 d-flex align-items-center more-btn"
                    @click="openDropDown"
                  >
                    <i class="ti-more-alt" />
                  </ion-button>
                </div>
              </div>
              <div class="mt-2 px-0">
                <span v-html="sanitizeHtml(comment.text.replace(/(?:\r\n|\r|\n)/g, '<br>'))" />
              </div>

              <div v-if="isAuthenticated" class="mt-2 d-flex justify-content-between">
                <div class="d-flex justify-content-start">
                  <sub v-if="comment.is_edited" class="text-muted text-sm py-3"><i>(Edited)</i></sub>
                </div>
                <div class="mt-2 d-flex justify-content-end">
                  <ion-button
                    fill="clear"
                    href="#"
                    class="mr-n2 mb-n2 d-flex align-items-center"
                    @click.prevent="onReply"
                  >
                    <i class="ti-comment mr-1" /><span v-if="!isMobSmallScreen">Reply</span>
                  </ion-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="w-100 ml-2 mb-2">
        <PostComment
          :target="target"
          :commenting-on-type="commentingOnType"
          :is-edit="true"
          :comment-id="comment.id"
          :existing-text="comment.text"
          @posted="onCommentUpdate"
          @canceled="onUpdateCancel"
        />
      </div>
    </div>

    <div v-if="isReplying" class="mb-2">
      <PostComment
        :target="target"
        :commenting-on-type="commentingOnType"
        :is-parent="!!comment.parent_comment_id"
        :parent-comment-id="comment.parent_comment_id || comment.id"
        :reply-to-mention-name="replyToMentionName"
        :reply-to-comment="comment.id"
        @posted="onReplyPosted"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Action } from 's-vuex-class';
import { formatDescDateTime } from '@/shared/utils/dateTime';
import constants from '@/shared/statics/constants';
import PostComment from '@/shared/components/PostComment.vue';
import { Character, User } from '@/shared/types/static-types';
import namespace from '@/shared/store/namespace';
import { authStore } from '@/shared/pinia-store/auth';

import CommenterImage from '@/shared/components/storage/CommenterImage.vue';
import { popovers } from '@/shared/native/popovers';
import CommentPopover from './popover.vue';
import Report from '@/shared/components/Report.vue';
import { sanitizeHtml } from '@/shared/utils/html';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { resizeUpload } from '@/shared/utils/upload';
import { onboardingStore } from '~/shared/pinia-store/onboarding';

@Options({
  name: 'CommentsListItem',
  components: { PostComment, CommenterImage, Report, ProfileBadge },
})
export default class CommentsListItem extends Vue {
  @Prop() comment!: any;
  @Prop() target!: Character | User;
  @Prop({ required: true }) commentingOnType!: string; // character, user

  resizeUpload = resizeUpload;
  sanitizeHtml = sanitizeHtml;
  private hasScrolled = false;

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }
  public get ongoingBoarding() {
    const { ongoingOnboarding } = onboardingStore();
    return ongoingOnboarding.value;
  }

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  @Action('postComment', { namespace: namespace.CharactersModule })
  public postComment!: Function;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public usernameColor(user: any) {
    return user?.customize_profile?.username_color || '';
  }

  public async openDropDown(ev: any) {
    const value = await (
      await popovers.open(ev, CommentPopover, {
        comment: this.comment,
        target: this.target,
        isEditable: this.isEditable,
        isDeletable: this.isDeletable,
      })
    ).onDidDismiss();

    if (value?.data?.operation === 'edit') {
      this.isEditing = true;
    }

    if (value?.data?.operation === 'delete') {
      this.$emit('commentDeleted', { commentId: this.comment.id, parentId: this.comment.parent_comment_id });
    }
  }

  hover = false;
  isReplying = false;
  replyToMentionName: string | null = null;
  isEditing = false;
  isdeleting = false;
  isSaving = false;

  formatDescDateTime = formatDescDateTime;
  get = get;
  commentedAsTypes = constants.commentedAsTypes;

  public get targetOwnerId() {
    return (this.target as any)?.user || this.target?.id;
  }

  public get isEditable() {
    return this.comment?.commenter_user_id === this.user.id;
  }

  public get isDeletable() {
    return this.comment?.commenter_user_id === this.user.id || this.targetOwnerId === this.user.id;
  }

  public get hasAction() {
    return this.isEditable || this.isDeletable;
  }

  public onReply() {
    this.replyToMentionName =
      this.comment.commented_as.type === this.commentedAsTypes.USER
        ? this.comment.commented_as.username
        : get(this.comment.commented_as, 'info.name', 'Unknown');
    this.isReplying = !this.isReplying;
  }

  public get isuser() {
    try {
      return this.comment.commenter_user_id === this.user.id;
    } catch (error) {
      return false;
    }
  }

  public getProfilePicture(comment: any) {
    if (comment.commented_as.type === this.commentedAsTypes.CHARACTER) {
      return get(comment.commented_as, 'info.cropProfilePicture', '/empty.png');
    } else if (comment.commented_as.type === this.commentedAsTypes.USER) {
      return comment.commented_as.profile_picture_url;
    }
    return '/images/user.jpg';
  }

  public getProfilePictureRing(comment: any) {
    if (comment.commented_as.type === this.commentedAsTypes.CHARACTER) {
      return get(comment.commented_as, 'profile_ring.image');
    } else if (comment.commented_as.type === this.commentedAsTypes.USER) {
      return get(comment.commented_as, 'customize_profile.profile_ring.image');
    }
  }

  public edit() {
    this.isEditing = true;
  }

  public onCommentUpdate() {
    this.isEditing = false;
    this.comment.is_edited = true;
    this.$nextTick(() => {
      this.$emit('posted');
    });
  }

  public onUpdateCancel() {
    this.isEditing = false;
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 420;
  }

  public onReplyPosted() {
    this.isReplying = false;
    this.replyToMentionName = null;
    this.$nextTick(() => {
      this.$emit('posted');
    });
  }

  public get posterProfileLink() {
    return this.comment.commented_as.type === this.commentedAsTypes.USER
      ? { name: 'profile', params: { username: this.comment.commented_as.username } }
      : { name: 'character-profile-new', params: { slug: this.comment.commented_as.slug } };
  }
}
</script>

<style lang="sass" scoped>
.more-btn
  width: 25px
  height: 25px
  margin-top: -5px
  --border-radius: 50px
  --padding-start: 0
  --padding-end: 0
.comment-poster-img
  width: 60px
  height: 60px
  border-radius: 40px
  border: solid gray 0.1px
  object-fit: cover

.comment-text
  width: calc(100% - 50px - 0.5rem)

.reply
  width: 75% !important
  @media(max-width: 500px)
    width: 90% !important

.cmnt-h
  outline: 2px solid #6a64ff !important
</style>
