export const useSafeEnv = () => {
  const hostUrlFromEnv = computed(() => {
    try {
      return process.env.HOST_URL || '';
    } catch (error) {
      return '';
    }
  });

  const hostUrlFromPublic = computed(() => {
    try {
      const {
        public: { hostUrl },
      } = useRuntimeConfig();

      return hostUrl;
    } catch (error) {
      return '';
    }
  });

  const hostUrl = computed(() => hostUrlFromEnv.value || hostUrlFromPublic.value);

  // --------

  const envFromEnv = computed(() => {
    try {
      return process.env.NODE_ENV || '';
    } catch (error) {
      return '';
    }
  });

  const envFromPublic = computed(() => {
    try {
      const {
        public: { env },
      } = useRuntimeConfig();

      return env;
    } catch (error) {
      return '';
    }
  });

  const env = computed(() => envFromEnv.value || envFromPublic.value);

  // --------

  const apiUrlFromComposable = computed(() => {
    try {
      const {
        public: { apiUrl },
      } = useRuntimeConfig();

      return apiUrl;
    } catch (error) {
      return '';
    }
  });

  const apiUrlFromEnv = computed(() => {
    try {
      return process.env.API_URL || '';
    } catch (error) {
      return '';
    }
  });

  const apiUrl = computed(() => apiUrlFromComposable.value || apiUrlFromEnv.value);

  // --------

  const appTypeFromComposable = computed(() => {
    try {
      const {
        public: { appType },
      } = useRuntimeConfig();

      return appType;
    } catch (error) {
      return '';
    }
  });

  const appTypeFromEnv = computed(() => {
    try {
      return process.env.APP_TYPE || '';
    } catch (error) {
      return '';
    }
  });

  const appType = computed(() => appTypeFromComposable.value || appTypeFromEnv.value);

  // --------

  const crispWebsiteIDFromComposable = computed(() => {
    try {
      const {
        public: { crispWebsiteID },
      } = useRuntimeConfig();

      return String(crispWebsiteID);
    } catch (error) {
      return '';
    }
  });

  const crispWebsiteIDFromEnv = computed(() => {
    try {
      return process.env.CRISP_WEBSITE_ID || '';
    } catch (error) {
      return '';
    }
  });

  const crispWebsiteID = computed(() => crispWebsiteIDFromComposable.value || crispWebsiteIDFromEnv.value);

  // --------

  return { hostUrl, apiUrl, env, appType, crispWebsiteID };
};
