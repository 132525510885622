<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header class="safe-top" translucent>
      <ion-toolbar class="toolbar">
        <div class="py-3 px-3">
          <div class="d-flex justify-content-between">
            <ion-title class="title">Personality Evaluator:</ion-title>

            <div>
              <ion-buttons slot="end">
                <ion-button class="close-button" @click="dismissModal()">
                  <i class="ti-close" style="color: #8e8880" />
                </ion-button>
              </ion-buttons>
            </div>
          </div>
          <div>
            <div v-if="get(insights, 'quiz.is_char_based')" class="py-1 name">
              <router-link
                :to="{ name: 'character-profile-new', params: { slug: get(insights, 'character.slug') } }"
                class="clickable"
                @click="dismissModal"
              >
                {{ get(insights, 'character.info.name') }}
              </router-link>
            </div>
            <div class="ques-ans mt-2">Based on <strong> questions </strong> you've answered!</div>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content">
      <div v-for="(insight, index) of insights.extra" :id="`report-${index}`" :key="index" class="pb-3 mt-3">
        <div class="d-flex">
          <div class="px-3 py-2 day-insight">{{ insightQuizInfo[index].title }}</div>
          <div class="mx-2 py-2 mt-1">
            <small>From {{ formatSlashDate(insights.created) }}</small>
          </div>
        </div>

        <div class="px-3 py-3 mx-1">
          <div v-if="!isEmpty(get(insight.common_score_outcome, 'writeup'))" class="px-2 py-2 desc">
            <div v-html="sanitizeHtml(get(insight.common_score_outcome, 'writeup'))" class="desc-text" />
          </div>
          <hr v-if="!isEmpty(get(insight.common_score_outcome, 'writeup'))" style="background: #b9b9b9" class="mt-4" />
          <div v-if="!isEmpty(get(insight.common_score_outcome, 'traits'))">
            <div class="sub-title">
              Suggested Traits {{ get(insights, 'quiz.is_char_based') ? 'to Add to Profile' : '' }}
            </div>

            <quizzes-traits-card
              :traits="get(insight.common_score_outcome, 'traits')"
              :is-char-based="get(insights, 'quiz.is_char_based')"
              @added="(traitIndex) => addToProfile(traitIndex, '1', index)"
            />

            <hr style="background: #b9b9b9" class="mt-4" />
          </div>
          <div v-if="!isEmpty(get(insight.common_score_outcome, 'custom'))">
            <div v-for="(custom, customIndex) in get(insight.common_score_outcome, 'custom')" :key="customIndex">
              <div class="py-2" style="font-weight: bold; font-size: 18px; color: var(--ion-color-primary)">
                {{ custom.title }}:
              </div>
              <div v-html="sanitizeHtml(custom.value)" class="px-2 py-2 desc" />
            </div>
          </div>
          <hr
            v-if="!isEmpty(get(insight.common_score_outcome, 'custom'))"
            style="background: #b9b9b9"
            class="mt-3 mb-3"
          />
          <div v-if="!isEmpty(get(insight.score_outcome, 'writeup'))" class="px-2 py-2 desc">
            <div v-html="sanitizeHtml(get(insight.score_outcome, 'writeup'))" />
          </div>

          <div v-if="!isEmpty(get(insight.score_outcome, 'traits'))">
            <div class="sub-title mt-2">
              Suggested Traits {{ get(insights, 'quiz.is_char_based') ? 'to Add to Profile' : '' }}
            </div>
            <quizzes-traits-card
              :traits="get(insight.score_outcome, 'traits')"
              :is-char-based="get(insights, 'quiz.is_char_based')"
              @added="(traitIndex) => addToProfile(traitIndex, null, index)"
            />

            <hr style="background: #b9b9b9" :class="get(insights, 'quiz.is_char_based') ? 'mt-4' : 'mt-3'" />
          </div>
          <div v-if="!isEmpty(get(insight.score_outcome, 'custom'))">
            <div v-for="(custom, tScoreIndex) in get(insight.score_outcome, 'custom')" :key="tScoreIndex">
              <div class="py-2" style="font-weight: bold; font-size: 18px; color: var(--ion-color-primary)">
                {{ custom.title }}:
              </div>
              <div v-html="sanitizeHtml(custom.value)" class="px-2 py-2 desc" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center w-100">
          <ion-buttons>
            <ion-button fill="primary" class="done-btn" @click="dismissModal"> Done </ion-button>
          </ion-buttons>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import { addCharTraitsToProfile } from '@/shared/actions/quizzes';
import { formatSlashDate } from '@/shared/utils/dateTime';

import { sanitizeHtml } from '@/shared/utils/html';
import QuizzesTraitsCard from '@/shared/components/QuizzesTraitsCard.vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  insights: {
    type: Object,
    default: () => {},
  },
  insightIndex: {
    type: String,
    default: '',
  },
});
const emits = defineEmits(['close', 'fetch', 'load']);
const isOpen = toRef(props, 'isOpen');
const insights: any = toRef(props, 'insights');
const insightIndex = toRef(props, 'insightIndex');
const route = useRoute();
watch(isOpen, () => {
  if (isOpen.value && insightIndex.value) {
    setTimeout(() => {
      nextTick(() => {
        const document = useDocument();
        document.value
          .getElementById(`report-${insightIndex.value}`)
          ?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
      });
    }, 100);
  }
});

const dismissModal = () => {
  emits('close');
};

const insightQuizInfo = computed(() => {
  if (!isEmpty(insights.value)) {
    return insights.value.quiz_info;
  }
});

const addToProfile = async (index: any, commonScore: any, dayVal: any) => {
  !isEmpty(commonScore)
    ? await addCharTraitsToProfile({
        trait_index: index,
        common: commonScore,
        log_id: insights.value.id,
        day: dayVal + 1,
      })
    : await addCharTraitsToProfile({
        trait_index: index,
        log_id: insights.value.id,
        day: dayVal + 1,
      });

  !isEmpty(commonScore)
    ? (insights.value.extra[dayVal].common_score_outcome.traits[index].is_added =
        !insights.value.extra[dayVal].common_score_outcome.traits[index].is_added)
    : (insights.value.extra[dayVal].score_outcome.traits[index].is_added =
        !insights.value.extra[dayVal].score_outcome.traits[index].is_added);

  if (route.name === 'character-profile-new') {
    emits('load');
  }
};
</script>
<style lang="sass" scoped>
.safe-top
  padding-top: var(--safe-ios-margin)
  background: #FFF
.done-btn
  --background: var(--ion-color-primary) !important
  height: 30px
  --border-radius: 7px !important
  width: 90px
  --background-hover: none !important

ion-button::part(native)
  color: white !important
.desc-text
  //margin-bottom: -10px !important
.text
  @media(max-width:500px)
    display: flex
    flex-direction: column
.desc
  background: white
  border-radius: 10px
.dark .desc
  background: #010123 !important
  color: white !important
.day-insight
  background: var(--ion-color-primary)
  color: white
  font-size: 20px
  font-weight: bold
  border-top-right-radius: 20px
  border-bottom-right-radius: 20px

.name
  text-decoration: underline
  color: var(--ion-color-primary)
  font-weight: bold
.icon
  font-size: 12px
  font-weight: bolder
  @media(max-width:550px)
    font-size: 11px
.ques-ans
  border: 2px solid
  border-radius: 22px
  width: 319px
  max-width: 100%
  text-align: center
.card
  background: white
  height: 100%
  width: 100%
.dark .toolbar
  --background: #17074c !important
.modal-inner-content
  background: #e6e6e6
  max-height: calc(100dvh - 240px)
  overflow: auto
  overflow-x: hidden

.modal-big
  --width: 900px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.sub-title
  font-size: 18px
  font-weight: bold
  color: var(--ion-color-primary)
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  //text-align: center
  padding-left: 1px
  padding-right: 0px
</style>
