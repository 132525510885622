import { authStore } from '@/shared/pinia-store/auth';
import { defineStore, storeToRefs } from 'pinia';
import { NotSignedInSection } from '@/shared/types/ui';
import { messagesMap } from '@/shared/statics/constants';

export interface IUIState {
  _notSignedInSection: NotSignedInSection | null;
  _isHoveringOverReactIcon: string | null;
  _isCreateOpen: boolean;
  _isMyCreationsOpen: boolean;
  _isReplying: boolean;
  myCreationsEv: MouseEvent | null;
}

export const useUI = defineStore('ui', {
  persist: { enabled: true },
  state: (): IUIState => ({
    _notSignedInSection: null,
    _isHoveringOverReactIcon: null,
    _isCreateOpen: false,
    _isMyCreationsOpen: false,
    _isReplying: false,
    myCreationsEv: null,
  }),
  actions: {
    setIsReplying(val: boolean) {
      this._isReplying = val;
    },
    handleShowNotSignedInSection(route: string) {
      const routesToNotShow = [
        'login',
        'logout',
        'signup',
        'dmca',
        'signup-invite0',
        'signup-invite',
        'forget-password',
        'reset-password',
        'terms',
        'upgrade-inter',
        'checkout-inter',
        'privacy',
        'cookies',
        'home',
        'public-home',
      ];
      const notToShowRoute = routesToNotShow.includes(route);
      const data = messagesMap[route] || messagesMap['*'];
      const { isAuthenticated } = authStore();
      const show = !!data && !notToShowRoute && !isAuthenticated.value;

      setTimeout(() => {
        if (!show) {
          this.hideNotSignedInSection();
          return;
        }

        this.showNotSignedInSection(data);
      }, 1000);
    },
    showNotSignedInSection(data: NotSignedInSection) {
      this._notSignedInSection = data;
    },
    hideNotSignedInSection() {
      this._notSignedInSection = null;
    },
    setHoverOverReactOn(rID: string) {
      this._isHoveringOverReactIcon = rID;
    },
    setHoverOverReactOff() {
      this._isHoveringOverReactIcon = null;
    },
    toggleCreate() {
      this._isCreateOpen = !this._isCreateOpen;
    },
    toggleMyCreations(ev: MouseEvent) {
      this._isMyCreationsOpen = !this._isMyCreationsOpen;
      this.myCreationsEv = ev;
    },
  },
  getters: {
    isReplying(): any {
      return this._isReplying;
    },
    notSignedInSection(): any {
      return this._notSignedInSection;
    },
    isHoveringOverReactIcon(): string | null {
      return this._isHoveringOverReactIcon;
    },
    isCreateOpen(): boolean {
      return this._isCreateOpen;
    },
    isMyCreationsOpen(): boolean {
      return this._isMyCreationsOpen;
    },
    myCreationsStyles(): { left: string; top: string } {
      if (!this.myCreationsEv) return { left: '', top: '' };

      const { pageX, pageY } = this.myCreationsEv;

      const finalX = `${pageX}px`;
      const finalY = `${pageY}px`;

      return {
        left: finalX,
        top: finalY,
      };
    },
  },
});

export const uiStore = () => {
  const store = useUI();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
