<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar>
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-title class="title">Locked Feature!!</ion-title>
        </div>
        <ion-buttons slot="end">
          <ion-button class="close-button" @click="dismissModal()">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content">
      <div class="pb-3 px-4 mt-3">
        <div class="h-100">
          <div v-if="isOpen">
            <div class="mt-3 py-3">
              <div class="d-flex justify-content-center" style="text-align: center">
                Create {{ getCharsCount }} more character(s) to customize your character’s profile!
              </div>
              <div class="d-flex justify-content-center mt-1">
                <a
                  :href="$router.resolve({ name: 'character-creator' }).href"
                  class="mx-1"
                  @click="openCharacterEditor()"
                  >CLICK
                </a>
                <div>here to make another character</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { IonModal, IonContent, IonTitle } from '@ionic/vue';

@Options({
  name: 'LockStylizeCharacterModal',
  components: {
    IonModal,
    IonContent,
    IonTitle,
  },
})
export default class LockStylizeCharacterModal extends Vue {
  @Prop({ default: false }) isOpen!: boolean;
  @Prop() charsCount!: number;

  public dismissModal() {
    this.$emit('dismissModal');
  }

  public get getCharsCount() {
    const count = 2 - this.charsCount;

    if (count < 0) return 0;

    return count;
  }

  @Watch('getCharsCount')
  getCharsCountChanged(val: boolean) {
    if (!val) {
      this.$emit('dismissModal');
    }
  }

  public openCharacterEditor() {
    this.$emit('dismissModal');
  }
}
</script>
<style lang="sass" scoped>
.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: calc(85vh - 45px)
  overflow: auto
  overflow-x: hidden
.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 18px
  text-align: center
  padding-left: 55px
  padding-right: 0px
</style>
