export const useChAsyncData = async (cb: (context?: any) => Promise<any>) => {
  if (process.server) {
    await useAsyncData(async (context) => {
      await cb(context);
    });

    return;
  }
  onMounted((context?: any) => cb(context));
};
