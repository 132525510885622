import { toastController } from '@ionic/vue';
import { IonicToastPosition, ToastColor, ToastPosition } from '@/shared/types/toast';

const DEFAULT_DURATION = 3500;
const DEFAULT_POSITION = 'bottom';
const DEFAULT_COLOR = 'dark';
const DEFAULT_TOAST_TYPE = 'native';

const runIonicToast = async (
  message: string,
  color: ToastColor = DEFAULT_COLOR,
  duration: number = DEFAULT_DURATION,
  position: IonicToastPosition = DEFAULT_POSITION
) => {
  if (process.server) return;
  const toast = await toastController.create({
    message,
    color,
    duration,
    position,
    mode: 'ios',
    cssClass: 'toast-cmfl',
    htmlAttributes: {
      tabindex: undefined,
    },
  });
  return toast.present();
};

export const toast = {
  async show(
    text: string,
    type = DEFAULT_TOAST_TYPE,
    color: ToastColor = DEFAULT_COLOR,
    duration: number = DEFAULT_DURATION,
    position: ToastPosition = DEFAULT_POSITION
  ) {
    return runIonicToast(text, color, duration, position as IonicToastPosition);
  },
};
