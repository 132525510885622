import { ActionTree } from 'vuex';
import { ImagesState } from './types';
import { RootState } from '@/shared/store';

import { UserReaction, Image } from '@/shared/types/static-types';
import { createReactionOnImage, deleteReactionOnImage, updateReactionOnImage } from '@/shared/actions/reactions';

const isUserReactedSameReactionImage = (reaction: string, image: Image, isInstant = false): boolean => {
  const { user_reaction } = image;
  return !!(user_reaction && user_reaction.id && (user_reaction.reaction === reaction || isInstant));
};

const isUserReactedAnotherReactionImage = (reaction: string, image: Image): boolean => {
  const { user_reaction } = image;
  return !!(user_reaction && user_reaction.id && user_reaction.reaction !== reaction);
};

const sendReactionToBackendImage = async (reaction: string, image: Image, fromRoulette = false, isInstant = false) => {
  const target = 'image';
  const { user_reaction, id: imageId = '' } = image;

  // delete
  if (isUserReactedSameReactionImage(reaction, image, isInstant)) {
    return await deleteReactionOnImage({ reactionId: user_reaction?.id || '' });
  }

  // patch
  if (isUserReactedAnotherReactionImage(reaction, image)) {
    return await updateReactionOnImage({ reactionId: user_reaction?.id || '', reaction });
  }

  // post
  const data = await createReactionOnImage({ objectId: imageId, target, reaction, fromRoulette });
  return data;
};

const updateReactionsDataForImage = (reaction: string, image: Image, newReaction: UserReaction): Partial<Image> => {
  const imageData = cloneDeep(image);
  imageData.reaction_counts = imageData.reaction_counts || { total_count: 0 };
  const totalCount = imageData.reaction_counts.total_count;
  let userReaction = imageData.user_reaction || ({} as UserReaction);
  const prevUserReaction = image.user_reaction as UserReaction;

  // editing counts if un-reacting or editing reaction
  if (imageData.user_reaction as UserReaction) {
    //  @ts-ignore
    imageData.reaction_counts[prevUserReaction.reaction as keyof ReactionImageCounts]--;

    if (newReaction.reaction in imageData.reaction_counts) {
      //  @ts-ignore
      imageData.reaction_counts[newReaction.reaction as keyof ReactionImageCounts]++;
    } else {
      // ignore; newReaction.reaction will never be "ordering" (which requires type string[])
      //  @ts-ignore
      imageData.reaction_counts[newReaction.reaction as keyof ReactionImageCounts] = 1;
    }
  }

  // if reacting to story for the first time: editing counts & user_reaction
  if (!userReaction.id) {
    imageData.reaction_counts!.total_count = totalCount + 1;
    //  @ts-ignore
    if (!imageData.reaction_counts[newReaction.reaction as keyof ReactionImageCounts]) {
      //  @ts-ignore
      imageData.reaction_counts[newReaction.reaction as keyof ReactionImageCounts] = 1;
    } else {
      //  @ts-ignore
      imageData.reaction_counts[newReaction.reaction as keyof ReactionImageCounts] += 1;
    }
    userReaction = newReaction;
  }

  // if editing reaction
  if (isUserReactedAnotherReactionImage(reaction, imageData)) {
    imageData.user_reaction!.reaction = reaction;
  }

  // if un-reacting
  if (isUserReactedSameReactionImage(reaction, imageData) && !newReaction) {
    imageData.reaction_counts!.total_count = totalCount > 0 ? totalCount - 1 : 0;
    imageData.user_reaction = undefined;
  } else {
    imageData.user_reaction = {
      ...userReaction,
      reaction,
    };
  }
  const out = {
    user_reaction: imageData.user_reaction,
    reaction_counts: imageData.reaction_counts,
  };
  return out;
};

export const actions: ActionTree<ImagesState, RootState> = {
  async reactImage(
    { commit },
    {
      reaction,
      image,
      fromRoulette,
      isInstant = false,
    }: { reaction: string; image: Image; fromRoulette: boolean; isInstant: boolean }
  ) {
    const newReaction = await sendReactionToBackendImage(reaction, image, fromRoulette, isInstant);
    const updatedReactionsData = updateReactionsDataForImage(reaction, image, newReaction);
    commit('APPLY_REACTION_ON_IMAGE', { image, updatedReactionsData });
    return { newReaction, updatedReactionsData };
  },
};
