<template>
  <div class="one-relationship dark p-3 d-flex">
    <div class="position-relative d-flex mr-3">
      <router-link :to="characterLink" target="_blank">
        <div class="image"><img loading="lazy" v-character-card-image :src="resizeUpload(image, '190h')" /></div
      ></router-link>
    </div>

    <div class="info w-100 flex-grow-1 mb-auto mt-2">
      <div class="top d-flex justify-content-between">
        <div class="title mb-1" :style="{ color: defaultTextColor }">
          <router-link :to="characterLink" target="_blank" :style="{ color: defaultTextColor }">
            <span :title="hoverText" :style="`font-family: ${defaultTextFont} `">{{ characterPrefixEmoji }}</span
            >{{ characterName }}
          </router-link>
        </div>
        <ion-button v-if="showExpand" fill="clear" color="dark" class="expand-btn" @click="toggleExpand">
          <i class="ti-angle-right expand-icon" :class="{ rotate: isExpanded }" />
        </ion-button>
      </div>
      <div class="status d-flex" :style="`font-family: ${defaultTextFont} `">
        <inline-svg width="18px" src="/icons/people-outline.svg" />&nbsp;{{ status }}
      </div>
      <div class="description mt-1" :style="`font-family: ${defaultTextFont} `">
        {{ isExpanded ? description : compactDescription }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CharacterRelationship } from '@/shared/types/static-types';
import { truncateEasy } from '@/shared/utils/string';
import { characterProfileStore } from '@/shared/pinia-store/character-profile';
import { resizeUpload } from '@/shared/utils/upload';
import { mainStore } from '@/shared/pinia-store/main';


const props = defineProps({
  relationship: {
    type: Object,
    default: {},
  },
  profileUser: {
    type: Object,
    default: {},
  },
  customize: {
    type: Object,
    default: {},
  },
});

const relationship = toRef(props, 'relationship');
const customize = toRef(props, 'customize');
const characterToShow = computed(() => {
  const { character1, character2 } = relationship.value as CharacterRelationship;
  if (character1?.id === characterProfileStore().currentCharacter?.value?.id) {
    return character2;
  }

  return character1;
});

const status = computed(() => {
  const { character1, character1_status, character2_status } = relationship.value as CharacterRelationship;

  if (character1?.id === characterProfileStore().currentCharacter.value?.id) {
    return character1_status;
  }

  return character2_status;
});

const hoverText = computed(() => {
  if (characterToShow.value?.id === relationship.value?.character1?.id && relationship.value?.character1?.is_draft)
    return 'As this character is currently a draft, only you can see this relation.';
  if (isDisabled.value) return 'Due to character privacy settings, only you can see this relation.';
  return '';
});

const isdark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const description = computed(() => {
  const { character1, character1_description, character2_description } = relationship.value as CharacterRelationship;
  if (character1?.id === characterProfileStore().currentCharacter.value?.id) {
    return character1_description;
  }
  return character2_description;
});

const { isTempDefault } = useCustTempDefault(props);

const cardColor = computed(() => {
  const defaultClr = isdark.value ? '#010123' : '#FFFFFF';
  return isTempDefault.value ? defaultClr : get(customize.value, 'data.theme.backgrounds.infoSection') || defaultClr;
});

const characterLink = computed(() => {
  const { character1, character2 } = relationship.value as CharacterRelationship;
  if (!character1?.is_draft) {
    const slug =
      character1?.id === characterProfileStore().currentCharacter.value?.id ? character2?.slug : character1?.slug;
    return { name: 'character-profile-new', params: { slug } };
  } else {
    const is_on_c1 = character1?.id === characterProfileStore().currentCharacter.value?.id;
    if (is_on_c1) return { name: 'character-profile-new', params: { slug: character2?.slug } };
    else return { name: 'char-draft-details', params: { id: character1?.id } };
  }
});
const getBorderColor = computed(() => {
  return get(customize.value, 'data.theme.text.default.color');
});
const defaultTextColor = computed(() => {
  const defaultClr = isdark.value ? '#FFFFFF' : '#000000';
  return isTempDefault.value ? defaultClr : get(customize.value, 'data.theme.text.default.color', defaultClr);
});

const getBorder = computed(() => {
  return isTempDefault.value ? 'none' : 'solid';
});

const characterPrefixEmoji = computed(() => {
  const { character1, character2 } = relationship.value as CharacterRelationship;
  if (character1?.id === characterProfileStore().currentCharacter.value?.id) {
    if (character2?.privacy === 'M') return '🔒 ';
    else if (character2?.privacy === 'U') return '🔗 ';
    return '';
  }
  if (character1?.is_draft) return '✏️';
  if (character1?.privacy === 'M') return '🔒 ';
  else if (character1?.privacy === 'U') return '🔗 ';
  return '';
});

const isDisabled = computed(() => {
  const { character1, character2 } = relationship.value as CharacterRelationship;
  if (character1?.id === characterProfileStore().currentCharacter.value?.id) {
    const isPrivate = character2?.privacy !== 'P';
    return isPrivate;
  }
  if (character1?.is_draft) return true;
  const isPrivate = character1?.privacy !== 'P';
  return isPrivate || character1?.is_draft;
});
const defaultTextFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.default.font') || 'Roboto';
});

const image = computed(() => characterToShow.value?.info.cropProfilePicture);

const isExpanded = ref(false);

const showExpand = computed(() => (description.value?.length || 0) > 120);

const characterName = computed(() => characterToShow.value?.info.name);

const compactDescription = computed(() => {
  return truncateEasy(description.value || '', 120);
});

const toggleExpand = () => (isExpanded.value = !isExpanded.value);
</script>

<style lang="sass" scoped>
.emos
  font-size: 20px
.expand-btn
  --border-radius: 10px
  width: 20px
  height: 20px
.expand-icon
  color: v-bind(defaultTextColor)
.dark .expand-icon
  color: v-bind(defaultTextColor)
.one-relationship
  border-radius: 10px
  background: v-bind(cardColor)
  border: 3px v-bind(getBorder) v-bind(getBorderColor)
  .image
    width: 100px
    height: 100px
    border-radius: 12px
    min-width: 100px
    background: #4A9FC3
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    img
      width: 100%
      height: 100%
      object-fit: cover
  .info
    height: 100%
    line-height: 25px
.description
  white-space: pre-line
  color: v-bind(defaultTextColor) !important
.rotate
  transform: rotate(90deg)
.status
  font-size: 1rem
  width: 100%
  font-weight: bold
  color: v-bind(defaultTextColor) !important
.title
  font-weight: bold
  font-size: 1.3rem
  a
    font-family: v-bind(defaultTextFont)
  &.disabled
    pointer-events: none
  .prefix
    font-weight: normal
ion-icon
    position: relative
    top: 2px
    pointer-events: none
ion-badge
    height: 20px
.dark .one-relationship
  background: v-bind(cardColor) !important
  border: 3px v-bind(getBorder) v-bind(getBorderColor)
</style>
