import { Module } from 'vuex';
import { RootState } from '../../index';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { WorldsState } from './types';

const state: WorldsState = {
  trendingWorlds: [],
  trendingWorldsPaging: {},
  trendingWorldsPage: 0,
  inActionWorld: {},
  latestWorlds: [],
  latestWorldsPage: 0,
  latestWorldsPaging: {},
  reactionWorld: {},
  statusWorld: {},
  myWorlds: [],
  myWorldsPage: 0,
  myWorldsPaging: {},
  userWorlds: [],
  userWorldsPage: 0,
  userWorldsPaging: {},
  hasUnreadWorldMsgs: false,
};

export const WorldsModule: Module<WorldsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
