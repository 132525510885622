<template>
  <div class="actions d-flex justify-content-end">
    <ion-button class="button" :style="{ color: textConverter }" @click="mutateAllSections"> {{ text }} </ion-button>
  </div>
</template>

<script lang="ts" setup>
import { characterProfileStore, SectionsCollapsed } from '@/shared/pinia-store/character-profile';
import { textColorChanged } from '@/shared/utils/textConverter';
const { collapseSections } = characterProfileStore();

const props = defineProps({
  sectionsCollapsed: {
    default: -1,
  },
  customize: {
    type: Object,
    default: {},
  },
});

const sectionsCollapsed = toRef(props, 'sectionsCollapsed');
const customize = toRef(props, 'customize');

const isCollapsed = computed(() => {
  const isCollapsed = sectionsCollapsed?.value as SectionsCollapsed;
  return isCollapsed;
});
const text = computed(() => {
  return isCollapsed.value === SectionsCollapsed.noCare || isCollapsed.value === SectionsCollapsed.false
    ? 'COLLAPSE ALL SECTIONS'
    : 'EXPAND ALL SECTIONS';
});

const getButtonsColor = computed(() => {
  return isEmpty(get(customize.value, 'template_applied')) &&
    get(customize.value, 'data.theme.backgrounds.buttons') === '#AE38E5'
    ? '#8168b7'
    : get(customize.value, 'data.theme.backgrounds.buttons');
});
const textConverter = computed(() => {
  return getButtonsColor.value ? `${textColorChanged(getButtonsColor.value)} !important` : '#ae38e5';
});

const mutateAllSections = () => {
  const isCollapsed = sectionsCollapsed?.value as SectionsCollapsed;
  const value = isCollapsed === SectionsCollapsed.noCare || isCollapsed === SectionsCollapsed.false ? 1 : 0;
  collapseSections(value);
};
</script>

<style lang="sass" scoped>
.button
  height: 30px
  width: 250px
  --border-radius: 10px
  --background: v-bind(getButtonsColor)
  color: v-bind(textConverter) !important
</style>
