<template>
  <div class="qualities">
    <Grid :xl="2" :lg="2" :md="1" :sm="1" :scrollSm="false" class="grid mt-n3">
      <div v-for="(item, index) of orderedQualities" :key="index" class="one-quality p-2 grid-item">
        <div v-if="hasValue(item)" class="align-items-center h-100 d-flex flex-column">
          <div class="key dark w-100 text-center">{{ toTitleSpaceCase(item.key) }}</div>
          <div class="mt-2 px-2 w-100">
            <div class="ranges d-flex align-items-center justify-content-between">
              <div class="left">{{ item.left }}</div>
              <div class="right">{{ item.right }}</div>
            </div>
            <ion-range class="px-2" :value="item.value" :ticks="false" :snaps="true" :min="0" :max="10" disabled />
          </div>
        </div>
      </div>
    </Grid>
  </div>
</template>

<script lang="ts" setup>
import { toTitleSpaceCase } from '@/shared/utils/string';
import { formatCharacterQualitiesIn } from '@/shared/utils/transform';
import Grid from '@/shared/components/storage/Grid.vue';

import { mainStore } from '@/shared/pinia-store/main';


const props = defineProps({
  qualities: {
    type: Object,
    default: () => ({}),
  },
  qualitiesConfig: {
    type: Object,
    default: () => ({}),
  },
  customize: {
    type: Object,
    default: () => ({}),
  },
});

const qualities = toRef(props, 'qualities');
const qualitiesConfig = toRef(props, 'qualitiesConfig');
const customize = toRef(props, 'customize');
const hasValue = (qualities: any) => {
  return qualities.value >= 0;
};
const isdark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const defBorderColor = computed(() => {
  return isdark.value ? '#4a288a' : '#000';
});

const { isTempDefault } = useCustTempDefault(props);

const getBackgroundColor = computed(() => {
  return isTempDefault.value && isdark.value
    ? '#010123'
    : isTempDefault.value && get(customize.value, 'data.theme.backgrounds.infoSection') === '#E6E6E6'
    ? '#FFFFFF'
    : get(customize.value, 'data.theme.backgrounds.infoSection', '#FFFFFF');
});
const defaultTextColor = computed(() => {
  const defaultClr = isdark.value ? '#FFFFFF' : '#000000';
  return isTempDefault.value ? defaultClr : `${get(customize.value, 'data.theme.text.default.color', defaultClr)}`;
});
const defaultTextFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.default.font', 'Roboto');
});

const oneTextValueBold = computed(() => {
  return isTempDefault.value ? '100' : get(customize.value, 'data.theme.text.default.bold') ? '800' : '100';
});

const defaultTextItalic = computed(() => {
  return get(customize.value, 'data.theme.text.default.italics') ? 'italic' : 'normal';
});

const orderedQualities = computed(() => {
  return formatCharacterQualitiesIn(qualities.value, qualitiesConfig.value);
});

const getBorderColor = computed(() => {
  return get(customize.value, 'data.theme.text.default.color') || defBorderColor.value;
});
</script>

<style lang="sass" scoped>
ion-range
  --bar-background: transparent !important
  --bar-height: 4px
  --knob-size: 11px

  opacity: 1 !important
  &::part(bar)
    border: 1px solid v-bind(getBorderColor)
    background: rgba(255, 255, 255, 0.5)
    border-radius: 5px
  &::part(bar-active)
    background: transparent
  &::part(knob)
    width: 30px
    height: 12px
    scale: 1 !important
    transform: scale(1) !important
    border-radius: 12px
    margin-inline-start: -10px !important
    box-shadow: none !important
    border: 1px solid v-bind(getBorderColor)
    background: v-bind(getBackgroundColor)
    outline: none !important
    &::before
      inset-inline-start: 4px
      content: ''
      opacity: 1 !important
      transform: unset !important
      color: #FFF
      font-size: 14px
      margin-top: 10px
      background: transparent !important
.qualities
  .one-quality
    margin-top: 10px
    border: 1px solid v-bind(getBorderColor)
    border-radius: 12px
    height: 115px
    .value
      background: v-bind(getBackgroundColor)
      border-radius: 20px
      padding: 7px
      width: calc(100% - 150px)
      color: v-bind(defaultTextColor) !important
      &.dark
        background: v-bind(getBackgroundColor) !important
        color: v-bind(defaultTextColor) !important
        font-weight: v-bind(oneTextValueBold) !important
        font-style: v-bind(defaultTextItalic) !important
        font-family: v-bind(defaultTextFont) !important
    @media(max-width: 500px)
      flex-direction: column
      .key, .value
        width: 100% !important
      .key
        margin-bottom: 5px

    .key
      width: 150px
      font-weight: bold
      &.dark
        color: v-bind(defaultTextColor) !important
        font-style: v-bind(defaultTextItalic) !important
        font-family: v-bind(defaultTextFont) !important
</style>
