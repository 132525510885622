<template>
  <div
    class="p-2 d-flex flex-wrap text-primary justify-content-center align-items-center upgrade-plan clickable-item"
    @click="openSubscribeModal($event, from)"
  >
    Upgrade to
    <template v-if="isFreeSubscription"> <SubPlanBadge class="mx-1" plan="plus" /> <span>or</span> </template>
    <SubPlanBadge class="mx-1" plan="ultra" />
    <div v-html="postfix" />
  </div>
</template>

<script lang="ts" setup>
import constants from '@/shared/statics/constants';
import SubPlanBadge from './SubPlanBadge.vue';
import { openSubscribeModal } from '@/shared/utils/modals';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';

const { user: loggedInUser } = authStore();
const { dark: isDark } = mainStore();
const { currentTemplate } = characterStylizeStore();

const props = defineProps({
  postfix: { type: String, default: 'to unlock more features.' },
  bgColor: { type: String, default: '#f3f3f3' },
  darkBgColor: { type: String, default: '#010123' },
  from: { type: String, default: 'upgradeSubscriptionText' },
});

const subscriptionLevels = constants.subscriptionLevels;

const isFreeSubscription = computed(() => {
  return loggedInUser.value.subscription_level === subscriptionLevels.free;
});

const isTempDefault = computed(() => {
  return isEmpty(get(currentTemplate.value, 'template_applied'));
});

const isCharProfileRoute = computed(() => {
  const router = useRouter();
  return ['character-profile-new', 'character-profile-draft-new'].includes(router.currentRoute.value.name as string);
});

const getBarBG = computed(() => {
  const defaultClr = isDark.value ? props.darkBgColor : props.bgColor;

  return isCharProfileRoute.value && !isTempDefault.value
    ? `${get(currentTemplate.value, 'data.theme.backgrounds.page')}`
    : defaultClr;
});
</script>

<style scoped lang="sass">
.upgrade-plan
  border: 2px solid transparent
  border-radius: 10px
  background: linear-gradient(to right, v-bind(getBarBG), v-bind(getBarBG)), linear-gradient(170deg, rgb(216 56 247) 43%, rgb(159 76 239) 25%, rgb(85 202 229) 63%, rgb(100 236 229) 76%)
  background-clip: padding-box, border-box
  background-origin: padding-box, border-box
  font-size: 13px
  @media(max-width:500px)
    margin-left: 1rem
    margin-right: 1rem
</style>
