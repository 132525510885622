import constants from '@/shared/statics/constants';
import { chAxios } from '@/shared/lib/axios';

export async function getTags(page = 1, pageSize = constants.defaultPageSize, searchText?: string | null) {
  const url = `/tags/`;
  try {
    const { data } = await chAxios().get(url, { params: { page, page_size: pageSize, search: searchText } });
    return data;
  } catch (e) {
    throw e;
  }
}
