import { Module } from 'vuex';
import { RootState } from '../../index';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { RolechatState } from './types';

const state: RolechatState = {
  rolechats: [],
  rolechatsPage: 0,
  rolechatsPaging: {},
  unreadRolechatsCount: 0,
  hasUnreadRolechats: false,
};

export const RolechatModule: Module<RolechatState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
