import { chAxios } from '@/shared/lib/axios';
import { sendAnalyticsEvent } from '@/shared/services/analytics';

export async function createComplimentBomb(targeted_char: string) {
  const url = `/compliment-bombs/`;
  const { data } = await chAxios().post(url, { targeted_char });
  sendAnalyticsEvent('Created compliment bomb ');
  return data;
}

export async function sendGiftToUser(payload: {}) {
  const url = `/inventory/gifts/`;
  const { data } = await chAxios().post(url, payload);
  sendAnalyticsEvent('Gift sent successfully');
  return data;
}

export async function getGiftRespondStatus(id: string) {
  const { data } = await chAxios().get(`/inventory/gifts/${id}`);
  return data;
}

export async function giftRespond(payload: {}) {
  const url = `/inventory/gifts/respond/`;
  const { data } = await chAxios().post(url, payload);
  sendAnalyticsEvent('Thankyou Message sent successfully');
  return data;
}

export async function getInventoryDetails(param = {}) {
  const url = `/inventory/details/`;

  const { data } = await chAxios().get(url, { params: { ...param } });
  return data;
}

export async function getComplimentBombDailyLimit() {
  const url = '/compliment-bombs/cb-daily-limit/';

  const { data } = await chAxios().get(url);
  return data;
}

export async function getUserComplimentBombedChars(params = {}) {
  const url = '/characters/my-bombed-chars/';

  const { data } = await chAxios().get(url, { params: { ...params } });
  return data;
}

export async function getCurrentlyBombedCharacters() {
  const url = '/characters/currently-bombed-chars/';

  const { data } = await chAxios().get(url);
  return data;
}
export async function getComplimentBombUserCharacters(params = {}) {
  const url = '/characters/chars-to-bomb/';

  const { data } = await chAxios().get(url, { params: { ...params } });
  return data;
}

export async function getComplimentBombCharacterStatus(slug?: string) {
  const url = `/characters/bombed-status/`;
  try {
    const { data } = await chAxios().get(url, { params: { slug } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getComplimentBombsList(params = {}) {
  const url = '/comments/compliments/';

  const { data } = await chAxios().get(url, { params: { ...params } });
  return data;
}
