import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { chAxios } from '@/shared/lib/axios';

export async function createBookmarkCollection(payload: {
  name?: string;
  description?: string;
  image_url?: string;
  cropped_img_url?: string;
  banner_img_url?: string;
}) {
  try {
    const { data } = await chAxios().post(`/bookmarks/`, payload);

    sendAnalyticsEvent('Created bookmark collection');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateBookmarkCollection(
  id: string,
  collectionData: {
    name?: string;
    description?: string;
    image_url?: string;
    cropped_img_url?: string;
    banner_img_url?: string;
  }
) {
  const url = `/bookmarks/${id}/`;
  try {
    const { data } = await chAxios().patch(url, collectionData);
    sendAnalyticsEvent('Updated bookmark collection');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteCollection(id: string) {
  const url = `/bookmarks/${id}/`;
  const { data } = await chAxios().delete(url);
  sendAnalyticsEvent('Deleted bookmark collection');
  return data;
}

export async function getBookmarkCollectionFromSlug(slug?: string) {
  const url = `/bookmarks/from-slug/`;
  try {
    const { data } = await chAxios().get(url, { params: { slug } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function bookmark(payload: { collection: string; id: string; type: string }) {
  try {
    const { data } = await chAxios().post(`/bookmarks/add-object/`, payload);
    sendAnalyticsEvent('Bookmarked object');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function unbookmark(payload: { collection: string; id: string }) {
  try {
    const { data } = await chAxios().post(`/bookmarks/remove-object/`, payload);
    sendAnalyticsEvent('Unbookmarked object');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getBookmarkCollectionObjects(id: string, page = 1) {
  const url = `/bookmarks/objects/`;
  try {
    const { data } = await chAxios().get(url, { params: { id, page } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getBookmarkCollections(page: number = 1, id?: string | null, params={}) {
  const url = `/bookmarks/`;
  try {
    const { data } = await chAxios().get(url, { params: { page, context_id: id , ...params} });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getBookmarkCollection(id: string) {
  const url = `/bookmarks/${id}/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getObjectBookmarkCollections(id: string) {
  const url = `/bookmarks/with-object/?id=${id}`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}
