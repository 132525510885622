<template>
  <div class="d-flex align-items-center">
    <ion-badge class="badge gift-badge" v-if="isGift && !isEmpty(type)">Gift</ion-badge>
    <ion-badge v-if="isEmpty(info)" class="badge own-badge" :class="{ 'count-badge': !isEmpty(type) }">
      <span v-if="isEmpty(type)">Owned:</span><span class="ml-1 text-primary">x{{ count }}</span>
    </ion-badge>
  </div>
</template>
<script lang="ts" setup>
import constants from '@/shared/statics/constants';
const props = defineProps({
  count: { type: Number, default: 0 },
  type: { type: String, default: '' },
  info: { type: String, default: '' },
});
const inventories = constants.inventories;
const isGift = computed(() => props.type === inventories.gift);
</script>
<style scoped lang="sass">
.badge
  padding: 6px 9px
  background: white
  color: #214163
  &.gift-badge
    background: #fa6c91
    font-size: 11px
    border-radius: 7px
    padding: 3px 6px 3px 6px !important
    margin-top: 0.25rem
    color: white
  &.count-badge
    border-radius: 7px
    font-size: 12px
    padding: 2px 3px 2px 2px !important
    color: var(--ion-color-primary)
    margin-left: auto !important
</style>
