<template>
  <div :id="`post-reply-${message.reply.id}`" class="position-relative">
    <!-- <div class="side-line" :class="{ 'side-line-lf': isBlabOwner }"></div> -->

    <div class="d-flex">
      <i v-if="isBlabOwner" id="blab-drag-handle" class="ti-menu clickable-item-hov ml-1 mt-2 mr-3 blab-drag-icon" />
      <ProfileRing
        :image="
          isNull(message.reply.reply_as_char)
            ? get(message.reply, 'user.customize_profile.profile_ring.image')
            : get(message.reply.reply_as_char, 'profile_ring.image')
        "
        :borderWidth="50"
        :ringTop="-5"
        :ringLeft="isBlabOwner ? 32 : -5"
        class="position-absolute"
      />
      <img
        loading="lazy"
        v-image
        class="img"
        :src="
          isNull(message.reply.reply_as_char)
            ? get(message.reply, 'user.profile_picture_url', '/empty.png')
            : get(message.reply.reply_as_char, 'info.cropProfilePicture', '/empty.png')
        "
      />

      <div class="ml-2 flex-grow-1">
        <div class="d-flex flex-column msg-content">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex flex-column">
              <div v-if="!message.reply.reply_as_char" @click.stop>
                <router-link
                  :class="{
                    'text-color-username':
                      usernameColor(get(message.reply, 'user')) &&
                      !usernameColor(get(message.reply, 'user')).startsWith('#'),
                    'bold-username': usernameColor(get(message.reply, 'user')),
                  }"
                  :style="
                    !usernameColor(get(message.reply, 'user'))
                      ? ''
                      : usernameColor(get(message.reply, 'user')).startsWith('#')
                      ? `color: ${usernameColor(get(message.reply, 'user'))} !important`
                      : `background-image: ${usernameColor(get(message.reply, 'user'))} !important`
                  "
                  class="msg-font"
                  :to="{ name: 'profile', params: { username: get(message.reply, 'user.username') } }"
                >
                  <div class="d-flex align-items-center">
                    <div class="mr-1">{{ get(message.reply, 'user.username') }}</div>
                    <ProfileBadge
                      :badges="get(message.reply, 'user.customize_profile.badges')"
                      :show-small-badge="true"
                    />
                  </div>
                </router-link>
              </div>
              <div v-else @click.stop>
                <router-link
                  class="msg-font"
                  :to="{ name: 'character-profile-new', params: { slug: message.reply.reply_as_char.slug } }"
                  >{{ get(message.reply.reply_as_char, 'info.name') }}
                </router-link>
              </div>

              <small class="timer">{{ formatTimeAgo(message.reply.created) }}</small>
              <!-- <small v-if="message.reply.location" class="ml-2">
                <i class="ti-location-pin icon" /> {{ message.reply.location }}</small
              > -->
            </div>

            <div v-if="isBlabOwner && !isEditing" class="ml-auto clickable">
              <div v-if="message.reply.sunshined_reply" @click="notsunshining(message.reply.id)">
                <span title="Sunshine">
                  <i class="ti-pin-alt" style="color: #3dc2ff; pointer-events: none" />
                </span>
              </div>
              <div v-else @click="sunshining(message.reply.id)">
                <span title="Sunshine">
                  <i class="ti-pin-alt" style="pointer-events: none" />
                </span>
              </div>
            </div>
          </div>

          <div v-if="message.reply.title && !isEditing">{{ message.reply.title }}</div>

          <div v-if="!isEditing" v-html="sanitizeHtml(strippedMessage)" />

          <post-blab
            v-else
            :blab-editing-text="message.reply.text"
            :blab-editing-title="message.reply.title"
            :parent="message.reply.id"
            :is-edit="true"
            @cancelled="onCancel"
            @send="onEditComplete"
          ></post-blab>
        </div>

        <div v-if="!isEditing" class="d-flex justify-content-between" :class="{ 'mt-2': isEmpty(message.reply.title) }">
          <div v-if="isAuthenticated" class="actions d-flex" style="margin-left: -5px">
            <a href="#" @click.prevent>
              <Reaction
                type="post"
                :reactions="reactions"
                :user-reaction="userReaction"
                @changed="(reaction) => reactionChanged(reaction.key, message.reply, reaction.isInstant)"
              >
                <ion-button color="transparent" class="inline-button icon-button clickable">
                  <div class="d-flex align-items-center justify-content-center">
                    <i
                      :color="!!userReaction && userReaction.reaction ? 'secondary' : 'primary'"
                      class="ti-thumb-up mr-2"
                    />
                    <span
                      class="reaction-count"
                      :class="!!userReaction && userReaction.reaction ? 'text-secondary' : 'text-primary'"
                      >{{ reactions.total_count || 0 }}</span
                    >
                  </div>
                </ion-button>
              </Reaction>
            </a>
            <a href="#" class="ml-2 d-flex align-items-center" @click.prevent="postReply(message)">
              <i class="ti-comment" /><span v-if="!isMobSmallScreen" class="ml-1">Reply</span>
            </a>
            <a href="#" class="ml-3 d-flex align-items-center" v-if="!isMessageOwner(message)">
              <i @click.stop="dropdownToggle($event, message)" class="ti-more-alt menu-icon" />
            </a>
            <a
              v-if="isMessageOwner(message)"
              href="#"
              class="ml-2 d-flex align-items-center text-danger"
              @click.prevent="deleteReply(message.reply.id)"
            >
              <i class="ti-trash" /><span v-if="!isMobSmallScreen" class="ml-1">Delete</span>
            </a>
            <a
              v-if="isMessageOwner(message)"
              href="#"
              class="ml-2 d-flex align-items-center"
              @click.prevent="editMessage"
            >
              <i class="ti-pencil-alt" /><span v-if="!isMobSmallScreen" class="ml-1">Edit</span>
            </a>
          </div>
          <small v-if="repliesCount" class="ml-auto mt-1 clickable-item" @click="openReplies(message.reply.id, false)"
            >{{ repliesCount }} repl{{ repliesCount === 1 || repliesCount === 0 ? 'y' : 'ies' }}</small
          >
        </div>
      </div>
    </div>

    <div v-if="isReplyofreplies" class="mt-3 mb-1">
      <div v-for="(reply, index) of replies" :key="reply.id" class="my-2">
        <blab-nested-reply
          :message="reply"
          :blab="blab"
          :reply="true"
          @nestedReply="postReply(reply)"
          @shine="onReplyPost"
          @unshine="onReplyPost"
          @afterReaction="(reactionResp: any) => onReaction(index, reactionResp)"
          @deleted="onNestedReplyDelete"
        />
        <hr v-if="index < replies.length - 1" class="my-3" />
      </div>
      <p
        v-show="repliesCount > 1 && replies.length < repliesCount"
        class="clickable-item"
        :class="isBlabOwner ? 'own-replies' : 'ml-5'"
        @click="requestLoadMore"
      >
        View More Replies
      </p>
      <p v-show="!nextPageExists" class="ml-5"></p>
    </div>

    <div v-if="isReply" :id="`post-blab-${message.id}`" style="scroll-margin-bottom: 200px !important">
      <post-blab
        :blab="blab"
        :is-reply="true"
        :parent="message.reply.id"
        :reply-to-mention-name="replyToMentionName"
        class="mt-2"
        :class="isBlabOwner ? 'ml-6' : 'ml-5'"
        @send="onReplyPost"
      ></post-blab>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { alertController } from '@ionic/vue';
import { Getter } from 's-vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { toast } from '@/shared/native';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import constants from '@/shared/statics/constants';
import BlabNestedReply from '@/shared/pages/blabs/components/BlabNestedReply.vue';
import { sanitizeHtml } from '@/shared/utils/html';
import namespace from '@/shared/store/namespace';
import { stripHtmlTags } from '@/shared/utils/string';
import Reaction from '@/shared/components/Reaction/index.vue';
import {
  blabDeleteSunShineReply,
  blabSunShineReply,
  deleteBlabReply,
  getBlabReplies,
  getNextPage,
} from '@/shared/actions/blabs';
import PostBlab from '@/shared/pages/blabs/components/PostBlab.vue';
import { Blab, Paging } from '@/shared/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';
import store from '@/shared/store';
import { popovers } from '@/shared/native/popovers';
import ProfileRing from '@/shared/components/ProfileRing.vue';
import PostPopover from './PostPopover.vue';

import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
@Options({
  name: 'BlabSunshineMessages',
  components: { PostBlab, Reaction, BlabNestedReply, ProfileBadge, ProfileRing },
})
export default class BlabSunshineMessages extends Vue {
  @Prop({ default: () => {} }) message!: any;
  @Prop({ default: () => {} }) blab!: any;
  @Prop({ default: () => {} }) nestedReply!: any;
  @Prop({ default: () => {} }) repliesCount!: any;
  @Prop({ default: () => {} }) sunindex!: any;
  @Prop({ default: () => {} }) sunshine!: any;

  public badges = ['', ''];
  isEmpty = isEmpty;
  sanitizeHtml = sanitizeHtml;

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public usernameColor(user: any) {
    return user?.customize_profile?.username_color || '';
  }

  @Getter('inActionBlab', { namespace: namespace.BlabsModule })
  public inActionBlab!: Blab;

  public isReply = false;
  ispost = false;
  public isReplyofreplies = false;
  public replies: { id: string; message: string }[] = [];
  public paging: Paging | null = null;

  hover = false;
  isSunShine = false;
  public isEditing = false;
  stripHtmlTags = stripHtmlTags;
  formatTimeAgo = formatTimeAgo;
  get = get;
  commentedAsTypes = constants.commentedAsTypes;
  replyToMentionName: string | null = null;

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  public async editMessage() {
    this.isEditing = !this.isEditing;
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 420;
  }

  public onCancel() {
    this.isEditing = false;
  }

  public dropdownToggle = async (ev: CustomEvent, message: any) => {
    const popover = await popovers.open(ev, PostPopover, {
      reportEntityId: message.reply.id,
      reportEntityType: 'sunshined_reply',
      reportedUser: get(message.reply, 'user'),
    });
    try {
      await popover.onDidDismiss();
    } catch (e) {}
  };

  public onEditComplete(payload: any) {
    this.isEditing = false;
    this.message.reply.text = payload.text;
    this.message.reply.title = payload.title;
  }

  public onReplyPost(id: string) {
    this.isReply = false;
    this.ispost = true;
    // this.openLastReply(this.ispost);
    this.$emit('posted');
    this.$emit('count');
  }

  public async deleteReply(id: string) {
    try {
      const alert = await alertController.create({
        cssClass: '',
        header: 'Are you sure?',
        message: `Please confirm that you want to delete this reply.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'text-secondary',
            id: 'cancel-button',
          },
          {
            text: 'Confirm',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'text-secondary',
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === 'ok') {
        await blabDeleteSunShineReply(id, this.blab);
        await deleteBlabReply(id);
        await toast.show('Reply deleted', 'nonative', 'success');
        this.isReply = false;
        this.isEditing = false;
        this.$emit('unshine');
        this.$emit('count');
      }
    } catch (e) {}
  }

  public isMessageOwner(message: any) {
    try {
      return message.reply.user?.id === this.user.id;
    } catch (error) {
      return false;
    }
  }

  public async onNestedReplyDelete(id: string) {
    this.replies = this.replies.filter((reply: any) => reply.id !== id);
    this.$emit('count');
  }

  public onSunshinePost(id: string) {
    this.ispost = true;
    this.openReplies(id, this.ispost);
    this.$emit('posted');
    this.$emit('count');
  }

  public draggable() {
    //  this.$emit('drag');
  }

  public get isBlabOwner() {
    try {
      return this.inActionBlab.user!.username === this.user.username;
    } catch (error) {
      return false;
    }
  }

  public get userReaction() {
    if (this.message.reply.user_reaction) {
      return this.message.reply.user_reaction;
    }
  }

  public onReaction(replyIndex: number, reaction: any) {
    this.replies[replyIndex] = {
      ...this.replies[replyIndex],
      ...reaction.updatedReactionsData,
    };
  }

  @Watch('nestedReply')
  public async openLastReply(val: boolean) {
    if (val) {
      if (this.message.id === this.nestedReply.parent_reply_id) {
        this.replies.push(this.nestedReply);
        this.isReplyofreplies = true;
      }
    }
  }

  public async openReplies(id: string, post: boolean) {
    if (!post) this.replies = [];
    const { results, ...paging } = await getBlabReplies(this.blab, 0, 3, id, { ordering: 'created' });
    const ids = this.replies.map((res) => res.id);
    const res = results.filter((result: any) => !ids.includes(result.id));
    this.replies = this.replies.concat(res);
    if (!post) {
      this.isReplyofreplies = !this.isReplyofreplies;
    } else {
      this.isReplyofreplies = true;
    }
    this.paging = paging;
  }

  public get nextPageExists() {
    return !!this.paging?.next;
  }

  public async requestLoadMore() {
    if (!this.paging && this.replies.length) {
      this.openReplies(this.message.id, true);
    } else {
      try {
        const { results, ...paging } = await getNextPage(this.paging!);
        const ids = this.replies.map((res) => res.id);
        const res = results.filter((result: any) => !ids.includes(result.id));
        this.replies = this.replies.concat(res);
        this.paging = paging;
      } catch (e) {}
    }
  }

  public async reactionChanged(reaction: string, msg: any, isInstant = false) {
    const reactionResp = await store.dispatch('BlabsModule/reactBlabReply', {
      reaction,
      blab: msg,
      isInstant,
    });
    this.$emit('afterReaction', reactionResp);
  }

  public get reactions() {
    const { reaction_counts } = this.message.reply || {};
    return reaction_counts;
  }

  public async sunshining(id: string) {
    this.isSunShine = true;

    const res = await blabSunShineReply(this.blab, id);
  }

  public async postReply(message: any) {
    this.replyToMentionName = message.reply_as_char
      ? get(message.reply_as_char, 'info.name', 'Unknown')
      : message.reply
      ? message.reply.user.username
      : message.user.username;

    this.isReply = true;
    const document = useDocument();
    document.value
      .getElementById(`post-blab-${this.message.id}`)
      ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  public async notsunshining(id: string) {
    const res = await blabDeleteSunShineReply(id, this.blab);
    this.$emit('unshine', id);
    this.$emit('unsunshine');
  }

  public get strippedMessage() {
    return stripHtmlTags(this.message.reply.text).replace(/(?:\r\n|\r|\n)/g, '<br />');
  }
}
</script>

<style lang="sass" scoped>
.timer
  position: relative
  top: -5px
  color: #A7AABE
.msg-font
  color: #ae38e5
  font-weight: bold

.dark .msg-font
  color: white

.blab-drag-icon
  font-size: 18px

.img
  width: 40px
  height: 40px
  border-radius: 20px
  border: solid gray 0.1px
  object-fit: cover

.msg-content
  border-radius: 15px
  //background-color: #F3F3F3

.dark .msg-content
  //background-color: #767676 !important

.side-line
  height: calc(100% - 55px)
  position: absolute
  top: 45px
  width: 22px
  left: 18px
  border-bottom: solid #cecece
  border-left: solid #cecece
  border-radius: 0px 10px

.side-line-lf
  left: 55px !important

.ml-6
  margin-left: 4.5rem !important

.own-replies
  margin-left: 5.5rem !important
</style>
