<template>
  <ion-button :disabled="disabled" class="btn" @click="openVerificationModal">
    <div class="no-select">{{ actionName }}</div>
  </ion-button>

  <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />
</template>
<script lang="ts" setup>
import VerificationModal from '@/shared/modals/VerificationModal.vue';

defineProps({
  actionName: { type: String, default: 'Publish' },
  disabled: { type: Boolean, default: false },
});

const verificationModal = ref(false);

const openVerificationModal = () => {
  verificationModal.value = true;
};

const closeVerificationModal = () => {
  verificationModal.value = false;
};
</script>
<style lang="sass" scoped>
.btn
  --border-radius: 10px
  text-transform: unset
  min-width: 150px
</style>
