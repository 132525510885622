<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="modal-inner-content">
      <div class="px-3 py-2 mt-1">
        <ChLoading size="lg" class="mx-auto" v-if="loading" />
        <div
          v-else
          class="d-flex text-black justify-content-center mx-auto align-items-center text-center flex-column flex-grow-1"
        >
          <!-- <ion-icon class="mr-1" style="font-size: 60px !important;color: #6adf32" :icon="checkmarkCircleOutline" /> -->

          <div class="title d-flex align-items-center">
            {{
              isPurchased
                ? `${get(item, 'item_group.title', 'Background Overlay')} successfully acquired!`
                : 'Confirm Purchase'
            }}
          </div>

          <VirtualItemsDisplay :item="item" />
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center p-2">
          <ion-button v-if="!isPurchased" @click="purchaseRing" color="transparent" class="purchase-btn">
            <ChLoading size="sm" v-if="loading" class="spinner" />
            <div v-else class="text-black d-flex align-items-center">
              Buy for
              <div class="mx-1 token">
                <CurrencyImage :image="'/char.jpg'" :type="get(item, 'currency_type')" :img-width="20" />
                <inline-svg :src="imageFromItemsCurrency" class="svg" />
              </div>

              <strong>{{ item.amount }}</strong>
            </div>
          </ion-button>
          <ion-button v-else @click="dismissModal">Thanks</ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import { purchaseVirtualItem } from '@/shared/actions/virtualItems';
import { checkmarkCircleOutline } from 'ionicons/icons';
import VirtualItemsDisplay from '@/shared/pages/store/VirtualItemsDisplay.vue';
import CurrencyImage from '@/shared/components/CurrencyImage.vue';
import { useVirtualItemCurrencyImage } from '~/composables/useVirtualItemCurrencyImage';
import { toast } from '../native';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  isPurchased: {
    type: Boolean,
    default: false,
  },
  item: {
    type: Object,
    default: {},
  },
});
const emits = defineEmits(['close', 'purchase', 'updated', 'success']);
const isOpen = toRef(props, 'isOpen');
const item = toRef(props, 'item');
const loading = ref(false);
const { imageFromItemsCurrency } = useVirtualItemCurrencyImage(item.value);
const isPurchased = toRef(props, 'isPurchased');
const dismissModal = () => {
  isPurchased.value ? emits('updated') : emits('close');
};

watch(isOpen, () => {
  if (isOpen.value) {
    loading.value = false;
  }
});

const purchaseRing = async () => {
  loading.value = true;
  const resp = await purchaseVirtualItem(item.value.id);
  //loading.value = false;
  if (get(resp, 'purchase_status')) {
    emits('success', item.value.id);
    emits('purchase');
  } else {
    emits('close');
    toast.show('Already Purchased!', 'nonative', 'danger');
  }
};
</script>
<style lang="sass" scoped>
.token
  height: 20px
.svg
  width: 20px
  height: 20px
.ring-title
  font-weight: bold
  font-size: 18px
.purchase-btn
  border: 2px solid #e6e6e6
  border-radius: 20px
  color: #214163
.image
  width: 105px
  height: 105px
  min-width: 105px
  img
    width: 100%
    height: 100%
    object-fit: cover
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: #fff
  max-height: 60vh
  max-height: 60dvh

.modal-big
  --width: 450px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.sub-title
  font-size: 18px
  font-weight: bold
.title
  font-size: 24px
  font-weight: bold
  text-align: center
</style>
