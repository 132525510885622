<template>
  <div>
    <ion-item class="item-container clickable-item-hov" @click="templateOptions('edit')">
      <div class="menu-item d-flex align-items-center">
        <i class="ti-marker-alt mr-2 menu-icon" />
        Edit Name
      </div>
    </ion-item>
    <ion-item class="item-container clickable-item-hov" @click="templateOptions('duplicate')">
      <div class="menu-item d-flex align-items-center">
        <i class="ti-layers-alt mr-2 menu-icon" />
        Duplicate
      </div>
    </ion-item>
    <ion-item class="item-container clickable-item-hov" @click="templateOptions('delete')">
      <div class="menu-item d-flex align-items-center delete-item">
        <i class="ti-trash mr-2 menu-icon delete-item" />
        Delete
      </div>
    </ion-item>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  instance: { type: Object, default: () => ({}) },
  id: { type: null, default: () => ({}) },
});

async function templateOptions(value: string) {
  await props.instance.dismiss({ value });
}
</script>

<style scoped lang="sass">
.menu-icon
  width: 18px
  height: 18px

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

.delete-item
  color: var(--ion-color-danger) !important
.dark .delete-item
  color: var(--ion-color-danger) !important
</style>
