<template>
  <div ref="elRef">
    <div class="content-container" :class="{ 'content-container--collapsed': !expanded }">
      <div
        ref="el"
        class="content text position-relative px-2"
        :style="{ background: `${infoSectionColor} !important` }"
        :class="expanded ? null : 'm-content'"
      >
        <div
          class="dynamic-content"
          :style="{ color: defaultTextColor, background: `${infoSectionColor} !important` }"
          v-html="sanitizeHtml(text)"
        ></div>
      </div>
      <div v-if="!expanded && showSeeMore" class="fade"></div>
    </div>
    <div v-if="showSeeMore" class="w-100 position-relative text-center see-more-container">
      <span v-if="!expanded" class="text-center see-more clickable-item mt-2 bold" @click="seeMore">See More</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { mainStore } from '@/shared/pinia-store/main';
import { sanitizeHtml } from '@/shared/utils/html';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: '',
  },
  customize: {
    type: Object,
    default: {},
  },
});
const el = ref<HTMLElement | null>(null);
const { heightRef } = useSizeObserver(el);

const text = toRef(props, 'text');
const link = toRef(props, 'link');
const customize = toRef(props, 'customize');
const expanded = ref(false);
const route = useRoute();

function checkOverflow(el: any) {
  if (!el) return false;
  const updateHeight = heightRef.value; // hack to keep this responsive
  const curOverflow = el.style.overflow;
  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';
  const isOverflowing = el.clientHeight < el.scrollHeight;
  el.style.overflow = curOverflow;
  return isOverflowing;
}

const showSeeMore = computed(() => {
  return checkOverflow(el.value as any) && !expanded.value;
});

const expand = () => {
  expanded.value = true;
};

const { isTempDefault } = useCustTempDefault(props);

const isdark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});
const infoSectionColor = computed(() => {
  const defaultClr = isdark.value ? '#17074C' : '#E6E6E6';
  return isTempDefault.value ? defaultClr : get(customize.value, 'data.theme.backgrounds.infoSection') || defaultClr;
});

const defaultTextColor = computed(() => {
  const defaultClr = isdark.value ? '#FFFFFF' : '#000000';
  return isTempDefault.value
    ? `${defaultClr} !important`
    : `${get(customize.value, 'data.theme.text.default.color', defaultClr)} !important`;
});
const defaultTextFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.default.font') || 'Roboto';
});

const defaultTextBold = computed(() => {
  return isTempDefault.value && !get(route.query, 'tempId')
    ? 'normal'
    : get(customize.value, 'data.theme.text.default.bold')
    ? '800'
    : 'normal';
});
const defaultTextItalic = computed(() => {
  return get(customize.value, 'data.theme.text.default.italics') ? 'italic' : 'normal';
});

const seeMore = () => {
  if (!link.value) return expand();
  const router = useRouter();
  router.push(link.value);
};

function useSizeObserver(elRef: any) {
  const widthRef = ref(0);
  const heightRef = ref(0);
  try {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        widthRef.value = entry.contentRect.width;
        heightRef.value = entry.contentRect.height;
      }
    });
    onMounted(() => resizeObserver.observe(elRef.value));
    onBeforeUnmount(() => resizeObserver.disconnect());
  } catch (e) {
    // does not support ResizeObserver; do not fold sections
    expanded.value = true;
  } finally {
    return {
      widthRef,
      heightRef,
    };
  }
}
</script>

<style lang="sass" scoped>
.content-container
  position: relative
.dark .content-container
  color: v-bind(defaultTextColor)
  font-weight: v-bind(defaultTextBold) !important
  font-style: v-bind(defaultTextItalic) !important
  font-family: v-bind(defaultTextFont) !important

.content-container--collapsed::after
  content: ''
  position: absolute
  bottom: 0
  left: 0
  right: 0
  height: 100px
  background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, transparent 100%)
  pointer-events: none
.m-content
  max-height: 150px
  overflow: hidden
  text-overflow: ellipsis
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  word-break: break-word
.fade
  position: absolute
  bottom: 0
  left: 0
  right: 0
  height: 50px
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, v-bind(infoSectionColor) 100%)
.dark .fade
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, v-bind(infoSectionColor) 100%)
.see-more-container
  display: block
  height: 30px
.see-more
  display: block
  position: relative
  width: 150px
  font-size: 16px
  margin: 0 auto
  padding: 0.2rem 1rem
  color: v-bind(defaultTextColor)
  font-family: v-bind(defaultTextFont)
.dark .see-more
  color: v-bind(defaultTextColor)
.clickable-item:hover
  opacity: 0.7
.dark .content
  background: v-bind(infoSectionColor) !important
.content
  font-weight: v-bind(defaultTextBold) !important
  ::v-deep
    .dynamic-content
      *
        font-family: v-bind(defaultTextFont)

.dark .m-content
  color: v-bind(defaultTextColor)
</style>
