<template>
  <div class="d-flex flex-column">
    <div class="p-2 section">
      <div class="d-flex align-items-center mx-auto justify-content-center flex-column">
        <ProfileRing
          class="pfp m-2"
          :width="70"
          :height="70"
          :borderWidth="88"
          :image="get(profileRing, 'image') || prfRingImage"
          :usercharacter="selectedUserChar"
          id="o-pfp-1"
        />

        <div class="container" :class="{ 'char-padding': !isNotCharacter }">
          <span class="username d-flex align-items-center justify-content-center mx-1">
            <router-link :to="profileLinkTarget">
              {{
                selectedUserChar.hasOwnProperty('slug') || isDraft
                  ? selectedUserChar.info.name
                  : selectedUserChar.username
              }}
            </router-link>
          </span>
          <div class="dropdown-icon" @click="openSelectionModal" v-if="isNotCharacter">
            <i class="ti-angle-down icon"></i>
          </div>
        </div>

        <small class="m-1" v-if="isNotCharacter"
          >Switch to a Character <i class="ti-info-alt pl-1 text-primary clickable-item-hov" @click="dropdownToggle"
        /></small>
      </div>
    </div>
    <div class="p-3" :style="isNotCharacter ? 'margin-top: 143px' : 'margin-top:128px'">
      <div class="title text-black py-2">Your Rings</div>
      <ChLoading size="lg" class="mx-auto" v-if="userLoader" />
      <ProfileRingsSection
        v-else
        :items="usersPrfRings"
        @selected="ringSelection"
        :user-ring="usersPrfRings"
        :virtualItem="profileRing"
        :character="characterDetails"
        :selectedUserChar="selectedUserChar"
      />
    </div>
    <div class="p-3 shop">
      <div class="title">Ring Shop</div>
      <CurrencyBar :setNoPadding="true" :coins="get(usersCurrency, 'coins')" :gems="get(usersCurrency, 'gems')" />
      <div class="title">Discover New Rings</div>
      <ChLoading size="lg" class="mx-auto" v-if="loader" />
      <ProfileRingsSection
        v-else
        :items="featuredRings"
        :showWhiteBackground="true"
        :showShopBtn="true"
        :prfaddOns="true"
        :amountSection="true"
        @updated="fetchPrfRings"
        @fetch="fetchFeaturedPrfRings"
        :selectedUserChar="selectedUserChar"
        :shopDetails="{
          name: 'Go to the Ring Shop',
          itemGroupTitle: 'Profile Ring',
          emptyShopMessage: 'No featured rings available. Visit Ring Shop for more options.',
        }"
      />
    </div>
    <ProfileRingBearerModal
      :is-open="isOpenSelectionModal"
      @close="closeModal"
      @selected="characterSelection"
      :selectedUserChar="selectedUserChar"
    />
  </div>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
import ProfileRingsSection from './VirtualItemsSection.vue';
import ProfileRing from '@/shared/components/ProfileRing.vue';
import CurrencyBar from '@/shared/pages/store/CurrencyBar.vue';
import ProfileRingBearerModal from '@/shared/modals/ProfileRingBearerModal.vue';
import { getFeaturedVirtualItems, getUsersVirtualEarnings } from '@/shared/actions/virtualItems';
import { nanoid } from 'nanoid';
import { popovers } from '@/shared/native/popovers';
import CharacterSwitchHintPopover from '../popovers/CharacterSwitchHintPopover.vue';
import { getCharacterFromSlug } from '@/shared/actions/characters';
const { usersCurrency, user, getUsersCurrency } = authStore();

const props = defineProps({
  character: {
    type: Object,
    default: {},
  },
  isDraft: {
    type: Boolean,
    default: false,
  },
  profileRing: {
    type: Object,
    default: {},
  },
});
const isOpenSelectionModal = ref(false);
const isDraft = toRef(props, 'isDraft');
const character = toRef(props, 'character');
const characterDetails = ref({});
const loader = ref(false);
const userLoader = ref(false);
const featuredRings = ref([]);
const usersPrfRings = ref([{ id: nanoid(8), title: 'Remove Ring', icon: true }]);
const prfRingImage: any = ref(null);
const emits = defineEmits(['selected', 'userchar']);
const selectedUserChar = ref(user.value);

watch(
  character,
  () => {
    if (!isEmpty(character.value)) {
      selectedUserChar.value = character.value;
    }
  },
  { immediate: true }
);
const openSelectionModal = () => {
  isOpenSelectionModal.value = true;
};

const closeModal = (val: any) => {
  selectedUserChar.value = val;
  isOpenSelectionModal.value = false;
};
const characterSelection = async (val: any) => {
  selectedUserChar.value = val;
  if (get(selectedUserChar.value, 'slug')) {
    const resp = await getCharacterFromSlug(selectedUserChar.value.slug);
    characterDetails.value = resp;
  }
  isOpenSelectionModal.value = false;
  emits('userchar', selectedUserChar.value);
};

const dropdownToggle = (ev: MouseEvent) => {
  popovers.open(ev, CharacterSwitchHintPopover);
};
const isNotCharacter = computed(() => {
  return isEmpty(character.value);
});

const profileLinkTarget = computed(() => {
  return isDraft.value
    ? { name: 'character-profile-draft-new', params: { slug: selectedUserChar.value.id } }
    : isEmpty(get(selectedUserChar.value, 'slug'))
    ? { name: 'profile', params: { username: selectedUserChar.value.username } }
    : { name: 'character-profile-new', params: { slug: selectedUserChar.value.slug } };
});

const fetchFeaturedPrfRings = async () => {
  loader.value = true;
  const resp = await getFeaturedVirtualItems({ item_group__title: 'Profile Ring' });
  featuredRings.value = resp;
  if (featuredRings.value) {
    featuredRings.value = featuredRings.value.filter(
      (val: any) =>
        isNull(get(val, 'in_store_till')) || new Date().getTime() <= new Date(get(val, 'in_store_till')).getTime()
    );
  }
  loader.value = false;
};
const fetchUsersProfileRings = async () => {
  userLoader.value = true;
  usersPrfRings.value = [{ id: nanoid(8), title: 'Remove Ring', icon: true }];
  const resp = await getUsersVirtualEarnings({ item_group__title: 'Profile Ring' });
  usersPrfRings.value = usersPrfRings.value.concat(resp);
  userLoader.value = false;
};

const ringSelection = (ring: any) => {
  const image = get(ring, 'virtual_item.image') || ring.image;
  prfRingImage.value = image;
  emits('selected', ring);
};

const fetchPrfRings = () => {
  fetchFeaturedPrfRings();
  fetchUsersProfileRings();
  getUsersCurrency();
};

onMounted(async () => {
  fetchPrfRings();
});
</script>

<style lang="sass" scoped>
.char-padding
  padding: 2px 13px 2px 13px !important
.container
  display: flex
  align-items: center
  justify-content: space-between
  padding: 2px 3px 2px 13px
  border: 1px solid #e6e6e6
  border-radius: 20px
  width: fit-content
.username
  font-size: 16px
.dropdown-icon
  display: flex
  align-items: center
  justify-content: center
  width: 24px
  height: 24px
  background-color: #214163
  border-radius: 50%
  cursor: pointer
.icon
  color: white
  font-size: 12px
  font-weight: bold
.shop
  background: linear-gradient(rgb(214, 190, 255) 0%, rgb(213 201 255) 49%, rgb(210 225 255) 100%)
.dark .shop
  background: none
.pfp
  width: 70px
  height: 70px
.circle-border
  width: 50px
  height: 50px
  border-radius: 24px
  border: 4px solid
.section
  background-color: white
  box-shadow:  rgba(0, 0, 0, 0.4) 0px 0px 3px, rgba(0, 0, 0, 0.3) 0px 7px 13px -6px, rgba(0, 0, 0, 0.2) 0px 1px 7px inset
  width: 100%
  z-index: 64
  position: fixed
.dark .section
  background-color: #17074C
.title
  font-weight: 900
  font-size: 18px
</style>
