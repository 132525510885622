const validResizeStrs = ['80x80', '190h', '240h', '250x250', '380h', '600x600', '800w', '1280w'];

export const resizeUpload = (image?: string, res?: string) => {
  /*    
      Gets thumbnail URL based on given image and desired supported resolution. Only works for user uploads.
          image: 'my-cdn.com/users/5deadfa9-0642-4985-a7a0-87632fa5a222/14b92cb4-80bf-40d0-b68a-54d8301b6604_l'
          res:   '80x80':
          my-cdn.com        /users/5deadfa9-0642-4985-a7a0-87632fa5a222/14b92cb4-80bf-40d0-b68a-54d8301b6604_l
      * -> my-cdn.com/r/80x80/users/5deadfa9-0642-4985-a7a0-87632fa5a222/14b92cb4-80bf-40d0-b68a-54d8301b6604_l
    */
  try {
    if (!image || !res) return image;
    if (!validResizeStrs.includes(res)) return image;
    if (image.includes('s3.amazonaws')) return image;
    if (image.includes('/r/')) return image.replace(/\/r\/[^/]+\//, `/r/${res}/`);
    return image.replace('/users/', `/r/${res}/users/`);
  } catch (e) {
    return image;
  }
};
