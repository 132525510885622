<template>
  <client-only>
    <ion-content class="subs-modal" ref="modal" @willDismiss="dismissModal" scroll-y="false">
      <ion-header>
        <ion-toolbar>
          <ion-title mode="md" slot="start"><strong>Unlock The Full Experience!</strong></ion-title>
          <ion-buttons slot="end">
            <ion-button size="large" @click="dismissModal">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div class="content" v-if="modifySubscriptionPaypalOrder">
        <div v-if="loading" class="loading-spinner">Loading...</div>
        <div v-else class="text-center text-white">
          <p class="invoice-amount" v-if="modifySubscriptionPaypalOrder?.amount">
            Your prorated price for this interval is: ${{ modifySubscriptionPaypalOrder?.amount }}
          </p>
          <p v-else>This change will go into effect at the end of your current billing cycle.</p>
          <div class="mx-auto pbc" id="paypal-button-container-modify"></div>
          <div v-if="!modifySubscriptionPaypalOrder?.order">
            <ion-button @click="confirmModifySubscription">Confirm Subscription Change</ion-button>
          </div>
        </div>
      </div>
      <div class="content" v-else-if="!endingAnimationToShow">
        <img loading="lazy" v-if="!checkoutOpen" :src="bgToShow" class="bg" />
        <ion-segment :disabled="loading" v-if="!checkoutOpen" color="tertiary" :value="productSelected" mode="md">
          <ion-segment-button
            value="CharacterHub Plus"
            :class="{ pl: productSelected === productNames[0] }"
            class="segment"
            @click="selectProduct(productNames[0])"
          >
            <ion-label class="no-select" color="tertiary" :class="{ pl: productSelected === productNames[0] }"
              >+plus</ion-label
            >
          </ion-segment-button>
          <ion-segment-button
            :class="{ ultra: productSelected === productNames[1] }"
            value="CharacterHub Plus Ultra"
            class="segment"
            @click="selectProduct(productNames[1])"
          >
            <ion-label class="no-select" color="tertiary" :class="{ ultra: productSelected === productNames[1] }"
              >+plus ULTRA</ion-label
            >
          </ion-segment-button>
        </ion-segment>
        <section
          :class="[checkoutOpen ? (checkoutOpen === 'modify' ? 'info-full-n' : 'info-full') : 'info']"
          id="scroll-info"
        >
          <div class="features pb-4" v-if="productSelected === productNames[0] && !checkoutOpen">
            <div v-html="htmlPlus" />
          </div>
          <div class="features pb-4" v-else-if="productSelected === productNames[1] && !checkoutOpen">
            <div v-html="htmlUltra" />
          </div>
          <div>
            <div class="w-100 d-flex flex-column justify-content-center">
              <div class="px-2">
                <div v-if="disablePaywall && checkoutOpen" class="no-data">
                  Payment on mobile is coming soon, if you need to purchase anything you can use the website for now.
                </div>
                <div v-else>
                  <div v-if="checkoutOpen" class="mt-1 text-white">
                    <span class="back" @click="selectProduct(productSelected)"><i class="ti-angle-left" /> Back</span>
                    <p class="text-center w-100 mx-auto bold">
                      Selected: {{ productSelected }} - ${{ priceSelected.unit_amount / 100 }}/{{
                        priceSelected.interval
                      }}
                    </p>
                    <p class="text-center mt-0 mb-1" v-if="checkoutOpen === 'subscribe' && priceSelected.discounted">
                      This discount lasts for the first year of your subscription.
                    </p>
                    <p v-if="isChangingSubscriptionPriceNextBillingCycle" class="text-center w-100 px-3 pt-1 mx-auto">
                      This change will take effect at the end of the current cycle. You will be billed at that time for
                      the amount above.
                    </p>
                    <p v-if="isChangingSubscriptionLevelImmediately" class="text-center w-100 px-3 pt-1 mx-auto"></p>
                  </div>
                  <div v-if="checkoutOpen === 'subscribe' && !currentElement">
                    <div class="payment-element-loading">
                      <p>Loading payment form...</p>
                      <ion-spinner color="white" />
                    </div>
                  </div>
                  <div>
                    <div
                      v-show="checkoutOpen === 'subscribe' && currentElement"
                      class="mx-auto pbc"
                      id="paypal-button-container"
                    ></div>
                  </div>
                  <div id="payment-element"></div>
                </div>
              </div>

              <div class="w-100 d-flex justify-content-center">
                <ion-button
                  v-if="(checkoutOpen === 'subscribe' && currentElement) || checkoutOpen === 'modify'"
                  size="large"
                  class="mt-2 mb-4 checkout"
                  @click="handleSubmitButton"
                  :disabled="loading"
                  ><span v-if="loading">Loading...</span
                  ><span v-else-if="!isChangingSubscriptionPriceNextBillingCycle">Checkout</span
                  ><span v-else>Confirm</span></ion-button
                >
              </div>
            </div>
          </div>
        </section>
        <section v-if="!checkoutOpen && pricesToShow" id="price-selection" class="fm">
          <div class="py-3 scroll">
            <div class="rounded-squares-container" v-if="!pricesToShow?.length">
              <ion-skeleton-text
                animated
                class="rounded-square d-flex align-items-center justify-content-center mb-3 unselected-border"
              />
              <ion-skeleton-text
                animated
                class="rounded-square d-flex align-items-center justify-content-center mb-3 unselected-border"
              />
            </div>
            <div class="rounded-squares-container" v-else>
              <div
                v-for="price in pricesToShow"
                :key="price.id"
                :class="{
                  'unselected-border': !priceSelected || priceSelected?.price_id !== price.price_id,
                  'selected-border-pl':
                    priceSelected?.price_id === price.price_id && productSelected === productNames[0],
                  'selected-border-ultra':
                    priceSelected?.price_id === price.price_id && productSelected === productNames[1],
                }"
                class="rounded-square d-flex align-items-center justify-content-center mb-3"
                @click="selectPrice(price)"
              >
                <ion-badge v-if="scheduledUpdate?.price_id === price.price_id" class="current-sub no-select"
                  >Scheduled
                </ion-badge>
                <ion-badge
                  color="secondary"
                  v-if="isCurrentSubscription(productSelected, price.interval)"
                  class="current-sub no-select"
                  >Current
                </ion-badge>
                <ion-badge
                  v-if="priceSelected?.price_id === price.price_id"
                  class="completed"
                  color="transparent"
                  title="Selected"
                  size="small"
                  ><img loading="lazy" class="no-select" :src="checkmarkToShow"
                /></ion-badge>
                <div>
                  <div v-if="price.discounted" class="top-badge position-absolute">Welcome Discount!</div>
                  <strong>1&nbsp;{{ price.interval }}</strong>
                  <h5 v-if="price.discounted" class="my-0 xed">${{ price.unit_prediscount / 100 }}</h5>
                  <h3
                    class="m-0"
                    :class="[
                      productSelected === productNames[0] && priceSelected?.price_id === price.price_id && 'pl',
                      productSelected === productNames[1] && priceSelected?.price_id === price.price_id && 'ultra',
                      price.discounted && 'mt-0',
                    ]"
                  >
                    ${{ price.unit_amount / 100 }}
                  </h3>
                  <div v-if="calcPercentage(price) > 0" class="discount-badge mt-1 d-inline-block">
                    Save {{ calcPercentage(price) }}%
                  </div>
                  <div v-if="price.discounted && discountEndsAt" class="bottom-counter position-absolute">
                    <vue-countdown
                      v-slot="{ days, hours, minutes, seconds }"
                      :time="discountEndsAt"
                      @end="discountRefresh"
                    >
                      <span class="countdown">
                        <span>Ends in </span><span v-if="days">{{ days }}d </span><span v-if="hours">{{ hours }}h </span
                        ><span v-if="!days">{{ minutes }}m </span><span v-if="!days && !hours">{{ seconds }}s</span>
                      </span>
                    </vue-countdown>
                  </div>
                </div>
              </div>
            </div>
            <div id="Continue" class="text-center">
              <ion-button
                v-if="prices && !checkoutOpen && !isSubscribed"
                size="large"
                class="checkout"
                @click="openCheckout"
                :class="{
                  'pl-bg': productSelected === productNames[0],
                  'ultra-bg': productSelected === productNames[1],
                }"
                :disabled="!priceSelected || loading || scheduledUpdate?.stripe || scheduledUpdate?.paypal"
                ><span v-if="!loading">Continue 🙌</span><span v-else>Loading...</span></ion-button
              >
              <ion-button
                v-if="isSubscribed && subscriptionType === 's' && !subscriptionIsCanceled"
                :disabled="
                  scheduledUpdate?.price_id ||
                  isCurrentSubscription(productSelected, priceSelected?.interval) ||
                  !priceSelected ||
                  (scheduledUpdate?.change_scheduled && priceSelected?.price_id === scheduledUpdate.price_id)
                "
                size="large"
                class="checkout"
                :class="{
                  'pl-bg': productSelected === productNames[0],
                  'ultra-bg': productSelected === productNames[1],
                }"
                @click="doPreModifySubscriptionStripe"
                >Modify Subscription
              </ion-button>
              <ion-button
                v-if="isSubscribed && subscriptionType === 'p' && !subscriptionIsCanceled"
                :disabled="
                  scheduledUpdate?.price_id ||
                  isCurrentSubscription(productSelected, priceSelected?.interval) ||
                  !priceSelected ||
                  (scheduledUpdate && priceSelected?.paypal_plan_id === scheduledUpdate.price_id)
                "
                size="large"
                class="checkout"
                :class="{
                  'pl-bg': productSelected === productNames[0],
                  'ultra-bg': productSelected === productNames[1],
                }"
                @click="doPreModifySubscriptionPaypal"
                >Review on Paypal
              </ion-button>
              <ion-button v-if="subscriptionIsCanceled" class="checkout pl-bg" disabled
                >Subscription canceled by user</ion-button
              >
            </div>
            <div class="mt-n2 mb-n1 text-center w-100">
              <ion-button v-if="scheduledUpdate?.price_id" size="small" class="mt-3" @click="cancelPendingChange"
                >Cancel pending subscription change</ion-button
              >
              <p v-if="!showReasonPressed">
                <span class="why" @click="pressShowReason">"Greedy swine! Why are you charging us?!"</span>
              </p>
              <img v-show="showReasonPressed" src="/images/sub.png" />
            </div>
            <div class="text-center mt-0 w-100">
              <small class="mx-auto d-block w-75"
                >Your monthly or annual subscription automatically renews for the same term unless canceled at least 24
                hours prior to the end of the current term. Cancel any time in the site settings at no additional cost;
                your subscription will then cease at the end of the current term.</small
              >
            </div>
          </div>
        </section>
      </div>
      <div class="content2" v-else>
        <div class="text-center now-on h-75">
          <h3 class="text-white">You are now on {{ productSelected }}!</h3>
          <img
            class="bg-end mt-3"
            :src="endingAnimationToShow"
            height="300"
            width="300"
            style="border-radius: 50%; box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5)"
          />
        </div>
        <div class="text-center">
          <ion-button size="large" class="end-close" @click="dismissModal">Close</ion-button>
        </div>
      </div>
    </ion-content>
  </client-only>
</template>
<script lang="ts" setup>
import StripeService from '@/shared/services/stripe';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import { modalController } from '@ionic/vue';
import { formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import { analyticsSetUser } from '@/shared/services/analytics';
import { fetchSiteMessageByName } from '@/shared/actions/main';
import { onCloseUsersPayment } from '@/shared/actions/users';
import { loadScript } from '@paypal/paypal-js';
import {
  initiateSubscriptionCheckoutPaypal,
  verifySubscriptionCheckoutPaypal,
  preModifySubscriptionPaypal,
  confirmModifySubscriptionPaypal,
} from '@/shared/actions/payments';
import logger from '@/shared/services/logger';
import { featureFlags } from '../config/feature-flags';
import { useMobile } from '@/apps/mobile/composables/useMobile';
const { trackAmplitudeEvent } = useAnalytics();
const { hydrateUser, refreshUserSubscriptionLevel, setUserSubscriptionLevel } = authStore();
const {
  public: { stripeKey, paypalClientId },
} = useRuntimeConfig();
const priceSelected = ref(null) as any;
const isPaid = ref(false);
const productNames = StripeService.productNames;
const prices = ref({}) as any;
const modifySubscriptionPaypalOrder = ref(null) as any;
const scheduledUpdate = ref(null) as any; // if there is a scheduled change to the subscription at end of billing cycle, what is it?
const loading = ref(true);
const productSelected = ref('CharacterHub Plus');
let stripe = null as any;
const elements = ref(null) as any;
const checkoutOpen = ref('');
const currentElement = ref(null) as any;
const pressedModifySubscription = ref(false);
const stripeService = ref(null) as any;
const showReasonPressed = ref(false);
const endingAnimationToShow = ref('') as any;
const ENDING_ANIMATIONS = {
  'CharacterHub Plus': '/images/plus-get-480.webp',
  'CharacterHub Plus Ultra': '/images/ultra-get-480.webp',
};
const htmlPlus = ref('<div />');
const htmlUltra = ref('<div />');
const { isOnboarding } = authStore();
const props = defineProps({
  openedFrom: {
    type: String,
    default: '',
  },
  goToSuccessTier: {
    type: String, // CharacterHub Plus
    default: 0,
  },
  subscriptionData: {
    type: Object,
    default: null,
  },
});
const openedFrom = toRef(props, 'openedFrom');
const goToSuccessTier = toRef(props, 'goToSuccessTier');
const subscriptionData = toRef(props, 'subscriptionData');

const disablePaywall = computed(() => {
  const { isMobile } = useMobile();

  return isMobile.value && !featureFlags.paywallOnMobile;
});

const paypalButtonDetails = {
  style: {
    color: 'black',
    borderRadius: 5,
    height: 53,
    tagline: 'false',
  },
  enableFunding: ['credit'],
  disableFunding: ['paylater'],
  createSubscription: async function (data: any, actions: any) {
    collapseStripe();
    loading.value = true;
    try {
      const sub = await initiateSubscriptionCheckoutPaypal(priceSelected.value.paypal_plan_id);
      if (sub.id) {
        return sub.id;
      }
      throw new Error();
    } catch (e) {
      console.error(e);
      toast.show(`Could not complete subscription order. Please try again in a moment.`, 'nonative', 'danger');
    } finally {
      loading.value = false;
    }
  },
  onApprove: async (data: any, actions: any) => {
    // https://developer.paypal.com/studio/checkout/standard/integrate
    // Three cases to handle:
    //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
    //   (2) Other non-recoverable errors -> Show a failure message
    //   (3) Successful transaction -> Show confirmation or thank you message
    try {
      loading.value = true;
      const res = await verifySubscriptionCheckoutPaypal(data.subscriptionID);
      if (!res.subscribed) {
        logger.error({
          loc: 'SubscribeModal:onApprove()',
          msg: 'Subscription not found in SubscribeModal:onApprove (paypal sub)',
          data: { serverRes: res, paypalRes: data },
        } as any);
        throw new Error('Could not confirm subscription. Please contact our chat support.');
      } else {
        endingAnimationToShow.value =
          ENDING_ANIMATIONS[productSelected.value as 'CharacterHub Plus' | 'CharacterHub Plus Ultra'];
        await hydrateUser();
      }
    } catch (error) {
      toast.show(`Could not complete transaction. Please try again in a moment.`, 'nonative', 'danger', 5000);
    } finally {
      loading.value = false;
    }
  },
  onCancel: (data: any) => {
    toast.show('Transaction canceled', 'nonative', 'primary');
  },
  onError: (err: any) => {
    if (err?.message === 'Detected popup close' || err?.message?.includes('#paypal')) {
      return;
    }
    toast.show('Transaction canceled.', 'nonative', 'primary');
  },
};

const paypalModifyButtonDetails = {
  style: {
    color: 'black',
    borderRadius: 5,
    height: 53,
    tagline: 'false',
  },
  enableFunding: ['credit'],
  disableFunding: ['paylater'],
  createOrder: async (data: any, actions: any) => {
    if (!modifySubscriptionPaypalOrder.value) {
      toast.show('Price is still loading. Please close this pop-up and try again in a moment.', 'nonative', 'danger');
      return;
    }
    loading.value = true;
    const orderId = modifySubscriptionPaypalOrder.value?.order?.id;
    if (orderId) {
      loading.value = false;
      return orderId;
    }
    toast.show(`Could not create order. Please try again in a moment.`, 'nonative', 'danger');
    loading.value = false;
  },
  onApprove: async (data: any, actions: any) => {
    const orderId = data.orderID;
    // https://developer.paypal.com/studio/checkout/standard/integrate
    // Three cases to handle:
    //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
    //   (2) Other non-recoverable errors -> Show a failure message
    //   (3) Successful transaction -> Show confirmation or thank you message
    try {
      loading.value = true;
      // const orderData = await captureOrderPaypal(orderId);
      let productTier = 0;
      if (productSelected.value === productNames[0]) {
        productTier = 1;
      } else if (productSelected.value === productNames[1]) {
        productTier = 2;
      }
      if (!productTier || !priceSelected.value) return;
      const intervalSelected = priceSelected.value.interval;

      const orderData = await confirmModifySubscriptionPaypal(productTier, intervalSelected, orderId);
      if (orderData.success) {
        // (3) Successful transaction -> Show confirmation or thank you message
        toast.show('Transaction complete', 'nonative', 'primary');

        endingAnimationToShow.value =
          ENDING_ANIMATIONS[productSelected.value as 'CharacterHub Plus' | 'CharacterHub Plus Ultra'];
        hydrateUser();
        modifySubscriptionPaypalOrder.value = null;
        loading.value = false;
        return;
      }
      const errorDetail = orderData?.error;

      if (errorDetail?.details?.[0]?.issue === 'INSTRUMENT_DECLINED') {
        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        // recoverable state, per
        // https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
        toast.show('Payment method was declined by Paypal.', 'nonative', 'danger', 10000);
        return actions.restart();
      } else if (errorDetail) {
        // (2) Other non-recoverable errors -> Show a failure message
        toast.show(`Could not complete transaction. ${errorDetail.message}`, 'nonative', 'danger', 10000);
      }
    } catch (error) {
      toast.show(`Could not complete transaction. Please try again in a moment.`, 'nonative', 'danger', 5000);
    } finally {
      loading.value = false;
    }
  },
  onCancel: (data: any) => {
    toast.show('Transaction canceled', 'nonative', 'primary');
  },
  onError: (err: any) => {
    if (err?.message?.includes('#paypal')) return toast.show('Transaction canceled.', 'nonative', 'primary');
    toast.show('Transaction canceled. ' + err?.message, 'nonative', 'primary');
  },
};

const pressShowReason = () => {
  trackAmplitudeEvent('SubscribeModal: Pressed Subtext Joke', { From: openedFrom.value });
  showReasonPressed.value = true;
};

const calcPercentage = (price: any) => {
  const monthPrice = prices.value[productSelected.value].find((i: any) => i.interval === 'month').unit_amount;
  const monthPriceAfterDiscount = price.interval === 'month' ? price.unit_amount : price.unit_amount / 12;

  return Math.round(((monthPrice - monthPriceAfterDiscount) * 100) / monthPrice) - 1;
};

const runLoad = async () => {
  loading.value = true;
  const htmlPlus1 = await fetchSiteMessageByName('submodal');
  htmlPlus.value = htmlPlus1.value?.message;
  const htmlUltra1 = await fetchSiteMessageByName('submodal-u');
  htmlUltra.value = htmlUltra1.value?.message;
  stripeService.value = StripeService.getInstance(stripeKey);
  await stripeService.value.load();
  stripe = stripeService.value.getStripe();
  try {
    const pricingDataObj = await stripeService.value.loadProductsAndPrices();

    prices.value = pricingDataObj;
    priceSelected.value = prices.value[productSelected.value][1];
  } catch (e: any) {
    toast.show('Error loading product data. Please try again in a moment.', 'nonative', 'danger');
  } finally {
    loading.value = false;
  }
  try {
    if (subscriptionType.value === 's') {
      const pendingChanges = await stripeService.value.checkForPendingChange();
      if (pendingChanges) scheduledUpdate.value = pendingChanges;
    }
  } catch (e) {
    toast.show(
      'Error loading your scheduled subscription changes. Please try again in a moment.',
      'nonative',
      'danger'
    );
  }
};

onMounted(() => {
  loadScript({
    clientId: paypalClientId,
    disableFunding: ['paylater', 'card'],
    enableFunding: ['credit'],
    vault: true,
  })
    .then((paypal: any) => {
      paypal.Buttons(paypalButtonDetails).render('#paypal-button-container');
    })
    .catch(() => {
      toast.show('Error loading Paypal form', 'nonative', 'danger');
    });
  const d = {
    plus: 'CharacterHub Plus',
    ultra: 'CharacterHub Plus Ultra',
  };
  if (goToSuccessTier.value) {
    endingAnimationToShow.value =
      ENDING_ANIMATIONS[
        d[goToSuccessTier.value as 'plus' | 'ultra'] as 'CharacterHub Plus' | 'CharacterHub Plus Ultra'
      ];
    if (subscriptionData.value?.subscription_level) {
      setUserSubscriptionLevel(
        subscriptionData.value.subscription_level,
        subscriptionData.value.subscription_duration,
        subscriptionData.value.pro
      );
    }
    return;
  }
  const { userId } = authStore();
  isPaid.value = false;
  analyticsSetUser(userId.value);
  trackAmplitudeEvent('SubscribeModal Opened', { From: openedFrom.value });
  setTimeout(() => {
    document.getElementById('scroll-info')?.scrollBy(0, 35);
    setTimeout(() => {
      document.getElementById('scroll-info')?.scrollBy(0, -35);
    }, 800);
  }, 800);
  runLoad();
});

const dismissModal = async () => {
  const { subscriptionLevel } = authStore();
  if (!isPaid.value && !isOnboarding.value && subscriptionLevel.value === 0) {
    onCloseUsersPayment();
  }

  if (currentElement.value) {
    currentElement.value.destroy();
    currentElement.value = null;
    checkoutOpen.value = '';
  }
  if (pressedModifySubscription.value) {
    setTimeout(refreshUserSubscriptionLevel, 2000);
    pressedModifySubscription.value = false;
  }
  if (!goToSuccessTier.value) {
    trackAmplitudeEvent('SubscribeModal Closed', { From: openedFrom.value });
  }
  modalController.dismiss(null, 'cancel');
};

const discountRefresh = () => {
  location.reload();
};

const collapseStripe = () => {
  elements.value.getElement('payment')?.collapse();
};

const subscriptionType = computed(() => {
  const { user } = authStore();
  return user.value?.st;
});

const subscriptionIsCanceled = computed(() => {
  return scheduledUpdate.value?.canceled;
});

const pendingChangeIsPaypal = computed(() => {
  return scheduledUpdate.value?.paypal;
});

const pendingChangeIsStripe = computed(() => {
  return scheduledUpdate.value?.stripe;
});

const isChangingSubscriptionPriceNextBillingCycle = computed(() => {
  const { subscriptionLevel, subscriptionDuration } = authStore();
  return (
    (subscriptionLevel.value === 1 &&
      productSelected.value === productNames[0] &&
      subscriptionDuration.value !== priceSelected.value.interval) ||
    (subscriptionLevel.value === 2 &&
      productSelected.value === productNames[1] &&
      subscriptionDuration.value !== priceSelected.value.interval) ||
    (subscriptionLevel.value === 2 && productSelected.value === productNames[0])
  );
});

const isChangingSubscriptionLevelImmediately = computed(() => {
  const { subscriptionLevel } = authStore();
  return subscriptionLevel.value === 1 && productSelected.value === productNames[1];
});

const cancelPendingChange = () => {
  if (scheduledUpdate.value) {
    if (pendingChangeIsStripe.value) {
      stripeService.value.cancelPendingChange();
    }
    scheduledUpdate.value = null;
    toast.show('Your scheduled subscription update has been canceled.', 'nonative', 'primary');
  }
};

const discountEndsAt = computed(() => {
  // returns nothing if past the date
  const { user } = authStore();
  const d1 = new Date(user.value.date_joined);
  const ds2 = '2024-03-15T12:00:00.000000Z';
  const d2 = new Date(ds2);
  const dateToUse = d1 > d2 ? user.value.date_joined : ds2;
  const discountEndDate = formatFromNowInMilliseconds(dateToUse, true) + 86400 * 3 * 1000;
  return discountEndDate > 0 ? discountEndDate : 0;
});

const isCurrentSubscription = (productName: string, priceInterval: string) => {
  const { subscriptionLevel, subscriptionDuration } = authStore();
  if (subscriptionLevel.value === 0) return false;
  if (productName === productNames[0] && subscriptionLevel.value === 1 && subscriptionDuration.value === priceInterval)
    return true;
  if (productName === productNames[1] && subscriptionLevel.value === 2 && subscriptionDuration.value === priceInterval)
    return true;
  return false;
};

const checkmarkToShow = computed(() => {
  if (productSelected.value === productNames[0]) {
    return '/icons/sub/check-contained2.png';
  } else if (productSelected.value === productNames[1]) {
    return '/icons/sub/check-contained.png';
  }
});

const bgToShow = computed(() => {
  if (productSelected.value === productNames[0]) {
    return '/sub-pl.png';
  } else if (productSelected.value === productNames[1]) {
    return '/sub-u.png';
  }
});

const pricesToShow = computed(() => {
  if (!prices.value || Object.keys(prices.value).length === 0) {
    return [];
  }
  return prices.value[productSelected.value];
});

const isSubscribed = computed(() => {
  const { subscriptionLevel } = authStore();
  return !!subscriptionLevel.value;
});

const markPressedModifySubscription = () => {
  pressedModifySubscription.value = true;
};

const selectProduct = (option: string) => {
  if (option) trackAmplitudeEvent('SubscribeModal: Pressed Product Tab: ' + option);
  productSelected.value = option;
  priceSelected.value = prices.value[option][1];
  if (currentElement.value) {
    currentElement.value.destroy();
    currentElement.value = null;
  }
  checkoutOpen.value = '';
};

const selectPrice = (price: any) => {
  if (priceSelected.value && priceSelected.value.price_id === price.price_id) {
    priceSelected.value = null;
    return;
  }
  if (currentElement.value) {
    currentElement.value.destroy();
    currentElement.value = null;
    checkoutOpen.value = '';
  }
  if (price) {
    const st = `Selected Product Option: ${price.product_name} (${price.interval})`;
    const discounted = price.discounted ? ' (discounted)' : '';
    trackAmplitudeEvent(`SubscribeModal: ${st} ${discounted}`);
  }
  priceSelected.value = price;
};

const doPreModifySubscriptionStripe = async () => {
  let product;
  if (productSelected.value === productNames[0]) {
    product = 'plus';
  } else if (productSelected.value === productNames[1]) {
    product = 'ultra';
  }
  if (!product || !priceSelected.value) return;
  const intervalSelected = priceSelected.value.interval;
  if (!intervalSelected) return;
  // if the selected option is the current subscription do not continue
  if (isCurrentSubscription(productSelected.value, intervalSelected)) return;
  stripeService.value.preModifySubscription(product, intervalSelected);
  checkoutOpen.value = 'modify';
};

const doPreModifySubscriptionPaypal = async () => {
  let product;
  if (productSelected.value === productNames[0]) {
    product = 1;
  } else if (productSelected.value === productNames[1]) {
    product = 2;
  }
  if (!product || !priceSelected.value) return;
  const intervalSelected = priceSelected.value.interval;
  if (!intervalSelected) return;
  if (isCurrentSubscription(productSelected.value, intervalSelected)) return;

  try {
    const paypalUrl = await preModifySubscriptionPaypal(product, intervalSelected);
    // Navigate to paypalUrl in a new tab/window
    window.location.href = paypalUrl;
  } catch (e: any) {
    if (e.response.data?.error) {
      toast.show(e.response.data.error, 'nonative', 'danger');
    } else {
      toast.show('Could not contact Paypal. Please try again later.', 'nonative', 'danger');
    }
    return;
  }

  checkoutOpen.value = 'modify';
  if (modifySubscriptionPaypalOrder.value?.order) {
    setTimeout(() => {
      nextTick(() => {
        loadScript({
          clientId: paypalClientId,
          disableFunding: ['paylater', 'card'],
          enableFunding: ['credit'],
          vault: true,
        })
          .then((paypal: any) => {
            paypal.Buttons(paypalModifyButtonDetails).render('#paypal-button-container-modify');
          })
          .catch(() => {
            toast.show('Error loading Paypal form', 'nonative', 'danger');
          });
      });
    }, 500);
  }
};

const payModifyPaypal = async () => {
  const { subscriptionLevel } = authStore();
  let product = '';
  if (productSelected.value === productNames[0]) {
    product = 'plus';
  } else if (productSelected.value === productNames[1]) {
    product = 'ultra';
  }
  if (!product || !priceSelected.value?.interval) {
    loading.value = false;
    return;
  }
  try {
    markPressedModifySubscription();
    if (subscriptionLevel.value === 2 && product === 'plus') {
      toast.show(`Your subscription will be updated on your next billing cycle.`, 'nonative', 'primary');
      dismissModal();
    } else {
      toast.show(`Your subscription settings have been updated.`, 'nonative', 'primary');
      endingAnimationToShow.value =
        ENDING_ANIMATIONS[productSelected.value as 'CharacterHub Plus' | 'CharacterHub Plus Ultra'];
    }
    modifySubscriptionPaypalOrder.value = null;
  } catch (e: any) {
    await toast.show(
      e?.response?.message || 'There was an error processing your request. Please try again in a moment.',
      'nonative',
      'danger'
    );
  } finally {
    loading.value = false;
    await hydrateUser();
  }
};

const confirmModifySubscription = async (orderId?: string) => {
  const { subscriptionLevel } = authStore();
  let product = '';
  if (productSelected.value === productNames[0]) {
    product = 'plus';
  } else if (productSelected.value === productNames[1]) {
    product = 'ultra';
  }
  if (!product || !priceSelected.value?.interval) {
    loading.value = false;
    return;
  }
  try {
    const data = await stripeService.value.modifySubscription(product, priceSelected.value.interval, orderId);
    if (data.error) {
      await toast.show(
        data.error || 'There was an error processing your request. Please try again in a moment.',
        'nonative',
        'danger'
      );
    } else {
      markPressedModifySubscription();
      if (subscriptionLevel.value === 2 && product === 'plus') {
        toast.show(`Your subscription will be updated on your next billing cycle.`, 'nonative', 'primary');
        dismissModal();
      } else {
        toast.show(`Your subscription settings have been updated.`, 'nonative', 'primary');
        endingAnimationToShow.value =
          ENDING_ANIMATIONS[productSelected.value as 'CharacterHub Plus' | 'CharacterHub Plus Ultra'];
        modifySubscriptionPaypalOrder.value = null;
      }
    }
  } catch (e: any) {
    await toast.show(
      e?.response?.data?.error || 'There was an error processing your request. Please try again in a moment.',
      'nonative',
      'danger'
    );
    selectProduct(productSelected.value);
  } finally {
    loading.value = false;
    hydrateUser();
  }
};

const openCheckout = async () => {
  if (!stripe) {
    toast.show('Payment processor could not load. Please try refreshing this page later.', 'nonative', 'danger');
    return;
  }

  if (!priceSelected.value?.price_id) {
    toast.show('Could not load prices. Please try refreshing the page.', 'nonative', 'danger');
    return;
  }
  loading.value = true;
  try {
    // Create a checkout session on the server side
    const session = await stripeService.value.initiateSubscriptionCheckout(priceSelected.value.price_id);
    // open card info fields
    elements.value = await stripe.elements({
      clientSecret: session.client_secret,
      appearance: {
        theme: 'night',
        variables: {
          colorBackground: 'rgb(44, 46, 47)',
        },
        rules: {
          '.Menu': {
            border: 'none',
            borderColor: 'rgb(44, 46, 47)',
            boxShadow: 'none',
          },
          '.AccordionItem': {
            border: 'none',
            borderColor: 'rgb(44, 46, 47)',
            boxShadow: 'none',
            fontSize: '1.15rem',
            textAlign: 'center',
          },
        },
      },
    });

    const paymentElement = elements.value.create('payment', {
      business: { name: 'CharacterHub' },
      paymentMethodOrder: ['apple_pay', 'google_pay', 'amazon_pay', 'cashapp', 'card'],
      layout: {
        type: 'accordion',
        defaultCollapsed: true,
        radios: false,
        spacedAccordionItems: true,
      },
    });
    paymentElement.mount('#payment-element');
    currentElement.value = paymentElement;

    checkoutOpen.value = 'subscribe';
  } catch (error: any) {
    if (error?.response?.data) toast.show(error.response.data, 'nonative', 'danger');
    else toast.show('Error opening payment form. Please try again in a moment.', 'nonative', 'danger');
  } finally {
    loading.value = false;
  }
};

const confirmSubscribeNewStripe = async (event: any) => {
  if (!stripe || !elements.value) {
    // Stripe.js hasn't yet loaded or elements were not created.
    // Make sure to disable form submission until Stripe.js has loaded and elements are created.
    loading.value = false;
    return;
  }

  // Trigger form validation and wallet collection
  const { error: submitError } = await elements.value.submit();
  if (submitError) {
    handleErrorStripe(submitError);
    loading.value = false;
    return;
  }
  const currentLoc = window.location.pathname + window.location.search;
  let productCode = '';
  if (productSelected.value === productNames[0]) {
    productCode = 'plus';
  } else if (productSelected.value === productNames[1]) {
    productCode = 'ultra';
  }
  const ending2 = `&km_=1&tier=${productCode}`;
  const priceId = priceSelected.value.price_id;
  const returnUrl = `${stripeService.value.getSubscriptionReturnUrl()}?price_id=${priceId}&from=${encodeURIComponent(
    currentLoc
  )}${ending2}`;
  const setupConfirmOptions = {
    elements: elements.value,
    redirect: 'if_required',
    confirmParams: {
      return_url: returnUrl,
    },
  };
  setupConfirmOptions['confirmParams'] = {
    ...setupConfirmOptions['confirmParams'],
  };
  const confirmSetupResponse = await stripe.confirmSetup(setupConfirmOptions);
  if (confirmSetupResponse.error) {
    return handleErrorStripe(confirmSetupResponse.error);
  }
  const setupIntentId = confirmSetupResponse.setupIntent.id;
  // Ask server to make the $ transaction and then confirm the subscription

  try {
    const data = await stripeService.value.confirmSubscriptionCheckout(setupIntentId, priceId);
    if (!data) {
      toast.show('Lost connection. Please try again in a moment.', 'nonative', 'danger');
      loading.value = false;
      return;
    }
    // handle errors and success case
    if (data.error || data?.subscribed === false) {
      if (data.requires_action) {
        // Handle 3D Secure authentication
        const { error: confirmationError } = await stripe.confirmCardPayment(data.client_secret);
        
        if (confirmationError) {
          // 3D Secure authentication failed
          toast.show('3D Secure authentication failed. Please try again.', 'nonative', 'danger');
          loading.value = false;
        } else {
          // 3D Secure authentication succeeded, proceed with subscription confirmation
          const confirmationResult = await stripeService.value.confirmSubscriptionCheckout(setupIntentId, priceId);
          if (confirmationResult.subscribed) {
            hydrateUser();
            endingAnimationToShow.value =
              ENDING_ANIMATIONS[productSelected.value as 'CharacterHub Plus' | 'CharacterHub Plus Ultra'];
          } else {
            toast.show('Subscription confirmation failed. Please contact support.', 'nonative', 'danger');
            loading.value = false;
          }
        }
      }
      toast.show(
        data.message || data.error || 'There was an error processing your payment. Please try again later.',
        'nonative',
        'danger'
      );

      loading.value = false;
    } else if (data?.subscribed) {
      // update user in store
      hydrateUser();
      endingAnimationToShow.value =
        ENDING_ANIMATIONS[productSelected.value as 'CharacterHub Plus' | 'CharacterHub Plus Ultra'];
    }
  } catch (e: any) {
    const msg = e?.response?.data;
    if (msg === 'Transaction declined by customer') {
      toast.show(msg, 'nonative', 'primary');
    } else {
      toast.show(msg || 'There was an error processing your payment info', 'nonative', 'danger');
    }
  } finally {
    isPaid.value = true;
    loading.value = false;
  }
};

const handleSubmitButton = async (event: any) => {
  event.preventDefault();
  // handle modify case first
  loading.value = true;
  if (checkoutOpen.value === 'modify') {
    if (modifySubscriptionPaypalOrder.value) {
      return payModifyPaypal();
    }
    return confirmModifySubscription();
  }
  return confirmSubscribeNewStripe(event);
};

const handleErrorStripe = (e: any) => {
  let errorMessage = 'There was an error processing your payment. Please try again in a moment.';
  // Provide more descriptive error messages based on Stripe's error types
  if (e.type === 'validation_error') {
    errorMessage = 'Validation error: Your payment information is incorrect. Please verify and try again.';
  } else if (e.type === 'api_connection_error') {
    errorMessage =
      'Connection error: Unable to connect to Stripe. Please check your internet connection and try again.';
  } else if (e.type === 'api_error') {
    errorMessage = 'Server error: A problem occurred with our payment service. Please try again later.';
  } else if (e.type === 'authentication_error') {
    errorMessage =
      'Authentication error: Unable to authenticate your payment method. Please try again or use a different payment method.';
  } else if (e.type === 'rate_limit_error') {
    errorMessage = 'Rate limit error: Too many requests made to the server. Please wait a moment and try again.';
  }
  toast.show(errorMessage, 'nonative', 'danger');
  loading.value = false;
};
</script>

<style lang="sass" scoped>
.no-data
  color: #FFF !important
.dark
  ion-header
    background: #17074C !important
.top-badge
  width: 85%
  top: -7px
  left: 0
  right: 0
  margin: auto
  background: var(--ch-red-color)
  color: #FFF
  font-size: 12px
  line-height: 7px
  font-weight: bold
  padding: 3px 10px
  border-radius: 20px
.bottom-counter
  bottom: -8px
  background: #fff
  border-radius: 20px
  border: 2px solid var(--ch-red-color)
  font-size: 12px
  line-height: 7px
  padding: 3px 10px
  left: 0
  right: 0
  margin: auto
  width: 115px
  font-weight: bold
.discount-badge
  background: var(--ch-red-color)
  padding: 6px 10px
  border-radius: 20px
  font-size: 14px
  color: #fff
  font-weight: bold
  line-height: 9px
.content
  background: rgba(23, 7, 76, 1)
  overflow: hidden
  height: 100%
.content2
  background: rgba(23, 7, 76, 1)
  overflow: hidden
  height: 100%
.icon
  width: 20px
  height: 20px
  margin-bottom: -4px
.checkout
  height: 35px !important
  width: 312px !important
  --border-radius: 20px !important
  font-weight: bold
.end-close
  height: 35px !important
  width: 312px !important
  --border-radius: 20px !important
  font-weight: bold
  bottom: 10%
  position: absolute
  left: 50%
  transform: translateX(-50%)

.ultra-bg
  --background: linear-gradient(131.47deg, #E338FC 0%, #AE38E5 25%, #886EFF 50%, #4FBDE5 75%, #6DFFE5 110%)

.ultra

  background: linear-gradient(131.47deg, #E338FC 0%, #AE38E5 25%, #886EFF 50%, #4FBDE5 75%, #6DFFE5 130%)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
  background-clip: text
.modal-big
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.rounded-squares-container
  width: 100%
  margin-left: auto
  margin-right: auto
  width: 312px
  display: flex
  justify-content: space-between
  align-items: center
  .gap
    flex-grow: 1
  .rounded-square
    position: relative
    height: 150px
    width: 150px
    background: white
    color: black
    border-radius: 18px
    padding: 15px, 0px, 15px, 0px
    text-align: center
    border-width: 2px
    color: var(--ch-black-color)
    div span
      user-select: none !important
    h3
      user-select: none !important
      justify-content: center
      align-items: center
      height: 100%
      font-weight: bold
    &:hover
      cursor: pointer !important
      opacity: 0.9
.completed
  position: absolute
  top: 3px
  right: 3px
  z-index: 1
  pointer-events: none
.unselected-border
  outline: 2px solid rgba(198, 200, 214, 1) !important
.selected-border-pl
  outline: 2px solid #00B4C5 !important
  border-color: #00B4C5 !important
  border: none !important
.selected-border-ultra
  border: 2px solid transparent !important
  background: linear-gradient(white, white), linear-gradient(131.47deg, #E338FC 0%, #AE38E5 25%, #886EFF 50%, #4FBDE5 75%, #6DFFE5 130%) !important
  background-origin: border-box !important
  background-clip: padding-box, border-box !important
.features
  margin-left: auto
  margin-right: auto
  width: 90% !important
  text-wrap: nowrap
  ::v-deep
    p
      img
        margin-bottom: 0 !important
        margin-top: -2px !important
.bg
  opacity: 0.12
  pointer-events: none !important
  user-select: none !important
  position: absolute
  left: 50%
  transform: translateX(-50%)
  max-width: 100% !important
  margin-top: 3rem
.bg-end
  user-select: none !important
  max-width: 100% !important
  border-radius: 20px
@media screen and (min-width: 571px)
  .features
    width: 55% !important
.pl
  font-weight: bold
  color: #00B4C5
  ion-label
    color: #00B4C5 !important
.pl-bg
  font-weight: bold
  --background: #00B4C5 !important
ion-button
  user-select: none !important
ion-segment
  --indicator-color: yellow !important
ion-segment-button
  --border-radius: 0 !important
  --color-checked: yellow
  background-color: unset
  font-weight: bold
  font-size: 1.2rem
  text-transform: unset !important
  ion-label
    color: white !important

ion-toolbar
  --background: rgba(23, 7, 76, 1)
  color: white
.info
  overflow-x: hidden
  overflow-y: scroll !important
  // margin-bottom: 243px /* Set to the height of .fm to prevent overlap */
  max-height: calc(100% - 380px)
  left: 0
  right: 0
  // padding-bottom: 1rem
.info-full
  overflow: scroll !important
  left: 0
  right: 0
  max-height: 100%
  top: 0
  padding-bottom: 1rem
.info-full-n
  overflow: hidden !important
  left: 0
  right: 0
  max-height: 100%
  top: 0
  padding-bottom: 1rem
.fm
  border-top-left-radius: 18px
  border-top-right-radius: 18px
  position: absolute
  bottom: 0
  height: 290px
  background-color: rgba(255,255,255,1)
  width: 100%
  overflow: hidden
  scrollbar-width: none !important
  -ms-overflow-style: none !important
  padding-bottom: calc(var(--safe-ios-margin)/3)
  .scroll
    height: 100%
    width: 100%
    overflow-y: scroll !important
  &::-webkit-scrollbar
    display: none !important
.ydo
  user-select: none !important
  font-weight: bold
  text-decoration: underline
  text-decoration-color: #ae38e5
.xed
  color: #ccc
  text-decoration: line-through !important
.back
  cursor: pointer
  &:hover
    opacity: 0.7
.ti-angle-left
  margin-top: 2px
  position: relative
small
  color: #808080
.why
  color: #ae38e5
  text-decoration: underline
  font-size: 0.9rem
  font-weight: bold
  &:hover
    cursor: pointer
    opacity: 0.8
#scroll-info
  scroll-behavior: smooth
.now-on
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
.current-sub
  position: absolute
  top: 3px
  left: 3px
  z-index: 1
  border-radius: 18px
  pointer-events: none
.countdown
  color: #ff6a6a
.payment-element-loading
  height: 330px !important
  color: white
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
</style>
