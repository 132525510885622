<template>
  <div class="modal-inner-content">
    <div class="px-3 py-3">
      <ChLoading size="sm" class="mx-auto" v-if="loading" />
      <ComplimentBombVerticalCard
        v-else
        v-for="(gift, index) in giftCbData"
        :key="index"
        :svg-icon="get(gift, 'svgIcon')"
        :title="gift.title"
        :description="gift.text"
        :extraText="get(gift, 'extraText')"
        :is-selected="selectedIndex === index && giftCbData.length > 1"
        :count="get(gift, 'count')"
        :type="get(gift, 'value')"
        :info="gift.info"
        :show-badge="gift.showBadge"
        @click="actionClicked(gift, index)"
        :class="{
          'mb-2 clickable-item-hov': giftCbData.length > 1,
        }"
      >
        <template #cbimage v-if="!isEmpty(animation)">
          <img loading="lazy" v-character-card-image :src="animation" class="animation" />
        </template>
      </ComplimentBombVerticalCard>

      <bomb-owned-badge v-if="showOuterBadge" class="mt-3 d-flex justify-content-center" :count="count" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import ComplimentBombVerticalCard from '@/shared/components/ComplimentBombVerticalCard.vue';
import BombOwnedBadge from '@/shared/pages/compliment-bombs/components/ComplimentBombOwnedBadge.vue';
const props = defineProps({
  giftCbData: { type: Array, default: () => [] },
  count: { type: String, default: null },
  showOuterBadge: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  animation: { type: String, default: null },
  selectedIndex: { type: Number, default: null },
});

const giftCbData: any = toRef(props, 'giftCbData');

const emits = defineEmits(['gifted']);
const count = toRef(props, 'count');

const actionClicked = (gift: any, index: any) => {
  if (gift.count === 0) return;
  emits('gifted', index);
};
</script>
<style lang="sass" scoped>
.animation
  max-width:150px !important
  height: auto

.modal-inner-content
  background: #e6e6e6
  max-height: calc(85dvh - 45px)
  max-height: calc(85vh - 45px)
  overflow: auto
  overflow-x: hidden
</style>
