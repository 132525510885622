import { Module } from 'vuex';
import { RootState } from '../../index';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { CharactersState } from './types';

const state: CharactersState = {
  homeCharacters: [],
  homeCharactersPage: 0,
  homeCharactersPaging: {},
  latestCharacters: [],
  latestCharactersPage: 0,
  latestCharactersPaging: {},
  trendingCharacters: [],
  trendingCharactersPage: 0,
  trendingCharactersPaging: {},
  userCharacters: [],
  userCharactersPage: 0,
  userCharactersPaging: {},
  ownCharacters: [],
  ownCharactersPage: 0,
  ownCharactersPaging: {},
  inActionCharacter: {},
  inActionCharacterDraft: {},
  wsCommentsConnection: null,
};

export const CharactersModule: Module<CharactersState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
