<template>
  <div class="main w-100">
    <div v-if="!image || remove" class="drag-drop" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <input id="imageInput" ref="file" type="file" class="d-none" accept=".jpg,.jpeg,.png" @change="onChange" />

      <div v-if="isDragging">
        <div style="height: 50px"></div>
        Drop file here to upload!
      </div>

      <div v-else class="d-flex flex-column">
        <div><i class="ti-cloud-up icon" /></div>
        <div>Drag & drop file here to upload</div>
        <span class="mt-2">OR</span>
        <div class="d-flex justify-content-center align-items-center mt-1 text-black">
          <UploadForm :fanwork="true" @uploaded="uploadFanArt" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadForm from './upload-form.vue';
import constants from '@/shared/statics/constants';
import { toast } from '@/shared/native';
import { requestUploadImageUrl, sendImageToS3 } from '@/shared/services/upload';
import { authStore } from '@/shared/pinia-store/auth';

export default {
  components: {
    UploadForm,
  },
  props: {
    remove: Boolean,
    maxFileSizeMb: { type: Number, default: constants.uploadMaxFileSizeMB },
  },
  data() {
    return {
      isDragging: false,
      image: '',
      file: {},
    };
  },
  methods: {
    onChange() {
      const files = this.$refs.file.files;
      this.file = files[0];
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.upload();
      this.isDragging = false;
    },
    uploadFanArt(image) {
      this.image = image;
      this.$emit('uploaded', this.image);
    },
    async upload() {
      if (this.maxFileSizeMb && this.maxFileSizeMb * 1024 * 1024 < this.file.size) {
        toast.show(`Image exceeds max size (${this.maxFileSizeMb}MB)`, 'nonative', 'danger');
        return;
      }
      if (!this.file.type.startsWith('image/')) {
        toast.show(`File should be an image`, 'nonative', 'danger');
        return;
      }
      await this.uploadFile(this.file);
    },

    async uploadFile(file) {
      try {
        const threePostRequests = await requestUploadImageUrl();
        const statusCode = await sendImageToS3(threePostRequests.l.url, threePostRequests.l.fields, file);
        if (statusCode >= 400) throw new Error('Encountered an error while uploading image.');

        const imageUrl = `${threePostRequests.l.url}${threePostRequests.l.fields.key}`;

        this.$refs.file.files = null;
        this.image = imageUrl;
        this.$emit('uploaded', imageUrl);
      } catch (e) {
        if (e instanceof TypeError) {
          // Do nothing
          await toast.show(e.message, 'nonative', 'danger');
        } else {
          // Do nothing
          const error = e;
          const body = 'Could not upload file(s).';
          const message = error ? error.message || body : body;
          await toast.show(message, 'nonative', 'danger');
        }
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.icon
  font-size: 50px
.main
  align-items: center
  justify-content: center
  text-align: center

.drag-drop
  padding: 3rem
  background: #f7fafc
  border: 1px solid #e2e8f0
  border-radius: 0.25rem
  min-height: 242px
.dark .drag-drop
  background: #0e0e0e
</style>
