export const characterCardImage = (el: HTMLImageElement, binding: { value: any }) => {
  try {
    const empty = '/icons/image.svg';
    const { value } = binding;
    const router = useRouter();

    if (el.src.includes(empty)) {
      el.classList.add('mod-small-img');
      return;
    }

    if (
      !el.src ||
      (el.src.includes(router.currentRoute.value.fullPath) && router.currentRoute.value.fullPath !== '/')
    ) {
      el.src = empty;
      el.classList.add('mod-small-img');
      return;
    }

    const loader = empty;
    const fallBackImage = empty;
    const img = new Image();
    let loading = loader;
    let error = fallBackImage;
    const original = el.src;
    if (typeof value === 'string') {
      loading = value;
      error = value;
    }
    if (value instanceof Object) {
      loading = value.imageLoader || loader;
      error = value.fallBackImage || fallBackImage;
    }
    img.src = original || empty;

    img.onload = () => {
      el.src = original;
      if (original) {
        el.classList.remove('mod-small-img');
      } else {
        el.classList.add('mod-small-img');
      }
    };
    img.onerror = () => {
      el.src = empty;
      el.classList.add('mod-small-img');
    };
  } catch (e) {
    // Do nothing
  }
};
