import { deleteReactionOnCharacter, updateReactionOnCharacter, createReactionOnCharacter } from '@/shared/actions/reactions';
import { Character, UserReaction, ReactionCounts } from '@/shared/types/static-types';

export const isUserReactedAnotherReaction = (reaction: string, userReaction: any): boolean => {
  return !!(userReaction && userReaction.id && userReaction.reaction !== reaction);
};

export const isUserReactedSameReaction = (reaction: string, userReaction: any, isInstant = false): boolean => {
  return !!(userReaction && userReaction.id && (userReaction.reaction === reaction || isInstant));
};

export const updateReactionsDataForCharacter = (
  reaction: string,
  updatedUserReaction: any,
  updatedReactionCounts: any,
  newReaction: UserReaction
): Partial<Character> => {
  const reactionCounts = updatedReactionCounts || { total_count: 0 };
  const totalCount = reactionCounts.total_count;
  let userReaction = updatedUserReaction;
  const prevUserReaction = userReaction as UserReaction;

  // editing counts if un-reacting or editing reaction
  if (userReaction as UserReaction) {
    reactionCounts[prevUserReaction.reaction as keyof ReactionCounts]--;
    if (newReaction.reaction in reactionCounts) {
      reactionCounts[newReaction.reaction as keyof ReactionCounts]++;
    } else {
      // ignore; newReaction.reaction will never be "ordering" (which requires type string[])
      //  @ts-ignore
      reactionCounts[newReaction.reaction as keyof ReactionCounts] = 1;
    }
  }
  // if reacting to story for the first time: editing counts & user_reaction
  if (!userReaction?.id) {
    reactionCounts!.total_count = totalCount + 1;
    //  @ts-ignore
    if (!reactionCounts[newReaction.reaction as keyof ReactionCounts]) {
      //  @ts-ignore
      reactionCounts[newReaction.reaction as keyof ReactionCounts] = 1;
    } else {
      //  @ts-ignore
      reactionCounts[newReaction.reaction as keyof ReactionCounts] += 1;
    }
    userReaction = newReaction;
  }

  // if editing reaction
  if (isUserReactedAnotherReaction(reaction, userReaction)) {
    userReaction!.reaction = reaction;
  }

  // if un-reacting
  if (isUserReactedSameReaction(reaction, userReaction) && !newReaction) {
    reactionCounts!.total_count = totalCount > 0 ? totalCount - 1 : 0;
    userReaction = undefined;
  } else {
    userReaction = {
      ...userReaction,
      reaction,
    };
  }

  const out = {
    user_reaction: userReaction,
    reaction_counts: reactionCounts,
  };
  return out;
};

export const updatedSendReactionToBackend = async (
  reaction: string,
  character: Character,
  userReaction: any,
  fromRoulette: boolean = false,
  isInstant: boolean = false
) => {
  const target = 'character';
  const { id: characterId = '' } = character;

  // delete
  if (isUserReactedSameReaction(reaction, userReaction, isInstant)) {
    return await deleteReactionOnCharacter({ reactionId: userReaction?.id || '' });
  }

  // patch
  if (isUserReactedAnotherReaction(reaction, userReaction)) {
    return await updateReactionOnCharacter({ reactionId: userReaction?.id || '', reaction });
  }

  // post
  const data = await createReactionOnCharacter({ objectId: characterId, target, reaction, fromRoulette });
  return data;
};

export const react = async (
  reaction: any,
  character: any,
  userReaction: any,
  reactionCounts: any,
  fromRoulette: boolean = false,
  isInstant: boolean = false
) => {
  const newReaction = await updatedSendReactionToBackend(reaction, character, userReaction, fromRoulette, isInstant);
  const updatedReactionsData = updateReactionsDataForCharacter(reaction, userReaction, reactionCounts, newReaction);
  return updatedReactionsData;
};
