<template>
  <div class="d-flex flex-column">
    <div class="side-line"></div>
    <div id="timelineId" class="cards-padding">
      <ion-card :id="`timeline-${0}`" class="d-flex flex-column ml-3 m-content content ion-card-padding">
        <strong style="font-size: 17px"> 1. {{ newTimeline[0].title }} </strong>
        <div
          v-if="newTimeline[0].description && newTimeline[0].description.length"
          v-html="
            sanitizeHtml(
              (newTimeline[0].isReadmore
                ? newTimeline[0].description
                : truncate(newTimeline[0].description, { length: 300 })) + '&nbsp;'
            )
          "
        />

        <ion-button
          v-if="newTimeline[0].description && newTimeline[0].description.length > 300"
          fill="clear"
          class="ml-auto m-content"
          @click.prevent="() => toggleRead(newTimeline[0])"
        >
          <i
            :class="newTimeline[0].isReadmore ? 'ti-angle-up' : 'ti-angle-down'"
            style="font-size: 12px"
            class="mr-2"
          />
          {{ newTimeline[0].isReadmore ? 'Close' : 'Read More' }}
        </ion-button>
        <span class="circle"></span>
      </ion-card>
      <ion-card
        v-if="!showAllEntries && newTimeline.length > 2"
        class="d-flex flex-column ml-3 m-content content ion-card-padding"
      >
        <strong class="clickable-item" style="font-size: 14px" @click.prevent="showAll">
          See {{ newTimeline.length - 2 }} more entries
        </strong>
        <span class="circle"></span>
      </ion-card>
      <Transition name="slide-fade">
        <div v-if="showAllEntries && newTimeline.length > 2">
          <ion-card
            v-for="(item, index) in newTimeline.slice(1, newTimeline.length - 1)"
            :id="`timeline-${index}`"
            :key="index"
            class="d-flex flex-column ml-3 m-content content ion-card-padding"
          >
            <strong style="font-size: 17px"> {{ index + 2 }}. {{ item.title }} </strong>
            <div
              v-if="item.description && item.description.length"
              v-html="
                sanitizeHtml(
                  item.isReadmore ? item.description : truncate(item.description, { length: 300 }) + '&nbsp;'
                )
              "
            />

            <ion-button
              v-if="item.description && item.description.length > 300"
              fill="clear"
              class="ml-auto m-content"
              @click.prevent="() => toggleRead(item)"
            >
              <i :class="item.isReadmore ? 'ti-angle-up' : 'ti-angle-down'" style="font-size: 12px" class="mr-2" />
              {{ item.isReadmore ? 'Close' : 'Read More' }}
            </ion-button>
            <span class="circle"></span>
          </ion-card>
        </div>
      </Transition>
      <ion-card
        v-if="newTimeline.length > 1"
        :id="`timeline-${newTimeline.length - 1}`"
        class="d-flex flex-column ml-3 m-content content ion-card-padding"
      >
        <strong style="font-size: 17px">
          {{ `${newTimeline.length}.` }} {{ newTimeline[newTimeline.length - 1].title }}
        </strong>
        <div
          v-if="
            newTimeline[newTimeline.length - 1].description && newTimeline[newTimeline.length - 1].description.length
          "
          v-html="
            sanitizeHtml(
              newTimeline[newTimeline.length - 1].isReadmore
                ? newTimeline[newTimeline.length - 1].description
                : truncate(newTimeline[newTimeline.length - 1].description, { length: 300 }) + '&nbsp;'
            )
          "
        />

        <ion-button
          v-if="
            newTimeline[newTimeline.length - 1].description &&
            newTimeline[newTimeline.length - 1].description.length > 300
          "
          fill="clear"
          class="ml-auto m-content"
          @click.prevent="() => toggleRead(newTimeline[newTimeline.length - 1])"
        >
          <i
            :class="newTimeline[newTimeline.length - 1].isReadmore ? 'ti-angle-up' : 'ti-angle-down'"
            style="font-size: 12px"
            class="mr-2"
          />
          {{ newTimeline[newTimeline.length - 1].isReadmore ? 'Close' : 'Read More' }}
        </ion-button>
        <span class="circle"></span>
      </ion-card>
    </div>

    <ion-button v-if="showAllEntries" fill="clear" class="ml-auto mr-2 m-content" @click.prevent="showAll">
      <i class="ti-angle-up mr-2" style="font-size: 12px" />Close
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
import { mainStore } from '@/shared/pinia-store/main';
import { sanitizeHtml } from '@/shared/utils/html';

const props = defineProps({
  customize: {
    type: Object,
    default: () => ({}),
  },
  timeline: {
    type: Array,
    default: () => [],
  },
});

const timeline = toRef(props, 'timeline');
const timelineHeight = ref('100px');
const timelineTop = ref('100px');
const showAllEntries = ref(false);
const customize = toRef(props, 'customize');
const newTimeline = ref([] as any);
const route = useRoute();

const { isTempDefault } = useCustTempDefault(props);

const isdark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const { width } = useWindowSize();

watch(width, () => {
  calculateHeight();
});

const infoSectionColor = computed(() => {
  const sectionColor = get(customize.value, 'data.theme.backgrounds.page');
  return isTempDefault.value && isdark.value ? 'white' : isTempDefault.value && !isdark.value ? 'black' : sectionColor;
});
const defaultTextColor = computed(() => {
  const defaultClr = isdark.value ? '#FFFFFF' : '#000000';
  return isTempDefault.value ? defaultClr : `${get(customize.value, 'data.theme.text.default.color', defaultClr)}`;
});
const defaultTextFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.default.font', 'Roboto');
});
const defaultTextBold = computed(() => {
  return isTempDefault.value && !get(route.query, 'tempId')
    ? 'normal'
    : get(customize.value, 'data.theme.text.default.bold')
    ? '800'
    : 'normal';
});

const defaultTextItalic = computed(() => {
  return get(customize.value, 'data.theme.text.default.italics') ? 'italic' : 'normal';
});

watch(
  timeline,
  () => {
    timeline.value.map((item: any) => {
      return newTimeline.value.push({ ...item, isReadmore: false });
    });
  },
  { immediate: true }
);

const showAll = () => {
  showAllEntries.value = !showAllEntries.value;
  setTimeout(() => {
    calculateHeight();
  }, 100);
};

const calculateHeight = () => {
  const document = useDocument();
  const doc = document?.value.getElementById('timelineId') as any;
  const firstCard = document?.value.getElementById(`timeline-${0}`) as any;
  const lastCard = document?.value.getElementById(`timeline-${newTimeline.value.length - 1}`) as any;
  timelineHeight.value = `${doc?.clientHeight - 4 - (firstCard?.clientHeight / 2 + lastCard?.clientHeight / 2)}px`;
  timelineTop.value = `${firstCard?.clientHeight / 2 + 2}px`;
};

onMounted(() => {
  setTimeout(() => {
    calculateHeight();
  }, 1000);
});

const toggleRead = (item: any) => {
  item.isReadmore = !item.isReadmore;
  setTimeout(() => {
    calculateHeight();
  }, 100);
};
</script>

<style lang="sass" scoped>
.side-line
  height: v-bind(timelineHeight)
  position: absolute
  margin-top: v-bind(timelineTop)
  width: 22px
  left: 20px
  border-left: solid v-bind(defaultTextColor)

.ion-card-padding
  padding: 8px !important
  margin-top: 8px !important
  margin-bottom: 8px !important
  border: 1px solid v-bind(infoSectionColor) !important
.ios .ion-card-padding
  padding: 8px !important
  margin-top: 8px !important
  margin-bottom: 8px !important

.dark .side-line
  border-left: solid v-bind(defaultTextColor) !important

.circle
  position: absolute
  width: 18px
  height: 18px
  background-color: v-bind(defaultTextColor)
  left: -25px
  top: 50%
  transform: translateY(-50%)
  border-radius: 50%

.dark .content
  background: inherit !important
  color: v-bind(defaultTextColor) !important
  font-weight: v-bind(defaultTextBold) !important
  font-style: v-bind(defaultTextItalic) !important
  font-family: v-bind(defaultTextFont) !important

.content
  background: inherit !important
  font-weight: v-bind(defaultTextBold) !important
  ::v-deep
    .dynamic-content
      *
        font-family: v-bind(defaultTextFont)

.m-content
  color: v-bind(defaultTextColor)
.dark .m-content
  color: v-bind(defaultTextColor)
</style>
