<template>
  <div>
    <div v-for="(chart, index) of charts" :key="`cha-${chart.id}`">
      <div class="title">{{ chart.title === 'CUSTOM' ? chart.customTitle : chart.title }}</div>
      <div class="text-desc">{{ chart.yTopAxis }}</div>

      <div class="d-flex justify-content-center">
        <div class="flex-column text-left-desc flex-grow-1">{{ chart.xLeftAxis }}</div>

        <div ref="plane" class="plane">
          <div class="horizontal-lines"></div>
          <div class="vertical-line"></div>

          <div
            class="target"
            :style="{
              transform: 'translate(' + xPosition(index) + 'px, ' + yPosition(index) + 'px)',
            }"
          ></div>
        </div>

        <div class="flex-column text-right-desc flex-grow-1">{{ chart.xRightAxis }}</div>
      </div>
      <div class="text-desc">{{ chart.yBottomAxis }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { mainStore } from '@/shared/pinia-store/main';

const props = defineProps({
  customize: {
    type: Object,
    default: () => ({}),
  },
  charts: {
    type: Array,
    default: () => [],
  },
});

const charts: any = toRef(props, 'charts');
const { isTempDefault } = useCustTempDefault(props);
const customize = toRef(props, 'customize');
const isMobSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 480 && width.value > 300;
});
const isExtraMobSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 300;
});
const chartWidth = computed(() => {
  return isMobSmallScreen.value ? 160 : isExtraMobSmallScreen.value ? 100 : 250;
});
const chartHeight = computed(() => {
  return isMobSmallScreen.value ? 160 : isExtraMobSmallScreen.value ? 100 : 250;
});

const xPosition = (index: any) => {
  return (parseInt(charts.value[index].xPercentage) * chartWidth.value) / 100;
};

const isdark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const defaultTextColor = computed(() => {
  const defaultClr = isdark.value ? '#FFFFFF' : '#000000';
  return isTempDefault.value ? defaultClr : `${get(customize.value, 'data.theme.text.default.color', defaultClr)}`;
});
const planeColor = computed(() => {
  const defaultClr = isdark.value ? '#FFFFFF' : '#000000';
  return isTempDefault.value ? defaultClr : `${get(customize.value, 'data.theme.text.default.color', defaultClr)}4a`;
});

const yPosition = (index: any) => {
  return (parseInt(charts.value[index].yPercentage) * chartHeight.value) / 100;
};
</script>

<style lang="sass" scoped>
.text-left-desc
  position: relative
  left: 0
  min-width: 18%
  display: flex
  align-items: center
  justify-content: center
  font-weight: 500
  font-size: 14px
  word-wrap: break-word
  margin-left: calc((100% - v-bind('`${chartWidth}px`')) / 2)
  margin-right: 0.5rem
  @media(max-width:643px)
    margin-left: 0.5rem
    width: calc((100% - v-bind('`${chartWidth}px`')) / 2)
  @media(max-width:480px)
    font-size: 11px
    font-weight: 400
    width: calc((100% - v-bind('`${chartWidth}px`')) / 2)
  @media(max-width:350px)
    padding: 0.5rem
    margin-left: 0
    margin-right: 0
.text-right-desc
  position: relative
  left: 0
  min-width: 18%
  align-items: center
  display: flex
  justify-content: center
  font-weight: 500
  font-size: 14px
  word-wrap: break-word
  margin-right: calc((100% - v-bind('`${chartWidth}px`')) / 2)
  margin-left: 0.5rem
  @media(max-width:643px)
    margin-right: 0.8rem
    width: calc((100% - v-bind('`${chartWidth}px`')) / 2)
  @media(max-width:480px)
    font-size: 12px
    font-weight: 400
    width: calc((100% - v-bind('`${chartWidth}px`')) / 2)
  @media(max-width:350px)
    padding: 0.5rem
    margin-left: 0
    margin-right: 0
.title
  font-weight: bold
  font-size: 18px
  color: v-bind(defaultTextColor) !important
.dark .title
  color: v-bind(defaultTextColor) !important
.plane
  position: relative
  width: v-bind('`${chartWidth}px`')
  min-width: v-bind('`${chartWidth}px`')
  height: v-bind('`${chartHeight}px`')
  border-radius: 12px
  border: 2px solid v-bind(planeColor)
  aspect-ratio: 1/1

.horizontal-lines, .vertical-line
  position: absolute
  background: v-bind(defaultTextColor)

.horizontal-lines
  width: 100%
  height: 2px
  top: 50%
  transform: translateY(-50%)

.vertical-line
  width: 2px
  height: 100%
  left: 50%
  transform: translateX(-50%)
.target
  position: relative
  width: 16px
  height: 16px
  border-radius: 20px
  background: v-bind(defaultTextColor)
  z-index: 1
.text-desc
  display: flex
  justify-content: center
  align-items: center
  font-weight: 500
  font-size: 14px
  margin: 0.5rem
  @media(max-width:480px)
    font-size: 12px
    font-weight: 400
</style>
