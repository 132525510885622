<template>
  <ion-modal class="modal-big" :is-open="isOpen" mode="md" :backdrop-dismiss="false" :show-backdrop="true">
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center px-3">
          <div></div>
          <div>
            <div class="title">Profile Ring Bearer</div>
          </div>
          <div>
            <ion-buttons slot="end">
              <ion-button @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="modal-inner-content">
      <div class="p-3">
        <div class="sub-title">Choose Profile Ring Bearer:</div>
        <div class="py-3">
          <SearchInput
            v-model="text"
            :search-icon="true"
            :shortcut-icon="false"
            :clear-icon="false"
            :hide-shortcut-icon-on-blur="true"
            :clear-on-esc="false"
            :blur-on-esc="true"
            :select-on-focus="false"
            :shortcut-listener-enabled="true"
            placeholder="Aa"
            @input="onSearch"
          />
        </div>

        <div v-if="isloading" class="d-flex justify-content-center mt-2">
          <ChLoading size="sm" class="spinner" />
        </div>

        <Grid
          v-else-if="!isEmpty(userCharacters)"
          :scrollSm="false"
          :sm="2"
          class="char-auto-scroll comp-section-scroll-height"
        >
          <CharacterCard
            v-for="character in userCharacters"
            :key="`char-${character.id}`"
            class="grid-item clickable-item-hov"
            :character="character"
            :selected-char="selectedCharacter.id === character.id"
            @click="isCharacterSelected(character)"
          />
          <ChLoading size="sm" v-if="isCharLoading" class="spinner" />
          <p v-else-if="userCharsPaging.next && !isCharLoading" class="clickable" @click="requestLoadMore">Load More</p>
        </Grid>
        <div v-else class="d-flex justify-content-center align-items-center text-center py-3">
          <span style="text-decoration: underline; color: var(--ion-color-primary)">
            <router-link :to="{ name: 'character-creator' }" @click="dismissModal"> Create a Character!</router-link>
          </span>
        </div>
      </div>
    </div>

    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button :disabled="isEmpty(selectedCharacter.id)" class="choose-btn" @click="chooseChar">
            Choose
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import SearchInput from 'vue-search-input';
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/RingBearerCharCard.vue';
import { Character, Paging } from '@/shared/types/static-types';
import { getOwnCharacters } from '@/shared/actions/characters';
import { getNextPage } from '@/shared/helpers/pagination';
import { authStore } from '@/shared/pinia-store/auth';

const text = ref('');
const isCharLoading = ref(false);
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  selectedUserChar: {
    type: Object,
    default: {},
  },
});
const { user } = authStore();
const emits = defineEmits(['close', 'selected']);
const isOpen = toRef(props, 'isOpen');
const selectedUserChar = toRef(props, 'selectedUserChar');
const userCharacters: any = ref([]);
const userCharsPaging: any = ref<Paging>();
const selectedCharacter: any = ref({});
const isloading = ref(false);
watch(isOpen, async () => {
  if (isOpen.value) {
    fetchUserCharacters();
  }
});
const isCharacterSelected = (character: Character) => {
  if (selectedCharacter.value.id === character.id) {
    selectedCharacter.value = {};
  } else {
    selectedCharacter.value = character;
  }
};

watch(userCharacters, async () => {
  if (userCharacters.value) {
    const index = userCharacters.value.findIndex((item: any) => item.id === selectedUserChar.value.id);
    if (index >= 0) {
      selectedCharacter.value = selectedUserChar.value;
    }
  }
});

const userCharactersNextPage = async () => {
  isCharLoading.value = true;
  const { results, ...paging } = await getNextPage(userCharsPaging.value!);
  userCharacters.value = userCharacters.value.concat(results);
  userCharsPaging.value = paging;
  isCharLoading.value = false;
};

const requestLoadMore = async (ev: CustomEvent) => {
  if (!userCharsPaging.value.next) {
    (ev?.target as any).complete();
  } else {
    await userCharactersNextPage();
  }
};

const fetchUserCharacters = async () => {
  isloading.value = true;

  !isEmpty(text.value) ? (userCharacters.value = []) : (userCharacters.value = [user.value]);
  const { results, ...paging } = !isEmpty(text.value)
    ? await getOwnCharacters(1, 48, { search: text.value })
    : await getOwnCharacters(1, 48);
  userCharacters.value = userCharacters.value.concat(results);
  userCharsPaging.value = paging;
  isloading.value = false;
};

const dismissModal = () => {
  emits('close', selectedUserChar.value);
  resetData();
};

const resetData = () => {
  selectedCharacter.value = {};
  text.value = '';
};
const onSearch = () => {
  searchTextChanged();
};

const chooseChar = () => {
  emits('selected', selectedCharacter.value);
};

const searchTextChanged = debounce(async () => {
  await fetchUserCharacters();
}, 500);
</script>
<style lang="sass" scoped>
.char-auto-scroll
  overflow: auto
  overflow-x: hidden
.comp-section-scroll-height
  height: 38vh
.dark .toolbar
  --background: #17074c !important
.choose-btn
  --background: #00b4c5
  --border-radius: 11px
  text-transform: none
  color: white
.dark .choose-btn
  color: white
.choose-btn::part(native)
  color: white !important
.spinner
  width: 30px
  height: 30px

.quiz-content
  height: calc(100vh - 330px) !important
  overflow: auto
.modal-inner-content
  background: var(--ion-background-color, #e6e6e6)
  max-height: 89vh
  max-height: 89dvh

.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.sub-title
  font-size: 16px
  font-weight: bold
  color: #214163
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
</style>
