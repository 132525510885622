<template>
  <div v-if="index || index === 0" class="px-2 py-0 text-center" @click="dismissSelf">
    <div class="d-flex align-items-center justify-content-center">
      <h6>{{ txt }}</h6>
    </div>
    <img :src="imgsrc" />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  index: {
    type: Number,
  },
});
const index = toRef(props, 'index');
const imgsrc = computed(() => {
  return `/onboarding/q${index.value}.png`;
});
const txt = computed(() => {
  if (index.value === 0) return '';
  if (index.value === 1) return 'Choose from 100+ traits to showcase your character!';
  if (index.value === 2) return 'Bring your worlds to life with world pages!';
  if (index.value === 3) return 'Roleplay with other character lovers!';
  if (index.value === 4) return 'Take quizzes to help develop your characters!';
  if (index.value === 5) return 'Challenges and events to engage the community!';
  return '';
});
const dismissSelf = () => {
  return;
};
</script>
