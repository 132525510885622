<template>
  <div :class="containerClasses">
    <div class="d-flex align-items-center" :class="{ 'mb-2': hasDescription }">
      <div class="d-flex align-items-center justify-content-center mr-3" :class="{ 'character-image': hasCharacter }">
        <template v-if="svgIcon">
          <inline-svg class="heart-icon" :src="svgIcon" />
        </template>
        <slot name="cbimage" v-else>
          <img loading="lazy" v-character-card-image :src="imageSource" :class="imageClasses" />
        </slot>
      </div>

      <div
        class="d-flex flex-column flex-grow-1"
        :style="smallscreen ? 'max-width:calc(100% - 106px)' : ''"
        :class="{ 'reverse-direction-badge': showBadge && isEmpty(type) }"
      >
        <BombOwnedBadge v-if="showBadge" :count="count" :type="type" :info="info" />

        <div class="title mt-1" :class="{ 'title-card': extraText }">
          <router-link
            v-if="hasCharacter"
            :to="{ name: 'character-profile-new', params: { slug: character.slug } }"
            class="clickable-item-hov"
            @click="emitClose"
          >
            <div v-html="title" />
          </router-link>
          <span v-else>{{ title }}</span>
        </div>
        <div v-if="!isEmpty(extraText)" class="mt-2 text-black" v-html="extraText" />
        <div class="text-black mt-1" style="font-size: 14px" v-html="description" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { mainStore } from '@/shared/pinia-store/main';
import BombOwnedBadge from '@/shared/pages/compliment-bombs/components/ComplimentBombOwnedBadge.vue';

const props = defineProps({
  character: { type: Object, default: {} },
  title: { type: String, default: '' },
  svgIcon: { type: String, default: '' },
  boldText: { type: String, default: '' },
  description: { type: String, default: '' },
  extraText: { type: String, default: '' },
  isSelected: { type: Boolean, default: false },
  count: { type: Number, default: 0 },
  type: { type: String, default: '' },
  info: { type: String, default: '' },
  showBadge: { type: Boolean, default: false },
});
const emits = defineEmits(['close']);
const { width } = useWindowSize();
const character = toRef(props, 'character');
const info = toRef(props, 'info');
const hasCharacter = computed(() => !isEmpty(character.value));
const hasDescription = computed(() => !!props.description);
const imageSource = computed(() => {
  return get(character.value, 'info.cropProfilePicture');
});
const imageClasses = computed(() => {
  return !isEmpty(get(character.value, 'info.cropProfilePicture')) ? 'char-prof-img' : 'char-small-img';
});
const containerClasses = computed(() => {
  if (isEmpty(info.value)) {
    return {
      'comp-bomb-card px-2 py-2': !hasCharacter.value,
      active: props.isSelected,
    };
  }
});
const smallscreen = computed(() => width.value <= 275);

const emitClose = () => emits('close');
</script>
<style lang="sass" scoped>
.reverse-direction-badge
  flex-direction: column-reverse !important
.active
  border: 2px solid var(--ion-color-primary) !important
.title-card
  background: white
  border-radius: 4px
  padding: 5px 6px
  width: 100%
.dark .title-card
  background: #010123  !important
.comp-bomb-card
  border-radius: 10px
  border: 2px solid #c3c3c3
.heart-icon
  width: 90px
  height: 90px
.yay-img
  max-width: 135px
  max-height: auto
  @media(max-width:500px)
    max-width: 100px
.title
  color: #214163
  font-weight: bold
  font-size: 18px
.character-image
  width: 95px
  height: 100px
  min-width: 95px
  border-radius: 12px
  overflow: hidden
  background: #4a9fc3
  box-shadow: 0px 2px 4px 1px rgba(35, 14, 51, 0.19)
  border-radius: 12px
.char-small-img
  width: 77% !important
  height: 69% !important
.char-prof-img
  width: 100% !important
  height: 100% !important
</style>
