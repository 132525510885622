<template>
  <inline-svg class="svg-icon mr-2" :src="subPlanIcon" :class="{ 'plus-plan-bg': isPlusPlan }" />
</template>

<script lang="ts" setup>
const props = defineProps({
  plan: { type: String, default: 'free' },
});

const isUltraPlan = computed(() => {
  return props.plan === 'ultra';
});
const isPlusPlan = computed(() => {
  return props.plan === 'plus';
});

const subPlanIcon = computed(() => {
  return isUltraPlan.value ? '/cloud-upload-ultra.svg' : '/cloud-upload-outline.svg';
});
</script>

<style scoped lang="sass">
.svg-icon
  width: 24px
  height: 24px
  color: #ae38e5
.plus-plan-bg
  color: #00b4c5 !important
</style>
