<template>
  <div>
    <ion-item class="item-container" v-if="featureFlags.profileRing">
      <div class="menu-item d-flex align-items-center no-select" @click="openProfileRingModal">Add Profile Ring</div>
    </ion-item>

    <ion-item class="item-container">
      <div class="menu-item d-flex align-items-center no-select" @click="openStylizeModal">
        Customize {{ isDraft ? 'Character Draft' : 'Character' }}
      </div>
    </ion-item>
  </div>
</template>

<script lang="ts" setup>
import { featureFlags } from '@/shared/config/feature-flags';
import { OverlayInterface } from '@ionic/core';

const props = defineProps({
  instance: {
    type: Object as () => OverlayInterface,
    default: () => ({}),
  },
  isDraft: {
    type: Boolean,
    default: false,
  },
});

const openProfileRingModal = async () => {
  await props.instance.dismiss('open');
};
const openStylizeModal = async () => {
  await props.instance.dismiss('customize');
};
</script>

<style scoped lang="sass">
.item-container
  cursor: pointer

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important
</style>
