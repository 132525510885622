<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-between align-items-center p-2">
          <div class="title">Reorder Images</div>
          <div>
            <ion-button class="done-btn" @click="action">
              <ion-icon class="mr-1" :icon="checkmarkCircleOutline" />
              Done
            </ion-button>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <Sortable
        v-if="ready"
        item-key="id"
        tag="div"
        :list="toModify"
        :options="sortableOptions"
        @end="onUpdate"
        class="d-flex align-items-center flex-wrap"
      >
        <template #item="{ element, index }">
          <ImageCard class="grid-item" :image="element" :display-order-count="true" :count="index" />
        </template>
      </Sortable>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { Sortable } from 'sortablejs-vue3';
import ImageCard from '../components/ImageCard.vue';
import { checkmarkCircleOutline } from 'ionicons/icons';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  images: {
    type: Array,
    default: () => [],
  },
});
const sortableOptions = {
  handle: '.grid-item',
  animation: 150,
};
const isOpen = toRef(props, 'isOpen');
const images = toRef(props, 'images');
const emits = defineEmits(['reorder', 'close']);
const ready = ref(true);
const toModify = ref([]) as any;
const mutate = () => {
  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
};

watch(isOpen, () => {
  if (isOpen.value) {
    toModify.value = cloneDeep(images.value);
  }
});

const onUpdate = (e: any) => {
  toModify.value.splice(e.newIndex, 0, toModify.value.splice(e.oldIndex, 1)[0]);
  mutate();
};
const action = () => {
  emits(
    'reorder',
    toModify.value.filter((item: any) => item)
  );
  setTimeout(() => {
    dismissModal();
  }, 50);
};

const dismissModal = () => {
  emits('close');
};
</script>
<style lang="sass" scoped>
.grid-item
  cursor: move !important
  -webkit-user-drag: element

.done-btn
  --background: transparent !important
  border: 1px solid #00B4C5
  border-radius: 20px !important
  height: 30px
.done-btn::part(native)
  color: #00B4C5
.modal-big
  --width: 610px
  --height: 75vh
  --height: 75dvh
  @media(max-width: 560px)
    --width: 100%

.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 100vh
  max-height: 100dvh
  height: 100vh
  height: 100dvh
  overflow: auto
.close-button
  width: 50px
.sub-title
  font-size: 18px
  font-weight: bold
.title
  color: #214163
  font-size: 20px
  font-weight: bold
</style>
