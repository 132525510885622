import { MutationTree } from 'vuex';
import { BlabsState } from './types';
import { Blab } from '@/shared/types/static-types';

export const mutations: MutationTree<BlabsState> = {
  UPDATE_SUN_SHINE_STATUS: (state, { isSunshine, sunshineId }) => {
    state.isSunshine = isSunshine;
    state.sunshineId = sunshineId;
  },

  SET_IN_ACTION_BLAB: (state, inActionBlab) => {
    state.inActionBlab = inActionBlab;
  },
  SET_TRENDING_POSTS: (state, { results, paging }) => {
    state.trendingBlabs = results;
    state.trendingBlabsPaging = paging;
  },
  APPEND_TRENDING_POSTS: (state, { results, paging }) => {
    state.trendingBlabs = [...(state.trendingBlabs || []), ...results];
    state.trendingBlabsPaging = paging;
    state.trendingBlabsPage = (state.trendingBlabsPage || 0) + 1;
  },
  SET_TOP_POSTS: (state, { results, paging }) => {
    state.topBlabs = results;
    state.topBlabsPaging = paging;
  },
  APPEND_TOP_POSTS: (state, { results, paging }) => {
    state.topBlabs = [...(state.topBlabs || []), ...results];
    state.topBlabsPaging = paging;
    state.topBlabsPage = (state.topBlabsPage || 0) + 1;
  },

  SET_LATEST_POSTS: (state, { results, paging }) => {
    state.latestBlabs = results;
    state.latestBlabsPaging = paging;
  },
  APPEND_LATEST_POSTS: (state, { results, paging }) => {
    state.latestBlabs = [...(state.latestBlabs || []), ...results];
    state.latestBlabsPaging = paging;
    state.latestBlabsPage = (state.latestBlabsPage || 0) + 1;
  },

  SET_ART_POSTS: (state, { results, paging }) => {
    state.artBlabs = results;
    state.artBlabsPaging = paging;
  },
  APPEND_ART_POSTS: (state, { results, paging }) => {
    state.artBlabs = [...(state.artBlabs || []), ...results];
    state.artBlabsPaging = paging;
    state.artBlabsPage = (state.artBlabsPage || 0) + 1;
  },
  SET_STORIES_POSTS: (state, { results, paging }) => {
    state.storiesBlabs = results;
    state.storiesBlabsPaging = paging;
  },
  APPEND_STORIES_POSTS: (state, { results, paging }) => {
    state.storiesBlabs = [...(state.storiesBlabs || []), ...results];
    state.storiesBlabsPaging = paging;
    state.storiesBlabsPage = (state.storiesBlabsPage || 0) + 1;
  },
  APPLY_REACTION_ON_BLABS: (
    state,
    { blab, updatedReactionsData }: { blab: Blab; updatedReactionsData: Partial<Blab> }
  ) => {
    const { inActionBlab } = state;

    // latest
    const latestBlabs = state.latestBlabs || [];
    const findLatestBlabIndex = latestBlabs.findIndex((latestBlab) => latestBlab.id === blab.id);
    if (findLatestBlabIndex >= 0) {
      const foundLatestBlab = state.latestBlabs![findLatestBlabIndex];
      const updatedLatestBlab = {
        ...foundLatestBlab,
        ...updatedReactionsData,
      };
      state.latestBlabs![findLatestBlabIndex] = updatedLatestBlab;
    }
    // trending
    const trendingBlabs = state.trendingBlabs || [];
    const findtrendingBlabIndex = trendingBlabs.findIndex((trendingBlab) => trendingBlab.id === blab.id);
    if (findtrendingBlabIndex >= 0) {
      const foundtrendingBlab = state.trendingBlabs![findtrendingBlabIndex];
      const updatedtrendingBlab = {
        ...foundtrendingBlab,
        ...updatedReactionsData,
      };
      state.trendingBlabs![findtrendingBlabIndex] = updatedtrendingBlab;
    }

    // top

    const topBlabs = state.topBlabs || [];
    const findtopBlabIndex = topBlabs.findIndex((topBlabs: any) => topBlabs.id === blab.id);
    if (findtopBlabIndex >= 0) {
      const foundtopBlab = state.trendingBlabs![findtopBlabIndex];
      const updatedtopBlab = {
        ...foundtopBlab,
        ...updatedReactionsData,
      };
      state.topBlabs![findtopBlabIndex] = updatedtopBlab;
    }

    // art
    // art
    const artBlabs = state.artBlabs || [];
    const findartBlabIndex = artBlabs.findIndex((artBlab) => artBlab.id === blab.id);
    if (findartBlabIndex >= 0) {
      const foundartBlab = state.artBlabs![findartBlabIndex];
      const updatedartBlab = {
        ...foundartBlab,
        ...updatedReactionsData,
      };
      state.artBlabs![findartBlabIndex] = updatedartBlab;
    }
    // stories
    const storiesBlabs = state.storiesBlabs || [];
    const findstoriesBlabIndex = storiesBlabs.findIndex((storiesBlab) => storiesBlab.id === blab.id);
    if (findstoriesBlabIndex >= 0) {
      const foundstoriesBlab = state.storiesBlabs![findstoriesBlabIndex];
      const updatedstoriesBlab = {
        ...foundstoriesBlab,
        ...updatedReactionsData,
      };
      state.storiesBlabs![findstoriesBlabIndex] = updatedstoriesBlab;
    }

    if (blab.id === inActionBlab.id) {
      const updatedInactionBlab = {
        ...inActionBlab,
        ...updatedReactionsData,
      };
      state.inActionBlab! = updatedInactionBlab;
    }
  },
};
