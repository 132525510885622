<template>
  <div @click="openReportModal">
    <slot />
    <ReportModal
      :is-open="isOpenReportModal"
      @close="closeReportModal"
      :report-entity-id="reportEntityId"
      :report-entity-type="reportEntityType"
      :reported-user="reportedUser"
      :reported-from="reportedFrom"
      :report-image-url="reportImageUrl"
      :leaveChatroom="leaveChatroom"
      :leaveSocialSpace="leaveSocialSpace"
      :status="status"
    />
  </div>
</template>

<script lang="ts" setup>
import ReportModal from '@/shared/modals/ReportModal.vue';

const props = defineProps({
  isPopover: {
    type: Boolean,
    default: false,
  },
  reportedUser: {
    type: Object,
    default: {},
  },
  reportEntityId: {
    type: String,
    default: '',
  },
  reportEntityType: {
    type: String,
    default: '',
  },
  reportedFrom: {
    type: String,
    default: '',
  },
  reportImageUrl: {
    type: String,
    default: '',
  },
  leaveChatroom: {
    type: Boolean,
    default: () => false,
  },
  leaveSocialSpace: {
    type: Boolean,
    default: () => false,
  },
  status: {
    type: Object,
    default: {},
  },
});

const isPopover = toRef(props, 'isPopover');
const isOpenReportModal = ref(false);
const emits = defineEmits(['close']);
const openReportModal = () => {
  isOpenReportModal.value = true;
};
const closeReportModal = () => {
  isOpenReportModal.value = false;
  if (isPopover.value) emits('close');
};
</script>

<style lang="sass" scoped></style>
