<template>
  <div class="d-flex flex-column w-100 align-items-center justify-content-center">
    <div
      class="claimed-text d-flex align-items-center justify-content-center text-center"
      :class="{ 'claimed-text-clr': isModal }"
    >
      {{ claimedBoxes.total_claimed }}/{{ claimedBoxes.total_allowed }}
      {{ isModal ? 'Boxes Claimed' : 'BOXES CLAIMED:' }}
    </div>
    <div
      class="d-flex align-items-center justify-content-center text-center p-1 boxes"
      :style="isModal ? 'border:2px solid  #eaecf6' : ''"
    >
      <div v-for="(_, index) of [0, 1, 2, 3, 4, 5]" :key="index" class="position-relative">
        <i class="ti-check check-icon" />
        <div
          :style="index < claimedBoxes.total_claimed ? 'opacity: 0.4' : ''"
          class="d-flex aling-items-center justify-content-center small-claimed-boxes"
        >
          <inline-svg class="small-box-img" src="/gift_box.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { mainStore } from '@/shared/pinia-store/main';

const props = defineProps({
  claimedBoxes: { type: Object, default: () => {} },
  isModal: { type: Boolean, default: false },
});

const isModal = toRef(props, 'isModal');
const { dark: isDark } = mainStore();
const boxesBg = computed(() => {
  const defaultClr = isDark.value && isModal.value ? '#010123' : '#fff';
  return defaultClr;
});

const checkBg = computed(() => {
  const defaultClr = isDark.value && isModal.value ? '#fff' : '#214163';
  return defaultClr;
});

const smallBoxesBg = computed(() => {
  const defaultClr = isDark.value && isModal.value ? '#17074c' : '#eaecf6';
  return defaultClr;
});
</script>

<style lang="sass" scoped>
.small-box-img
  width: 23px
  height: auto
.small-claimed-boxes
  border-radius: 3px
  background: v-bind(smallBoxesBg)
  padding: 2px
.check-icon
  color: v-bind(checkBg)
  position: absolute
  top: 5px
  z-index: 1
  left: 5px
.boxes
  background: v-bind(boxesBg)
  gap: 5px
  border-radius: 6px
  @media(max-width:470px)
    gap: 1px
.claimed-txt-clr
  color: #214163
.dark .claimed-txt-clr
  color: white
.claimed-text
  font-weight: bold
  font-size: 18px
  @media(max-width:415px)
    font-size:16px
  @media(max-width:380px)
    font-size: 15px
</style>
