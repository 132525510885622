import axios, { AxiosRequestHeaders } from 'axios';
import { authStore } from '@/shared/pinia-store/auth';
import { storage } from '@/shared/native';
import constants from '@/shared/statics/constants';
import { chAxios } from '@/shared/lib/axios';

export function getAuthTokenHeader(): AxiosRequestHeaders {
  try {
    const { authToken } = authStore();

    if (!authToken.value) {
      return {} as AxiosRequestHeaders;
    }

    return { Authorization: `Token ${authToken.value}` } as AxiosRequestHeaders;
  } catch (error) {
    return {} as AxiosRequestHeaders;
  }
}

export async function getPreloginState() {
  let lastStateInfo = await storage.get('loginStateInfo');
  if (lastStateInfo) lastStateInfo = JSON.parse(lastStateInfo);
  await storage.remove('loginStateInfo');
  if (lastStateInfo && (lastStateInfo.path.includes('login') || lastStateInfo.path.includes('signup')))
    lastStateInfo = constants.defaultLoginStateInfo;
  return lastStateInfo || constants.defaultLoginStateInfo;
}

export async function sendPasswordResetEmail(email: string) {
  // by email
  const apiUrl = `/reset/${email}/`;
  return chAxios().post(apiUrl, {});
}

export async function resetPassword(email: string) {
  return chAxios().post(`/password-reset/`, { email });
}

export async function resetPasswordConfirm(data: {}) {
  return chAxios().post(`/password-reset/confirm/`, data);
}

export async function validateResetPasswordToken(token: string) {
  return chAxios().post(`/password-reset/validate_token/`, { token });
}

export async function changeLoggedInUserPassword(data: { old_password: string; new_password: string }) {
  await chAxios().patch(`/users/update-password/`, data);
}

export async function sendVerificationEmail(id: string) {
  // resends the e-mail verification e-mail
  const apiUrl = `/users/${id}/verify/`;
  return chAxios()
    .post(apiUrl, {})
    .catch((e) => {
      throw e;
    });
}

export function verifyEmail(token: string) {
  // verify the e-mail after following the link in the verification e-mail
  const apiUrl = `/verify/${token}/`;
  return chAxios()
    .post(apiUrl)
    .catch((e) => {
      throw e;
    });
}

export const savePreloginState = async (path: string, params: string, query: string) => {
  const prevState = { path, params, query };
  await storage.set('loginStateInfo', JSON.stringify(prevState));
};

export const setReferrer = async (referrer: any) => {
  if (!referrer) return;
  await storage.set('referrer', referrer);
};

export const getReferrer = async () => {
  try {
    return await storage.get('referrer');
  } catch (e) {
    return null;
  }
};
