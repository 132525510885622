export const useCustTempDefault = (props: any) => {
  const route = useRoute();

  const isTempDefault = computed(() => {
    return (
      isEmpty(get(props.customize, 'template_applied')) &&
      ['character-profile-draft-new', 'character-profile-new'].includes(route.name as string)
    );
  });
  return { isTempDefault };
};
