import { MutationTree } from 'vuex';
import { ListingState } from './types';
import { MarketplaceListing } from '@/shared/types/static-types';

export const mutations: MutationTree<ListingState> = {
  SET_IN_ACTION_LISTS: (state, inActionList) => {
    state.inActionList = inActionList;
  },

  SET_HOME_LISTINGS: (state, { results, paging }) => {
    state.marketplaceListings = results;
    state.marketplaceListingsPaging = paging;
  },
  APPEND_HOME_LISTINGS: (state, { results, paging }) => {
    state.marketplaceListings = [...(state.marketplaceListings || []), ...results];
    state.marketplaceListingsPaging = paging;
    state.marketplaceListingsPage = (state.marketplaceListingsPage || 0) + 1;
  },
};
