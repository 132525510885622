import { authStore } from '@/shared/pinia-store/auth';

export default defineNuxtRouteMiddleware(async () => {
  if (process.server) return;

  const { hydrateUser, user, isAuthenticated } = authStore();

  if (isAuthenticated.value && !user.value?.is_email_verified) {
    hydrateUser();
  }
});
