<template>
  <ion-modal
    class="modal-big"
    css-class=""
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex px-3 py-3 flex-column">
          <div>
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <ion-buttons v-if="!isSubmitted && isOpenSubmissionCategory">
                  <ion-button @click="goBack">
                    <i class="ti-angle-left" />
                  </ion-button>
                </ion-buttons>
              </div>

              <div class="title">{{ isSubmitted ? 'Thank You' : 'Report Content' }}</div>
              <div class="d-flex align-items-center">
                <ion-buttons>
                  <ion-button @click="dismissModal()">
                    <i class="ti-close" />
                  </ion-button>
                </ion-buttons>
              </div>
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>
    <div class="inner-content">
      <div class="d-flex justify-content-center align-items-center py-3" v-if="isLoading">
        <ChLoading size="sm" class="spinner" />
      </div>
      <div v-else>
        <div class="title px-3 py-3 mt-2" v-if="!isOpenSubmissionCategory">What type of issues are you reporting?</div>
        <div v-if="isOpenSubmissionCategory && !isSubmitted">
          <report-sub-categories-card
            :category="selectedCategory"
            :report-entity-id="reportEntityId"
            :report-entity-type="reportEntityType"
            :reported-user="reportedUser"
            :reported-from="reportedFrom"
            :report-image-url="reportImageUrl"
            @submitted="submittedReport"
          />
        </div>
        <div v-else-if="isOpenSubmissionCategory && isSubmitted">
          <report-submitted-card
            :category="selectedCategory"
            :reported-user="reportedUser"
            :leaveChatroom="leaveChatroom"
            :leaveSpace="leaveSocialSpace"
            :status="status"
            :report-entity-id="reportEntityId"
            @dismiss="dismissModal()"
          />
        </div>
        <div v-else>
          <ion-list class="bg-transparent">
            <ion-radio-group v-model="category" mode="md">
              <ion-item
                v-for="category in reportCategories"
                :key="category.id"
                lines="none"
                class="bg-transparent"
                @click="actionClicked(category)"
              >
                <ion-label class="mx-2 d-flex flex-column">
                  <div class="category-name">{{ category.title }}</div>
                  <div class="category-text">
                    {{ category.description }}
                  </div></ion-label
                >
                <ion-radio style="margin-inline-end: 36px" :value="category.title"></ion-radio>
              </ion-item>
            </ion-radio-group>
            <div class="mx-2">
              <ion-button class="w-100 next-btn" :disabled="isEmpty(category)" @click="goToSubmissionPage"
                >Next</ion-button
              >
            </div>
          </ion-list>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import constants from '@/shared/statics/constants';
import ReportSubCategoriesCard from '@/shared/components/ReportSubCategoriesCard.vue';
import ReportSubmittedCard from '@/shared/components/ReportSubmittedCard.vue';
import { getUserReportCategories } from '@/shared/actions/report';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  reportedUser: {
    type: Object,
    default: {},
  },
  reportEntityId: {
    type: String,
    default: '',
  },
  reportEntityType: {
    type: String,
    default: '',
  },
  reportedFrom: {
    type: String,
    default: '',
  },
  reportImageUrl: {
    type: String,
    default: '',
  },
  leaveChatroom: {
    type: Boolean,
    default: () => false,
  },
  leaveSocialSpace: {
    type: Boolean,
    default: () => false,
  },
  status: {
    type: Object,
    default: {},
  },
});

const isOpenSubmissionCategory = ref(false);
const selectedCategory: any = ref({});
const category = ref('');
const emits = defineEmits(['close']);
const router = useRouter();
const isOpen = toRef(props, 'isOpen');
const isSubmitted = ref(false);
const reportCategories: any = ref([]);
const isLoading = ref(false);

const resetData = () => {
  isOpenSubmissionCategory.value = false;
  selectedCategory.value = null;
  isSubmitted.value = false;
  isLoading.value = false;
  reportCategories.value = [];
  category.value = '';
};
const dismissModal = () => {
  emits('close');
};

const fetchUserReportCategories = async () => {
  isLoading.value = true;
  const resp = await getUserReportCategories();
  reportCategories.value = resp;
  isLoading.value = false;
};
watch(isOpen, () => {
  if (isOpen.value) {
    resetData();
    fetchUserReportCategories();
  }
});

const goBack = () => {
  isOpenSubmissionCategory.value ? (isOpenSubmissionCategory.value = false) : router.go(-1);
};

const submittedReport = () => {
  isSubmitted.value = true;
};

const actionClicked = (category: any) => {
  selectedCategory.value = category;
};
const goToSubmissionPage = () => {
  if (isEmpty(category.value)) return;
  isOpenSubmissionCategory.value = true;
};
</script>

<style lang="sass" scoped>
.dark .toolbar
  --background: #17074c !important
.report-categories
  padding-top: 15px
  padding-bottom: 13px
  @media(max-width:570px)
    border-bottom-color: #cdcccc
    border-bottom-width: 1px
    border-bottom-style: solid
    padding-top: 0.6rem
    padding-bottom: 0.6rem
.report-categories:hover
  background-color: #eeeeee !important
  border-radius: 10px
.category-name
  font-weight: bold
  font-size: 16px
.category-text
  font-size: 14px
  font-weight: 200
.title
  font-weight: bold
  font-size: 22px
.sub-title
  font-weight: bold
  font-size: 20px
.modal-big
  --width: 500px
  --height: auto
  --border-radius: 9px

  @media(max-width: 570px)
    --width: 100%
    --height: 100%
.next-btn
  padding-right: 2rem
  padding-left: 1.5rem
  --border-radius: 15px

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 88dvh
  //height: 88dvh
  overflow: auto
  @media(max-width: 570px)
    height: 100dvh
    max-height: 100dvh
</style>
