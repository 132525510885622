import { useSafeEnv } from './useSafeEnv';

export const useWindowSize = () => {
  const width = ref(0);
  const height = ref(0);
  const isCustomizationSize = ref(false);
  const isMobSmallScreen = ref(false);
  const isLessThan570px = ref(false);
  const isDesktopSize = ref(false);
  const isShortHeight = ref(false);

  if (process.client) {
    width.value = window.innerWidth || 0;
    height.value = window.innerHeight || 0;
    isMobSmallScreen.value = width.value <= 500;
    isLessThan570px.value = width.value <= 570;
    isCustomizationSize.value = width.value < 1365;
    isDesktopSize.value = width.value > 1184;
    isShortHeight.value = height.value < 565;

    window.addEventListener('resize', () => {
      width.value = window.innerWidth || 0;
      height.value = window.innerHeight || 0;
      isMobSmallScreen.value = width.value <= 500;
      isLessThan570px.value = width.value <= 570;
      isCustomizationSize.value = width.value < 1365;
      isDesktopSize.value = width.value > 1184;
      isShortHeight.value = height.value < 565;
    });
  } else {
    isDesktopSize.value = true;
  }

  return {
    width,
    height,
    isMobSmallScreen,
    isLessThan570px,
    isDesktopSize,
    isShortHeight,
    isCustomizationSize,
  };
};
