<template>
  <div :id="`post-reply-${message.id}`" class="position-relative">
    <!-- <div class="side-line" :class="{ 'bl-side-line': showNewSideLine }"></div> -->
    <div class="d-flex">
      <CommenterImage
        size="pre-md"
        :src="
          message.reply_as_char
            ? resizeUpload(get(message.reply_as_char, 'info.cropProfilePicture'), '80x80')
            : resizeUpload(get(message, 'user.profile_picture_url'), '80x80')
        "
        :ring="
          message.reply_as_char
            ? get(message.reply_as_char, 'profile_ring.image')
            : get(message, 'user.customize_profile.profile_ring.image')
        "
        :ringWidth="50"
        :top="-5"
        :type="message.reply_as_char ? 'char.' : 'user'"
      />

      <div class="ml-2 flex-grow-1">
        <div class="d-flex flex-column msg-content">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex flex-column">
              <div v-if="!message.reply_as_char" @click.stop>
                <router-link
                  :class="{
                    'text-color-username':
                      usernameColor(get(message, 'user')) && !usernameColor(get(message, 'user')).startsWith('#'),
                    'bold-username': usernameColor(get(message, 'user')),
                  }"
                  :style="
                    !usernameColor(get(message, 'user'))
                      ? ''
                      : usernameColor(get(message, 'user')).startsWith('#')
                      ? `color: ${usernameColor(get(message, 'user'))} !important`
                      : `background-image: ${usernameColor(get(message, 'user'))} !important`
                  "
                  class="msg-font"
                  :to="{ name: 'profile', params: { username: get(message, 'user.username') } }"
                >
                  <div class="d-flex align-items-center">
                    <div class="mr-1">@{{ get(message, 'user.username') }}</div>
                    <ProfileBadge
                      :badges="get(message, 'user.customize_profile.badges') || []"
                      :show-small-badge="true"
                    />
                  </div>
                </router-link>
              </div>
              <div v-else @click.stop>
                <router-link
                  class="msg-font"
                  :to="{ name: 'character-profile-new', params: { slug: message.reply_as_char.slug } }"
                  >{{ get(message.reply_as_char, 'info.name') }}
                </router-link>
              </div>

              <small class="timer">{{ formatTimeAgo(message.created) }}</small>
              <small v-if="message.location" class="ml-2">
                <i class="ti-location-pin icon" /> {{ message.location }}</small
              >
            </div>

            <div v-if="isBlabOwner && !isEditing" class="clickable">
              <div v-if="message.sunshined_reply" @click="notsunshining(message.id)">
                <span title="Sunshine">
                  <i class="ti-pin-alt" style="color: #3dc2ff; pointer-events: none" />
                </span>
              </div>
              <div v-else @click="sunshining(message.id)">
                <span title="Sunshine">
                  <i class="ti-pin-alt" style="pointer-events: none" />
                </span>
              </div>
            </div>
          </div>

          <div v-if="message.title && !isEditing">{{ message.title }}</div>
          <div v-if="!isEditing" v-html="sanitizeHtml(strippedMessage)" />

          <post-blab
            v-else
            :blab-editing-text="message.text"
            :blab-editing-title="message.title"
            :parent="message.id"
            :is-edit="true"
            class="mt-2"
            @cancelled="onCancel"
            @send="onEditComplete"
          ></post-blab>
        </div>
        <div v-if="!isEditing" class="d-flex justify-content-between mt-1">
          <div v-if="isAuthenticated" class="actions d-flex" style="margin-left: -3px">
            <a href="#" @click.prevent>
              <Reaction
                type="post"
                :reactions="reactions"
                :user-reaction="userReaction"
                @changed="(reaction) => reactionChanged(reaction.key, message, reaction.isInstant)"
              >
                <ion-button color="transparent" class="inline-button icon-button clickable">
                  <div class="d-flex align-items-center justify-content-center">
                    <i
                      :color="!!userReaction && userReaction.reaction ? 'secondary' : 'primary'"
                      class="ti-thumb-up mr-2"
                    />
                    <span
                      class="reaction-count"
                      :class="!!userReaction && userReaction.reaction ? 'text-secondary' : 'text-primary'"
                      >{{ reactions.total_count || 0 }}</span
                    >
                  </div>
                </ion-button>
              </Reaction>
            </a>
            <a href="#" class="ml-2 d-flex align-items-center" @click.prevent="postReply(message)">
              <i class="ti-comment" /><span v-if="!isMobSmallScreen" class="ml-1">Reply</span>
            </a>

            <a href="#" class="ml-3 d-flex align-items-center" v-if="!isMessageOwner(message)">
              <i @click.stop="dropdownToggle($event, message)" class="ti-more-alt menu-icon" />
            </a>

            <a
              v-if="isMessageOwner(message)"
              href="#"
              class="ml-2 d-flex align-items-center text-danger"
              @click.prevent="deleteReply(message.id)"
            >
              <i class="ti-trash" /><span v-if="!isMobSmallScreen" class="ml-1">Delete</span>
            </a>
            <a
              v-if="isMessageOwner(message)"
              href="#"
              class="ml-2 d-flex align-items-center"
              @click.prevent="editMessage"
            >
              <i class="ti-pencil-alt" /><span v-if="!isMobSmallScreen" class="ml-1">Edit</span>
            </a>
          </div>
          <small v-if="repliesCount" class="ml-auto mt-1 clickable-item" @click="openReplies(message.id, false)"
            >{{ repliesCount }} repl{{ repliesCount === 1 || repliesCount === 0 ? 'y' : 'ies' }}</small
          >
        </div>
      </div>
    </div>

    <div v-if="isReplyofreplies" class="mt-3 mb-1">
      <div v-for="(reply, index) of replies" :key="reply.id" class="my-2">
        <blab-nested-reply
          :id="`comment-reply-${reply.id}`"
          :message="reply"
          :blab="blab"
          :reply="true"
          @nestedReply="postReply(reply)"
          @shine="onSunshinePost(message.id)"
          @unshine="onSunshinePost(message.id)"
          @afterReaction="(reactionResp: any) => onReaction(index, reactionResp)"
          @deleted="onNestedReplyDelete"
        />
        <hr v-if="index < replies.length - 1" class="my-3" />
      </div>
      <!-- <div class="ml-5 d-flex"> -->
      <div
        v-if="repliesCount > 1 && replies.length < repliesCount"
        class="ml-5 clickable-item"
        @click="requestLoadMore"
      >
        View More Replies
      </div>
      <!-- </div> -->
      <p v-show="!nextPageExists" class="ml-5"></p>
    </div>

    <div :id="`post-blab-${message.id}`" style="scroll-margin-bottom: 200px !important">
      <post-blab
        v-if="isReply"
        :opendetails="true"
        :blab="blab"
        :is-reply="true"
        :parent="message.id"
        :reply-to-mention-name="replyToMentionName"
        class="ml-3 mt-2"
        @send="onReplyPost"
        @done="isReply = false"
        @cancelled="onCancel"
        @count="fetchReplyofRepliesCount"
      ></post-blab>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Getter } from 's-vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import { alertController } from '@ionic/vue';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import constants from '@/shared/statics/constants';
import { sanitizeHtml } from '@/shared/utils/html';
import namespace from '@/shared/store/namespace';
import { stripHtmlTags } from '@/shared/utils/string';
import {
  blabDeleteSunShineReply,
  blabSunShineReply,
  getBlabReplies,
  getCountofReplies,
  getNextPage,
  deleteBlabReply,
} from '@/shared/actions/blabs';
import PostBlab from '@/shared/pages/blabs/components/PostBlab.vue';
import BlabNestedReply from '@/shared/pages/blabs/components/BlabNestedReply.vue';
import { Blab, Paging } from '@/shared/types/static-types';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import store from '@/shared/store';
import { resizeUpload } from '@/shared/utils/upload';
import Report from '@/shared/components/Report.vue';
import CommenterImage from '@/shared/components/storage/CommenterImage.vue';
import { popovers } from '@/shared/native/popovers';
import Reaction from '@/shared/components/Reaction/index.vue';
import PostPopover from './PostPopover.vue';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { uiStore } from '@/shared/pinia-store/ui';

@Options({
  name: 'BlabMessage',
  components: { PostBlab, Reaction, BlabNestedReply, CommenterImage, Report, ProfileBadge },
})
export default class BlabMessage extends Vue {
  @Prop({ default: () => {} }) message!: any; // this is a BlabReply
  @Prop({ default: () => {} }) blab!: any;
  @Prop({ default: () => {} }) nestedReply!: any;
  @Prop({ default: false }) reply!: boolean;
  @Prop({ default: false }) maindetails!: boolean;
  @Prop({ default: false }) showNewSideLine!: boolean;
  @Prop({ default: () => {} }) repliesCount!: any;
  resizeUpload = resizeUpload;
  sanitizeHtml = sanitizeHtml;
  public isEmpty = isEmpty;
  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  @Getter('inActionBlab', { namespace: namespace.BlabsModule })
  public inActionBlab!: Blab;

  public isReply = false;
  public isEditing = false;
  public isopenReplies = false;
  public isReplyofreplies = false;
  public replies: { id: string; message: string }[] = [];
  replyofrepliesCounts = {};
  public paging: Paging | null = null;

  @Watch('isReply')
  public isReplyingChanged(val: boolean) {
    const { setIsReplying } = uiStore();
    setIsReplying(val);
  }

  hover = false;
  isSunShine = false;
  stripHtmlTags = stripHtmlTags;
  formatTimeAgo = formatTimeAgo;
  public ispost = false;
  get = get;
  commentedAsTypes = constants.commentedAsTypes;
  replyToMentionName: string | null = null;

  public get windowWidth() {
    const { width } = useWindowSize();
    return width.value;
  }

  public get isMobSmallScreen() {
    return this.windowWidth <= 420;
  }

  public usernameColor(user: any) {
    return user?.customize_profile?.username_color || '';
  }

  public onReplyPost(id: string) {
    this.isReply = false;
    this.ispost = true;
    // this.openLastReply(this.ispost);
    this.$emit('posted');
    this.$emit('count');
  }

  public dropdownToggle = async (ev: CustomEvent, message: any) => {
    const popover = await popovers.open(ev, PostPopover, {
      reportEntityId: message?.id,
      reportEntityType: 'blabreply',
      reportedUser: get(message, 'user'),
    });
    try {
      await popover.onDidDismiss();
    } catch (e) {}
  };

  public onCancel() {
    this.isEditing = false;
  }

  public onEditComplete(payload: any) {
    this.isEditing = false;
    this.message.text = payload.text;
    this.message.title = payload.title;
  }

  public async onNestedReplyDelete(id: string) {
    this.replies = this.replies.filter((reply: any) => reply.id !== id);
    this.$emit('count');
  }

  public async deleteReply(id: string) {
    try {
      const alert = await alertController.create({
        cssClass: '',
        header: 'Are you sure?',
        message: `Please confirm that you want to delete this reply.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'text-secondary',
            id: 'cancel-button',
          },
          {
            text: 'Confirm',
            id: 'confirm-button',
            role: 'ok',
            cssClass: 'text-danger',
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      if (role === 'ok') {
        await deleteBlabReply(id);
        await toast.show('Reply deleted', 'nonative', 'success');
        this.isReply = false;
        this.$emit('deleted', id);
        this.$emit('count');
      }
    } catch (e) {}
  }

  public onSunshinePost(id: string) {
    this.ispost = true;
    this.openReplies(id, this.ispost);
    this.$emit('sunshine');
  }

  public async fetchReplyofRepliesCount() {
    this.replyofrepliesCounts = {};
    const resp = await getCountofReplies(map(this.replies, 'id'));
    this.replyofrepliesCounts = keyBy(resp, 'parent_reply_id');
  }

  public onReaction(replyIndex: number, reaction: any) {
    this.replies[replyIndex] = {
      ...this.replies[replyIndex],
      ...reaction.updatedReactionsData,
    };
  }

  public get isBlabOwner() {
    try {
      return this.inActionBlab.user?.username === this.user.username;
    } catch (error) {
      return false;
    }
  }

  public isMessageOwner(message: any) {
    try {
      return message.user?.id === this.user.id;
    } catch (error) {
      return false;
    }
  }

  public open() {}

  @Watch('nestedReply')
  public async openLastReply(val: boolean) {
    if (val) {
      if (this.message.id === this.nestedReply.parent_reply_id) {
        this.replies.push(this.nestedReply);
        this.isReplyofreplies = true;
      }
    }
  }

  public async openReplies(id: string, post: boolean) {
    if (!post) this.replies = [];
    const { results, ...paging } = await getBlabReplies(this.blab, 0, 3, id, { ordering: 'created' });
    const ids = this.replies.map((res) => res.id);
    const res = results.filter((result: any) => !ids.includes(result.id));
    this.replies = this.replies.concat(res);

    if (!post) {
      this.isReplyofreplies = !this.isReplyofreplies;
    } else {
      this.isReplyofreplies = true;
    }
    this.paging = paging;
  }

  public get nextPageExists() {
    return !!this.paging?.next;
  }

  public async requestLoadMore() {
    if (!this.paging && this.replies.length) {
      this.openReplies(this.message.id, true);
    } else {
      try {
        const { results, ...paging } = await getNextPage(this.paging!);
        const ids = this.replies.map((res) => res.id);
        const res = results.filter((result: any) => !ids.includes(result.id));
        this.replies = this.replies.concat(res);
        this.paging = paging;
      } catch (e) {}
    }
  }

  public async reactionChanged(reaction: string, msg: any, isInstant = false) {
    const reactionResp = await store.dispatch('BlabsModule/reactBlabReply', { reaction, blab: msg, isInstant });
    this.$emit('afterReaction', reactionResp);
  }

  public get userReaction() {
    if (this.message.user_reaction) {
      return this.message.user_reaction;
    }
  }

  public get reactions() {
    const { reaction_counts } = this.message || {};
    return reaction_counts;
  }

  public async sunshining(id: string) {
    if (!this.isSunShine) {
      this.isSunShine = true;

      const res = await blabSunShineReply(this.blab, id);
      toast.show('Reply pinned!', 'nonative', 'success');
      this.$emit('pinned', res);
    }
  }

  public async postReply(message: any) {
    const { setIsReplying } = uiStore();
    this.replyToMentionName = message.reply_as_char
      ? get(message.reply_as_char, 'info.name', 'Unknown')
      : message.user.username;

    this.isReply = true;
    const document = useDocument();
    document.value
      .getElementById(`post-blab-${this.message.id}`)
      ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    setIsReplying(false);
  }

  public async editMessage() {
    this.isEditing = !this.isEditing;
  }

  public async notsunshining(id: string) {
    if (this.isSunShine) {
      const res = await blabDeleteSunShineReply(id, this.blab);
      this.isSunShine = false;
      this.$emit('shine');
      this.$emit('sunshine');
    }
  }

  public get strippedMessage() {
    return stripHtmlTags(this.message.text).replace(/(?:\r\n|\r|\n)/g, '<br />');
  }
}
</script>

<style lang="sass" scoped>
.timer
  position: relative
  top: -5px
  color: #A7AABE
.msg-font
  color: #ae38e5
  font-weight: bold

.dark .msg-font
  color: white

.blab-drag-icon
  font-size: 24px

.img
  width: 40px
  height: 40px
  border-radius: 20px
  border: solid gray 0.1px
  object-fit: cover

.msg-content
  border-radius: 15px
  //background-color: #F3F3F3


.side-line
  height: calc(100% - 65px)
  position: absolute
  top: 55px
  width: 22px
  left: 18px
  border-bottom: solid #CECECE
  border-left: solid #CECECE
  border-radius: 0px 10px

.dark .side-line
  border-bottom: solid #767676 !important
  border-left: solid #767676 !important

.bl-side-line
  border-bottom: solid #3dc2ff !important
  border-left: solid #3dc2ff !important

.dark .bl-side-line
  border-bottom: solid #3dc2ff !important
  border-left: solid #3dc2ff !important
</style>
