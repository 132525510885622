import { defineStore, storeToRefs } from 'pinia';
import { getShopItems, getGemShopListings, buyGemShopListing, getGemPackageListings } from '@/shared/actions/payments';
import { StoreProduct } from '@/shared/types/static-types';

export interface IShopState {
  _listings: any[];
  _gemListings: StoreProduct[]; // things you can buy with gems
  _gemPackages: any; // { bundles: [], special: [] }
}

const shop = defineStore('shop', {
  state: (): IShopState => ({
    _listings: [],
    _gemListings: [],
    _gemPackages: [],
  }),
  actions: {
    removeGemPackageListing(listingId: string) {
      for (const section in this._gemPackages) {
        const sectionListings = this._gemPackages[section];
        const index = sectionListings.findIndex((listing: any) => listing.id === listingId);
        if (index > -1) {
          sectionListings.splice(index, 1);
          break;
        }
      }
    },
    async fetchListings() {
      this._listings = await getShopItems();
    },
    async fetchGemListings() {
      const data = await getGemShopListings();
      this._gemListings = data;
    },
    async fetchGemPackageListings() {
      if (this._gemPackages?.length) return this._gemPackages;
      this._gemPackages = await getGemPackageListings();
      return this._gemPackages; // { bundles: [], special: [] }
    },
    async buyGemListing(listingId: string) {
      await buyGemShopListing(listingId);
    },
  },
  getters: {
    listings(): any[] {
      return this._listings;
    },
    gemListings(): any[] {
      return this._gemListings;
    },
    gemPackages(): any[] {
      return this._gemPackages;
    },
  },
});

export const shopStore = () => {
  const store = shop();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
