<template>
  <client-only>
    <ion-content ref="modal" @willDismiss="dismissModal" scroll-y="false">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button size="large" @click="dismissModal">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div class="inner-content">
        <section class="info w-100 ml-1">
          <div class="d-flex flex-column justify-content-center align-items-center position-relative">
            <div>
              <h2>Buy {{ productListing?.name }}</h2>
            </div>
            <img :src="productListing.image" />
            <div>
              <p>
                with <strong>{{ productListing?.amount_gems }}</strong> gems?
              </p>
            </div>
          </div>
        </section>
      </div>
      <div class="button-container">
        <div class="d-flex align-center justify-content-center">
          <ion-button size="large" color="secondary" class="done mr-4" @click="dismissModal">Close</ion-button>
          <ion-button size="large" class="done" @click="confirmSpend">Confirm</ion-button>
        </div>
      </div>
    </ion-content>
  </client-only>
</template>

<script lang="ts" setup>
import { modalController } from '@ionic/vue';
import { authStore } from '@/shared/pinia-store/auth';
import { shopStore } from '@/shared/pinia-store/shop';
import { toast } from '@/shared/native';
const { buyGemListing } = shopStore();

const props = defineProps<{
  productListing: any;
}>();
const productListing = toRef(props, 'productListing');

const gemCount = computed(() => {
  const { usersCurrency } = authStore();
  return usersCurrency.value?.gems;
});

const receivedItems = computed(() => {
  const postAction = productListing.value?.post_action;
  const items = [] as any[];
  if (!postAction) return;
  postAction.split(',').forEach((item: string) => {
    if (item?.startsWith('amethyst')) {
      let amt = item.split('amethyst')[1];
      items.push(['gem', amt]);
    }
    if (item?.startsWith('cbomb')) {
      let amt = item.split('cbomb')[1];
      items.push(['cbomb', amt]);
    }
    if (item?.startsWith('hubux')) {
      let amt = item.split('hubux')[1];
      items.push(['hubux', amt]);
    }
  });
  return items;
});

const confirmSpend = async () => {
  if (gemCount.value < (productListing.value?.amount_gems || 0)) {
    toast.show('Not Enough Gems', 'nonative', 'primary');
    return dismissModal('needgems');
  }
  try {
    await buyGemListing(productListing.value.id);
    toast.show('Transaction successful', 'nonative', 'success');
    closeModalAndContinue();
  } catch (e) {
    toast.show('Transaction failed', 'nonative', 'danger');
    console.error(e);
  }
};

const closeModalAndContinue = () => {
  modalController.dismiss(productListing.value.code, 'done');
};

const dismissModal = (code?: string) => {
  modalController.dismiss(code, 'cancel');
};
</script>

<style lang="sass" scoped>
.dark
  ion-toolbar
    --background: rgba(23, 7, 76, 1)
    color: white
  ion-title
    --background: rgba(23, 7, 76, 1)
    color: white
ion-skeleton-text
  --background: rgba(255, 255, 255, 0.065)
ion-title
  --background: rgba(23, 7, 76, 1)
  color: white
.title
  font-family: Roboto Slab, sans-serif
  color: #214163
h2
  font-size: 1.25rem
.inner-content
  position: relative
  overflow: hidden
  padding: 1rem
  img
    object-fit: contain
    margin: auto 0
    border-radius: 16px
    min-height: 120px
    max-height: 120px
.icon
  width: 20px
  height: 20px
  margin-bottom: -4px
.confirming
  background-color: gray
  border-radius: 16px
  height: 100px
  color: white
  font-weight: bold
  font-family: Roboto, sans-serif
  span
    font-size: 2rem
.button-container
  position: absolute
  bottom: 0
  width: 100%
  height: 70px !important
.done
  top: 11px
  left: 0
  right: 0
  margin: auto
  height: 3rem
  width: 8rem
  --border-radius: 12px
.white
  color: white !important
</style>
