<template>
  <ion-modal
    class="modal-with-layout"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="false"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout
      :data="{ title: 'Select Images' }"
      :isCropModal="true"
      :isImageSaving="isImageSaving"
      class="layout"
      @save="saveModal"
      @dismiss-modal="dismissModal"
      style="padding: 0px !important"
    >
      <div :key="rerenderKey" class="crop-preview" style="padding: 0px !important">
        <div v-if="loading || isImageSaving" class="d-flex align-items-center justify-content-center h-100 w-100">
          <ChLoading size="sm" />
        </div>

        <vue-picture-cropper
          ref="cropperRef"
          :class="{ invisible: loading || isImageSaving, ...cropperClasses }"
          :box-style="{ width: '100%', height: '100%', backgroundColor: '#EAECF6' }"
          :img="image"
          :options="{
            viewMode: 1,
            dragMode,
            aspectRatio: cropperAspectRatio,
            ready: onLoaded,
            cropend: change,
            minCropBoxWidth: 100,
            minCropBoxHeight: 100,
          }"
        />
      </div>
      <div class="d-flex justify-content-between px-2 py-1">
        <div>
          <ion-button class="reorder-btn" @click="reorderModal">
            <i class="ti-list mx-1" />
            Reorder
          </ion-button>
        </div>
        <div style="margin-left: -15%">
          <ion-button class="imgs-btn">
            <i class="ti-gallery mx-1" />
            {{ images.length }} /{{ subscriptionLIMIT[subscriptionLevel] }}</ion-button
          >
        </div>
        <div />
      </div>

      <div class="image-content">
        <Grid class="grid-item" :scrollSm="false" :xl="4" :lg="4" :md="3" :sm="3" :gridGap="true">
          <ImageCard
            v-for="(image, index) of images"
            :key="index"
            class="small-card"
            :image="image"
            :selected-img="selectedImg === image.id"
            @click.prevent.stop="isImageSelected(image)"
            @delete="deleteImage"
          />
        </Grid>
      </div>

      <UploadForm only-blob-url class="cover-uploader" @uploaded="handleChangeMultipleCoverImages">
        <div class="d-flex flex-column clickable-item-hov">
          <ion-icon class="plus-icon" :icon="addCircleOutline" />
          <div class="add-text">Add Image</div>
        </div>
      </UploadForm>
    </modals-layout>
    <ReorderImageModal
      :is-open="isOpenReorderModal"
      @close="() => (isOpenReorderModal = false)"
      :images="images"
      @reorder="reorderImages"
    />
  </ion-modal>
</template>

<script lang="ts">
import UploadForm from '@/shared/components/upload-form.vue';
import { defineComponent, ref, watch, computed, nextTick } from 'vue';
import ImageCard from '../components/ImageCard.vue';
import { addCircleOutline } from 'ionicons/icons';
import ReorderImageModal from './ReorderImageModal.vue';
import VuePictureCropper, { cropper } from 'vue-picture-cropper';
import { upload } from '@/shared/services/upload';
import { nanoid } from 'nanoid';
import { toast } from '@/shared/native/toast';
import Grid from '@/shared/components/storage/Grid.vue';
import { subscriptionLIMIT } from '../statics/constants';
import { authStore } from '../pinia-store/auth';
export default defineComponent({
  name: 'CropImageModal',
  components: { VuePictureCropper, ReorderImageModal, ImageCard, UploadForm, Grid },
  props: {
    imageUrl: {
      type: Array,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    outlineStyle: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { subscriptionLevel } = authStore();
    const isSaving = ref(false);
    const loading = ref(true);
    const cropperAspectRatio = ref(1);
    const imageUrl = toRef(props, 'imageUrl') as any;
    const rerenderKey = ref(1);
    const showSkipButton = ref(false);
    const isImageSaving = ref(false);
    const cropperRef: Ref = ref<any>(null);
    const images = ref<any[]>([]);
    const selectedImg = ref('');
    const isOpenReorderModal = ref(false);

    const reorderModal = async () => {
      isOpenReorderModal.value = true;
    };

    const isImageSelected = (img: any) => {
      if (selectedImg.value === img.id) {
        return;
      }
      loading.value = true;
      selectedImg.value = img.id;
    };

    const reorderImages = (imgs: any) => {
      images.value = imgs;
    };

    const skipCropAndUpload = () => {
      emit('skip-crop-and-upload');
      dismissModal(true);
    };

    const currentIndexImage = computed(() => {
      const index = selectedImg.value ? images.value.findIndex((img: any) => img.id === selectedImg.value) : 0;
      return index;
    });

    const deleteImage = (image: any) => {
      const index = images.value.findIndex((img: any) => img.id === image.id);
      images.value.splice(index, 1);
      if (selectedImg.value === image.id) {
        selectedImg.value = images.value.length ? images.value[0].id : '';
        rerenderKey.value += 1;
      }
    };

    const handleChangeMultipleCoverImages = (url: any) => {
      loading.value = true;

      if (images.value.length < subscriptionLIMIT[subscriptionLevel.value]) {
        images.value.push({ id: nanoid(6), url: url, blob: '' });
        selectedImg.value = images.value[images.value.length - 1].id;
      } else {
        toast.show(`You can only upload ${subscriptionLIMIT[subscriptionLevel.value]} images`, 'nonative', 'danger');
        loading.value = false;
      }
    };

    const dismissModal = (doNothing?: boolean) => {
      isSaving.value = false;
      loading.value = true;

      emit('close', doNothing);
    };
    const saveModal = async () => {
      isImageSaving.value = true;

      const uploadPromises = images.value.map(async (image: any) => {
        if (!image.blob) return image;
        else {
          const imagefile = image.blob;
          const url = await upload(imagefile, false);
          return { ...image, url };
        }
      });
      await toast.show('Uploading Images...', 'nonative', 'primary');
      const updatedImages = await Promise.all(uploadPromises);
      const imageUrls = updatedImages.map((image) => image.url);
      emit('save', imageUrls);
      await toast.show('Images uploaded successfully!', 'nonative', 'success');
      isImageSaving.value = false;
    };
    const onLoaded = () => {
      loading.value = false;
      const index = images.value.findIndex((image: any) => image.id === selectedImg.value);
      if (index !== -1) {
        cropper?.setData(images.value[index].position);
      }
    };
    const change = async () => {
      const imagefile = (await cropper!.getBlob()) as Blob;
      isSaving.value = true;

      if (!imagefile.type.startsWith('image/') || imagefile.type === 'image/gif') {
        throw new TypeError('File should be an image.');
      }
      images.value[currentIndexImage.value].blob = imagefile;
      images.value[currentIndexImage.value].position = cropper?.getData();

      isSaving.value = false;
    };

    const image = computed(() => {
      if (images.value.length > 0 && images.value[currentIndexImage.value]) {
        return images.value[currentIndexImage.value].url;
      } else {
        //  rerenderKey.value += 1;
        return '';
      }
    });

    const dragMode = computed(() => {
      if (props.outlineStyle === 'round') return 'crop';
      if (props.outlineStyle === 'cover') return 'move';
      return 'crop';
    });

    const cropperClasses = computed(() => ({
      'round-cropper': props.outlineStyle === 'round',
      'cover-cropper': props.outlineStyle === 'cover',
    }));

    watch(
      () => props.isOpen,
      (newVal) => {
        isSaving.value = false;
        loading.value = true;
        cropperAspectRatio.value = props.outlineStyle === 'cover' ? 770 / 241 : 1;
        if (newVal) {
          images.value = imageUrl.value.map((url: any) => ({ id: nanoid(6), url, blob: '' }));
          selectedImg.value = images.value[0].id;

          nextTick(() => {
            rerenderKey.value += 1;
          });
        }
      }
    );

    watch(
      () => props.imageUrl,
      () => {
        setTimeout(() => {
          rerenderKey.value += 1;
        }, 300);
      }
    );

    return {
      isSaving,
      loading,
      cropperAspectRatio,
      rerenderKey,
      showSkipButton,
      selectedImg,
      images,
      isOpenReorderModal,
      addCircleOutline,
      reorderModal,
      subscriptionLevel,
      isImageSelected,
      reorderImages,
      skipCropAndUpload,
      dismissModal,
      saveModal,
      currentIndexImage,
      change,
      image,
      dragMode,
      cropperClasses,
      subscriptionLIMIT,
      isImageSaving,
      deleteImage,
      onLoaded,
      handleChangeMultipleCoverImages,
    };
  },
});
</script>

<style lang="sass" scoped>
.small-card
  height: 120px
  width: 25%
  max-width: 25%
  min-width: 25%
  @media(max-width: 500px)
    width: 33.33%
    max-width: 33.33%
    min-width: 33.33%
.progress-container
  width: 100%
  background-color: #f3f3f3
  border-radius: 4px
  overflow: hidden
  margin-bottom: 10px


.progress-bar
  height: 10px
  background-color: #4caf50
  transition: width 0.4s ease
.dark .image-content
  background: #17074c
.image-content
  background: white

  height: calc(100% - 435px)
  overflow: auto !important
  @media(max-height: 760px)
    height: calc(100% - 337px)
  @media(max-width: 500px)
    height: calc(100vh - 600px)
.add-text
  margin: 0px 0px 0px 15px
  color: #ae38e5
  font-weight: bold
  font-size: 12px

.plus-icon
  margin: 10px 0px 2px 21px
  width: 50px
  height: 50px
  color: #ae38e5
.imgs-btn
  --background: transparent !important
  border-radius: 20px
  border: 1px solid #ae38e5
  height: 30px
  pointer-events: none
.imgs-btn::part(native)
  color: #ae38e5 !important
.reorder-btn
  --background: #00B4C5
  --border-radius: 20px
  color: white

.layout
  ::v-deep
    .modal-content
      padding: 0px !important
.invisible
  visibility: hidden

.crop-preview
  height: calc(100% - 250px) !important
  @media(max-width: 550px)
    height: 300px !important
  @media(max-height: 590px)
    height: 200px !important

.btn-size
  width: 82px

ion-spinner
  width: 5em
  height: 5em

.round-cropper .cropper-container .cropper-crop-box .cropper-view-box
  box-shadow: 0 0 0 1px #39f
  border-radius: 50%
  outline: 0

.round-cropper .cropper-container .cropper-crop-box .cropper-face
  background-color: inherit !important

.round-cropper .cropper-container .cropper-crop-box .cropper-view-box
  outline: inherit !important


.cropper-container
  position: relative

  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: #eaecf600
    z-index: 1

.cropper-crop-box
  z-index: 2

.cropper-view-box,
.cropper-face
  background-color: transparent !important

.skip-button
  position: absolute
  bottom: 20px
  left: 50%
  transform: translateX(-50%)
  z-index: 2
</style>
