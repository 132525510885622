import ShareProfileModal from '@/shared/modals/ShareProfileModal.vue';
import { defineStore, storeToRefs } from 'pinia';
import { Character, Onboarding, OnboardingCreateBody } from '@/shared/types/static-types';
import { authStore } from '../auth';
import { getUserOnboarding, updateOnboarding } from '@/shared/actions/onboarding';
import { getTags } from '@/shared/actions/tags';
import { modalController } from '@ionic/vue';
import OnboardingModal from '@/shared/modals/OnboardingModal/index.vue';
import { getRandomCharactersForFirstLogin } from '@/shared/actions/characters';
import { getFeedPage, getTrendingTopics, pinTopics } from '@/shared/actions/follow';
import SubscribeModal from '@/shared/modals/SubscribeModal.vue';
import { storage } from '@/shared/native';
import { step27 } from './steps';

const MAX_ONBOARDING_STEP = 20;
const ONBOARDING_STEPS_WITH_NO_MODAL = [3, 19, 20];
const ONBOARDING_STEPS_WITH_OVERLAY = [19];
//const ONBOARDING_STEPS_WITH_OVERLAY_ON_TOP = [24, 25];

export const useOnboarding = defineStore(
  'onboarding',
  () => {
    const onboarding = ref<Onboarding | null>(null);
    const modalInstance = ref<HTMLIonModalElement | null>(null);
    const subsInstance = ref<HTMLIonModalElement | null>(null);
    const boardingProfileInstance = ref<HTMLIonModalElement | null>(null);
    const router = useRouter();
    const { currentRoute } = router;
    const reactedCharacterIds = ref<string[]>([]);
    const characters = ref<Character[]>([]);
    const heardAboutOther = ref('');
    const lookingForOther = ref('');
    const trendingTags = ref<any[]>([]);
    const tags = ref<any[]>([]);
    const selectedTags = ref<any[]>([]);
    const sideEffectsInited = ref(false);
    const areTagsLoading = ref(false);
    const earlyPaywallFlag = ref(true);

    const { user } = authStore();

    const paywallStep = computed(() => (earlyPaywallFlag.value ? 5 : 13));

    const modalTitlesMap: Record<number, string> = {
      1: 'Onboarding',
    };

    const modalHeightMap = computed<Record<number, number>>(() => {
      const stepsMap: Record<number, number> = {
        1: 350,
        2: 350,
        3: 550,
        4: 360,
        5: 360,
        6: 550,
        7: 550,
        8: 550,
        9: 270,
        10: 550,
        11: 380,
        12: 380,
        13: 420,
        14: 170,
        15: 490,
        16: 150,
        17: 200,
        18: 150,
      };

      if (earlyPaywallFlag.value) {
        const keys = Object.keys(stepsMap).map(Number);
        return keys.reduce((acc, curr) => {
          if (curr < paywallStep.value)
            return {
              ...acc,
              [curr]: stepsMap[curr],
            };

          return {
            ...acc,
            [curr + 1]: stepsMap[curr],
          };
        }, {} as Record<number, number>);
      }
      return stepsMap;
    });

    const modalActionNameMap = computed<Record<number, string | null>>(() => {
      const map: Record<number, string | null> = {
        1: 'Hi, Hubert!',
        2: 'Heck yeah!',
        3: 'Interesting!',
        4: 'OMG!',
        5: 'Bring it on!',
        6: 'Continue',
        7: 'Continue',
        8: 'Continue',
        9: null,
        10: 'Continue',
        11: 'Continue',
        12: 'Continue',
        13: 'Continue',
        14: null,
        15: null,
        16: null,
        17: 'Start Creating',
        18: null,
      };

      if (earlyPaywallFlag.value) {
        const keys = Object.keys(map).map(Number);
        return keys.reduce((acc, curr) => {
          if (curr < paywallStep.value)
            return {
              ...acc,
              [curr]: map[curr],
            };

          return {
            ...acc,
            [curr + 1]: map[curr],
          };
        }, {} as Record<number, string | null>);
      }

      return map;
    });

    const heardAboutOptions = ref(['TikTok', 'Instagram', 'Youtube', 'Google search', 'Our blog', 'Friends', 'Other']);
    const lookingForOptions = ref([
      'Keep track of your characters and worlds in one place',
      'Write or draw more!',
      'To finish a story, webtoon, comic, or creative work you’re working on!',
      'Hang out with other character lovers.',
      'Build your audience and get your characters seen more',
      'Other',
    ]);

    const triviaOptions = ref([
      {
        name: '100+ character traits',
        icon: '/onboarding/ico-wrld.svg',
      },
      {
        name: 'Worlds',
        icon: '/onboarding/ico-opt.svg',
      },
      {
        name: 'Roleplay Tools',
        icon: '/onboarding/ico-pr.svg',
      },
      {
        name: 'Quizzes to learn more about your character’s personality',
        icon: '/onboarding/ico-quz.svg',
      },
      {
        name: 'Hundreds of challenges per month',
        icon: '/onboarding/ico-cup.svg',
      },
    ]);

    const currentStep = computed(() => onboarding.value?.step || 0);

    const isStepActive = (step: number) => {
      const usedStep = step >= 5 && earlyPaywallFlag.value ? step + 1 : step;
      return currentStep.value === usedStep;
    };

    const modalTitle = computed(() => {
      return modalTitlesMap[currentStep.value] || modalTitlesMap[1];
    });

    const modalActionName = computed(() => {
      return modalActionNameMap.value[currentStep.value];
    });

    const onboardingRouteFlag = currentRoute.value.name === 'test-onboarding';

    const stepForward = (step = 1) => {
      const validStep = step && typeof step === 'number' ? step : 1;

      if (!onboarding.value) return;

      onboarding.value.step = (onboarding.value?.step || 0) + validStep;
    };
    const stepBackward = (step = 1) => {
      const validStep = step && typeof step === 'number' ? step : 1;

      if (!onboarding.value) return;

      onboarding.value.step = (onboarding.value?.step || 0) - validStep;
    };
    const setStep = (step: number) => {
      if (!onboarding.value) return;

      onboarding.value.step = step;
    };

    const disableAction = computed(() => {
      const didntSelectedAge = isStepActive(14) && isNull(get(user.value, 'over_18'));
      const didntSatisfyCharacterReactions = isStepActive(10) && reactedCharacterIds.value.length < 2;
      const didntUploadPrfPic = isStepActive(12) && isEmpty(get(user.value, 'profile_picture_url'));
      const didnitUploadPrfCoverPic = isEmpty(get(user.value, 'cover_cropped_url')) && isStepActive(13)
      const didnotUploadSlidingCover = isEmpty(get(user.value?.customize_profile, 'sliding_cover_images')) && isStepActive(13)
      const didnotUploadPrfCover =  get(user.value?.customize_profile, 'cover_preference') === 'multiple' ? didnotUploadSlidingCover :didnitUploadPrfCoverPic 
      const didntSatisfyHeardAbout =
        isStepActive(6) &&
        (!onboarding.value?.heard_about || (onboarding.value?.heard_about === 'Other' && !heardAboutOther.value));
      const didntSatisfyLookingFor =
        isStepActive(7) &&
        (!onboarding.value?.accomplishments.length ||
          (onboarding.value?.accomplishments.includes('Other') && !lookingForOther.value));

      const didntSatisfyTags = isStepActive(8) && !selectedTags.value.length;

      return (
        didntSatisfyCharacterReactions ||
        didntUploadPrfPic ||
        didntSelectedAge ||
        didnotUploadPrfCover||
        didntSatisfyHeardAbout ||
        didntSatisfyLookingFor ||
        didntSatisfyTags
      );
    });

    const doUpdateOnboarding = () => {
      const likedCharacterIds = onboarding.value?.liked_characters
        .map(({ id }) => id)
        .filter((item): item is string => !!item);

      const updatedOnboarding = {
        ...omit(onboarding.value, ['user', 'liked_character']),
        heard_about: heardAboutOther.value || onboarding.value?.heard_about,
        user: user.value.id,
        liked_characters: likedCharacterIds,
        interests: tags.value.map((tag: any) => tag.name),
      } as OnboardingCreateBody;

      if (updatedOnboarding.step > MAX_ONBOARDING_STEP) return;

      updateOnboarding(updatedOnboarding);
    };

    watch(
      onboarding,
      (val, old) => {
        if (JSON.stringify(val) !== JSON.stringify(old)) return;

        const { isAuthenticated } = authStore();
        if (!isAuthenticated.value) return;

        if ((val?.step || 0) < (old?.step || 0) && !onboardingRouteFlag) return;

        doUpdateOnboarding();
      },
      { deep: true }
    );

    const loadCharacters = async () => {
      const [response, ...restFeedResponses] = await Promise.all([
        getRandomCharactersForFirstLogin(),
        ...selectedTags.value.slice(0, 3).map((tag) => getFeedPage({ tag: tag.name })),
      ]);
      const charsFromFeed = restFeedResponses
        .reduce((acc: any[], curr: any) => [...acc, ...curr.results], [] as any[])
        .filter((item: any) => item.type === 'character')
        .map(({ entity }: any) => entity);

      const allUniqueChars: any[] = Array.from(
        [...charsFromFeed, ...response].reduce((map, obj) => map.set(obj.id, obj), new Map()).values()
      );

      characters.value = allUniqueChars;
    };

    const loadTags = async (lookup = '') => {
      if (!lookup.trim()) {
        return;
      }
      areTagsLoading.value = true;
      const response = await getTags(1, 5, lookup);
      tags.value = response.results;
      areTagsLoading.value = false;
    };

    const loadTrendingTags = async () => {
      const response = await getTrendingTopics();
      trendingTags.value = response.results;
    };

    const loadWithModal = async () => {
      await loadCharacters();
      await loadTrendingTags();
    };

    const debouncedLoadTags = debounce(loadTags, 400);

    const checkAndShowPaywall = async () => {
      const showedPaywallDayTwoOnce = await storage.get('showedPaywallDayTwoOnce');
      if (isDay2.value && !showedPaywallDayTwoOnce) {
        const instance = await modalController.create({
          component: SubscribeModal,
          backdropDismiss: true,
          cssClass: `modal-cmfl subs-modal`,
          mode: 'ios',
        });
        instance.present();
        storage.set('showedPaywallDayTwoOnce', true);
      }
    };

    const initOnboarding = async () => {
      if (!user.value?.id) return;

      const onboardingRes = await getUserOnboarding();

      onboarding.value = onboardingRes;

      if (!isNewUser.value && ongoingOnboarding.value && onboarding.value) {
        onboarding.value = {
          ...onboarding.value,
          step: 20,
        };
        doUpdateOnboarding();
      }

      nextTick(() => {
        checkAndShowPaywall();
        doShowOnboardingModal();
        sideEffects();
        sideEffectsInited.value = true;
      });
    };

    const showModal = async () => {
      if (modalInstance.value) return;
      modalInstance.value = await modalController.create({
        component: OnboardingModal,
        backdropDismiss: false,
        cssClass: `modal-cmfl onboarding-modal`,
        id: 'onboarding-modal-id',
        mode: 'ios',
      });
      await modalInstance.value.present();
      handleModalHeight();
    };

    const dismissModal = () => {
      if (!modalInstance.value) return;

      modalInstance.value.dismiss();
    };

    const isNewUser = computed(() =>
      onboardingRouteFlag ? true : +new Date() - +new Date(user.value.date_joined) < 48 * 60 * 60 * 1000
    );
    const isDay2 = computed(
      () => +new Date() - +new Date(user.value.date_joined) >= 24 * 60 * 60 * 1000 && isNewUser.value
    );

    const disabledRemainingMs = computed(() => {
      const joinDatePlus22 = new Date(user.value.date_joined);
      joinDatePlus22.setHours(joinDatePlus22.getHours() + 22);
      return +joinDatePlus22 - +new Date();
    });

    const disabledForNewUser = computed(() => disabledRemainingMs.value > 0);

    const showOnboarding = computed(() => isNewUser.value && currentStep.value < MAX_ONBOARDING_STEP);

    const doShowOnboardingModal = async () => {
      if (
        (currentRoute.value.name === 'character-profile-new' || currentRoute.value.name === 'profile') &&
        currentRoute.value.query.v === '1'
      ) {
        dismissModal();
        return;
      }
      if (onboardingRouteFlag && onboarding.value) {
        onboarding.value.step = 1;
        if (!ONBOARDING_STEPS_WITH_NO_MODAL.includes(currentStep.value || 0)) showModal();
        return;
      }

      const shouldShowModal =
        isNewUser.value &&
        currentStep.value <= MAX_ONBOARDING_STEP &&
        !ONBOARDING_STEPS_WITH_NO_MODAL.includes(currentStep.value || 0);

      if (shouldShowModal) {
        showModal();
        return;
      }

      if (!shouldShowModal) {
        dismissModal();
        return;
      }
    };

    const sideEffects = async () => {
      const { isAuthenticated } = authStore();

      if (!isAuthenticated.value) {
        dismissModal();
        return;
      }

      if (!ongoingOnboarding.value) {
        dismissModal();
      }

      if (!ongoingOnboarding.value && currentRoute.value.name === 'onboarding') {
        router.push({ name: 'home' });
      }

      if (currentStep.value === 4) {
        showModal();
      }

      if (currentStep.value === 18) {
        showModal();
        const tagList = selectedTags.value.map((tag: any) => tag.name);
        pinTopics(tagList);
      }

      if (currentStep.value === paywallStep.value) {
        if (subsInstance.value) return;

        subsInstance.value = await modalController.create({
          component: SubscribeModal,
          backdropDismiss: false,
          cssClass: `modal-cmfl subs-modal`,
          mode: 'ios',
        });

        subsInstance.value.onDidDismiss().then(() => {
          stepForward();
        });

        subsInstance.value.present();
      }
      if (currentStep.value === 17) {
        const { updateProfileInstance } = authStore();

        if (boardingProfileInstance.value) return;

        boardingProfileInstance.value = await modalController.create({
          component: ShareProfileModal,
          cssClass: `share-prf-modal-one`,
        });
        updateProfileInstance(boardingProfileInstance.value);
        boardingProfileInstance.value.onDidDismiss().then(() => {
          stepForward(), updateProfileInstance(null), dismissModal();
          modalInstance.value = null;
          router.replace({ name: 'character-creator' });
        });

        await boardingProfileInstance.value.present();
      }
      if (currentStep.value === 3) {
        if (modalInstance.value) {
          dismissModal();
        }
        router.replace({ name: 'character-profile-new', params: { slug: 'hubert' } });
        modalInstance.value = null;
      }

      if (currentStep.value === 19) {
        dismissModal();
      
        if (get(currentRoute.value.params, 'id')) {
          router.replace({ path: currentRoute.value.fullPath, query: { _: Date.now() } });
        } else {
          router.replace({ name: 'character-creator' });
        }
      }

      // if (currentStep.value === 19) {
      //   step27(stepsTimer, showOnboarding);
      //   return;
      // }
    };

    const handleModalHeight = () => {
      nextTick(() => {
        const modalCss = document.querySelector('.onboarding-modal') as HTMLElement;
        if (!modalCss) return;

        modalCss.style.setProperty('--height', `${modalHeightMap.value[currentStep.value]}px`);
      });
    };

    watch(currentStep, () => {
      const { profileInstance } = authStore();
      handleModalHeight();
      sideEffectsInited.value = false;
      if (profileInstance.value && currentStep.value === 17) {
        return;
      }

      sideEffects();
    });

    const react = (characterID: string, userReacted: boolean) => {
      if (reactedCharacterIds.value.includes(characterID)) {
        if (userReacted) return;
        reactedCharacterIds.value = reactedCharacterIds.value.filter((id) => id !== characterID);
        return;
      }

      if (!userReacted) return;

      reactedCharacterIds.value.push(characterID);
    };

    const heardAboutOptionChanged = (value: string) => {
      if (!onboarding.value) {
        return;
      }

      onboarding.value.heard_about = value;
      heardAboutOther.value = '';
    };

    const handleTrivia = (value: string) => {
      if (!onboarding.value) {
        return;
      }

      onboarding.value.trivia_question_responses = [value];
      stepForward();
    };

    const lookingForOptionChanged = (value: string, checked: boolean) => {
      if (!onboarding.value) {
        return;
      }

      if (checked) {
        onboarding.value.accomplishments.push(value);
      }

      if (!checked) {
        onboarding.value.accomplishments = onboarding.value.accomplishments.filter((item) => item !== value);
      }
    };

    const heardAboutValueChanged = debounce((value: string) => {
      if (!onboarding.value) {
        return;
      }

      onboarding.value.heard_about = value;
    }, 300);

    const isTagSelected = (tag: any) => selectedTags.value.find((itag) => itag.name === tag.name);

    const selectTag = (tag: any) => {
      if (isTagSelected(tag)) {
        selectedTags.value = selectedTags.value.filter((itag) => itag.name !== tag.name);
        return;
      }
      selectedTags.value.push(tag);
    };

    const setSelectedTags = (tags: any[]) => {
      selectedTags.value = tags;
    };

    const showOverlay = computed(() => ONBOARDING_STEPS_WITH_OVERLAY.includes(currentStep.value));
    //const overlayOnTop = computed(() => ONBOARDING_STEPS_WITH_OVERLAY_ON_TOP.includes(currentStep.value));
    const ongoingOnboarding = computed(() => {
      const { isAuthenticated, isEventUserRegistered } = authStore();
      return currentStep.value < MAX_ONBOARDING_STEP && isAuthenticated.value && !isEventUserRegistered.value;
    });

    const reset = () => {
      onboarding.value = null;
      reactedCharacterIds.value = [];
      characters.value = [];
      heardAboutOther.value = '';
      lookingForOther.value = '';
      trendingTags.value = [];
      tags.value = [];
      selectedTags.value = [];
    };

    return {
      handleTrivia,
      onboarding,
      initOnboarding,
      stepForward,
      modalTitle,
      modalActionName,
      currentStep,
      disableAction,
      react,
      stepBackward,
      characters,
      heardAboutOptions,
      heardAboutValueChanged,
      heardAboutOptionChanged,
      heardAboutOther,
      lookingForOther,
      lookingForOptions,
      lookingForOptionChanged,
      triviaOptions,
      selectedTags,
      debouncedLoadTags,
      isTagSelected,
      tags,
      setSelectedTags,
      trendingTags,
      selectTag,
      areTagsLoading,
      loadWithModal,
      showOnboarding,
      disabledForNewUser,
      disabledRemainingMs,
      isNewUser,
      showOverlay,
      setStep,
      ongoingOnboarding,
      isStepActive,
      reset,
    };
  },
  {
    persist: { enabled: true },
  }
);

export const onboardingStore = () => {
  const store = useOnboarding();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
