<template>
  <div class="blab-card px-2 py-2 mt-3 clickable-item" @click="openModal(post.id)">
    <div style="border: 2px solid; border-radius: 15px; font-weight: bold" class="px-2 py-2 mb-3 mt-1">
      <div v-if="!isEmpty(post.featured_characters) && isFeatured">{{ charName }} featured in:</div>
      <div v-else>{{ charName }} posted:</div>
    </div>
    <div class="d-flex flex-wrap clickable-item-hov">
      <img
        loading="lazy"
        v-image
        class="img"
        :src="resizeUpload(extractImages.length ? extractImages[0] : '/empty.png', '80x80')"
      />
      <span
        class="blab-title ml-2"
        :style="` color: ${charColor}; font-family: ${defaultTextFont}; font-style: ${defaultTextItalic} !important; `"
      >
        {{ post.title }}
      </span>
    </div>
    <div class="pb-2" style="margin-top: -20px; margin-left: 58px">
      <div v-if="post.character && $route.name === 'profile'">
        <router-link
          :to="{ name: 'character-profile-new', params: { slug: post.character.slug } }"
          class="edit clickable-item-hov"
        >
          {{ get(post.character, 'info.name', 'unknown') }}
        </router-link>
      </div>
      <div v-else class="mt-2"></div>
    </div>

    <div class="mt-2 d-flex justify-content-between">
      <div class="d-flex text-center align-items-center">
        <div class="actions d-flex">
          <Reaction
            type="post"
            :reactions="reactions"
            :user-reaction="userReaction"
            @click.stop
            @changed="(reaction) => reactionChanged(reaction.key, post, reaction.isInstant)"
          >
            <ion-button
              color="transparent"
              class="inline-button icon-button clickable"
              :class="{ 'text-secondary': !!userReaction && userReaction.reaction }"
            >
              <div class="d-flex align-items-center justify-content-center" :style="{ color: buttonColor }">
                <i class="ti-thumb-up mr-2" :style="{ color: buttonColor }" />
                <span class="reaction-count" :style="{ color: buttonColor }">{{ reactions.total_count || 0 }}</span>
              </div>
            </ion-button>
          </Reaction>
        </div>

        <div
          v-if="repliesCount > 0"
          class="clickable-item reply d-flex text-center align-items-center"
          :style="`font-family: ${defaultTextFont}; font-style: ${defaultTextItalic} !important; font-weight: ${defaultTextBold} !important `"
        >
          <ion-icon class="icon mx-2" :icon="chatboxEllipsesOutline" />
          {{ repliesCount >= 99 ? `${repliesCount}k` : repliesCount }}
        </div>
      </div>
      <div class="px-1 py-1" :class="{ 'd-flex': post.category === 'art_wip' }">
        <ion-badge class="badge" :style="`color: ${badgeColor}; border: 2px solid ${badgeColor}`">
          {{ post.category === 'art_wip' ? 'Art' : blabCategories[post.category].name }}
        </ion-badge>

        <ion-badge
          v-if="post.category === 'art_wip'"
          :style="`color: ${badgeColor}; border: 2px solid ${badgeColor}`"
          class="badge mx-1"
        >
          WIP
        </ion-badge>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { chatboxEllipsesOutline } from 'ionicons/icons';
import { Prop } from 'vue-property-decorator';
import { formatTimeAgo } from '@/shared/utils/dateTime';
import { authStore } from '@/shared/pinia-store/auth';
import constants from '@/shared/statics/constants';
import { resizeUpload } from '@/shared/utils/upload';
import store from '@/shared/store';
import Reaction from '@/shared/components/Reaction/index.vue';

@Options({
  name: 'BlabCard',
  components: { Reaction },
})
export default class BlabCard extends Vue {
  @Prop({ default: () => ({}) }) post!: any;
  @Prop({ default: () => ({}) }) repliesCount!: any;
  @Prop({ default: () => [] }) featured_characters!: any;
  @Prop() customize!: any;
  @Prop() charName!: any;
  @Prop() dark!: any;
  @Prop() defaultColor!: any;
  @Prop() defaultTextFont!: any;
  @Prop() charId!: any;
  public first = first;
  get = get;
  formatTimeAgo = formatTimeAgo;
  resizeUpload = resizeUpload;
  chatboxEllipsesOutline = chatboxEllipsesOutline;

  public blabCategories = keyBy(constants.blabCategories, 'value');

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get userReaction() {
    if (this.post.user_reaction) {
      return this.post.user_reaction;
    }
  }

  public async reactionChanged(reaction: string, post: any, isInstant = false) {
    const reactionResp = await store.dispatch('BlabsModule/react', { reaction, blab: post, isInstant });
    this.$emit('afterReaction', reactionResp);
  }

  public get isCharProfileRoute() {
    try {
      const router = useRouter();
      return ['character-profile-new', 'character-profile-draft-new'].includes(
        router.currentRoute.value.name as string
      );
    } catch (error) {
      return '';
    }
  }

  public get isTempDefault() {
    return isEmpty(get(this.customize, 'template_applied')) && this.isCharProfileRoute;
  }

  public get badgeColor() {
    return this.blabCategories[this.post.category].color;
  }

  public get charColor() {
    return this.isTempDefault && this.dark
      ? '#ffffff'
      : `${get(this.customize, 'data.theme.text.charName.color')} !important`;
  }

  public get buttonColor() {
    return this.isTempDefault && this.dark
      ? '#ffffff !important'
      : this.isTempDefault
      ? '#00000 !important'
      : `${get(this.customize, 'data.theme.backgrounds.buttons')} !important`;
  }

  public get isFeatured() {
    if (!isEmpty(this.post.featured_characters)) {
      return this.post.featured_characters.find((value: any) => value.id === this.charId);
    }
  }

  public get extractImages() {
    let imgSrc;
    const imgUrls = [];
    const rex = /<img[^>]+src="(https:\/\/[^">]+)"/g;

    while ((imgSrc = rex.exec(this.post.description))) {
      imgUrls.push(imgSrc[1]);
    }

    return imgUrls;
  }

  public get defaultTextItalic() {
    return get(this.customize, 'data.theme.text.default.italics') ? 'italic' : 'normal';
  }

  public get defaultTextBold() {
    return this.isTempDefault ? 'normal' : get(this.customize, 'data.theme.text.default.bold') ? 'bold' : 'normal';
  }

  public get reactions() {
    const { reaction_counts } = this.post || {};
    return reaction_counts;
  }

  public openModal(id: string) {
    this.$emit('open', id);
  }
}
</script>

<style lang="sass" scoped>
.blab-card
  overflow: unset !important
  border-radius: 10px
  border: 2px solid

  .d-flex
    font-size: 15px
  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important
  .blab-title
    font-size: 16px
    font-weight: bold
  .img
    width: 50px
    height: 50px
    object-fit: cover
    border-radius: 8px

.reaction-count
  font-size: 14px
  font-weight: bold
.icon
  font-size: 16px
  font-weight: bold
.reply
  font-size: 14px
.clickable-item-hov
  background: unset
.badge
  border: 2px solid rgb(255, 100, 165)
  color: rgb(255, 100, 165)
  background: #09183c
  text-transform: capitalize
  width: 100%
  padding: 4px 18px 4px 18px
  display: flex
  justify-content: center
  text-align: center
  align-items: center
  border-radius: 15px
</style>
