import { Image, ImageCollection } from './../types/static-types';
import { chAxios } from '@/shared/lib/axios';
import { sendAnalyticsEvent } from '@/shared/services/analytics';
import constants from '@/shared/statics/constants';

export async function getVisualCollections(paramsIn: {
  ownerId?: string;
  ownerType: string;
  page?: number;
  pageSize?: number;
}) {
  const { ownerId, ownerType, page, pageSize } = paramsIn;
  const url = `/image-collections/owned_by/`;
  const params = {
    page,
    page_size: pageSize,
    id: ownerId,
    type: ownerType,
  };
  try {
    const { data } = await chAxios().get(url, { params });
    return data;
  } catch (e) {
    throw e;
  }
}
export const getImageCollections = getVisualCollections;

export async function getVisualCollectionsForUser(paramsIn: { userId?: string; page?: number; pageSize?: number }) {
  const { userId, page, pageSize } = paramsIn;
  const url = `/image-collections/`;
  const params = {
    page,
    page_size: pageSize,
    user: userId,
  };

  try {
    const { data } = await chAxios().get(url, { params });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getImageCollection(id: string, page = 1, pageSize = constants.defaultPageSize) {
  const url = `/images/collection/`;
  try {
    const { data } = await chAxios().get(url, { params: { id, page, page_size: pageSize } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getImageStorage() {
  const url = `/images/storage-detail/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getImageStorageTiers() {
  const url = `/images/storage-tiers/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function removeImages({ ids, urls }: { ids?: string[]; urls?: string[] }) {
  try {
    const { data } = await chAxios().delete(`/images/remove-images/`, {
      data: {
        ...(ids && ids.length && { image_ids: ids }),
        ...(urls && urls.length && { image_urls: urls }),
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createVisualCollection(paramsIn: {
  imageCollection: ImageCollection;
  ownerId?: string;
  ownerType?: string;
}) {
  const url = `/image-collections/`;
  const { ownerId, ownerType, imageCollection } = paramsIn;
  const params = {
    owner_type: ownerType,
    owner_id: ownerId,
  };
  try {
    const { data } = await chAxios().post(url, imageCollection, { params });
    return data;
  } catch (e) {
    throw e;
  }
}
export const createImageCollection = createVisualCollection;

export async function updateVisualCollection(imageCollection: ImageCollection) {
  const { id } = imageCollection;
  const url = `/image-collections/${id}/`;

  try {
    const { data } = await chAxios().patch(url, imageCollection);
    return data;
  } catch (e) {
    throw e;
  }
}
export const updateImageCollection = updateVisualCollection;

export async function reorderImageCollectionImages(collectionId: string, orderedImageIds: string[]) {
  const url = `/image-collections/${collectionId}/reorder_images/`;

  try {
    const { data } = await chAxios().post(url, { ids: orderedImageIds });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function moveImageToCollection(payload: { imageId: string; from: string; to: string }) {
  const url = `/images/${payload.imageId}/move/`;
  try {
    const { data } = await chAxios().post(url, { collection_from: payload.from, collection_to: payload.to });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function addImagesToCollection(payload: { collectionId: string; imageIds: string[] }) {
  const url = `/image-collections/${payload.collectionId}/append/`;
  try {
    const { data } = await chAxios().post(url, { ids: payload.imageIds });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteVisualCollection(id: string) {
  const url = `/image-collections/${id}/`;
  try {
    const { data } = await chAxios().delete(url);
    return data;
  } catch (e) {
    throw e;
  }
}
export const deleteImageCollection = deleteVisualCollection;

export async function deleteImage(id: string) {
  const url = `/images/${id}/`;
  try {
    const { data } = await chAxios().delete(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createMultipleVisualCollection(paramsIn: {
  ownerId: string;
  ownerType: string;
  imageCollections: ImageCollection[];
}) {
  const { ownerId, ownerType, imageCollections } = paramsIn;
  const tasks = imageCollections.map((imageCollection) =>
    createVisualCollection({
      ownerId,
      ownerType,
      imageCollection,
    })
  );
  try {
    const data = await Promise.all(tasks);
    return data;
  } catch (e) {
    throw e;
  }
}
export const createMultipleImageCollections = createMultipleVisualCollection;

export async function getImagesReactions(page = 1, pageSize = constants.defaultPageSize) {
  const url = `/images/reacted/`;
  try {
    const { data } = await chAxios().get(url, { params: { page, page_size: pageSize } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getImage(id?: string) {
  const url = `/images/${id}/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function patchImage(image: Image) {
  const { id } = image;
  const url = `/images/${id}/`;
  try {
    const { data } = await chAxios().patch(url, image);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function patchMultipleImages(images: Image[]) {
  const tasks = images.map((i) => patchImage(i));
  try {
    const data = await Promise.all(tasks);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function bulkUpdateWatermarks(payload: { image_ids: string[]; is_watermarked: boolean }) {
  const url = `/images/bulk-watermark-update/`;
  try {
    const { data } = await chAxios().patch(url, payload);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getImages(params: object) {
  const url = `images/section-images/`;
  // try {
  const { data } = await chAxios().get(url, { params });
  return data;
  // } catch (e) {
  //   throw e;
  // }
}

export async function unSpoilImage(payload = {}) {
  try {
    const url = `/images/unspoiled-for-me/`;
    const { data } = await chAxios().patch(url, payload);

    // sendAnalyticsEvent('Watermarked Image');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function watermarkImage(id: string, payload = {}) {
  try {
    const url = `/images/${id}/watermark-img/`;
    const { data } = await chAxios().patch(url, payload);

    sendAnalyticsEvent('Watermarked Image');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getRouletteImages() {
  const url = `/images/roulette-images/`;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function handleObjectImageUpdates(payload: {
  object_id: string;
  object_type: string;
  collections: {
    update?: any; // { id: ImageCollection }
    reorder?: string[]; // [id, id, id]
    create?: any[]; // [{ name: string, id_: string, images: string[] }]
  };
  images: {
    update: any; // { id: Image }
  };
}) {
  const url = `/imgs/manage/`;
  try {
    const { data } = await chAxios().post(url, payload);
    return data;
  } catch (e) {
    throw e;
  }
}
