import { MutationTree } from 'vuex';
import { WorldsState } from './types';
import { World, WorldsInfo } from '@/shared/types/static-types';

function updateWorldReaction(world_id?: string, worldsData?: any[], updatedReactionsData?: any) {
  const worlds = worldsData || [];
  const worldIndex = worlds.findIndex((world) => world.id === world_id);
  if (worldIndex >= 0) {
    const foundWorld = worlds![worldIndex];
    const updatedWorld = {
      ...foundWorld,
      ...updatedReactionsData,
    };

    return { updatedWorld, worldIndex };
  }
  return null;
}

export const mutations: MutationTree<WorldsState> = {
  SET_TRENDING_WORLDS: (state, { results, paging }) => {
    state.trendingWorlds = results;
    state.trendingWorldsPaging = paging;
  },

  SET_TOP_WORLDS: (state, { results, paging }) => {
    state.topWorlds = results;
    state.topWorldsPaging = paging;
  },
  SET_IN_ACTION_WORLD: (state, inActionWorld) => {
    state.inActionWorld = inActionWorld;
  },

  SET_IN_REACTION_WORLD: (state, reactionWorld) => {
    state.reactionWorld = reactionWorld;
  },

  APPLY_REACTION_ON_WORLD: (
    state,
    { world, updatedReactionsData }: { world: WorldsInfo; updatedReactionsData: Partial<WorldsInfo> }
  ) => {
    // latest
    const latestWorlds = state.latestWorlds || [];
    const findLatestWorldIndex = latestWorlds.findIndex((latestWorld) => latestWorld.id === world.id);
    if (findLatestWorldIndex >= 0) {
      const foundLatestWorld = state.latestWorlds![findLatestWorldIndex];
      const updatedLatestWorld = {
        ...foundLatestWorld,
        ...updatedReactionsData,
      };
      state.latestWorlds![findLatestWorldIndex] = updatedLatestWorld;
    }
    // trending
    const trendingWorlds = state.trendingWorlds || [];
    const findtrendingWorldIndex = trendingWorlds.findIndex((trendingWorld) => trendingWorld.id === world.id);
    if (findtrendingWorldIndex >= 0) {
      const foundtrendingWorld = state.trendingWorlds![findtrendingWorldIndex];
      const updatedtrendingWorld = {
        ...foundtrendingWorld,
        ...updatedReactionsData,
      };
      state.trendingWorlds![findtrendingWorldIndex] = updatedtrendingWorld;
    }
    // top
    const topWorlds = state.topWorlds || [];
    const findtopWorldIndex = topWorlds.findIndex((topWorld) => topWorld.id === world.id);
    if (findtopWorldIndex >= 0) {
      const foundtopWorld = state.topWorlds![findtopWorldIndex];
      const updatedtopWorld = {
        ...foundtopWorld,
        ...updatedReactionsData,
      };
      state.topWorlds![findtopWorldIndex] = updatedtopWorld;
    }
    // my worlds (i'm member of)
    const updatedMyWorld = updateWorldReaction(world.id, state.myWorlds, updatedReactionsData);
    if (updatedMyWorld) {
      state.myWorlds![updatedMyWorld.worldIndex] = updatedMyWorld.updatedWorld;
    }

    // user worlds (user is a leader of of)
    const updatedUserWorld = updateWorldReaction(world.id, state.userWorlds, updatedReactionsData);
    if (updatedUserWorld) {
      state.userWorlds![updatedUserWorld.worldIndex] = updatedUserWorld.updatedWorld;
    }

    const { inActionWorld } = state;

    if (world.id === inActionWorld.id) {
      const updatedInactionWorld = {
        ...inActionWorld,
        ...updatedReactionsData,
      };
      state.inActionWorld! = updatedInactionWorld;
    }
  },
  SET_LATEST_WORLD: (state, { results, paging }) => {
    state.latestWorlds = results;
    state.latestWorldsPaging = paging;
  },
  APPEND_LATEST_WORLD: (state, { results, paging }) => {
    state.latestWorlds = [...(state.latestWorlds || []), ...results];
    state.latestWorldsPaging = paging;
    state.latestWorldsPage = (state.latestWorldsPage || 0) + 1;
  },
  APPEND_TRENDING_WORLD: (state, { results, paging }) => {
    state.trendingWorlds = [...(state.trendingWorlds || []), ...results];
    state.trendingWorldsPaging = paging;
    state.trendingWorldsPage = (state.trendingWorldsPage || 0) + 1;
  },

  APPEND_TOP_WORLDS: (state, { results, paging }) => {
    state.topWorlds = [...(state.topWorlds || []), ...results];
    state.topWorldsPaging = paging;
    state.topWorldsPage = (state.topWorldsPage || 0) + 1;
  },

  SET_MY_WORLDS: (state, { results, paging }) => {
    state.myWorlds = results;
    state.myWorldsPaging = paging;
  },

  APPEND_MY_WORLDS: (state, { results, paging }) => {
    state.myWorlds = [...(state.myWorlds || []), ...results];
    state.myWorldsPaging = paging;
    state.myWorldsPage = (state.myWorldsPage || 0) + 1;
  },

  SET_USER_WORLDS: (state, { results, paging }) => {
    state.userWorlds = results;
    state.userWorldsPaging = paging;
  },

  APPEND_USER_WORLDS: (state, { results, paging }) => {
    state.userWorlds = [...(state.userWorlds || []), ...results];
    state.userWorldsPaging = paging;
    state.userWorldsPage = (state.userWorldsPage || 0) + 1;
  },

  UPDATE_WORLD_STATUS: (state, { statusWorld }) => {
    state.statusWorld = statusWorld;
  },
  SET_UNREAD_MSG: (state, { hasUnreadWorldMsgs }) => {
    state.hasUnreadWorldMsgs = hasUnreadWorldMsgs;
  },
};
