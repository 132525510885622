<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="true" :show-backdrop="true" @didDismiss="dismissModal">
    <modals-layout :data="{ title: 'Edit Image' }" @dismiss-modal="dismissModal">
      <div class="d-flex justify-content-center align-items-center">
        <div class="w-100 mt-2">
          <div class="label mb-2"><b>Caption:</b></div>
          <ion-input
            v-model="imageDescription"
            class="c-input"
            placeholder="Write a caption for this image"
            maxlength="150"
          />
          <div class="label mt-4 mb-2"><b>Source:</b></div>
          <ion-input v-model="imageSource" class="c-input" placeholder="Image credits/source" maxlength="150" />
          <div class="actions w-100 d-flex justify-content-center">
            <ion-button class="no-select done" @click="dismissModal">Done</ion-button>
          </div>
          <div class="d-flex mt-4 mb-2">
            <div class="label"><b>Blur Image:</b></div>
            <ion-checkbox v-model="imageSpoiler" class="mx-2"></ion-checkbox>
          </div>
          <ion-input
            v-if="imageSpoiler"
            v-model="imageSpoilerText"
            class="c-input"
            placeholder="Explain why you’re blurring. Content Guidelines still apply."
            maxlength="50"
          />
        </div>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts" setup>
import { imageStore } from '@/shared/pinia-store/images';
const { updateImageLocal } = imageStore();

const props = defineProps({
  imageId: {
    type: String,
    default: '',
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['dismissModal']);
const imageId = toRef(props, 'imageId');

const image = computed(() => {
  const { imageById } = imageStore();
  return imageById.value(imageId.value);
});

const imageDescription = computed({
  get() {
    return image.value?.description || '';
  },
  set(val: string) {
    updateImageLocal({ ...image.value, description: val });
  },
});

const imageSource = computed({
  get() {
    return image.value?.source || '';
  },
  set(val: string) {
    updateImageLocal({ ...image.value, source: val });
  },
});

const imageSpoiler = computed({
  get() {
    return image.value?.is_spoiled || false;
  },
  set(val: boolean) {
    updateImageLocal({ ...image.value, is_spoiled: val });
  },
});

const imageSpoilerText = computed({
  get() {
    return image.value?.spoiled_text || '';
  },
  set(val: string) {
    updateImageLocal({ ...image.value, spoiled_text: val });
  },
});

const dismissModal = () => {
  emits('dismissModal', true);
};
</script>

<style lang="sass" scoped>
.visual-prev-img
  max-width: 100%
  max-height: 100%
.actions
  position: absolute
  bottom: 2rem
.done
  width: 200px
  padding-right: 33px
</style>
