export const useShopProductsFeature = (product:any) => {
    const productFeatureTuples = computed(() => {
        const postAction = product.post_action;
        const items = [] as any[];
        if (postAction) {
          postAction.split(',').forEach((item: string) => {
            if (item?.startsWith('amethyst')) {
              let amt = item.split('amethyst')[1];
              items.push(['amethyst', amt]);
            }
            else if (item?.startsWith('cbomb')) {
              let amt = item.split('cbomb')[1];
              items.push(['cbomb', amt]);
            }
            else if (item?.startsWith('hubux')) {
              let amt = item.split('hubux')[1];
              items.push(['hubux', amt]);
            } else if(item?.startsWith('vi') && get(product,'more_preview_images')){
              let amt = ''
              items.push(['others', amt]);

            }
          });
        }
        return items;
      });
      const quantitiesFromProductFeatureTuples = computed(() => {
        const items = productFeatureTuples.value;
        const postActionQuantities = items.map((tup: any[]) => tup[1]);
        const firstPurchaseQuantities = product.first_purchase_offer
          ?.split(',')
          .map((item: string) => {
            if (item?.startsWith('amethyst')) {
              return item.split('amethyst')[1];
            } else if (item?.startsWith('cbomb')) {
              return item.split('cbomb')[1];
            } else if (item?.startsWith('hubux')) {
              return item.split('hubux')[1];
            }
            return '';
          })
          .filter((item: any) => item);
        return [...postActionQuantities, ...firstPurchaseQuantities];
      });
  
    return { productFeatureTuples ,quantitiesFromProductFeatureTuples };
  };
  