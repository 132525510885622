import { mainStore } from '@/shared/pinia-store/main';
import { app } from '../native/app';
import { screenOrientation } from '../native/screenOrientation';
import { statusBar } from '../native/status-bar';
import { keyboard } from '../native/keyboard';
import { textZoom } from '../native/text-zoom';
import { isAndroid, isMobileFn } from '../native/device';
import { network } from '../native/network';
import { crisp } from '../native/crisp';
import { URLOpenListenerEvent } from '@capacitor/app';
import { onboardingStore } from '~/shared/pinia-store/onboarding';
import { setBackForwardNavigationGestures, setWebviewBounce } from '../native/webview';
import { docHeight } from '~/shared/utils/ui';

const initAppOpenListener = () => {
  try {
    app.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
      const path = event.url.split('.app').pop() || '';
      const cleanPath = path.replace('https://staging382.characterhub.com', '').replace('https://characterhub.com', '');

      if (!cleanPath) return;

      const [basePath, queryString] = cleanPath.split('?');
      const queryParams = Object.fromEntries(new URLSearchParams(queryString));

      const router = useRouter();
      router.push({
        path: basePath,
        query: queryParams,
      });
    });
  } catch (error) {}
};

const initWebview = () => {
  try {
    setWebviewBounce(false);

    const { ongoingOnboarding } = onboardingStore();
    setBackForwardNavigationGestures(!ongoingOnboarding);
  } catch (error) {}
};

export const handleColorMode = async () => {
  try {
    const _isMobile = await isMobileFn();

    if (!_isMobile) return;

    const router = useRouter();
    const isChat = router.currentRoute.value.path.includes('chat');

    if (isChat) {
      await statusBar.setLight();
      return;
    }

    const { dark } = mainStore();

    const _isAndroid = await isAndroid();

    if (_isAndroid) return;

    if (dark.value) {
      await statusBar.setDark();
    } else {
      await statusBar.setLight();
    }
  } catch {}
};

const handleRestoreApp = async () => {
  app.addListener('appStateChange', () => {
    handleColorMode();
  });

  app.addListener('appRestoredResult', () => {
    handleColorMode();
  });

  app.addListener('appUrlOpen', () => {
    handleColorMode();
  });
};

const handleScreenMode = () => {
  try {
    screenOrientation.lockPortrait();
  } catch {}
};

const handleBackButton = () => {
  try {
    const backCb = () => {
      const router = useRouter();
      const currentRoutePath = router.currentRoute.value.fullPath;

      const { ongoingOnboarding } = onboardingStore();

      if (ongoingOnboarding.value) return;

      if (currentRoutePath === '/' || currentRoutePath === '/myhome') {
        app.exitApp();
      } else {
        router.go(-1);
      }
    };

    app.addListener('backButton', backCb);
  } catch (error) {}
};

const handleZoom = () => {
  try {
    textZoom.set({ value: 1 });
  } catch (error) {}
};

const handleKeyboard = () => {
  try {
    const { initHeight } = mainStore();
    const { setKeyboardOpen, setKeyboardWillOpen } = mainStore();

    keyboard.addListener('keyboardWillShow', () => {
      setKeyboardWillOpen(true);
    });

    keyboard.addListener('keyboardWillHide', () => {
      setKeyboardWillOpen(false);
    });

    keyboard.addListener('keyboardDidShow', () => {
      setKeyboardOpen(true);
      setKeyboardWillOpen(true);
    });

    keyboard.addListener('keyboardWillHide', () => {
      setKeyboardOpen(false);
      setKeyboardWillOpen(false);
      const doc = document.documentElement;
      doc.style.setProperty('--doc-height', `${initHeight.value}px`);
    });
  } catch (error) {}
};

const handleNetwork = () => {
  try {
    network.addListener('networkStatusChange', ({ connected }) => {
      const { setNetworkConnected } = mainStore();
      setNetworkConnected(connected);
    });
  } catch (error) {}
};

const handleInitCrisp = () => {
  try {
    const { crispWebsiteID } = useSafeEnv();

    crisp.configure(crispWebsiteID.value);
  } catch (error) {}
};

export const bootstrapNative = async () => {
  const isMobile = await isMobileFn();

  if (!isMobile) return;

  handleRestoreApp();
  handleZoom();
  handleNetwork();
  handleBackButton();
  handleScreenMode();
  handleColorMode();
  handleInitCrisp();
  handleKeyboard();
  initAppOpenListener();
  initWebview();
  docHeight();

  setTimeout(() => {
    const { setKeyboardWillOpen } = mainStore();
    setKeyboardWillOpen(false);
  }, 1000);
};
