<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="false" :show-backdrop="true">
    <div class="inner-content d-flex flex-column px-4 py-3">
      <div class="d-flex justify-content-between align-items-center">
        <div v-if="used && !deleted" class="title">
          {{ truncateText(stripHtmlTags(templateName), 25) }} is now saved and in use
        </div>
        <div v-else-if="!used && !deleted" class="title">
          "{{ truncateText(stripHtmlTags(templateName), 25) }}" has been Updated
        </div>
        <div v-else class="title">
          Are you sure you want to delete "{{ truncateText(stripHtmlTags(templateName), 25) }}"?
        </div>
      </div>
      <div v-if="!isEmpty(tempres)" class="set-bg px-2 py-2 mt-2">
        <div class="d-flex mt-2">
          <div v-if="deleted">These characters will be updated:</div>
          <div v-else>These characters have also been updated:</div>
        </div>
        <div class="d-flex text-black">
          <div v-for="(char, index) of displayedChars" :key="index" class="px-2 py-1">
            <router-link
              v-if="get(char, 'slug', '')"
              :to="{ name: 'character-profile-new', params: { slug: get(char, 'slug') } }"
            >
              <img
                :src="resizeUpload(get(char, 'info.cropProfilePicture', '/empty.png'), '80x80')"
                style="width: 40px; height: 40px; border-radius: 20px"
              />
            </router-link>
          </div>
        </div>
        <div class="d-flex justify-content-end px-2">
          <div v-if="hiddenChars" style="margin-top: -26px">({{ tempcount }}+)</div>
        </div>
      </div>

      <div class="d-flex mt-2">
        <ion-button v-if="!deleted" class="undo-btn w-100" :disabled="isSubmitting" @click.prevent="undoDesign">
          <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
          <span v-else>Undo</span>
        </ion-button>
        <ion-button v-if="deleted" class="cancel-btn w-100" @click.prevent="cancelbtn">
          <span>Cancel</span>
        </ion-button>

        <ion-button
          class="w-100 mx-2"
          :class="deleted ? 'confirm-btn' : 'submit-btn'"
          style="--border-radius: 20px"
          :disabled="isSubmitting"
          type="submit"
          @click.prevent="actionClicked"
        >
          <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
          <span v-else>{{ deleted ? 'Confirm' : 'OK' }}</span>
        </ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { stripHtmlTags } from '@/shared/utils/string';
import { deleteCharacterTemplate, getTemplateUsedByOtherChar } from '@/shared/actions/characters';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';
const { customizeCharacter } = characterStylizeStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  templateName: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  isTemplateEmpty: {
    type: Boolean,
    default: false,
  },
  deleted: {
    type: Boolean,
    default: false,
  },
  used: {
    type: Boolean,
    default: false,
  },
});
const { updatePreviousTemplate, updatePreviousCustomize } = authStore();
const usedTemp = toRef(props, 'used');
const deleted = toRef(props, 'deleted');
const isOpen = toRef(props, 'isOpen');
const id = toRef(props, 'id');
const isSubmitting = ref(false);
const emits = defineEmits(['closeSavedModal', 'closeUndo', 'closeOk', 'cancel', 'deleted']);
const tempres = ref([]);
const tempcount: any = ref('');
const isTemplateEmpty = toRef(props, 'isTemplateEmpty');
const route = useRoute();
watch(
  () => props.isOpen,
  async () => {
    if (isOpen.value) {
      const tempId = !isEmpty(id.value)
        ? id.value
        : !isEmpty(get(route.query, 'tempId'))
        ? get(route.query, 'tempId')
        : get(customizeCharacter.value, 'template_applied');
      const cId = get(route.params, 'id') as string;
      const res = deleted.value
        ? await getTemplateUsedByOtherChar(tempId as string, 1, 40)
        : await getTemplateUsedByOtherChar(tempId as string, 1, 40, { character_id: cId });
      tempres.value = res.results;
      tempcount.value = res.count;
    }
  }
);

const displayedChars = computed(() => {
  return tempres.value.slice(0, 3);
});
const hiddenChars = computed(() => {
  const temp = tempcount.value - 3;
  if (tempcount.value >= 3 && temp !== 0) {
    tempcount.value = tempcount.value - 3;
    return true;
  } else {
    return false;
  }
});
const actionClicked = async () => {
  isSubmitting.value = true;
  if (deleted.value) {
    await deleteCharacterTemplate(id.value);
    await toast.show('Template deleted successfully', 'nonative', 'success');
    emits('deleted');
  } else {
    usedTemp.value ? emits('closeSavedModal') : emits('closeOk');
  }
  isSubmitting.value = false;
};

const cancelbtn = () => {
  emits('cancel');
};
const undoDesign = async () => {
  isSubmitting.value = true;
  if (usedTemp.value) {
    updatePreviousCustomize({
      isUndo: true,
      isTempEmp: isTemplateEmpty.value,
    });
  }
  emits('closeUndo', usedTemp.value);
  isSubmitting.value = false;
};
</script>

<style lang="sass" scoped>
.set-bg
  background: #eaecf6 !important
.dark .set-bg
  background: #100534 !important

.title
  font-weight: bold
  font-size: 22px
.submit-btn
  width: 150px
  --background: var(--ion-color-primary) !important
.undo-btn
  --border-radius: 20px
  --background: #faa6cb
  color: black
  width: 150px
.cancel-btn
  --border-radius: 20px
  --background: #8c8a8b
  width: 150px
  color: rgb(244, 244, 244)
.confirm-btn
  width: 150px
  --background: #e11414 !important

ion-modal
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 80vh
  overflow: auto
.spinner
  width: 15px
  height: 15px
</style>
