import { defineStore, storeToRefs } from 'pinia';
import { authStore } from './auth';
import {
  CharacterOwnershipEntry,
  CharacterRelationship,
  CharacterTransferRequest,
  PaginatedResponse,
} from './../types/static-types';
import { toast } from '@/shared/native/toast';
import { sendReactionToBackend } from './shared/character';
import {
  getCharacterDraft,
  getCharacterFromSlug,
  getPendingCharacterTransfer,
  initiateCharacterTransfer,
  cancelPendingCharacterTransfer,
  getCharacterOwnershipHistory,
  getCharacterByIdFull,
} from '@/shared/actions/characters';
import { Character } from '@/shared/types/static-types';
import { getPublicCharacterRelationships } from '@/shared/actions/characterRelationships';
import { imageStore } from '@/shared/pinia-store/images';
import { updateReactionsDataForCharacter } from '@/shared/helpers/characters';

export interface ICharacterProfileState {
  currentCharacter: Character | null;
  isLoading: boolean;
  isSectionsCollapsed: SectionsCollapsed;
  relationships: PaginatedResponse<CharacterRelationship[]> | null;
}

export enum SectionsCollapsed {
  true = 1,
  false = 0,
  noCare = -1,
}

const useCharacterProfile = defineStore('character-profile', {
  state: (): ICharacterProfileState => ({
    currentCharacter: null,
    isLoading: false,
    isSectionsCollapsed: 0,
    relationships: null,
  }),
  actions: {
    reset() {
      const { reset } = imageStore();
      this.currentCharacter = null;
      this.relationships = null;
      reset();
    },
    async loadCharacterRelationships() {
      const characterId = this.character?.id;
      if (!characterId) return;
      const relationships = await getPublicCharacterRelationships(characterId);
      this.relationships = { ...relationships, page: 1 };
      if (this.currentCharacter?.info && 'relationshipsSection' in this.currentCharacter?.info) {
        this.currentCharacter.info.relationshipsSection = relationships.results;
      }
    },
    async loadCharacterRelationshipsNextPage() {
      const characterId = this.character?.id;
      if (!characterId) return;
      const relationships = await getPublicCharacterRelationships(characterId, this.relationships?.page || 0 + 1);
      this.relationships = {
        ...relationships,
        results: [...(this.relationships?.results || []), ...relationships.results],
        page: this.relationships?.page || 0 + 1,
      };
    },
    async loadCharacterBySlug(slug: string) {
      const { setImageCollections, reset } = imageStore();
      reset();
      try {
        this.isLoading = true;
        const { user } = authStore();
        const character = await getCharacterFromSlug(slug);
        if (character.is_nsfw && user.value.id && !user.value.show_nsfw && user.value.id !== character.author?.id) {
          this.currentCharacter = null;
          return;
        }
        this.currentCharacter = character;
        if (this.currentCharacter.visual_collections_page?.results) {
          setImageCollections(this.currentCharacter.visual_collections_page?.results);
        }
        await this.loadCharacterRelationships();
      } catch (error: any) {
        if (error.response?.status === 403 && error.response?.data?.includes('NSFW')) {
          toast.show('This character has been marked as mature, and you have mature content disabled.', 'nonative', 'danger');
          this.currentCharacter = null;
        } else {
          toast.show('Error loading character', 'nonative', 'danger');
          this.currentCharacter = null;
          if (error.response?.status === 404) {
            this.isLoading = false;
            throw error;
          }
        }
      } finally {
        this.isLoading = false;
      }
    },
    async loadCharacterById(id: string) {
      const { setImageCollections, reset } = imageStore();
      reset();
      try {
        this.isLoading = true;
        const character = await getCharacterByIdFull(id);
        this.currentCharacter = character;
        if (this.currentCharacter.visual_collections_page?.results) {
          setImageCollections(this.currentCharacter.visual_collections_page?.results);
        }
        await this.loadCharacterRelationships();
      } catch (error) {
        toast.show('Error loading character', 'nonative', 'danger');
      } finally {
        this.isLoading = false;
      }
    },
    async loadDraftCharacterById(id: string) {
      const { setImageCollections, reset } = imageStore();
      reset();
      try {
        this.isLoading = true;
        const character = await getCharacterDraft(id);
        this.currentCharacter = character;
        if (this.currentCharacter?.visual_collections_page?.results) {
          setImageCollections(this.currentCharacter.visual_collections_page?.results);
        }
        await this.loadCharacterRelationships();
      } catch (error) {
        toast.show('Error loading character', 'nonative', 'danger');
      } finally {
        this.isLoading = false;
      }
    },
    collapseSections(value: SectionsCollapsed) {
      this.isSectionsCollapsed = value;
    },
    async react(reaction: string, isInstant = false, fromRoulette = false) {
      if (!this.currentCharacter) return;
      const newReaction = await sendReactionToBackend(reaction, this.currentCharacter, fromRoulette, isInstant);
      const updatedReactionsData = updateReactionsDataForCharacter(reaction, this.currentCharacter, newReaction);
      this.currentCharacter = {
        ...this.currentCharacter,
        ...updatedReactionsData,
      };
      return { newReaction, updatedReactionsData };
    },
    async fetchCharacterOwnershipHistory() {
      if (!this.currentCharacter) return;
      const history = await getCharacterOwnershipHistory(this.currentCharacter.id!);
      if (this.currentCharacter) this.currentCharacter.ownershipHistory = history;
    },
    async fetchPendingCharacterTransferRequest() {
      if (!this.currentCharacter) return;
      const pendingTransfer = await getPendingCharacterTransfer(this.currentCharacter.id!);
      if (this.currentCharacter) this.currentCharacter.pendingTransfer = pendingTransfer;
    },
    async initiateCharacterTransferRequest(toUser: string, note: string) {
      if (!this.currentCharacter) return;
      const pendingTransfer = await initiateCharacterTransfer(this.currentCharacter.id!, toUser, note);
      this.currentCharacter.pendingTransfer = pendingTransfer;
      toast.show('Transfer request sent', 'nonative', 'success');
      return pendingTransfer;
    },
    async cancelPendingCharacterTransferRequest() {
      if (!this.currentCharacter) return;
      await cancelPendingCharacterTransfer(this.currentCharacter.pendingTransfer!.id!);
      this.currentCharacter.pendingTransfer = undefined;
      toast.show('Canceled transfer request', 'nonative', 'success');
    },
  },
  getters: {
    character(): Character | null {
      return this.currentCharacter;
    },
    loading(): boolean {
      return this.isLoading;
    },
    sectionsCollapsed(): SectionsCollapsed {
      return this.isSectionsCollapsed;
    },
    pendingCharacterTransfer(): CharacterTransferRequest | null {
      return this.currentCharacter?.pendingTransfer || null;
    },
    ownershipHistory(): CharacterOwnershipEntry[] {
      return this.currentCharacter?.ownershipHistory || [];
    },
  },
});

export const characterProfileStore = () => {
  const store = useCharacterProfile();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
