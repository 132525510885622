<template>
  <div class="featured-in">
    <div v-for="(item, index) of featuredIn" :key="index">
      <div v-if="hasValue(item)" class="one-featured-in dark d-flex align-items-center">
        <div class="type">• {{ itemTitle(item) }}</div>
        <div class="value dark ml-1">
          <a rel="nofollow" class="link" v-if="item.link" :href="item.link">{{ item.title }}</a>
          <span v-else>{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toTitleSpaceCase } from '@/shared/utils/string';
import { mainStore } from '@/shared/pinia-store/main';

const props = defineProps({
  value: {
    type: Array,
    default: [],
  },
  customize: {
    type: Object,
    default: {},
  },
});

const featuredIn = toRef(props, 'value');
const customize = toRef(props, 'customize');
const currColor = ref('');

const { dark: isDark } = mainStore();

const { isTempDefault } = useCustTempDefault(props);

const infoSectionColor = computed(() => {
  const defaultClr = isDark.value ? '#010123' : '#FFFFFF';
  return isTempDefault.value ? defaultClr : get(customize.value, 'data.theme.backgrounds.infoSection') || defaultClr;
});
const getBorder = computed(() => {
  return isTempDefault.value ? 'none' : 'solid';
});

const itemTitle = (item: any) => {
  if (item.type === 'custom') return toTitleSpaceCase(item.key || 'Custom');
  return toTitleSpaceCase(item.type);
};

const defaultTextColor = computed(() => {
  const currdefaultClr = get(customize.value, 'data.theme.text.default.color');
  const defaultClr = '#45B6F5';
  if (isEmpty(get(customize.value, 'template_applied')) && currdefaultClr === '#000000') {
    if (isEmpty(currColor.value)) return defaultClr;
  } else {
    currColor.value = currdefaultClr;
    return `${get(customize.value, 'data.theme.text.default.color', defaultClr)}`;
  }
});

const defaultTextFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.default.font') || 'Roboto';
});

const hasValue = (featuredIn: any) => {
  return featuredIn.type && featuredIn.title;
};
</script>

<style lang="sass" scoped>
.one-featured-in
  min-height: 30px
  margin-bottom: 10px
  background: #FFF
  border-radius: 10px
  color: #45B6F5
  padding: 0 24px
  background: v-bind(infoSectionColor) !important
  border: 3px v-bind('`${getBorder} ${defaultTextColor}`')
  *
    font-family: v-bind(defaultTextFont) !important

  .type
    width: 100px
    color: v-bind(defaultTextColor) !important
  .value
    font-weight: bold
    color: v-bind(defaultTextColor) !important
    &.dark
      background: v-bind(infoSectionColor) !important
  a
    color: v-bind(defaultTextColor) !important
  span
    color: v-bind(defaultTextColor) !important
.dark
  .one-featured-in, .value
    color: white !important
.dark .collapsable-section .featured-in .one-featured-in
  background: v-bind(infoSectionColor) !important
</style>
