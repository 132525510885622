import { getElementScrollPosition } from '@/shared/utils/ui';
export const useCommentHighlighter = (loading: Ref<boolean>) => {
  if (process.server) return;
  watch(loading, (val) => {
    try {
      if (!val) {
        setTimeout(goToComment, 1000);
      }
    } catch (error) {}
  });
};



const goToComment = async () => {
  const router = useRouter();
  const document = useDocument();
  const commentID = router.currentRoute.value.query.cmnt;
  if (!commentID) return;

  const { isDesktopSize } = useWindowSize();
  const targetEl =
            (document.value.querySelector(`#comment-${commentID}`) ||
            document.value.querySelector(`#comment-reply-${commentID}`)) as HTMLElement;
  if (!targetEl) return;
  const scrollPosition = getElementScrollPosition(targetEl);
  
  if (isDesktopSize.value) {
    const scroller = document.value.querySelector('.layout-scroll-area') as HTMLElement;
    if (scroller) {
      scroller.scrollTo({ 
        top: scrollPosition - 100, 
        behavior: 'smooth' 
      });
    }
  } else {
    // Find nearest ion-content by traversing up from the images section. Or try fallback
    const content = targetEl.closest('ion-content') as HTMLIonContentElement || 
      document.value?.querySelector('.layout-scroll-area ion-content') as HTMLIonContentElement;
    try {
      await content?.scrollToPoint(0, scrollPosition - 100, 500);
    } catch (e) {}
  }
};