<template>
  <div @click="openCharacterSelectionModal">
    <slot />
    <character-selection-modal
      :is-open="isOpenCharSelectionModal"
      :is-compliment-bomb="true"
      @close="closeCharSelectionModal"
      @selected="openSelectedCharacterModal"
    />
    <compliment-bombs-selected-character-modal
      :is-open="isOpenSelectedCharacterModal"
      :modal-title="modalTitle"
      :section-title="sectionTitle"
      :section-description="`${
        isEmpty(character) ? 'The Character you selected' : get(character, 'info.name')
      }  was <strong>${sectionTitle.toLowerCase()}.</strong> You should star seeing compliments trickle in within 48 hours of bombing.`"
      :svg-icon="'/compliment-heart.svg'"
      :button-text="'Done'"
      @close="closeSelectedModal"
    />
  </div>
</template>

<script lang="ts" setup>
import CharacterSelectionModal from '@/shared/modals/CharacterSelectionModal.vue';
import { createComplimentBomb } from '@/shared/actions/complimentBomb';
import ComplimentBombsSelectedCharacterModal from '@/shared/modals/ComplimentBombsSelectedAndGiftedModal.vue';
const props = defineProps({
  inventory: {
    type: Object,
    default: {},
  },
  character: {
    type: Object,
    default: {},
  },
});
const isOpenCharSelectionModal = ref(false);
const selectedChar: any = ref({});
const sectionTitle = ref('SUCESSFULLY BOMBED');
const emits = defineEmits(['updated']);
const inventory: any = toRef(props, 'inventory');
const isOpenSelectedCharacterModal = ref(false);
const character: any = toRef(props, 'character');
const openCharacterSelectionModal = () => {
  if (isEmpty(inventory.value)) return;
  !isEmpty(character.value) ? postComplimentBomb() : (isOpenCharSelectionModal.value = true);
};

const closeCharSelectionModal = () => {
  isOpenCharSelectionModal.value = false;
};
const modalTitle = computed(() => {
  return `${isEmpty(character.value) ? 'Character' : get(character.value, 'info.name')} Selected`;
});

const openCharSelectedModal = () => {
  isOpenSelectedCharacterModal.value = true;
};
const postComplimentBomb = async () => {
  await createComplimentBomb(!isEmpty(character.value) ? character.value.id : selectedChar.value.id);
  closeCharSelectionModal();
  openCharSelectedModal();
};

const closeSelectedModal = () => {
  isOpenSelectedCharacterModal.value = false;
  emits('updated');
};

const openSelectedCharacterModal = (data: any) => {
  selectedChar.value = data.character;
  postComplimentBomb();
};
</script>

<style lang="sass" scoped></style>
