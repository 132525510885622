import { GalleryPhoto, Photo } from '@capacitor/camera';
import { urltoFile } from '@/shared/utils/files';

export const cameraFileToJsFile = async (image: GalleryPhoto) => {
  const { webPath, format } = image;
  const mimeType = `image/${format}`;
  const fileName = `commaful-camera-capture.${format}`;
  const file = await urltoFile(webPath!, fileName, mimeType);

  return file;
};

export const cameraPhotoToJsFile = async (image: Photo) => {
  const { dataUrl, format } = image;
  const mimeType = `image/${format}`;
  const fileName = `commaful-camera-capture.${format}`;
  const file = await urltoFile(dataUrl!, fileName, mimeType);

  return file;
};
