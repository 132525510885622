export default {
  ModalsModule: 'ModalsModule',
  UsersModule: 'UsersModule',
  CharactersModule: 'CharactersModule',
  NotificationsModule: 'NotificationsModule',
  RolechatModule: 'RolechatModule',
  ListingModule: 'ListingModule',
  WorldsModule: 'WorldsModule',
  BlabsModule: 'BlabsModule',
  FanworksModule: 'FanworksModule',
  ImagesModule: 'ImagesModule',
};
