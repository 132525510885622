<template>
  <ion-modal
    class="modal-big"
    css-class=""
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <div class="inner-content">
      <div class="d-flex justify-content-between align-items-center">
        <div class="p-2 text" v-if="isFreeSubscription">You just got:</div>
        <div />
        <CurrencyBar
          :gems="get(usersCurrency, 'gems')"
          :coins="get(currency, 'user_total_coins')"
          :earned="get(currency, 'coins')"
          :img-width="40"
        />
      </div>
      <div
        class="align-items-center d-flex justify-content-center mx-auto flex-column text-center"
        :class="{ 'mb-3': isFreeSubscription }"
      >
        <div class="position-relative d-flex align-items-center justify-content-center mx-auto">
          <inline-svg class="blast-svg" src="/box_open_blast.svg" :class="{ 'pre-blast-svg': !isFreeSubscription }" />
        </div>
        <HubuxCoins v-if="isFreeSubscription" :currency="currency" />
      </div>
      <div class="px-2 mb-2 position-relative" style="z-index: 2" v-if="!isFreeSubscription">
        <div class="sub-title">Rewards x{{ get(user, 'subscription_level') === subscriptionLevels.plus ? 2 : 3 }}:</div>
        <HubuxCoins :currency="currency" :showBadge="true" />
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar" :style="isFreeSubscription ? '--min-height: 74px' : '--min-height: 54px'">
        <div class="d-flex flex-column flex-wrap justify-content-center align-items-center">
          <ion-button class="rewards-btn" v-if="isFreeSubscription" @click="openSubscribeModal(null, 'User Currency')"
            >Rewards x2 with
            <SubPlanBadge
              v-for="(_, index) of [1, 2]"
              :key="index"
              :plan="subLevelMap[index + 1]"
              class="ml-2"
              :withBorder="true"
            />
          </ion-button>

          <ion-button :class="{ 'thanks-btn': isFreeSubscription }" @click="closeModal">
            <span
              :style="
                isFreeSubscription && isDark
                  ? 'color: white;text-decoration: underline'
                  : isFreeSubscription
                  ? 'color: #214163;text-decoration: underline'
                  : 'color: white'
              "
              >{{ isFreeSubscription ? 'No, thanks' : 'OK!' }}</span
            >
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import constants from '@/shared/statics/constants';
import SubPlanBadge from '@/shared/components/SubPlanBadge.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { openSubscribeModal } from '@/shared/utils/modals';
import CurrencyBar from '@/shared/pages/store/CurrencyBar.vue';
import HubuxCoins from '@/shared/pages/store/HubuxCoins.vue';
import { mainStore } from '@/shared/pinia-store/main';
const { user, usersCurrency, getUsersCurrency } = authStore();
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  currency: {
    type: Object,
  },
});
const subLevelMap = constants.subscriptionLevelsTextMap;
const subscriptionLevels = constants.subscriptionLevels;
const emits = defineEmits(['close', 'dismissModal']);
const isOpen = toRef(props, 'isOpen');
const { dark: isDark } = mainStore();

watch(isOpen, () => {
  if (isOpen.value) {
    getUsersCurrency();
  }
});
const currency = toRef(props, 'currency');
const dismissModal = (val: any) => {
  emits('dismissModal', val);
};

const closeModal = () => {
  emits('close');
};

const isFreeSubscription = computed(() => {
  return get(user.value, 'subscription_level') === subscriptionLevels.free;
});
</script>

<style lang="sass" scoped>
.rewards-btn
  --background: linear-gradient(171.47deg, #e338fc 0%, #ae38e5 25%, #886eff 50%, #4fbde5 75%, #6dffe5 100%)
.thanks-btn
  --background: transparent
  font-size: 12px
  height: 12px
.pre-blast-svg
  opacity: 0.1 !important
  z-index: 4

.text
  font-size: 20px
  font-weight: 600
  color: #431c89
.dark .text
  color: white !important
.sub-title
  font-size: 22px
  color: #431c89
  font-weight: 600
.dark .sub-title
  color: white
.blast-svg
  opacity: 0.2
  position: absolute
  display: flex
  align-items: center
  justify-content: center
  width: 223px
  height: auto
  top: -29px
.upgrade-plan
  --border-radius: 12px
.item-img
  width: 125px
  height: auto
.label
  font-weight: bold

.dark .toolbar
  --background: #17074c !important

.title
  font-weight: bold
  font-size: 22px

.modal-big
  --width: 400px
  --height: auto
  --border-radius: 9px

.inner-content
  background: var(--ion-background-color, #fff)
  overflow: hidden
  height: 100%
  @media(max-height:525px)
   height: 100%
</style>
