<template>
  <div>
    <div v-if="!isEmpty(products)">
      <Grid :xl="inModal ? 3 : 4" :lg="inModal ? 3 : 4" :md="3" :sm="2" :scrollSm="false">
        <ShopProductHorizontalCard
          v-for="(item, index) in products"
          :key="index"
          :product="item"
          class="grid-item"
          @reload="reloadGemShopListings"
          :whiteCardColor="true"
          :amountSection="true"
        />
      </Grid>
    </div>
    <div v-else class="no-data">No offers available</div>
  </div>
</template>

<script lang="ts" setup>
import Grid from '@/shared/components/storage/Grid.vue';
import ShopProductHorizontalCard from '@/shared/components/Store/ShopProductHorizontalCard.vue';

const props = defineProps({
  inModal: { type: Boolean, default: false },
  products: { type: Array, default: [] },
  whiteCardColor: { type: Boolean, default: false },
  amountSection: { type: Boolean, default: false },
  ringShopBtn: { type: Boolean, default: false },
  profileRing: { type: Object, default: {} },
  character: { type: Object, default: {} },
  selectedUserChar: { type: Object, default: {} },
});
const products = toRef(props, 'products');

const emits = defineEmits(['reload']);
const reloadGemShopListings = () => {
  emits('reload');
};
</script>

<style lang="sass" scoped>
.shop-btn
  --background: linear-gradient(to right,rgb(189 71 216) 21%,rgb(108 83 206) 73%,rgb(94 93 206) 98%,rgb(86 98 206) 100%)
</style>
