import { GetterTree } from 'vuex';
import { SiteEventState } from './types';
import { RootState } from '@/shared/store';

export const getters: GetterTree<SiteEventState, RootState> = {
  siteEvents(state: SiteEventState) {
    return state.siteEvents;
  },
  siteEventsPaging(state: SiteEventState) {
    return state.siteEventsPaging;
  },

  submissions(state: SiteEventState) {
    return state.submissions;
  },
  submissionsPaging(state: SiteEventState) {
    return state.submissionsPaging;
  },
}