<template>
  <div class="my-modal">
    <ion-header>
      <ion-toolbar>
        <ion-title slot="start" class="title-ov"><span class="title-ov">Info</span></ion-title>
        <ion-buttons slot="end">
          <ion-button size="large" @click="dismissModal">
            <i class="ti-close title no-select p-3" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="inner-content mt-2 mx-3 mb-5">
        <h4>{{ productName }}</h4>
        <div v-html="htmlContent" class="ic" />
        <div class="my-5"></div>
      </div>
      <div class="button-container" v-if="productPurchasable || productPurchasableGems">
        <div v-if="productPurchasableGems" class="d-flex align-center justify-content-center">
          <ion-button size="large" color="secondary" @click="dismissModal" class="mr-4 action">
            <span class="white">Close</span>
          </ion-button>
          <ion-button size="large" @click="pressBuy" class="ml-4 action">
            <span class="white">Continue</span>
          </ion-button>
        </div>
        <div v-else-if="productPurchasable" class="d-flex align-center justify-content-center">
          <ion-button size="large" color="secondary" @click="dismissModal" class="mr-4 action">
            <span class="white">Close</span>
          </ion-button>
          <ion-button size="large" @click="pressBuy" class="ml-4 action">
            <span class="white">Continue</span>
          </ion-button>
        </div>
      </div>
    </ion-content>
  </div>
</template>

<script lang="ts" setup>
import { modalController } from '@ionic/vue';

const props = defineProps({
  productName: {
    type: String,
    required: true,
  },
  htmlContent: {
    type: String,
    required: true,
  },
  productPurchasable: {
    type: Object,
    required: false,
  },
  productPurchasableGems: {
    type: Object,
    required: false,
  },
});

const productName = toRef(props, 'productName');
const htmlContent = toRef(props, 'htmlContent');
const productPurchasable = toRef(props, 'productPurchasable');
const productPurchasableGems = toRef(props, 'productPurchasableGems');
const emits = defineEmits(['close']);

const dismissModal = () => {
  emits('close');
  modalController.dismiss(null, 'cancel');
};

const pressBuy = () => {
  modalController.dismiss('continue', 'done');
};
</script>

<style lang="sass" scoped>
.my-modal
  background: white
  --width: 600px
  min-height: 100%
  min-width: 100%
  padding-top: var(--safe-ios-margin)
  padding-bottom: calc(var(--safe-ios-margin)/3)
  @media(max-width: 576px)
    --width: 100%
:deep
  p
    margin-block-start: 4px
    margin-block-end: 4px
  ul
    margin-block-start: 4px
    margin-block-end: 4px
  h1
    margin: 1rem 0
    font-weight: bold
    font-size: 1.4rem
  h2
    margin: 1rem 0
    font-weight: bold
    font-size: 1.3rem
  h3
    margin: 1rem 0
    font-weight: bold
    font-size: 1.25rem
  h4
    margin: 1rem 0
    font-weight: bold
    font-size: 1.2rem
  h5
    margin: 0.5rem 0
    font-weight: bold
    font-size: 1.1rem
  h6
    margin: 0
    font-size: 1.05rem
.content
  padding: 1rem
.inner-content
  background: var(--ion-background-color, #fff)
  overflow-y: auto
.ic
  white-space: pre-wrap
.title-ov
  max-width: 90%
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
.button-container
  position: fixed
  bottom: 0
  width: 100%
  display: flex
  justify-content: center
  padding: 1rem
.action
  width: 120px !important
  font-size: 1.3rem !important
</style>
