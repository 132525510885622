import { chAxios } from '@/shared/lib/axios';

export async function submitUserReport(payload: {}) {
  const url = `/content-reports/`;

  const { data } = await chAxios().post(url, payload);
  return data;
}

export async function getUserReportCategories() {
  const url = `/content-reports/report-categories/`;
  const { data } = await chAxios().get(url);
  return data;
}
