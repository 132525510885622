import { MutationTree } from 'vuex';
import { ImagesState } from './types';
import { Image } from '@/shared/types/static-types';

export const mutations: MutationTree<ImagesState> = {
  APPLY_REACTION_ON_IMAGE: (
    state,
    { image, updatedReactionsData }: { image: Image; updatedReactionsData: Partial<Image> }
  ) => {
    // const { inActionCharacter } = state;
    // const visualAlbum = inActionCharacter.visual_collections_page?.results?.find((com: any) =>
    //   com.images_page.results.find((im: any) => im.id === image.id)
    // );
    // const albumIndex = inActionCharacter.visual_collections_page?.results?.findIndex((com: any) =>
    //   com.images_page.results.find((im: any) => im.id === image.id)
    // );
    // const visualimage = visualAlbum?.images_page?.results.find((com: any) => com.id === image.id);
    // const imageIndex = visualAlbum?.images_page?.results.findIndex((com: any) => com.id === image.id);
    // if (image.id === visualimage?.id) {
    //   const updatedInaction = {
    //     //@ts-ignore
    //     ...inActionCharacter.visual_collections_page?.results[albumIndex].images_page.results[imageIndex],
    //     ...updatedReactionsData,
    //   };
    //   //@ts-ignore
    //   let res = state.inActionCharacter.visual_collections_page?.results[albumIndex].images_page.results[imageIndex]
    //   res! = updatedInaction;
    // }
  },
};
