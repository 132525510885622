<template>
  <ion-segment
    class="tabs compact no-shadow bg-transparent tabs-segment d-flex"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button
      v-for="(tab, index) of tabs"
      :key="index"
      class="bg-transparent"
      :value="index"
      style="width: 100%"
    >
      <div>
        <ion-label class="name">
          {{ tab.name }}
          <span v-if="tab.value === 'blabs' && get(count, 'all_posts')" class="name">
            ({{ get(count, 'all_posts') }})</span
          >
          <span v-if="tab.value === 'story' && get(count, 'story_posts_count')" class="name">
            ({{ count.story_posts_count }})</span
          >
          <span v-if="tab.value === 'comic' && get(count, 'comic_posts_count')" class="name">
            ({{ count.comic_posts_count }})</span
          >
        </ion-label>
      </div>
    </ion-segment-button>
  </ion-segment>
</template>

<script lang="ts" setup>
import { Tab } from '@/shared/types/static-types';

const props = defineProps({
  tabs: {
    type: Array<Tab>,
    default: [] as Tab[],
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
  btnClr: {
    type: String,
    default: '',
  },
  textClr: {
    type: String,
    default: '',
  },
  textBtnClr: {
    type: String,
    default: '',
  },
  textFont: {
    type: String,
    default: '',
  },
  count: {
    type: Object,
    default: {},
  },
});
const tabs = toRef(props, 'tabs');
const activeIndex = toRef(props, 'activeIndex');
const textClr = toRef(props, 'textClr');
const emits = defineEmits(['update:activeIndex', 'tab-changed']);

const segmentChanged = ($event: CustomEvent) => {
  const tabIndex = $event.detail.value as number;
  const tab = tabs.value[tabIndex];
  emits('update:activeIndex', tabIndex);
  emits('tab-changed', tab);
};
</script>

<style scoped lang="sass">
.name
  font-size: 18px !important
  color: v-bind(textClr)
  @media(max-width: 551px)
   font-size: 16px !important

.dark .name
   color: v-bind(textClr) !important
ion-segment
  border-bottom: 3px solid v-bind(btnClr)

.dark  ion-segment
  border-bottom: 3px solid v-bind(btnClr)

.tabs-segment
  height: 60px
  padding-top: 10px
  @media(max-width: 551px)
    height:45px !important

ion-segment-button
  --background-checked: v-bind(btnClr)
  border-radius: 10px
  border-bottom-left-radius:0 !important
  border-bottom-right-radius:0 !important
  min-height: 52px !important
  max-height: 52px !important
  height: 52px !important
  color: #5d5c5c !important
  width: inherit
  @media(max-width:900px)
   margin-right: 0px
   margin-left: 0px
  @media(max-width:551px)
    min-height: 33px !important
    max-height: 33px !important
    height: 33px !important
.segment-button-checked
  --background-checked: v-bind(btnClr)
  --indicator-color: transparent !important
  border: none !important
  background:  v-bind(btnClr) !important
  border: none !important
  @media(max-width:551px)
   min-height: 36px !important
   max-height: 36px !important
   height: 36px !important
  *
    color: v-bind(textBtnClr) !important
.dark .segment-button-checked
    *
     color: v-bind(textBtnClr) !important
</style>
