<template>
  <ion-modal
    class="modal-big"
    css-class=""
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="close"
  >
    <div class="inner-content">
      <div v-if="!isEmpty(characters)" class="d-flex flex-column px-4 py-3">
        <div class="d-flex justify-content-between align-items-center">
          <div class="title mt-3">{{ isMember ? 'Members' : 'Characters' }}</div>

          <ion-buttons>
            <ion-button class="close-button text-black" @click.prevent="close">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </div>
        <div class="container">
          <div class="row row-cols row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5 no-space">
            <div v-for="char of characters" :key="char.id" class="col">
              <div class="one-character mt-3">
                <div class="h-100">
                  <div class="d-flex justify-content-center">
                    <router-link
                      v-if="isMember"
                      :to="{ name: 'profile', params: { username: char.username } }"
                      class="clickable-item-hov"
                    >
                      <img
                        v-image
                        class="char-img clickable-item-hov"
                        :src="resizeUpload(char.profile_picture_url, '250x250')"
                      />
                    </router-link>
                    <router-link
                      v-else
                      :to="{ name: 'character-profile-new', params: { slug: char.slug } }"
                      class="clickable-item-hov"
                      ><img
                        v-image
                        class="char-img clickable-item-hov"
                        :src="resizeUpload(char.info.cropProfilePicture, '250x250')"
                    /></router-link>
                  </div>

                  <div class="name d-flex justify-content-center mt-2 text-center">
                    <router-link
                      :class="{
                        'text-color-username': usernameColor(char) && !usernameColor(char).startsWith('#'),
                        'bold-username': usernameColor(char),
                      }"
                      :style="
                        !usernameColor(char)
                          ? ''
                          : usernameColor(char).startsWith('#')
                          ? `color: ${usernameColor(char)} !important`
                          : `background-image: ${usernameColor(char)} !important`
                      "
                      v-if="isMember"
                      :to="{ name: 'profile', params: { username: char.username } }"
                      class="clickable-item-hov"
                    >
                      {{ char.username }}
                    </router-link>
                    <router-link
                      v-else
                      :to="{ name: 'character-profile-new', params: { slug: char.slug } }"
                      class="clickable-item-hov"
                      :class="isMember ? 'text-white' : 'text-black'"
                      >{{ char.info.name }}</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="d-flex p-5">
        <ChLoading size="sm" class="mx-auto" />
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  characters: {
    type: Object,
  },
  isMember: {
    type: Boolean,
  },
});

const emits = defineEmits(['close']);
const isMember = toRef(props, 'isMember');
const close = () => {
  emits('close');
};

const usernameColor = (user: any) => {
  return user?.customize_profile?.username_color || '';
};
</script>

<style lang="sass" scoped>
.close-button
  width: 50px
.no-space
  margin-left: 0
  margin-right: 0
  margin-bottom: 0

.no-space [class*='col-']:not(:first-child),
.no-space [class*='col-']:not(:last-child)
  padding-right: 0
  padding-left: 0
.see-all-btn
  --border-radius: 10px
  height: 30px
.row
  margin-top: 10px !important

.one-character
  img
    min-width: 90px
    width: 90px !important
    height: 90px !important
    border-radius: 45px
    border: solid gray 0.1px
    object-fit: cover
    .name
      font-size: 18px
      font-weight: bold
      color: #737373
      cursor: pointer
    .name:hover
      opacity: 0.7

.title
  font-weight: bold
  font-size: 22px
.modal-big
  --width: 700px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 95vh
  overflow: auto
</style>
