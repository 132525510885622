<template>
  <div class="p-3">
    <div class="title d-flex justify-content-center align-items-center text-center mx-auto">Are you over 18?</div>
    <div class="d-flex mt-2">
      <ion-button color="transparent" class="w-100 age-btn yes-btn-clr" @click="handleYes">Yes</ion-button>
      <ion-button color="transparent" class="w-100 age-btn no-btn-clr" @click="handleNo">No</ion-button>
    </div>
    <div class="d-flex justify-content-center align-items-center text-center mx-auto py-2">
      <small class="small-text">You can change this later on your profile.</small>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UserProfile } from '@/shared/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';
import { profileStore } from '@/shared/pinia-store/profile';

const { updateUserProfile } = profileStore();
const { user } = authStore();
const isOver18: any = ref(null);

const emits = defineEmits(['skip', 'forward']);

watch(
  user,
  () => {
    if (user.value) {
      isOver18.value = get(user.value, 'over_18');
    }
  },
  { immediate: true }
);
const handleYes = async () => {
  isOver18.value = true;
  await save();
  emits('forward');
};

const handleNo = async () => {
  isOver18.value = false;
  await save();
  emits('skip');
};

const save = () => {
  const data: Partial<UserProfile> = {
    over_18: !!isOver18.value,
  };
  setTimeout(() => {
    updateUserProfile(data);
  }, 1000);
};
</script>

<style lang="sass" scoped>
.small-text
  color: #A7AABE
  font-weight: bold
.no-btn-clr
  border: 2px solid #D222A3
  color: #D222A3 !important


.yes-btn-clr
  border: 2px solid #00B4C5
  color: #00B4C5 !important
.age-btn
  border-radius: 12px
  font-size: 14px
  font-weight: bold

.dark .action-btn
  color: #fff


.title
  font-size: 22px
  font-weight: bold
  color: #214163
.dark .title
  color: #ffffff

.max-w-s
  max-width: 150px
</style>
