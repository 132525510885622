<template>
  <div
    class="character-card d-flex position-relative"
    :class="{
      active: selectedChar,
      'not-active': !selectedChar && get(character, 'slug'),
      'border-clr': get(character, 'username'),
    }"
  >
    <div class="position-absolute d-flex flex-column justify-content-end char-info" v-if="get(character, 'slug')">
      <div class="name">
        <router-link
          class="clickable-item-hov"
          style="color: white"
          v-if="get(character, 'slug')"
          :to="{ name: 'character-profile-new', params: { slug: get(character, 'slug') } }"
          @click="emits('close')"
        >
          {{ truncateText(get(character, 'info.name')) }}</router-link
        >
        <span v-else>
          {{ get(character, 'info.name') }}
        </span>
      </div>
    </div>
    <div class="image d-flex align-items-center justify-content-center" v-if="get(character, 'slug')">
      <img loading="lazy" v-character-card-image :src="get(character, 'info.cropProfilePicture')" />
    </div>

    <div v-else class="d-flex flex-column justify-content-center mx-auto align-items-center">
      <img
        loading="lazy"
        style="width: 100px; height: 100px; border-radius: 50%"
        v-user-image
        :src="get(character, 'profile_picture_url')"
      />
      <span class="self">Myself</span>
      <router-link class="username" :to="{ name: 'profile', params: { username: character.username } }">
        @{{ character.username }}
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { checkmarkCircle } from 'ionicons/icons';
import { Character } from '@/types/static-types';
import { authStore } from '@/shared/pinia-store/auth';

const props = defineProps({
  character: {
    type: Object,
    default: () => {},
  },
  selectedChar: {
    type: Boolean,
    default: false,
  },
});
const { user } = authStore();
const emits = defineEmits(['close']);
const character = computed(() => props.character as Character);

const truncateText = (value: string, length = 30) => {
  return truncate(value, { length, separator: /,? +/ });
};
</script>

<style lang="sass" scoped>
.active
  border: 4px solid var(--ion-color-primary) !important
.not-active
  border: none !important

.self
  font-weight: 700
  color: #214183
.dark .self
  color: white
.username
  font-weight: bold
  font-size: 18px
.border-clr
  border: 1px solid white
.character-card
  width: 100%
  aspect-ratio: 1
  border-radius: 15px
  box-shadow: 1px 1px 5px 0px rgba(33,65,99,0.19)
  .image
    background: #4A9FC3
    width: 100%
    height: 100%
    border-radius: 10px

  img
    width: 100%
    height: 100%
    border-radius: 10px
    object-fit: cover
  .name
    font-size: 14px
    font-weight: bold
    color: white
.char-info
  border-radius: 10px
  width: 100%
  height: 100%
  padding: 9px
  background: linear-gradient(180deg, rgba(247,6,207,0) 0%, rgba(0,0,0,0.3) 74%, #170624CF 100%)
</style>
