<template>
  <div :class="class" @click="openFanWorkModal">
    <slot>
      <ion-button color="primary" class="action">
        <div class="d-flex">
          <div class="text">Submit FanWork</div>
        </div>
      </ion-button>
    </slot>
  </div>

  <choose-fan-artand-fan-work-modal
    :is-open="isChooseFanWorkOpen"
    @dismiss-modal="closeFanWorkModal"
    @selected="selectedFanWork"
  />
  <submit-fanart-and-fanwork-modal
    :type="submissionType"
    :is-open="isSubmitFanWorkOpen"
    :submitted-for-id="submittedForId"
    :submitted-for-type="submittedForType"
    @dismiss-modal="closeSubmitFanWorkModal"
    @go-back="openFanWorkModal"
    @submitted="openSubmittedFanWorkModal"
  />
  <submitted-fan-work-modal :is-open="isSubmitted" @dismiss-modal="closeModal" />
</template>

<script lang="ts" setup>
import SubmittedFanWorkModal from '../../modals/SubmittedFanWorkModal.vue';
import ChooseFanArtandFanWorkModal from '../../modals/ChooseFanArtandFanWorkModal.vue';
import SubmitFanartAndFanworkModal from '../../modals/SubmitFanartAndFanworkModal.vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  submittedForId: {
    type: String,
    default: '',
  },
  submittedForType: {
    type: String,
    default: 'character',
  },
  class: {
    type: String,
    default: '',
  },
});

const disabled = toRef(props, 'disabled');
const submissionType = ref('');
const isChooseFanWorkOpen = ref(false);
const isSubmitFanWorkOpen = ref(false);
const isSubmitted = ref(false);

const openFanWorkModal = () => {
  if (disabled.value) return;
  isSubmitFanWorkOpen.value = false;
  submissionType.value = '';
  isChooseFanWorkOpen.value = true;
};

const closeFanWorkModal = () => {
  isChooseFanWorkOpen.value = false;
};

const selectedFanWork = (type: any) => {
  submissionType.value = type;
  isChooseFanWorkOpen.value = false;
  isSubmitFanWorkOpen.value = true;
};

const closeSubmitFanWorkModal = () => {
  isSubmitFanWorkOpen.value = false;
};

const openSubmittedFanWorkModal = () => {
  isSubmitFanWorkOpen.value = false;
  isSubmitted.value = true;
};

const closeModal = () => {
  isSubmitted.value = false;
};
</script>

<style lang="sass" scoped></style>
