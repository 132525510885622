<template>
  <div>
    <DeletionToggle :title="title" :id="id" :value="value" :isDraft="isDraft" @close="dismiss">
      <ion-item class="item-container clickable-item-hov">
        <div class="menu-item text-danger">
          <small>Delete {{ title }}</small>
        </div>
      </ion-item>
    </DeletionToggle>
  </div>
</template>

<script lang="ts" setup>
import DeletionToggle from '@/shared/components/DeletionToggle.vue';
const props = defineProps({
  instance: {
    type: Object,
  },
  title: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  isDraft: {
    type: Boolean,
    default: false,
  },
});

const dismiss = () => {
  props.instance?.dismiss();
};
</script>

<style scoped lang="sass">
.bottom-button
  background-color: var(--ion-color-danger) !important
  color: white
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
</style>
