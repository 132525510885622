import { NotSignedInSection } from '@/shared/types/ui';
import { EditorAboutExample } from '@/shared/types/static-types';

import { featureFlags } from '@/shared/config/feature-flags';

export const delimitingCharacter = String.fromCharCode(8204);
export const delimitingCharacterRegex = new RegExp(delimitingCharacter, 'g');
export const featurePreviewDescriptions = {
  alignment_charts: "Display charts about your character's personality!",
  trivia: 'Display trivia questions and see how well others do answering them!',
  timeline: 'Make a timeline for your character!',
};
export const DURATIONS = {
  MONTHLY: 'month',
  YEARLY: 'year',
};
export const coverPreferences = {
  MULTIPLE: 'multiple',
  SINGLE: 'single',
};


export const subscriptionLIMIT = {
  1: 3,
  2: 9  
}

export const COMPLIMENT_BOMB = 'compliment_bomb';
export const CANCEL = 'cancel';
export const MINI_SPECIAL_0430 = 'mini_special_0430';

const constants: any = {
  uploadMaxFileSizeMB: 20,
  logLevels: {
    INFO: 'info',
    WARN: 'warn',
    ERROR: 'error',
  },
  commafulBlue: '#ae38e5',
  usernameRegex: /^[0-9a-zA-Z_.-]{1,15}$/,
  tagUsernameRegex: /(^|\s)@[0-9a-zA-Z_.-]{1,15}/g,
  autoplayIntervalTime: 200, // in ms
  baseS3Url: 'https://crb-media.s3.us-west-2.amazonaws.com',
  sanitizerWhitelist: {
    allowedTags: ['a'],
    allowedAttributes: {
      a: ['href', 'target', 'rel'],
    },
  },
  anonUserId: '00000000-0000-0000-0000-000000000000',
  maxImgDim: 700,
  maxMobileImgDim: 480,
  maxThumbnailDim: 90,
  maxProfilePictureDim: 120,
  maxProfilePictureSmallDim: 36,
  autosaveTimeout: 2000, // in ms
  invalidUploadTypeMessage: 'Sorry, you can only upload files of type .png, .jpg, or .gif',
  defaultInviteMessage: 'Hi - I wanted to tell you about my new favorite thing!',
  blockUserConfirmMessage: 'Are you sure you want to block this user on chat?',
  emptyGif: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
  maxRecommendedTags: 5,
  announcementCheckInterval: 60 * 60 * 1000,
  onboardingPeriodDays: 7,
  maxAlbumImageCount: 200,
  maxCharactersCount: 9,
};

constants.tagCollections = {
  noTag2: '-',
  storyTypes: {
    poetry: 'poems',
    none: '',
    fanfiction: 'fanfiction',
  },
};

constants.cookiePerms = {
  ga: true,
  sen: true,
  amp: true,
  hot: true,
};

constants.homeNav = {
  feed: 'hn_feed',
  compliments: 'hn_compliments',
};

constants.lsKeys = {
  cookiePermissions: 'chrhub-a',
  tagsHit: 'tagsHit',
  tagCollectionsHit: 'tagCollectionsHit',
  privacyModalSeen: 'cmflprivacyModalSeen',
  euCookieModalSeen: 'euCookieModalSeen',
  euEmailModalSeen: 'cmflEuEmailModalSeen',
  analyticsOn: 'cmflanlcson',
  referrer: 'cmflReferrer',
  lateNightModalShown: 'cmfllnms',
  announcement: 'cmflann',
  announcementLastCheckedDateTime: 'cmflanndt',
  dismissedAnnouncementID: 'cmflann_d',
};

constants.cookieKeys = {
  euCookiesEnabled: 'cmflEUCE',
  isEU: 'cmfleu',
};

constants.uploadStates = {
  UPLOAD_DEFAULT: 0,
  UPLOAD_IN_PROGRESS: 1,
  UPLOAD_FAILED: 2,
  UPLOAD_SUCCESS: 3,
};

constants.errors = {
  loginRequired: { message: 'Login required', id: 'loginRequired' },
  storyEditedElsewhere: { message: 'User story has been modified elsewhere', id: 'storyEditedElsewhere' },
  networkError: { message: 'Network error', id: 'loginRequired' },
};

constants.errorMsgs = {
  chatGetRoomsFailed: "We can't retrieve your conversations right now. Please try again later.",
  chatGetRoomFailed: "We can't retrieve that chat room right now. Please try again later.",
  storyLoadingFailed: "We can't retrieve that story right now. Please try again later.",
  tryLater: 'Please try again later.',
  imageCollectionLoadingFailed: 'Could not load image collection options. Please try gain later.',
};

constants.player = {
  defaultAlpha: 0.3,
};

constants.modals = {
  transcript: 'transcript',
  addToSeries: 'addToSeries',
  importText: 'importText',
  seriesDetails: 'seriesDetails',
  seriesStories: 'seriesStories',
  badge: 'badge',
  media: 'media',
  chatLookup: 'chatLookup',
  editChatGroup: 'editChatGroup',
  commentStory: 'commentStory',
  commentShoutout: 'commentShoutout',
  confirm: 'confirm',
  createFinish: 'createFinish',
  createOnboarding: 'createOnboarding',
  cropper: 'cropper',
  editProfile: 'editProfile',
  email: 'email',
  imageSelection: 'imageSelection',
  lateNightTweet: 'lateNightTweet',
  login: 'login',
  password: 'password',
  profileOnboarding: 'profileOnboarding',
  report: 'report',
  share: 'share',
  sharePlaylist: 'sharePlaylist',
  shareOnboarding: 'shareOnboarding',
  storyPlayer: 'storyPlayer',
  storyShoutout: 'storyShoutout',
  editShoutout: 'editShoutout',
  seriesShoutout: 'seriesShoutout',
  tumblrblog: 'tumblrblog',
  privacyUpdate: 'privacyUpdate',
  privacyEuEmail: 'privacyEuEmail',
  privacyEuCookies: 'privacyEuCookies',
  signupTermsEuModal: 'signupTermsEuModal',
  video: 'video',
};

constants.badgePriorityList = ['vip', 'amb', 'leg', 'lv4', 'avr', 'str', 'lv3', 'pwr', 'gft', 'lv2', 'fqr', 'lv1'];
constants.thankYouMessages = [
  `You're the bomb!`,
  'Wow!! Thank you so much for doing this!',
  `You're amazing!`,
  `Crying so much right now <3`,
];
// these modals don't close even if the backdrop is clicked
constants.stickyModals = ['cropper'];

constants.targetModels = {
  comment: 'comment',
  playlist: 'playlist',
  shoutout: 'shoutout',
  story: 'story',
  series: 'series',
};

constants.categories = {
  FC: 'Fiction',
  FE: 'Feelings',
  FF: 'Fanfiction',
  FN: 'Funny',
  PT: 'Poetry',
  RM: 'Romance',
  TH: 'Thriller',
  TS: 'Thoughts',
};

constants.virtualItems = {
  profileRing: 'Profile Ring',
  profileAnimation: 'Background Overlay',
};

constants.onboardingExcludeCategories = ['FF'];

constants.prompts = [
  'A wave has hit your character’s city, what are you going to do?',
  'Your character is being chased by a monster. Write about the escape.',
  'Is something bothering your character?',
  'What’s on your character’s mind right now?',
  'Write about a time your character embarrassed themselves.',
  'Write about a time your character was a hero.',
  'Write about a time your character was a good friend.',
  'Write about a time your character went on a trip.',
  '“This is for you”',
  '“I’ll be there”',

  '“the coast is clear”',
  '“take my hand”',
  '“I love you, but….”',
  '“I’m sorry, but….”',
  '“Don’t you remember?”',
  '“Can anybody hear me?”',

  '“You’re a liar”',
  '“I’m in love”',
  '“That was bad”',
  '“Try to relax”',
  '“Please don’t give up”',
  '“I don’t need your help”',
  '“What have you done?”',
  '“I knew this was a terrible idea”',
  '“You’ve changed”',
  '“I don’t want to talk about it”',
  '“I lied”',
  '“I want to go home”',
  '“You’re embarrassing me”',
  '“I hate it”',
  '“Is that even possible?”',
  '“What’s the point?”',
  '“Who told you that?”',
  '“I didn’t mean to”',
  '“Don’t freak out”',

  '“We’re doomed”',
  '“That’s not fair”',
  '“You can’t tell anyone”',
  '“Don’t get any ideas”',

  '“Are the rumors true?”',
  '“What are you hiding?”',

  '“Easier said than done”',
  '“Can you forgive me?”',

  'Pick 2 random characters of yours and write about them',
  'Your character’s most embarrassing moment',
  'Write a journal entry for one of your characters!',

  'Your character is in an argument',
  'Write about a date that went wrong.',
  '“Are you mad at me?”',
  '“Are you lost?”',

  '“Where am I?”',
  '“You’re the worst”',
  'Your character in an awkward moment',
  'Your character is the new kid in school',

  'Your character trying out a new hobby',

  'Your character on a first date',

  'Your character running into somebody at a local coffee shop',

  'Write a conversation at the dinner table',

  'A secret that’s been hidden for years…..revealed.',
  'Your character’s daily routine',
  'Your character’s night time, get ready for bed routine',
  'Your character’s morning routine',
  'Your character’s craziest dream',
  'What was your character like as a kid?',
  'Your character at a slumber party',
  'The best day ever',
  'Your character and their crush',
  'Dreading school',
  'Some students at school have gone missing…',
  'Your character asks for advice from the community',
  'Your character hanging out with Hubert for the day!',
  'Reuniting with an old friend',
  'Something crazy just went down at school/work',
  'Make-a-prompt: make a prompt for other people to reply to',
  'Texting a conversation',
  'Make-a-prompt: create a scenario starter for other people to respond to in the feed!',
  'Make-a-prompt: Ask a question for people on the feed to answer!',
  'What’s the hardest thing your character has ever done?',
  'Write about your character’s biggest fear',
  'What does your character think about love?',
  'What does your character think about their future?',
  'What’s the worst memory your character has?',
  'What are your character’s aspirations?',
  'Your character is faced with a difficult decision',
  'The most random thing ever happens to your character',
  'Your character is at the beach',
  'Your character is at a party',
  'Your character is sick',
  'Your character is going through a tough time',
  'Your character is trying to figure out the future',
  'Your character is reminiscing about the past',
  'Your character is daydreaming',
  'What would your character do if they won the lottery?',
  'Your character is transported to a different world, what do they do?',
  'What’s the most daring thing your character has ever done?',
  'What’s the biggest fear your character has and how do they deal with it?',
  'What would be your character’s perfect day?',
  'What’s the best advice your character has ever been given?',
  'What’s the worst advice your character has ever been given?',
  'What’s your character’s guilty pleasure?',
  'What’s the deal with this new person in town?',
  'The person your character has been waiting for…finally arrives',
  'What does your character think about love?',
  'Your character is babysitting',
  'Your character is lost',
  'Your character just got some terrible news',
  'Your character is going on a roadtrip',
  'What would your character do with a kiosk in their room where they could order anything they want for delivery to their room without even having to speak?',
  'While your character is out on a walk, they stumble upon a $100 bill. What do they do with it?',
  'Your character is at a museum and they get separated from their group. What do they do?',
  'Your character is home alone and they hear a noise coming from downstairs. What do they do?',
  'Your character finds out they’re pregnant/they’re going to be a parent. How do they react?',
  'Your character has to do a presentation in front of the class but they’re really nervous about it. What do they do?',
  'Your character is at a park and they see a dog that looks lost. What do they do?',
  'Your character is in a store and they see somebody shoplifting. What do they do?',
  'Your character is studying for a big test and they’re really struggling. What do they do?',
  'Your character is sitting in a park, minding their own business, when a bird poops on them. What do they do?',
  'You character has been chosen to be a contestant on a new reality TV show. How do they feel about it?',
  'Your character is at a restaurant and they find a hair in their food. What do they do?',
  'Your character is at a movie theater and the person in front of them has their feet up on the back of your seat. What do they do?',
  'Your character goes on a blind date. What happens?',
  'Your character is stuck in an elevator with a person they don’t like. What happens?',
  'Your character is at a party and they see somebody they don’t want to talk to. What do they do?',
  'Your character is in a store and they can’t find their wallet. What do they do?',
  'Your character is driving and they get lost. What do they do?',
  'It’s a rainy day and your character is stuck inside. What do they do?',
  'Your character is babysitting and the kids they’re watching won’t go to bed. What do they do?',
  'Your character’s phone battery dies and they’re expecting an important phone call. What do they do?',
  'Your character finds out they’re going to be on a reality TV show. How do they react and how do they prepare?',
  'Your character is nominated for an award. How do they react and who do they tell?',
  'Your character is in a play. What role do they have and how do they prepare?',
  'Your character is in a competition. What are they competing in and how do they train?',
  'Make-a-prompt: Write a short story starter for others on the feed to continue!',
  'Your character is invited to a fancy event',
  'Make-a-prompt: Make a situation and ask the community what they would do in this situation?',
  'Your character is playing truth or dare',
  'Make-a-prompt: come up with an interesting plot twist for people to add to!',
  'Your character is in a heated discussion',
  'Your character is bored',
  'Your character is eavesdropping',
  'Make-a-prompt: Ask people what would happen if….',
  'Make-a-prompt: Write a one-sentence story starter for people to continue in the replies!',
  '“It’s not what it looks like”',
  '“I swear, I didn’t know”',
  '“I wish I could take it back”',
  '“I regret everything”',
  '“Please don’t be mad”',
  '“Please don’t hate me”',
  '“Please don’t leave me”',
  '“Why are you doing this to me?”',
  '“Why can’t you just leave me alone?”',
  '“I don’t want to see you ever again”',
  '“Just stay away from me”',
  '“This is all your fault”',
  '“I hate you”',
  '“I never want to see you again”',
  '“I’m warning you”',
  '“You’re going to regret this”',
  '“I’ll make you pay”',
  '“This isn’t over”',
  '“There’s nothing to be scared of”',
  '“It’s okay, I’m here”',
  '“I’m not going to hurt you”',
  '“I would never hurt you”',
  '“I’m sorry I hurt you”',
  '“It was an accident”',
  '“I didn’t mean to”',
  '“I was just trying to help”',
  '“I was just trying to do the right thing”',
  '“I was just trying to make you happy”',
  'Write about a time your character had to face a fear.',
  'What does your character want more than anything else?',
  'What is your character’s biggest regret?',
  'Write about a time your character made a difficult decision.',
  'What is your character’s favorite thing to do?',
  'What is your character’s biggest secret?',
  'Write about a time your character had to deal with a loss.',
  'Your character wakes up to find they have switched bodies with someone else. Write about the events that unfold.',
  'Your character has been granted one wish. Write about what they wish for and what happens.',
  'Your character has been turned into a frog. Write about the events that unfold.',
  'Your character has been turned into a vampire. Write about the events that unfold.',
  'Your character has been turned into a werewolf. Write about the events that unfold.',
  'Your character has been abducted by aliens. Write about the events that unfold.',
  'Write about a time your character had to overcome a challenge.',
  'Write about a time your character met someone new.',
  'Write about a time your character had to deal with a difficult situation.',
  'Write about a time your character made a mistake.',
  'Write about a time your character had to forgive someone.',
  'Write about a time your character had to let go of something.',
  'Write about a time your character had to start over.',
  'Your character has to choose between two difficult options. Write about the events that unfold.',
  'Your character has been betrayed by someone they trust. Write about the events that unfold.',
  'Your character has to save someone else’s life. Write about the events that unfold.',
  'Your character has to confront a fear. Write about the events that unfold.',
  'Your character is in a dangerous situation. Write about the events that unfold.',
  'Your character is being chased by a group of people. Write about the escape.',
  'Your character is being chased by an animal. Write about the escape.',
  'Your character is being held captive. Write about the events that unfold.',
  'Your character has to make a difficult choice. Write about the events that unfold.',
  'Your character is in a situation where they could be seriously hurt. Write about the events that unfold.',
  'Your character is in a situation where they could die. Write about the events that unfold.',
  'Your character is in a situation where they are being threatened. Write about the events that unfold.',
  'Your character finds a genie in a lamp. Write about the events that unfold.',
  'Your character stumbles upon a magical kingdom. Write about the events that unfold.',
  'Your character is transported to a different time period. Write about the events that unfold.',
  'Your character is offered a deal by a demon. Write about the events that unfold.',
  'Your character is cursed. Write about the events that unfold.',
  'Your character is granted a magical power. Write about the events that unfold.',
  'Your character is transformed into a different species. Write about the events that unfold.',
  'An evil villain is after your character. Write about the events that unfold.',
  'Your character is trying to save a friend. Write about the events that unfold.',
  'Your character is trying to stop an evil plot. Write about the events that unfold.',
  'Your character has been captured. Write about the events that unfold.',
  'Your character is lost in a strange place. Write about the events that unfold.',
  'Your character is in danger. Write about the events that unfold.',
  'Your character is in a race. Write about the events that unfold.',
  'Your character is participating in a tournament. Write about the events that unfold.',
  'Your character is on a quest. Write about the events that unfold.',
  'Your character is going on an adventure. Write about the events that unfold.',
  'Your character is trying to solve a mystery. Write about the events that unfold.',
  'Your character is trying to survive in a hostile environment. Write about the events that unfold.',
  'Your character is in a battle. Write about the events that unfold.',
  'Your character is trying to escape from danger. Write about the events that unfold.',
  'Your character is trying to find a lost friend. Write about the events that unfold.',
  'Your character is trying to make a tough decision. Write about the events that unfold.',
  'Your character is dealing with a personal tragedy. Write about the events that unfold.',
  'Your character is trying to help someone in need. Write about the events that unfold.',
  'Your character is searching for a lost treasure. Write about the events that unfold.',
  'Your character is trying to clear their name. Write about the events that unfold.',
  'Your character is trying to find themselves. Write about the events that unfold.',
  'Your character is trying to save the world. Write about the events that unfold.',
  'Your character is on a journey of self-discovery. Write about the events that unfold.',
  'Your character has been sent on a mission. Write about the events that unfold.',
  'Your character is in a competition. Write about the events that unfold.',
  'Your character is trying to make a difference. Write about the events that unfold.',
  'Your character is facing a tough challenge. Write about the events that unfold.',
  'Your character is in a difficult situation. Write about the events that unfold.',
  'Your character is trying to do the right thing. Write about the events that unfold.',
  'Your character is torn between two choices. Write about the events that unfold.',
  'Your character is trying to figure out who they are. Write about the events that unfold.',
  'Your character is facing an uphill battle. Write about the events that unfold.',
  'Your character is at a crossroads. Write about the events that unfold.',
  'Your character is on a quest for knowledge. Write about the events that unfold.',
  'Your character is seeking justice. Write about the events that unfold.',
  'Your character is trying to find their place in the world. Write about the events that unfold.',
  'Your character is struggling with an inner conflict. Write about the events',
  'What does your character think about school?',
  'What does your character think of their parents?',
  'What does your character think of their siblings?',
  'What does your character think of their friends?',
  'What does your character think of their enemies?',
  'What does your character think about strangers?',
  'What does your character think about animals?',
  'What does your character think about nature?',
  'What does your character think about technology?',
  'What does your character think about the future?',
  'What does your character think about the past?',
  'What does your character think about the present?',
  'What does your character think about love?',
  'Pretend your character is in ____ and describe your day!',
  'Make-a-prompt: Ask the community an open-ended question for their characters to respond!',
  'Make-a-prompt: Share an opinion and invite discussion from other characters',
];

constants.defaultLoginStateInfo = {
  path: '/myhome',
  params: {},
  query: {},
};

constants.getprompts = function () {};

constants.generateStoryTitle = function () {
  return 'Untitled';
};

constants.reasonsForLeaving = {
  quitwriting: 'No longer writing',
  format: "Didn't like the format",
  longer: 'Want to post longer works',
  create: "Didn't understand how to create",
  read: "Didn't understand how to read",
  slowsite: 'Website is slow',
  slowapp: 'App is slow',
  nointerestingstories: "Didn't find interesting stories to read",
};

constants.creatorSteps1 = [
  {
    target: '#v-step-0', // We're using document.querySelector() under the hood
    content: `Start typing and click here to add a new page`,
    params: {
      placement: 'right',
    },
    labels: {
      buttonNext: 'Ok',
    },
  },
  {
    target: '#v-step-1', // We're using document.querySelector() under the hood
    content: `Already wrote a story or prefer typing in a word doc?`,
    params: {
      placement: 'bottom',
    },
  },
];

constants.creatorSteps2 = [
  {
    target: '[data-v-step="10"]',
    content: `Start typing and click here to add a new page`,
    params: {
      placement: 'bottom',
    },
    labels: {
      buttonNext: 'Ok',
    },
  },
  {
    target: '[data-v-step="11"]',
    content: `Already wrote a story or prefer typing in a word doc?`,
    params: {
      placement: 'top',
    },
  },
];

constants.adviceCategoryTitles = {
  beginner: 'Beginner',
  fiction: 'Fiction',
  mystery: 'Mystery',
  publishing: 'Publishing',
  poetry: 'Poetry',
  'writers-block': "Writer's Block",
};

constants.reactions = {
  NI: { name: 'Nice', inapp: 'like', image: '/reactions/NI.svg', imageSm: '/reactions/nice-sm.png' },
  LV: { name: 'Love', inapp: 'love', image: '/reactions/LV.svg', imageSm: '/reactions/love-sm.png' },
  FN: { name: 'Funny', inapp: 'haha', image: '/reactions/FN.svg', imageSm: '/reactions/funny-sm.png' },
  WO: { name: 'Wow', inapp: 'wow', image: '/reactions/WO.svg', imageSm: '/reactions/wow-sm.png' },
  SD: { name: 'Sad', inapp: 'sad', image: '/reactions/SD.svg', imageSm: '/reactions/sad-sm.png' },
};

constants.privacyTypes = [
  {
    name: 'Public',
    value: 'P',
  },
  {
    name: 'Unlisted',
    value: 'U',
  },
];

constants.getPrivacyLabelFromCode = (privacyCode: string) => {
  if (privacyCode === 'P') return constants.privacyTypes[0];
  else if (privacyCode === 'U') return constants.privacyTypes[1];
  else if (privacyCode === 'D') return constants.privacyTypes[2];
  throw new Error('Invalid privacy level');
};

constants.profileAccounts = [
  {
    name: 'twitter',
    link: 'https://twitter.com/',
  },
  {
    name: 'instagram',
    link: 'https://instagram.com/',
  },
  {
    name: 'tumblr',
    link: 'https://tumblr.com/',
  },
];

constants.forgotPassToastOptions = {
  INVALID_TOKEN: 'token_invalid',
  RESET_SUCCESSFUL: 'reset_successful',
};

constants.commentedAsTypes = {
  USER: 'user',
  CHARACTER: 'character',
};

constants.postAsTypes = {
  USER: 'user',
  CHARACTER: 'character',
};

constants.requesteeAsTypes = {
  USER: 11,
  CHARACTER: 14,
};

constants.notificationVerbs = {
  CUSTOM: '__custom__',
  REACTION: 'got a reaction',
  COMMENT: 'received a comment',
  COMMENT_REPLY: 'replied to a comment',
  RC_CHAR_REQ: 'requested character rolechat',
  RC_STARTER_REQ: 'requested starter rolechat',
  WORLD_CHAR_REQ: 'character request to a world',
  WORLD_USER_REQ: 'request to a world',
  WORLD_CHAR_ACCP: 'leader accept your character request',
  WORLD_USER_ACCP: 'leader accept your request',
  WORLD_REACTION: 'got a world reaction',
  CHAR_TRENDING: 'character is trending',
  CHAR_FEATURE: 'character was featured!',
  CHAR_VISUAL: 'visual was featured!',
  WORLD_TREND: 'world is trending',
  BLAB_REACTION: 'got a blab reaction',
  BLAB_REPLY_REACTION: 'got a blab reply reaction',
  BLAB_SHINE_REPLY: 'reply was sunshined',
  BLAB_SEND_REPLY: 'replied to blab',
  BLAB_PARENT_REPLY: 'replied to blab reply',
  CHAR_SECTION: 'character was featured in section',
  FOLLOW_USER: 'follow',
  ACTIVITY_CHEER_CHART: 'cc_activity_selected',
  SF_ACTIVITY_CREATED: 'sf_activity_created',
  CHEER: 'cheered',
  USER_CHEER_CHART: 'cheer won for cheer chart',
  EVENT_SUBMISSION_REVIEWED: 'event submission was approved',
  HELP_CHEER: 'cheer helped for cheer chart',
  COLLECTIBLE_RECEIVED: 'received an item',
  SENT_FAN_ART: 'fanart received',
  SENT_FEEDBACK: 'fanart feedback note received',
  SENT_DEC_FEEDBACK: 'decoration feedback note received',
  REACTION_FEEDBACK: 'reacted to fanart',
  IMAGE_REACTION: 'got a image reaction',
  REQUEST_ROOM: 'request to a room',
  MISSED_MESSAGES: 'you have missed messages in your social spaces',
  CHATROOM_REPLY: 'replied to world message',
  USER_JOINED: 'added a user to a world',
  ADDED_CHAR_WORLD: 'added a character to a world',
  QUEST_UPDATE: 'quest update',
  CHARACTER_TRANSFER_REQUEST_SENT: 'sent a character transfer request',
  CHARACTER_TRANSFER_REQUEST_ACCEPTED: 'accepted a character transfer request',
  CHARACTER_TRANSFER_REQUEST_REJECTED: 'declined a character transfer request',
  FANART_REACTION: 'got a fanart reaction',
  DECORATION_REACTION: 'reacted to decoration',
  DECORATION_RECEIVED: 'decoration received',
  QUEST_SUBMISSION_REACTION: 'got a quest submission reaction',
  QUIZ_READY: 'quiz ready',
  RAFFLE_MILESTONE: 'raffle milestone achieved',
  RAFFLE_ENDED: 'raffle ended',
  RAFFLE_WINNER: 'winner announced',
  GIFTED_ITEM: 'gifted an item',
  THANKS_GIFT_MSG: 'said thanks on gift',
  GIFTED_VIRTUAL_ITEMS: 'gifted a virtual item',
  CLAIMABLE_VIRTUAL_ITEM: 'claimable for subscribers',
  GEMS_TRANSFER: 'Gem Transfer',
  GEMS_RECEIVED: 'Gems Received',
  CHALLENGE_REWARD: 'Joining Bonus'
};
constants.welcomeVerbs = {};

constants.charRouletteReactions = [
  { value: 'lov', text: '😍 Which character do you like more?' },
  // { value: 'obses', text: '😍 I’m most obsessed with:' },
  // { value: 'socoo', text: '😎 The coolest character has to be:' },
  // { value: 'relat', text: '🥺 The most relatable is:' },
  // { value: 'wellw', text: '✍ The best-written character is definitely:' },
  // { value: 'love', text: '💖 I’m most in love with:' },
  // { value: 'ba', text: '🔥 _______ is by far the most badass of the group:' },
  // { value: 'icart', text: '🎨 And the best-looking art goes to....' },
  // { value: 'chils', text: '🥶 ________ gave me chills:' },
  // { value: 'lov', text: '❤️ Love this!' },
];

constants.rouletteReactions = [{ value: 'lov', text: '❤️ Love this!' }];
constants.giftComplimentBomb = [
  {
    value: 'gift',
    text: `Share one with a friend, <strong>because you can't use these for yourself.</strong>`,
    count: '',
    svgIcon: '/wrapped-cbomb.svg',
    title: 'GIFT COMPLIMENT BOMB',
  },
  {
    value: 'consumable',
    text: `A normal bomb <strong>you can use yourself!</strong> You can gift it too if you don't plan to use it.`,
    count: '',
    svgIcon: '/compliment-bomb.svg',
    title: 'COMPLIMENT BOMB',
  },
];
constants.defaultPageSize = 10;

constants.spotifyLinkRegex = /^https:\/\/\w+.spotify.com/i;
constants.youtubeLinkRegex =
  /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;

constants.allowedSafeHtmlTags = [
  'address',
  'article',
  'aside',
  'center',
  'footer',
  'header',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hgroup',
  'main',
  'nav',
  'section',
  'blockquote',
  'dd',
  'div',
  'dl',
  'dt',
  'figcaption',
  'figure',
  'hr',
  'li',
  'main',
  'ol',
  'p',
  'pre',
  'ul',
  'a',
  'abbr',
  'b',
  'bdi',
  'bdo',
  'br',
  'cite',
  'code',
  'data',
  'dfn',
  'em',
  'i',
  'kbd',
  'mark',
  'q',
  'rb',
  'rp',
  'rt',
  'rtc',
  'ruby',
  'samp',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'time',
  'u',
  'var',
  'wbr',
  'caption',
  'col',
  'colgroup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'acronym',
  'img',
  'marquee',
  'del',
  'ins',
];

constants.rolechatReqStatuses = {
  accepted: 'accepted',
  denied: 'denied',
  pending: 'pending',
};

constants.search = {
  characters: 'characters',
  worlds: 'world',
  blabs: 'blabs',
  users: 'users',
  marketplace: 'marketplace',
};

constants.feedReactedTypes = {
  USER: 'user',
  CHARACTER: 'character',
  WORLD: 'world',
  SOCIALSPACE: 'socialspace',
  POST: 'post',
  BLAB: 'blab',
  STORY: 'story',
};

constants.bookmarkableTypes = ['blab', 'character', 'image', 'world'];

constants.mpListingCategories = [
  { name: 'Art Commission', value: 'art_commission' },
  { name: 'Writing Commission', value: 'writing_commission' },
  { name: 'Character Sale', value: 'character_sale' },
  { name: 'Art Base', value: 'art_base' },
  { name: 'Merch', value: 'merch' },
  { name: 'Reference Sheet', value: 'reference_sheet' },
  { name: 'Emotes', value: 'emotes' },
  { name: 'Animation', value: 'animation' },
  { name: 'Your Character Here (YCH)', value: 'ych' },
  { name: 'Other', value: 'other' },
];

export const imageCategories = [
  {
    name: 'Character Images',
    value: 'character_gallery',
    color: '#ff41b3',
  },
  {
    name: 'Story World Images',
    value: 'world_gallery',
    color: '#b9ed64',
  },
  {
    name: 'Moodboard',
    value: 'character_moodboard',
    color: '#a234e5',
  },
  {
    name: 'Outfits',
    value: 'character_outfit',
    color: '#5562cf',
  },
  {
    name: 'Inventory',
    value: 'character_inventory',
    color: '#60b7e8',
  },
  {
    name: 'Favorites',
    value: 'character_favorites',
    color: '#32dede',
  },
  {
    name: 'Character LifeStages',
    value: 'character_lifestages',
    color: '#FFDE67',
  },
  {
    name: 'Social Space Images',
    value: 'social_space_gallery',
    color: '#7ad7ff',
  },
];

constants.raffleCategories = [
  { name: 'Custom Art', type: 'Art', color: '#00e6e6', value: 'custom_art' },
  { name: 'Custom Writing', type: '', color: '#ff64a5', value: 'custom_writing' },
  { name: 'Character Giveaway', type: 'Character', color: '#ffde67', value: 'character_giveaway' },
  { name: 'Art Base', type: 'Art', color: '#00e6e6', value: 'art_base' },
  { name: 'Merch', type: '', color: '#e287f7', value: 'merch' },
  { name: 'Reference Sheet', type: '', color: '#ff64a5', value: 'ref_sheet' },
  { name: 'Emotes', type: '', color: '#ff64a5', value: 'emotes' },
  { name: 'Animation', type: 'Art', color: '#00e6e6', value: 'animation' },
  { name: 'Your Character Here (YCH)', type: 'Character', color: '#ffde67', value: 'ych' },
  { name: 'Other', type: 'other', color: '#e287f7', value: 'other' },
];

constants.quizCategories = [
  { name: 'OC', color: '#ffde67', value: 'oc' },
  { name: 'Worldbuilding', color: '#8ae23a', value: 'wb' },
];
constants.fanworkReactions = [
  'OMG!',
  'This is so good. Like what the heck?',
  'I’m obsessed!!!!',
  'I love it!!',
  'My jaw is on the ground!!!!',
  'The art is amazing!!!',
  'This is the BEST thing ever!!',
  'This is freakin awesome!!',
  'This made my day!',
  'I vibe with this!!!',
  'I’m speechless!!',
  'Thank you so much!',
];

constants.giveawayReactions = [
  'You are awesome! Thanks for hosting this!',
  'You are THE BOMB!!!!',
  'I REALLY APPRECIATE YOU',
  `You're EPIC!`,
  'Thank you so much!',
  'you are a STAR!!!!!',
  `WOW, YOU'RE INCREDIBLE!!!!!`,
  'MUCH LOVE FOR THIS RAFFLE!!!!',
  `seriously, you're a hero for doing this!`,
  `can't believe how AWESOME you are!!!!`,
  'this is the BEST THING EVER!!!!',
  'YOU ROCK MY WORLD!!!!',
  'absolutely blown away by your generosity!!!!',
  'LEGENDARY MOVE, THANK YOU!!!!',
  'your effort is out of this world!!!!',
  `you've made everyone so HAPPY!!!!`,
  'kudos to you for such a FANTASTIC raffle!!!!',
  'your kindness is UNMATCHED!!!!',
  'BIG THANKS for the excitement!!!!',
  `you've sparked so much JOY!!!!`,
  'so grateful for your AMAZING work!!!!',
  `you've outdone yourself this time!!!!`,
  'this raffle is a game-changer!!!!',
  'hats off to your EPIC organizing skills!!!!',
  'beyond thankful for this experience!!!!',
  'your creativity in hosting is INSPIRING!!!!',
  'HUGE SHOUTOUT for making this happen!!!!',
  `you've brought a smile to so many faces!!!!`,
  'MANY THANKS for the thrill!!!!',
  `you're the reason for so much happiness today!!!!`,
  'you have a HEART OF GOLD!!!!',
  'this raffle is the HIGHLIGHT of the day!!!!',
  `can't overstate how grateful everyone is!!!!`,
  `you've set a new bar for awesomeness!!!!`,
  'your efforts are deeply appreciated!!!!',
  `everyone's in awe of your fantastic work!!!!`,
  'THANK YOU for making magic happen!!!!',
  'you are a true raffle MASTER!!!!',
  'your dedication is BEYOND AMAZING!!!!',
  `you've created something truly SPECIAL!!!!`,
  'BIG APPLAUSE for your hard work!!!!',
  `incredible job, you're a SUPERSTAR!!!!`,
  'your generosity knows no bounds!!!!',
  'thanks a million for the excitement!!!!',
  `you're a true blessing to this community!!!!`,
  'immensely grateful for your kindness!!!!',
  `you've really outshone yourself!!!!`,
  'A+ for this AMAZING raffle!!!!',
  'a round of applause for your efforts!!!!',
  `can't thank you enough for this joy!!!!`,
  'your work is nothing short of SPECTACULAR!!!!',
  'so thankful for your AMAZING spirit!!!!',
  `you're the MVP of raffle hosting!!!!`,
  'RAFFLE HERO ALERT!!!!',
  'a million thanks for your epic generosity!!!!',
  'UNBELIEVABLY COOL of you to do this!!!!',
  'OVER THE MOON with gratitude!!!!',
  `you've made a HUGE IMPACT!!!!`,
  'KNOCKED IT OUT OF THE PARK!!!!',
  'your efforts are worth their weight in gold!!!!',
  'THRILLED by your amazing raffle!!!!',
  `you're the epitome of AWESOMENESS!!!!`,
  'OVERJOYED by your kindness!!!!',
  'THANK YOU A THOUSAND TIMES!!!!',
  'HIGH FIVE for being so amazing!!!!',
  `you're a true raffle WIZARD!!!!`,
  'GIGANTIC THANKS for your hard work!!!!',
  `you're a beacon of positivity!!!!`,
  'BLOWN AWAY by your generosity!!!!',
  'your spirit is INFECTIOUS!!!!',
  'ENORMOUS GRATITUDE for everything!!!!',
  'your energy is CONTAGIOUS!!!!',
  `you've made this event SPARKLE!!!!`,
  'BIGGEST THANKS EVER!!!!',
  'your commitment is INSPIRATIONAL!!!!',
  'JAW-DROPPINGLY GOOD job!!!!',
  `you're the heart of this community!!!!`,
  'THRILLED to bits with your work!!!!',
  'BEYOND GRATEFUL for your efforts!!!!',
  'you light up the room with your kindness!!!!',
  'RAFFLE CHAMPION right here!!!!',
  'your dedication is ASTOUNDING!!!!',
  'EXTRAORDINARY efforts, THANK YOU!!!!',
  'MIND-BLOWINGLY AWESOME job!!!!',
  `you've brought so much EXCITEMENT!!!!`,
  'in awe of your SPECTACULAR work!!!!',
  'your awesomeness knows no bounds!!!!',
  'so LUCKY to have you in our community!!!!',
  'ELECTRIC vibes thanks to you!!!!',
  'nothing but HUGE APPRECIATION!!!!',
  `you've made a SPLASH with this raffle!!!!`,
  'eternally grateful for your MAGIC!!!!',
  `you've turned this into an EPIC event!!!!`,
  'your effort shines BRIGHTER THAN STARS!!!!',
  `you've sparked a WAVE OF JOY!!!!`,
  'BLOWN AWAY by your creativity!!!!',
  'STANDING OVATION for your work!!!!',
  'a HEARTFELT THANK YOU!!!!',
  `SO MUCH LOVE for what you've done!!!!`,
  `you've created UNFORGETTABLE moments!!!!`,
  'ENDLESS THANKS for your dedication!!!!',
  'Thank you for your amazing effort!',
  'Really appreciate your work on this raffle!',
  `You've done a great job, thank you!`,
  'So grateful for your dedication!',
  'Thanks for making this possible!',
  'Your hard work is truly appreciated!',
  'Thanks for adding so much fun!',
  'Really thankful for this raffle!',
  'Your time and effort mean a lot!',
  'Thank you for your kindness!',
  'Appreciating you doing this!!!',
  'Thanks for creating such a great raffle!',
  'Grateful for your fantastic work!',
  `You've really made a difference!`,
  'Thanks for your incredible contribution!',
  'Your generosity is so appreciated!',
  'Thank you for making this happen!',
  'Really value your hard work!',
  'Thanks for putting this together!',
  'Appreciate all your efforts!',
  'Thank you for your creativity!',
  'Your work is outstanding!!',
  'Grateful for your thoughtful planning of this raffle!',
  'Your initiative is much appreciated!',
  'Thank you for being so generous!',
  'Appreciate your time and energy!',
  'Thank you for your enthusiasm!',
  'Thanks for making this <3',
  'Thank you for your hard work!',
  'Appreciating your commitment!',
  'Thanks for setting this up!',
  `You've done a wonderful job!`,
  'Thank you for everything!',
  'Really thankful!!',
  'Thanks for making this!',
  'Appreciate you making this raffle!',
  'Thanks for making this special!',
  'Thank you for hosting such a cool raffle!',
  'Your generosity is incredible, thank you!',
  'Thanks for spreading joy with this raffle!',
  'Really appreciate you doing this!',
  `You're awesome for hosting this!`,
  'Your kindness in this raffle is amazing!',
  'Thank you for your wonderful gesture!',
  'Grateful for your spirit of giving!',
  'Thanks for adding excitement with your raffle!',
  `You're making a difference, thank you!`,
  'Appreciate your selflessness in hosting!',
  'Your gift of joy is appreciated, thank you!',
  'Thank you for your big-heartedness!',
  'Thanks for sharing through this raffle!',
  `You're a gem for doing this!`,
  'Grateful for your generosity!',
  'Thank you for brightening our day!',
  'Thanks for being so giving!',
  'Your thoughtfulness shines, thank you!',
  `Appreciate the fun you're sharing!`,
  'Thank you for your kindness!',
  'Thanks for the fantastic raffle!',
  `You're a star for hosting this!`,
  'Your giving nature is inspiring, thank you!',
  `Thanks for the smiles you're creating!`,
  'Grateful for your amazing gift!',
  'Thank you for being so generous!',
  'Thanks for the wonderful surprise!',
  `You're amazing for doing this!`,
  'Your generosity warms hearts, thank you!',
  'Thank you for your graciousness!',
  'Thanks for the awesome raffle!',
  `You're making an impact, thank you!`,
  'Appreciate your giving spirit!',
  'Thank you for the excitement!',
  'Thanks for your generous spirit!',
  `You're a blessing, thank you!`,
  'Grateful for your wonderful raffle!',
  'Thank you for being amazing!',
  'Thanks for the thrill of the raffle!',
  `You're fantastic for hosting this!`,
  'Your generosity is heartwarming, thank you!',
  `Thanks for the joy you're spreading!`,
  `You're an inspiration, thank you!`,
  'Appreciate your wonderful raffle!',
  'Thank you for the fun and generosity!',
  'Thanks for making this possible!',
  `You're a hero for hosting this!`,
  'Your kindness is unmatched, thank you!',
  'Thanks for being so incredible!',
  `You're amazing for this raffle, thank you!!!`,
  'Wow, your generosity is off the charts!!!',
  'Incredible raffle host alert, thank you!!!',
  'Thanks a million for the fun!!!',
  'You rock for doing this, big thanks!!!',
  `Such a cool raffle, you're awesome!!!`,
  'Thanks for being so fabulous!!!',
  'Your raffle is the highlight of our day, thank you!!!',
  `You're the best, thanks for hosting!!!`,
  'Unbelievably generous, thank you so much!!!',
  'Thank you for your amazing spirit!!!',
  'Thanks for making everything brighter!!!',
  `You're a superstar for hosting this!!!`,
  'Your kindness is mind-blowing, thank you!!!',
  `Thanks for being the reason we're smiling!!!`,
  'Absolutely love your raffle, thank you!!!',
  `You're a legend for doing this, thanks!!!`,
  'Such a fantastic raffle, big thanks to you!!!',
  'Your giving heart is so appreciated, thank you!!!',
  'Thanks for the incredible excitement!!!',
  `You're an angel for hosting this, thank you!!!`,
  'Wow, what a wonderful raffle, thanks!!!',
  `You've made my day, big thanks!!!`,
  'Your generosity is awe-inspiring, thank you!!!',
  'Thank you for being so extraordinary!!!',
  'Thanks for spreading so much joy!!!',
  `You're a hero in our eyes, thank you!!!`,
  `Such a thrilling raffle, you're amazing!!!`,
  'Thank you for your boundless generosity!!!',
  'Thanks for being the best raffle host ever!!!',
  `You're a gift to us all, thank you!!!`,
  'Thanks for the awesome vibes!!!',
  `You're a ray of sunshine with this raffle, thank you!!!`,
  'Your awesomeness knows no bounds, thanks!!!',
  'Thank you for being such a kind soul!!!',
  'Thanks for lighting up our world!!!',
  `You're the MVP of giving, thank you!!!`,
  'Such a cool gesture, big thanks to you!!!',
  'Your big heart shines through, thank you!!!',
  'Thanks for the fantastic raffle fun!!!',
  `You're a treasure for doing this, thank you!!!`,
  'Thanks for being so wonderfully generous!!!',
  `You're incredible, and we're grateful, thank you!!!`,
  'Thank you for the joy you bring!!!',
  'Thanks for being a raffle superstar!!!',
  `You're a blessing, thank you so much!!!`,
  'Thanks for the incredible thrill!!!',
  `You're the best, thank you for everything!!!`,
  'Thank you for your amazing generosity!!!',
  'Thanks for being so awesome and generous!!!',
  `You're a gem for hosting this raffle, thanks a ton!!!`,
  'Big cheers for your generous spirit, thank you!!!',
  `You're a raffle wizard, massive thanks!!!`,
  'Thanks for sprinkling joy with your raffle!',
  'Huge shoutout for your kindness, thank you!!!',
  'Your raffle is a blast, thanks for the fun!',
  `You've outdone yourself, thank you so much!!!`,
  `Thanks for the excitement, you're incredible!`,
  'Your generosity is legendary, big thank you!!!',
  'Thank you for being our raffle hero!',
  'Thanks for adding sparkle to our day!',
  `You're a superstar, thank you for this raffle!`,
  'Your big heart is showing, thanks a bunch!',
  'Thanks for the amazing raffle, you rock!',
  `You're the reason for so many smiles, thank you!`,
  'Thank you for your fantastic generosity!',
  'Thanks for making this raffle amazing!',
  'Your kindness is off the charts, thank you!',
  'Thanks for being so awesome and generous!',
  `You're a raffle mastermind, thank you!`,
  'Thank you for spreading happiness!',
  `Thanks for the thrilling raffle, you're amazing!`,
  'Your selflessness is inspiring, big thanks!',
  'Thank you for your wonderful giving spirit!',
  'Thanks for being the highlight of our day!',
  `You're a true star, thank you for the raffle!`,
  'Thanks for the fantastic experience!',
  'Your generosity is heartwarming, thank you!',
  'Thank you for the exciting raffle!',
  'Thanks for being a shining example of kindness!',
  `You're a beacon of joy, thank you so much!`,
  'Thanks for making magic happen with this raffle!',
  'Your thoughtfulness is a treasure, thank you!',
  `Thanks for being a raffle rockstar!`,
  `You're the best, thank you for the fun!`,
  'Thanks for your incredible act of giving!',
  `You're a raffle champion, thank you!`,
  'Thank you for your extraordinary kindness!',
  'Thanks for being such a great giver!',
  `You're a wonder, thank you for the raffle!`,
  'Thanks for bringing so much joy!',
  `You're a blessing, thank you for your generosity!`,
  'Thank you for the unforgettable raffle!',
  'Thanks for being so wonderfully giving!',
  `You're a joy-bringer, thank you!`,
  'Thank you for your awesome raffle!',
  'Thanks for being an amazing host!',
  `You're a dream, thank you for the raffle!`,
  `Thanks for being the reason we're all happy!`,
  `You're fantastic, thank you so much!`,
  'THANK YOU FOR AN AMAZING RAFFLE!',
  'YOUR GENEROSITY IS UNMATCHED, THANKS!',
  'WOW, WHAT A FANTASTIC RAFFLE HOST!',
  'THANKS FOR SPREADING SO MUCH JOY!',
  `YOU'RE THE BEST, THANK YOU!`,
  'INCREDIBLE RAFFLE, HUGE THANKS!',
  'YOUR KINDNESS IS INSPIRING, THANK YOU!',
  'THANKS FOR MAKING EVERYTHING SO FUN!',
  `YOU'RE A STAR, THANK YOU FOR HOSTING!`,
  'BIG CHEERS FOR YOUR GENEROSITY!',
  'THANK YOU FOR THE THRILLING RAFFLE!',
  `YOU'VE MADE OUR DAY, THANKS A LOT!`,
  'YOUR GENEROUS SPIRIT IS APPRECIATED!',
  'THANKS FOR THE AWESOME RAFFLE!',
  `YOU'RE AMAZING, THANK YOU!`,
  'THANK YOU FOR YOUR WONDERFUL GESTURE!',
  'RAFFLE HERO ALERT, THANKS SO MUCH!',
  'YOUR KINDNESS IS A BEACON OF HOPE!',
  'THANKS FOR BRINGING HAPPINESS!',
  `YOU'RE A GEM, THANK YOU!`,
  'BIG THANKS FOR YOUR AWESOME RAFFLE!',
  'THANK YOU FOR YOUR INCREDIBLE SPIRIT!',
  'YOU MAKE THE WORLD BRIGHTER, THANKS!',
  'YOUR GENEROSITY IS HEARTWARMING!',
  'THANKS FOR BEING SUCH A GREAT HOST!',
  `YOU'RE AN INSPIRATION, THANK YOU!`,
  'THANK YOU FOR THE EXCITING RAFFLE!',
  'YOUR KINDNESS IS BEYOND WORDS, THANKS!',
  'THANKS FOR THE MEMORABLE RAFFLE!',
  `YOU'RE A TRUE GIVER, THANK YOU!`,
  'YOUR EFFORTS ARE DEEPLY APPRECIATED!',
  'THANK YOU FOR BEING SO GENEROUS!',
  'BIG APPLAUSE FOR YOUR KINDNESS!',
  'THANKS FOR YOUR AMAZING CONTRIBUTION!',
  `YOU'RE A RAY OF SUNSHINE, THANK YOU!`,
  'THANK YOU FOR YOUR UNMATCHED GENEROSITY!',
  `YOU'RE THE REAL MVP, THANKS!`,
  'THANKS FOR MAKING THIS SPECIAL!',
  'YOUR GENEROSITY KNOWS NO BOUNDS!',
  'THANK YOU FOR YOUR BOUNDLESS KINDNESS!',
  'BIGGEST THANKS FOR YOUR GENEROSITY!',
  `YOU'RE A HERO, THANK YOU!`,
  'THANKS FOR BEING THE BEST!',
  'YOUR KINDNESS IS TREASURED, THANK YOU!',
  'THANK YOU FOR SHARING JOY!',
  'BIG LOVE FOR YOUR GENEROUS HEART!',
  `YOU'RE OUTSTANDING, THANKS A MILLION!`,
  'THANK YOU FOR BEING A BLESSING!',
  'YOUR SPIRIT OF GIVING IS AWESOME, THANKS!',
  'Your raffle is a special treat, thank you.',
  'Thanks for the heartwarming raffle experience.',
  'Your raffle adds sparkle to our day.',
  'Thank you for your gracious raffle.',
  'Grateful for the fun and joy of your raffle.',
  'Your raffle brightens our day, thank you.',
  'Thank you for the delightful raffle magic.',
];

constants.chatroomPrivacy = ['public', 'private'];
constants.customizeFonts = [
  {
    name: `Roboto`,
    key: 'Roboto',
  },
  {
    name: 'Aleo',
    key: 'Aleo',
  },
  {
    name: `Knewave`,
    key: 'Knewave',
  },
  {
    name: `Anton`,
    key: 'Anton',
  },
  {
    name: `Cabin`,
    key: 'Cabin',
  },
  {
    name: 'Lato',
    key: 'Lato',
  },
  {
    name: 'Open Sans',
    key: 'Open-Sans',
  },
  {
    name: 'Ranchers',
    key: 'Ranchers',
  },
  {
    name: 'Frijole',
    key: 'Frijole',
  },
  {
    name: 'Teko',
    key: 'Teko',
  },
  {
    name: 'Comic Neue',
    key: 'Comic-Neue',
  },
  {
    name: 'Special Elite',
    key: 'Special-Elite',
  },
  {
    name: 'Courier Prime',
    key: 'Courier-Prime',
  },
  {
    name: 'Spectral',
    key: 'Spectral',
  },
  {
    name: `Vina Sans`,
    key: `Vina-Sans`,
  },
  {
    name: 'Lobster',
    key: 'Lobster',
  },
  {
    name: 'Bebas Neue',
    key: 'Bebas-Neue',
  },
  {
    name: 'Cedarville Cursive',
    key: 'Cedarville-Cursive',
  },
  {
    name: 'Nanum Gothic',
    key: 'Nanum-Gothic',
  },
  {
    name: 'Pacifico',
    key: 'Pacifico',
  },
  {
    name: 'Dancing Script',
    key: 'Dancing-Script',
  },
  {
    name: `Sevillana`,
    key: 'Sevillana',
  },
  {
    name: `Oswald`,
    key: 'Oswald',
  },
  {
    name: `Nunito`,
    key: 'Nunito',
  },
  {
    name: 'Kalnia Glaze',
    key: 'Kalnia-Glaze',
  },
  {
    name: 'Edu Australia VIC WA NT Hand',
    key: 'Edu-Australia-VIC-WA-NT-Hand',
  },
];

constants.blabCategories = [
  { name: 'Art', value: 'art', color: '#00e6e6' },
  { name: 'Art WIP', value: 'art_wip', color: '#00e6e6' },
  { name: 'Story', value: 'story', color: '#ff64a5' },
  { name: 'Meme', value: 'meme', color: '#00e6e6' },
  { name: 'Personal Update', value: 'personal_update', color: '#00e6e6' },
  { name: 'Roleplay', value: 'roleplay', color: '#ffde67' },
  { name: 'Journal', value: 'journal', color: '#ff64a5' },
  { name: 'Social Post', value: 'social_post', color: '#ff64a5' },
  { name: 'Question And Answer', value: 'question_and_answer', color: '#ff64a5' },
  { name: 'Comic', value: 'comic', color: '#e287f7' },
];

constants.currencyCategories = [
  { name: 'USD', value: 'usd' },
  { name: 'Gems', value: 'gem' },
];

constants.worldChatRoomPrivacy = {
  private: 'private',
  public: 'public',
};

constants.worldMemberRoles = {
  leader: 'leader',
  member: 'member',
};

constants.inventories = {
  consumable: 'consumable',
  gift: 'gift',
};

constants.charComplimentbombType = {
  bombedByMe: 'bombed_by_me',
  bombedByOthers: 'bombed_by_others',
};

constants.subscriptionLevels = {
  free: 0,
  plus: 1,
  ultra: 2,
};

constants.tiers = {
  lowTier: 'Tier 1',
  midTier: 'Tier 2',
  highTier: 'Tier 3',
};

constants.subscriptionLevelsTextMap = {
  0: 'free',
  1: 'plus',
  2: 'ultra',
};

constants.freebieStatus = {
  open: 'opened',
  close: 'closed',
};

constants.worldMemberRoleTitles = {
  leader: 'World Leader',
  member: 'Member',
};

constants.followFeedActions = {
  CREATED_CHARACTER: 'created_character',
  CREATED_MARKETPLACELISTING: 'created_marketplacelisting',
  CREATED_WORLD: 'created_world',
  CREATED_SPACE: 'created_social_space_world',
  CREATED_STORY_WORLD: 'created_story_world',
  CREATED_BLAB: 'created_blab',
  SUNSHINED_REPLY: 'sunshined_reply',
  UPDATED_CHAR_TEXT: 'updated_char_text',
  UPDATED_CHAR_FIELDS: 'updated_char_fields',
  UPDATED_CHAR_VISUALS: 'updated_char_visuals',
  UPDATED_CHAR_PROFILE_IMG: 'updated_char_profile_img',
  UPDATED_WORLD_TEXT: 'updated_world_text',
  UPDATED_SOCIAL_TEXT: 'updated_social_world_text',
  UPDATED_STORY_WORLD_TEXT: 'updated_story_world_text',
  UPDATED_WORLD_FIELDS: 'updated_world_fields',
  UPDATED_WORLD_VISUALS: 'updated_world_visuals',
  UPDATED_WORLD_PROFILE_IMG: 'updated_world_profile_img',
  FANWORK_SUBMITTED: 'fanart_submitted',
  FANART_FEATURED: 'fanart_featured',
};

export const supportEmail = 'info@characterhub.com';

export default constants;

export const socials = [
  {
    base: 'https://facebook.com/',
    icon: 'ti-facebook',
    value: 'facebook',
  },
  {
    base: 'https://twitter.com/',
    icon: 'ti-twitter-alt',
    value: 'twitter',
  },
  {
    base: 'https://instagram.com/',
    icon: 'ti-instagram',
    value: 'instagram',
  },
  {
    base: 'https://tumblr.com/',
    icon: 'ti-tumblr-alt',
    value: 'tumblr',
  },
  {
    base: 'https://tiktok.com/',
    icon: '/icons/tiktok.svg',
    value: 'tiktok',
  },
  {
    base: 'https://deviantart.com/',
    icon: '/icons/deviantart.svg',
    value: 'deviantart',
  },
];

export const seoFandoms = [
  'My Hero Academia',
  'Harry Potter',
  'Arcane',
  'Dragon Ball Z',
  'Sonic',
  'Transformers',
  'Mario',
];

export const seoSpecies = ['Dog', 'Furry', 'Cat', 'Vampire', 'Mummy'];

export const unreorderableEditorKeys = [
  'aboutConfig',
  'characterCollections',
  'cover',
  'cropProfilePicture',
  'name',
  'order',
  'privacy',
  'profilePicture',
  'quote',
  'statsConfig',
  'tags',
  'visuals',
  'warningMessage',
  'relationships',
];
export const defaultOrderCharacterKeys = ['description', 'about', 'colors', 'stats', 'spotifyLink', 'collections']

export const unreorderableCreatorKeys = [
  'name',
  'quote',
  'author',
  'allow_join',
  'cover_image',
  'created ',
  'order',
  'tags',
  'visuals',
  'privacy',
  'membership_details',
  'user',
  'created',
  'id',
  'slug',
  'profile_image',
  'cropped_profile_img',
  'is_nsfw',
  'max_members',
  'reaction_counts',
  'user_reaction',
  'hidden_tags',
  'visual_collections_p1',
  'type',
  'collections',
  'world_collections',
  'rooms',
];
export const unreorderableCreateWorldKeys = [
  'name',
  'extra',
  'quote',
  'author',
  'allow_join',
  'cover_image',
  'created ',
  'order',
  'tags',
  'visuals',
  'privacy',
  'membership_details',
  'user',
  'created',
  'id',
  'slug',
  'profile_image',
  'cropped_profile_img',
  'is_nsfw',
  'max_members',
  'reaction_counts',
  'user_reaction',
  'hidden_tags',
  'visual_collections_p1',
  'type',
  'collections',
  'world_collections',
  'included_locations',
];
export const unreorderableCreatesocialspaceKeys = [
  'name',
  'quote',
  'world_collections',
  'extra',
  'allow_join',
  'cover_image',
  'created ',
  'included_locations',
  'spotify_link',
  'subworlds',
  'tags',
  'visuals',
  'privacy',
  'membership_details',
  'user',
  'created',
  'id',
  'slug',
  'profile_image',
  'cropped_profile_img',
  'is_nsfw',
  'max_members',
  'reaction_counts',
  'user_reaction',
  'hidden_tags',
  'visual_collections_p1',
  'type',
  'warningMessage',
  'collections',
  'triggerwarning',
];
export const unreorderablesocialspaceKeys = [
  'name',
  'quote',
  'world_collections',
  'allow_join',
  'cover_image',
  'created ',
  'included_locations',
  'spotify_link',
  'subworlds',
  'tags',
  'visuals',
  'privacy',
  'membership_details',
  'user',
  'created',
  'id',
  'slug',
  'profile_image',
  'cropped_profile_img',
  'is_nsfw',
  'max_members',
  'reaction_counts',
  'user_reaction',
  'hidden_tags',
  'visual_collections_p1',
  'type',
  'warningMessage',
  'collections',
  'triggerwarning',
];
export const storyWorldKeys = [
  'tags',
  'triggerwarning',
  'id',
  'slug',
  'characters',
  'created',
  'included_locations',
  'visuals',
  'profile_image',
  'cropped_profile_img',
  'max_members',
  'reaction_counts',
  'user_reaction',
  'hidden_tags',
  'name',
  'description',
  'cover_image',
  'spotify_link',
  'allow_join',
  'user',
  'membership_details',
  'is_nsfw',
  'subworlds',
  'rooms',
  'visual_collections',
  'visual_collections_p1',
  'tags',
  'type',
  'extra',
  'quote',
  'colors',
  'about',
  'featuredin',
  'privacy',
  'order',
  'world_collections',
  'character_worlds',
  'character_collections',
  'visual_collections_page',
  'warningMessage',
  'collections',
];

export const characterAboutKeys = {
  '': ['custom'],
  basicAndIdentityInformation: [
    'fullName',
    'nickname',
    'alias',
    'realName',
    'pronouns',
    'birthPlace',
    'age',
    'nationality',
    'race',
    'ethnicity',
    'species',
    'age',
    'breed',
    'class',
    'title',
    'zodiac',
    'astrologicalSign',
    'bloodType',
    'gender',
    'sexuality',
    'likes',
    'dislikes',
    'birthday',
    'codeName',
    'previousNames',
    'adoptionStatus',
    'pastLifeIdentity',
    'secretIdentity',
    'signatureItemOrTotem',
    'secretOrHiddenTalent',
  ],
  physicalCharacteristics: [
    'height',
    'weight',
    'Hair (Color, Style)',
    'Eyes (Color)',
    'Skin (Color, Texture)',
    'build',
    'faceShape',
    'specificFeatures',
    'Marks (Scars, Tattoos)',
    'makeup',
    'piercings',
    'restingFacialExpression',
    'dominantSense',
    'physicalIdiosyncrasies',
    'gait',
    'fashionStyle',
    'fitnessLevel',
    'physicalDisabilities',
    'cosmeticEnhancements',
    'bodyModifications',
    'voiceTone',
    'naturalScent',
    'opticalPrescription',
    'uniqueDietaryNeeds',
    'elementAffinity',
    'seasonalAppearanceChanges',
    'biologicalAnomalies',
  ],
  personalityAndPsychologicalTraits: [
    'personalityType',
    'MBTI',
    'moralCompass',
    'temperament',
    'virtues',
    'flaws',
    'emotionalIntelligence',
    'charismaLevel',
    'communicationStyle',
    'humorStyle',
    'levelOfEmpathy',
    'intellectualInterests',
    'artisticTalents',
    'learningStyle',
    'linguisticAbilities',
    'leadershipStyle',
    'riskTolerance',
    'politicalViews',
    'coreBelief',
    'lifePhilosophy',
    'personalMantra',
    'greatestFear',
    'sourceOfJoy',
    'attitudeTowardDeath',
    'emotionalTrigger',
    'stressReliefMethod',
    'cognitiveBiasSusceptibility',
    'personalSuperstition',
  ],
  backgroundAndLifeExperiences: [
    'education',
    'occupation',
    'financialStatus',
    'residence',
    'family',
    'friends',
    'enemies',
    'significantOther',
    'soulMate',
    'lifeGoals',
    'biggestDream',
    'darkestSecret',
    'guilt',
    'shameElements',
    'internalConflicts',
    'History/Background',
    'formativeChildhoodEvent',
    'historicalEraOfGreatestInfluence',
    'mostCherishedMemory',
    'aDefiningChallengeOvercome',
    'inheritance',
    'previousOccupations',
    'firstLoveExperience',
    'historicalFigureIdentification',
    'personalLegendOrMyth',
    'archNemesis',
  ],
  'Interests, Hobbies, and Preferences': [
    'hobbies',
    'favoriteAnimal',
    'favoriteFood',
    'favoriteDrink',
    'favoriteColor',
    'foodPreferences',
    'leisureActivities',
    'fandom',
    'orientation',
    'favoriteGenre',
    'preferredFormOfEntertainment',
  ],
  'Health, Abilities, and Skills': [
    'physicalHealth',
    'mentalHealth',
    'allergies',
    'illnesses',
    'disorders',
    'strengths',
    'weaknesses',
    'specialPowers',
    'skills',
    'magic',
    'fightingStyle',
    'uniquePhysicalAbility',
    'mentalResilience',
    'creativeAptitude',
    'technologicalSavvy',
    'languageProficiency',
    'culinarySkills',
    'organizationalAbility',
    'musicalInstrumentProficiency',
    'athleticCompetence',
    'survivalSkills',
  ],
  socialAndCulturalAspects: [
    'relationship',
    'affiliation',
    'language',
    'accent',
    'speechPatterns',
    'socialStanding',
    'culturalBackground',
    'religion',
    'philosophy',
  ],
  lifestyleAndDailyLife: [
    'Alive/Dead',
    'Most Used Phrases / Words',
    'comfortItem',
    'weapons',
    'pets',
    'wealth',
    'outfits',
    'accessories',
    'voiceClaim',
    'threatLevel',
    'parentingStyle',
    'environmentalAwareness',
    'fears',
    'phobias',
  ],
  behavioralAndCognitiveTraits: [
    'motivation',
    'copingMechanisms',
    'senseOfTime',
    'selfPerception',
    'ambitions',
    'attachmentStyle',
    'locusOfControl',
    'toleranceForAmbiguity',
    'trustworthiness',
    'relationshipWithTechnology',
    'Conformity Vs. Nonconformity',
    'attentionSpan',
    'levelOfSuperstitiousBeliefs',
    'Adaptability / Flexibility',
  ],
  //Premium
  ambitionsAndAspirations: [
    'lifetimeAmbition',
    'shortTermGoals',
    'dreamAchievement',
    'careerAspiration',
    'educationalAspiration',
    'artisticEndeavor',
    'scientificTechnologicalGoal',
    'socialImpactAim',
    'environmentalObjective',
    'personalDevelopmentTarget',
  ],
  valuesAndBeliefs: [
    //Premium
    'coreValues',
    'ethicalStance',
    'lifeMotto',
    'viewsOnJustice',
    'beliefsAboutDestinyFate',
    'opinionOnWealthAndMaterialism',
    'stanceOnPowerAndAuthority',
    'culturalValues',
    'philosophicalBeliefs',
    'viewsOnHumanNature',
  ],
  leisureAndRecreation: [
    //Premium
    'favoritePastime',
    'preferredLeisureEnvironment',
    'goToSocialActivity',
    'solitaryRelaxationPreference',
    'preferredSportOrPhysicalActivity',
    'favoriteCreativeOutlet',
    'entertainmentChoices',
    'typicalWeekendActivity',
    'vacationStyle',
    'favoriteFestivalOrEvent',
  ],
  relationshipsAndSocialDynamics: [
    //Premium
    'relationshipWithFamily',
    'friendshipDynamics',
    'romanticInclinations',
    'mentoringStyle',
    'attitudeTowardsStrangers',
    'socialCircleDescription',
    'approachToNetworking',
    'roleInATeamOrGroup',
    'conflictManagementInRelationships',
    'attitudeTowardsPetsAndAnimals',
  ],
  psychologicalComplexities: [
    //Premium
    'deepSeatedFear',
    'personalInsecurities',
    'sourceOfPride',
    'guiltyPleasure',
    'internalMoralConflict',
    'psychologicalTrauma',
    'reactionToCrisis',
    'copingMechanismForLoss',
    'approachToChangeAndTransition',
    'methodForSelfReflection',
  ],
  creativeAndArtisticExpression: [
    //Premium
    'preferredArtisticMedium',
    'signatureArtStyle',
    'influentialArtistsOrWorks',
    'favoriteArtisticPeriod',
    'artisticInspirations',
    'creativeProcessDescription',
    'originalCreations',
    'artisticCollaborations',
    'artisticCommunityInvolvement',
    'artisticAchievements',
  ],
  digitalAndOnlinePresence: [
    //Premium
    'onlinePersona',
    'favoriteDigitalPlatforms',
    'onlineCommunityEngagement',
    'attitudeTowardsDigitalPrivacy',
    'onlineContentPreferences',
    'virtualWorldInvolvement',
    'digitalSkillset',
    'onlineInfluenceStyle',
    'digitalCreationInvolvement',
    'viewsOnDigitalEtiquette',
  ],
  mysticalAndEsotericTraits: [
    //Premium
    'psychicSensitivity',
    'astrologicalBirthChart',
    'spiritualityPractices',
    'ritualsAndTraditions',
    'connectionToTheSupernatural',
    'esotericKnowledge',
    'mysticSymbolsAffinity',
    'occultInterests',
    'supernaturalExperiences',
    'beliefInMythsAndLegends',
  ],
  adventurousAndTravelExperiences: [
    //Premium
    'mostMemorableAdventure',
    'dreamExplorationDestination',
    'survivalExpeditionExperience',
    'culturalExchangeExperiences',
    'attitudeTowardsAdventure',
    'travelCompanions',
    'wildernessExplorationSkills',
    'urbanExplorationInterests',
    'historicSiteVisits',
    'extremeSportParticipation',
  ],
  futureVisionsAndDreams: [
    //Premium
    'idealFutureWorld',
    'personalRoleInFutureSociety',
    'futureTechnologyFascination',
    'visionForHumanitysFuture',
    'dreamInventionsOrInnovations',
    'predictionsForFutureTrends',
    'hopeForFutureGenerations',
    'personalLegacyAspirations',
    'dreamOfFutureAchievements',
    'futureChallengesAnticipation',
  ],
};

export const characterAboutPremiumCategories = [
  'futureVisionsAndDreams',
  'adventurousAndTravelExperiences',
  'mysticalAndEsotericTraits',
  'digitalAndOnlinePresence',
  'creativeAndArtisticExpression',
  'psychologicalComplexities',
  'relationshipsAndSocialDynamics',
  'leisureAndRecreation',
  'valuesAndBeliefs',
  'ambitionsAndAspirations',
];

export const characterAboutPremiumKeys = [
  'codeName',
  'previousNames',
  'adoptionStatus',
  'pastLifeIdentity',
  'secretIdentity',
  'signatureItemOrTotem',
  'secretOrHiddenTalent',
  'restingFacialExpression',
  'dominantSense',
  'physicalIdiosyncrasies',
  'gait',
  'fashionStyle',
  'fitnessLevel',
  'physicalDisabilities',
  'cosmeticEnhancements',
  'bodyModifications',
  'voiceTone',
  'naturalScent',
  'opticalPrescription',
  'uniqueDietaryNeeds',
  'elementAffinity',
  'seasonalAppearanceChanges',
  'biologicalAnomalies',
  'coreBelief',
  'lifePhilosophy',
  'personalMantra',
  'greatestFear',
  'sourceOfJoy',
  'attitudeTowardDeath',
  'emotionalTrigger',
  'stressReliefMethod',
  'cognitiveBiasSusceptibility',
  'personalSuperstition',
  'formativeChildhoodEvent',
  'historicalEraOfGreatestInfluence',
  'mostCherishedMemory',
  'aDefiningChallengeOvercome',
  'inheritance',
  'previousOccupations',
  'firstLoveExperience',
  'historicalFigureIdentification',
  'personalLegendOrMyth',
  'archNemesis',
  'favoriteGenre',
  'preferredFormOfEntertainment',
  'uniquePhysicalAbility',
  'mentalResilience',
  'creativeAptitude',
  'technologicalSavvy',
  'languageProficiency',
  'culinarySkills',
  'organizationalAbility',
  'musicalInstrumentProficiency',
  'athleticCompetence',
  'survivalSkills',
];

export const statsKeys = {
  // DnD stat attributes
  '': ['custom'],
  general: [
    'strength',
    'dexterity',
    'constitution',
    'intelligence',
    'wisdom',
    'charisma',

    // Other stuff
    'stamina',
    'creativity',
    'speed',
    'power',
    'attack',
    'defense',
    'endurance',
    'agility',

    'patience',
    'empathy',
    'magic',
    'confidence',
    'luck',
    'kindness',
    'powerManipulation',
    'social',
    'confidence',
    'logical',
    'emotional',
    'humor',
    'hp',
    'generosity',
    'health',
    'temper',
    'aggression',
    'integrity',
    'courage',
    'maturity',
    'kindness',
    'cooking',
    'intellect',
    'bravery',
    'socialSkills',
    'perception',
    'extroversion',
    'introversion',
  ],
};

export const worldAboutKeys = {
  '': ['custom'],
  general: ['species', 'culture', 'magic', 'location', 'economy', 'factions', 'climate', 'religion'],
  countriesAndContinents: [
    'numberOfContinents',
    'countriesAndNationsInTheWorld',
    'relationshipsBetweenCountriesAndNations',
    'politicalDynamicsAndStability',
    'levelOfUrbanization',
    'aspectsOfPopularityOrPride',
    'societalStructureAndHierarchy',
    'climateAndHarshness',
    'naturalResources',
    'sourcesOfEnergy',
    'weapons',
    'physicalAnomalies',
    'faithAndReligion',
    'food',
    'holidaysAndCelebrations',
    'lawsAndAuthority',
    'systemsOfEducation',
    'clothesAndFashion',
    'popularJobsAndFormsOfWork',
    'popularFormsOfEntertainment',
    'availableTechnologiesAndAdvancement',
  ],
  culturalIdentityAndHeritage: [
    'motto',
    'funFacts',
    'genre',
    'traditionsAndRituals',
    'artAndAesthetics',
    'fashionAndApparel',
    'philosophicalSchools',
  ],
  governanceAndSocialOrder: [
    'ruler',
    'lawsAndAuthority',
    'justiceSystem',
    'publicServicesAndUtilities',
    'secretSocieties',
    'propagandaAndInformationControl',
    'intelligenceAndEspionage',
    'guildsAndAssociations',
    'colonialismAndExpansion',
    'socialMobility',
  ],
  technologicalAndMagicalAdvancements: [
    'availableTechnologiesAndAdvancement',
    'inventionsAndInnovations',
    'technologicalConstraints',
    'magic',
  ],
  economicAndResourceManagement: [
    'economy',
    'economyAndTrade',
    'resourceScarcity',
    'currencyAndValuation',
    'environmentalConservation',
  ],
  historicalAndTemporalContext: [
    'historyAndLore',
    'howTimePasses',
    'timekeepingAndCalendars',
    'explorationAndDiscovery',
    'adventureAndQuests',
  ],
  communicationAndInformation: ['languagesAndDialects', 'communicationNetworks', 'surveillanceAndPrivacy'],
  lifestyleAndSocialDynamics: [
    'popularJobsAndFormsOfWork',
    'popularFormsOfEntertainment',
    'recreationAndSports',
    'tourism',
    'touristAppeal',
    'festivalsAndCompetitions',
  ],
  geopoliticsAndInternationalRelations: [
    'diplomaticRelations',
    'relationshipsBetweenCountriesAndNations',
    'politicalDynamicsAndStability',
  ],
  scienceResearchAndExploration: ['scienceAndResearch', 'spaceAndAstronomy', 'conflictAndResolution'],
  urbanAndEnvironmentalPlanning: [
    'levelOfUrbanization',
    'transportationSystems',
    'traffic',
    'characteristicInWorldShapeOrForm',
  ],
  // Premium
  naturalAndGeographicalFeatures: [
    'predominantLandscape',
    'uniqueGeologicalFormations',
    'floraAndFaunaDiversity',
    'naturalWonders',
    'seasonalVariations',
    'weatherPatterns',
    'naturalDisastersFrequency',
    'biodiversityLevels',
    'rareNaturalPhenomena',
    'waterBodiesLakesRiversOceans',
  ],

  societalAndCulturalNorms: [
    'socialEtiquetteAndCustoms',
    'culturalTaboosAndSuperstitions',
    'commonCulturalMisconceptions',
    'publicCelebrationsAndMourningCustoms',
    'culturalSymbolsAndIcons',
    'storytellingAndFolkloreTraditions',
    'commonMythsAndLegends',
    'attitudesTowardOutsiders',
    'culturalDressAndAttire',
    'popularCulturalBeliefs',
  ],

  infrastructureAndArchitecture: [
    'architecturalStyles',
    'landmarkStructures',
    'housingAndLivingConditions',
    'publicSpacesAndParks',
    'majorConstructionMaterials',
    'infrastructureWonders',
    'cityPlanningAndLayout',
    'historicalSites',
    'undergroundStructures',
    'transportationInnovations',
  ],

  healthWellBeingAndSafety: [
    'healthCareSystem',
    'publicHealthConcerns',
    'safetyAndSecurityMeasures',
    'emergencyResponseSystems',
    'wellnessAndFitnessTrends',
    'lifeExpectancyAndMortalityRates',
    'commonDiseasesAndTreatments',
    'mentalHealthAwareness',
    'safetyRegulations',
    'disasterPreparedness',
  ],

  educationAndKnowledgeDissemination: [
    'educationalInstitutions',
    'popularFieldsOfStudy',
    'knowledgeTransmissionMethods',
    'librariesAndArchives',
    'scholarlyTraditions',
    'literacyRates',
    'educationalInnovations',
    'philosophicalMovements',
    'scientificCommunity',
    'intellectualPropertyRights',
  ],

  leisureRecreationAndTourism: [
    'touristAttractions',
    'leisureActivitiesAndHobbies',
    'recreationalFacilities',
    'adventureSports',
    'localDelicaciesAndCuisine',
    'nightlifeAndEntertainment',
    'shoppingAndMarkets',
    'spaAndWellnessCenters',
    'culturalFestivals',
    'touristInfrastructure',
  ],

  environmentalAndEcologicalAwareness: [
    'conservationEfforts',
    'sustainablePractices',
    'endangeredSpeciesProtection',
    'environmentalPolicies',
    'ecologicalAwarenessCampaigns',
    'renewableEnergySources',
    'wasteManagement',
    'urbanGreenSpaces',
    'wildlifeSanctuaries',
    'climateChangeInitiatives',
  ],

  interstellarAndPlanetaryAspects: [
    'planetarySystems',
    'alienSpeciesAndCultures',
    'spaceTravelMethods',
    'interstellarPolitics',
    'spaceColoniesAndHabitats',
    'extraterrestrialEcosystems',
    'cosmicPhenomena',
    'spaceExplorationMissions',
    'interplanetaryTrade',
    'alienTechnology',
  ],

  mythicalAndFantasyElements: [
    'mythicalCreatures',
    'enchantedLocations',
    'legendaryArtifacts',
    'ancientProphecies',
    'magicalSchoolsAndOrders',
    'sacredSitesAndTemples',
    'darkForestsAndForbiddenLands',
    'elementalRealms',
    'mysticalTraditions',
    'cursedObjectsAndPlaces',
  ],

  socialAndCommunityEngagement: [
    'communityOrganizations',
    'volunteerismAndCharity',
    'socialMovements',
    'grassrootsInitiatives',
    'communityCelebrations',
    'localHeroesAndPersonalities',
    'socialNormsAndPractices',
    'communityProjects',
    'neighborhoodDynamics',
    'civicParticipation',
  ],
};

export const worldAboutPremiumCategories = [
  'naturalAndGeographicalFeatures',
  'societalAndCulturalNorms',
  'socialAndCommunityEngagement',
  'mythicalAndFantasyElements',
  'interstellarAndPlanetaryAspects',
  'environmentalAndEcologicalAwareness',
  'leisureRecreationAndTourism',
  'educationAndKnowledgeDissemination',
  'healthWellBeingAndSafety',
  'infrastructureAndArchitecture',
];

export const worldAboutPremiumKeys = [
  'planetaryAlignmentAndEffects',
  'dominantWeatherPhenomenon',
  'mostPrizedCulturalAchievement',
  'predominantArchitecturalFeature',
  'uniqueEnvironmentalIssue',
  'commonSocialMisunderstanding',
  'publicTransportationSystem',
  'celebratedHistoricalFigure',
  'majorScientificDiscovery',
  'prevailingPublicMood',
  'unexploredTerritories',
  'renownedLandmarks',
  'crossCountryFestivals',
  'internationalRelationsQuirks',
  'famousLocalDelicacies',
  'uniqueWildlifeSpecies',
  'indigenousPractices',
  'legendaryBattleSites',
  'continentalMysteries',
  'regionalSuperstitions',
  'nationalMythosAndLore',
  'distinctiveCulturalPractices',
  'culturalIconsAndHeroes',
  'majorCulturalMovements',
  'influentialArtForms',
  'traditionalStorytellingMethods',
  'culturalAttitudesTowardsNature',
  'historicalCulturalShifts',
  'uniqueCulturalFestivals',
  'culturalFashionTrends',
  'unusualLawsAndRegulations',
  'peculiarGovernmentTraditions',
  'uniqueSocialHierarchies',
  'localGovernanceStyles',
  'publicOpinionLeaders',
  'notableRebelMovements',
  'influentialFamiliesOrClans',
  'majorPoliticalScandals',
  'urbanLegendsAboutRulers',
  'socialWelfareSystems',
  'pioneeringInventions',
  'rareMagicalAbilities',
  'technologicalAnomalies',
  'forbiddenMagicalPractices',
  'revolutionaryScientificTheories',
  'uniqueMagicalCreatures',
  'experimentalTechnologies',
  'arcaneLibraries',
  'technoMagicalFusion',
  'unexplainedTechnologicalPhenomena',
  'uniqueTradeGoods',
  'unconventionalEconomicModels',
  'majorEconomicCrises',
  'innovativeResourceSolutions',
  'blackMarketDynamics',
  'keyTradeRoutes',
  'resourceDependency',
  'economicSanctionsAndEmbargoes',
  'undergroundEconomies',
  'luxuryGoodsAndServices',
  'forgottenCivilizations',
  'majorErasAndAges',
  'historicalMysteries',
  'ancientRelicsAndArtifacts',
  'lostExpeditions',
  'timeAnomalies',
  'significantArchaeologicalDiscoveries',
  'historicalRevisionisms',
  'legendaryFounders',
  'epochalEvents',
  'uniqueCommunicationMethods',
  'languageEvolution',
  'majorNewsOutlets',
  'censorshipPractices',
  'famousWritersAndPoets',
  'informationTrade',
  'crypticLanguagesOrCodes',
  'popularTheoriesAndIdeas',
  'controversialPublications',
  'famousDebates',
  'unusualAlliances',
  'territorialDisputes',
  'diplomaticCustoms',
  'internationalSummitsAndConferences',
  'espionageTactics',
  'peaceTreaties',
  'proxyWars',
  'intergovernmentalOrganizations',
  'diplomaticCrises',
  'globalAgreements',
  'pioneeringResearchFields',
  'unexploredFrontiers',
  'renownedScientistsAndExplorers',
  'majorScientificTheories',
  'explorationTechnologies',
  'researchInstitutions',
  'ethicalDilemmasInResearch',
  'explorationMyths',
  'scientificBreakthroughs',
  'unchartedTerritories',
  'cityExpansionTechniques',
  'greenCitiesInitiatives',
  'urbanLegendsAndMyths',
  'majorUrbanReforms',
  'environmentalRestorationProjects',
  'uniqueCityFeatures',
  'urbanWildlife',
  'pollutionControlMeasures',
  'historicPreservationEfforts',
  'urbanRenewalProjects',
];

export const featuredInTypes = {
  '': ['custom'],
  general: ['book', 'story', 'comic', 'video', 'animatic', 'videoGame'],
};

export const messagesMap: { [key: string]: NotSignedInSection } = {
  'character-profile-new': {
    title: 'Showcase and Discover Characters!',
    subtitle: 'Get inspo, be part of a community, organize your character details!',
    cta: 'Join the Waitlist',
  },
  // Rest of pages that doesn't have message
  '*': {
    title: 'Showcase and Discover Characters!',
    subtitle: 'Get inspo, be part of a community, organize your character details!',
    cta: 'Join the Waitlist',
  },
};

export const excludedImageReactRoutes = [
  'create-worlds',
  'edit-world',
  'create',
  'char-draft-details',
  'edit-char-draft',
  'character-profile-draft-new',
  'character-editor',
  'draft-character-editor',
  'character-creator',
  'preset-creator',
  'preset-editor',
  'world-draft-details',
  'create-social-space',
  'create-story-world',
];

export const complimentBombsButton = [
  {
    title: 'Get Featured Here!',
    class: 'featured-char',
    isFeatured: true,
  },
  {
    title: 'Manage My Bombs',
    class: 'manage-bomb',
    isFeatured: false,
  },
];

export const complimentTargetBomb = [
  'Look at the Character Profile',
  'Write something nice',
  'Win your own chance to win a Compliment Bomb to get featured here!',
];

export const autosaveTimeout = 8000;
export const MAX_PROFILE_SELECTION = 6;
export const PRO_MAX_PROFILE_SELECTION = featureFlags.pro ? 9 : 6;
export const ULTRA_MAX_PROFILE_SELECTION = featureFlags.pro ? 12 : 6;
export const ERROR_UNLISTED_CHARACTER_IN_PUBLIC_CHAT = 'Non-public characters cannot be used in public chat rooms.';
export const ERROR_UNLISTED_CHARACTER_IN_COMMENTS = 'Non-public characters cannot be used to leave comments.';

export const qualitiesGroups = {
  '': {
    custom: {
      left: '',
      right: '',
    },
  },
  physicalAttributes: {
    physicalStrength: {
      left: 'Weak',
      right: 'Strong',
    },
    endurance: {
      left: 'Low',
      right: 'High',
    },
    agility: {
      left: 'Clumsy',
      right: 'Graceful',
    },
    athleticism: {
      left: 'Non-athletic',
      right: 'Olympian',
    },
    physicalSpeed: {
      left: 'Slow',
      right: 'Fast',
    },
    physicalFlexibility: {
      left: 'Stiff',
      right: 'Limber',
    },
    physicalEndurance: {
      left: 'Weak',
      right: 'Ironman',
    },
    handiness: {
      left: 'Clumsy',
      right: 'Skilled Craftsman',
    },
    danceAbility: {
      left: 'Two Left Feet',
      right: 'Dance Prodigy',
    },
    combatSkill: {
      left: 'Unskilled',
      right: 'Master Warrior',
    },
  },
  mentalAndIntellectualAttributes: {
    intelligence: {
      left: 'Low',
      right: 'High',
    },
    wisdom: {
      left: 'Naive',
      right: 'Sage',
    },
    memory: {
      left: 'Forgetful',
      right: 'Eidetic',
    },
    problemSolving: {
      left: 'Ineffective',
      right: 'Master Solver',
    },
    strategicThinking: {
      left: 'Improviser',
      right: 'Chessmaster',
    },
    creativity: {
      left: 'Unimaginative',
      right: 'Innovative',
    },
    scientificKnowledge: {
      left: 'Unscientific',
      right: 'Genius Scientist',
    },
    historicalKnowledge: {
      left: 'Uninformed',
      right: 'Historian',
    },
    scientificCuriosity: {
      left: 'Uninterested',
      right: 'Inquisitive',
    },
    mysticalKnowledge: {
      left: 'Skeptic',
      right: 'Mystic',
    },
  },
  emotionalAndInterpersonalSkills: {
    emotionalStability: {
      left: 'Volatile',
      right: 'Steady',
    },
    charisma: {
      left: 'Uncharismatic',
      right: 'Charismatic',
    },
    empathy: {
      left: 'Apathetic',
      right: 'Compassionate',
    },
    sociability: {
      left: 'Introverted',
      right: 'Extroverted',
    },
    assertiveness: {
      left: 'Passive',
      right: 'Assertive',
    },
    humility: {
      left: 'Arrogant',
      right: 'Humble',
    },
    diplomacy: {
      left: 'Blunt',
      right: 'Tactful',
    },
    negotiationSkill: {
      left: 'Ineffective',
      right: 'Diplomatic',
    },
    persuasion: {
      left: 'Unconvincing',
      right: 'Persuasive',
    },
    conflictResolution: {
      left: 'Aggressor',
      right: 'Peacemaker',
    },
  },
  personalityTraits: {
    optimism: {
      left: 'Pessimistic',
      right: 'Optimistic',
    },
    temperament: {
      left: 'Hot-headed',
      right: 'Even-tempered',
    },
    senseOfHumor: {
      left: 'Dry',
      right: 'Slapstick',
    },
    adventurousness: {
      left: 'Cautious',
      right: 'Daredevil',
    },
    spontaneity: {
      left: 'Predictable',
      right: 'Spontaneous',
    },
    selfControl: {
      left: 'Impulsive',
      right: 'Disciplined',
    },
    bravery: {
      left: 'Timid',
      right: 'Fearless',
    },
    riskTaking: {
      left: 'Risk-Averse',
      right: 'Risk-Taker',
    },
    Tidiness: {
      left: 'Messy',
      right: 'Neat Freak',
    },
    adventurousEating: {
      left: 'Picky',
      right: 'Omnivore',
    },
  },
  socialAndCommunicationSkills: {
    publicSpeaking: {
      left: 'Poor',
      right: 'Excellent',
    },
    leadership: {
      left: 'Follower',
      right: 'Natural Leader',
    },
    teachingAbility: {
      left: 'Ineffective',
      right: 'Inspirational',
    },
    languageFluency: {
      left: 'Monolingual',
      right: 'Polyglot',
    },
    writingAbility: {
      left: 'Inarticulate',
      right: 'Poetic',
    },
    actingSkill: {
      left: 'Wooden',
      right: 'Oscar-Worthy',
    },
    comedicTiming: {
      left: 'Unfunny',
      right: 'Hilarious',
    },
    honesty: {
      left: 'Deceptive',
      right: 'Truthful',
    },
    generosity: {
      left: 'Selfish',
      right: 'Altruistic',
    },
    loyalty: {
      left: 'Treacherous',
      right: 'Loyal',
    },
  },
  creativeAndArtisticSkills: {
    artisticSkill: {
      left: 'Inartistic',
      right: 'Master Artist',
    },
    cookingSkill: {
      left: "Can't Cook",
      right: 'Gourmet Chef',
    },
    fashionSense: {
      left: 'Unfashionable',
      right: 'Style Icon',
    },
    fashionDesign: {
      left: 'Lacks Style',
      right: 'Trendsetter',
    },
    drawingSkill: {
      left: 'Poor',
      right: 'Master Illustrator',
    },
    musicalTalent: {
      left: 'Tone Deaf',
      right: 'Virtuoso',
    },
    musicalComposition: {
      left: 'Tone Deaf',
      right: 'Mozart-Esque',
    },
    craftsmanship: {
      left: 'Amateur',
      right: 'Master Craftsman',
    },
    mechanicalSkill: {
      left: 'Inept',
      right: 'Ingenious',
    },
    gardeningSkill: {
      left: 'Brown Thumb',
      right: 'Green Thumb',
    },
  },
  practicalAndSurvivalSkills: {
    streetSmarts: {
      left: 'Naive',
      right: 'Streetwise',
    },
    survivalSkills: {
      left: 'Non-survivor',
      right: 'Survival Expert',
    },
    environmentalAwareness: {
      left: 'Ignorant',
      right: 'Eco-Warrior',
    },
    environmentalAdaptability: {
      left: 'Inflexible',
      right: 'Chameleon',
    },
    financialAcumen: {
      left: 'Spendthrift',
      right: 'Savvy Investor',
    },
    financialResponsibility: {
      left: 'Irresponsible',
      right: 'Frugal',
    },
    businessSense: {
      left: 'Unbusinesslike',
      right: 'Tycoon',
    },
    technologicalSkill: {
      left: 'Technophobe',
      right: 'Tech Wizard',
    },
    digitalLiteracy: {
      left: 'Technophobe',
      right: 'Digital Native',
    },
    lawKnowledge: {
      left: 'Uninformed',
      right: 'Legal Expert',
    },
  },
  lifestyleAndPersonalHabits: {
    punctuality: {
      left: 'Always Late',
      right: 'Always On Time',
    },
    readingSpeed: {
      left: 'Slow',
      right: 'Fast',
    },
    cookingStyle: {
      left: 'Simple',
      right: 'Gourmet',
    },
    romanticInterest: {
      left: 'Unromantic',
      right: 'Hopeless Romantic',
    },
    parentingInstinct: {
      left: 'Unfit',
      right: 'Natural Parent',
    },
    animalAffinity: {
      left: 'Indifferent',
      right: 'Animal Whisperer',
    },
    spiritualBelief: {
      left: 'Non-believer',
      right: 'Deeply Spiritual',
    },
    socialMediaSavvy: {
      left: 'Inept',
      right: 'Influencer',
    },
    attentionToDetail: {
      left: 'Careless',
      right: 'Perfectionist',
    },
    timeManagement: {
      left: 'Procrastinator',
      right: 'Efficient',
    },
    actingUnderPressure: {
      left: 'Panicky',
      right: 'Cool-Headed',
    },
    bodyLanguageReading: {
      left: 'Oblivious',
      right: 'Perceptive',
    },
    senseOfDirection: {
      left: 'Lost',
      right: 'Human Compass',
    },
    maturity: {
      left: 'Childish',
      right: 'Mature',
    },
    dominance: {
      left: 'Submissive',
      right: 'Dominant',
    },
  },
};

export const proEditorKeys = ['qualities'];
export const triviaQuestions = [
  { value: 'Favorite Food', title: `What's <strong class="text-primary">{character_name}'s</strong> favorite food?` },
  {
    value: 'Dream Vacation',
    title: `Where would <strong class="text-primary">{character_name}</strong> love to go for a dream vacation?`,
  },
  {
    value: 'Secret Talent',
    title: `Does <strong class="text-primary">{character_name}</strong> have a secret talent? What is it?`,
  },
  {
    value: 'Go-To Karaoke Song',
    title: `What song would <strong class="text-primary">{character_name}</strong> pick for karaoke night?`,
  },
  {
    value: 'Cherished Memory',
    title: `What is <strong class="text-primary">{character_name}'s</strong> most cherished memory?`,
  },
  {
    value: 'Biggest Fear',
    title: `What is <strong class="text-primary">{character_name}'s</strong> biggest fear or phobia?`,
  },
  {
    value: 'Beloved Hobby',
    title: `What hobby does <strong class="text-primary">{character_name}</strong> enjoy most in their free time?`,
  },
  {
    value: 'Guilty Pleasure',
    title: `What is <strong class="text-primary">{character_name}'s</strong> guilty pleasure?`,
  },
  {
    value: 'Favorite Book or Story',
    title: `What is <strong class="text-primary">{character_name}'s</strong> favorite book or story?`,
  },
  {
    value: 'If They Had A Superpower',
    title: `If <strong class="text-primary">{character_name}</strong> could have any superpower, what would it be?`,
  },
  {
    value: 'Ideal Pet',
    title: `What kind of pet would <strong class="text-primary">{character_name}</strong> love to have?`,
  },
  {
    value: 'Favorite Season',
    title: `Which season does <strong class="text-primary">{character_name}</strong> prefer?`,
  },
  {
    value: 'Best Childhood Friend',
    title: `Who was <strong class="text-primary">{character_name}'s</strong> best friend in childhood?`,
  },
  {
    value: 'Signature Dance Move',
    title: `What is <strong class="text-primary">{character_name}'s</strong> signature dance move?`,
  },
  {
    value: 'Favorite Movie Genre',
    title: `What movie genre does <strong class="text-primary">{character_name}</strong> enjoy the most?`,
  },
  {
    value: 'Lucky Charm',
    title: `Does <strong class="text-primary">{character_name}</strong> have a lucky charm? What is it?`,
  },
  {
    value: 'Morning or Night Person',
    title: `Is <strong class="text-primary">{character_name}</strong> a morning person or a night owl?`,
  },
  {
    value: 'Favorite Music Genre',
    title: `What is <strong class="text-primary">{character_name}'s</strong> favorite music genre?`,
  },
  {
    value: 'Hidden Fear',
    title: `What fear does <strong class="text-primary">{character_name}</strong> keep hidden from others?`,
  },
  { value: 'First Crush', title: `Who was <strong class="text-primary">{character_name}'s</strong> first crush?` },
  {
    value: 'Favorite Subject in School',
    title: `What was <strong class="text-primary">{character_name}'s</strong> favorite subject in school?`,
  },
  {
    value: 'Dream Job as a Kid',
    title: `What did <strong class="text-primary">{character_name}</strong> want to be when they grew up?`,
  },
  {
    value: 'Favorite Mythical Creature',
    title: `What mythical creature does <strong class="text-primary">{character_name}</strong> find fascinating?`,
  },
  {
    value: 'Best Relaxation Activity',
    title: `How does <strong class="text-primary">{character_name}</strong> like to relax?`,
  },
  {
    value: 'Favorite Festival or Holiday',
    title: `What is <strong class="text-primary">{character_name}'s</strong> favorite festival or holiday?`,
  },
  {
    value: 'Favorite Sport',
    title: `Does <strong class="text-primary">{character_name}</strong> have a favorite sport?`,
  },
  {
    value: 'Childhood Hero',
    title: `Who was <strong class="text-primary">{character_name}'s</strong> hero growing up?`,
  },
  {
    value: 'Preferred Weather',
    title: `What kind of weather does <strong class="text-primary">{character_name}</strong> like most?`,
  },
  {
    value: 'Favorite Color',
    title: `What is <strong class="text-primary">{character_name}'s</strong> favorite color?`,
  },
  {
    value: 'Best Childhood Memory',
    title: `What is <strong class="text-primary">{character_name}'s</strong> best memory from their childhood?`,
  },
  {
    value: 'A Skill They Wish to Learn',
    title: `What skill does <strong class="text-primary">{character_name}</strong> wish they could learn?`,
  },
  {
    value: 'Favorite Type of Movie',
    title: `What type of movie does <strong class="text-primary">{character_name}</strong> enjoy the most?`,
  },
  {
    value: 'If They Could Time Travel',
    title: `If <strong class="text-primary">{character_name}</strong> could travel in time, where would they go?`,
  },
  {
    value: 'Ideal Superhero or Villain',
    title: `Would <strong class="text-primary">{character_name}</strong> be a superhero or a villain? Why?`,
  },
  {
    value: 'Favorite Artist or Band',
    title: `Who is <strong class="text-primary">{character_name}'s</strong> favorite artist or band?`,
  },
  {
    value: 'Comfort Food',
    title: `What food does <strong class="text-primary">{character_name}</strong> eat for comfort?`,
  },
  {
    value: 'Dream Collaboration',
    title: `Who would <strong class="text-primary">{character_name}</strong> love to collaborate with in their field?`,
  },
  {
    value: 'A Book They Connect With',
    title: `What book does <strong class="text-primary">{character_name}</strong> feel a strong connection to?`,
  },
  {
    value: 'Favorite Childhood TV Show',
    title: `What was <strong class="text-primary">{character_name}'s</strong> favorite TV show as a child?`,
  },
  {
    value: "One Thing They Can't Live Without",
    title: `What is one thing <strong class="text-primary">{character_name}</strong> cannot live without?`,
  },
  {
    value: 'Perfect Day Description',
    title: `How would <strong class="text-primary">{character_name}</strong> describe their perfect day?`,
  },
  {
    value: 'Most Prized Possession',
    title: `What is <strong class="text-primary">{character_name}'s</strong> most prized possession?`,
  },
  {
    value: 'If They Were an Animal',
    title: `If <strong class="text-primary">{character_name}</strong> were an animal, what would they be?`,
  },
  {
    value: 'Favorite Fictional Character',
    title: `Who is <strong class="text-primary">{character_name}'s</strong> favorite fictional character?`,
  },
  {
    value: 'Dream Concert to Attend',
    title: `What concert would <strong class="text-primary">{character_name}</strong> love to attend?`,
  },
  {
    value: 'Ideal Fantasy World',
    title: `In what fantasy world would <strong class="text-primary">{character_name}</strong> like to live?`,
  },
  {
    value: 'Favorite Board Game',
    title: `What is <strong class="text-primary">{character_name}'s</strong> favorite board game?`,
  },
  {
    value: 'Preferred Art Style',
    title: `What art style does <strong class="text-primary">{character_name}</strong> prefer?`,
  },
  {
    value: 'Dream Collaboration in Art',
    title: `Who would <strong class="text-primary">{character_name}</strong> dream of collaborating with in art?`,
  },
  {
    value: 'If They Wrote a Book',
    title: `If <strong class="text-primary">{character_name}</strong> wrote a book, what would it be about?`,
  },
  {
    value: 'Favorite Time of Day',
    title: `When does <strong class="text-primary">{character_name}</strong> feel most energetic or inspired?`,
  },
  {
    value: 'If They Were in a Movie',
    title: `What genre of movie would <strong class="text-primary">{character_name}</strong> be the star of?`,
  },
  {
    value: 'Favorite Childhood Game',
    title: `What game did <strong class="text-primary">{character_name}</strong> love playing as a child?`,
  },
  {
    value: 'Most Treasured Item',
    title: `What item does <strong class="text-primary">{character_name}</strong> hold most dear?`,
  },
  {
    value: 'A Historical Figure They Admire',
    title: `Which historical figure does <strong class="text-primary">{character_name}</strong> admire the most?`,
  },
  {
    value: 'Dream Superpower',
    title: `If <strong class="text-primary">{character_name}</strong> could choose any superpower, what would it be?`,
  },
  {
    value: 'Favorite Plant or Flower',
    title: `What is <strong class="text-primary">{character_name}'s</strong> favorite plant or flower?`,
  },
  {
    value: 'Preferred Mode of Transportation',
    title: `How does <strong class="text-primary">{character_name}</strong> prefer to travel?`,
  },
  {
    value: "A Skill They're Proud Of",
    title: `What skill is <strong class="text-primary">{character_name}</strong> most proud of mastering?`,
  },
  {
    value: 'Ideal Fantasy Pet',
    title: `What fantasy creature would <strong class="text-primary">{character_name}</strong> want as a pet?`,
  },
  { value: 'Favorite Snack', title: `What snack can't <strong class="text-primary">{character_name}</strong> resist?` },
  {
    value: 'If They Could Live Anywhere',
    title: `Where would <strong class="text-primary">{character_name}</strong> choose to live?`,
  },
  {
    value: 'Preferred Weapon',
    title: `If <strong class="text-primary">{character_name}</strong> were in a battle, what weapon would they choose?`,
  },
  {
    value: 'Dream Collaboration in Music',
    title: `Who would <strong class="text-primary">{character_name}</strong> dream of making music with?`,
  },
  {
    value: 'A Place They Find Peaceful',
    title: `Where does <strong class="text-primary">{character_name}</strong> go to find peace?`,
  },
  {
    value: 'If They Were an Inventor',
    title: `What would <strong class="text-primary">{character_name}</strong> invent?`,
  },
  {
    value: 'Favorite Art Medium',
    title: `What art medium does <strong class="text-primary">{character_name}</strong> prefer?`,
  },
  {
    value: 'An Era They Belong In',
    title: `In what historical era does <strong class="text-primary">{character_name}</strong> feel they belong?`,
  },
  {
    value: 'Dream Sidekick',
    title: `Who or what would be <strong class="text-primary">{character_name}'s</strong> ideal sidekick?`,
  },
  {
    value: 'A Cuisine They Love',
    title: `What type of cuisine does <strong class="text-primary">{character_name}</strong> enjoy the most?`,
  },
  {
    value: 'If They Wrote a Song',
    title: `What would a song written by <strong class="text-primary">{character_name}</strong> be about?`,
  },
  {
    value: 'Dream Holiday Destination',
    title: `Where would <strong class="text-primary">{character_name}</strong> go for their dream holiday?`,
  },
  {
    value: "Celebrity They'd Love to Meet",
    title: `Which celebrity would <strong class="text-primary">{character_name}</strong> be excited to meet?`,
  },
  {
    value: 'Favorite Type of Weather',
    title: `What weather does <strong class="text-primary">{character_name}</strong> enjoy the most?`,
  },
  {
    value: 'A Language They Wish to Speak',
    title: `What language does <strong class="text-primary">{character_name}</strong> wish they could speak fluently?`,
  },
  {
    value: 'If They Were a Leader',
    title: `What kind of leader would <strong class="text-primary">{character_name}</strong> be?`,
  },
  {
    value: 'Dream Home',
    title: `What does <strong class="text-primary">{character_name}'s</strong> dream home look like?`,
  },
  {
    value: "A Habit They're Trying to Break",
    title: `Does <strong class="text-primary">{character_name}</strong> have a habit they're trying to break?`,
  },
  {
    value: "A Fictional World They'd Visit",
    title: `Which fictional world would <strong class="text-primary">{character_name}</strong> love to visit?`,
  },
  {
    value: 'Favorite Childhood Toy',
    title: `What was <strong class="text-primary">{character_name}'s</strong> favorite toy as a child?`,
  },
  {
    value: 'Dream Occupation',
    title: `What is <strong class="text-primary">{character_name}'s</strong> dream job or occupation?`,
  },
  {
    value: "Subject They're Passionate About",
    title: `What subject could <strong class="text-primary">{character_name}</strong> talk about for hours?`,
  },
  {
    value: 'Favorite Dessert',
    title: `What is <strong class="text-primary">{character_name}'s</strong> favorite dessert?`,
  },
  {
    value: 'A Character Trait They Admire',
    title: `What character trait does <strong class="text-primary">{character_name}</strong> admire in others?`,
  },
  {
    value: 'If They Were an Artist',
    title: `What kind of art would <strong class="text-primary">{character_name}</strong> create?`,
  },
  {
    value: 'Favorite Video Game',
    title: `What is <strong class="text-primary">{character_name}'s</strong> favorite video game?`,
  },
  {
    value: 'If They Had a Time Machine',
    title: `Where would <strong class="text-primary">{character_name}</strong> go if they had a time machine?`,
  },
  {
    value: "A Goal They're Working Towards",
    title: `What goal is <strong class="text-primary">{character_name}</strong> currently working towards?`,
  },
  {
    value: 'Favorite Type of Party',
    title: `What kind of party does <strong class="text-primary">{character_name}</strong> enjoy the most?`,
  },
  {
    value: "A Book They'd Recommend",
    title: `What book would <strong class="text-primary">{character_name}</strong> recommend to a friend?`,
  },
  {
    value: 'If They Were an Animal',
    title: `What animal does <strong class="text-primary">{character_name}</strong> identify with the most?`,
  },
  {
    value: 'Favorite Piece of Clothing',
    title: `What is <strong class="text-primary">{character_name}'s</strong> favorite item of clothing?`,
  },
  {
    value: 'A Talent They Wish They Had',
    title: `What talent does <strong class="text-primary">{character_name}</strong> wish they possessed?`,
  },
  {
    value: 'Their Role in a Heist',
    title: `What role would <strong class="text-primary">{character_name}</strong> play in a heist?`,
  },
  {
    value: 'Favorite Board or Card Game',
    title: `What is <strong class="text-primary">{character_name}'s</strong> favorite board or card game?`,
  },
  {
    value: "A Place They'd Love to Explore",
    title: `Where would <strong class="text-primary">{character_name}</strong> love to go exploring?`,
  },
  {
    value: 'If They Could Change One Thing',
    title: `What would <strong class="text-primary">{character_name}</strong> change about the world?`,
  },
  {
    value: 'Movie They Can Watch Over and Over',
    title: `What movie can <strong class="text-primary">{character_name}</strong> watch repeatedly without getting bored?`,
  },
  {
    value: 'A Childhood Dream',
    title: `What was one of <strong class="text-primary">{character_name}'s</strong> dreams as a child?`,
  },
  {
    value: 'Mythological Creature',
    title: `What mythological creature would <strong class="text-primary">{character_name}</strong> be?`,
  },
];

export const alignmentCharts = [
  {
    title: 'Love Language',
    yTopAxis: 'Affectionate',
    yBottomAxis: 'Not Affectionate',
    xRightAxis: 'Physically',
    xLeftAxis: 'Emotionally',
  },
  {
    title: 'Niceness',
    yTopAxis: 'Nice person',
    yBottomAxis: 'Prick',
    xRightAxis: 'Plays by rules',
    xLeftAxis: 'Does not play by rules',
  },
  {
    title: 'Morality Grid',
    yTopAxis: 'Good',
    yBottomAxis: 'Evil',
    xRightAxis: 'Lawful',
    xLeftAxis: 'Chaotic',
  },
  {
    title: 'Conflict Approach',
    yTopAxis: 'Avoids Conflict',
    yBottomAxis: 'Seeks Conflict',
    xRightAxis: 'Diplomatic',
    xLeftAxis: 'Aggressive',
  },
  {
    title: 'Heroic Traits',
    yTopAxis: 'Heroic',
    yBottomAxis: 'Cowardly',
    xRightAxis: 'Selfless',
    xLeftAxis: 'Selfish',
  },
  {
    title: 'Humor Style',
    yTopAxis: 'Serious',
    yBottomAxis: 'Playful',
    xRightAxis: 'Dark Humor',
    xLeftAxis: 'Light Humor',
  },
  {
    title: 'Leadership Style',
    yTopAxis: 'Leader',
    yBottomAxis: 'Follower',
    xRightAxis: 'Democratic',
    xLeftAxis: 'Authoritarian',
  },
  {
    title: 'Learning Style',
    yTopAxis: 'Intuitive',
    yBottomAxis: 'Methodical',
    xRightAxis: 'Theoretical',
    xLeftAxis: 'Practical',
  },
  {
    title: 'Social Engagement',
    yTopAxis: 'Introvert',
    yBottomAxis: 'Extrovert',
    xRightAxis: 'Observant',
    xLeftAxis: 'Unobservant',
  },
  {
    title: 'Adventure Level',
    yTopAxis: 'Adventurous',
    yBottomAxis: 'Homebody',
    xRightAxis: 'Spontaneous',
    xLeftAxis: 'Planner',
  },
  {
    title: 'Problem Solving',
    yTopAxis: 'Analytical',
    yBottomAxis: 'Creative',
    xRightAxis: 'By-The-Book',
    xLeftAxis: 'Improvisational',
  },
  {
    title: 'Ambition Scale',
    yTopAxis: 'Ambitious',
    yBottomAxis: 'Content',
    xRightAxis: 'Individual Goals',
    xLeftAxis: 'Group Goals',
  },
  {
    title: 'Loyalty Spectrum',
    yTopAxis: 'Loyal',
    yBottomAxis: 'Traitorous',
    xRightAxis: 'To People',
    xLeftAxis: 'To Cause',
  },
  {
    title: 'Intellect Type',
    yTopAxis: 'Book Smart',
    yBottomAxis: 'Street Smart',
    xRightAxis: 'Logical',
    xLeftAxis: 'Emotional',
  },
  {
    title: 'Cultural Alignment',
    yTopAxis: 'Traditional',
    yBottomAxis: 'Modern',
    xRightAxis: 'Conservative',
    xLeftAxis: 'Liberal',
  },
  {
    title: 'Resourcefulness',
    yTopAxis: 'Resourceful',
    yBottomAxis: 'Helpless',
    xRightAxis: 'Innovative',
    xLeftAxis: 'Conventional',
  },
  {
    title: 'Optimism Scale',
    yTopAxis: 'Optimistic',
    yBottomAxis: 'Pessimistic',
    xRightAxis: 'Realistic',
    xLeftAxis: 'Idealistic',
  },
  {
    title: 'Bravery Quotient',
    yTopAxis: 'Brave',
    yBottomAxis: 'Timid',
    xRightAxis: 'Reckless',
    xLeftAxis: 'Cautious',
  },
  {
    title: 'Creativity Chart',
    yTopAxis: 'Creative',
    yBottomAxis: 'Unimaginative',
    xRightAxis: 'Original',
    xLeftAxis: 'Derivative',
  },
  {
    title: 'Empathy Spectrum',
    yTopAxis: 'Empathetic',
    yBottomAxis: 'Apathetic',
    xRightAxis: 'Reactive',
    xLeftAxis: 'Proactive',
  },
  {
    title: 'Financial Attitude',
    yTopAxis: 'Thrifty',
    yBottomAxis: 'Extravagant',
    xRightAxis: 'Investor',
    xLeftAxis: 'Spender',
  },
  {
    title: 'Work Ethic',
    yTopAxis: 'Hardworking',
    yBottomAxis: 'Lazy',
    xRightAxis: 'Team Player',
    xLeftAxis: 'Solo Worker',
  },
  {
    title: 'Sense of Humor',
    yTopAxis: 'No-Nonsense',
    yBottomAxis: 'Joker',
    xRightAxis: 'Sarcasm',
    xLeftAxis: 'Sincerity',
  },
  {
    title: 'Ambience Preference',
    yTopAxis: 'Loves Nature',
    yBottomAxis: 'Loves City',
    xRightAxis: 'Noisy',
    xLeftAxis: 'Quiet',
  },
  {
    title: 'Artistic Inclination',
    yTopAxis: 'Artistic',
    yBottomAxis: 'Non-Artistic',
    xRightAxis: 'Abstract',
    xLeftAxis: 'Realistic',
  },
  {
    title: 'Aspiration Scale',
    yTopAxis: 'Dreamer',
    yBottomAxis: 'Realist',
    xRightAxis: 'Career',
    xLeftAxis: 'Personal Life',
  },
  {
    title: 'Authority Stance',
    yTopAxis: 'Submissive',
    yBottomAxis: 'Rebellious',
    xRightAxis: 'Respects Authority',
    xLeftAxis: 'Defies Authority',
  },
  {
    title: 'Boldness Gauge',
    yTopAxis: 'Bold',
    yBottomAxis: 'Timid',
    xRightAxis: 'Direct',
    xLeftAxis: 'Indirect',
  },
  {
    title: 'Cautiousness Measure',
    yTopAxis: 'Cautious',
    yBottomAxis: 'Risk-Taker',
    xRightAxis: 'Predictable',
    xLeftAxis: 'Unpredictable',
  },
  {
    title: 'Comedic Sense',
    yTopAxis: 'Serious',
    yBottomAxis: 'Comical',
    xRightAxis: 'Dry Wit',
    xLeftAxis: 'Slapstick',
  },
  {
    title: 'Communication Style',
    yTopAxis: 'Verbal',
    yBottomAxis: 'Non-Verbal',
    xRightAxis: 'Direct',
    xLeftAxis: 'Subtle',
  },
  {
    title: 'Compassion Level',
    yTopAxis: 'Compassionate',
    yBottomAxis: 'Indifferent',
    xRightAxis: 'People',
    xLeftAxis: 'Animals',
  },
  {
    title: 'Conflict Resolution',
    yTopAxis: 'Confrontational',
    yBottomAxis: 'Avoidant',
    xRightAxis: 'Compromise',
    xLeftAxis: 'Win-Lose',
  },
  {
    title: 'Culinary Taste',
    yTopAxis: 'Gourmet',
    yBottomAxis: 'Unfussy',
    xRightAxis: 'Sweet',
    xLeftAxis: 'Savory',
  },
  {
    title: 'Curiosity Index',
    yTopAxis: 'Curious',
    yBottomAxis: 'Uninterested',
    xRightAxis: 'Science',
    xLeftAxis: 'Art',
  },
  {
    title: 'Decision Making',
    yTopAxis: 'Impulsive',
    yBottomAxis: 'Deliberate',
    xRightAxis: 'Heart',
    xLeftAxis: 'Head',
  },
  {
    title: 'Dedication Degree',
    yTopAxis: 'Dedicated',
    yBottomAxis: 'Uncommitted',
    xRightAxis: 'Work',
    xLeftAxis: 'Relationships',
  },
  {
    title: 'Dream Attitude',
    yTopAxis: 'Dreamy',
    yBottomAxis: 'Practical',
    xRightAxis: 'Fantastical',
    xLeftAxis: 'Realistic',
  },
  {
    title: 'Education Value',
    yTopAxis: 'Scholarly',
    yBottomAxis: 'Unacademic',
    xRightAxis: 'Formal Education',
    xLeftAxis: 'Life Experiences',
  },
  {
    title: 'Emotional Expression',
    yTopAxis: 'Expressive',
    yBottomAxis: 'Stoic',
    xRightAxis: 'Public',
    xLeftAxis: 'Private',
  },
  {
    title: 'Environmental Awareness',
    yTopAxis: 'Eco-Friendly',
    yBottomAxis: 'Indifferent',
    xRightAxis: 'Activist',
    xLeftAxis: 'Non-Activist',
  },
  {
    title: 'Fashion Sense',
    yTopAxis: 'Stylish',
    yBottomAxis: 'Plain',
    xRightAxis: 'Trendy',
    xLeftAxis: 'Classic',
  },
  {
    title: 'Flexibility Level',
    yTopAxis: 'Flexible',
    yBottomAxis: 'Rigid',
    xRightAxis: 'Adaptable',
    xLeftAxis: 'Inflexible',
  },
  {
    title: 'Friendship Style',
    yTopAxis: 'Independent',
    yBottomAxis: 'Cliquey',
    xRightAxis: 'Many Acquaintances',
    xLeftAxis: 'Few Close Friends',
  },
  {
    title: 'Generosity Quotient',
    yTopAxis: 'Generous',
    yBottomAxis: 'Stingy',
    xRightAxis: 'Time',
    xLeftAxis: 'Money',
  },
  {
    title: 'Habitual Behaviors',
    yTopAxis: 'Habitual',
    yBottomAxis: 'Spontaneous',
    xRightAxis: 'Good Habits',
    xLeftAxis: 'Bad Habits',
  },
  {
    title: 'Happiness Source',
    yTopAxis: 'Internal',
    yBottomAxis: 'External',
    xRightAxis: 'Material',
    xLeftAxis: 'Spiritual',
  },
  {
    title: 'Health Focus',
    yTopAxis: 'Health-Conscious',
    yBottomAxis: 'Carefree',
    xRightAxis: 'Diet',
    xLeftAxis: 'Exercise',
  },
  {
    title: 'Honesty Scale',
    yTopAxis: 'Honest',
    yBottomAxis: 'Deceptive',
    xRightAxis: 'Blunt',
    xLeftAxis: 'Tactful',
  },
  {
    title: 'Humility Range',
    yTopAxis: 'Humble',
    yBottomAxis: 'Arrogant',
    xRightAxis: 'Self-Deprecating',
    xLeftAxis: 'Proud',
  },
  {
    title: 'Independence Level',
    yTopAxis: 'Independent',
    yBottomAxis: 'Dependent',
    xRightAxis: 'Self-Sufficient',
    xLeftAxis: 'Supported',
  },
  {
    title: 'Innovation Tendency',
    yTopAxis: 'Innovator',
    yBottomAxis: 'Traditionalist',
    xRightAxis: 'Tech',
    xLeftAxis: 'Non-Tech',
  },
  {
    title: 'Justice View',
    yTopAxis: 'Just',
    yBottomAxis: 'Unjust',
    xRightAxis: 'Merciful',
    xLeftAxis: 'Strict',
  },
  {
    title: 'Knowledge Pursuit',
    yTopAxis: 'Knowledgeable',
    yBottomAxis: 'Ignorant',
    xRightAxis: 'Specialized',
    xLeftAxis: 'Generalized',
  },
  {
    title: 'Learning Preference',
    yTopAxis: 'Visual',
    yBottomAxis: 'Auditory',
    xRightAxis: 'Kinesthetic',
    xLeftAxis: 'Textual',
  },
  {
    title: 'Maturity Spectrum',
    yTopAxis: 'Mature',
    yBottomAxis: 'Immature',
    xRightAxis: 'Emotionally',
    xLeftAxis: 'Intellectually',
  },
  {
    title: 'Musical Taste',
    yTopAxis: 'Melodic',
    yBottomAxis: 'Rhythmic',
    xRightAxis: 'Classical',
    xLeftAxis: 'Modern',
  },
  {
    title: 'Nature Affinity',
    yTopAxis: 'Nature-Lover',
    yBottomAxis: 'Indoorsy',
    xRightAxis: 'Active',
    xLeftAxis: 'Passive',
  },
  {
    title: 'Organization Method',
    yTopAxis: 'Organized',
    yBottomAxis: 'Disorganized',
    xRightAxis: 'Planner',
    xLeftAxis: 'Improvisor',
  },
  {
    title: 'Patience Level',
    yTopAxis: 'Patient',
    yBottomAxis: 'Impatient',
    xRightAxis: 'Calm',
    xLeftAxis: 'Anxious',
  },
  {
    title: 'Philanthropy Approach',
    yTopAxis: 'Philanthropic',
    yBottomAxis: 'Self-Serving',
    xRightAxis: 'Volunteer',
    xLeftAxis: 'Donor',
  },
  {
    title: 'Punctuality Practice',
    yTopAxis: 'Punctual',
    yBottomAxis: 'Late',
    xRightAxis: 'Time-Oriented',
    xLeftAxis: 'Event-Oriented',
  },
  {
    title: 'Societal Role',
    yTopAxis: 'Conformist',
    yBottomAxis: 'Nonconformist',
    xRightAxis: 'Mainstream',
    xLeftAxis: 'Niche',
  },
  {
    title: 'Adventure Seeking',
    yTopAxis: 'Thrill-Seeker',
    yBottomAxis: 'Security-Seeker',
    xRightAxis: 'Explorer',
    xLeftAxis: 'Settler',
  },
  {
    title: 'Altruism Scale',
    yTopAxis: 'Altruistic',
    yBottomAxis: 'Selfish',
    xRightAxis: 'Community-Focused',
    xLeftAxis: 'Individual-Focused',
  },
  {
    title: 'Animal Affinity',
    yTopAxis: 'Animal Lover',
    yBottomAxis: 'Indifferent to Animals',
    xRightAxis: 'Domestic',
    xLeftAxis: 'Wild',
  },
  {
    title: 'Attitude to Magic',
    yTopAxis: 'Enchanted',
    yBottomAxis: 'Skeptic',
    xRightAxis: 'Natural Magic',
    xLeftAxis: 'High Magic',
  },
  {
    title: 'Belief Systems',
    yTopAxis: 'Spiritual',
    yBottomAxis: 'Atheist',
    xRightAxis: 'Organized Religion',
    xLeftAxis: 'Personal Beliefs',
  },
  {
    title: 'Book Preferences',
    yTopAxis: 'Bookworm',
    yBottomAxis: 'Non-Reader',
    xRightAxis: 'Fiction',
    xLeftAxis: 'Non-Fiction',
  },
  {
    title: 'Brevity in Speech',
    yTopAxis: 'Laconic',
    yBottomAxis: 'Verbose',
    xRightAxis: 'Straightforward',
    xLeftAxis: 'Eloquent',
  },
  {
    title: 'Camaraderie Level',
    yTopAxis: 'Team Player',
    yBottomAxis: 'Lone Wolf',
    xRightAxis: 'Inclusive',
    xLeftAxis: 'Exclusive',
  },
  {
    title: 'Childhood Nostalgia',
    yTopAxis: 'Sentimental',
    yBottomAxis: 'Unsentimental',
    xRightAxis: 'Past',
    xLeftAxis: 'Present',
  },
  {
    title: 'Conflict Management',
    yTopAxis: 'Peacemaker',
    yBottomAxis: 'Warmonger',
    xRightAxis: 'Tactician',
    xLeftAxis: 'Improviser',
  },
  {
    title: 'Craftsmanship',
    yTopAxis: 'Craftsman',
    yBottomAxis: 'Novice',
    xRightAxis: 'Artisanal',
    xLeftAxis: 'Industrial',
  },
  {
    title: 'Cynicism vs. Idealism',
    yTopAxis: 'Cynical',
    yBottomAxis: 'Idealistic',
    xRightAxis: 'Realist',
    xLeftAxis: 'Dreamer',
  },
  {
    title: 'Decision Style',
    yTopAxis: 'Decisive',
    yBottomAxis: 'Indecisive',
    xRightAxis: 'Intuitive',
    xLeftAxis: 'Calculated',
  },
  {
    title: 'Emotional Stability',
    yTopAxis: 'Stable',
    yBottomAxis: 'Volatile',
    xRightAxis: 'Calm',
    xLeftAxis: 'Passionate',
  },
  {
    title: 'Family Dynamics',
    yTopAxis: 'Family-Oriented',
    yBottomAxis: 'Independent',
    xRightAxis: 'Traditional',
    xLeftAxis: 'Modern',
  },
  {
    title: 'Fashion Style',
    yTopAxis: 'Fashionable',
    yBottomAxis: 'Indifferent',
    xRightAxis: 'Trendy',
    xLeftAxis: 'Classic',
  },
  {
    title: 'Financial Management',
    yTopAxis: 'Saver',
    yBottomAxis: 'Spender',
    xRightAxis: 'Budgeter',
    xLeftAxis: 'Impulsive',
  },
  {
    title: 'Flavor Preferences',
    yTopAxis: 'Sweet Tooth',
    yBottomAxis: 'Savory Fan',
    xRightAxis: 'Gourmet Snacker',
    xLeftAxis: 'Anything-Goes Muncher',
  },
  {
    title: 'Friendship Dynamics',
    yTopAxis: 'Inclusive',
    yBottomAxis: 'Selective',
    xRightAxis: 'Surface-Level',
    xLeftAxis: 'Deep Bonds',
  },
  {
    title: 'Gaming Style',
    yTopAxis: 'Competitive',
    yBottomAxis: 'Casual',
    xRightAxis: 'Strategic',
    xLeftAxis: 'Luck-Based',
  },
  {
    title: 'Gastronomic Adventurousness',
    yTopAxis: 'Foodie',
    yBottomAxis: 'Picky Eater',
    xRightAxis: 'Local Cuisine',
    xLeftAxis: 'International Cuisine',
  },
  {
    title: 'Historical Interest',
    yTopAxis: 'Historian',
    yBottomAxis: 'Modernist',
    xRightAxis: 'Specific Era',
    xLeftAxis: 'General History',
  },
  {
    title: 'Hobby Engagement',
    yTopAxis: 'Hobbyist',
    yBottomAxis: 'Non-Hobbyist',
    xRightAxis: 'Collecting',
    xLeftAxis: 'Creating',
  },
  {
    title: 'Humor Appreciation',
    yTopAxis: 'Slapstick',
    yBottomAxis: 'Satirical',
    xRightAxis: 'Physical Comedy',
    xLeftAxis: 'Word Play',
  },
  {
    title: 'Inquisitiveness',
    yTopAxis: 'Inquisitive',
    yBottomAxis: 'Accepting',
    xRightAxis: 'Broad',
    xLeftAxis: 'Specific',
  },
  {
    title: 'Justice Perception',
    yTopAxis: 'Retributive',
    yBottomAxis: 'Rehabilitative',
    xRightAxis: 'Personal',
    xLeftAxis: 'Systematic',
  },
  {
    title: 'Leadership Ambition',
    yTopAxis: 'Aspiring Leader',
    yBottomAxis: 'Reluctant Leader',
    xRightAxis: 'Front',
    xLeftAxis: 'Behind the Scenes',
  },
  {
    title: 'Learning Agility',
    yTopAxis: 'Quick Learner',
    yBottomAxis: 'Slow Learner',
    xRightAxis: 'Book Learning',
    xLeftAxis: 'Experiential Learning',
  },
  {
    title: 'Literary Interests',
    yTopAxis: 'Prose',
    yBottomAxis: 'Poetry',
    xRightAxis: 'Genre Fiction',
    xLeftAxis: 'Literary Fiction',
  },
  {
    title: 'Memory Reliance',
    yTopAxis: 'Eidetic',
    yBottomAxis: 'Forgetful',
    xRightAxis: 'Details',
    xLeftAxis: 'Concepts',
  },
  {
    title: 'Musical Inclination',
    yTopAxis: 'Musician',
    yBottomAxis: 'Listener',
    xRightAxis: 'Instrumental',
    xLeftAxis: 'Vocal',
  },
  {
    title: 'Nature vs. Nurture',
    yTopAxis: 'Genetics',
    yBottomAxis: 'Environment',
    xRightAxis: 'Fixed Traits',
    xLeftAxis: 'Malleable Traits',
  },
  {
    title: 'Organizational Preference',
    yTopAxis: 'Minimalist',
    yBottomAxis: 'Collector',
    xRightAxis: 'Neat',
    xLeftAxis: 'Messy',
  },
  {
    title: 'Outdoor Activities',
    yTopAxis: 'Outdoorsy',
    yBottomAxis: 'Indoorsy',
    xRightAxis: 'Land',
    xLeftAxis: 'Water',
  },
  {
    title: 'Personal Growth Focus',
    yTopAxis: 'Self-Improvement',
    yBottomAxis: 'Contentment',
    xRightAxis: 'Skills',
    xLeftAxis: 'Mindset',
  },
  {
    title: 'Philosophical Bent',
    yTopAxis: 'Philosopher',
    yBottomAxis: 'Pragmatist',
    xRightAxis: 'Abstract',
    xLeftAxis: 'Concrete',
  },
  {
    title: 'Political Engagement',
    yTopAxis: 'Activist',
    yBottomAxis: 'Apathetic',
    xRightAxis: 'Left-Wing',
    xLeftAxis: 'Right-Wing',
  },
  {
    title: 'Problem Tackling',
    yTopAxis: 'Analytical',
    yBottomAxis: 'Intuitive',
    xRightAxis: 'Systematic',
    xLeftAxis: 'Holistic',
  },
  {
    title: 'Risk Management',
    yTopAxis: 'Risk-Taker',
    yBottomAxis: 'Risk-Averse',
    xRightAxis: 'Calculated Risks',
    xLeftAxis: 'Spontaneous Risks',
  },
  {
    title: 'Sensory Preference',
    yTopAxis: 'Visual',
    yBottomAxis: 'Auditory',
    xRightAxis: 'Tactile',
    xLeftAxis: 'Olfactory',
  },
  {
    title: 'Snack Affinities',
    yTopAxis: 'Sweet Tooth',
    yBottomAxis: 'Savory Fan',
    xRightAxis: 'Gourmet Snacker',
    xLeftAxis: 'Anything-Goes Muncher',
  },
  {
    title: 'Dance Moves Spectrum',
    yTopAxis: 'Dance Floor Star',
    yBottomAxis: 'Wallflower',
    xRightAxis: 'Choreographed',
    xLeftAxis: 'Free-Style',
  },
  {
    title: 'Pet Choices',
    yTopAxis: 'Cat Person',
    yBottomAxis: 'Dog Person',
    xRightAxis: 'Mythical Creatures',
    xLeftAxis: 'Real Animals',
  },
  {
    title: 'Time Travel Tendencies',
    yTopAxis: 'Past Explorer',
    yBottomAxis: 'Future Voyager',
    xRightAxis: 'Cautious Observer',
    xLeftAxis: 'Timeline Meddler',
  },
  {
    title: 'Potion Brewing',
    yTopAxis: 'Master Alchemist',
    yBottomAxis: 'Potion Novice',
    xRightAxis: 'Healing Elixirs',
    xLeftAxis: 'Chaotic Concoctions',
  },
  {
    title: 'Secret Lair Style',
    yTopAxis: 'Hidden Cave',
    yBottomAxis: 'Floating Castle',
    xRightAxis: 'Tech Fortress',
    xLeftAxis: 'Magic-Infused',
  },
  {
    title: 'Dream Vacation',
    yTopAxis: 'Space Odyssey',
    yBottomAxis: 'Deep Sea Dive',
    xRightAxis: 'Luxury Resort',
    xLeftAxis: 'Wilderness Survival',
  },
  {
    title: 'Magical Pet Companion',
    yTopAxis: 'Loyal Dragon',
    yBottomAxis: 'Mischievous Fairy',
    xRightAxis: 'Invisible',
    xLeftAxis: 'Glowing',
  },
  {
    title: 'Superpower Desires',
    yTopAxis: 'Flight',
    yBottomAxis: 'Invisibility',
    xRightAxis: 'Heroic Feats',
    xLeftAxis: 'Pranks',
  },
  {
    title: 'Cosmic Quest',
    yTopAxis: 'Star Commander',
    yBottomAxis: 'Galaxy Gardener',
    xRightAxis: 'Peace Missions',
    xLeftAxis: 'Space Battles',
  },
  {
    title: 'Wizarding Worldview',
    yTopAxis: 'Spell Caster',
    yBottomAxis: 'Potion Master',
    xRightAxis: 'Elemental Magic',
    xLeftAxis: 'Dark Arts',
  },
  {
    title: 'Legendary Weapon',
    yTopAxis: 'Enchanted Sword',
    yBottomAxis: 'Wise Wand',
    xRightAxis: 'Forged in Fire',
    xLeftAxis: 'Born of Ice',
  },
  {
    title: 'Alien Encounter',
    yTopAxis: 'Friendly E.T.',
    yBottomAxis: 'Suspicious Alien',
    xRightAxis: 'Communicative',
    xLeftAxis: 'Mysterious',
  },
  {
    title: 'Fantasy Feast',
    yTopAxis: 'Banquet with Elves',
    yBottomAxis: 'Tea with Trolls',
    xRightAxis: 'Gourmet',
    xLeftAxis: 'Wholesome',
  },
  {
    title: 'Sidekick Selection',
    yTopAxis: 'Silent Robot',
    yBottomAxis: 'Talking Animal',
    xRightAxis: 'Strategic Ally',
    xLeftAxis: 'Comic Relief',
  },
  {
    title: 'Time of Day Dominance',
    yTopAxis: 'Early Bird',
    yBottomAxis: 'Night Owl',
    xRightAxis: 'Sunrise Quests',
    xLeftAxis: 'Moonlight Adventures',
  },
  {
    title: 'Intergalactic Outfit',
    yTopAxis: 'Alien Disguise',
    yBottomAxis: 'Space Armor',
    xRightAxis: 'Undercover',
    xLeftAxis: 'Flashy',
  },
  {
    title: 'Treasure Hunt Tactics',
    yTopAxis: 'Riddle Solver',
    yBottomAxis: 'Map Decipherer',
    xRightAxis: 'Crew Captain',
    xLeftAxis: 'Solo Explorer',
  },
  {
    title: 'Arcade Avatar',
    yTopAxis: '3D Trailblazer',
    yBottomAxis: 'Pixel Pioneer',
    xRightAxis: 'Platform Jumper',
    xLeftAxis: 'Puzzle Solver',
  },
  {
    title: 'Mystical Market Shopping',
    yTopAxis: 'Ancient Artifacts',
    yBottomAxis: 'Potion Ingredients',
    xRightAxis: 'Splurge Spender',
    xLeftAxis: 'Bargain Hunter',
  },
];

export const announcementCheckInterval = 60 * 60 * 1000;

export const hotCharacterEditorSections = ['backstory', 'colors', 'stats', 'spotifyLink', 'relationshipsSection'];

export const hotCharacterAboutSections = [
  'age',
  'pronouns',
  'species',
  'gender',
  'sexuality',
  'height',
  'likes',
  'birthday',
  'dislikes',
  'fullName',
  'nickname',
  'birthPlace',
  'occupation',
  'Eyes (Color)',
  'Hair (Color, Style)',
  'family',
  'nationality',
  'zodiac',
  'friends',
  'hobbies',
];

export const characterAboutExamples: EditorAboutExample = {
  "fullName": "What is their full name?",
  "nickname": "Do they have a nickname?",
  "alias": "Do they have a code name?",
  "realName": "What is their real name?",
  "pronouns": "What pronouns do they use?",
  "age": "How old are they?",
  "birthPlace": "Where were they born?",
  "nationality": "What country are they from?",
  "race": "What race are they?",
  "ethnicity": "What is their ethnic background?",
  "species": "What species are they?",
  "breed": "What breed are they?",
  "class": "What is their class or role?",
  "title": "Do they have a title?",
  "zodiac": "What is their zodiac sign?",
  "astrologicalSign": "What astrological sign were they born under?",
  "bloodType": "What is their blood type?",
  "gender": "What is their gender?",
  "sexuality": "What is their sexual orientation?",
  "likes": "What do they enjoy?",
  "dislikes": "What do they dislike?",
  "birthday": "When is their birthday?",
  "codeName": "Do they go by a secret name?",
  "previousNames": "Have they had any other names before?",
  "adoptionStatus": "Were they adopted?",
  "pastLifeIdentity": "Do they have an identity from a past life?",
  "secretIdentity": "Do they have a hidden identity?",
  "signatureItemOrTotem": "Do they have a special item they carry?",
  "secretOrHiddenTalent": "Do they have a hidden talent?",
  "height": "How tall are they?",
  "weight": "How much do they weigh?",
  "Hair (Color, Style)": "What color is their hair, and how do they style it?",
  "Eyes (Color)": "What color are their eyes?",
  "Skin (Color, Texture)": "What is their skin color or texture?",
  "build": "How is their body built?",
  "faceShape": "What is the shape of their face?",
  "specificFeatures": "Do they have any distinct features?",
  "Marks (Scars, Tattoos)": "Any unique scars or symbols on them?",
  "makeup": "Do they wear makeup? If so, what kind?",
  "jewelry": "Do they wear any jewelry?",
  "piercings": "Do they have any piercings?",
  "restingFacialExpression": "What is their usual facial expression?",
  "dominantSense": "Which sense do they rely on most?",
  "physicalIdiosyncrasies": "Any unusual physical habits or traits?",
  "gait": "How do they walk?",
  "fashionStyle": "What style of clothing do they wear?",
  "fitnessLevel": "How physically fit are they?",
  "physicalDisabilities": "Do they have any physical disabilities?",
  "cosmeticEnhancements": "Do they have any cosmetic modifications?",
  "bodyModifications": "Have they altered their body in any way?",
  "voiceTone": "What is the tone of their voice?",
  "naturalScent": "Do they have a natural scent?",
  "opticalPrescription": "Do they wear glasses or contact lenses?",
  "uniqueDietaryNeeds": "Do they have special food requirements?",
  "elementAffinity": "What element are they most connected to?",
  "seasonalAppearanceChanges": "Does their appearance change with the seasons?",
  "biologicalAnomalies": "Any unusual biological traits or anomalies?",
  "personalityType": "What kind of personality do they have?",
  "MBTI": "What is their Myers-Briggs type?",
  "moralCompass": "What guides their decisions about right and wrong?",
  "temperament": "How do they typically behave emotionally?",
  "virtues": "What are their strengths?",
  "flaws": "What are their weaknesses?",
  "emotionalIntelligence": "How well do they understand and manage emotions?",
  "charismaLevel": "How charming are they?",
  "communicationStyle": "How do they typically communicate?",
  "humorStyle": "What kind of humor do they have?",
  "levelOfEmpathy": "How empathetic are they towards others?",
  "intellectualInterests": "What subjects interest them the most?",
  "artisticTalents": "What artistic abilities do they have?",
  "learningStyle": "How do they best learn?",
  "linguisticAbilities": "What languages do they speak?",
  "leadershipStyle": "How do they lead others?",
  "riskTolerance": "How comfortable are they with taking risks?",
  "politicalViews": "What are their political beliefs?",
  "coreBelief": "What is the core value they live by?",
  "lifePhilosophy": "What is their philosophy on life?",
  "personalMantra": "Do they have a saying or motto they live by?",
  "greatestFear": "What scares them the most?",
  "sourceOfJoy": "What brings them the most happiness?",
  "attitudeTowardDeath": "How do they view death?",
  "emotionalTrigger": "What easily upsets them?",
  "stressReliefMethod": "How do they calm down under stress?",
  "cognitiveBiasSusceptibility": "Are they prone to certain biases in thinking?",
  "personalSuperstition": "Do they believe in any superstitions?",
  "education": "What is their educational background?",
  "occupation": "What do they do for work?",
  "financialStatus": "How wealthy or poor are they?",
  "residence": "Where do they live?",
  "family": "Who are their family members?",
  "friends": "Who are their closest friends?",
  "enemies": "Do they have any enemies?",
  "significantOther": "Are they in a relationship?",
  "soulMate": "Do they believe in having a soul mate?",
  "lifeGoals": "What are their long-term goals in life?",
  "biggestDream": "What is their biggest dream?",
  "darkestSecret": "What is their most well-kept secret?",
  "guilt": "What do they feel guilty about?",
  "shameElements": "What makes them feel ashamed?",
  "internalConflicts": "What do they struggle with internally?",
  "History/Background": "What is their personal history or background?",
  "formativeChildhoodEvent": "What was a defining event in their childhood?",
  "historicalEraOfGreatestInfluence": "Which historical era influenced them the most?",
  "mostCherishedMemory": "What is their most cherished memory?",
  "aDefiningChallengeOvercome": "What challenge have they overcome?",
  "inheritance": "Have they received an inheritance? If so, what?",
  "previousOccupations": "What jobs have they had in the past?",
  "firstLoveExperience": "What was their first love experience like?",
  "historicalFigureIdentification": "Which historical figure do they identify with?",
  "personalLegendOrMyth": "Do they have a personal legend or myth tied to them?",
  "archNemesis": "Do they have an arch-nemesis?",
  "hobbies": "What are their hobbies?",
  "favoriteAnimal": "What is their favorite animal?",
  "favoriteFood": "What is their favorite food?",
  "favoriteDrink": "What is their favorite drink?",
  "favoriteColor": "What is their favorite color?",
  "foodPreferences": "Do they have any food preferences?",
  "leisureActivities": "What do they like to do for fun?",
  "fandom": "Are they part of any fandoms?",
  "orientation": "What is their sexual orientation?",
  "favoriteGenre": "What's their favorite genre of entertainment or media?",
  "preferredFormOfEntertainment": "How do they prefer to be entertained?",
  "physicalHealth": "How is their physical health?",
  "mentalHealth": "How is their mental health?",
  "allergies": "Do they have any allergies?",
  "illnesses": "Do they have any known illnesses?",
  "disorders": "Do they have any disorders?",
  "strengths": "What are their strengths?",
  "weaknesses": "What are their weaknesses?",
  "specialPowers": "Do they have any special powers?",
  "skills": "What are their notable skills?",
  "magic": "Can they use magic?",
  "fightingStyle": "How do they fight? What's their style?",
  "uniquePhysicalAbility": "Do they have a unique physical ability?",
  "mentalResilience": "How mentally resilient are they?",
  "creativeAptitude": "How creative are they?",
  "technologicalSavvy": "How skilled are they with technology?",
  "languageProficiency": "How many languages can they speak?",
  "culinarySkills": "How good are they at cooking?",
  "organizationalAbility": "How well can they organize tasks or things?",
  "musicalInstrumentProficiency": "Can they play any musical instruments?",
  "athleticCompetence": "How good are they at sports or physical activity?",
  "survivalSkills": "How well can they survive in tough environments?",
  "relationship": "What is their relationship status?",
  "affiliation": "What groups or organizations are they affiliated with?",
  "language": "What languages do they speak or understand?",
  "accent": "Do they have an accent? If so, what kind?",
  "speechPatterns": "How do they typically speak or express themselves?",
  "socialStanding": "What is their position in society?",
  "culturalBackground": "What culture do they come from?",
  "religion": "What religion, if any, do they follow?",
  "philosophy": "What is their personal philosophy?",
  "Alive/Dead": "Are they alive or dead?",
  "Most Used Phrases / Words": "What phrases or words do they say most often?",
  "comfortItem": "Do they have a special comfort item they rely on?",
  "weapons": "What weapons do they use?",
  "pets": "Do they have any pets?",
  "wealth": "How wealthy are they?",
  "outfits": "What kind of outfits do they wear?",
  "accessories": "Do they wear any accessories?",
  "voiceClaim": "Who would voice them, if applicable?",
  "threatLevel": "How dangerous or threatening are they?",
  "parentingStyle": "What is their approach to parenting, if applicable?",
  "environmentalAwareness": "How aware are they of environmental issues?",
  "fears": "What are they afraid of?",
  "phobias": "Do they have any phobias?",
  "motivation": "What drives or motivates them?",
  "copingMechanisms": "How do they cope with stress or difficult situations?",
  "senseOfTime": "How do they perceive time?",
  "selfPerception": "How do they see themselves?",
  "ambitions": "What are their ambitions?",
  "attachmentStyle": "How do they form relationships or attachments?",
  "locusOfControl": "Do they believe they control their own fate, or is it outside forces?",
  "toleranceForAmbiguity": "How well do they handle uncertainty?",
  "trustworthiness": "How trustworthy are they?",
  "relationshipWithTechnology": "How comfortable are they with technology?",
  "Conformity Vs. Nonconformity": "Do they tend to follow the crowd or go their own way?",
  "attentionSpan": "How long can they focus on something?",
  "levelOfSuperstitiousBeliefs": "How superstitious are they?",
  "Adaptability / Flexibility": "How adaptable are they to new situations?",
  "lifetimeAmbition": "What is their biggest life goal?",
  "shortTermGoals": "What are their current or short-term goals?",
  "dreamAchievement": "What dreams have they achieved?",
  "careerAspiration": "What do they want to achieve in their career?",
  "educationalAspiration": "What are their goals related to education?",
  "artisticEndeavor": "What do they want to accomplish artistically?",
  "scientificTechnologicalGoal": "What are their science or technology-related goals?",
  "socialImpactAim": "How do they want to impact society?",
  "environmentalObjective": "What are their environmental goals or interests?",
  "personalDevelopmentTarget": "What do they want to improve about themselves?",
  "coreValues": "What values guide their life?",
  "ethicalStance": "What is their ethical viewpoint?",
  "lifeMotto": "Do they have a life motto or slogan they live by?",
  "viewsOnJustice": "How do they feel about justice or fairness?",
  "beliefsAboutDestinyFate": "Do they believe in destiny or fate?",
  "opinionOnWealthAndMaterialism": "How do they view money and material things?",
  "stanceOnPowerAndAuthority": "How do they feel about power and authority?",
  "culturalValues": "What cultural values do they hold dear?",
  "philosophicalBeliefs": "What is their philosophy on life or the world?",
  "viewsOnHumanNature": "How do they perceive human nature?",
  "favoritePastime": "What do they love doing in their free time?",
  "preferredLeisureEnvironment": "Where do they enjoy spending their leisure time?",
  "goToSocialActivity": "What social activity do they always enjoy?",
  "solitaryRelaxationPreference": "How do they prefer to relax alone?",
  "preferredSportOrPhysicalActivity": "What sport or physical activity do they enjoy the most?",
  "favoriteCreativeOutlet": "What's their favorite way to express their creativity?",
  "entertainmentChoices": "What kind of entertainment do they choose?",
  "typicalWeekendActivity": "How do they typically spend their weekends?",
  "vacationStyle": "What's their preferred vacation style?",
  "favoriteFestivalOrEvent": "Do they have a favorite festival or event?",
  "relationshipWithFamily": "What's their relationship with their family like?",
  "friendshipDynamics": "How do they interact with their friends?",
  "romanticInclinations": "What is their approach to romance?",
  "mentoringStyle": "How do they mentor or guide others?",
  "attitudeTowardsStrangers": "How do they feel about meeting new people?",
  "socialCircleDescription": "What's their social circle like?",
  "approachToNetworking": "How do they go about making new connections?",
  "roleInATeamOrGroup": "What role do they tend to play in a group?",
  "conflictManagementInRelationships": "How do they handle conflicts in their relationships?",
  "attitudeTowardsPetsAndAnimals": "How do they feel about animals and pets?",
  "deepSeatedFear": "What is their most deep-rooted fear?",
  "personalInsecurities": "What insecurities do they have?",
  "sourceOfPride": "What are they most proud of?",
  "guiltyPleasure": "Do they have a guilty pleasure?",
  "internalMoralConflict": "Is there a moral conflict they struggle with?",
  "psychologicalTrauma": "Have they experienced any psychological trauma?",
  "reactionToCrisis": "How do they react in a crisis situation?",
  "copingMechanismForLoss": "How do they cope with loss?",
  "approachToChangeAndTransition": "How do they handle change and transitions in life?",
  "methodForSelfReflection": "How do they reflect on their actions or decisions?",
  "preferredArtisticMedium": "What's their favorite artistic medium?",
  "signatureArtStyle": "Do they have a recognizable art style?",
  "influentialArtistsOrWorks": "Which artists or works have influenced them the most?",
  "favoriteArtisticPeriod": "What is their favorite period or movement in art?",
  "artisticInspirations": "What inspires their creativity?",
  "creativeProcessDescription": "How would they describe their creative process?",
  "originalCreations": "What original creations are they most proud of?",
  "artisticCollaborations": "Have they collaborated with others on creative projects?",
  "artisticCommunityInvolvement": "Are they involved in any artistic communities?",
  "artisticAchievements": "What are their greatest achievements in the arts?",
  "onlinePersona": "How do they present themselves online?",
  "favoriteDigitalPlatforms": "What digital platforms do they use the most?",
  "onlineCommunityEngagement": "How do they interact with online communities?",
  "attitudeTowardsDigitalPrivacy": "How concerned are they about online privacy?",
  "onlineContentPreferences": "What type of content do they like online?",
  "virtualWorldInvolvement": "Are they involved in any virtual worlds?",
  "digitalSkillset": "How skilled are they with digital tools or technology?",
  "onlineInfluenceStyle": "How do they influence others online?",
  "digitalCreationInvolvement": "Do they create content or art in the digital space?",
  "viewsOnDigitalEtiquette": "How do they feel about manners and behavior online?",
  "psychicSensitivity": "Do they have any sensitivity to psychic phenomena?",
  "astrologicalBirthChart": "What does their astrological birth chart say about them?",
  "spiritualityPractices": "Do they practice any form of spirituality?",
  "ritualsAndTraditions": "Do they follow any rituals or traditions?",
  "connectionToTheSupernatural": "Do they have a connection to supernatural entities or forces?",
  "esotericKnowledge": "How much do they know about esoteric or hidden knowledge?",
  "mysticSymbolsAffinity": "Are they drawn to mystic symbols or objects?",
  "occultInterests": "Are they interested in the occult or mysticism?",
  "supernaturalExperiences": "Have they had any supernatural experiences?",
  "beliefInMythsAndLegends": "Do they believe in myths or legends?",
  "mostMemorableAdventure": "What is their most unforgettable adventure?",
  "dreamExplorationDestination": "Where do they dream of exploring?",
  "survivalExpeditionExperience": "Have they experienced any survival expeditions?",
  "culturalExchangeExperiences": "Have they participated in cultural exchanges?",
  "attitudeTowardsAdventure": "How do they feel about going on adventures?",
  "travelCompanions": "Who do they travel with?",
  "wildernessExplorationSkills": "How skilled are they at surviving in the wilderness?",
  "urbanExplorationInterests": "Are they interested in exploring urban areas or abandoned places?",
  "historicSiteVisits": "Do they enjoy visiting historical sites?",
  "extremeSportParticipation": "Have they participated in extreme sports?",
  "idealFutureWorld": "What is their vision for the future world?",
  "personalRoleInFutureSociety": "What role do they think they'll play in the future?",
  "futureTechnologyFascination": "Are they fascinated by future technologies?",
  "visionForHumanitysFuture": "What is their vision for the future of humanity?",
  "dreamInventionsOrInnovations": "What invention or innovation would they love to see in the future?",
  "predictionsForFutureTrends": "What future trends do they predict?",
  "hopeForFutureGenerations": "What hopes do they have for future generations?",
  "personalLegacyAspirations": "How do they want to be remembered?",
  "dreamOfFutureAchievements": "What do they dream of achieving in the future?",
  "futureChallengesAnticipation": "What challenges do they anticipate facing in the future?"
}

export const storyWorldAboutExamples: EditorAboutExample = {
  "species": "What species exist?",
  "culture": "What is the culture like?",
  "magic": "How does magic work, who can use it?",
  "location": "Where is it?",
  "economy": "How does the economy function?",
  "factions": "What are the key groups?",
  "climate": "What is the weather like?",
  "religion": "What are the main beliefs?",
  "numberOfContinents": "How many continents?",
  "countriesAndNationsInTheWorld": "What are the nations?",
  "relationshipsBetweenCountriesAndNations": "How do different nations interact?",
  "politicalDynamicsAndStability": "What are the main political issues?",
  "levelOfUrbanization": "How many people live in cities versus rural?",
  "aspectsOfPopularityOrPride": "What brings pride?",
  "societalStructureAndHierarchy": "How is society organized?",
  "climateAndHarshness": "How tough is the climate?",
  "naturalResources": "What resources are available?",
  "sourcesOfEnergy": "Where does energy come from?",
  "weapons": "What weapons are used?",
  "physicalAnomalies": "Are there unique physical traits?",
  "faithAndReligion": "What are the faiths?",
  "food": "What food is eaten?",
  "holidaysAndCelebrations": "What holidays are celebrated?",
  "lawsAndAuthority": "Who makes the laws, and how are they enforced?",
  "systemsOfEducation": "How is education handled?",
  "clothesAndFashion": "What clothes are worn?",
  "popularJobsAndFormsOfWork": "What are the most common jobs or professions?",
  "popularFormsOfEntertainment": "What do people do for fun?",
  "availableTechnologiesAndAdvancement": "How advanced is the technology?",
  "motto": "What's the motto?",
  "funFacts": "Any fun facts?",
  "genre": "What genre is this world?",
  "traditionsAndRituals": "What are the traditions?",
  "artAndAesthetics": "What kind of art is popular?",
  "fashionAndApparel": "What's the fashion style?",
  "philosophicalSchools": "What philosophies exist?",
  "ruler": "Who's in charge?",
  "justiceSystem": "How is justice served?",
  "publicServicesAndUtilities": "What services are provided?",
  "secretSocieties": "Are there secret groups?",
  "propagandaAndInformationControl": "How is info controlled?",
  "intelligenceAndEspionage": "Is there spying?",
  "guildsAndAssociations": "What groups exist?",
  "colonialismAndExpansion": "Are they expanding?",
  "socialMobility": "Can people move up in society?",
  "inventionsAndInnovations": "What new things are invented?",
  "technologicalConstraints": "Any tech limits?",
  "economyAndTrade": "How does trade work?",
  "resourceScarcity": "Are resources scarce?",
  "currencyAndValuation": "What currency is used?",
  "environmentalConservation": "How is the environment protected?",
  "historyAndLore": "What's the world's history?",
  "howTimePasses": "How is time tracked?",
  "timekeepingAndCalendars": "What calendar system is used?",
  "explorationAndDiscovery": "Is there exploration?",
  "adventureAndQuests": "What quests exist?",
  "languagesAndDialects": "What languages are spoken?",
  "communicationNetworks": "How do people communicate?",
  "surveillanceAndPrivacy": "How is privacy handled?",
  "recreationAndSports": "What sports are played?",
  "tourism": "Is there tourism?",
  "touristAppeal": "What attracts tourists?",
  "festivalsAndCompetitions": "What festivals are held?",
  "diplomaticRelations": "How do countries deal with each other?",
  "scienceAndResearch": "What science is studied?",
  "spaceAndAstronomy": "What's known about space?",
  "conflictAndResolution": "How are conflicts solved?",
  "transportationSystems": "How do people get around?",
  "traffic": "How do people get around? Is there a lot of traffic?",
  "characteristicInWorldShapeOrForm": "What defines the shape or form of the world?",
  "predominantLandscape": "What's the main landscape?",
  "uniqueGeologicalFormations": "What unique landforms or geological features stand out?",
  "floraAndFaunaDiversity": "What plants and animals exist?",
  "naturalWonders": "What natural wonders exist?",
  "seasonalVariations": "How do seasons change?",
  "weatherPatterns": "What are the common weather patterns?",
  "naturalDisastersFrequency": "How often do disasters happen?",
  "biodiversityLevels": "How diverse is the wildlife?",
  "rareNaturalPhenomena": "Any rare natural events?",
  "waterBodiesLakesRiversOceans": "What bodies of water exist?",
  "socialEtiquetteAndCustoms": "What are the social norms?",
  "culturalTaboosAndSuperstitions": "What are the taboos?",
  "commonCulturalMisconceptions": "What do people get wrong about the culture?",
  "publicCelebrationsAndMourningCustoms": "How are celebrations and mourning handled?",
  "culturalSymbolsAndIcons": "What symbols represent the culture?",
  "storytellingAndFolkloreTraditions": "What are the storytelling traditions?",
  "commonMythsAndLegends": "What myths and legends exist?",
  "attitudesTowardOutsiders": "How do people treat outsiders?",
  "culturalDressAndAttire": "What clothes do people wear?",
  "popularCulturalBeliefs": "What beliefs are widespread?",
  "architecturalStyles": "What style of buildings are there?",
  "landmarkStructures": "What landmarks are notable?",
  "housingAndLivingConditions": "How do people live?",
  "publicSpacesAndParks": "What parks and public areas are there?",
  "majorConstructionMaterials": "What materials are used for building?",
  "infrastructureWonders": "What are the engineering marvels?",
  "cityPlanningAndLayout": "How are cities planned?",
  "historicalSites": "What historical sites are important?",
  "undergroundStructures": "Are there structures underground?",
  "transportationInnovations": "Any unique transport systems?",
  "healthCareSystem": "How does healthcare work?",
  "publicHealthConcerns": "What are the major health issues?",
  "safetyAndSecurityMeasures": "How is safety maintained?",
  "emergencyResponseSystems": "How are emergencies handled?",
  "wellnessAndFitnessTrends": "What are the fitness trends?",
  "lifeExpectancyAndMortalityRates": "What is the average life expectancy?",
  "commonDiseasesAndTreatments": "What are the common illnesses?",
  "mentalHealthAwareness": "How is mental health treated?",
  "safetyRegulations": "What safety rules exist?",
  "disasterPreparedness": "How prepared is society for disasters?",
  "educationalInstitutions": "What schools and universities exist?",
  "popularFieldsOfStudy": "What subjects are most studied?",
  "knowledgeTransmissionMethods": "How is knowledge passed on?",
  "librariesAndArchives": "Where is knowledge stored?",
  "scholarlyTraditions": "What are the academic traditions?",
  "literacyRates": "How many people can read and write?",
  "educationalInnovations": "What new teaching methods are there?",
  "philosophicalMovements": "What are the key schools of thought?",
  "scientificCommunity": "How organized is science?",
  "intellectualPropertyRights": "How is intellectual property protected?",
  "touristAttractions": "What are the major tourist spots?",
  "leisureActivitiesAndHobbies": "What do people do in their free time?",
  "recreationalFacilities": "What facilities exist for leisure?",
  "adventureSports": "What extreme sports are popular?",
  "localDelicaciesAndCuisine": "What local foods are famous?",
  "nightlifeAndEntertainment": "What is the nightlife like?",
  "shoppingAndMarkets": "Where do people shop?",
  "spaAndWellnessCenters": "What wellness centers exist?",
  "culturalFestivals": "What festivals are unique to the culture?",
  "touristInfrastructure": "How developed is tourism?",
  "conservationEfforts": "How is the environment protected?",
  "sustainablePractices": "What eco-friendly practices are followed?",
  "endangeredSpeciesProtection": "How are endangered species protected?",
  "environmentalPolicies": "What environmental rules are in place?",
  "ecologicalAwarenessCampaigns": "How is ecology promoted?",
  "renewableEnergySources": "What renewable energy sources are used?",
  "wasteManagement": "How is waste disposed of?",
  "urbanGreenSpaces": "How much green space is in cities?",
  "wildlifeSanctuaries": "What areas are dedicated to wildlife?",
  "climateChangeInitiatives": "What efforts address climate change?",
  "planetarySystems": "What planets exist in this world?",
  "alienSpeciesAndCultures": "Are there alien races?",
  "spaceTravelMethods": "How do people travel through space?",
  "interstellarPolitics": "How are politics managed between planets?",
  "spaceColoniesAndHabitats": "Where are space colonies located?",
  "extraterrestrialEcosystems": "What are alien ecosystems like?",
  "cosmicPhenomena": "What cosmic events are important?",
  "spaceExplorationMissions": "What missions explore space?",
  "interplanetaryTrade": "How is trade between planets handled?",
  "alienTechnology": "What alien technology is used?",
  "mythicalCreatures": "What mythical creatures exist?",
  "enchantedLocations": "Where are magical or enchanted places?",
  "legendaryArtifacts": "What powerful artifacts exist?",
  "ancientProphecies": "What prophecies shape the world?",
  "magicalSchoolsAndOrders": "What magic schools or groups exist?",
  "sacredSitesAndTemples": "What sacred places are revered?",
  "darkForestsAndForbiddenLands": "What dangerous or off-limits areas exist?",
  "elementalRealms": "Are there realms tied to elements?",
  "mysticalTraditions": "What mystical practices are followed?",
  "cursedObjectsAndPlaces": "What items or locations are cursed?",
  "communityOrganizations": "What groups help the community?",
  "volunteerismAndCharity": "How do people contribute to society?",
  "socialMovements": "What movements are changing society?",
  "grassrootsInitiatives": "What local initiatives are important?",
  "communityCelebrations": "How does the community celebrate?",
  "localHeroesAndPersonalities": "Who are the important figures locally?",
  "socialNormsAndPractices": "What are the everyday norms?",
  "communityProjects": "What projects unite the community?",
  "neighborhoodDynamics": "How do people interact in neighborhoods?",
  "civicParticipation": "How involved are people in local governance?"
};

export const randomizerExclude = [
  'genre',
  'location',
  'virtualWorldInvolvement',
  'title',
  'weight',
  'Skin (Color, Texture)',
  'politicalViews',
  'family',
  'sexuality',
  'friends',
  'enemies',
  'significantOther',
  'soulMate',
  'personalLegendOrMyth',
  'fandom',
  'relationship',
  'accent',
  'voiceClaim',
  'threatLevel',
  'friendshipDynamics',
  'artisticCollaborations',
  'fullName',
  'realName',
  'pronouns',
  'birthPlace',
  'race',
  'ethnicity',
  'title',
  'gender',
  'birthday',
  'previousNames',
];

export const cacheKeys = {
  myCreationsCharacters: 'my-creations-characters',
  myCreationsFolders: 'my-creations-folders',
  myCreationsWorlds: 'my-creations-worlds',
  homeFeaturedCharacters: 'home-featured-characters',
  homeFeaturedVisuals: 'home-featured-visuals',
  announcements: 'ap-announcement',
  suffixMyCreationsFolderCharacters: '-my-creations-folder-characters',
  suffixMyCreationsFolderWorlds: '-my-creations-folder-worlds',
  suffixSiteMessage: '-site-message',
  suffixSiteCarousel: '-site-carousel',
  prefixUserProfile: 'user-profile-',
};
