import { chAxios } from '@/shared/lib/axios';
import { cacheKeys } from '@/shared/statics/constants';

export async function fetchSiteMessageByName(name: string) {
  const url = `/site-message/?name=${name}`;
  const cacheKey = `${name}${cacheKeys.suffixSiteMessage}`;
  const value = await useChFetch<any>(cacheKey, url, undefined, 300000 * 60);

  return value;
}

export async function fetchSiteCarouselPages(group: string) {
  const url = `/site-carousel/?group=${group}`;
  const cacheKey = `${group}${cacheKeys.suffixSiteCarousel}`;
  const value = await useChFetch<any>(cacheKey, url, undefined, 300000 * 60);

  return value;
}
