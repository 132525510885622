<template>
  <div>
    <Sortable
      id="moodboard"
      class="moodboard d-flex flex-wrap"
      :list="moodboard"
      item-key="url"
      tag="div"
      :options="sortableOptions"
      @end="onUpdate"
    >
      <template #item="{ element, index }">
        <div
          :key="element.url"
          class="one-moodboard position-relative"
          :class="{ editable: editable && moodboard.length > 1 }"
          @click="
            openVisualPreview(element.url, element.caption, element.source, 'Moodboard preview', null, element, index)
          "
        >
          <div v-if="editable && dragEnabled" class="mboard-handle clickable-item-hov">
            <ion-icon id="blab-drag-handle" :icon="reorderThreeOutline" />
          </div>
          <ion-button
            v-if="editable && !dragEnabled"
            color="transparent"
            class="float-circle-btn clickable-item-hov"
            @click.prevent.stop="removeMoodboard(index)"
          >
            <i class="ti-close" />
          </ion-button>
          <img loading="lazy" v-image :src="resizeUpload(element.url, '250x250')" />
        </div>
      </template>
    </Sortable>
    <VisualPreviewModal
      v-model:caption="editedMoodboardCaption"
      v-model:source="editedMoodboardSource"
      :image-url="selectedVisualImg"
      :is-open="openVisualPreviewModal"
      :modal-title="visualPreviewModalTitle"
      :source-user="selectedVisSourceUser"
      :editable="editable"
      :report-user="charAuth"
      @dismiss-modal="closeVisualPreviewModal"
    />
  </div>
</template>

<script lang="ts" setup>
import { reorderThreeOutline } from 'ionicons/icons';
import VisualPreviewModal from '../../../modals/VisualPreviewModal.vue';
import { characterEditorStore } from '@/shared/pinia-store/character-editor';
import { imageStore } from '@/shared/pinia-store/images';
import { resizeUpload } from '@/shared/utils/upload';

const { changeInfoKey } = characterEditorStore();
const { setRemovedImages } = imageStore();

const emit = defineEmits(['removeMoodboard']);

const props = defineProps({
  value: {
    type: Array<any>,
    default: [],
  },
  editable: {
    type: Boolean,
    default: false,
  },
  dragEnabled: {
    type: Boolean,
    default: false,
  },
  charAuth: {
    type: Object,
    default: {},
  },
});

const editedMoodboardCaption = ref('');
const editedMoodboardSource = ref('');
const editedMoodboardId = ref(9999);

const moodboard = toRef(props, 'value');
const editable = toRef(props, 'editable');
const dragEnabled = toRef(props, 'dragEnabled');

const sortableOptions = {
  handle: '.mboard-handle',
  animation: 150,
};
const onUpdate = (e: any) => {
  const toModify = [...moodboard.value];
  toModify.splice(e.newIndex, 0, toModify.splice(e.oldIndex, 1)[0]);
  changeInfoKey(
    'moodboard',
    toModify.filter((item) => item)
  );
};

const selectedVisualImg = ref();
const visualPreviewModalTitle = ref();
const openVisualPreviewModal = ref();
const selectedVisCaption = ref();
const selectedVisSource = ref();
const selectedVisSourceUser = ref();

const openVisualPreview = (
  imgUrl: string,
  caption = '',
  source = '',
  modalTitle = 'Image Preview',
  sourceUser = null,
  moodboard: any,
  index: number
) => {
  if (dragEnabled.value) return;
  selectedVisualImg.value = imgUrl;
  visualPreviewModalTitle.value = modalTitle;
  openVisualPreviewModal.value = true;
  selectedVisCaption.value = caption;
  selectedVisSource.value = source;
  selectedVisSourceUser.value = sourceUser;
  editedMoodboardCaption.value = moodboard.caption;
  editedMoodboardSource.value = moodboard.source;
  editedMoodboardId.value = index;
};

const closeVisualPreviewModal = () => {
  openVisualPreviewModal.value = false;
  selectedVisualImg.value = '';
  selectedVisCaption.value = '';
  selectedVisSource.value = '';
  selectedVisSourceUser.value = null;
  const updatedMoodboard = moodboard.value as any;

  if (editedMoodboardCaption.value) {
    updatedMoodboard[editedMoodboardId.value].caption = editedMoodboardCaption.value;
    editedMoodboardCaption.value = '';
  }

  if (editedMoodboardSource.value) {
    updatedMoodboard[editedMoodboardId.value].source = editedMoodboardSource.value;
    editedMoodboardSource.value = '';
  }
  changeInfoKey('moodboard', updatedMoodboard);
};

const removeMoodboard = (index: number) => {
  emit('removeMoodboard', index);
  setRemovedImages({ urls: [moodboard.value[index].url] });
};
</script>

<style lang="sass" scoped>
.moodboard
  grid-gap: 3px 6px
  .one-moodboard
    cursor: pointer
    width: calc((100% / 3) - 4.5px)
    aspect-ratio: 1
    img
      object-fit: cover
      width: 100%
      height: 100%
      border-radius: 6px
.mboard-handle
  z-index: 2
  position: absolute
  padding: 0.2rem
  cursor: move
  -webkit-user-drag: element
  ion-icon
    font-size: 32px
    background-color: rgba(0,0,0,0.5)
    border-radius: 8px
    color: white !important
.float-circle-btn
  background-color: rgba(0,0,0,0.5)
  --border-radius: 100% !important
  border-radius: 100% !important
  width: 28px
  height: 28px
  --padding: 0
  --padding-start: 0
  --padding-end: 0
  top: 5px
  right: 5px
  position: absolute
  z-index: 1
@media(max-width: 500px)
  .one-moodboard
    cursor: pointer
</style>
