<template>
  <div>
    <router-link :to="editRoute" @click="close">
      <ion-item class="item-container clickable-item-hov">
        <div class="menu-item">
          <small>Edit Information</small>
        </div>
      </ion-item>
    </router-link>
    <router-link
      :to="{ name: 'character-editor', params: { id: character.id }, query: { images: 1 } }"
      @click="close"
    >
      <ion-item class="item-container clickable-item-hov">
        <div class="menu-item">
          <small>Add Images</small>
        </div>
      </ion-item>
    </router-link>
    <ion-item class="item-container clickable-item-hov" @click="dismiss('delete')">
      <div class="menu-item text-danger">
        <small>Delete {{ isDraft ? 'Character Draft' : ' Character' }}</small>
      </div>
    </ion-item>
  </div>
</template>

<script lang="ts" setup>
import { Character } from '@/shared/types/static-types';

const props = defineProps({
  instance: {
    type: Object,
  },
  character: {
    type: Object,
  },
});

const close = () => props.instance?.dismiss();
const character = computed(() => props.character as Character);
const isDraft = computed(() => character.value.type === 'draft');
const editRoute = computed(() => {
  const name = isDraft.value ? 'draft-character-editor' : 'character-editor';
  return { name, params: { id: character.value.id } };
});

const dismiss = (value?: string) => {
  props.instance?.dismiss(value);
};
</script>

<style scoped lang="sass">
.bottom-button
  background-color: var(--ion-color-danger) !important
  color: white
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
</style>
