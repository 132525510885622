<template>
  <div class="px-2 py-3">
    <div class="d-flex align-items-center justify-content-center text-center">
      <p>
        Trait From
        <span
          v-if="loggedInUser.username === charAuth.username"
          class="text-primary clickable-item"
          @click="openInsightModal('open')"
          >{{ quizName }}</span
        >
        <router-link v-else :to="{ name: 'quizzes', query: { quizId: quizId } }" @click="openInsightModal('')">
          {{ quizName }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';
const { user: loggedInUser } = authStore();
const props = defineProps({
  instance: {
    type: Object,
    default: () => {},
  },
  charAuth: {
    type: Object,
    default: () => {},
  },
  quizId: {
    type: String,
    default: '',
  },
  quizName: {
    type: String,
    default: '',
  },
});

const quizName = computed(() => props.quizName);
const openInsightModal = async (val: string) => {
  await props.instance?.dismiss({ value: val });
};
</script>

<style scoped lang="sass">
.pointer
  cursor: pointer
.bottom-button
  background-color: var(--ion-color-primary) !important
  color: white
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem
.trait-clr
  color: black
.dark .trait-clr
  color: white
.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important

hr
  margin-bottom: 0
</style>
