import { ActionTree } from 'vuex';
import { BlabsState } from './types';
import { RootState } from '@/shared/store';

import { Blab, BlabReply, ReactionCounts, UserReaction } from '@/shared/types/static-types';
import {
  createReactionOnBlab,
  createReactionOnBlabReplies,
  deleteReactionOnBlab,
  deleteReactionOnBlabReply,
  updateReactionOnBlab,
  updateReactionOnBlabReply,
} from '@/shared/actions/reactions';
import { getBlabDetails, getNextPage, getPosts } from '@/shared/actions/blabs';
import constants from '@/shared/statics/constants';

const isUserReactedSameReaction = (reaction: string, blab: Blab, isInstant = false): boolean => {
  const { user_reaction } = blab;
  return !!(user_reaction && user_reaction.id && (user_reaction.reaction === reaction || isInstant));
};

const isUserReactedSameReactionOnReply = (reaction: string, blab: BlabReply): boolean => {
  const { user_reaction } = blab;
  return !!(user_reaction && user_reaction.id && user_reaction.reaction === reaction);
};

const isUserReactedAnotherReaction = (reaction: string, blab: Blab): boolean => {
  const { user_reaction } = blab;
  return !!(user_reaction && user_reaction.id && user_reaction.reaction !== reaction);
};

const isUserReactedAnotherReactionOnReply = (reaction: string, blab: BlabReply): boolean => {
  const { user_reaction } = blab;
  return !!(user_reaction && user_reaction.id && user_reaction.reaction !== reaction);
};

const sendReactionToBackend = async (reaction: string, blab: Blab, isInstant = false) => {
  const target = 'blab';
  const { user_reaction, id: blabId = '' } = blab;

  // delete
  if (isUserReactedSameReaction(reaction, blab, isInstant)) {
    return await deleteReactionOnBlab({ reactionId: user_reaction?.id || '' });
  }

  // patch
  if (isUserReactedAnotherReaction(reaction, blab)) {
    return await updateReactionOnBlab({ reactionId: user_reaction?.id || '', reaction });
  }

  // post
  const data = await createReactionOnBlab({ objectId: blabId, target, reaction });
  return data;
};

const sendReplyReactionToBackend = async (reaction: string, blab: BlabReply) => {
  const target = 'blab';
  const { user_reaction, id: blabId = '' } = blab;

  // delete
  if (isUserReactedSameReactionOnReply(reaction, blab)) {
    return await deleteReactionOnBlabReply({ reactionId: user_reaction?.id || '' });
  }

  // patch
  if (isUserReactedAnotherReactionOnReply(reaction, blab)) {
    return await updateReactionOnBlabReply({ reactionId: user_reaction?.id || '', reaction });
  }

  // post
  const data = await createReactionOnBlabReplies({ objectId: blabId, target, reaction });
  return data;
};

const updateReactionsDataForBlab = (reaction: string, blab: Blab, newReaction: UserReaction): Partial<Blab> => {
  const blabData = cloneDeep(blab);
  blabData.reaction_counts = blabData.reaction_counts || { total_count: 0 };
  const totalCount = blabData.reaction_counts.total_count;
  let userReaction = blabData.user_reaction || ({} as UserReaction);
  const prevUserReaction = blabData.user_reaction as UserReaction;

  // editing counts if un-reacting or editing reaction
  if (blabData.user_reaction as UserReaction) {
    blabData.reaction_counts[prevUserReaction.reaction as keyof ReactionCounts]--;
    if (newReaction.reaction in blabData.reaction_counts) {
      blabData.reaction_counts[newReaction.reaction as keyof ReactionCounts]++;
    } else {
      // ignore; newReaction.reaction will never be "ordering" (which requires type string[])
      //  @ts-ignore
      blabData.reaction_counts[newReaction.reaction as keyof ReactionCounts] = 1;
    }
  }
  // if reacting to story for the first time: editing counts & user_reaction
  if (!userReaction.id) {
    blabData.reaction_counts!.total_count = totalCount + 1;
    //  @ts-ignore
    if (!blabData.reaction_counts[newReaction.reaction as keyof ReactionCounts]) {
      //  @ts-ignore
      blabData.reaction_counts[newReaction.reaction as keyof ReactionCounts] = 1;
    } else {
      //  @ts-ignore
      blabData.reaction_counts[newReaction.reaction as keyof ReactionCounts] += 1;
    }
    userReaction = newReaction;
  }

  // if editing reaction
  if (isUserReactedAnotherReaction(reaction, blabData)) {
    blabData.user_reaction!.reaction = reaction;
  }

  // if un-reacting
  if (isUserReactedSameReaction(reaction, blabData) && !newReaction) {
    blabData.reaction_counts!.total_count = totalCount > 0 ? totalCount - 1 : 0;
    blabData.user_reaction = undefined;
  } else {
    blabData.user_reaction = {
      ...userReaction,
      reaction,
    };
  }

  const out = {
    user_reaction: blabData.user_reaction,
    reaction_counts: blabData.reaction_counts,
  };
  return out;
};

const updateReactionsDataForBlabReply = (
  reaction: string,
  blab: BlabReply,
  newReaction: UserReaction
): Partial<BlabReply> => {
  const blabData = cloneDeep(blab);
  blabData.reaction_counts = blabData.reaction_counts || { total_count: 0 };
  const totalCount = blabData.reaction_counts.total_count;
  let userReaction = blabData.user_reaction || ({} as UserReaction);
  const prevUserReaction = blabData.user_reaction as UserReaction;

  // editing counts if un-reacting or editing reaction
  if (blabData.user_reaction as UserReaction) {
    blabData.reaction_counts[prevUserReaction.reaction as keyof ReactionCounts]--;
    if (newReaction.reaction in blabData.reaction_counts) {
      blabData.reaction_counts[newReaction.reaction as keyof ReactionCounts]++;
    } else {
      // ignore; newReaction.reaction will never be "ordering" (which requires type string[])
      //  @ts-ignore
      blabData.reaction_counts[newReaction.reaction as keyof ReactionCounts] = 1;
    }
  }
  // if reacting to story for the first time: editing counts & user_reaction
  if (!userReaction.id) {
    blabData.reaction_counts!.total_count = totalCount + 1;
    //  @ts-ignore
    if (!blabData.reaction_counts[newReaction.reaction as keyof ReactionCounts]) {
      //  @ts-ignore
      blabData.reaction_counts[newReaction.reaction as keyof ReactionCounts] = 1;
    } else {
      //  @ts-ignore
      blabData.reaction_counts[newReaction.reaction as keyof ReactionCounts] += 1;
    }
    userReaction = newReaction;
  }

  // if editing reaction
  if (isUserReactedAnotherReactionOnReply(reaction, blabData)) {
    blabData.user_reaction!.reaction = reaction;
  }

  // if un-reacting
  if (isUserReactedSameReactionOnReply(reaction, blabData) && !newReaction) {
    blabData.reaction_counts!.total_count = totalCount > 0 ? totalCount - 1 : 0;
    blabData.user_reaction = undefined;
  } else {
    blabData.user_reaction = {
      ...userReaction,
      reaction,
    };
  }

  const out = {
    user_reaction: blabData.user_reaction,
    reaction_counts: blabData.reaction_counts,
  };
  return out;
};

export const actions: ActionTree<BlabsState, RootState> = {
  async updateSunshineStatus({ commit }, { isSunshine, sunshineId }) {
    commit('UPDATE_SUN_SHINE_STATUS', { isSunshine, sunshineId });
  },
  async getBlab({ commit }, id: string) {
    const inActionBlab = await getBlabDetails(id);
    commit('SET_IN_ACTION_BLAB', inActionBlab);
    return inActionBlab;
  },

  async getTrendingPosts({ commit }, { page = 1, pageSize = constants.defaultPageSize, randomize = false }) {
    const { results, ...paging } = await getPosts(page, pageSize, {
      ordering: '-hot_score',
      ...(randomize && { randomize: true }),
    });
    commit('SET_TRENDING_POSTS', { results, paging });
  },

  async trendingPostsNextPage({ commit, state }) {
    const { trendingBlabsPaging } = state;
    const { results, ...paging } = await getNextPage(trendingBlabsPaging);
    commit('APPEND_TRENDING_POSTS', { results, paging });
  },

  async getTopPosts({ commit }, { page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getPosts(page, pageSize, {
      ordering: '-hot_score,-reaction_count',
      home_trending: 1,
    });
    commit('SET_TOP_POSTS', { results, paging });
  },

  async topPostsNextPage({ commit, state }) {
    const { topBlabsPaging } = state;
    const { results, ...paging } = await getNextPage(topBlabsPaging);
    commit('APPEND_TOP_POSTS', { results, paging });
  },

  async getLatestPosts({ commit }, { page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getPosts(page, pageSize, { ordering: '-created' });
    commit('SET_LATEST_POSTS', { results, paging });
  },

  async latestPostsNextPage({ commit, state }) {
    const { latestBlabsPaging } = state;
    const { results, ...paging } = await getNextPage(latestBlabsPaging);
    commit('APPEND_LATEST_POSTS', { results, paging });
  },

  async getArtCategoryPosts({ commit }, { page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getPosts(page, pageSize, { category: 'art', ordering: '-created' });
    commit('SET_ART_POSTS', { results, paging });
  },

  async artPostsNextPage({ commit, state }) {
    const { artBlabsPaging } = state;
    const { results, ...paging } = await getNextPage(artBlabsPaging);
    commit('APPEND_ART_POSTS', { results, paging });
  },
  async getStoriesCategoryPosts({ commit }, { page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getPosts(page, pageSize, { category: 'story', ordering: '-created' });
    commit('SET_STORIES_POSTS', { results, paging });
  },

  async storiesPostsNextPage({ commit, state }) {
    const { storiesBlabsPaging } = state;
    const { results, ...paging } = await getNextPage(storiesBlabsPaging);
    commit('APPEND_STORIES_POSTS', { results, paging });
  },

  async react({ commit }, { reaction, blab, isInstant = false }: { reaction: string; blab: Blab; isInstant: boolean }) {
    const newReaction = await sendReactionToBackend(reaction, blab, isInstant);
    const updatedReactionsData = updateReactionsDataForBlab(reaction, blab, newReaction);
    commit('APPLY_REACTION_ON_BLABS', { blab, updatedReactionsData });
    return { newReaction, updatedReactionsData };
  },

  async reactBlabReply({ commit }, { reaction, blab }: { reaction: string; blab: BlabReply }) {
    const newReaction = await sendReplyReactionToBackend(reaction, blab);
    const updatedReactionsData = updateReactionsDataForBlabReply(reaction, blab, newReaction);
    return { newReaction, updatedReactionsData };
  },
};
