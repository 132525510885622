import { announcementCheckInterval } from './../statics/constants';
import { defineStore, storeToRefs } from 'pinia';
import { getLatestAnnouncement } from '@/shared/actions/announcement';
import { authStore } from './auth';

type Announcement = {
  id: string;
  text: string;
  datetime: string;
};

export interface IAnnouncementState {
  _currentAnnouncement: Announcement | null;
  _lastDismissedAnnouncementID: string;
  _announcementLastCheckedDateTime: number;
}

export const useAnnouncement = defineStore('announcement', {
  persist: { enabled: true },
  state: (): IAnnouncementState => ({
    _currentAnnouncement: null,
    _lastDismissedAnnouncementID: '',
    _announcementLastCheckedDateTime: 0,
  }),
  actions: {
    dismiss() {
      if (!this._currentAnnouncement) return;

      this._lastDismissedAnnouncementID = this._currentAnnouncement.id;
      this._currentAnnouncement = null;
      this._announcementLastCheckedDateTime = 0;
    },
    async getCurrentAnnouncement() {
      const { isAuthenticated } = authStore();

      if (!isAuthenticated.value) return;

      try {
        const announcement = await getLatestAnnouncement();
        if (!this._currentAnnouncement) {
          if (!announcement) return;

          if (this._lastDismissedAnnouncementID === announcement.value.id) return;

          this._currentAnnouncement = announcement.value;
          this._announcementLastCheckedDateTime = +new Date();

          return;
        }

        if (JSON.stringify(this._currentAnnouncement) !== JSON.stringify(announcement)) {
          this._currentAnnouncement = announcement.value;
        }

        if (+new Date() - this._announcementLastCheckedDateTime > announcementCheckInterval) {
          this.dismiss();
        }
      } catch (e) {}
    },
  },
  getters: {
    currentAnnouncement(): any {
      return this._currentAnnouncement;
    },
  },
});

export const announcementStore = () => {
  const store = useAnnouncement();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
