<template>
  <div class="modal h-100" :class="{ dynamic }">
    <ion-header v-if="!dynamic" translucent>
      <ion-toolbar v-if="isCropModal">
        <div class="d-flex align-items-center justify-content-between px-3">
          <div class="d-flex align-items-center">
            <ion-buttons slot="start">
              <ion-button class="close-button" @click="dismissModal">
                <i class="ti-close" />
              </ion-button>
            </ion-buttons>
            <div class="title">Select Images</div>
          </div>

          <ion-button class="save-btn" @click="saveModal" :disabled="isImageSaving">
            <i class="ti-save mx-1" />
            <ChLoading size="sm" v-if="isImageSaving" class="spinner" />
            <span v-else>Save changes</span>
          </ion-button>
        </div>
      </ion-toolbar>
      <ion-toolbar v-else>
        <ion-title class="title">{{ outputTitle }}</ion-title>
        <ion-buttons slot="end">
          <ion-button v-if="!backAndDone" class="close-button" @click="dismissModal">
            <i class="ti-close" />
          </ion-button>
          <ion-button
            v-else
            class="done-btn mr-3"
            :disabled="loading"
            color="primary"
            fill="solid"
            @click="dismissModal('done')"
          >
            <ChLoading size="sm" v-if="loading" />
            <div v-else>Done</div>
          </ion-button>
        </ion-buttons>
        <ion-buttons v-if="backAndDone" slot="start">
          <ion-button class="close-button" @click="dismissModal">
            <i class="ti-arrow-left" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="modal-content">
      <slot />
    </div>
  </div>
</template>
<script lang="ts" setup>
import store from '@/shared/store';

const props = defineProps({
  isCropModal: { default: false },
  instance: { default: () => {} },
  data: { default: () => {} },
  backAndDone: { default: false },
  loading: { default: false },
  isImageSaving: { default: false },
});

const instance = computed(() => props.instance as any);
const data = computed(() => props.data as any);
const backAndDone = computed(() => props.backAndDone);
const loading = computed(() => props.loading);

const emits = defineEmits(['dismissModal', 'save']);

const outputTitle = computed(() => {
  const currentModal = store.getters['ModalsModule/currentModal'] || { data: {} };
  return data.value?.title ? data.value?.title : currentModal.title;
});

const dynamic = computed(() => {
  const currentModal = store.getters['ModalsModule/currentModal'] || {};
  return currentModal.dynamic;
});

async function dismissModal(data: string) {
  if (instance.value) {
    await instance.value.dismiss();
  }
  emits('dismissModal', data);
}
async function saveModal() {
  emits('save');
}
</script>
<style scoped lang="sass">
.save-btn
  --background: transparent !important
  border-radius: 20px !important
  border: 2px solid  #00B4C5
  height: 30px

.save-btn::part(native)
    color: #00B4C5 !important
    font-weight: bold
.done-btn
  --border-radius: 10px !important
  text-transform: unset
.close-button
  width: 25px
  height: 25px
  border-radius: 18px
  overflow: hidden
.title
  color: #214163
  letter-spacing: 0 !important
  font-size: 16px
  text-align: center
</style>
