import { nanoid } from 'nanoid';
import { characterAboutKeys, qualitiesGroups, statsKeys } from '@/shared/statics/constants';

export const formatCharacterAboutIn = (about: any, aboutConfig: any) => {
  // Used when loading in characterInfo.about data for frontend form use
  if (!about || !aboutConfig) return [];

  if (about && !aboutConfig) {
    // if aboutConfig is not provided, use a default, but ensure that 'description' is first
    const keys = Object.keys(about);
    const index = keys.indexOf('description');
    if (index > -1) keys.unshift(keys.splice(index, 1)[0]);
    return keys.map((key) => {
      return {
        key,
        value: about[key],
        isCustom: !flatten(Object.values(characterAboutKeys)).includes(key) || false,
        id: aboutConfig[key]?.id || nanoid(8),
      };
    });
  }
  const keys = Object.keys(aboutConfig);
  const sortedKeys = keys.sort((a, b) => {
    return aboutConfig[a].orderNum - aboutConfig[b].orderNum;
  });
  return sortedKeys.map((key) => {
    return {
      key,
      value: about[key],
      isCustom: !flatten(Object.values(characterAboutKeys)).includes(key) || false,
      id: aboutConfig[key]?.id || nanoid(8),
    };
  });
};

export const formatCharacterQualitiesIn = (qualities: any, quantitiesConfig: any) => {
  // Used when loading in characterInfo.qualities data for frontend form use
  if (!qualities || !quantitiesConfig) return [];
  if (qualities && !quantitiesConfig) {
    // if quantitiesConfig is not provided, use a default, but ensure that 'description' is first
    const keys = Object.keys(qualities);
    const index = keys.indexOf('description');
    if (index > -1) keys.unshift(keys.splice(index, 1)[0]);

    return keys.map((key) => {
      return {
        key,
        ...qualities[key],
        isCustom: !flatten(Object.values(qualitiesGroups).map((i) => Object.keys(i))).includes(key) || false,
        id: quantitiesConfig[key]?.id || nanoid(8),
      };
    });
  }
  const keys = Object.keys(quantitiesConfig);
  const sortedKeys = keys.sort((a, b) => {
    return quantitiesConfig[a].orderNum - quantitiesConfig[b].orderNum;
  });
  return sortedKeys.map((key) => {
    return {
      key,
      ...qualities[key],
      isCustom: !flatten(Object.values(qualitiesGroups).map((i) => Object.keys(i))).includes(key) || false,
      id: quantitiesConfig[key]?.id || nanoid(8),
    };
  });
};

export const formatCharacterAboutOut = (about: any[]) => {
  // Used before saving data to characterInfo.about
  const aboutObj = {} as any;
  const aboutConfig = {} as any;
  about.forEach((elem, index) => {
    aboutConfig[elem.key] = { orderNum: index + 1, id: elem.id };
    aboutObj[elem.key] = elem.value;
  });
  return { about: aboutObj, config: aboutConfig };
};

export const formatCharacterQualitiesOut = (qualities: any[]) => {
  const qualitiesObj = {} as any;
  const qualitiesConfig = {} as any;
  qualities.forEach((elem, index) => {
    qualitiesConfig[elem.key] = { orderNum: index + 1, id: elem.id };
    const { value, left, right } = elem;
    qualitiesObj[elem.key] = { value, left, right };
  });
  return { qualities: qualitiesObj, config: qualitiesConfig };
};

export const formatStatsIn = (stats: any, statsConfig: any) => {
  if (!stats || !statsConfig) return [];
  if (stats && !statsConfig) {
    // if statsConfig is not provided, use a default
    const keys = Object.keys(stats);
    return keys.map((key) => {
      return {
        key,
        value: stats[key].value,
        maxvalue: stats[key].maxvalue,
        displayType: stats[key].displayType,
        isCustom: !flatten(Object.values(statsKeys)).includes(key) || false,
        id: statsConfig[key]?.id || nanoid(8),
      };
    });
  }
  const keys = Object.keys(statsConfig);
  const sortedKeys = keys.sort((a, b) => {
    return statsConfig[a].orderNum - statsConfig[b].orderNum;
  });
  return sortedKeys.map((key) => {
    return {
      key,
      isCustom: !flatten(Object.values(statsKeys)).includes(key) || false,
      id: statsConfig[key]?.id || nanoid(8),
      value: stats[key]?.value,
      maxvalue: stats[key]?.maxvalue,
      displayType: stats[key]?.displayType,
    };
  });
};
export const formatStatsOut = (stats: any[]) => {
  const statsObj = {} as any;
  const statsConfig = {} as any;
  stats.forEach((elem, index) => {
    statsConfig[elem.key] = { orderNum: index + 1, id: elem.id };
    statsObj[elem.key] = {
      value: elem.value,
      maxvalue: elem.maxvalue,
      displayType: elem.displayType,
    };
  });
  return { stats: statsObj, config: statsConfig };
};

export async function sha256(message: string) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}
