<template>
  <div class="im-wrap" :class="{ [size || '']: size, 'position-relative': setRingPosition }">
    <ProfileRing
      :image="ring"
      :borderWidth="ringWidth"
      :ringTop="top"
      :ringLeft="left"
      :class="setRingPosition ? 'position-absolute' : 'mx-auto d-flex align-items-center justify-content-center'"
    />
    <div
      class="position-relative comment-poster-img mx-auto d-flex align-items-center justify-content-center"
      :class="{ [type || '']: type }"
    >
      <img loading="lazy" v-character-card-image :src="src" />
      <div class="switching" v-if="switching">
        <i class="ti-reload" />
      </div>
    </div>

    <div class="comment-type mt-1" :class="{ [type]: type }" v-if="size !== 'sm' && size !== 'more-sm'">
      {{ toUpper(type || '') }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import { mainStore } from '@/shared/pinia-store/main';
import { textColorChanged } from '@/shared/utils/textConverter';
import ProfileRing from '@/shared/components/ProfileRing.vue';

defineProps({
  type: {
    type: String,
  },
  setRingPosition: {
    type: Boolean,
  },
  ring: {
    type: String,
  },
  switching: {
    type: Boolean,
    default: false,
  },
  src: {
    type: String,
  },
  ringWidth: {
    type: Number,
  },
  top: {
    type: Number,
  },
  size: {
    type: String,
  },
  left: {
    type: Number,
  },
});

const { dark: isDark } = mainStore();

const defaultPageRoute = computed(() => {
  try {
    const router = useRouter();
    return router.currentRoute.value.name;
  } catch (error) {
    return '';
  }
});

const { currentTemplate, customizeCharacter } = characterStylizeStore();

const customizeChar = computed(() => {
  return customizeCharacter.value;
});

const getBackground = computed(() => {
  if (
    ['character-profile-new', 'character-profile-draft-new', 'character-stylize', 'character-draft-stylize'].includes(
      defaultPageRoute.value as string
    )
  ) {
    return isEmpty(get(customizeChar.value, 'template_applied')) &&
      isDark.value &&
      ['character-profile-draft-new', 'character-profile-new'].includes(defaultPageRoute.value as string)
      ? '#010123 !important'
      : `${get(currentTemplate.value, 'data.theme.backgrounds.page')} !important`;
  }
  return 'rgba(0,0,0,0)';
});

const defaultTextColor = computed(() => {
  const defaultClr = isDark.value ? '#FFFFFF' : '#214163';

  if (
    !['character-profile-new', 'character-profile-draft-new', 'character-stylize', 'character-draft-stylize'].includes(
      defaultPageRoute.value as string
    )
  ) {
    return defaultClr;
  }

  const background = getBackground.value?.replace(' !important', '');

  if (!background) return defaultClr;

  return textColorChanged(background);
});
</script>

<style lang="sass" scoped>
.character
  font-size: 8px !important
.switching
  width: 20px
  z-index: 11
  height: 20px
  font-size: 10px
  background: rgba(10, 9, 40, 0.8)
  position: absolute
  right: 3px
  bottom: 3px
  display: flex
  align-items: center
  justify-content: center
  border-radius: 50%
  color: #FFF
.im-wrap
  height: fit-content
.pre-md
  .comment-poster-img
    width: 40px !important
    max-width: 40px !important
    min-height: 40px !important
    img
      &:not(.mod-small-img)
        width: 40px !important
        height: 40px !important
        max-width: 40px !important
    &.user
      border-radius: 50% !important
.more-sm
  .comment-poster-img
    width: 35px !important
    max-width: 35px !important
    min-height: 35px !important
    img
      &:not(.mod-small-img)
        width: 35px !important
        height: 35px !important
        max-width: 35px !important
    &.user
      border-radius: 50% !important
.md
  .comment-poster-img
    width: 50px
    max-width: 50px
    min-height: 50px
    img
      &:not(.mod-small-img)
        width: 50px
        height: 50px
        max-width: 50px
    &.user
      border-radius: 50% !important
.sm
  .comment-poster-img
    width: 25px
    max-width: 25px
    min-height: 25px
    img
      &:not(.mod-small-img)
        width: 25px
        height: 25px
        max-width: 25px
    &.user
      border-radius: 50% !important
.cm
  .comment-poster-img
    width: 60px
    max-width: 60px
    min-height: 60px
    img
      &:not(.mod-small-img)
        width: 60px
        height: 60px
        max-width: 60px
    &.user
      border-radius: 50% !important
.comment-type
  font-weight: bold
  font-size: 9px
  text-align: center
  color: v-bind(defaultTextColor)

.comment-poster-img
  border-radius: 50%
  overflow: hidden
  object-fit: cover
  background: rgba(172, 191, 212, 1)
  width: 60px
  max-width: 60px
  min-height: 60px
  img
    width: 60px
    height: 60px
    max-width: 60px
  &.user
    border-radius: 50% !important
</style>
