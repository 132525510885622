import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import authorize_45global from "/app/middleware/authorize.global.ts";
import header_45global from "/app/middleware/header.global.ts";
import hydrate_45user_45global from "/app/middleware/hydrate-user.global.ts";
import public_45global from "/app/middleware/public.global.ts";
import reset_45pass_45validation_45global from "/app/middleware/reset-pass-validation.global.ts";
import slash_45global from "/app/middleware/slash.global.ts";
import ui_45global from "/app/middleware/ui.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authorize_45global,
  header_45global,
  hydrate_45user_45global,
  public_45global,
  reset_45pass_45validation_45global,
  slash_45global,
  ui_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}