<template>
  <client-only>
    <ion-content class="ch-modal" ref="modal" @willDismiss="dismissModal" scroll-y="false">
      <div class="title-bar">
        <div class="closer" />
        <div class="title">
          {{ title }}
        </div>
      </div>
      <div class="view-content" @touchmove.stop><CharacterProfile :charSlug="characterSlug" /></div>
    </ion-content>
  </client-only>
</template>

<script lang="ts" setup>
import { modalController } from '@ionic/vue';
import CharacterProfile from '@/shared/pages/character-profile-new/index.vue';

const props = defineProps({
  characterSlug: { type: String },
  title: { type: String },
});

const characterSlug = toRef(props, 'characterSlug');
const title = toRef(props, 'title');

const modal = ref(null);

const dismissModal = () => {
  modalController.dismiss(null, 'cancel');
};

onMounted(() => {});
</script>

<style lang="sass" scoped>
.submit-btn
  width: 180px
  .spinner
    width: 15px
    height: 15px

ion-modal
  @media(max-width: 599px) and (max-height: 767px)
    --height: 85%
    --width: 85%
</style>
