import { isMobileFn } from '@/apps/mobile/native/device';

export default defineNuxtPlugin(async () => {
  const { crispWebsiteID, appType } = useSafeEnv();

  const isMobile = (await isMobileFn()) || appType.value === 'mobile';

  if (isMobile) return;

  // @ts-ignore
  window.$crisp = [];
  // @ts-ignore
  window.CRISP_WEBSITE_ID = crispWebsiteID.value;

  (function () {
    var d = document;
    var s = d.createElement('script');

    s.src = 'https://client.crisp.chat/l.js';

    // @ts-ignore
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);
  })();
});
