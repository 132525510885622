export const imageResizer = (el: HTMLImageElement, binding: { value: any }) => {
  try {
    const img = new Image();

    const original = el.src;

    img.src = original;

    img.onload = () => {
      const { naturalWidth, naturalHeight } = el;
      const aspectRatio = naturalWidth / naturalHeight;
      const isLandscape = aspectRatio > 1;
      const isSquare = aspectRatio === 1;
      const isPortrait = aspectRatio < 1;

      const wrapper = document.querySelector('.swiper-wrapper');

      if (!wrapper) return;

      const { clientWidth: maxWidth, clientHeight: maxHeight } = wrapper;

      const wrapperAspectRatio = maxWidth / maxHeight;

      const isWrapperLandscape = wrapperAspectRatio > 1;

      if (isWrapperLandscape) {
        const safeWidth = maxWidth > naturalWidth ? `${naturalWidth}px` : '100%';
        const safeHeight = maxHeight > naturalHeight ? `${naturalHeight}px` : '100%';

        if (isLandscape) {
          el.style.width = safeWidth;
          el.style.height = 'auto';
        }

        if (isPortrait || isSquare) {
          el.style.height = safeHeight;
          el.style.width = 'auto';
        }
      }
    };
  } catch (e) {
    // Do nothing
  }
};
