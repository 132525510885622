import { sendAnalyticsEvent } from '@/shared/services/analytics';
import constants from '@/shared/statics/constants';
import type { Paging } from '@/shared/types/static-types';
import { chAxios } from '@/shared/lib/axios';

export async function createBlab(payload: {
  title?: string;
  description: string;
  location?: string;
  is_nsfw?: boolean;
  user?: string;
  category?: string;
  visuals: string[];
  tags?: string[];
  character?: string;
  featured_characters?: string[];
}) {
  try {
    const { data } = await chAxios().post(`/blabs/`, payload);
    sendAnalyticsEvent('Created Blab');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteBlab(id: string) {
  try {
    const { data } = await chAxios().delete(`/blabs/${id}/`);
    sendAnalyticsEvent('Deleted Blab');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function editBlabReply(
  id: string,
  payload: {
    title?: string;
    text?: string;
  }
) {
  const url = `/blabs/replies/${id}/`;
  try {
    const { data } = await chAxios().patch(url, payload);
    sendAnalyticsEvent('Updated Blab Reply');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteBlabReply(id: string) {
  try {
    const { data } = await chAxios().delete(`/blabs/replies/${id}/`);
    sendAnalyticsEvent('Deleted Blab Reply');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getBlabDetails(postId: string) {
  try {
    const { data } = await chAxios().get(`/blabs/${postId}/`);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getFeaturedUserPosts(userId?: string, page = 1, pageSize = constants.defaultPageSize) {
  const url = `/blabs/featured-blabs/`;
  try {
    const { data } = await chAxios().get(url, { params: { user_id: userId, page, page_size: pageSize } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getBlabsReactions(page = 1, pageSize = constants.defaultPageSize) {
  const url = `/blabs/reacted/`;
  try {
    const { data } = await chAxios().get(url, { params: { page, page_size: pageSize } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function blabReplyMessage(
  blabId: string,
  replyTitle: string,
  replyText: string,
  characterId: string,
  parentReply?: string | null | undefined
) {
  const url = `/blabs/replies/send-reply/`;
  try {
    const { data } = await chAxios().post(url, {
      blab: blabId,
      title: replyTitle,
      text: replyText,
      reply_as_char: characterId,
      parent_reply: parentReply,
    });
    sendAnalyticsEvent('Replied to Blab');
    return data;
  } catch (e) {
    throw e;
  }
}
export async function blabUserReplyMessage(
  blabId: string,
  replyTitle: string,
  replyText: string,
  parentReply?: string | null | undefined
) {
  const url = `/blabs/replies/send-reply/`;
  try {
    const { data } = await chAxios().post(url, {
      blab: blabId,
      title: replyTitle,
      text: replyText,
      parent_reply: parentReply,
    });
    sendAnalyticsEvent('Replied to Blab');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function blabSunShineReply(blabId: string, replyid: string) {
  const url = `/blabs/replies/sunshine-reply/`;
  try {
    const { data } = await chAxios().post(url, { blab: blabId, reply: replyid });
    sendAnalyticsEvent('Sunshined a Reply');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getBlabReplyofReplies(replyId?: string, page = 1) {
  const url = `/blabs/replies/${replyId}/get-blab-reply-of-relies/`;
  try {
    const { data } = await chAxios().get(url, { params: { page } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getSunShineBlabReply(blabIds: string[] | undefined = []) {
  const url = `/blabs/replies/sunshine-replies-of-blab/`;
  try {
    const { data } = await chAxios().post(url, { blab_ids: blabIds });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getCountofReplies(replyIds: string[] | undefined = []) {
  const url = `/blabs/replies/replies-count-of-parent-reply/`;
  try {
    const { data } = await chAxios().post(url, { parent_reply_ids: replyIds });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateSunShineOrder(payload: { blab?: string; reply_order?: string[] }) {
  const url = `/blabs/replies/change-order-sunshined-reply/`;
  try {
    const { data } = await chAxios().patch(url, payload);
    sendAnalyticsEvent('Updated Sunshines Order');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getCountofBlabReplies(blabIds: string[] | undefined = []) {
  const url = `/blabs/replies/replies-count-of-blab/`;
  try {
    const { data } = await chAxios().post(url, { blab_ids: blabIds });
    return data;
  } catch (e) {
    throw e;
  }
}
export async function blabDeleteSunShineReply(replyid: string, blabid: string) {
  const url = `/blabs/replies/delete-sunshined-reply/`;
  try {
    const { data } = await chAxios().delete(url, { data: { reply: replyid, blab: blabid } });
    sendAnalyticsEvent('Deleted Sunshined Reply');
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getBlabReplies(
  blabId: string,
  offset = 0,
  limit = constants.defaultPageSize,
  parentReply?: string | null,
  params = {}
) {
  const url = `/blabs/replies/`;
  try {
    const { data } = await chAxios().get(url, {
      params: { blab: blabId, offset, limit, parent_reply: parentReply, ...params },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getNextPage(paging: Paging) {
  const url = paging.next;
  try {
    const { data } = await chAxios().get(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getPosts(page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/blabs/`;
  try {
    const { data } = await chAxios().get(url, { params: { page, page_size: pageSize, ...params } });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getPostsCount(params = {}) {
  const url = `/blabs/my-posts-count/`;

  const { data } = await chAxios().get(url, { params: { ...params } });
  return data;
}

export async function updateBlab(
  id: string,
  blabData: {
    title?: string;
    description?: string;
    location?: string;
    is_nsfw?: boolean;
    user?: string;
    category?: string;
    visuals: string[];
    tags?: string[];
    character?: string;
  }
) {
  const url = `/blabs/${id}/`;
  try {
    const { data } = await chAxios().patch(url, blabData);
    sendAnalyticsEvent('Updated Blab');
    return data;
  } catch (e) {
    throw e;
  }
}
