<template>
  <div>
    <fwb-dropdown class="this-menu" ref="dropdown" :placement="position" close-inside>
      <template #trigger>
        <slot />
      </template>
      <fwb-list-group class="rounded-lg follow-menu">
        <div v-if="loadingProfilePicture" class="loading d-flex flex-column align-center">
          <div class="px-3 py-2">Uploading</div>
          <ion-progress-bar type="indeterminate"></ion-progress-bar>
        </div>
        <fwb-list-group-item
          @click="openImagePickerModal"
          class="cursor-pointer border-none hover:bg-gray-100 dark:hover:bg-gray-600"
        >
          <div class="item-container pointer">
            <div class="d-flex">
              <i class="ti-layout-grid4-alt" />
              <div class="ml-2 menu-item d-flex align-items-center no-select">Choose a photo</div>
            </div>
          </div>
        </fwb-list-group-item>
        <fwb-list-group-item
          v-if="commitAfterChange ? userImage : !commitAfterChange ? userTempImage : null"
          class="cursor-pointer border-none hover:bg-gray-100 dark:hover:bg-gray-600"
        >
          <div class="item-container d-flex pointer" @click="clearPicture">
            <i class="ti-eraser" />
            <div class="ml-2 menu-item d-flex align-items-center">Clear Profile Picture</div>
          </div>
        </fwb-list-group-item>
      </fwb-list-group>
    </fwb-dropdown>
  </div>
</template>

<script lang="ts" setup>
import { profileStore } from '@/shared/pinia-store/profile';
import { authStore } from '@/shared/pinia-store/auth';
import { modalController } from '@ionic/vue';
import ImageUploadPlusModal from '@/shared/modals/ImageUploadPlusModal.vue';
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from 'flowbite-vue';

const { currentProfile, updateUserProfile } = profileStore();
const { updateCurrentUserTempProfilePicture, userCurrentTempProfilePic, user } = authStore();
const props = defineProps({
  isOnboarding: {
    type: Boolean,
    default: false,
  },
  commitAfterChange: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String,
    default: 'right',
  },
});

const dropdown = ref(null);

const commitAfterChange = toRef(props, 'commitAfterChange');
const loadingProfilePicture = ref(false);
const isOnboarding = toRef(props, 'isOnboarding');
const openImagePickerModal = async () => {
  const modal = await modalController.create({
    component: ImageUploadPlusModal,
    cssClass: 'upload-plus-modal',
    componentProps: {
      isUserProfile: commitAfterChange.value,
      isUserEditProfile: !commitAfterChange.value,
      restrictGif: true,
    },
  });
  await modal.present();
  modal.onDidDismiss().then(() => {
    //
  });
};

const changeUserProfilePicture = async (url: string) => {
  const data: object = {
    profile_picture_url: url,
    original_picture_url: url,
  };
  await updateUserProfile(data);
};

const changeUserEditProfilePicture = async (url: string) => {
  updateCurrentUserTempProfilePicture(url);
};

const userImage = computed(() => {
  return isOnboarding.value ? user.value?.profile_picture_url || '' : currentProfile.value?.profile_picture_url || '';
});

const userTempImage = computed(() => {
  return userCurrentTempProfilePic.value || '';
});

const clearPicture = () => {
  if (commitAfterChange.value) {
    changeUserProfilePicture('');
  } else if (!commitAfterChange.value) {
    changeUserEditProfilePicture('');
  }
};
</script>

<style scoped lang="sass">
ul
  width: 200px
.d-flex
  display: flex
  align-items: center
li
  align-items: center
  justify-content: center
.pointer
  cursor: pointer
.bottom-button
  background-color: var(--ion-color-primary) !important
  color: white
  padding: 10px 15px
  width: 100%
  text-align: center
  border-radius: 12px
  font-size: 0.8rem

.bottom-button:hover
  opacity: 0.8

.bottom-button-container
  padding: 0 0.25rem 0 0.25rem !important

hr
  margin-bottom: 0
.hide
  visibility: hidden
</style>
