<template>
  <client-only>
    <ion-content class="modal-content-self" ref="modal" @willDismiss="dismissModal()" scroll-y="false">
      <ion-header>
        <ion-toolbar>
          <ion-title slot="start" class="text-white"><span class="title">Placing Order</span></ion-title>
          <ion-buttons slot="end">
            <ion-button size="large" @click="dismissModal()">
              <i class="ti-close" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div class="content">
        <section class="info w-100 ml-1" id="items">
          <div class="mx-2">
            <h4 class="text-white">{{ prettifiedItemName }}</h4>
            <h6 class="text-white">Quantity: {{ quantity }}</h6>
            <h3 class="text-white bold" v-if="totalPrice">${{ (totalPrice / 100).toFixed(2) }}</h3>
            <ion-skeleton-text v-else animated />
          </div>
        </section>
        <section class="info" id="scroll-info">
          <div>
            <div class="w-100 d-flex flex-column justify-content-center">
              <div class="px-2">
                <div v-if="!currentElement">
                  <div class="payment-element-loading w-100 mx-auto text-center">
                    <p class="text-white">Loading payment form...</p>
                    <ion-spinner class="mx-auto" />
                  </div>
                </div>
                <div>
                  <div v-show="currentElement" class="mx-auto pbc" id="paypal-button-container"></div>
                </div>
                <div id="payment-element"></div>
              </div>
              <div class="w-100 d-flex justify-content-center mb-5">
                <ion-button
                  v-if="currentElement"
                  size="large"
                  class="mt-4 mb-4 checkout"
                  @click="handleSubmitStripe"
                  :disabled="loading"
                  ><span v-if="loading" class="no-select">Processing... Do not close</span
                  ><span v-else class="no-select">Checkout</span></ion-button
                >
              </div>
            </div>
          </div>
        </section>
      </div>
    </ion-content>
  </client-only>
</template>

<script lang="ts" setup>
import StripeService from '@/shared/services/stripe';
import { loadScript } from '@paypal/paypal-js';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import { modalController } from '@ionic/vue';
import { analyticsSetUser } from '@/shared/services/analytics';
import { COMPLIMENT_BOMB } from '@/shared/statics/constants';
import { createOrderPaypal, captureOrderPaypal } from '@/shared/actions/payments';
const paypalButtonDetails = {
  style: {
    color: 'black',
    borderRadius: 5,
    height: 53,
    tagline: 'false',
  },
  enableFunding: ['credit'],
  disableFunding: ['paylater'],
  createOrder: async (data: any, actions: any) => {
    if (!totalPrice.value) {
      toast.show('Price is still loading. Please try again a moment.', 'nonative', 'danger');
      return;
    }
    collapseStripe();
    loading.value = true;
    try {
      const orderData = await createOrderPaypal(productCode.value, quantity.value);
      if (orderData.id) {
        return orderData.id;
      }
      throw new Error();
    } catch (e) {
      toast.show(`Could not create order. Please try again in a moment.`, 'nonative', 'danger');
    } finally {
      loading.value = false;
    }
  },
  onApprove: async (data: any, actions: any) => {
    const orderId = data.orderID;
    // https://developer.paypal.com/studio/checkout/standard/integrate
    // Three cases to handle:
    //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
    //   (2) Other non-recoverable errors -> Show a failure message
    //   (3) Successful transaction -> Show confirmation or thank you message
    try {
      loading.value = true;
      const orderData = await captureOrderPaypal(orderId);
      if (orderData.success) {
        // (3) Successful transaction -> Show confirmation or thank you message
        toast.show('Transaction complete', 'nonative', 'primary');
        loading.value = false;
        dismissModal(productCode.value);
        return;
      }
      const errorDetail = orderData?.error;

      if (errorDetail?.details?.[0]?.issue === 'INSTRUMENT_DECLINED') {
        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        // recoverable state, per
        // https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
        toast.show('Payment method was declined by Paypal.', 'nonative', 'danger', 10000);
        return actions.restart();
      } else if (errorDetail) {
        // (2) Other non-recoverable errors -> Show a failure message
        toast.show(`Could not complete transaction. ${errorDetail.message}`, 'nonative', 'danger', 10000);
      }
    } catch (error) {
      toast.show(`Could not complete transaction. Please try again in a moment.`, 'nonative', 'danger', 5000);
    } finally {
      loading.value = false;
    }
  },
  onCancel: (data: any) => {
    toast.show('Transaction canceled', 'nonative', 'primary');
  },
  onError: (err: any) => {
    if (err?.message?.includes('#paypal')) return toast.show('Transaction canceled.', 'nonative', 'primary');
    toast.show('Transaction canceled. ' + err?.message, 'nonative', 'primary');
  },
};

const { trackAmplitudeEvent } = useAnalytics();
const {
  public: { stripeKey, paypalClientId },
} = useRuntimeConfig();

const props = defineProps({
  openedFrom: { type: String, default: '' },
  quantity: { type: Number, required: true },
  productCode: { type: String, required: true }, // storeproduct.code
  name: { type: String, required: true }, // storeproduct.name
  postAction: { type: String, required: false }, // storeproduct.post_action
  firstPurchaseOffer: { type: String, required: false },
});
const openedFrom = toRef(props, 'openedFrom');
const quantity = toRef(props, 'quantity');
const productCode = toRef(props, 'productCode');
const name = toRef(props, 'name');
const postAction = toRef(props, 'postAction');
const firstPurchaseOffer = toRef(props, 'firstPurchaseOffer');
let stripe = null as any;
const elements = ref(null) as any;
const currentElement = ref(null) as any;
const stripeService = ref(null) as any;
const loading = ref(true);
const paymentIntentId = ref(null) as any;
const polling = ref(null) as any;
const totalPrice = ref(null) as any;

const dismissModal = (code?: string) => {
  if (currentElement.value) {
    currentElement.value.destroy();
    currentElement.value = null;
  }
  trackAmplitudeEvent('PurchaseModal Closed', { From: openedFrom.value });
  modalController.dismiss(code, 'cancel');
};

const prettifiedItemName = computed(() => {
  if (productCode.value === COMPLIMENT_BOMB) {
    return 'Compliment Bomb';
  }
  return name.value;
});

const runLoad = async () => {
  loading.value = true;
  loadScript({
    clientId: paypalClientId,
    disableFunding: ['paylater', 'card'],
    enableFunding: ['credit'],
  })
    .then((paypal: any) => {
      paypal.Buttons(paypalButtonDetails).render('#paypal-button-container');
    })
    .catch((error) => {
      console.error('paypal script load error', error);
      toast.show('Error loading Paypal form', 'nonative', 'danger');
    });
  stripeService.value = StripeService.getInstance(stripeKey);
  await stripeService.value.load();
  stripe = stripeService.value.getStripe();

  try {
    // Create a checkout session on the server side
    const currentUrl = window.location.href;
    const session = await stripeService.value.initiateItemCheckout(productCode.value, quantity.value, currentUrl);
    paymentIntentId.value = session.payment_intent_id;
    totalPrice.value = session.total_price;
    elements.value = await stripe.elements({
      clientSecret: session.client_secret,
      appearance: {
        theme: 'night',
        variables: {
          colorBackground: 'rgb(44, 46, 47)',
        },
        rules: {
          '.Menu': {
            border: 'none',
            borderColor: 'rgb(44, 46, 47)',
            boxShadow: 'none',
          },
          '.AccordionItem': {
            border: 'none',
            borderColor: 'rgb(44, 46, 47)',
            boxShadow: 'none',
            fontSize: '1.15rem',
            textAlign: 'center',
          },
        },
      },
    });

    const paymentElement = elements.value.create('payment', {
      business: { name: 'CharacterHub' },
      paymentMethodOrder: ['apple_pay', 'google_pay', 'amazon_pay', 'cashapp', 'card'],
      layout: {
        type: 'accordion',
        defaultCollapsed: true,
        radios: false,
        spacedAccordionItems: true,
      },
    });
    paymentElement.mount('#payment-element');
    currentElement.value = paymentElement;
  } catch (error) {
    setTimeout(() => {
      modalController.getTop().then((topModal) => {
        if (topModal) {
          toast.show('Error loading payment form. Please try again in a moment.', 'nonative', 'danger');
        }
      });
    }, 800);
  } finally {
    loading.value = false;
  }
  loading.value = false;
};

const confirmPaymentStripe = async () => {
  if (polling.value) return;
  if (!stripe || !elements.value) {
    // Stripe.js hasn't yet loaded or elements were not created.
    // Make sure to disable form submission until Stripe.js has loaded and elements are created.
    loading.value = false;
    return;
  }
  // Trigger form validation and wallet collection
  const { error: validationError } = await elements.value.submit();
  if (validationError) {
    handleError(validationError);
    loading.value = false;
    return;
  }
  loading.value = true;
  const currentLoc = window.location.pathname + window.location.search;
  let ending2 = `&km_=1&productCode=${productCode.value}&postAction=${postAction.value}`;
  if (firstPurchaseOffer.value) {
    ending2 += `&fpo=${firstPurchaseOffer.value}`;
  }
  const returnUrl = `${stripeService.value.getPurchaseReturnUrl()}?from=${encodeURIComponent(currentLoc)}${ending2}`;
  const confirmParams = {
    return_url: returnUrl,
  } as any;
  const res = await stripe.confirmPayment({
    elements: elements.value,
    redirect: 'if_required',
    confirmParams,
  });
  const confirmError = res?.error;
  if (confirmError) {
    handleError(confirmError);
    loading.value = false;
    return;
  }
  // Start polling after payment is complete
  let count = 0;
  polling.value = setInterval(async () => {
    try {
      const result = await stripeService.value.confirmItemCheckout(paymentIntentId.value);
      if (result) {
        loading.value = false;
        clearInterval(polling.value);
        polling.value = null;
        dismissModal(productCode.value);
      } else if (result === false) {
        count += 1;
        if (count >= 40) {
          alert('Checkout could not be confirmed. Please use our support chat.');
          loading.value = false;
          clearInterval(polling.value);
          polling.value = null;
        }
        return;
        // Handle failed payment here
      }
    } catch (e) {
      handleError(e);
      loading.value = false;
      clearInterval(polling.value);
      polling.value = null;
    }
  }, 3000);
};

const collapseStripe = () => {
  elements.value.getElement('payment')?.collapse();
};

const handleSubmitStripe = async (event: any) => {
  event.preventDefault();
  confirmPaymentStripe();
};

const handleError = (e: any) => {
  let errorMessage = 'There was an error processing your payment. Please try again in a moment.';
  // Provide more descriptive error messages based on Stripe's error types
  if (e.type === 'validation_error') {
    errorMessage = 'Validation error: Your payment information is incorrect. Please verify and try again.';
  } else if (e.type === 'api_connection_error') {
    errorMessage =
      'Connection error: Unable to connect to Stripe. Please check your internet connection and try again.';
  } else if (e.type === 'api_error') {
    errorMessage = 'Server error: A problem occurred with our payment service. Please try again later.';
  } else if (e.type === 'authentication_error') {
    errorMessage =
      'Authentication error: Unable to authenticate your payment method. Please try again or use a different payment method.';
  } else if (e.type === 'rate_limit_error') {
    errorMessage = 'Rate limit error: Too many requests made to the server. Please wait a moment and try again.';
  }
  toast.show(errorMessage, 'nonative', 'danger', 10000);
  loading.value = false;
};

onMounted(() => {
  const { userId } = authStore();
  analyticsSetUser(userId.value);
  trackAmplitudeEvent('PurchaseModal Opened', { From: openedFrom.value });
  runLoad();
});
</script>

<style lang="sass" scoped>
ion-toolbar
  --background: rgba(23, 7, 76, 1)
  color: white
ion-skeleton-text
  --background: rgba(255, 255, 255, 0.065)
ion-title
  --background: rgba(23, 7, 76, 1)
  color: white
.title
  font-family: Roboto Slab, sans-serif
  color: white
.content
  background: rgba(23, 7, 76, 1)
  overflow: hidden
  overflow-y: auto
  height: 100%
.icon
  width: 20px
  height: 20px
  margin-bottom: -4px
.pbc
  margin-bottom: 4px
</style>
