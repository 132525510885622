<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="false" :show-backdrop="true">
    <div class="inner-content d-flex flex-column px-4 py-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="title">Save changes to "{{ truncateText(stripHtmlTags(templateName), 25) }}" before Switching</div>
        <ion-buttons>
          <ion-button class="close-button text-black" @click="dismissModal">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </div>

      <div class="d-flex mt-2">
        <ion-button
          class="submit-btn w-100"
          style="--border-radius: 10px; --background: #faa6cb"
          :disabled="isSubmitting"
          type="submit"
          @click.prevent="dismissModal"
        >
          <span>Cancel</span>
        </ion-button>
        <ion-button
          class="submit-btn w-100 mx-2"
          style="--border-radius: 10px; --background: #00b0c1"
          :disabled="isSubmitting"
          type="submit"
          @click.prevent="saveDesign"
        >
          <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
          <span v-else>Save</span>
        </ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { toast } from '@/shared/native';
import { stripHtmlTags } from '@/shared/utils/string';
import { updateCharTemplate } from '@/shared/actions/characters';
import { truncateText } from '@/shared/utils/string';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';

const { currentTemplate } = characterStylizeStore();
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  templateName: {
    type: String,
    default: '',
  },
  templateId: {
    type: String,
    default: '',
  },
});

const templateId = toRef(props, 'templateId');
const isSubmitting = ref(false);
const emits = defineEmits(['fetch', 'close']);

const dismissModal = () => {
  emits('close');
};

const saveDesign = async () => {
  const payload = {
    data: currentTemplate.value?.data,
  };
  await updateCharTemplate(templateId.value, true, payload);

  await toast.show('Template saved successfully', 'nonative', 'success');
  dismissModal();
};
</script>

<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 22px
.submit-btn
  width: 150px

ion-modal
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 80vh
  overflow: auto
</style>
