<template>
  <ion-modal
    class="gift-modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ModalHeader :modal-title="modalTitle" :showCloseButton="true" @close="dismissModal" />

    <ModalComplimentBombContent :giftCbData="updatedGiftCBomb" @gifted="selected" :selectedIndex="selectedGiftIndex" />

    <ModalFooter v-if="showFooter">
      <div class="d-flex justify-content-center text-center align-items-center">
        <ion-button class="gift-button" :disabled="isTotalCountZero" @click="openModal">
          <ion-icon :icon="giftOutline" class="gift-icon mx-1" />
          Gift
        </ion-button>
      </div>
    </ModalFooter>
  </ion-modal>
</template>

<script lang="ts" setup>
import { giftOutline } from 'ionicons/icons';
import ModalHeader from './ModalComponents/ModalHeader.vue';
import ModalFooter from './ModalComponents/ModalFooter.vue';
import ModalComplimentBombContent from './ModalComponents/ModalComplimentBombContent.vue';
import GiftComplimentBomb from '@/shared/pages/compliment-bombs/components/GiftComplimentBomb.vue';
import constants from '@/shared/statics/constants';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  modalTitle: {
    type: String,
    default: '',
  },
  inventory: {
    type: Object,
    default: () => {},
  },
  giftInventory: {
    type: Object,
    default: () => {},
  },
  showFooter: {
    type: Boolean,
    default: false,
  },
  cbInfoValue: {
    type: String,
    default: '',
  },
});
const emits = defineEmits(['close', 'updated', 'open']);
const isOpen = toRef(props, 'isOpen');
const giftCBomb = constants.giftComplimentBomb;
const giftInventory = toRef(props, 'giftInventory');
const cbInfoValue = toRef(props, 'cbInfoValue');
const inventory = toRef(props, 'inventory');
const selectedIndex = ref(0);

watch(isOpen, () => {
  selectedIndex.value = 0;
});
const isTotalCountZero = computed(() => {
  return isEqual(get(inventory.value, 'amount') + get(giftInventory.value, 'amount'), 0);
});
const selectedGiftIndex = computed(() => {
  return isTotalCountZero.value || !isEmpty(cbInfoValue.value)
    ? null
    : isEqual(updatedGiftCBomb.value[0].count, 0) && updatedGiftCBomb.value[1].count >= 1
    ? 1
    : selectedIndex.value;
});

const selectedGift = computed(() => {
  if (!isNull(selectedGiftIndex.value)) return updatedGiftCBomb.value[selectedGiftIndex.value];
});

const dismissModal = () => {
  emits('close');
};
const openModal = () => {
  emits('open', selectedGift.value);
};

const selected = (val: any) => {
  selectedIndex.value = val;
};
const updatedGiftCBomb = computed(() => {
  let tempCbomb = giftCBomb;
  if (!isEmpty(cbInfoValue.value)) tempCbomb = filter(tempCbomb, (item) => cbInfoValue.value.includes(item.value));
  return tempCbomb.map((bomb: any) => {
    const count = get(inventory.value, 'amount') || 0;
    if (bomb.value === 'consumable') {
      return { ...bomb, count: count, showBadge: true, info: cbInfoValue.value };
    } else if (bomb.value === 'gift') {
      return { ...bomb, count: get(giftInventory.value, 'amount') || 0, showBadge: true, info: cbInfoValue.value };
    }
  });
});
</script>
<style lang="sass" scoped>
.hide-cursor
  pointer-events: none !important

.dark .toolbar
  --background: #17074c !important

.gift-modal-big
  --width: 400px
  --height: auto
  --border-radius: 10px
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
</style>
