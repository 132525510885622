import { sendAnalyticsEvent } from '@/shared/services/analytics';
import constants from '@/shared/statics/constants';
import { chAxios } from '@/shared/lib/axios';

export async function getQuizzes(params = {}) {
  const url = `/quizzes/`;

  const { data } = await chAxios().get(url, { params: { ...params } });
  return data;
}

export async function postQuizzes(payload: {}, title: any, day: any) {
  const url = `/quizzes/logs/submit/`;

  const { data } = await chAxios().post(url, payload);
  sendAnalyticsEvent('Submitted Quiz', {
    quizName: title,
    day,
  });
  return data;
}

export async function getQuizzesLogs(page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/quizzes/logs/`;

  const { data } = await chAxios().get(url, {
    params: { page, page_size: pageSize, ...params },
  });
  return data;
}

export async function getQuizzesInsights(id: string) {
  const url = `/quizzes/logs/${id}/`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function getUserQuizCountDetails() {
  const url = `/users/quiz-details/`;

  const { data } = await chAxios().get(url);
  return data;
}

export async function addCharTraitsToProfile(params = {}) {
  const url = `/quizzes/logs/add-char-traits/`;

  const { data } = await chAxios().get(url, {
    params: { ...params },
  });
  return data;
}

export async function getQuizCharacters(params = {}) {
  const url = `/characters/my-quiz-chars/`;

  const { data } = await chAxios().get(url, {
    params: { ...params },
  });
  return data;
}

export async function getUsersQuizHistory(page = 1, pageSize = constants.defaultPageSize, params = {}) {
  const url = `/quizzes/logs/quiz-history/`;
  const { data } = await chAxios().get(url, {
    params: { page, page_size: pageSize, ...params },
  });
  return data;
}

export async function getQuizDetails(id: string, params = {}) {
  const url = `/quizzes/${id}/`;
  const { data } = await chAxios().get(url, { params: { ...params } });
  return data;
}
