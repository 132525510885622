import { Module } from 'vuex';
import { RootState } from '../../index';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { SiteEventState } from './types';

const state: SiteEventState = {
  siteEvents: [],
  siteEventsPage: 0,
  siteEventsPaging: {},
  submissions: [],
  submissionsPage: 0,
  submissionsPaging: {},
};

export const SiteEventModule: Module<SiteEventState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
