<template>
  <div @click="doDelete">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { alertController } from '@ionic/vue';
import { toast } from '@/shared/native';
import { deleteBlab } from '@/shared/actions/blabs';
import { deleteWorld, deleteWorldDraft } from '@/shared/actions/worlds';
import { deleteMarketplaceListing } from '@/shared/actions/marketplace';
const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  value: {
    type: String,
    default: '',
  },
  isDraft: {
    type: Boolean,
    default: false,
  },
});

const id = toRef(props, 'id');
const title = toRef(props, 'title');
const key = toRef(props, 'value');
const isDraft = toRef(props, 'isDraft');
const emits = defineEmits(['close']);
const doDelete = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to delete this ${title.value}.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      if (isDraft.value && key.value === 'world') await deleteWorldDraft(id.value);
      else if (key.value === 'world') {
        await deleteWorld(id.value as string);
      } else if (key.value === 'post') {
        await deleteBlab(id.value as string);
      } else if (key.value === 'marketplace') {
        await deleteMarketplaceListing(id.value as string);
      }
      emits('close');
      toast.show(`${title.value} deleted successfully`, 'nonative', 'success');
      location.reload();
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};
</script>

<style lang="sass" scoped></style>
