<template>
  <ion-modal
    class="modal-cb"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ModalHeader :modal-title="modalTitle" :showGoBackButton="true" :showCloseButton="true" @close="dismissModal" />
    <modal-compliment-bomb-content :giftCbData="updatedCbomb" :count="totalCount" :show-outer-badge="true" />

    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div
          class="d-flex justify-content-between"
          v-if="get(inventory, 'amount') > 0 && !purchaseMore && get(inventory, 'type') === inventoryTypes.consumable"
        >
          <GiftComplimentBomb
            v-if="isEmpty(character)"
            :inventory="inventory"
            :giftInventory="giftInventory"
            @updated="fetchInventoryDetails"
          >
            <ion-button fill="clear" class="gift-btn">
              <ion-icon :icon="giftOutline" class="icon" />
              <span class="mx-1">Send as gift instead!</span>
            </ion-button>
          </GiftComplimentBomb>
          <compliment-bomb-character-selection
            @updated="emits('updated')"
            :inventory="inventory"
            :character="character"
            :class="{ 'mx-auto': !isEmpty(character) }"
          >
            <ion-button class="use-btn" :class="{ 'set-padding': !isEmpty(character) }">Use</ion-button>
          </compliment-bomb-character-selection>
        </div>
        <div v-else class="d-flex justify-content-center align-items-center text-center">
          <ion-button class="token-btn" @click="buyComplimentBomb">
            <img class="token-icon mr-1" src="/quiz_token.png" />
            Buy</ion-button
          >
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import { giftOutline } from 'ionicons/icons';
import ModalHeader from './ModalComponents/ModalHeader.vue';
import constants from '@/shared/statics/constants';
import ComplimentBombCharacterSelection from '@/shared/pages/compliment-bombs/components/ComplimentBombCharacterSelection.vue';
import ModalComplimentBombContent from './ModalComponents/ModalComplimentBombContent.vue';
import GiftComplimentBomb from '@/shared/pages/compliment-bombs/components/GiftComplimentBomb.vue';
const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  character: {
    type: Object,
    default: {},
  },
  inventory: {
    type: Object,
    default: () => {},
  },
  giftInventory: {
    type: Object,
    default: () => {},
  },
  purchaseMore: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['close', 'purchase', 'gifted', 'sent', 'updated']);
const isOpen = toRef(props, 'isOpen');
const inventoryTypes = constants.inventories;
const svgIcon = ref('/compliment-bomb.svg');
const router = useRouter();
const inventory: any = toRef(props, 'inventory');
const purchaseMore: any = toRef(props, 'purchaseMore');
const tempInvType = ref('');
const character = toRef(props, 'character');
const totalCount = computed(() => {
  return get(inventory.value, 'amount');
});

const isGiftCountOrPurchaseMore = computed(() => {
  return get(inventory.value, 'amount') > 0 && !purchaseMore.value;
});

const updatedCbomb = computed(() => {
  return [
    {
      svgIcon: svgIcon.value,
      title: sectionTitle.value,
      text: description.value,
      extraText: subTitle.value,
      count: totalCount.value,
      showBadge: false,
    },
  ];
});

const modalTitle = computed(() => {
  return isGiftCountOrPurchaseMore.value
    ? `Compliment Bomb ${isEmpty(character.value) ? '' : character.value.info.name}`
    : 'Buy a Compliment Bomb';
});
const description = computed(() => {
  return `<strong>Use on ${
    isEmpty(character.value) ? 'any Character on the site' : `${character.value.info.name}.`
  }  </strong> ${
    isEmpty(character.value) ? '(even yours). The Character' : character.value.info.name
  }  will then be <strong>targeted to a wave of compliments</strong> from other users.`;
});

const sectionTitle = computed(() => {
  return isGiftCountOrPurchaseMore.value
    ? `TARGET ${isEmpty(character.value) ? ' A CHARACTER' : character.value.info.name} FOR COMPLIMENTS`
    : 'COMPLIMENT BOMBS';
});

const fetchInventoryDetails = (val: any) => {
  tempInvType.value = val;
  emits('gifted', tempInvType.value);
};

const subTitle = computed(() => {
  if (get(inventory.value, 'amount') <= 0 || purchaseMore.value)
    return `<strong>Target a Character for Compliments</strong>`;
  else '';
});

const dismissModal = (val: any) => {
  emits('close', val);
};

const buyComplimentBomb = () => {
  purchaseMore.value ? emits('purchase') : dismissModal('');
  router.push({ name: 'shop' });
};
</script>
<style lang="sass" scoped>
.icon
  font-weight: bold
.token-icon
  width: 20px
  height: 20px
.token-btn
  --background: linear-gradient(to right, rgb(215 27 217), rgb(180 51 225))
  border: 3px solid #214062
  border-radius: 10px
  font-size: 17px
  font-weight: bold
  @media(min-width:550px)
    width: 20%
.use-btn
  --background: var(--ion-color-primary)
  --color: white !important
  margin-left: auto
  font-weight: bolder
  font-size: 14px
  padding-right: 14px
.set-padding
  padding-right: 0px !important
.gift-btn
  --color: #00b4c5
  text-decoration: underline
  font-size: 14px
.dark .toolbar
  --background: #17074c !important

.modal-inner-cb-content
  background: #e6e6e6
  max-height: 70vh
  max-height: 70dvh
  overflow: auto
  overflow-x: hidden

.modal-cb
  --width: 450px
  --height: auto
  --border-radius: 10px
  @media(max-width: 470px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
</style>
