export const userImage = (el: HTMLImageElement, binding: { value: any }) => {
  try {
    const empty = '/icons/person.svg';

    const router = useRouter();

    if (!el.src || el.src.includes(router.currentRoute.value.fullPath)) {
      el.src = empty;
      return;
    }

    const { value } = binding;
    const loader = empty;
    const fallBackImage = empty;
    const img = new Image();
    let loading = loader;
    let error = fallBackImage;
    const original = el.src;
    if (typeof value === 'string') {
      loading = value;
      error = value;
    }
    if (value instanceof Object) {
      loading = value.imageLoader || loader;
      error = value.fallBackImage || fallBackImage;
    }
    img.src = original;

    img.onload = () => {
      el.src = original || empty;
    };
    img.onerror = () => {
      el.src = error;
    };
  } catch (e) {
    // Do nothing
  }
};
