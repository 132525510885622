export const useDocument = () => {
  const _document = ref<Document>({
    getElementById: () => {},
  } as unknown as Document);

  if (process.client) {
    _document.value = document;
  }

  return _document;
};
