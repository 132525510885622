<template>
  <ion-modal
    class="modal-size"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <SearchInput
        v-model="text"
        :blur-on-esc="true"
        :select-on-focus="false"
        :shortcut-listener-enabled="true"
        :clear-icon="false"
        :hide-shortcut-icon-on-blur="false"
        :clear-on-esc="true"
        placeholder="Search your Characters here"
        @input="onSearch"
      />
      <div class="h-100 overflow-auto p-2">
        <div class="character-card-small d-flex clickable" @click="selectPostAs(user.id, 'user', user)">
          <CommenterImage
            :src="user.profile_picture_url"
            :ring="get(user, 'customize_profile.profile_ring.image')"
            :ringWidth="60"
            :top="-5"
            size="md"
            type="user"
          />
          <div class="rest-info ml-3 flex-grow-1">
            <div class="name d-flex align-items-center justify-content-start">
              <div>
                {{ user.username }}
              </div>
            </div>
            <div class="description">
              <i>{{ yourselfText }}</i>
            </div>
          </div>
        </div>

        <hr />

        <div v-if="characters && characters.length" class="characters">
          <character-card-select-small
            v-for="character of characters"
            :key="character.id"
            :character="character"
            class="my-3 ch-no-pad"
            @character-selected="selectPostAs(character.id, 'character', character)"
          />
          <p v-show="userCharsPaging.next" class="clickable" @click="requestLoadMore">Load More</p>
        </div>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SearchInput from 'vue-search-input';
import { getUserCharacters } from '@/shared/actions/characters';
import type { Paging } from '@/shared/types/static-types';
import CharacterCardSelectSmall from '@/shared/components/CharacterCardSelectSmall.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { getNextPage } from '@/shared/helpers/pagination';
import CommenterImage from '@/shared/components/storage/CommenterImage.vue';

@Options({
  name: 'PostAsSelectModal',
  components: { CharacterCardSelectSmall, CommenterImage, SearchInput },
})
export default class PostAsSelectModal extends Vue {
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({ default: 'Post As' }) modalTitle!: string;
  @Prop({ default: 'Post as yourself' }) yourselfText!: string;

  public characters!: any = [];
  public text = '';
  public userCharsPaging: any = ref<Paging>();

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public dismissModal() {
    this.$emit('dismissModal');
  }

  public async requestLoadMore(ev: CustomEvent) {
    if (!this.userCharsPaging.next) {
      (ev?.target as any).complete();
    } else {
      await this.userCharactersNextPage();
    }
  }

  public selectPostAs(id: string, type: string, item: {}) {
    this.$emit('itemSelected', { id, type, item });
    this.dismissModal();
  }

  public async userCharactersNextPage() {
    const { results, ...paging } = await getNextPage(this.userCharsPaging!);
    this.characters = this.characters.concat(results);
    this.userCharsPaging = paging;
  }

  public async fetchCharacters() {
    const { results, ...paging } = !isEmpty(this.text)
      ? await getUserCharacters(this.user.id, 1, { page_size: 40, search: this.text })
      : await getUserCharacters(this.user.id, 1, { page_size: 40 });
    this.characters = results;
    this.userCharsPaging = paging;
  }

  public searchTextChanged = debounce(async () => {
    this.fetchCharacters();
  }, 500);

  public onSearch() {
    this.searchTextChanged();
  }

  public mounted() {
    this.fetchCharacters();
  }
}
</script>

<style lang="sass" scoped>
.character-card-small
  img
    width: 60px
    height: 60px
    border-radius: 40px
    border: solid gray 0.1px
    object-fit: cover
  .name
    font-size: 18px
    font-weight: bold
  .description
    font-size: 13px
.modal-size
  --border-radius: 8px !important
  --width: 60%
  --height: 90%
  @media(max-width: 700px)
    --width: 80%
    --height: 90%
</style>
