export const fanArtImage = (el: HTMLImageElement, binding: { value: any }) => {
  try {
    const empty = '/icons/image.svg';
    const { value } = binding;
    const fallBackImage = empty;
    const img = new Image();

    let error = fallBackImage;
    const original = el.src;
    if (typeof value === 'string') {
      error = value;
    }
    if (value instanceof Object) {
      error = value.fallBackImage || fallBackImage;
    }
    img.src = original;

    img.onload = () => {
      el.src = original || empty;
      if (original) {
        el.classList.remove('small-img');
      } else {
        el.classList.add('small-img');
      }
    };
    img.onerror = () => {
      el.src = error;
      el.classList.add('small-img');
    };
  } catch (e) {
    // Do nothing
  }
};
