import { GetterTree } from 'vuex';
import { BlabsState } from './types';
import { RootState } from '@/shared/store';

export const getters: GetterTree<BlabsState, RootState> = {
  isSunshine(state: BlabsState) {
    return state.isSunshine;
  },

  sunshineId(state: BlabsState) {
    return state.sunshineId;
  },
  inActionBlab(state: BlabsState) {
    return state.inActionBlab;
  },
  trendingBlabs(state: BlabsState) {
    return state.trendingBlabs;
  },

  trendingBlabsPaging(state: BlabsState) {
    return state.trendingBlabsPaging;
  },
  topBlabs(state: BlabsState) {
    return state.topBlabs;
  },

  topBlabsPaging(state: BlabsState) {
    return state.topBlabsPaging;
  },
  latestBlabs(state: BlabsState) {
    return state.latestBlabs;
  },

  latestBlabsPaging(state: BlabsState) {
    return state.latestBlabsPaging;
  },
  artBlabs(state: BlabsState) {
    return state.artBlabs;
  },

  artBlabsPaging(state: BlabsState) {
    return state.artBlabsPaging;
  },
  storiesBlabs(state: BlabsState) {
    return state.storiesBlabs;
  },

  storiesBlabsPaging(state: BlabsState) {
    return state.storiesBlabsPaging;
  },
};
