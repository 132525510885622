import axios from 'axios';
import { getAuthTokenHeader } from '@/shared/services/auth';

const { apiUrl } = useSafeEnv();

export const chAxios = () =>
  axios.create({
    baseURL: apiUrl.value,
    headers: getAuthTokenHeader(),
  });
