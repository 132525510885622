export const templateImage = (el: HTMLImageElement, binding: { value: any }) => {
  try {
    const empty = '/icons/template.svg';
    const { value } = binding;
    const loader = empty;
    const fallBackImage = empty;
    const img = new Image();
    let loading = loader;
    let error = fallBackImage;
    const original = el.src;
    if (typeof value === 'string') {
      loading = value;
      error = value;
    }
    if (value instanceof Object) {
      loading = value.imageLoader || loader;
      error = value.fallBackImage || fallBackImage;
    }
    img.src = original;

    img.onload = () => {
      el.src = original || empty;
      if (original) {
        el.classList.remove('small-template');
      } else {
        el.classList.add('small-template');
      }
    };
    img.onerror = () => {
      el.src = error;
      el.classList.add('small-template');
    };
  } catch (e) {
    // Do nothing
  }
};
