<template>
  <div class="custom-button">
    <ion-button class="c-button" :disabled="loading" v-bind="$props" @click.self="click">
      <slot v-if="!loading" />
      <ChLoading size="sm" v-else class="spinner" />
    </ion-button>
  </div>
</template>

<script lang="ts">
import { Prop, Vue, Options } from 'vue-property-decorator';

@Options({
  name: 'IonCustomButton',
  components: {},
})
export default class IonCustomButton extends Vue {
  @Prop({ type: String }) public type!: string;
  @Prop({ type: Boolean }) public loading!: boolean;
  @Prop({ type: Boolean }) public disabled!: boolean;
  @Prop({ type: String }) public color!: string;

  public click(value: string) {
    return this.$emit('click', value);
  }
}
</script>

<style scoped lang="sass">
.custom-button
  width: 100%
  .spinner
    width: 15px
    height: 15px
  .button
    margin-left: 0px
    margin-right: 0px
    width: 100%
</style>
