export const possibleSectionsLabelMap: { [key: string]: string } = {
  description: 'Description',
  backstory: 'Background Story',
  creatorsNote: "Creator's Note",
  spotifyLink: 'Music',
  about: 'About',
  stats: 'Stats',
  colors: 'Color Palette',
  moodboard: 'Moodboard',
  featuredIn: 'Featured in',
  collections: 'Images',
  relationshipsSection: 'Relations',
  timeline: 'Timeline',
  outfits: 'Outfits',
  favorites: 'Favorites',
  inventory: 'Inventory',
  life_stages: 'Life Stages',
  qualities: 'Attribute Sliders',
  trivia: 'Trivia',
  alignment_charts: 'Alignment Charts',
};

export const unlockableSectionsLabelMap: { [key: string]: string } = {
  qualities: 'Attribute Sliders',
  alignment_charts: 'Alignment Charts',
  timeline: 'Timeline',
  trivia: 'Trivia',
};
