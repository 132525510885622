<template>
  <div class="px-3 py-3">
    <div class="d-flex justify-content-center text-center">
      <div v-if="['other'].includes(category.title)">
        <ion-badge class="other-badge">
          <ion-icon class="icon mx-2" :icon="checkmarkOutline" /><span class="mr-2">Other</span>
        </ion-badge>
      </div>
    </div>
    <div class="d-flex justify-content-center text-center align-items-center py-2">
      <small class="small-text"> Thank you! Your report will be reviewed by CharacterHub moderation. </small>
    </div>
    <div class="d-flex flex-column block" v-if="!isEmpty(reportedUser)">
      <div class="title">Another step you can take:</div>
      <div class="d-flex mt-2 clickable-item-hov" @click="blockUser">
        <ion-icon class="ban-icon mx-2 mt-1" :icon="banOutline" />
        <div>
          <div class="d-flex flex-column">
            <span class="block-text">Block {{ get(reportedUser, 'username') }}'s profile </span>
            <small>You won't be able to see or interact with each other.</small>
          </div>
        </div>
      </div>
      <div
        v-if="leaveChatroom && status.role === 'member'"
        class="d-flex mt-2 clickable-item-hov"
        @click="leavePrivateChatrooom"
      >
        <ion-icon class="ban-icon mx-2 mt-1" :icon="banOutline" />
        <div>
          <div class="d-flex flex-column">
            <span class="block-text">Leave Chatroom</span>
            <small>You you will no longer be part of this chat room.</small>
          </div>
        </div>
      </div>
      <div v-if="leaveSpace" class="d-flex mt-2 clickable-item-hov" @click="leaveSocial">
        <ion-icon class="ban-icon mx-2 mt-1" :icon="banOutline" />
        <div>
          <div class="d-flex flex-column">
            <span class="block-text">Leave Space</span>
            <small>You will no longer be part of this social space.</small>
          </div>
        </div>
      </div>
    </div>
    <ion-button color="secondary" class="w-100 mt-3" @click="dismiss">Done</ion-button>
  </div>
</template>

<script lang="ts" setup>
import { blockEntity } from '@/shared/actions/blockedEntities';
import { alertController } from '@ionic/vue';
import { toast } from '@/shared/native';
import { banOutline, checkmarkOutline } from 'ionicons/icons';
import { leavesocialSpaceChatroom } from '@/shared/actions/socialSpaceChatroom';
import { leaveSocialSpace } from '@/shared/actions/worlds';
const props = defineProps({
  category: {
    type: Object,
    default: () => {},
  },
  reportedUser: {
    type: Object,
    default: () => {},
  },
  leaveChatroom: {
    type: Boolean,
    default: () => false,
  },
  leaveSpace: {
    type: Boolean,
    default: () => false,
  },
  reportEntityId: {
    type: String,
    default: '',
  },
  status: {
    type: Object,
    default: {},
  },
});

const emits = defineEmits(['dismiss']);
const category = toRef(props, 'category');
const reportEntityId = toRef(props, 'reportEntityId');
const isBlocking = ref(false);
const reportedUser = toRef(props, 'reportedUser');

const dismiss = () => {
  emits('dismiss');
};
const leaveSocial = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to leave this social space!`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      await leaveSocialSpace(reportEntityId.value as string);
      toast.show('Left social space successfully', 'nonative', 'success');
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};
const leavePrivateChatrooom = async () => {
  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to leave this chatroom. You will no longer be able to interact with this chat.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      await leavesocialSpaceChatroom(reportEntityId.value);
      toast.show('Left Chatroom successfully', 'nonative', 'success');
    }
  } catch (e) {
    toast.show('Encountered an error - please try again later.', 'nonative', 'danger');
  }
};
const blockUser = async () => {
  if (isBlocking.value) return;

  isBlocking.value = true;

  try {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to block <strong>${get(reportedUser.value, 'username')}</strong>.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-medium',
          id: 'cancel-button',
        },
        {
          text: 'Block',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-danger',
        },
      ],
    });
    await alert.present();

    const { role } = await alert.onDidDismiss();

    const userId = get(reportedUser.value, 'id');
    if (role === 'ok') {
      await blockEntity('user', userId || '');
      toast.show(`${get(reportedUser.value, 'username')} has been blocked.`, 'nonative', 'success');
      dismiss();
      const router = useRouter();
      router.replace({ name: 'home' });
      location.reload();
    }
  } catch (err) {
    toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
  }

  isBlocking.value = false;
};
</script>

<style lang="sass" scoped>
.ban-icon
  font-size: 20px
  display: flex
  align-items: center
.small-text
  font-size: 85% !important
.other-badge
  border-radius: 15px
  background: #ebf5ff
  color: #3280da
  width: 100%
  height: 26px
  display: flex
  align-items: center
  justify-content: center

.title
  font-weight: 700
  font-size: 18px
.block-text
  color: rgb(0 0 0)
  font-weight: 600
  font-size: 16px

.dark  .block-text
  color: white
</style>
