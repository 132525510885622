<template>
  <div>
    <div class="d-flex justify-content-end skip-btn clickable-item-hov" @click="emits('skip')">Skip</div>
    <div class="d-flex flex-column justify-content-center align-items-center text-center mx-auto choose-sec">
      Add a profile picture
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center text-center mx-auto mt-3">
      <div>
        <ChangeProfilePopover position="bottom" commitAfterChange restrictGif isOnboarding>
          <div>
            <ProfilePicture commitAfterChange size="lg" />
            <div class="position-relative">
              <ion-button class="position-absolute p-0 round-button" shape="round" color="dark">
                <inline-svg class="icon" src="/icons/camera.svg" />
              </ion-button>
            </div>
          </div>
        </ChangeProfilePopover>
      </div>
      <small class="w-50 mt-3" style="color: #afa7a7"
        >Your Friends will see this photo when they visit your profile.</small
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import ChangeProfilePopover from '@/shared/components/ProfilePicture/popover.vue';
import ProfilePicture from '@/shared/components/ProfilePicture/index.vue';

const emits = defineEmits(['skip']);
</script>

<style scoped lang="sass">
.skip-btn
  text-decoration: underline
  font-size: 14px
.choose-sec
  font-size: 23px
  width: 50%
  @media(max-width:300px)
    width:75%
.round-button
  bottom: 5px
  right: 10px
  --ion-color-dark: rgba(10, 9, 40, 0.8)
  --padding-start: 0
  --padding-end: 0
  width: 24px
  z-index: 12
  height: 24px
</style>
