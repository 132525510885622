<template>
  <div
    class="d-flex justify-content-center mx-auto align-items-center clickable-item-hov"
    :class="{ 'curr-trans-box': isGiftBox }"
    @click.stop.prevent="openGiftBox"
  >
    <img v-if="isGiftBox" :src="currencyImage" style="position: relative; top: 20%" />
    <slot />
    <CurrencyGiftAnimationModal :is-open="isOpenCurrAnimationModal" @close="closeAnimationModal" />
    <DisplayGiftItemsModal
      :currency="currencyData"
      :is-open="isOpenCurrGiftModal"
      @close="openHintModal"
      @dismiss-modal="dismissModal"
    />
    <CurrencyHintModal
      :boxes="boxes"
      :is-open="isOpenHintCurrModal"
      @close="closeHintModal"
      @dismiss-modal="dismissHintModal"
    />
  </div>
</template>

<script lang="ts" setup>
import { postCurrencyOpenStatus } from '@/shared/actions/currency';
import DisplayGiftItemsModal from '@/shared/modals/DisplayGiftItemsModal.vue';
import CurrencyHintModal from '@/shared/modals/CurrencyHintModal.vue';
import CurrencyGiftAnimationModal from '@/shared/modals/CurrencyGiftAnimationModal.vue';
import { toast } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';

const props = defineProps({
  progressId: {
    type: String,
    default: '',
  },
  boxes: {
    type: Object,
    default: {},
  },
  isGiftBox: {
    type: Boolean,
    default: false,
  },
});

const { currencyBoxImage } = authStore();
const emits = defineEmits(['updated', 'delete', 'fetch']);
const isOpenCurrGiftModal = ref(false);
const isOpenCurrAnimationModal = ref(false);
const currencyData = ref({});
const isOpenHintCurrModal = ref(false);
const progressId = toRef(props, 'progressId');
const isGiftBox = toRef(props, 'isGiftBox');
const openGiftBox = async () => {
  try {
    if (progressId.value) {
      const resp = isGiftBox.value
        ? await postCurrencyOpenStatus({ progress_box: progressId.value })
        : await postCurrencyOpenStatus({ progress_box: progressId.value, freebie: true });
      currencyData.value = resp;
      isOpenCurrAnimationModal.value = true;
    }
  } catch (_err: any) {
    toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
  }
};

const closeAnimationModal = () => {
  isOpenCurrAnimationModal.value = false;
  isOpenCurrGiftModal.value = true;
};

const closeHintModal = () => {
  isOpenHintCurrModal.value = false;
  isOpenCurrGiftModal.value = false;
  setTimeout(() => {
    if (isGiftBox.value) {
      get(currencyData.value, 'refresh_existing') ? emits('updated') : emits('delete');
    } else emits('fetch');
  }, 10);
};

const currencyImage = computed(() => {
  return isEmpty(get(currencyBoxImage.value, 'box_image'))
    ? '/idlebox_square.png'
    : get(currencyBoxImage.value, 'box_image');
});
const dismissHintModal = (val: any) => {
  if (get(val, 'detail.role') === 'backdrop') {
    closeHintModal();
  }
};
const openHintModal = () => {
  isOpenHintCurrModal.value = true;
};

const dismissModal = (val: any) => {
  if (get(val, 'detail.role') === 'backdrop') {
    openHintModal();
  }
};
</script>

<style lang="sass" scoped>
.curr-trans-box
  color: black !important
  width: 50px
  height: 50px
  position: relative
</style>
