<template>
  <div id="coll-sc" class="coll-supercontainer-h w-100">
    <div class="colls-new my-1">
      <div v-if="isEditor" class="create-coll mx-2 h-100" @click="createCollection">
        <div class="d-flex flex-column align-items-center justify-content-center h-100">
          <i class="ti-plus icon" />
          <div class="text mt-2">NEW<br />ALBUM</div>
        </div>
      </div>
    </div>
    <Sortable
      id="imagecollections"
      :disabled="!isEditor"
      class="colls ml-1 my-1"
      item-key="id"
      tag="div"
      :list="collections"
      :options="sortableOptions"
      @end="onMoveAlbum"
    >
      <template #item="{ element }">
        <InlineGalleryCollection :id="element.id_" :is-editor="isEditor" :customize="customize" />
      </template>
    </Sortable>
  </div>
</template>
<script lang="ts" setup>
import InlineGalleryCollection from './InlineGalleryCollection.vue';
import { imageStore } from '@/shared/pinia-store/images';
import { isTouchScreen } from '@/shared/utils/ui';
const { updateImageCollectionOrder } = imageStore();

const props = defineProps({
  isEditor: {
    type: Boolean,
    default: false,
  },
  customize: {
    type: Object,
    default: () => ({}),
  },
});
const isEditor = toRef(props, 'isEditor');
const customize = toRef(props, 'customize');
const sortableOptions = {
  group: 'clxns',
  handle: '.d-ic',
  animation: 50,
  delay: isTouchScreen() === false ? 0 : 200,
  touchStartThreshold: 5,
  fallbackTolerance: 5,
  chosenClass: 'held',
  swapThreshold: 1,
  direction: 'horizontal',
};

const collections = computed(() => {
  const { activeImageCollections } = imageStore();
  return activeImageCollections.value;
});

const onMoveAlbum = (e: any) => {
  updateImageCollectionOrder({ from: e.oldIndex, to: e.newIndex });
};

const createCollection = () => {
  const { createImageCollectionLocal } = imageStore();
  createImageCollectionLocal({});
  const div = document.getElementById('coll-sc') as HTMLDivElement;
  nextTick(() => {
    div.scroll({
      left: div.scrollWidth,
      behavior: 'smooth',
    });
  });
};
</script>
<style scoped lang="sass">
.coll-supercontainer-h
  overflow-x: auto
  overflow-y: hidden
  white-space: nowrap
  padding-top: 0.25rem
.colls
  width: 100%
  display: flex
.colls-new
  display: flex
.held
  outline: 2px solid rgba(174, 56, 229, 0.3) !important
  opacity: 0.6
  top: 10px
  left: 10px
  border-radius: 10px
.create-coll
  width: 6rem
  background-color: #AE38E5
  margin-bottom: 0 !important
  border-radius: 10px !important
  cursor: pointer
  user-select: none !important
  &:hover
    opacity: 0.8
  .icon
    font-size: 36px
    color: white
  .text
    font-size: 14px
    user-select: none !important
    text-align: center
    color: white
    letter-spacing: 0.03rem
</style>
