import { CharacterRelationship } from './../types/static-types';
import { chAxios } from '@/shared/lib/axios';

export async function getPublicCharacterRelationships(characterId: string, page = 1) {
  const url = `/characters/relationships/`;
  try {
    const { data } = await chAxios().get(url, {
      params: {
        id: characterId,
        page,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createCharacterRelationship(
  character1Id: string,
  character2Id: string,
  description: string,
  status: string
) {
  const url = `/characters/relationships/`;
  const payload = {
    character1: character1Id,
    character2: character2Id,
    description,
    status,
  };
  try {
    const { data } = await chAxios().post(url, payload);
    return data as CharacterRelationship;
  } catch (e) {
    throw e;
  }
}

export async function getPrivateCharacterRelationships(characterId: string, page = 1) {
  const url = `/characters/relationships/private/`;
  try {
    const { data } = await chAxios().get<any, any>(url, {
      params: {
        id: characterId,
        page,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function queryCharactersForRelationships(query: string, pageNumber = 1, characterId = '') {
  const url = `/characters/lookup-relationships/`;
  try {
    const { data } = await chAxios().get(url, {
      params: {
        id: characterId,
        page_size: 8,
        q: query,
        page: pageNumber,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function sendCharacterRelationshipRequest(characterRelationshipId: string, message: string) {
  const url = `/characters/relationships/request/`;
  const payload = {
    id: characterRelationshipId,
    message,
  };
  try {
    const { data } = await chAxios().post(url, payload);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function acceptCharacterRelationshipRequest(characterRelationshipId: string) {
  const url = `/characters/relationships/accept/`;
  const payload = {
    id: characterRelationshipId,
  };
  try {
    const { data } = await chAxios().post(url, payload);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function denyCharacterRelationshipRequest(characterRelationshipId: string) {
  const url = `/characters/relationships/reject/`;
  const payload = {
    id: characterRelationshipId,
  };
  try {
    const { data } = await chAxios().post(url, payload);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteCharacterRelationship(characterRelationshipId: string) {
  const url = `/characters/relationships/${characterRelationshipId}`;
  try {
    const { data } = await chAxios().delete(url);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateCharacterRelationship(
  characterRelationshipId: string,
  characterId: string,
  description: string,
  status: string
) {
  const url = `/characters/relationships/update/`;
  const payload = {
    id: characterRelationshipId,
    character: characterId,
    description,
    status,
  };
  try {
    const { data } = await chAxios().post(url, payload);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function reorderRelationships(characterId: string, ordered_relation_ids: string[]) {
  const url = `/characters/relationships/reorder/`;
  const payload = {
    character: characterId,
    ordering: ordered_relation_ids,
  };
  try {
    const { data } = await chAxios().post(url, payload);
    return data;
  } catch (e) {
    throw e;
  }
}
