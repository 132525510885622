<template>
  <div class="colors mt-2 d-flex flex-wrap">
    <template v-for="(color, index) of colors" :key="index">
      <div
        class="px-2 py-2 d-flex color-box"
        :class="{ hide: (index >= 6 && !isClickSeeMore) || (index >= 4 && isMobSmallScreen && !isClickSeeMore) }"
      >
        <div class="position-relative">
          <div
            class="color clickable-item"
            :style="{ background: color.hex_code, border: '2px solid' }"
            @click="showAlert(index, color.hex_code)"
          ></div>
        </div>

        <div style="position: relative">
          <div v-if="isShowingAlert && selectedIndex === index" class="hex-copied px-1 py-1">
            {{ color.hex_code }} copied!
          </div>
        </div>

        <div
          class="d-flex flex-column px-2"
          :class="{ 'justify-content-center text-center align-items-center': isEmpty(color.label) }"
        >
          <div
            v-if="!isEmpty(color.label)"
            class="value mt-auto"
            :style="{ background: `${labelBgCOlor} !important` }"
            :class="{ 'pt-1': color.label?.length < 15 }"
          >
            {{ color.label }}
          </div>
          <div
            class="clickable-item"
            :style="
              !isEmpty(color.label) && color.label?.length > 20
                ? 'margin-bottom: 0.5rem'
                : color.label?.length < 20 && !isEmpty(color.label)
                ? 'margin-bottom:12px'
                : ''
            "
            :class="{
              'd-flex justify-content-center': isEmpty(color.label),
              'mt-auto hex-code': !isEmpty(color.label),
            }"
          >
            <span @click="showAlert(index, color.hex_code)"> {{ color.hex_code }}</span>
          </div>
        </div>
        <!-- <div class="value">{{ color.hex_code }}</div> -->
      </div>
    </template>
    <div
      v-if="hasMore && !isClickSeeMore"
      class="d-flex justify-content-end mt-auto mr-4 ml-auto clickable-item-hov see-more"
      @click="seeMore"
    >
      <i class="ti-angle-down mx-2" style="margin-top: 2px" />

      See More
    </div>

    <div
      v-if="isClickSeeMore"
      class="d-flex justify-content-end mt-auto mr-4 ml-auto clickable-item-hov see-more"
      @click="seeLess"
    >
      <i class="ti-angle-up mx-2" style="margin-top: 2px" />
      See Less
    </div>
  </div>
</template>

<script lang="ts" setup>
import { mainStore } from '@/shared/pinia-store/main';

const { width } = useWindowSize();

const props = defineProps({
  value: {
    type: Array,
    default: [],
  },
  customize: {
    type: Object,
    default: {},
  },
});

const colors = toRef(props, 'value') as any;
const customize = toRef(props, 'customize');
const isClickSeeMore = ref(false);
const isShowingAlert = ref(false);
const selectedIndex = ref(0);

const isdark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const isMobSmallScreen = computed(() => {
  return width.value <= 600;
});

const showAlert = (index: any, hexCode: any) => {
  isShowingAlert.value = true;
  navigator.clipboard.writeText(`${hexCode}`);
  selectedIndex.value = index;
  setTimeout(() => {
    isShowingAlert.value = false;
  }, 1000);
};

const { isTempDefault } = useCustTempDefault(props);

const defaultTextFont = computed(() => {
  return isTempDefault.value ? 'Roboto' : get(customize.value, 'data.theme.text.default.font') || 'Roboto';
});

const textColor = computed(() => {
  const defaultClr = isdark.value ? '#FFFFFF' : '#000000';
  return isTempDefault.value ? defaultClr : `${get(customize.value, 'data.theme.text.default.color', defaultClr)}`;
});

const hasMore = computed(() => {
  return !!((isMobSmallScreen.value && colors.value!.length > 4) || colors.value!.length > 6);
});

const seeMore = () => {
  isClickSeeMore.value = true;
};

const seeLess = () => {
  isClickSeeMore.value = false;
};
const labelBgCOlor = computed(() => {
  const defaultClr = isdark.value ? '#17074C' : '#e6e6e6';
  return isTempDefault.value ? defaultClr : `${get(customize.value, 'data.theme.backgrounds.infoSection', defaultClr)}`;
});
</script>

<style lang="sass" scoped>
.hide
  display: none !important
.color-box
  border: 2px solid
  width: calc(35% - 26px)
  border-radius: 10px
  //padding-top: 11px
  @media(max-width:841px) and (min-width:751px)
    width: calc(35% - 20px)

  @media(max-width:751px) and (min-width:480px)
    width: calc(51% - 14px) !important
  @media(max-width:480px)
    width: 100%
.colors
  gap:10px
  .color
    width: 65px
    height: 65px
    font-size: 10px
    position: relative
    border-radius: 10px
    //margin-bottom: 30px
.value
  font-size: 14px
  font-weight: bold
  color: v-bind(textColor) !important
  font-family: v-bind(defaultTextFont) !important
.see-more
  font-size: 14px
  font-family: v-bind(defaultTextFont) !important

.dark .see-more
  color: v-bind(textColor) !important
.dark .value
  background: v-bind(labelBgCOlor) !important
.hex-code
  font-size: 14px
  color: v-bind(textColor) !important
  font-family: v-bind(defaultTextFont) !important
.hex-copied
  color: white
  position: absolute
  background: #14ab5a
  font-size: 11px
  border-radius: 6px
  left: 30px
  margin-top: 25px
  width: 99px
</style>
