<template>
  <TEditor 
    :tinymce-script-src="tinyMCEUrl"
    :placeholder="placeholder" 
    :value="value" 
    :init="customInit" 
    @keyup="inputChange"
  />
</template>

<script lang="ts" setup>
import { default as TEditor } from '@tinymce/tinymce-vue';
import { requestUploadImageUrl, sendImageToS3, sendImageUrlToBackend } from '@/shared/services/upload';
import { mainStore } from '@/shared/pinia-store/main';
import constants from '@/shared/statics/constants';
import { toast } from '@/shared/native/toast';
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  init: {
    type: Object,
    default: {},
  },
  value: {
    type: String,
    default: '',
  },
  apiKey: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Body',
  },
});

const tinyMCEUrl = computed(() => {
  const {
    public: { tinyMCEUrl },
  } = useRuntimeConfig();
  return tinyMCEUrl;
});
const init = toRef(props, 'init');
const placeholder = toRef(props, 'placeholder');
const value = toRef(props, 'value');

const emits = defineEmits(['input', 'update:value']);

const inputChange = (val: string) => {
  emits('input', val);
  emits('update:value', val);
};

const ready = ref(true);

const dark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

watch(dark, () => {
  ready.value = false;
  nextTick(() => {
    ready.value = true;
  });
});

const maxFileSizeMb = constants.uploadMaxFileSizeMB;

const image_uploader = async (blobInfo: any, progress: any) => {
  const imageFile = blobInfo.blob();

  try {
    const threePostRequests = await requestUploadImageUrl();

    if (maxFileSizeMb && maxFileSizeMb * 1024 * 1024 < imageFile.size) {
      await toast.show(`File exceeds max size (${maxFileSizeMb}MB)`, 'nonative', 'danger');
    }

    if (!imageFile.type.startsWith('image/')) {
      throw new TypeError('File should be an image.');
    }
    const statusCode = await sendImageToS3(threePostRequests.l.url, threePostRequests.l.fields, imageFile);
    if (statusCode >= 400) throw new Error('Encountered an error while uploading image.');
    const imageData = await sendImageUrlToBackend(threePostRequests.l.fields.key, threePostRequests.l.url);

    return resizeUpload(imageData.image, '1280w');
  } catch (e: any) {
    if (e instanceof TypeError) {
      // Do nothing
      await toast.show(e.message, 'nonative', 'danger');
    } else {
      // Do nothing
      const error: any = e;
      const body = 'Could not upload file.';
      const message = error ? error.message || body : body;
      await toast.show(message, 'nonative', 'danger');
    }
  }
};

const customInit = computed(() => {
  return {
    ...init.value,
    image_dimensions: false,
    promotion: false,
    toolbar:
      'undo redo | bold italic backcolor | image | blocks |  fontfamily | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
    images_upload_url: 'http://localhost:3000/posts/create',
    automatic_uploads: true,
    images_upload_handler: image_uploader,
    content_css: dark.value ? 'dark' : '',
    content_style: dark.value
      ? 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: white; background-color: transparent } img { max-width: 100%; max-height: 100%; }' +
        'body::before { color: #878094 !important }'
      : 'img { max-width: 100%; max-height: 100%; }',
    init_instance_callback: function (editor: any) {
      editor.on('OpenWindow', function () {
        const uploadBtns = document.querySelectorAll('.tox-dialog__body-nav-item.tox-tab');
        if (uploadBtns.length === 2) {
          // @ts-ignore
          uploadBtns[0].style.display = 'none';
          // @ts-ignore
          uploadBtns[1].click();
        }
      });
    },
  };
});
</script>

<style lang="sass" scoped></style>
