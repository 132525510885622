import { toast } from '@/shared/native/toast';
import { authStore } from '@/shared/pinia-store/auth';
import logger from '@/shared/services/logger';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error) => {
    const {
      public: { env },
    } = useRuntimeConfig();

    try {
      const { loggedIn, userBannedUntil, isEmailVerified } = authStore();
      const e404 = get(error, 'response.status') === 404;
      const e403 = get(error, 'response.status') === 403;
      if (e403 && loggedIn.value) {
        if (userBannedUntil.value) toast.show('User is currently suspended', 'nonative', 'danger');
        if (!isEmailVerified.value) toast.show('Please verify your email to use this feature.', 'nonative', 'danger');
      } else if (!e404) {
        console.error('ErrorHandler:', error);
        // toast.show('Encountered a network error', 'nonative', 'danger');
      }
    } catch (error) {}

    if (env === 'development') {
      console.error('ErrorHandler:', error);
    } else {
      logger.error({
        e: error,
        loc: 'errorHandler',
        msg: 'ErrorHandler: ' + error,
        data: {},
      });
    }
    const router = useRouter();
    const {
      public: { apiUrl },
    } = useRuntimeConfig();
    if (
      get(error, 'response.status') === 401 &&
      router.currentRoute.value.name !== 'login' &&
      router.currentRoute.value.name !== 'logout' &&
      !get(error, 'response.config.url', '').includes('logout') &&
      get(error, 'response.config.baseURL', '').includes(apiUrl)
    ) {
      const { logout } = authStore();

      logout();
    }
  };
});
