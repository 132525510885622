import * as Sentry from '@sentry/vue';
import { Primitive } from '@sentry/types';

interface Data {
  e: any;
  loc: any;
  msg: any;
  data: any;
}

class Logger {
  public useService;

  constructor() {
    const env = '';
    this.useService = process.env.NODE_ENV !== 'development';
  }

  error({ e, loc, msg, data = {} }: Data) {
    const message = msg || e + '';
    data.loc = loc;
    data.msg = message;
    if (e && e.response && !e.response.status) return;
    if (!this.useService) {
      console.error('logger.error: ' + message, '@ ' + loc + ': ', e, data);
    } else {
      if (e instanceof Error) {
        Sentry.captureException(e, { tags: data });
      } else {
        Sentry.withScope(function (scope) {
          scope.setLevel('warning');
          Object.entries(data).forEach(([key, val]) => scope.setTag(key, val as Primitive));
          scope.setTag('e', e);
          Sentry.captureMessage(message);
        });
      }
    }
  }

  warn({ loc, msg, data = {} }: Data) {
    if (!this.useService) return console.warn('logger.warn', msg, 'loc: ' + loc, data);
    try {
      Sentry.withScope(function (scope) {
        scope.setLevel('warning');
        Object.entries(data).forEach(([key, val]) => scope.setTag(key, val as Primitive));
        scope.setTag('loc', loc);
        // will be tagged with my-tag="my value"
        Sentry.captureMessage(msg);
      });
    } catch (e) {}
  }

  info({ loc, msg, data = {} }: Data) {
    if (!this.useService) return console.info(msg, data);
    try {
      Sentry.withScope(function (scope) {
        scope.setLevel('info');
        Object.entries(data).forEach(([key, val]) => scope.setTag(key, val as Primitive));
        scope.setTag('loc', loc);
        Sentry.captureMessage(msg);
      });
    } catch (e) {}
  }
}

const logger = new Logger();

export default logger;
