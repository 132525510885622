import { ActionTree } from 'vuex';
import { WorldsState } from './types';
import { RootState } from '@/shared/store';
import constants from '@/shared/statics/constants';
import { ReactionWorldCounts, UserReaction, WorldsInfo } from '@/shared/types/static-types';
import {
  editWorld,
  editWorldRoomDetails,
  fetchUserWorlds,
  getWorldUnreadMsgsCount,
  getNextPage,
  getTopWorlds,
  getWorld,
  getWorldListings,
  getWorldsFromSlug,
  getUserStoryWorld,
  getUserStoryWorldByUsername,
} from '@/shared/actions/worlds';
import { createReactionOnWorld, deleteReactionOnWorld, updateReactionOnWorld } from '@/shared/actions/reactions';

const isUserReactedSameReaction = (reaction: string, world: WorldsInfo, isInstant = false): boolean => {
  const { user_reaction } = world;
  return !!(user_reaction && user_reaction.id && (user_reaction.reaction === reaction || isInstant));
};

const isUserReactedAnotherReaction = (reaction: string, world: WorldsInfo): boolean => {
  const { user_reaction } = world;
  return !!(user_reaction && user_reaction.id && user_reaction.reaction !== reaction);
};

const sendReactionToBackend = async (reaction: string, world: WorldsInfo, isInstant = false) => {
  const target = 'world';
  const { user_reaction, id: worldId = '' } = world;

  // delete
  if (isUserReactedSameReaction(reaction, world, isInstant)) {
    return await deleteReactionOnWorld({ reactionId: user_reaction?.id || '' });
  }

  // patch
  if (isUserReactedAnotherReaction(reaction, world)) {
    return await updateReactionOnWorld({ reactionId: user_reaction?.id || '', reaction });
  }

  // post
  const data = await createReactionOnWorld({ objectId: worldId, reaction, target });
  return data;
};

const updateReactionsDataForWorld = (
  reaction: string,
  world: WorldsInfo,
  newReaction: UserReaction
): Partial<WorldsInfo> => {
  const worldData = cloneDeep(world);
  worldData.reaction_counts = worldData.reaction_counts || { total_count: 0 };
  const totalCount = worldData.reaction_counts.total_count;
  let userReaction = worldData.user_reaction || ({} as UserReaction);
  const prevUserReaction = worldData.user_reaction as UserReaction;

  // editing counts if un-reacting or editing reaction
  if (worldData.user_reaction as UserReaction) {
    worldData.reaction_counts[prevUserReaction.reaction as keyof ReactionWorldCounts]--;
    if (newReaction.reaction in worldData.reaction_counts) {
      worldData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts]++;
    } else {
      // ignore; newReaction.reaction will never be "ordering" (which requires type string[])
      //  @ts-ignore
      worldData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts] = 1;
    }
  }
  // if reacting to story for the first time: editing counts & user_reaction
  if (!userReaction.id) {
    worldData.reaction_counts!.total_count = totalCount + 1;
    if (!worldData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts]) {
      //  @ts-ignore
      worldData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts] = 1;
    } else {
      //  @ts-ignore
      worldData.reaction_counts[newReaction.reaction as keyof ReactionWorldCounts] += 1;
    }
    userReaction = newReaction;
  }

  // if editing reaction
  if (isUserReactedAnotherReaction(reaction, worldData)) {
    worldData.user_reaction!.reaction = reaction;
  }

  // if un-reacting
  if (isUserReactedSameReaction(reaction, worldData) && !newReaction) {
    worldData.reaction_counts!.total_count = totalCount > 0 ? totalCount - 1 : 0;
    worldData.user_reaction = undefined;
  } else {
    worldData.user_reaction = {
      ...userReaction,
      reaction,
    };
  }

  const out = {
    user_reaction: worldData.user_reaction,
    reaction_counts: worldData.reaction_counts,
  };
  return out;
};

export const actions: ActionTree<WorldsState, RootState> = {
  async getTrendingWorlds({ commit }, { page = 1, type = 'story', pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getTopWorlds(page, type, '-hot_score', pageSize);
    commit('SET_TRENDING_WORLDS', { results, paging });
  },

  async trendingWorldsNextPage({ commit, state }) {
    const { trendingWorldsPaging } = state;
    const { results, ...paging } = await getNextPage(trendingWorldsPaging);
    commit('APPEND_TRENDING_WORLD', { results, paging });
  },

  async updateWorld(
    _obj,
    {
      id,
      name,
      description,
      lore,
      profile_image,
      cropped_profile_img,
      cover_image,
      about,
      spotify_link,
      colors,
      allow_join,
      is_nsfw,
      membership_details,
      extra,
      visual_collections,
      tags,
    }
  ) {
    return editWorld(id, {
      name,
      description,
      lore,
      profile_image,
      cropped_profile_img,
      cover_image,
      about,
      spotify_link,
      colors,
      allow_join,
      is_nsfw,
      membership_details,
      extra,
      visual_collections,
      tags,
    });
  },
  async react(
    { commit },
    { reaction, world, isInstant = false }: { reaction: string; world: WorldsInfo; isInstant: boolean }
  ) {
    const newReaction = await sendReactionToBackend(reaction, world, isInstant);
    const updatedReactionsData = updateReactionsDataForWorld(reaction, world, newReaction);
    commit('APPLY_REACTION_ON_WORLD', { world, updatedReactionsData });
    return { newReaction, updatedReactionsData };
  },

  async getWorldsFromSlug({ commit }, slug: string) {
    const inActionWorld = await getWorldsFromSlug(slug);
    commit('SET_IN_ACTION_WORLD', inActionWorld);
    return inActionWorld;
  },

  async getWorld({ commit }, id: string) {
    const inActionWorld = await getWorld(id);
    commit('SET_IN_ACTION_WORLD', inActionWorld);
    return inActionWorld;
  },

  async getReaction({ commit }, { objectId, reaction, target }) {
    const reactionWorld = await createReactionOnWorld({ objectId, reaction, target });
    commit('SET_IN_REACTION_WORLD', reactionWorld);
    return reactionWorld;
  },

  async getTopWorlds({ commit }, { page = 1, type, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getTopWorlds(page, type, '-rxn_count', pageSize);
    commit('SET_TOP_WORLDS', { results, paging });
  },

  async topWorldsNextPage({ commit, state }) {
    const { topWorldsPaging } = state;
    const { results, ...paging } = await getNextPage(topWorldsPaging);
    commit('APPEND_TOP_WORLDS', { results, paging });
  },

  async getMyWorlds({ commit }, { page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await fetchUserWorlds('story', page, pageSize);
    commit('SET_MY_WORLDS', { results, paging });
  },
  async myWorldsNextPage({ commit, state }) {
    const { myWorldsPaging } = state;
    const { results, ...paging } = await getNextPage(myWorldsPaging);
    commit('APPEND_MY_WORLDS', { results, paging });
  },

  async getUserWorlds({ commit }, { user, type, page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getUserStoryWorld(user, type, page, pageSize);
    commit('SET_USER_WORLDS', { results, paging });
  },
  async getUserWorldsByUsername({ commit }, { username, type, page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getUserStoryWorldByUsername(username, type, page, pageSize);
    commit('SET_USER_WORLDS', { results, paging });
  },
  async userWorldsNextPage({ commit, state }) {
    const { userWorldsPaging } = state;
    const { results, ...paging } = await getNextPage(userWorldsPaging);
    commit('APPEND_USER_WORLDS', { results, paging });
  },

  async getLatestWorlds({ commit }, { page = 1, pageSize = constants.defaultPageSize, type }) {
    const { results, ...paging } = await getWorldListings(page, pageSize, type);
    commit('SET_LATEST_WORLD', { results, paging });
  },
  async latesttWorldsNextPage({ commit, state }) {
    const { latestWorldsPaging } = state;
    const { results, ...paging } = await getNextPage(latestWorldsPaging);
    commit('APPEND_LATEST_WORLD', { results, paging });
  },
  updateWorldStatus({ commit }, statusWorld) {
    commit('UPDATE_WORLD_STATUS', statusWorld);
  },

  async updateRoomdetails(_obj, { id, title, description, banner_img_url }) {
    return editWorldRoomDetails(id, { title, description, banner_img_url });
  },

  async getUnreadWorldsMsgs({ commit }) {
    const resp = await getWorldUnreadMsgsCount();
    commit('SET_UNREAD_MSG', { hasUnreadWorldMsgs: get(resp, 'count', '') });
  },
  async updateWorldImageOrder({ commit, state }, { worldId, collectionId, images }) {
    // const collection = this.currentImageCollections.find((c: any) => c.id === collectionId);
    // if (!collection) return;
    // collection.images_page!.results = images;
    // reorderImageCollectionImages(collectionId, images.map((i: Image) => i.id).filter(Boolean) as string[]);
  },
};
