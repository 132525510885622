import { GetterTree } from 'vuex';
import { CharactersState } from './types';
import { RootState } from '@/shared/store';

export const getters: GetterTree<CharactersState, RootState> = {
  homeCharacters(state: CharactersState) {
    return state.homeCharacters;
  },

  homeCharactersPaging(state: CharactersState) {
    return state.homeCharactersPaging;
  },

  userCharacters(state: CharactersState) {
    return state.userCharacters;
  },

  userCharactersPaging(state: CharactersState) {
    return state.userCharactersPaging;
  },

  trendingCharacters(state: CharactersState) {
    return state.trendingCharacters;
  },

  trendingCharactersPaging(state: CharactersState) {
    return state.trendingCharactersPaging;
  },

  latestCharacters(state: CharactersState) {
    return state.latestCharacters;
  },

  latestCharactersPaging(state: CharactersState) {
    return state.latestCharactersPaging;
  },

  topCharacters(state: CharactersState) {
    return state.topCharacters;
  },

  topCharactersPaging(state: CharactersState) {
    return state.topCharactersPaging;
  },

  inActionCharacter(state: CharactersState) {
    return state.inActionCharacter;
  },
  inActionCharacterDraft(state: CharactersState) {
    return state.inActionCharacterDraft;
  },

  wsCommentsConnection(state: CharactersState) {
    return state.wsCommentsConnection;
  }
};
