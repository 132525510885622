import { ActionTree } from 'vuex';
import { CharactersState } from './types';

import { RootState } from '@/shared/store';
import {
  getHomeCharacters as getHomeCharactersRemote,
  getOwnCharacters,
  getUserCharacters,
  getCharactersByUsername,
  getTopCharacters,
  createCharacter,
  editCharacter,
  deleteCharacter,
  getCharacter,
  getCharacterFromSlug,
  getCharacterDraft,
  getCharacterByIdFull,
} from '@/shared/actions/characters';
import { createComment, getComments } from '@/shared/actions/comments';
import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { Character, UserReaction, ReactionCounts } from '@/shared/types/static-types';
import {
  createReactionOnCharacter,
  deleteReactionOnCharacter,
  updateReactionOnCharacter,
} from '@/shared/actions/reactions';
import { getNextPage } from '@/shared/helpers/pagination';
import constants from '@/shared/statics/constants';

const isUserReactedSameReaction = (reaction: string, character: Character, isInstant = false): boolean => {
  const { user_reaction } = character;
  return !!(user_reaction && user_reaction.id && (user_reaction.reaction === reaction || isInstant));
};

const isUserReactedAnotherReaction = (reaction: string, character: Character): boolean => {
  const { user_reaction } = character;
  return !!(user_reaction && user_reaction.id && user_reaction.reaction !== reaction);
};

const sendReactionToBackend = async (
  reaction: string,
  character: Character,
  fromRoulette: boolean = false,
  isInstant = false
) => {
  const target = 'character';
  const { user_reaction, id: characterId = '' } = character;

  // delete
  if (isUserReactedSameReaction(reaction, character, isInstant)) {
    return await deleteReactionOnCharacter({ reactionId: user_reaction?.id || '' });
  }

  // patch
  if (isUserReactedAnotherReaction(reaction, character)) {
    return await updateReactionOnCharacter({ reactionId: user_reaction?.id || '', reaction });
  }

  // post
  const data = await createReactionOnCharacter({ objectId: characterId, target, reaction, fromRoulette });
  return data;
};

export const updateReactionsDataForCharacter = (
  reaction: string,
  character: Character,
  newReaction: UserReaction
): Partial<Character> => {
  const characterData = cloneDeep(character);
  characterData.reaction_counts = characterData.reaction_counts || { total_count: 0 };
  const totalCount = characterData.reaction_counts.total_count;
  let userReaction = characterData.user_reaction || ({} as UserReaction);
  const prevUserReaction = character.user_reaction as UserReaction;

  // editing counts if un-reacting or editing reaction
  if (characterData.user_reaction as UserReaction) {
    characterData.reaction_counts[prevUserReaction.reaction as keyof ReactionCounts]--;
    if (newReaction.reaction in characterData.reaction_counts) {
      characterData.reaction_counts[newReaction.reaction as keyof ReactionCounts]++;
    } else {
      // ignore; newReaction.reaction will never be "ordering" (which requires type string[])
      //  @ts-ignore
      characterData.reaction_counts[newReaction.reaction as keyof ReactionCounts] = 1;
    }
  }
  // if reacting to story for the first time: editing counts & user_reaction
  if (!userReaction.id) {
    characterData.reaction_counts!.total_count = totalCount + 1;
    //  @ts-ignore
    if (!characterData.reaction_counts[newReaction.reaction as keyof ReactionCounts]) {
      //  @ts-ignore
      characterData.reaction_counts[newReaction.reaction as keyof ReactionCounts] = 1;
    } else {
      //  @ts-ignore
      characterData.reaction_counts[newReaction.reaction as keyof ReactionCounts] += 1;
    }
    userReaction = newReaction;
  }

  // if editing reaction
  if (isUserReactedAnotherReaction(reaction, characterData)) {
    characterData.user_reaction!.reaction = reaction;
  }

  // if un-reacting
  if (isUserReactedSameReaction(reaction, characterData) && !newReaction) {
    characterData.reaction_counts!.total_count = totalCount > 0 ? totalCount - 1 : 0;
    characterData.user_reaction = undefined;
  } else {
    characterData.user_reaction = {
      ...userReaction,
      reaction,
    };
  }

  const out = {
    user_reaction: characterData.user_reaction,
    reaction_counts: characterData.reaction_counts,
  };
  return out;
};

export const actions: ActionTree<CharactersState, RootState> = {
  async getCharacter({ commit }, id: string) {
    const inActionCharacter = await getCharacter(id);
    commit('SET_IN_ACTION_CHARACTERS', inActionCharacter);
    return inActionCharacter;
  },
  async getCharacterFromIdForEditing({ commit }, id: string) {
    const inActionCharacter = await getCharacterByIdFull(id);
    commit('SET_IN_ACTION_CHARACTERS', inActionCharacter);
    return inActionCharacter;
  },
  async getCharacterDraft({ commit }, id: string) {
    const inActionCharacterDraft = await getCharacterDraft(id);
    commit('SET_IN_ACTION_CHARACTERS_DRAFTS', inActionCharacterDraft);
    return inActionCharacterDraft;
  },

  async getCharacterFromSlug({ commit }, slug: string) {
    const inActionCharacter = await getCharacterFromSlug(slug);
    commit('SET_IN_ACTION_CHARACTERS', inActionCharacter);
    return inActionCharacter;
  },

  async getHomeCharacters({ commit }, { page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getHomeCharactersRemote(page, pageSize);
    commit('SET_HOME_CHARACTERS', { results, paging });
  },

  async react(
    { commit },
    {
      reaction,
      character,
      fromRoulette = false,
      isInstant = false,
    }: { reaction: string; character: Character; fromRoulette: boolean; isInstant: boolean }
  ) {
    const newReaction = await sendReactionToBackend(reaction, character, fromRoulette, isInstant);
    const updatedReactionsData = updateReactionsDataForCharacter(reaction, character, newReaction);
    commit('APPLY_REACTION_ON_CHARACTER', { character, updatedReactionsData });
    return { newReaction, updatedReactionsData };
  },

  async homeCharactersNextPage({ commit, state }) {
    const { homeCharactersPage = 0 } = state;
    const { results, ...paging } = await getHomeCharactersRemote(homeCharactersPage + 1);
    commit('APPEND_HOME_CHARACTERS', { results, paging });
  },

  async getUserCharacters({ commit }, { id, username, includeUnlisted }) {
    try {
      commit('SET_USER_CHARACTERS', { results: [], paging: { count: 0, next: null, previous: null } });
      if (id) {
        const { results, ...paging } = await getUserCharacters(id, undefined, { incl_u: includeUnlisted });
        commit('SET_USER_CHARACTERS', { results, paging });
      } else if (username) {
        const { results, ...paging } = await getCharactersByUsername(username);
        commit('SET_USER_CHARACTERS', { results, paging });
      }
    } catch (error) {
      commit('SET_USER_CHARACTERS', { results: [], paging: { count: 0, next: null, previous: null } });
      throw error;
    }
  },

  async userCharactersNextPage({ commit, state }) {
    const { userCharactersPaging } = state;
    const { results, ...paging } = await getNextPage(userCharactersPaging);
    commit('APPEND_USER_CHARACTERS', { results, paging });
  },

  async getTrendingCharacters({ commit }, { page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getTopCharacters(page, '-hot_score', pageSize);
    commit('SET_TRENDING_CHARACTERS', { results, paging });
  },

  async trendingCharactersNextPage({ commit, state }) {
    const { trendingCharactersPaging } = state;
    const { results, ...paging } = await getNextPage(trendingCharactersPaging);
    commit('APPEND_TRENDING_CHARACTERS', { results, paging });
  },

  async getTopCharacters({ commit }, { page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getTopCharacters(page, '-rxn_count', pageSize);
    commit('SET_TOP_CHARACTERS', { results, paging });
  },

  async topCharactersNextPage({ commit, state }) {
    const { topCharactersPaging } = state;
    const { results, ...paging } = await getNextPage(topCharactersPaging);
    commit('APPEND_TOP_CHARACTERS', { results, paging });
  },

  async getLatestCharacters({ commit }, { page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getHomeCharactersRemote(page, pageSize);
    commit('SET_LATEST_CHARACTERS', { results, paging });
  },

  async latestCharactersNextPage({ commit, state }) {
    const { latestCharactersPaging } = state;
    const { results, ...paging } = await getNextPage(latestCharactersPaging);
    commit('APPEND_LATEST_CHARACTERS', { results, paging });
  },

  async getOwnCharacters({ commit }, { page = 1, pageSize = constants.defaultPageSize }) {
    const { results, ...paging } = await getOwnCharacters(page, pageSize);
    commit('SET_OWN_CHARACTERS', { results, paging });
  },

  async ownCharactersNextPage({ commit, state }) {
    const { ownCharactersPaging } = state;
    const { results, ...paging } = await getNextPage(ownCharactersPaging);
    commit('APPEND_OWN_CHARACTERS', { results, paging });
  },

  async create(
    _,
    {
      charData,
      allow_roleplay_req,
      allow_comp_bomb,
      is_nsfw,
      character_collections,
      visual_collections,
      charId,
      tags,
      original_creator,
      is_fanchar,
      is_fanchar_spinoff,
    }
  ) {
    const { trackEvent } = useAnalytics();
    const data = await createCharacter(
      charData,
      allow_roleplay_req,
      allow_comp_bomb,
      is_nsfw,
      character_collections,
      visual_collections,
      charId,
      tags,
      original_creator,
      is_fanchar,
      is_fanchar_spinoff,
    );
    trackEvent('Created character');
    return data;
  },

  async edit(
    _,
    {
      id,
      info,
      allow_roleplay_req,
      allow_comp_bomb,
      is_nsfw,
      character_collections,
      visual_collections,
      tags,
      original_creator,
      is_fanchar,
      is_fanchar_spinoff,
    }
  ) {
    const data = await editCharacter(
      id,
      info,
      allow_roleplay_req,
      allow_comp_bomb,
      is_nsfw,
      character_collections,
      visual_collections,
      tags,
      original_creator,
      is_fanchar,
      is_fanchar_spinoff,
    );
    sendAnalyticsEvent('Edited character');
    return data;
  },

  async delete(_, id) {
    await deleteCharacter(id);
    sendAnalyticsEvent('Deleted character');
  },

  async postComment(_obj, commentData) {
    const data = await createComment(commentData);
    sendAnalyticsEvent('Comment sent');
    return data;
  },

  getComments(_obj, { objId, objType, page = 1, params }) {
    return getComments(objId, objType, page, params);
  },

  updateWSCommentConnection({ commit }, connection) {
    commit('UPDATE_WS_COMMENT_CONN', connection);
  },
};
