import { sendAnalyticsEvent } from '@/shared/services/analytics';
import { chAxios } from '@/shared/lib/axios';

export async function getCurrencyBoxStatus() {
  const url = `/currency/transaction/show-box/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function getUsersClaimedBoxes() {
  const url = `/users/claimed-boxes/`;
  const { data } = await chAxios().get(url);
  return data;
}

export async function postCurrencyOpenStatus(payload: {}) {
  const url = `/currency/transaction/open-box/`;
  const { data } = await chAxios().post(url, payload);
  sendAnalyticsEvent('Daily gift box opened', { coins: data.coins });
  return data;
}

export async function getUsersCurrencyBoxImage() {
  const url = `/currency/transaction/box-image/`;
  const { data } = await chAxios().get(url);
  return data;
}
