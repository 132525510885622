<template>
  <ion-modal :is-open="isOpen" mode="md" :backdrop-dismiss="true" :show-backdrop="true" @didDismiss="dismiss()">
    <div class="d-flex flex-column modal-inner-content card">
      <div class="d-flex justify-content-between align-items-center my-3 px-4">
        <strong class="text-color" style="font-size: 18px"
          >{{ isEditor ? 'Edit' : '' }}
          {{
            usedKey === 'outfits'
              ? 'Outfit'
              : usedKey === 'favorites'
              ? 'Favorite'
              : usedKey === 'inventory'
              ? 'Inventory'
              : 'Life Stage'
          }}</strong
        >
        <i
          class="ti-close icon clickable-item-hov text-color"
          style="font-size: 18px; color: lightslategray"
          @click="dismiss()"
        />
      </div>
      <div class="px-4">
        <div v-if="isEditor" class="w-100 mt-2">
          <div class="label mb-2"><b>Caption:</b></div>
          <ion-textarea
            v-model="image.description"
            class="c-textarea"
            placeholder="Write a caption for this image"
            maxlength="400"
            @input="debounceSave"
          />
          <div class="label mt-2 mb-2"><b>Source:</b></div>
          <ion-input
            v-model="image.source"
            class="c-input"
            placeholder="Image credits/source"
            maxlength="150"
            @input="debounceSave"
          />

          <div class="d-flex mt-4 mb-2">
            <div class="label"><b>Blur Image:</b></div>
            <ion-checkbox v-model="image.is_spoiled" class="mx-2"></ion-checkbox>
          </div>
          <ion-input
            v-if="image.is_spoiled"
            v-model="image.spoiled_text"
            class="c-input"
            placeholder="Explain why you’re blurring. Content Guidelines still apply."
            maxlength="50"
          />
        </div>
        <div v-if="isEditor" class="top w-100">
          <div class="d-flex mt-2">
            <div>
              <UploadForm
                :is-multiple="false"
                :save-watermark="true"
                :full-image-info="true"
                :outfits="image?.id"
                class="upload-button"
                @uploaded="addImage"
                @loading="setUploading"
              >
                <ion-button
                  v-if="get(image, 'type', 'standard') == 'standard'"
                  fill="solid"
                  :color="imageButtonColor"
                  :disabled="isUploading"
                  class="change-button left"
                >
                  <ChLoading size="sm" v-if="isUploading" class="spinner" />
                  <div v-else><i class="ti-pencil mr-1" /> Change Image</div>
                </ion-button>
              </UploadForm>
            </div>

            <ion-button
              v-if="get(image, 'type', 'standard') == 'standard' && image?.id && image?.image"
              :key="forceRender"
              fill="solid"
              :disabled="isUploading"
              :color="image?.is_watermarked ? 'medium' : imageButtonColor"
              class="one-button ml-1"
              :title="image?.is_watermarked ? 'Remove watermark' : 'Watermark image'"
              @click.prevent="toggleImageWatermark()"
            >
              <span>
                <inline-svg v-if="image?.is_watermarked === false" src="/icons/watermark-on.svg" />
                <inline-svg v-if="image?.is_watermarked === true" src="/icons/watermark-off.svg" />
              </span>
            </ion-button>
          </div>
        </div>

        <img
          class="vis"
          :src="
            resizeUpload(
              image?.image ||
                (usedKey === 'outfits'
                  ? '/images/Outfits.png'
                  : usedKey === 'favorites'
                  ? '/images/Favorites.png'
                  : usedKey === 'inventory'
                  ? '/images/Inventory.png'
                  : '/images/LifeStages.png') ||
                '/empty.png',
              '240h'
            )
          "
        />
        <div v-if="!isEditor" class="text-color">{{ image?.description }}</div>
        <div
          v-if="isWatermarkLoading"
          class="watermarking-card w-100"
          :class="image?.description ? '' : 'rounded-bottom'"
        >
          <div class="h-100 position-relative">
            <div class="d-flex justify-content-center waiting-icon"><i class="ti-time" /></div>
            <div class="text-center mt-2 process-text">Image Processing...</div>
          </div>
        </div>

        <div v-if="isEditor" class="mt-auto w-100 d-flex justify-content-center">
          <ion-button class="no-select done" @click="dismiss()">Done</ion-button>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import { mainStore } from '@/shared/pinia-store/main';
import UploadForm from '@/shared/components/upload-form.vue';
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  image: {
    type: Object,
    default: () => {},
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  isWatermarkLoading: {
    type: Boolean,
    default: false,
  },
  isEditor: {
    type: Boolean,
    default: false,
  },
  usedKey: {
    type: String,
    default: '',
  },
  customize: {
    type: Object,
    default: () => ({}),
  },
  uploading: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['dismissModal', 'updatedImage', 'imageWatermark']);
const image = toRef(props, 'image');
const isEditor = toRef(props, 'isEditor');
const usedKey = toRef(props, 'usedKey');
const customize = toRef(props, 'customize');
const uploading = toRef(props, 'uploading');

const { isTempDefault } = useCustTempDefault(props);
const forceRender = ref(0);
const isUploading = ref(false);
const imageSpoiler = ref(false);

const dark = computed(() => {
  const { dark } = mainStore();
  return dark.value;
});

const imageButtonColor = computed(() => {
  return dark.value ? 'light' : 'dark';
});

watch(uploading, (val) => {
  isUploading.value = val;
});

const setUploading = (val: boolean) => {
  isUploading.value = val;
};

const windowWidth = computed(() => {
  const { width } = useWindowSize();
  return width.value;
});

const defaultTextColor = computed(() => {
  const defaultClr = dark.value ? '#FFFFFF' : '#000000';
  return isTempDefault.value ? defaultClr : `${get(customize.value, 'data.theme.text.default.color', defaultClr)}`;
});

const infoSectionColor = computed(() => {
  const defaultClr = dark.value ? '#17074C' : '#FFFFFF';
  if (isEditor.value) return defaultClr;
  return isTempDefault.value ? defaultClr : get(customize.value, 'data.theme.backgrounds.infoSection') || defaultClr;
});

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 580;
});

const debounceSave = debounce(() => emits('updatedImage', image.value), 1000);

const toggleImageWatermark = () => {
  emits('imageWatermark');
};

const addImage = (img: any) => {
  if (!img) return;
  emits('updatedImage', img);
};

const imageHeight = computed(() => {
  return !isEditor.value ? '50vh' : isMobSmallScreen.value ? '45vh' : '28vh';
});

const captionHeight = computed(() => {
  return !isMobSmallScreen.value ? '50px' : '100px';
});

const dismiss = () => {
  emits('updatedImage', image.value);
  emits('dismissModal');
};
</script>

<style lang="sass" scoped>
.done
  width: 100px
.vis
  width: 100% !important
  object-fit: contain
  object-position: center

  height: v-bind(imageHeight)

.right
  right: 1.3rem

ion-button
  cursor: pointer

ion-modal
  --max-height: 97vh

.c-textarea
  ::v-deep textarea
    height: 10px
    min-height: v-bind(captionHeight)

  ::v-deep .textarea-wrapper
    min-height: v-bind(captionHeight)

.modal-inner-content
  max-height: 90vh !important
  overflow: scroll
  background: v-bind(infoSectionColor)

.change-button
  --border-radius: 8px
  width: 8rem !important
  height: 1.6rem !important
  --padding-start: 10px
  --padding-end: 10px
.one-button
  --border-radius: 8px
  width: 1.6rem !important
  height: 1.6rem !important
  --padding-start: 10px
  --padding-end: 10px
input[type=text]::placeholder
  color: #888 !important
.watermarking-card
  position: absolute
  background-color: rgba(255, 255, 255, 0.65) !important
  font-size: 12px !important
  border-radius: 10px !important
  border: none !important
  outline: none !important
  top: 0
  right: 0
  left: 0
  bottom: 0
  display: block !important
  z-index: 1
  justify-content: center !important
  align-items: stretch !important
  padding: 0 !important
  margin: 0 !important
  div
    top: 60%
  .waiting-icon
    font-size: 36px !important
    color: var(--color) !important
  .process-text
    color: var(--color) !important
    font-size: 14px !important
    font-weight: 400 !important

.text-color
  color: v-bind(defaultTextColor) !important

.loading-overlay
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  justify-content: center
  align-items: flex-start
  border-radius: 20px
  &.visible
    z-index: 1
  .inner-overlay
    background: rgba(255, 255, 255, 1.0)
    padding: 1rem
    border-radius: 0.5rem
    display: flex
    justify-content: top
    align-items: center
    flex-direction: column
    margin-top: 300px
  .loading-indicator
    animation: spin 3s linear infinite
    margin: 0.75rem
    i
      font-size: 2em
      color: #50c8ff !important
@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

.spinner
  height: 15px
</style>
