import { authStore } from '@/shared/pinia-store/auth';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.server) return;

  const { authToken } = authStore();
  const { requireAuth } = to.meta;

  if (requireAuth && !authToken.value) {
    return navigateTo('/');
  }
});
